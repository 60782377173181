<!-- Card completed project -->
<div class="card">
    <div class="card-body">
        <div class="dropright dropright-action">
            <svg width="24px" height="24px" id="dropdownMenuButton4" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" class="opacity-icon">
                <use xlink:href="#action" />
            </svg>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton4">
                <span class="dropdown-menu-arrow"></span>
                <a class="dropdown-item" href="http://ecv5-test.eventcatalyst.io/assets/extract_test.xls" i18n>Extract
                    completed project</a>
                <a class="dropdown-item" href="http://ecv5-test.eventcatalyst.io/assets/extract_test.xls" i18n>Extract
                    not completed project</a>
                <a class="dropdown-item" [routerLink]="['/admin/communication/emailing']" i18n>Send recall e-mail to valid
                    list</a>
                <a class="dropdown-item" [routerLink]="['/admin/communication/emailing']" i18n>Send recall e-mail to invalid
                    list</a>
            </div>
        </div>
        <h4 class="card-title">{{data.type}} {{data.cardTitle}}</h4>
        <div class="bottomRows">
            <div class="row middleRow">
                <div class="col d-flex align-items-baseline">
                    <span class="h2Top"
                        ngbPopover="{{complete}} {{data.type}} have completed their projects infos over {{total}}"
                        popoverClass="popover-custom" triggers="mouseenter:mouseleave">{{complete}}</span>
                    <span class="ml-2 valueLabel"
                        ngbPopover="{{complete}} {{data.type}} have completed their projects infos over {{total}}"
                        popoverClass="popover-custom" triggers="mouseenter:mouseleave" i18n>COMPLETE</span>
                    <div class="col-3 taux">{{tauxProgress(taux)}}<span>&nbsp;%</span></div>
                </div>
            </div>
            <div class="progressBar">
                <ngb-progressbar
                    ngbPopover="{{tauxProgress(taux)}} % of all {{data.type}} have completed their project infos"
                    popoverClass="popover-custom" triggers="mouseenter:mouseleave" class="mb-2 mt-3" [showValue]="false"
                    showValue="true" [value]="taux*100">
                </ngb-progressbar>
            </div>
            <div class="row lastRow">
                <div style="margin-left: 15px"
                    ngbPopover="{{incomplete}} {{data.type}} haven't completed their projects infos over {{total}}"
                    popoverClass="popover-custom" triggers="mouseenter:mouseleave">
                    <span class="h2">{{incomplete}}</span>
                    <span class="ml-2 valueLabel" i18n>INCOMPLETE / {{total}}</span>
                </div>
                <div>
                    <span [routerLink]="['/admin/communication/emailing']" class="mr-3 pt-2 resp-item"
                        ngbPopover="Send recall e-mail" popoverClass="popover-custom-light"
                        triggers="mouseenter:mouseleave">
                        <svg class="opacity-icon " width="24px" height="24px">
                            <use xlink:href="#mail" />
                        </svg>
                    </span>
                    <span [routerLink]="['/admin/contacts/list/']" class="mr-2 pt-2" ngbPopover="List {{data.type}}"
                        popoverClass="popover-custom-light" triggers="mouseenter:mouseleave">
                        <svg class="opacity-icon " width="24px" height="24px">
                            <use xlink:href="#arrow-right" />
                        </svg>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End card -->