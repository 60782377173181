import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EventService } from '../../../../core/http/event.service';
import { RefService } from '../../../../core/http/ref.service';
import { bounceOutRightOnLeaveAnimation, bounceAnimation, hueRotateAnimation, fadeInDownOnEnterAnimation, fadeOutUpOnLeaveAnimation } from 'angular-animations';
import * as _moment from 'moment';
import { HotelService } from 'src/app/core/http/hotel.service';
import { FormDataHotelService } from 'src/app/core/http/form-data-hotel.service';

declare var $: any;

@Component({
  selector: 'app-hotels-edit-form',
  templateUrl: './hotels-edit-form.component.html',

  styleUrls: ['./hotels-edit-form.component.scss'],
  animations: [
    bounceOutRightOnLeaveAnimation({ delay: 100 }),
    bounceAnimation(),
    hueRotateAnimation(),
    fadeInDownOnEnterAnimation({ duration: 10, translate: '5px', delay: 0 }),
    fadeOutUpOnLeaveAnimation({ duration: 10, translate: '5px', delay: 0 }),
  ]
})

export class HotelsEditFormComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private hotelService: HotelService,
    private toastr: ToastrService,
    private eventService: EventService,
    private refService: RefService,
    private formDataHotelService: FormDataHotelService
  ) {}

  editHotelsFormGroup: any;
  dataHotel: any;
  listOfCountry: any;
  initialHotelsForm: any;
  isHotelHasChange: boolean = false;
  idHotel: any;

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.idHotel = params.get('id');
    });
    this.editHotelsFormGroup = this.formDataHotelService.dataHotel();
    this.hotelService.getHotel(this.idHotel).subscribe(res => {
      this.dataHotel = res;
      this.initValueForm();
    })
    this.initRef();
  }
  initRef() {
    this.refService.getCountry().subscribe( res => {
      this.listOfCountry = res;
    })
  }
  initValueForm() {
    console.log(this.dataHotel)
    this.editHotelsFormGroup.patchValue({
      hotelName: this.dataHotel.hotelName,
      idEvent: this.dataHotel.idEvent,
      idSociete: this.dataHotel.idSociete,
      address: this.dataHotel.address,
      zipCode: this.dataHotel.zipCode,
      city: this.dataHotel.city,
      state: this.dataHotel.state,
      idCountry: this.dataHotel.idCountry,
      mobile: this.dataHotel.mobile,
      fax: this.dataHotel.fax,
      url: this.dataHotel.url,
      description: this.dataHotel.description
    })
    this.initialHotelsForm = this.editHotelsFormGroup.value;
    this.editHotelsFormGroup.valueChanges.subscribe(value => {
      this.isHotelHasChange = Object.keys(this.initialHotelsForm).some(key =>
        this.editHotelsFormGroup.value[key] != this.initialHotelsForm[key])
      }
    );
  }
  onSubmit() {
    this.hotelService.updateHotel(this.idHotel, this.editHotelsFormGroup["value"]).subscribe(res => {
      console.log('success', res);
      this.toastr.clear();
      this.toastr.success('Success', 'Hotels edit');
      this.router.navigateByUrl(`/admin/logistics/hotels`);
    }, err => {
      console.log("L'error est : " + err);
      this.toastr.clear();
      this.toastr.error('Error', 'Hotels not edit');
    });
  }
  cancelEdit() {
    this.isHotelHasChange = false;
    this.ngOnInit();
  }
  
  changeHotelsForm() {
    this.editHotelsFormGroup.valueChanges.subscribe(value => {
      this.isHotelHasChange = Object.keys(this.initialHotelsForm).some(key =>
        this.editHotelsFormGroup.value[key] != this.initialHotelsForm[key])
      }
    );
  }
}