<div class="container-fluid font-color" [ngStyle]="{'background-color':mobileRes == true ? '#f8f9f4' : '#FFF' }">
  <div class="maincontainer row">
    <div style="padding-left: 0px;"  class="col">
      <nav class="breadcrumb-responsive filAriane" aria-label="breadcrumb" style="margin-top: 15px">
        <ol class="breadcrumb pl-0 breadcrumb-color">
          <svg id="dropdownMenuButton6" height="24px" width="24px" aria-haspopup="true" aria-expanded="false"
               style="fill:#84C000">
            <use xlink:href="#company_f" />
          </svg>
          <li class="breadcrumb-item"><a href="#" i18n>Dashboard</a></li>
          <li class="breadcrumb-item"><a href="#" i18n>Communication</a></li>
          <li class="breadcrumb-item"><a href="#" i18n>Emailing</a></li>
          <li class="breadcrumb-item active"><a href="#" i18n>Broadcast</a></li>
        </ol>
      </nav>

    </div>
    <div class="offset-lg-2 col-3" style="text-align: end; height: 90px; margin-top: 18px;">
      <div class="icons" style="vertical-align: middle;">

        <div class="btn-group dropleft">
          <svg id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="iconFilArianne">
              <use xlink:href="#action_h" />
          </svg>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
              <span class="dropdown-menu-arrow"></span>
              <a class="dropdown-item" href="#" i18n>Action 1</a>
              <a class="dropdown-item" href="#" i18n>Action 2</a>
              <a class="dropdown-item" href="#" i18n>Action 3</a>
              <a class="dropdown-item" href="#" i18n>Action 4</a>
          </div>
      </div>
      </div>
    </div>
  </div>
</div>
