import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-add-attendee',
  templateUrl: './card-add-attendee.component.html',
  styleUrls: ['./card-add-attendee.component.scss']
})
export class CardAddAttendeeComponent implements OnInit {

  @Input() data;
  participantType;

  constructor() { }

  ngOnInit() {
    this.participantType = this.data.participantType;

  }

}
