import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-svg-library',
  templateUrl: './svg-library.component.html',
  styleUrls: ['./svg-library.component.scss']
})
export class SvgLibraryComponent implements OnInit {
  constructor(private toastr: ToastrService,) { }
  public listSvg = ["activity", "arrow-left", "arrow-right", "bell", "briefcase", "check", "chevrons-right", "ic_stats", "late", "loader", "logo", "mail", "mailDetails", "phone", "phoneDetails", "message-circle", "more-vertical", "refresh-cw", "user", "user-plus", "left", "right", "action", "download", "edit", "editDetails", "viewDetails", "export", "dotArrow", "dotArrow2", "dotArrow3", "plus", "minus", "calendar", "mic", "search", "company_f", "action_h", "company", "ic_Settings", "taskTodo", "taskIP", "taskDone", "idList", "infoLogo", "previewLogo", "trash", "eraser", "delete", "more-vertical", "save", "copy"];
  
  ngOnInit() {

  }

  copyToClipboard(item) {
    item = "#" + item;
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    this.toastr.success('Copied to clipboard');

  }

}
