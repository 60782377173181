import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ParticipantService } from '../../../../core/http/participant.service';
import { bounceOutRightOnLeaveAnimation, bounceAnimation, hueRotateAnimation, fadeInDownOnEnterAnimation, fadeOutUpOnLeaveAnimation } from 'angular-animations';
import { FormDataParticipantService } from 'src/app/core/http/form-data-participant.service';

declare var $: any;
// tslint:disable: prefer-template

@Component({
  selector: 'app-participant-add-form',
  templateUrl: './participant-add-form.component.html',

  styleUrls: ['./participant-add-form.component.scss'],
  animations: [
    bounceOutRightOnLeaveAnimation({ delay: 100 }),
    bounceAnimation(),
    hueRotateAnimation(),
    fadeInDownOnEnterAnimation({ duration: 10, translate: '5px', delay: 0 }),
    fadeOutUpOnLeaveAnimation({ duration: 10, translate: '5px', delay: 0 }),
  ]
})



export class ParticipantAddFormComponent implements OnInit {

  urlUpdate;
  response;
  selectedCompany;
  skipAutocomplete;
  urlSelectedCompany;
  companyNameValue;
  autocompleteParticipantsValue;
  listOfParticipantExisting : any;

  isDisplayCompanyHeader: boolean;
  isDisplayAutocomplete: boolean;
  isDisplayAutocompleteParticipant: boolean;
  isDisplayAutocompleteSvg: boolean;
  isParticipantExist: boolean;
  idParticipantExist: any;
  participantForm: any;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private ParticipantService: ParticipantService,
    private formDataParticipantService: FormDataParticipantService
  ) {}

  ngOnInit() {
    this.participantForm = this.formDataParticipantService.dataSimple();
    this.selectedCompany = false;
    this.skipAutocomplete = false;
    this.isDisplayCompanyHeader = false;
    this.isDisplayAutocomplete = false;
    this.isDisplayAutocompleteParticipant = false;
    this.isDisplayAutocompleteSvg = false;
    this.isParticipantExist = false;
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  displayAutocomplete() {
    this.isDisplayCompanyHeader = true;
  }

  getSelectedCompany(event) {
    this.selectedCompany = event;
    this.urlSelectedCompany = '/admin/company/' + this.selectedCompany.id + "/details";
    this.isDisplayCompanyHeader = !this.isDisplayCompanyHeader;
    this.participantForm.patchValue({ idCompany: this.selectedCompany.id });
  }

  checkDisplayAutocomplete(value) {
    this.companyNameValue = value;
    if (value.length > 0) {
      this.isDisplayAutocompleteParticipant = false;
      this.isDisplayAutocomplete = true;
      this.isDisplayAutocompleteSvg = false;
    }
    else {
      this.isDisplayAutocompleteSvg = false;
    }
  }


  swapViewAutocomplete(val) {
    if (val === 'CLEAR') {
      this.isDisplayAutocomplete = false;
      this.participantForm.patchValue({ companyName: '' });
    } else {
      this.isDisplayAutocompleteParticipant = false;
      this.isDisplayAutocomplete = val;
      $('companyName').focus();
    }
  }

  forceDisplayAutocomplete() {
    this.companyNameValue = this.participantForm["value"].companyName;
    this.isDisplayAutocomplete = true;
  }

  resetCompany() {
    this.isDisplayCompanyHeader = !this.isDisplayCompanyHeader;
    // this.displayAutocomplete = !this.displayAutocomplete;
    this.participantForm.patchValue({ companyName: '' });
  }

  closeBtn(){
    sessionStorage.clear();
    this.isDisplayCompanyHeader = !this.isDisplayCompanyHeader;
    this.participantForm.patchValue({ companyName: '' });
    this.participantForm.patchValue({ firstName: ''});
    this.participantForm.patchValue({ lastName: ''});
    this.participantForm.patchValue({ email: ''});
  }

  onSubmit() {
    this.ParticipantService.createParticipant(this.participantForm["value"])
    .subscribe(res => {
      console.log("Success : " + res);
      this.toastr.clear();
      this.toastr.success('Success', 'Participant Create');
      this.router.navigateByUrl(`/admin/contacts/${res.idPerson}/edit`);
    }, (err) => {
      console.log("Error : " + err);
      if (err.substring(0, 25) == "The person already exists") {
        this.isParticipantExist = true;
        $('#existingModal').modal('show');
        this.idParticipantExist = err.substring(26);
        this.ParticipantService.getParticipantAttendee(this.idParticipantExist).subscribe(res => {
          this.listOfParticipantExisting = res;
        });
      }
    })

    // if (sessionStorage.getItem("firstName"))
    //   this.participantForm["value"].firstName = sessionStorage.getItem("firstName");
    // sessionStorage.setItem("firstName", this.participantForm["value"].firstName);

    // if (sessionStorage.getItem("lastName"))
    //   this.participantForm["value"].lastName = sessionStorage.getItem("lastName");
    // sessionStorage.setItem("lastName", this.participantForm["value"].lastName);

    // if (sessionStorage.getItem("email"))
    //   this.participantForm["value"].email = sessionStorage.getItem("email");
    // sessionStorage.setItem("email", this.participantForm["value"].email);

    // if (sessionStorage.getItem("idCompany"))
    //   this.participantForm["value"].idCompany = sessionStorage.getItem("idCompany");
    // sessionStorage.setItem("idCompany", this.participantForm["value"].idCompany);
    
    // console.log(sessionStorage);
  }
  editParticipant() {
    this.router.navigateByUrl(`/admin/contacts/${this.idParticipantExist}/edit`);
  }
  cancelEdit() {
    this.ngOnInit();
  }
}



