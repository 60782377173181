// tslint:disable: variable-name
import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { GetDataService } from '../../../core/http/get-data.service';
import { EmailingService } from '../../../core/http/emailing.service';
import { log } from 'util';

declare var $: any;

@Component({
  selector: 'app-mailing-list-step',
  templateUrl: './mailing-list-step.component.html',
  styleUrls: ['./mailing-list-step.component.scss']
})
export class MailingListStepComponent implements OnInit {

  isForm1Display = false;
  isForm2Display = false;
  isResultFilterDisplay = false;
  isResultIdDisplay = false;

  filterListPicked = false;
  idsListPicked = false;

  attendeesList = [{id: 124, firstname: 'Thelonious', lastname: 'Monk', status: 'invited', email: 'thelonious.monk@microsoft.com', type: 'guest'}]

  public listOfIds = this.emailingService.listOfIdList;
  public listOfFiltersList = this.emailingService.listOfFiltersList;

  public listOfTypes = this.getDataService.participantType;
  public listOfCountry = this.getDataService.countryList;
  public listOfStatus = this.getDataService.participantStatus;
  public listOfPass = this.getDataService.participantPass;
  public listOfPriorityLvl = this.getDataService.partcipantPriorityLvl;
  public listOfGuestCategory = this.getDataService.guestCategory;
  public listOfPartnersCategory = this.getDataService.partnersCategory;
  public listOfDomains = this.getDataService.domainList;

  @Input() emailObject;

  secondStepFormGroup: FormGroup;
  saveModalFormGroup: FormGroup;
  selectMethodFormGroup: FormGroup;
  filterValues;
  idsList;

  // DDL_participantType = new FormControl();
  // DDL_participantCountry = new FormControl();
  // DDL_participantStatus = new FormControl();
  // DDL_participantPass = new FormControl();
  // DDL_participantPrioLvl = new FormControl();

  // DDL_participantProfileUpdate = new FormControl();
  // DDL_participantCompanyUpdate = new FormControl();
  // DDL_participantCCDebit = new FormControl();
  // DDL_participantOne = new FormControl();

  constructor(private _formBuilder: FormBuilder, private getDataService: GetDataService, private emailingService: EmailingService,){}

  ngOnInit() {
    this.secondStepFormGroup = this._formBuilder.group({
      DDL_participantType: ['All'],
      DDL_participantCountry: ['All'],
      DDL_participantLangage: ['All'],

      DDL_participantGuestCategory: ['All'],
      DDL_participantPartnersCategory: ['All'],

      DDL_participantStatus: ['All'],
      DDL_participantPass: ['All'],
      DDL_participantPrioLvl: ['All'],
      DDL_participantDomain: ['All'],

      DDL_participantProfileUpdate: ['Ignore'],
      DDL_participantCompanyUpdate: ['Ignore'],
      DDL_participantCCDebit: ['Ignore'],
      DDL_participantOne: ['Ignore'],
    });

    this.saveModalFormGroup = new FormGroup({
      nameListInput: new FormControl()
    });
    this.selectMethodFormGroup = this._formBuilder.group({
      SelectFilterList: ['null'],
      SelectIdsList: ['null']
    });

   for(let i = 1; i < 30; i++){
     let attendee = {
       id: 124 + i,
       lastname: this.attendeesList[0].lastname,
       firstname: this.attendeesList[0].firstname,
       status: this.attendeesList[0].status,
       email: this.attendeesList[0].email,
       type: this.attendeesList[0].type
     }
     this.attendeesList.push(attendee);
    };
  }

  // ngAfterViewInit() {
  // }

  checkDisplayForm() {
    this.isForm1Display ? this.isForm1Display  = false : this.isForm2Display = false;
  }


  saveFilters() {
    this.filterValues = this.secondStepFormGroup.value;
    console.log(this.filterValues.DDL_participantCountry);
    if (this.isForm1Display) {
      this.isResultFilterDisplay = !this.isResultFilterDisplay;
      this.isForm1Display = !this.isForm1Display;
    } else {
      this.isResultIdDisplay = !this.isResultIdDisplay;
      this.isForm2Display = !this.isForm2Display;
    }
  }

  
  checkDisplayFormResult() {
    if (this.isResultFilterDisplay) {
      this.isResultFilterDisplay = false;
      this.isForm1Display = true;
    } else if (this.isResultIdDisplay){
      this.isResultIdDisplay = false;
      this.isForm2Display = true;
    }
  }

  pickFilterList(event) {
    console.log(event)
    if (event !== 'null') {
      this.selectMethodFormGroup.controls['SelectIdsList'].disable();
      this.filterListPicked = true
    } else {
      this.selectMethodFormGroup.controls['SelectIdsList'].enable();
      this.filterListPicked = false
      }
  }

  pickIdList(event) {
    console.log(event)
    if (event !== 'null') {
      this.selectMethodFormGroup.controls['SelectFilterList'].disable();
      this.idsListPicked = true
    } else {
      this.selectMethodFormGroup.controls['SelectFilterList'].enable();
      this.idsListPicked = false
    }
  }

  skipStep() {
    if (this.filterListPicked == true) {
      this.LoadFilterList(this.selectMethodFormGroup.value.SelectFilterList);
      this.filterValues = this.secondStepFormGroup.value;
      this.isResultFilterDisplay = true
    } else if (this.idsListPicked == true) {
      this.isResultIdDisplay = true
    }
  }

  saveFiltersList(){
    let newFilterlist = {
      listName: this.saveModalFormGroup.value.nameListInput,
      listId: this.listOfFiltersList.length,
      participantType: this.secondStepFormGroup.value.DDL_participantType,
      participantCountry: this.secondStepFormGroup.value.DDL_participantCountry,
      participantLangage: this.secondStepFormGroup.value.DDL_participantLangage,
      participantGuestCategory: this.secondStepFormGroup.value.DDL_participantGuestCategory,
      participantPartnersCategory: this.secondStepFormGroup.value.DDL_participantPartnersCategory,
      participantStatus: this.secondStepFormGroup.value.DDL_participantStatus,
      participantPass: this.secondStepFormGroup.value.DDL_participantPass,
      participantPrioLvl: this.secondStepFormGroup.value.DDL_participantPrioLvl,
      participantDomain: this.secondStepFormGroup.value.DDL_participantDomain,
      participantProfileUpdate: this.secondStepFormGroup.value.DDL_participantProfileUpdate,
      participantCompanyUpdate: this.secondStepFormGroup.value.DDL_participantCompanyUpdate,
      participantCCDebit: this.secondStepFormGroup.value.DDL_participantCCDebit,
      participantOne: this.secondStepFormGroup.value.DDL_participantOne,
    }
    this.emailingService.listOfFiltersList.push(newFilterlist);
    // this.listOfFiltersList.push(newFilterlist);
    $('#exampleModal').modal('hide');
  }

  LoadFilterList(listId){
    let targetedList = this.emailingService.listOfFiltersList[listId];

    this.secondStepFormGroup.controls['DDL_participantType'].setValue(targetedList.participantType);
    this.secondStepFormGroup.controls['DDL_participantCountry'].setValue(targetedList.participantCountry);
    this.secondStepFormGroup.controls['DDL_participantLangage'].setValue(targetedList.participantLangage);

    this.secondStepFormGroup.controls['DDL_participantGuestCategory'].setValue(targetedList.participantGuestCategory);
    this.secondStepFormGroup.controls['DDL_participantPartnersCategory'].setValue(targetedList.participantPartnersCategory);

    this.secondStepFormGroup.controls['DDL_participantStatus'].setValue(targetedList.participantStatus);
    this.secondStepFormGroup.controls['DDL_participantPass'].setValue(targetedList.participantPass);
    this.secondStepFormGroup.controls['DDL_participantPrioLvl'].setValue(targetedList.participantPrioLvl);
    this.secondStepFormGroup.controls['DDL_participantDomain'].setValue(targetedList.participantDomain);

    this.secondStepFormGroup.controls['DDL_participantProfileUpdate'].setValue(targetedList.participantProfileUpdate);
    this.secondStepFormGroup.controls['DDL_participantCompanyUpdate'].setValue(targetedList.participantCompanyUpdate);
    this.secondStepFormGroup.controls['DDL_participantCCDebit'].setValue(targetedList.participantCCDebit);
    this.secondStepFormGroup.controls['DDL_participantOne'].setValue(targetedList.participantOne);
  }

  saveIDsList(){
    let newIdsList = {
      listName: this.saveModalFormGroup.value.nameListInput,
      listId: this.listOfIds.length,
      ids: this.idsList,
    }
    this.emailingService.listOfIdList.push(newIdsList)
    $('#exampleModal').modal('hide');
  }

  loadIdList(listId){
    let targetedList = this.emailingService.listOfIdList[listId];
    this.idsList = targetedList.ids;
    console.log(listId)
  }

  saveAttendeesList(){
    $('#exampleModal').modal('hide');
  }

}
