<!-- LISTE DESKTOP -->
<div *ngIf="!mobileRes">         

    <app-fil-ariane (filterStatut)="checkStatut($event)"></app-fil-ariane>

    <div id="listUsersDesktop">

        <div class="row filters" *ngIf="filterStatut">
            <div class="filtersCenter mt-3">
                <span i18n>Status</span>
                <div class="select-custom">
                    <select class="form-control-sm">
                        <option i18n>Select...</option>
                    </select>
                </div>
                <span style="margin-left: 35px" i18n>Priority</span>
                <div class="select-custom">
                    <select class="form-control-sm">
                        <option i18n>Select...</option>
                    </select>
                </div>
                <span style="margin-left: 35px" i18n>Assignation</span>
                <div class="select-custom">
                    <select class="form-control-sm">
                        <option i18n>Select...</option>
                    </select>
                </div>
                <button type="button" class="btn btn-success btn-size btn-responsive" i18n>Apply</button>
            </div>
        </div>

        <app-list-sort-by></app-list-sort-by>

        <div class="row headerList justify-content-between">
        
            <div class="col-3 selectAll">
                <label>
                    <input type="checkbox" id="selectAllCB">
                    <span class="cbLabel" i18n>Select all</span>
                </label>
                <span i18n>3 selected</span>
            </div>

            <div class="pagination" style="white-space: nowrap;">

                <pagination-controls (pageChange)="pageChange($event)" 
                    class="myPagination" 
                    previousLabel="" 
                    nextLabel=""
                    maxSize="5"></pagination-controls>

                <div class="nbItems">
                    <span>Show</span>
                    <div class="select-custom">
                        <select [(ngModel)]="nbItems" (ngModelChange)='onItemsChange($event)'>
                            <option selected [value]="10">10</option>
                            <option [value]="50">50</option>
                            <option [value]="100">100</option>
                            <option [value]="300">300</option>            
                            <option [value]="999999999">All</option>
                        </select>
                    </div>
                    <span i18n>tasks</span>
                </div>

            </div>
        </div>

        <div class="list">
            <ul>
                <li *ngFor="let task of taskListTodo[0] | paginate: config">
                    <app-task-for-list [task]="task"></app-task-for-list>
                    <!-- <app-user-card-desktop></app-user-card-desktop -->  <!--OLD VERSION-->
                </li>
                <br>
            </ul>
        </div>

    </div>
</div>