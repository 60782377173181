import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(
    private http: HttpClient,
    private fb: FormBuilder
  ) {}

  dataDownload: any;

  public exportFile(body: any) {
    return this.http.post(`${environment.apiUrl}/api/a/download/`, body, {observe: 'response', responseType: 'blob'});
  }

  public downloadData() {
    return this.dataDownload = this.fb.group({
      Name: "",
      TypeFile: ".csv",
      IdEvent: 0,
      Status: "all",
      Year: 0,
      IdCountry : 0,
      IdType: 0,
      IdCompany: 0,
      Main: -1,
      Vip: -1
    });
  }
}
