import { Component, Input, OnInit } from '@angular/core';
import { EventService } from '../../../../core/http/event.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-event-item-card',
  templateUrl: './event-item-card.component.html',
  styleUrls: ['./event-item-card.component.scss']
})
export class EventItemCardComponent implements OnInit {
  
  @Input() event: any;
  collapse: boolean = false;
  eventObj: any;
  listCollapseType = [];
  nbrConfirmed: number;
  nbrDeclined: number;
  nbrAttended: number;
  nbrInvited: number;
  nbrNoShow: number;

  constructor(private EventService: EventService,
    private toastr: ToastrService,) { 
    
  }

  ngOnInit(): void {
    this.EventService.getEventRegistrationStatus("confirmed", this.event.id).subscribe(res => {
      if (res) {
        this.nbrConfirmed = res["length"];
      } else {
        this.nbrConfirmed = 0;
       }
    });
    this.initNbrStatusEvent();
    this.EventService.getEventById(this.event.id).subscribe( response => {
      this.eventObj = response; 
      this.eventObj.startDateRegistration = this.eventObj.startDateRegistration.substring(0, 10)
      this.eventObj.endDateRegistration = this.eventObj.endDateRegistration.substring(0, 10)
      this.eventObj.startDate = this.eventObj.startDate.substring(0, 10)
      this.eventObj.endDate = this.eventObj.endDate.substring(0, 10)
      this.eventObj.idEvent = this.eventObj.idEvent
      this.eventObj.typeParticipant = this.eventObj.typeParticipant
      this.eventObj.idModal = "deleteEvent" + this.eventObj.idEvent
      this.eventObj.eventName = this.eventObj.eventName
      if (this.eventObj.eventName.length > 40){
        this.eventObj.eventName = this.eventObj.eventName.substring(0, 40);
        this.eventObj.eventName += ' ...';
      }
      // Ici s'exec apres la reponse de l'api
    });
  }

  initNbrStatusEvent() {
    this.EventService.getEventRegistrationStatus("confirmed", this.event.id).subscribe(res => {
      if (res) { this.nbrConfirmed = res["length"]; } 
      else { this.nbrConfirmed = 0; }
    });
    this.EventService.getEventRegistrationStatus("invited", this.event.id).subscribe(res => {
      if (res) { this.nbrInvited = res["length"]; } 
      else { this.nbrInvited = 0; }
    });
    this.EventService.getEventRegistrationStatus("declined", this.event.id).subscribe(res => {
      if (res) { this.nbrDeclined = res["length"]; } 
      else { this.nbrDeclined = 0; }
    });
    this.EventService.getEventRegistrationStatus("attended", this.event.id).subscribe(res => {
      if (res) { this.nbrAttended = res["length"]; } 
      else { this.nbrAttended = 0; }
    });
    this.EventService.getEventRegistrationStatus("noshow", this.event.id).subscribe(res => {
      if (res) { this.nbrNoShow = res["length"]; } 
      else { this.nbrNoShow = 0; }
    });
  }
  showId() {
    console.log(this.eventObj.idEvent);
  }

  toastDelete() {
    this.toastr.success('Success', 'Event delete');
  }

  collapseParticipantType(id) {
    $('#'+id).collapse('toggle');
  }
}
