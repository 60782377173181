<div class="maincontainer">
    <form (ngSubmit)="dataHotel.valid && onSubmit()" [formGroup]='dataHotel'>
        <div class="mt-3">
            <app-form-input>
                <label for="hotelName" class="col-md-2 label col-form-label" i18n>Hotel Name *</label>
                <input type="text" class="input form-control" id="hotelName" formControlName="hotelName">
            </app-form-input>
            <hr>
            <div class="form-group row">
                <div class="col-md-6">
                    <app-form-input>
                        <label for="adressHotel" class="col-md-4 label col-form-label" i18n>Address</label>
                        <input type="text" class="input form-control" id="address" formControlName="address">
                    </app-form-input>
                    <app-form-input>
                        <label for="cityHotel" class="col-md-4 label col-form-label" i18n>City</label>
                        <input type="text" class="input form-control" id="city" formControlName="city">
                    </app-form-input>
                    <app-form-select>
                        <label for="countryHotel" class="col-md-4 label col-form-label" i18n>Country</label>
                        <select class="select form-control" id="countryHotel" formControlName="idCountry">
                            <option value="0" i18n>Please select</option>
                            <option *ngFor="let country of listOfCountry" value="{{country.id}}">
                            {{country.label}}
                            </option>
                        </select>
                    </app-form-select>
                </div>
                <div class="col-md-6">
                    <app-form-input>
                        <label for="zipCodeHotel" class="col-md-4 label col-form-label" i18n>Zip Code</label>
                        <input type="text" class="input form-control" id="zipCode" formControlName="zipCode">
                    </app-form-input>
                    <app-form-input>
                        <label for="stateHotel" class="col-md-4 label col-form-label" i18n>State</label>
                        <input  type="text" class="input form-control" id="state" formControlName="state">
                    </app-form-input>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-6">
                    <app-form-input>
                        <label for="phoneHotel" class="col-md-4 label col-form-label" i18n>Phone Number</label>
                        <input type="text" class="input form-control" id="mobile" formControlName="mobile">
                    </app-form-input>
                </div>
                <div class="col-md-6">
                    <app-form-input>
                        <label for="faxHotel" class="col-md-4 label col-form-label" i18n>Fax</label>
                        <input type="text" class="input form-control" id="faxHotel" formControlName="fax">
                    </app-form-input>
                </div>
                <div class="col-md-12">
                <app-form-input>
                    <label for="urlHotel" class="col-md-2 label labelSpe col-form-label" i18n>Url</label>
                    <input type="text" class="input form-control" id="url" formControlName="url">
                </app-form-input>
                </div>
            </div>
            <hr>
            <div class="row justify-content-center">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="descriptionHotel" i18n>Hotel description: </label>
                        <textarea class="form-control" id="descriptionHotel" formControlName="description"
                        rows="5" maxlength="1000"></textarea>
                        <small i18n>{{dataHotel.value.description.length}} Characters (Max. 1000)</small>
                    </div>
                </div>
                <button type="submit" class="btn btn-success button--color btn-size btn-responsive"
                [disabled]="!dataHotel.valid" i18n>Save
                </button>
                <br>
            </div>
        </div>   
    </form>
</div>
<!-- <div class="">
    <div class="card border-secondary" style="max-width: 18rem;margin-left: auto;">
        <div class="card-header" i18n>Rooms</div>
        <div class="card-body text-secondary">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col" i18n>Room type</th>
                        <th scope="col" i18n>Price</th>
                        <th scope="col" i18n>Quantity</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let room of hotelRooms">
                        <th scope="row">{{room.nameHotelRoom}}</th>
                        <td>{{room.priceHotelRoom}}€</td>
                        <td>{{room.quantityHotelRoom}}</td>  
                    </tr>
                    <br>
                    <button class="btn btn-success button--color btn-size btn-responsive" style="margin-left:100%" data-toggle="modal" data-target="#exampleModal" i18n>Add</button>
                </tbody>
            </table>
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel" i18n>Add new room type</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <form (ngSubmit)="hotelRoomForm.valid && onSubmitHotelRoom()" [formGroup]='hotelRoomForm'>
                            <div class="modal-body" style="margin-left: 5%;">
                                <app-form-input>
                                    <label for="nameHotelRoom" class="label col-form-label" i18n>Room Type</label>
                                    <input type="text" class="input form-control" id="nameHotelRoom"
                                    placeholder="Type of room" formControlName="nameHotelRoom">
                                </app-form-input>
                                <app-form-input>
                                    <label for="priceHotelRoom" class="label col-form-label" i18n>Price</label>
                                    <input type="number" class="input form-control" id="priceHotelRoom"
                                    placeholder="Price" formControlName="priceHotelRoom">
                                </app-form-input>
                                <app-form-input>
                                    <label for="quantityHotelRoom" class="label col-form-label" i18n>Quantity</label>
                                    <input type="text" class="input form-control" id="quantityHotelRoom"
                                        placeholder="Quantity" formControlName="quantityHotelRoom">
                                </app-form-input>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal" i18n>Close</button>
                                <button type="submit" [disabled]="!hotelRoomForm.valid" class="btn btn-primary" i18n>Save</button>
                            </div>
                        </form>  
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->