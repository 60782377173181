<div class="block__size--responsive">
    <div class="card mb-4">
      <div class="card-body p-3">
        <a class="nowayRegular mb-3" style="color: #244746; font-size: 18px; font-weight: bold;" i18n>Participant status</a>
        <div class="col-md-12 row">
          <div class="col-md-6">
            <p class="m-0"> <span class="badge badge-pill card__badge-invited" i18n>INVITED</span></p>
            <p class="m-0"> <span class="badge badge-pill card__badge-declined" i18n>DECLINED</span></p>
            <p class="m-0"> <span class="badge badge-pill card__badge-attended" i18n>ATTENDED</span></p>
            <p class="m-0"> <span class="badge badge-pill card__badge-confirmed" i18n>COMFIRMED</span></p>
            <p class="m-0"> <span class="badge badge-pill card__badge-noshow" i18n>NOSHOW</span></p>
          </div>
          <div class="col-md-6">
            <p class="m-0">{{nbrInvited}}</p>
            <p class="m-0">{{nbrDeclined}}</p>
            <p class="m-0">{{nbrAttended}}</p>
            <p class="m-0">{{nbrConfirmed}}</p>
            <p class="m-0">{{nbrNoShow}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>