<div class="container-fluid body-height font-color">
    <div class="container pt-5">

        <div class="row">

            <div class="col-lg-6 col-md-6 offset-md-3 mb-5">

                <div class="text-center">
                    <img class="logo pointer" [routerLink]="['']"
                         src="http://www.comparatif-logiciels.fr/wp-content/uploads/2016/05/event_catalyst_comparatif_logiciels_saas-1.png">
                </div>

                <div class="col-lg-12 login-responsive">

                    <p class="text-center mb-5 login-title" i18n>event catalyst</p>

                    <form (ngSubmit)="onSubmit()" [formGroup]="loginForm">
                        <div class="form-group">
                            <label for="username" i18n>Forgot username</label>
                            <input type="mail" class="form-control" id="username"
                                   aria-describedby="usernameHelp" placeholder="Email adress" formControlName="username">
                        </div>
                        <div class="text-center mt-4">
                            <button type="submit" class="btn btn-lg btn-success" [disabled]="!loginForm.valid" i18n>Sent
                            </button>
                        </div>
                    </form>

                    <p *ngIf="error" class="alert mt-4 alert-content" i18n>Sorry, we couldn't find an account with
                        this mail adress.
                    </p>

                </div>

            </div>
        </div>

    </div>
</div>

