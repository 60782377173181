import { Component, OnInit } from '@angular/core';
import { CheckDeviceService } from '../../../core/http/check-device.service';
import { slideInUpOnEnterAnimation, slideOutLeftOnLeaveAnimation, } from 'angular-animations';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskListService } from '../../../core/http/task-list.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-list-all-tasks',
  templateUrl: './list-all-tasks.component.html',
  styleUrls: ['./list-all-tasks.component.scss'],
  animations: [
    slideInUpOnEnterAnimation(),
    slideOutLeftOnLeaveAnimation()
  ]
})
export class ListAllTasksComponent implements OnInit {

  mobileRes: boolean;
  filterStatut = false;
  className1 = 'zoneContainerShadow';
  className2 = 'zoneContainerShadow';
  firstFilter = 'All zones';
  zoneFilter = ['Africa', 'Asia', 'Baltic Countries', 'Benelux', 'British Isles',
    'Central & Eastern Europe', 'DACH', 'Iberian Peninsula', 'Indian Sub Continent',
    'Latin America', 'Maghreb', 'Middle East', 'Nordic Countries', 'North America',
    'Oceania', 'Russia and CIS', 'South East Asia', 'Southern Europe'];
  config: any;
  taskListTodo = [];
  taskListIP = [];
  taskListDone = [];
  nbItems = 10;

  constructor(
    private checkDeviceService: CheckDeviceService,
    private taskListService: TaskListService,
    private route: ActivatedRoute,
    private router: Router
    ) {
    this.config = {
        currentPage: 1,
        itemsPerPage: this.nbItems
      };

    this.route.queryParamMap.pipe(map(params => params.get('page')))
      .subscribe(page => this.config.currentPage = page);


      // for (let i = 1; i <= 301; i++) {    // A Supprimer, définit nb d'items dans la collection
      // this.collection.push(`item ${i}`);
      // }
  }

  ngOnInit() {
      this.mobileRes = this.checkDeviceService.isMobileResolution;
      this.taskListTodo.push(this.taskListService.taskTodo);
      this.taskListIP.push(this.taskListService.taskIP);
      this.taskListDone.push(this.taskListService.taskDone);
      // console.log(this.taskListTodo);
    }

  checkStatut($event) {
      this.filterStatut = $event;
    }

  pageChange(newPage: number) {
      this.router.navigate(['admin/dashboard/allTasks/'], { queryParams: { page: newPage } });
    }

  onItemsChange(event) {
      this.config.itemsPerPage = event;
    }
}
