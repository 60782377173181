import { Component, OnInit, Input } from '@angular/core';
import { ParamMap, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../../../core/http/company.service'

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit {
  
  constructor(
    private route: ActivatedRoute,
    private companyService: CompanyService
  ) {}

  idCompany: any;
  updateLink: string;
  company: any;
  companyOrder: any;
  dataCompany: any;
  dataCompanyOrder: any;

  async ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.idCompany = params.get('id');
    });
    this.updateLink = '/admin/company/' + this.idCompany + "/edit";
    this.dataCompany = await this.companyService.getCompanyByID(this.idCompany).toPromise();
    this.initCompany();
    this.dataCompanyOrder = await this.companyService.getCompanyOrder(this.idCompany).toPromise();
    this.initCompanyOrder();
  }
  initCompany() {
    this.company = [
      {
        title: 'COMPANY INFORMATION', fields: [
          { title: 'COMPANY', content: this.dataCompany?.company ? this.dataCompany.company : '-' },
          { title: 'GROUP', content: this.dataCompany?.group ? this.dataCompany.group : '-' },
          { title: 'MOBILE', content: this.dataCompany?.telephone ? this.dataCompany.telephone : '-' },
          { title: 'FAX', content: this.dataCompany?.fax ? this.dataCompany.fax : '-' },
          { title: 'WEBSITE', content: this.dataCompany?.website ? this.dataCompany.website : '-' },
        ]
      },
      {
        title: 'INTERNAL INFORMATION', fields: [
          { title: 'COMMENTS', content: this.dataCompany?.comments ? this.dataCompany.comments : '-' },
          { title: 'PROFILE UPDATE', content: this.dataCompany?.profileUpdate == true ? 'True' : 'False' },
        ]
      },
      {
        title: 'PROFILE INFORMATION', fields: [
          { title: 'CHANNEL TYPE', content: this.dataCompany?.channelName ? this.dataCompany.channelName : '-' },
          { title: 'PROFILE TYPE', content: this.dataCompany?.profileType ? this.dataCompany.profileType : '-' },
          { title: 'LOGO URL', content: this.dataCompany?.logoUrl ? this.dataCompany.logoUrl : '-' },
          { title: 'PROFILE URL', content: this.dataCompany?.profileUrl ? this.dataCompany.profileUrl : '-' },
        ]
      },
      {
        fields: [
          { title: '', content: '' },
          { title: '', content: '' },
          { title: 'TWEET PICH', content: this.dataCompany?.tweetPich ? this.dataCompany.tweetPich : '-' },
        ]
      },
      {
        title: 'ATTENDEE ADDRESS', fields: [
          { title: 'ADDRESS', content: this.dataCompany?.address ? this.dataCompany.address : '-' },
          { title: 'CITY', content: this.dataCompany?.city ? this.dataCompany.city : '-' },
          { title: 'ZIP CODE', content: this.dataCompany?.zipCode ? this.dataCompany.zipCode : '-' },
        ]
      },
      {
        fields: [
          { title: '', content: '' },
          { title: '', content: '' },
          { title: 'ADDRESS 2', content: this.dataCompany?.address2 ? this.dataCompany.address2 : '-' },
          { title: 'STATE', content: this.dataCompany?.state ? this.dataCompany.state : '-' },
          { title: 'COUNTRY', content: this.dataCompany?.country ? this.dataCompany.country : '-' },
        ]
      }
    ];
  }
  initCompanyOrder(){
    this.companyOrder = [
      {
        fields: [
          { title: 'ONE-ON-ONE', content: this.dataCompanyOrder?.oneOnOne == true ? "True" : 'False' },
          { title: 'ONE-ON-ONE', content: this.dataCompanyOrder?.nbOne ? this.dataCompanyOrder.nbOne : '-' },
          { title: 'ONE-ON-ONE REQUEST', content: this.dataCompanyOrder?.nbRequest ? this.dataCompanyOrder.nbRequest : '-' },
          { title: 'BOARDROOMS', content: this.dataCompanyOrder?.boardrooms ? this.dataCompanyOrder.boardrooms : '-' },
          { title: 'BOOTH NUMBER', content: this.dataCompanyOrder?.boothNumber ? this.dataCompanyOrder.boothNumber : '-' },
          { title: 'ATTENDANCE TYPE', content: this.dataCompanyOrder?.attendanceType ? this.dataCompanyOrder.attendanceType : '-' },
        ]
      },
    ];
  }
}
