import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DownloadService } from 'src/app/core/http/download.service';
import { EventService } from 'src/app/core/http/event.service';

@Component({
  selector: 'app-list-all-events',
  templateUrl: './list-all-events.component.html',
  styleUrls: ['./list-all-events.component.scss']
})
export class ListAllEventsComponent implements OnInit {

  constructor(
    private eventService: EventService,
    private downloadService: DownloadService,
    private datePipe: DatePipe
  ) { }

  listAllEvents:any;
  currentYear: number;
  dataDownload: any;

  ngOnInit(): void {  
    this.dataDownload = this.downloadService.downloadData();
    this.currentYear = new Date().getFullYear();

    this.eventService.getEventByYear(this.currentYear).subscribe( response => {
      this.listAllEvents = response
    });
  }

  changeYear(year: number){
    this.eventService.getEventByYear(year).subscribe( response => {
      this.listAllEvents = response
    });
  }

  previousYear(){
    this.currentYear = this.currentYear - 1
    this.changeYear(this.currentYear);
  }

  nextYear(){
    this.currentYear = this.currentYear + 1
    this.changeYear(this.currentYear);
  }
  downloadList() {
    this.dataDownload["value"].Name = "event";
    this.dataDownload["value"].TypeFile = "csv";
    this.dataDownload["value"].Year = this.currentYear;
    this.downloadService.exportFile(this.dataDownload["value"]).subscribe(res => {
      let blob: Blob = res.body as Blob;
        let name = res.headers.get('content-disposition');
        let a = document.createElement('a');
        a.download = name.split(';')[1].split('=')[1].replace(/\"/g, '');
        a.href = window.URL.createObjectURL(blob);
      a.click();
      console.log("Success", res);
    }, error => {
      console.log("Error", error);
    });
  }
}
