<div class="row followup-item">
    <div class="col-6 pad_right">
        <div class="w-100 d-flex justify-content-between">
            <a [href]="followup_mail_link"><p class="main_txt">{{data.emailAdress}}</p></a>
        </div>
    </div>
    <div class="col-2 pad_right">
        <p class="main_txt">{{data.firstOpened}}</p>
    </div>
    <div class="col-2">
        <p class="main_txt">{{data.lastOpened}}</p>
    </div>
    <div class="col-2">
        <p class="main_txt">{{data.totalOpening}}</p>
    </div>
    <div class="w-100" style="border-bottom: 2px solid #EFEEE2;margin: 0 15px;"></div>
</div>