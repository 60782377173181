<app-fil-ariane-v2></app-fil-ariane-v2>

<div class="container-fluid font-color pb-4">
    <div class="container body-height">

        <div class="row">

            <div class="col-lg-12 col-md-12  mt-4">
<!-- Desktop-->
<div class="display--nav-none">
    <nav class="nav-responsive-top">
      <div class="nav nav-tabs nav-responsive" id="nav-tab" role="tablist">
        <a class="nav-item nav-link active nowayRegular" id="nav-attendee" data-toggle="tab" href="#attendee"
           role="tab" aria-controls="nav-attendee" aria-selected="true" i18n>Attendee</a>
        <a class="nav-item nav-link nowayRegular" id="nav-participation" data-toggle="tab" href="#participation"
           role="tab" aria-controls="nav-participation" aria-selected="false" i18n>Participation</a>
        <a class="nav-item nav-link nowayRegular" id="nav-travel" data-toggle="tab" href="#travel"
           role="tab" aria-controls="nav-travel" aria-selected="false" i18n>Travel</a>
        <a class="nav-item nav-link nowayRegular" id="nav-hotel" data-toggle="tab" href="#hotel"
           role="tab" aria-controls="nav-hotel" aria-selected="false" i18n>Hotel</a>
      </div>
    </nav>
  
    <div class="tab-content" id="nav-tabContent">
  
      <div class="tab-pane fade show active mt-3" id="attendee" role="tabpanel"
           aria-labelledby="nav-attendee">
  
        <form (ngSubmit)="attendeeInformation.valid && onSubmit()" [formGroup]='attendeeInformation'>
  
          <div class="row mt-3">
            <div class="col-md-5">
              <h6 class="mb-4 mt-3 nowayRegular" style="color: #244746; font-weight: bold;" i18n>ATTENDEE INFORMATION</h6>
  
              <app-form-input>
                <label for="firstNameAttendeeI" class="label col-sm-5 col-form-label" i18n>First Name *</label>
                <input [ngClass]="attendeeInformation.get('firstNameAttendeeI').errors ? 'is-invalid' : 'is-valid'"
                       type="text" class="input form-control" id="firstNameAttendeeI"
                       placeholder="First Name" formControlName="firstNameAttendeeI">
              </app-form-input>
  
              <app-form-input>
                <label for="lastNameAttendeeI" class="label col-sm-5 col-form-label" i18n>Last Name *</label>
                <input [ngClass]="attendeeInformation.get('lastNameAttendeeI').errors ? 'is-invalid' : 'is-valid'"
                       type="text" class="input form-control" id="lastNameAttendeeI"
                       placeholder="Last Name" formControlName="lastNameAttendeeI">
              </app-form-input>
  
              <app-form-select>
                <label for="titleAttendeeI" class="label col-sm-5 col-form-label" i18n>Title</label>
                <select class="select form-control" id="titleAttendeeI" formControlName="titleAttendeeI">
                  <option value="" i18n>Please select</option>
                  <option *ngFor="let title of title">
                    {{ title }}
                  </option>
                </select>
              </app-form-select>
  
              <hr style="border: solid 1px white; margin-top: 31px; margin-bottom: 31px">
  
              <app-form-select>
                <label for="genderAttendeeI" class="label col-sm-5 col-form-label" i18n>Gender</label>
                <select class="select form-control" id="genderAttendeeI" formControlName="genderAttendeeI">
                  <option value="" i18n>Please select</option>
                  <option *ngFor="let gender of gender">
                    {{ gender }}
                  </option>
                </select>
              </app-form-select>
  
              <app-form-input>
                <label for="jobPositionAttendeeI" class="label col-sm-5 col-form-label" i18n>Job position</label>
                <input type="text" class="input form-control" id="jobPositionAttendeeI"
                       placeholder="Job position" formControlName="jobPositionAttendeeI">
              </app-form-input>
  
              <app-form-select>
                <label for="functionSelectAttendeeI" class="label col-sm-5 col-form-label" i18n>Function</label>
                <select class="select form-control" id="functionSelectAttendeeI"
                        formControlName="functionSelectAttendeeI">
                  <option value="" i18n>Please select</option>
                  <option *ngFor="let functionSelect of functionSelect">
                    {{ functionSelect }}
                  </option>
                </select>
              </app-form-select>
  
              <app-form-input>
                <label for="functionInputAttendeeI" class="label col-sm-5 col-form-label" i18n>Function</label>
                <input type="text" class="input form-control" id="functionInputAttendeeI"
                       placeholder="Function" formControlName="functionInputAttendeeI">
              </app-form-input>
  
              <hr class="mt-4 mb-4" style="border: solid 1px white; margin-top: 31px; margin-bottom: 31px">
  
              <app-form-input>
                <label for="directPhoneAttendeeI" class="label col-sm-5 col-form-label" i18n>Direct phone</label>
                <input type="phone" class="input form-control" id="directPhoneAttendeeI"
                       placeholder="Direct phone" formControlName="directPhoneAttendeeI">
              </app-form-input>
  
              <app-form-input>
                <label for="mobileAttendeeI" class="label col-sm-5 col-form-label" i18n>Mobile</label>
                <input type="phone" class="input form-control" id="mobileAttendeeI"
                       placeholder="Mobile" formControlName="mobileAttendeeI">
              </app-form-input>
  
              <app-form-input>
                <label for="mobileTwoAttendeeI" class="label col-sm-5 col-form-label" i18n>Mobile 2</label>
                <input type="text" class="input form-control" id="mobileTwoAttendeeI"
                       placeholder="Mobile 2" formControlName="mobileTwoAttendeeI">
              </app-form-input>
  
              <app-form-input>
                <label for="emailAttendeeI" class="label col-sm-5 col-form-label" i18n>Email *</label>
                <input [ngClass]="attendeeInformation.get('emailAttendeeI').errors ? 'is-invalid' : 'is-valid'"
                       type="email" class="input form-control" id="emailAttendeeI"
                       placeholder="Email" formControlName="emailAttendeeI">
              </app-form-input>
  
  
            </div>
  
            <div class="offset-md-1 offset-xs-0 col-md-5">
              <h6 class="mt-3 mb-4 nowayRegular" style="color: #244746; font-weight: bold; " i18n>ASSISTANT INFORMATION</h6>
  
              <app-form-checkbox>
                <input class="check form-check-input mt-2" type="checkbox" value=""
                       id="AssistantCopyCorrespondence" formControlName="AssistantCopyCorrespondence">
                <label class="label form-check-label mb-4 ml-2" for="AssistantCopyCorrespondence">
                  <small style="font-size: 16px" i18n>Assistant receives a copy of all correspondence</small>
                </label>
              </app-form-checkbox>
  
  
              <app-form-input>
                <label for="firstNameAssistant" class="label col-sm-5 col-form-label" i18n>First Name *</label>
                <input [ngClass]="attendeeInformation.get('firstNameAssistant').errors ? 'is-invalid' : 'is-valid'"
                       type="text" class="input form-control" id="firstNameAssistant"
                       placeholder="First Name" formControlName="firstNameAssistant">
              </app-form-input>
  
              <app-form-input>
                <label for="lastNameAssistant" class="label col-sm-5 col-form-label" i18n>Last Name *</label>
                <input [ngClass]="attendeeInformation.get('lastNameAssistant').errors ? 'is-invalid' : 'is-valid'"
                       type="text" class="input form-control" id="lastNameAssistant"
                       placeholder="Last Name" formControlName="lastNameAssistant">
              </app-form-input>
  
              <app-form-select>
                <label for="titleAssistant" class="label col-sm-5 col-form-label" i18n>Title</label>
                <select class="select form-control" id="titleAssistant"
                        formControlName="titleAssistant">
                  <option value="" i18n>Please select</option>
                  <option *ngFor="let title of title">
                    {{ title }}
                  </option>
                </select>
              </app-form-select>
  
              <app-form-input>
                <label for="jobPositionAssistant" class="label col-sm-5 col-form-label" i18n>Job Position</label>
                <input type="text" class="input form-control" id="jobPositionAssistant"
                       placeholder="Job Position" formControlName="jobPositionAssistant">
              </app-form-input>
  
              <app-form-input>
                <label for="directTelephoneAssistant" class="label col-sm-5 col-form-label" i18n>Direct
                  Telephone</label>
                <input type="phone" class="input form-control" id="directTelephoneAssistant"
                       placeholder="Direct Telephone" formControlName="directTelephoneAssistant">
              </app-form-input>
  
              <app-form-input>
                <label for="mobileAssistant" class="label col-sm-5 col-form-label" i18n>Mobile</label>
                <input type="phone" class="input form-control" id="mobileAssistant"
                       placeholder="Mobile" formControlName="mobileAssistant">
              </app-form-input>
  
              <app-form-input>
                <label for="emailAssistant" class="label col-sm-5 col-form-label" i18n>Email *</label>
                <input [ngClass]="attendeeInformation.get('emailAssistant').errors ? 'is-invalid' : 'is-valid'"
                       type="email" class="input form-control" id="emailAssistant"
                       placeholder="Email" formControlName="emailAssistant">
              </app-form-input>
  
            </div>
          </div>
  
          <hr class="mt-5 mb-5">
  
          <h6 class="nowayRegular mb-5" style="color: #244746; font-weight: bold" i18n>ATTENDEE ADDRESS</h6>
  
          <div class="row mt-3">
  
            <div class="col-md-5">
  
              <app-form-input>
                <label for="addressOneAttendeeA" class="label col-sm-5 col-form-label" i18n>Address 1</label>
                <input type="text" class="input form-control" id="addressOneAttendeeA"
                       placeholder="Address 1" formControlName="addressOneAttendeeA">
              </app-form-input>
  
              <app-form-input>
                <label for="cityAttendeeA" class="label col-sm-5 col-form-label" i18n>City</label>
                <input type="text" class="input form-control" id="cityAttendeeA"
                       placeholder="City" formControlName="cityAttendeeA">
              </app-form-input>
  
              <app-form-input>
                <label for="zipCodeAttendeeA" class="label col-sm-5 col-form-label" i18n>Zip Code</label>
                <input type="text" class="input form-control" id="zipCodeAttendeeA"
                       placeholder="ZipCode" formControlName="zipCodeAttendeeA">
              </app-form-input>
  
            </div>
  
            <div class="offset-md-1 offset-xs-0 col-md-5">
  
              <app-form-input>
                <label for="addressTwoAttendeeA" class="label col-sm-5 col-form-label" i18n>Address 2</label>
                <input type="text" class="input form-control" id="addressTwoAttendeeA"
                       placeholder="Address 2" formControlName="addressTwoAttendeeA">
              </app-form-input>
  
              <app-form-input>
                <label for="stateAttendeeA" class="label col-sm-5 col-form-label" i18n>State</label>
                <input type="text" class="input form-control" id="stateAttendeeA"
                       placeholder="State" formControlName="stateAttendeeA">
              </app-form-input>
  
              <app-form-select>
                <label for="countryAttendeeA" class="label col-sm-5 col-form-label" i18n>Country</label>
                <select class="select form-control" id="countryAttendeeA"
                        formControlName="countryAttendeeA">
                  <option value="" i18n>Please select</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </app-form-select>
  
            </div>
  
          </div>
  
          <hr class="mt-5 mb-5">
  
          <h6 class="nowayRegular mb-5" style="color: #244746; font-weight: bold" i18n>EMERGENCY CONTACT</h6>
  
          <div class="row mt-3">
  
            <div class="col-md-5">
  
              <app-form-input>
                <label for="contactNameEmergency" class="label col-sm-5 col-form-label" i18n>Contact name</label>
                <input type="text" class="input form-control" id="contactNameEmergency"
                       placeholder="Contact name" formControlName="contactNameEmergency">
              </app-form-input>
  
            </div>
  
            <div class="offset-md-1 offset-xs-0 col-md-5">
  
              <app-form-input>
                <label for="contactNumberEmergency" class="label col-sm-5 pr-0 col-form-label" i18n>Contact
                  number</label>
                <input type="text" class="input form-control" id="contactNumberEmergency"
                       placeholder="Contact number" formControlName="contactNumberEmergency">
              </app-form-input>
  
            </div>
  
          </div>
  
          <!--<hr>
          <h6 class="nowayRegular">Profile information</h6>
          <div class="row mt-3">
              <div class="col-md-12">
                  <div class="form-group row">
                      <label for="uploadPicture" class="col-sm-2 col-form-label">Upload picture</label>
                      <div class="col-sm-8 mt-1">
                          <input type="file" class="form-control-file" id="uploadPicture"
                                 formControlName="uploadPicture">
                      </div>
                  </div>
              </div>
          </div>-->
  
          <div class="form-group row mt-4 mb-5">
            <div class="col-sm-12 text-center mt-4">
              <button type="button" class="btn btn-success button--color btn-size btn-responsive mr-3" i18n>
                Cancel
              </button>
              <button type="submit" class="btn btn-success button--color btn-size btn-responsive"
                      [disabled]="!attendeeInformation.valid" i18n>Save
              </button>
            </div>
          </div>
  
        </form>
  
      </div>
  
      <div class="tab-pane fade mt-3" id="participation" role="tabpanel" aria-labelledby="nav-participation">
  
  
        <form (ngSubmit)="eventInformation.valid && onSubmit()" [formGroup]='eventInformation'>
  
          <div class="row mt-3">
  
            <div class="col-md-5">
              <h6 class="mb-4 mt-3 nowayRegular" style="color: #244746; font-weight: bold" i18n>EVENT INFORMATION</h6>
  
              <app-form-select>
                <label for="pass" class="label col-sm-5 col-form-label" i18n>Pass</label>
                <select class="select form-control" id="pass"
                        formControlName="pass">
                  <option value="" i18n>None</option>
                </select>
              </app-form-select>
  
              <app-form-select>
                <label for="status" class="label col-sm-5 col-form-label" i18n>Status</label>
                <select class="select form-control" id="status"
                        formControlName="status">
                  <option value="" i18n>Please select</option>
                </select>
              </app-form-select>
  
              <app-form-radio>
                <h6 class="title float-left" i18n>Main</h6>
                <input class="radioTrue form-check-input" type="radio" id="mainTrue" value="true"
                       formControlName="main">
                <label class="labelTrue form-check-label" for="mainTrue" i18n>Yes</label>
                <input class="radioFalse form-check-input" type="radio" id="mainFalse" value="false"
                       formControlName="main">
                <label class="labelFalse form-check-label" for="mainFalse" i18n>No</label>
              </app-form-radio>
  
              <app-form-radio>
                <h6 class="title float-left" i18n>Free</h6>
                <input class="radioTrue form-check-input" type="radio" id="freeTrue" value="true"
                       formControlName="free">
                <label class="labelTrue form-check-label" for="freeTrue" i18n>Yes</label>
                <input class="radioFalse form-check-input" type="radio" id="freeFalse" value="false"
                       formControlName="free">
                <label class="labelFalse form-check-label" for="freeFalse" i18n>No</label>
              </app-form-radio>
  
              <app-form-radio>
                <h6 class="title float-left" i18n>One-On-One</h6>
                <input class="radioTrue form-check-input" type="radio" id="oneOnOneTrue" value="true"
                       formControlName="oneOnOne">
                <label class="labelTrue form-check-label" for="oneOnOneTrue" i18n>Yes</label>
                <input class="radioFalse form-check-input" type="radio" id="oneOnOneFalse" value="false"
                       formControlName="oneOnOne">
                <label class="labelFalse form-check-label" for="oneOnOneFalse" i18n>No</label>
              </app-form-radio>
  
              <app-form-radio>
                <h6 class="title float-left" i18n>Vip</h6>
                <input class="radioTrue form-check-input" type="radio" id="vipTrue" value="true"
                       formControlName="vip">
                <label class="labelTrue form-check-label" for="vipTrue" i18n>Yes</label>
                <input class="radioFalse form-check-input" type="radio" id="vipFalse" value="false"
                       formControlName="vip">
                <label class="labelFalse form-check-label" for="vipFalse" i18n>No</label>
              </app-form-radio>
  
              <app-form-radio>
                <h6 class="title float-left" i18n>Speaker</h6>
                <input class="radioTrue form-check-input" type="radio" id="speakerTrue" value="true"
                       formControlName="speaker">
                <label class="labelTrue form-check-label" for="speakerTrue" i18n>Yes</label>
                <input class="radioFalse form-check-input" type="radio" id="speakerFalse" value="false"
                       formControlName="speaker">
                <label class="labelFalse form-check-label" for="speakerFalse" i18n>No</label>
              </app-form-radio>
  
              <app-form-radio>
                <h6 class="title float-left" i18n>Hotel</h6>
                <input class="radioTrue form-check-input" type="radio" id="hotelTrue" value="true"
                       formControlName="hotel">
                <label class="labelTrue form-check-label" for="hotelTrue" i18n>Yes</label>
                <input class="radioFalse form-check-input" type="radio" id="hotelFalse" value="false"
                       formControlName="hotel">
                <label class="labelFalse form-check-label" for="hotelFalse" i18n>No</label>
              </app-form-radio>
  
              <app-form-radio>
                <h6 class="title float-left" i18n>Travel</h6>
                <input class="radioTrue form-check-input" type="radio" id="travelTrue" value="true"
                       formControlName="travel">
                <label class="labelTrue form-check-label" for="travelTrue" i18n>Yes</label>
                <input class="radioFalse form-check-input" type="radio" id="travelFalse" value="false"
                       formControlName="travel">
                <label class="labelFalse form-check-label" for="travelFalse" i18n>No</label>
              </app-form-radio>
  
              <app-form-radio>
                <h6 class="title float-left" i18n>Do Not Post Email</h6>
                <input class="radioTrue form-check-input" type="radio" id="doNotPostEmailTrue" value="true"
                       formControlName="doNotPostEmail">
                <label class="labelTrue form-check-label" for="doNotPostEmailTrue" i18n>Yes</label>
                <input class="radioFalse form-check-input" type="radio" id="doNotPostEmailFalse"
                       value="false" formControlName="doNotPostEmail">
                <label class="labelFalse form-check-label" for="doNotPostEmailFalse" i18n>No</label>
              </app-form-radio>
  
              <app-form-radio>
                <h6 class="title float-left" i18n>Incident</h6>
                <input class="radioTrue form-check-input" type="radio" id="incidentTrue" value="true"
                       formControlName="incident">
                <label class="labelTrue form-check-label" for="incidentTrue" i18n>Yes</label>
                <input class="radioFalse form-check-input" type="radio" id="incidentFalse" value="false"
                       formControlName="incident">
                <label class="labelFalse form-check-label" for="incidentFalse" i18n>No</label>
              </app-form-radio>
  
              <app-form-radio>
                <h6 class="title float-left" i18n>Profile Update</h6>
                <input class="radioTrue form-check-input" type="radio" id="profileUpdateTrue" value="true"
                       formControlName="profileUpdate">
                <label class="labelTrue form-check-label" for="profileUpdateTrue" i18n>Yes</label>
                <input class="radioFalse form-check-input" type="radio" id="profileUpdateFalse"
                       value="false" formControlName="profileUpdate">
                <label class="labelFalse form-check-label" for="profileUpdateFalse" i18n>No</label>
              </app-form-radio>
  
              <app-form-select>
                <label for="speakerType" class="label col-sm-5 col-form-label" i18n>Speaker Type</label>
                <select class="select form-control" id="speakerType"
                        formControlName="speakerType">
                  <option value="" i18n>Please select</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </app-form-select>
  
            </div>
  
  
            <div class="offset-md-1 offset-xs-0 col-md-5">
              <h6 class="mb-4 mt-3 nowayRegular" style="color: #244746; font-weight: bold" i18n>INTERNAL INFORMATION</h6>
  
              <div class="form-group">
                <label for="generalComments" i18n>General comments</label>
                <textarea
                        [ngClass]="eventInformation.get('generalComments').errors ? 'is-invalid' : 'is-valid'"
                        class="form-control" id="generalComments" formControlName="generalComments"
                        rows="5"></textarea>
                <small i18n>Characters (Max. 1000)</small>
              </div>
  
              <app-form-input>
                <label for="couponUsed" class="label col-sm-5 col-form-label" i18n>Coupon Used</label>
                <input type="text" class="input form-control" id="couponUsed"
                       placeholder="Coupon Used" formControlName="couponUsed">
              </app-form-input>
  
            </div>
  
  
          </div>
  
          <div class="form-group row">
            <div class="col-sm-12 text-center mt-4">
              <button type="button" class="btn btn-success button--color btn-size btn-responsive mr-3" i18n>
                Cancel
              </button>
              <button type="submit" class="btn btn-success button--color btn-size btn-responsive"
                      [disabled]="!eventInformation.valid" i18n>Save
              </button>
            </div>
          </div>
  
        </form>
  
      </div>
  
      <div class="tab-pane fade mt-3" id="travel" role="tabpanel" aria-labelledby="nav-travel">
  
        <form (ngSubmit)="travelDetails.valid && onSubmit()" [formGroup]='travelDetails'>
  
          <div class="row mt-3">
  
            <div class="col-md-5">
              <h6 class="mb-4 mt-3 nowayRegular" style="color: #244746; font-weight: bold" i18n>ARRIVAL DETAILS</h6>
  
              <app-form-select>
                <label for="travelOption" class="label col-sm-5 col-form-label" i18n>Travel option</label>
                <select class="select form-control" id="travelOption"
                        formControlName="travelOption">
                  <option value="" i18n>Please select</option>
                </select>
              </app-form-select>
  
              <!-- date time -->
  
  
            </div>
  
            <div class="offset-md-1 offset-xs-0 col-md-5">
              <h6 class="mb-4 mt-3 nowayRegular" style="color: #244746; font-weight: bold" i18n>DEPARTURE DETAILS</h6>
  
              <app-form-select>
                <label for="travelOptionD" class="label col-sm-5 col-form-label" i18n>Travel option</label>
                <select class="select form-control" id="travelOptionD"
                        formControlName="travelOptionD">
                  <option value="" i18n>Please select</option>
                </select>
              </app-form-select>
  
              <!-- date time -->
  
            </div>
  
          </div>
  
          <hr class="mt-5 mb-5">
  
          <h6 class="mt-4 nowayRegular" style="color: #244746; font-weight: bold" i18n>TRAVEL EVENT</h6>
  
          <div class="row mt-3">
  
            <div class="col-md-5">
  
              <app-form-input>
                <label for="departureCity" class="label col-sm-5 col-form-label" i18n>Departure City</label>
                <input type="text" class="input form-control" id="departureCity"
                       placeholder="Departure City" formControlName="departureCity">
              </app-form-input>
  
              <app-form-input>
                <label for="preferredDepartureAirportStation" class="label col-sm-5 col-form-label" i18n>Preferred
                  Departure Airport/Station</label>
                <input type="text" class="input form-control" id="preferredDepartureAirportStation"
                       placeholder="Preferred Departure Airport/Station"
                       formControlName="preferredDepartureAirportStation">
              </app-form-input>
  
              <app-form-input>
                <label for="frequentFlyerNumber" class="label col-sm-5 col-form-label" i18n>Frequent Flyer
                  Number</label>
                <input type="text" class="input form-control" id="frequentFlyerNumber"
                       placeholder="Frequent Flyer Number" formControlName="frequentFlyerNumber">
              </app-form-input>
  
              <app-form-checkbox>
                <input class="check form-check-input" type="checkbox" id="readOnly"
                       formControlName="readOnly">
                <label class="label form-check-label mb-3" for="readOnly" i18n>
                  Read only
                </label>
              </app-form-checkbox>
  
            </div>
  
            <div class="offset-md-1 offset-xs-0 col-md-5">
  
              <app-form-select>
                <label for="departureCountry" class="label col-sm-5 col-form-label" i18n>Departure
                  Country</label>
                <select class="select form-control" id="departureCountry"
                        formControlName="departureCountry">
                  <option value="" i18n>Please select</option>
                </select>
              </app-form-select>
  
              <app-form-input>
                <label for="preferredAirline" class="label col-sm-5 col-form-label" i18n>Preferred
                  Airline</label>
                <input type="text" class="input form-control" id="preferredAirline"
                       placeholder="Preferred Airline" formControlName="preferredAirline">
              </app-form-input>
  
              <app-form-input>
                <label for="alternativeArrivalOrDepartureDate" class="label col-sm-5 col-form-label" i18n>Alternative
                  Arrival Or DepartureDate</label>
                <input type="text" class="input form-control" id="alternativeArrivalOrDepartureDate"
                       placeholder="Alternative Arrival Or DepartureDate"
                       formControlName="alternativeArrivalOrDepartureDate">
              </app-form-input>
  
            </div>
  
          </div>
  
          <hr class="mt-5 mb-5">
  
          <h6 class="mt-4 mt-3 nowayRegular" style="color: #244746; font-weight: bold" i18n>OTHER INFORMATION</h6>
  
          <div class="row mt-3">
  
            <div class="col-md-5">
  
              <app-form-select>
                <label for="dietaryRequirements" class="label col-sm-5 col-form-label" i18n>Dietary
                  Requirements</label>
                <select class="select form-control" id="dietaryRequirements"
                        formControlName="dietaryRequirements">
                  <option value="" i18n>Please select</option>
                </select>
              </app-form-select>
  
            </div>
  
            <div class="offset-md-1 offset-xs-0 col-md-5">
  
              <app-form-input>
                <label for="otherRequirements" class="label col-sm-5 col-form-label" i18n>Other
                  Requirements</label>
                <input type="text" class="input form-control" id="otherRequirements"
                       placeholder="Other Requirements" formControlName="otherRequirements">
              </app-form-input>
  
            </div>
  
          </div>
  
  
          <hr class="mt-5 mb-5">
  
          <h6 class="mt-4 nowayRegular" style="color: #244746; font-weight: bold" i18n>HOTEL DETAILS</h6>
  
          <div class="row mt-3">
  
            <div class="col-md-5">
  
              <!--  date time-->
  
              <app-form-select>
                <label for="roomType" class="label col-sm-5 col-form-label" i18n>Room Type</label>
                <select class="select form-control" id="roomType"
                        formControlName="roomType">
                  <option value="" i18n>Please select</option>
                </select>
              </app-form-select>
  
              <app-form-checkbox>
                <input class="check form-check-input" type="checkbox" id="noAccommodation"
                       formControlName="noAccommodation">
                <label class="label form-check-label mb-3" for="noAccommodation" i18n>
                  No accommodation
                </label>
              </app-form-checkbox>
  
            </div>
  
            <div class="offset-md-1 offset-xs-0 col-md-5">
  
              <!--date time-->
  
            </div>
  
          </div>
  
  
          <div class="form-group row">
            <div class="col-sm-12 text-center mt-4">
              <button type="button" class="btn btn-success button--color btn-size btn-responsive mr-3" i18n>
                Cancel
              </button>
              <button type="submit" class="btn btn-success button--color btn-size btn-responsive"
                      [disabled]="!travelDetails.valid" i18n>Save
              </button>
            </div>
          </div>
  
        </form>
  
      </div>
  
      <div class="tab-pane fade mt-3" id="hotel" role="tabpanel"
           aria-labelledby="nav-hotel">
  
        <form (ngSubmit)="hotelBooking.valid && onSubmit()" [formGroup]='hotelBooking'>
  
  
          <div class="row mt-3">
            <div class="col-md-12">
  
              <h6 class="nowayRegular mt-3" style="color: #244746; font-weight: bold" i18n>ADMIN HOTEL RESERVATION </h6>
  
              <app-form-select>
                <label for="hotelBooking" class="label col-sm-5 col-form-label" i18n>Hotel</label>
                <select class="select form-control" id="hotelBooking"
                        formControlName="hotelBooking">
                  <option value="" i18n>Please select</option>
                </select>
              </app-form-select>
  
              <app-form-select>
                <label for="roomBooking" class="label col-sm-5 col-form-label" i18n>Room</label>
                <select class="select form-control" id="roomBooking"
                        formControlName="roomBooking">
                  <option value="" i18n>Please select</option>
                </select>
              </app-form-select>
  
              <div class="form-group">
                <label for="commentsBooking" i18n>Additional Comments: </label>
                <textarea
                        [ngClass]="eventInformation.get('generalComments').errors ? 'is-invalid' : 'is-valid'"
                        class="form-control" id="commentsBooking" formControlName="commentsBooking"
                        rows="5"></textarea>
                <small i18n>Characters (Max. 1000)</small>
              </div>
  
              <app-form-checkbox>
                <input class="check form-check-input" type="checkbox" id="notifyAttendee"
                       formControlName="notifyAttendee">
                <label class="label form-check-label mb-3" for="notifyAttendee" i18n>
                  Notify attendee
                </label>
              </app-form-checkbox>
  
              <div class="form-group row">
                <div class="col-sm-12 text-center mt-4">
                  <button type="button" class="btn btn-success button--color btn-size btn-responsive mr-3" i18n>
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-success button--color btn-size btn-responsive"
                          [disabled]="!hotelBooking.valid" i18n>Save
                  </button>
                </div>
              </div>
  
            </div>
  
          </div>
  
        </form>
  
  
      </div>
  
    </div>
  </div>
  <!-- Fin composant -->
</div>

</div>

</div>
</div>
  
  <!-- Mobile -->
  <div class="accordion display--collapse-none mt-3" id="accordionExample">
    <div class="">
      <div id="headingOne">
        <h2 class="mb-0">
          <button class="btn btn-link pl-0" type="button" data-toggle="collapse" data-target="#collapseOne"
                  aria-expanded="false" aria-controls="collapseOne" i18n>
            Attendee
          </button>
          <svg class="float-right" id="AddThings" height="44px" width="44px" data-toggle="collapse"
               data-target="#collapseOne" aria-haspopup="true"
               aria-expanded="false" style="fill: #84C000; margin-top: 12px">
            <use xlink:href="#plus"/>
          </svg>
        </h2>
      </div>
  
      <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div class="card-body pt-2">
          1
        </div>
      </div>
    </div>
    <div class="mt-3">
      <div id="headingTwo">
        <h2 class="mb-0">
          <button class="btn btn-link pl-0 collapsed" type="button" data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false" aria-controls="collapseTwo" i18n>
            Participation
          </button>
          <svg class="float-right" id="AddThings" height="44px" width="44px" data-toggle="collapse"
               data-target="#collapseTwo" aria-haspopup="true"
               aria-expanded="false" style="fill: #84C000; margin-top: 12px">
            <use xlink:href="#plus"/>
          </svg>
        </h2>
      </div>
      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
        <div class="card-body">
          2
        </div>
      </div>
    </div>
    <div class="mt-3">
      <div id="headingThree">
        <h2 class="mb-0">
          <button class="btn btn-link pl-0 collapsed" type="button" data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false" aria-controls="collapseThree" i18n>
            Travel
          </button>
          <svg class="float-right" id="AddThings" height="44px" width="44px" data-toggle="collapse"
               data-target="#collapseThree" aria-haspopup="true"
               aria-expanded="false" style="fill: #84C000; margin-top: 12px">
            <use xlink:href="#plus"/>
          </svg>
        </h2>
      </div>
      <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
        <div class="card-body">
          3
        </div>
      </div>
    </div>
    <div class="mt-3">
      <div id="headingFive">
        <h2 class="mb-0">
          <button class="btn btn-link pl-0 collapsed" type="button" data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="false" aria-controls="collapseFive" i18n>
            Hotel
          </button>
          <svg class="float-right" id="AddThings" height="44px" width="44px" data-toggle="collapse"
               data-target="#collapseFive" aria-haspopup="true"
               aria-expanded="false" style="fill: #84C000; margin-top: 12px">
            <use xlink:href="#plus"/>
          </svg>
        </h2>
      </div>
      <div id="collapseFive" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
        <div class="card-body">
          4
        </div>
      </div>
    </div>
  </div>