<app-fil-ariane-v2></app-fil-ariane-v2>
<div class="container-fluid font-color">
  <div class="maincontainer">
    <div class="row">
      <div class="col-xl-9 col-lg-12 col-md-12 mt-4">
        <app-company-header></app-company-header>
        <app-company-edit-form></app-company-edit-form>
      </div>
      <div class="col-xl-3 col-lg-12 col-md-12 mt-4">
        <app-company-attendee-card></app-company-attendee-card>
      </div>
    </div>
  </div>
</div>

