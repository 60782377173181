import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventService } from 'src/app/core/http/event.service';
import { RefService } from 'src/app/core/http/ref.service';
import { DateService } from 'src/app/core/http/date.service';
import { ImagesHandlerService } from 'src/app/core/http/images-handler.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct, NgbTimeStruct  } from '@ng-bootstrap/ng-bootstrap/';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-events-form',
  templateUrl: './add-events-form.component.html',
  styleUrls: ['./add-events-form.component.scss']
})
export class AddEventsFormComponent implements OnInit {

  constructor() {}

  ngOnInit() {};

  // getEventInfoUpdate(currentEvent) {
  //   this.addEventFormGroup.patchValue({
  //     EventName: currentEvent.eventName,
  //     EventType: currentEvent.idType,
  //     Location: currentEvent.location,
  //     Capacity: currentEvent.capacity,
  //     Organizer: currentEvent.organizer,

  //     IdLogo: currentEvent.logo,
  //     IdLogoBanner: currentEvent.logoBanner,
  //     IdFooter: currentEvent.footer,
      
  //     EventDate: currentEvent.EventDate,
  //     StartDate: this.DateService.formatToNgbDateStruct(currentEvent.startDate),
  //     StartHour: this.DateService.formatToNgbTimeStruct(currentEvent.startDate),
  //     EndDate: this.DateService.formatToNgbDateStruct(currentEvent.endDate),
  //     EndHour: this.DateService.formatToNgbTimeStruct(currentEvent.endDate),
  //     StartDateRegistration: this.DateService.formatToNgbDateStruct(currentEvent.startDateRegistration),
  //     StartRegistrationHour: this.DateService.formatToNgbTimeStruct(currentEvent.startDateRegistration),
  //     EndDateRegistration: this.DateService.formatToNgbDateStruct(currentEvent.endDateRegistration),
  //     EndRegistrationHour: this.DateService.formatToNgbTimeStruct(currentEvent.endDateRegistration),
  //     IdTimezone: currentEvent.idTimezone,
  //     replyTo: currentEvent.replyTo,
  //     TypeParticipant: [''],
  //     pitch: currentEvent.pitch,
  //     description: currentEvent.description,
  //     loginTxt: currentEvent.loginTxt,
  //   });
  // }

  // getEventContactUpdate(currentEvent){
  //   this.addEventFormGroup.patchValue({
  //     ContactInformation: currentEvent.contactInformation,
  //   })
  // }

  // getEventMenuUpdate(currentEvent){
  //   this.addEventFormGroup.patchValue({
  //     OpenMenuLive: currentEvent.openLive,
  //     PlaceholderMenuLive: currentEvent.placeholder,
  //     DateOpenMenuLive: this.DateService.formatToNgbDateStruct(currentEvent.liveDate),
  //     HourOpenMenuLive: this.DateService.formatToNgbTimeStruct(currentEvent.liveDate),
  //     LinkMenuLive: currentEvent.liveDate,
  //     OpenMenuNetworking: currentEvent.openNetworking
  //   })
  // }

  // onChange(short: string, isChecked: boolean) {
  //   for (let i = 0; this.participantType.length > i; i++) {
  //     if (this.participantType[i].Libelle == short) {
  //       !isChecked ? this.participantType[i].Checked = false :  this.participantType[i].Checked = true
  //     }
  //   }
  //   console.log(this.participantType)
  // }

  // selectAll() {
  //   document.querySelectorAll<HTMLElement>('.click_input').forEach(ele => ele.click());
  // }
  
  // async onSubmit() {
  //   if (!this.isUpdate){
  //     var tab = [-1, -1, -1];
  //     // 1st post image, Then await id_image in response
  //     if (this.listOfimages.every(e => e === null)) {
  //       console.log('no images');
  //     } else{
  //       for (let i = 0; i < this.listOfimages.length; i++){
  //         if (this.listOfimages[i] !== null){
  //           const response = await this.http.request(this.ImagesHandlerService.uploadImage(this.listOfimages[i])).toPromise();
  //           response["body"] ? (tab[i] = response["body"].idImage) : null;
  //         }
  //       }
  //     }
  //     //Init value at 0 if is null or empty
  //     if (this.addEventFormGroup["value"].Capacity == "" || this.addEventFormGroup["value"].Capacity == null) {
  //       this.addEventFormGroup["value"].Capacity = 0;
  //     }
  //     if (this.addEventFormGroup["value"].IdTimezone == "" || this.addEventFormGroup["value"].IdTimezone == null) {
  //       this.addEventFormGroup["value"].IdTimezone = 0;
  //     }
  //     //Init TypeParticipant
  //     this.addEventFormGroup["value"].TypeParticipant = this.participantType;
  //     //Init id image in the formGroup
  //     this.addEventFormGroup["value"].IdLogo = tab[0];
  //     this.addEventFormGroup["value"].IdLogoBanner = tab[1];
  //     this.addEventFormGroup["value"].IdFooter = tab[2];
  //     //Parse datepicker toString()
  //     this.addEventFormGroup["value"].StartDate = this.DateService.datePickerToString(this.addEventFormGroup["value"].StartDate);
  //     this.addEventFormGroup["value"].EventDate = this.addEventFormGroup["value"].StartDate;
  //     this.addEventFormGroup["value"].EndDate = this.DateService.datePickerToString(this.addEventFormGroup["value"].EndDate);
  //     this.addEventFormGroup["value"].StartDateRegistration = this.DateService.datePickerToString(this.addEventFormGroup["value"].StartDateRegistration);
  //     this.addEventFormGroup["value"].EndDateRegistration = this.DateService.datePickerToString(this.addEventFormGroup["value"].EndDateRegistration);
  //     this.addEventFormGroup["value"].StartHour = this.DateService.timePickerToString(this.addEventFormGroup["value"].StartHour);
  //     this.addEventFormGroup["value"].StartRegistrationHour = this.DateService.timePickerToString(this.addEventFormGroup["value"].StartRegistrationHour);
  //     this.addEventFormGroup["value"].EndHour = this.DateService.timePickerToString(this.addEventFormGroup["value"].EndHour);
  //     this.addEventFormGroup["value"].EndRegistrationHour = this.DateService.timePickerToString(this.addEventFormGroup["value"].EndRegistrationHour);
  //     // Send values
  //     this.EventService.addEvent(this.addEventFormGroup["value"]);
  //   } else {
  //     // console.log(this.addEventFormGroup["value"])
  //     let IdTimezone: number = +this.addEventFormGroup["value"].IdTimezone

  //     this.addEventFormGroup["value"].TypeParticipant = this.participantType;
  //     this.addEventFormGroup["value"].IdLogo = 0
  //     this.addEventFormGroup["value"].IdLogoBanner = 0
  //     this.addEventFormGroup["value"].IdFooter = 0
  //     this.addEventFormGroup["value"].IdTimezone = IdTimezone

  //     this.addEventFormGroup["value"].EventDate = this.DateService.datePickerToString(this.addEventFormGroup["value"].StartDate);
  //     this.addEventFormGroup["value"].StartDate = this.DateService.datePickerToString(this.addEventFormGroup["value"].StartDate);
  //     this.addEventFormGroup["value"].EndDate = this.DateService.datePickerToString(this.addEventFormGroup["value"].EndDate);
  //     this.addEventFormGroup["value"].StartDateRegistration = this.DateService.datePickerToString(this.addEventFormGroup["value"].StartDateRegistration);
  //     this.addEventFormGroup["value"].EndDateRegistration = this.DateService.datePickerToString(this.addEventFormGroup["value"].EndDateRegistration);
  //     this.addEventFormGroup["value"].StartHour = this.DateService.timePickerToString(this.addEventFormGroup["value"].StartHour);
  //     this.addEventFormGroup["value"].StartRegistrationHour = this.DateService.timePickerToString(this.addEventFormGroup["value"].StartRegistrationHour);
  //     this.addEventFormGroup["value"].EndHour = this.DateService.timePickerToString(this.addEventFormGroup["value"].EndHour);
  //     this.addEventFormGroup["value"].EndRegistrationHour = this.DateService.timePickerToString(this.addEventFormGroup["value"].EndRegistrationHour);

  //     let res = this.EventService.updateEvent(this.idEvent, this.addEventFormGroup["value"])
  //     if(this.addEventFormGroup["value"].ContactInformation !== this.currentEventContact.contactInformation){
  //       let res2 = this.EventService.updateEventContact(this.idEvent, {contactInformation: this.addEventFormGroup["value"].ContactInformation})
  //       console.log(res2)
  //     }
  //     console.log(res)
  //     this.toastr.clear();
  //     this.toastr.success('Success', `${this.addEventFormGroup["value"].EventName}`);
  //     this.router.navigateByUrl(`/admin/agenda/events/${this.idEvent}/details`);
  
  //   }
  // }

  // handleFileInput(files: FileList, index: number) {
  //   if (files.length > 0) {
  //     this.uploadFile = files.item(0);
  //     this.listOfimages[index] = this.uploadFile;
  //   }
  //   console.log(this.listOfimages)
  // }
}