<div class="row body">
    <div class="col-lg-3 col-sm-2 col-xs-1 svgIcon" (click)="swipTask(task)" style="text-align: end; padding-right: 0px; cursor: pointer; height: 80px;"> <!--(click)="status(1, taskTodo)"-->
        <svg class="pt-4"  style="fill: #84C000" >
        <use xlink:href="#todoIcon"/>
        </svg>
    </div>

    <div class="col-lg-9 col-sm-10 col-xs-8 taskBody assigned" data-toggle="modal" attr.data-target="#example3Modal{{index}}" style="cursor: pointer; height: 80px;">      <!--(click)="getData(taskTodo)"-->
        <p class="mt-0 mb-0 opacity-text" i18n>Done by: 
            <span>{{task.doneBy[0]}}</span>
            <!-- <span *ngIf="userAssigned.length > 0 || userAssigned.length != null">{{userAssignedString}}</span> -->
            <!-- <span *ngIf="task.modified">{{userAssignedString}}</span>
            <span *ngIf="!task.modified">{{userAssignedString}}</span>
            <span *ngIf="task.assignedTo.length === 0">Nobody</span> -->
        </p> 
        <div class="row justify-content-between">
            <p class="mt-0 mb-0 title"  style="font-size: 18px"><strong>{{titleCut}}</strong></p>
            <span *ngIf="task.priority" class="badge badge-pill badge-{{task.priorityClass}}">{{task.priorityLabel}}</span> 
        </div>
        <div style="display: flex" class="dates">
        <p class="mt-0 mb-0" *ngIf="task.dueDate.year > 0" [ngClass]="doneDateIsPassed ? 'is-passed' : 'is-not-passed'" i18n>
            Due {{task.dueDate.year}}/{{task.dueDate.month}}/{{task.dueDate.day}} <!--<span class="ml-2"> {{taskTodo.startTime.hour}}:{{taskTodo.startTime.minute}}</span>-->
        </p> 
        <p class="mt-0 mb-0 opacity-text" style="font-style: italic" *ngIf="task.dueDate.year === 0" i18n>
            No due date.            </p> 
        &nbsp; // &nbsp;
        <p class="mt-0 mb-0 opacity-text" i18n>
            Done {{task.doneDate.year}}/{{task.doneDate.month}}/{{task.doneDate.day}} <!--<span class="ml-2"> {{taskTodo.startTime.hour}}:{{taskTodo.startTime.minute}}</span>-->
        </p>
        </div>
    </div>
</div>

    
<!-- MODAL TASK DETAILS -->
<div class="modal fade" id="example3Modal{{index}}" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header" style="background-color: #f5f5ed">

                <h5 class="modal-title" id="exampleModalLabel">{{task.taskTitle}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="outline: none">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body" style="background-color: #f5f5ed">

                <div class="firstBox">
                        <div class="headerRow">
                            <h2 i18n>Assigned to :</h2>
                                <span class="response" *ngIf="userAssigned.length === 0 || userAssigned.length == 'null'" style="font-style: italic;margin-left: 46%;" i18n>No assignations</span>
                                <span class="response" *ngIf="userAssigned.length > 0 || userAssigned.length != null">{{userAssignedString}}</span>
                        </div>
                        <div class="headerRow">
                            <h2 i18n>Status :</h2>
                            <span class="response">{{task.statut}}</span>
                        </div>
                        <div class="headerRow">
                            <h2 i18n>Priority :</h2>
                            <span class="badge badge-pill badge-{{task.priorityClass}} badgeTask">{{task.priorityLabel}}</span>
                            <span class="response" *ngIf="!task.priority" style="font-style:italic" i18n>None</span>
                        </div>
                        <div class="headerRow">
                            <h2 i18n>Due date :</h2>
                            <span *ngIf="task.dueDate.year > 0" [ngClass]="dateIsPassed ? 'is-passed' : 'is-not-passed'">
                                {{task.dueDate.year}}/{{task.dueDate.month}}/{{task.dueDate.day}}
                            </span>
                            <span *ngIf="task.dueDate.year === 0" class="is-not-passed" style="font-style:italic" i18n>
                                    No due date
                            </span> 
                        </div>
                        <div class="headerRow">
                            <h2 i18n>Done by : &nbsp;</h2>
                            <strong> {{usersDoneString}} &nbsp;</strong>
                            <h2 i18n> on : &nbsp;</h2>
                            <strong>{{task.doneDate.year}}/{{task.doneDate.month}}/{{task.doneDate.day}}</strong>
                        </div>
                        <div class="headerRow lastRow">
                            <h2 i18n>Create by : &nbsp;</h2>
                            <strong> {{task.userCreate}} &nbsp;</strong>
                            <h2 i18n> on : &nbsp;</h2>
                            <strong>{{task.dateCreate}}</strong>
                        </div>
                    </div> 
    
                    <div class="detailsBox">
                        <p>{{task.taskContent}}</p>
                        <p *ngIf="task.taskContent.length === 0" style="font-style:italic" i18n>No details for this task</p>
                    </div>
                    
                    <div class="commentBox" *ngFor="let comment of task.taskComment;  let index2 = index;">
                        <div style="display: flex;justify-content: space-between">
                            <div style="display: flex">
                                <div class="avatarUser" [style.background-color]="comment.userColor">{{userInitialName[index2]}}</div>
                                <p>{{comment.user}} -  {{comment.dateComment}}</p>
                            </div>
                            <div style="display: flex">
                                <svg  width="20px" height="20px" viewBox="0 0 24 24"
                                        style="fill:#84C000" (click)="modifComment(index2)" data-toggle="modal" data-backdrop="static" attr.data-target="#modif3Comment{{index}}">
                                <use xlink:href="#edit"/>
                                </svg>&nbsp;
                                <svg  width="20px" height="20px" viewBox="0 0 24 24"
                                    style="fill:#84C000" (click)="deleteComment(index2)">
                                    <use xlink:href="#tratsh"/>
                                </svg>
                            </div>
                        </div>
                        <p>{{comment.comment}}</p>
                        <!-- #{{index2 + 1}} - -->
                    </div>

                    <div class="commentBox" *ngIf="task.taskComment.length === 0">
                        <span i18n>Pas encore de commentaires ! Commentez en cliquant <a data-toggle="modal" attr.data-target="#modif3Task{{index}}" (click)="patchFormDatas()" style="text-decoration: underline; color:blue; cursor:pointer">ici</a></span>
                    </div>


                <div class="modal-footer row justify-content-between mt-3">
                    <div style="width: 35%; margin-left: 3%;" class="row justify-content-between buttons">
                        <button type="button" class="btn btn-secondary" data-toggle="modal" attr.data-target="#modif3Task{{index}}" (click)="patchFormDatas()">Modify</button> <br>
                        <button type="button" class="btn btn-danger" (click)='deleteTask()' data-dismiss="modal" i18n>Delete</button>
                    </div>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" i18n>Close</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- MODAL TASK MODIFICATION -->
<div class="modal fade" id="modif3Task{{index}}" tabindex="-1" role="dialog" aria-labelledby="addTasksLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h5 class="modal-title" i18n>Edit task</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="outline: none">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <form (ngSubmit)="modifTaskForm.valid && onSubmit()" [formGroup]='modifTaskForm'>
                <div class="modal-body">

                    <div class="row justify-content-between">

                        <div class="form-group col-6">
                            <label for="doneDate" i18n>Done date</label>
                            <div class="input-group">
                                <input class="form-control"
                                    [ngClass]="modifTaskForm.get('doneDate').errors ? 'is-invalid' : 'is-valid'"
                                    name="dp" formControlName="doneDate" ngbDatepicker #d="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-6">
                            <label for="statut" i18n>Change statut</label>
                            <select class="form-control" id="statut" formControlName="statut">
                                <option value="To do" i18n>To do</option>
                                <option value="In progress" i18n>In progress</option>
                                <option value="Done" i18n>Done</option>
                            </select>
                        </div>

                        <div class="form-group col-12">
                            <label for="taskComment" i18n>Add a comment</label>
                            <textarea
                                    class="form-control" id="taskComment"
                                    formControlName="taskComment"
                                    rows="2">
                            </textarea>
                        </div>

                        <div class="form-group col-6">
                            <label for="doneBy" i18n>Done by</label>
                            <select class="form-control" id="doneBy" formControlName="doneBy" (change)="onChange($event.target.value)">
                                <option *ngFor="let user of users" [value]="user">{{user}}</option>
                            </select>
                        </div>

                        <div class="col-6" i18n>
                            Done by: <br>
                            <span *ngFor="let user of usersDone; let index = index">
                                <button (click)="deleteUserAssigned(index)" class="btn btn-outline-danger" style="height: 20px;width: 20px;padding: 0 0 25px 0;margin-bottom: 2px;">&times;</button>
                                {{user}}<br>
                            </span>
                            <span *ngIf="usersDone.length === 0 || usersDone.length == null" style="margin-left: 10%;">-- Nobody --</span>
                        </div>                            
                    </div>

                    <div class="mb-5">
                        <button type="submit" class="btn btn-success button--color float-right"
                                [disabled]="!modifTaskForm.valid" i18n>Save Task
                        </button>
                        <button type="button" class="btn btn-secondary float-left" data-dismiss="modal" i18n>
                            Close
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!---->
    
<!-- MODIF COMMENT MODAL-->
<div class="modal" id="modif3Comment{{index}}" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" i18n>Edit a Comment</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form (ngSubmit)="onSubmitComment()" [formGroup]='modifCommentForm'>
                <div class="modal-body">
                    <textarea
                            class="form-control" id="comment"
                            formControlName="comment"
                            rows="2">
                    </textarea>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="openModalDetails()" data-dismiss="modal" i18n>Close</button>
                    <button type="submit" class="btn btn-primary" i18n>Save changes</button>
                </div>
            </form>
        </div>
    </div>
</div>