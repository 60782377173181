                                        <!-- LISTE DESKTOP -->
<div *ngIf="!mobileRes">         
  <app-fil-ariane-list-users (filterStatut)="checkStatut($event)" (filterStatutFast)="checkStatutFast($event)" (exportExcel)="modalDownload()"></app-fil-ariane-list-users>
  <div id="listUsersDesktop">
    <div *ngIf="filterStatutFast" class="maincontainer" style="border-bottom: 2px solid #EFEEE2;">
      <div class="row col pt-3 justify-content-end pr-0">
        <div class="row fast_edit">
          <app-form-select>
            <label for="IdType" class="label col-md-3 col-form-label" i18n>Type</label>
            <select class="select form-control" id="IdType">
              <option value="0" i18n>Please select</option>
              <option *ngFor="let type of this.listOfType" [value]="type.id">
                {{type.label}}
              </option>
            </select>
          </app-form-select>
          <app-form-select>
            <label for="IdStatus" class="label col-md-3 col-form-label" i18n>Status</label>
            <select class="select form-control" id="IdStatus">
              <option value="0" i18n>Please select</option>
              <option *ngFor="let status of this.listOfStatus" [value]="status.short">
                {{status.label}}
              </option>
            </select>
          </app-form-select>
          <app-form-select *ngIf="listOfOnsite">
            <label for="IdOnsite" class="label col-md-3 col-form-label" i18n>Onsite</label>
            <select class="select form-control" id="IdOnsite">
              <option value="0" i18n>Please select</option>
              <option *ngFor="let onsite of this.listOfOnsite" [value]="onsite.short">
                {{onsite.label}}
              </option>
            </select>
          </app-form-select>
          <app-form-select *ngIf="listOfAttendance">
            <label for="IdAttendance" class="label col-md-4 col-form-label" i18n>Attendance</label>
            <select class="select form-control" id="IdAttendance">
              <option value="-1" i18n>Please select</option>
              <option *ngFor="let attendance of this.listOfAttendance" [value]="attendance.id">
                {{attendance.label}}
              </option>
            </select>
          </app-form-select>
        </div>
        <div class="ml-5 ">
          <button type="button" (click)="modalConfirm()" class="btn btn-success button--color btn-size btn-responsive"
          [disabled]="selected == 0" i18n>
            Apply
          </button>
        </div>
      </div>
    </div>
    <div class="maincontainer">
      <div *ngIf="filterStatut" style="border-bottom: 2px solid #EFEEE2;">
        <form [formGroup]="filterFormGroup" (keyup)="changeFilter()" (change)="changeFilter()">
          <div style="background-color: #f9f8f4;" class="row col filters">
            <div style="padding-left: 15px;" class="filtersCenter mt-3">
              <div style="width: 25%;" class="select-custom">
                <label style="width: 25%;" i18n>Country</label>
                <select class="form-control-sm" id="IdCountry" formControlName="idCountry">
                  <option value="0" i18n>Please select</option>
                  <option *ngFor="let country of this.listOfCountry" [value]="country.id">
                    {{country.label}}
                  </option>
                </select>
              </div>
              <div style="width: 25%" class="select-custom">
                <label style="width: 25%;" i18n>Type</label>
                <select class="form-control-sm" id="IdType" formControlName="idType">
                  <option value="0" i18n>Please select</option>
                  <option *ngFor="let type of this.listOfType" [value]="type.id">
                    {{type.label}}
                  </option>
                </select>
              </div>
              <div style="width: 25%;" class="select-custom">
                <label style="width:25%" i18n>Vip</label>
                <select class="form-control-sm" id="Vip" formControlName="vip">
                  <option value="-1" i18n>Please select</option>
                  <option value="1" i18n>Yes</option>
                  <option value="0" i18n>No</option>
                </select>
              </div>
              <div style="width:25%" class="select-custom">
                <label style="width:25%" i18n>Main</label>
                <select class="form-control-sm" id="Main" formControlName="main">
                  <option [value]="-1" i18n>Please select</option>
                  <option [value]="1" i18n>Yes</option>
                  <option [value]="0" i18n>No</option>
                </select>
              </div>
            </div>
            <div class="filtersCenter" style='padding-left: 15px'>
              <div style="width:25%" class="select-custom">
                <label style="width: 25%;" i18n>Company</label>
                <select class="form-control-sm" id="IdCountry" formControlName="idCompany">
                  <option value="0" i18n>Please select</option>
                  <option *ngFor="let company of this.listOfCompany" [value]="company.id">
                    {{company.label}}
                  </option>
                </select>
              </div>
              <div style="width:50%">
                <label style="width:12.5%" i18n>Search</label>
                <input class="input-custom" id="SearchName" formControlName="search">
              </div>
              <div style="width:25%; text-align: center;">
                <button type="button" (click)="resetFilter()"
                  class="btn btn-success button--color btn-size btn-responsive" i18n>Clear
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div style="width: auto!important;" class="row headerList justify-content-between">
        <div class="col-3 selectAll">
          <mat-checkbox [(ngModel)]="box" (click)="checkBoxSelectAll()" color="none" height="16px"></mat-checkbox>
          <span class="cbLabel pr-2" i18n>Select all</span>
          <span i18n>{{selected}} selected</span>
        </div>
        <div class="pagination" style="white-space: nowrap;">
          <pagination-controls (pageChange)="pageChange($event)" class="myPagination" previousLabel="" nextLabel=""
            maxSize="5"></pagination-controls>
          <div style="padding-right: 25px;" class="nbItems">
            <span i18n>Show</span>
            <div class="select-custom">
              <select [(ngModel)]="nbItems" (ngModelChange)='onItemsChange($event);'>
                <option selected [value]="10">10</option>
                <option [value]="50">50</option>
                <option [value]="100">100</option>
                <option [value]="300">300</option>
              </select>
            </div>
            <span i18n>lines</span>
          </div>
        </div>
      </div>
      <div *ngIf="listOfTypeParticipant" class="list">
        <ul> 
          <li *ngFor="let item of listOfTypeParticipant | paginate: config">
            <app-user-card-desktop-v2 [check]="check" (countUser)="userCount($event, item.idPerson)" [data]="item" ></app-user-card-desktop-v2>
          </li>
          <br>
        </ul>
      </div>
      <div *ngIf="!listOfTypeParticipant" class="list">
        <h3 i18n>No user found</h3>
      </div>
    </div>
  </div>
                                        <!-- LISTE MOBILE -->
  <div *ngIf="mobileRes" style="background-color: #f9f8f4;">             
    <div id="listUsers">
        <app-fil-ariane (filterStatut)="checkStatut($event)" *ngIf="!filterStatut && count == 0"></app-fil-ariane>
        <div class="filtersMobile" *ngIf="filterStatut"  [@slideInUpOnEnter] [@slideOutLeftOnLeave]>
            <app-filters-mobile (filterStatut)="checkStatut($event)"></app-filters-mobile>
        </div>
        <div *ngIf="!filterStatut">
          <div class="list" >
            <div *ngIf="count > 0" class="userCount">
              <div i18n>
                  {{count}} user<span *ngIf="count > 1">s</span> selected
              </div>
              <div style="padding-top: 8px;">
                <svg height="44px" width="44px">
                    <use xlink:href="#export" />
                </svg>
                <div class="btn-group dropup">
                    <svg id="dropdownMenuButton" height="44px" width="44px" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <use xlink:href="#mail" />
                    </svg>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" href="#" i18n>Send mail</a>
                        <a class="dropdown-item" href="#" i18n>Send SMS</a>
                    </div>
                </div>
                <div class="btn-group dropup">
                  <svg id="dropdownMenuButton2" height="44px" width="44px" data-toggle="dropdown" aria-haspopup="true"
                      aria-expanded="false">
                      <use xlink:href="#action_h" />
                  </svg>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                    <a class="dropdown-item" href="#" i18n>Change status</a>
                    <a class="dropdown-item" href="#" i18n>Change account manager</a>
                    <a class="dropdown-item" href="#" i18n>Swip participant type</a>
                    <a class="dropdown-item" href="#" i18n>Detach</a>
                    <a class="dropdown-item" href="#" i18n>Add VIP tag</a>
                    <a class="dropdown-item" href="#" i18n>Add MAIN tag</a>
                    <a class="dropdown-item" href="#" i18n>Delete users</a>
                    <a class="dropdown-item" href="#" i18n>Delete access password</a>
                  </div>
                </div>
              </div>
            </div>
            <ul [ngClass]=" count == 0 ? 'count0' : 'count1'">
              <li *ngFor="let item of listOfTypeParticipant"> <!--| paginate: config-->
                <app-user-card-mobile  (countUser)="userCount($event)"></app-user-card-mobile>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
</div>
<div class="p-4 already_exist modal" id="ConfirmModal" tabindex="-1" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="">
    <div class="">
      <div class="">
      <h4 class="mb-4 text-align-center" i18n>Edit {{selected}} participant(s)</h4>
    </div>
    <hr class="mt-2 mb-3 separator" style="background-color: #84C000;">
    <div class="dp-flex-justy-beetw">
      <div class="p-3" style="border: 1px solid #aeaeae;">
        <h5 class="" i18n>Participant(s)</h5>
        <ul class="p-0 m-0" style="list-style: none;">
          <ng-container *ngFor="let item of listOfChecked">
            <li *ngIf="item.check == true">
              <b>{{item.id + " " + item.name}}</b>
            </li>
          </ng-container>
        </ul>
      </div>
      <div class="ml-4 mr-4 arrow_middle">
        <div class="mt-2 pt-2 pr-2 pl-2 border_arrow_edit" data-dismiss="modal">
          <a href="" class="link_edit">
            <svg class="mb-2" id="svgAR" height="24px" width="24px">
              <use class="arrow_edit" xlink:href="#arrow-left"/>
            </svg>
            <span>Cancel</span>
          </a>
        </div>
      <div class="mt-2 pt-2 pr-2 pl-2 border_arrow_exist">
          <a href="" data-dismiss="modal" (click)="fast_edit()" class="link_exist">
            <svg class="mb-2" id="svgAL" height="24px" width="24px">
              <use class="arrow_exist" xlink:href="#check"/>
            </svg>
            <span>Edit participant(s)</span>
            </a>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
<div class="p-4 already_exist modal" id="DownloadModal" tabindex="-1" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="">
    <div class="">
      <div class="">
      <h4 class="mb-4 text-align-center" i18n>Download file</h4>
    </div>
    <hr class="mt-2 mb-3 separator" style="background-color: #84C000;">
    <div class="dp-flex-justy-beetw">
      <form [formGroup]="loginForm">
        <div class="p-3" style="border: 1px solid #aeaeae;">
          <label for="username" i18n>Username</label>
          <input type="text" class="form-control" id="username" formControlName="username">
          <label for="password" i18n>Password</label>
          <input type="password" class="form-control" id="password" formControlName="password">
        </div>
      </form>
      <div class="ml-4 mr-4 arrow_middle">
        <div class="mt-2 pt-2 pr-2 pl-2 border_arrow_edit" data-dismiss="modal">
          <a href="" class="link_edit">
            <svg class="mb-2" id="svgAR" height="24px" width="24px">
              <use class="arrow_edit" xlink:href="#arrow-left"/>
            </svg>
            <span>Cancel</span>
          </a>
        </div>
        <div class="mt-2 pt-2 pr-2 pl-2 border_arrow_exist">
          <a href="" data-dismiss="modal" (click)="check_password()" class="link_exist">
            <svg class="mb-2" id="svgAL" height="24px" width="24px">
              <use class="arrow_exist" xlink:href="#check"/>
            </svg>
            <span>Download</span>
          </a>
        </div>
      </div>
    </div>
    </div>
  </div>
</div>