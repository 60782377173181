<form [formGroup]="updateParticipantFormGroup" (ngSubmit)="onSubmit()">
    <div [ngClass]="userSelected ? 'userCardSelected' : 'userCard'">
        <div class="row justify-content-around" style="margin-bottom: 20px;">
            <div class="col-md-2 row">
                <a [routerLink]="detailUrl">
                    <h3>{{data.firstName}} {{data.lastName}}</h3>
                </a>
                <svg [routerLink]="updateUrl" class="svgEdit" id="svgEdit"
                    style="cursor:pointer;margin-top: -5px;fill: #fffefb;margin-left: 15px;overflow: visible"
                    height="24px" width="24px">
                    <use xlink:href="#edit" />
                </svg>
            </div>
            <app-form-select>
                <label for="ChannelType" class="label col-sm-5 col-form-label" style="width: 180px;" i18n>Status contact :</label>
                <select class="select form-control" id="ChannelType" formControlName="statusContact">
                  <option *ngFor="let statusType of this.listOfChannelStatus" value="{{statusType.short}}">
                    {{statusType.label}}
                  </option>
                </select>
            </app-form-select>
            <app-form-select>
                <label for="ChannelType" class="label col-sm-5 col-form-label" style="width: 140px;" i18n>Channel type :</label>
                <select class="select form-control" id="ChannelType" formControlName="idChannelType">
                    <option value="0" or value="-1">Please select</option>
                    <option *ngFor="let channelType of this.listOfChannelType" [value]="channelType.id">
                    {{channelType.label}}
                  </option>
                </select>
            </app-form-select>
            <app-form-select>
                <label for="ChannelType" class="label col-sm-5 col-form-label" style="width: 240px;" i18n>Attendance Type :</label>
                <select class="select form-control" id="ChannelType" formControlName="idAttendanceType">
                  <option *ngFor="let attType of this.listOfChannelAtten" [value]="attType.id">
                    {{attType.label}}
                  </option>
                </select>
            </app-form-select>
        </div>
        <div class="row l2">
            <span class="badge badge-pill badge-status badge-status-{{badgeStatus}}"
                style="opacity: 0.6">{{badgeStatus}}.</span>
            <app-form-radio-v2 class="margin_right">
                <h6 class="title" style="width: 50px;">Vip : </h6>
                <input class="radioTrue form-check-input" type="radio" id="vipTrue" formControlName="vip" [value]="true">
                <label class="labelTrue form-check-label" for="vipTrue" i18n>Yes</label>
                <input class="radioFalse form-check-input" type="radio" id="vipFalse" formControlName="vip" [value]="false">
                <label class="labelFalse form-check-label" for="vipFalse" i18n>No</label>
            </app-form-radio-v2>
            <app-form-radio-v2 class="margin_right">
                <h6 class="title" style="width: 150px;" i18n>Incident : </h6>
                <input class="radioTrue form-check-input" type="radio" id="incidentTrue" formControlName="incident" [value]="true">
                <label class="labelTrue form-check-label" for="incidentTrue" i18n>Yes</label>
                <input class="radioFalse form-check-input" type="radio" id="incidentFalse" formControlName="incident" [value]="false">
                <label class="labelFalse form-check-label" for="incidentFalse" i18n>No</label>
            </app-form-radio-v2>
            <app-form-radio-v2 class="margin_right">
                <h6 class="title" style="width: 250px;" i18n>Authorize to share your email : </h6>
                <input class="radioTrue form-check-input" type="radio" id="shareEmailTrue" formControlName="shareEmail" [value]="true">
                <label class="labelTrue form-check-label" for="shareEmailTrue" i18n>Yes</label>
                <input class="radioFalse form-check-input" type="radio" id="shareEmailFalse" formControlName="shareEmail" [value]="false">
                <label class="labelFalse form-check-label" for="shareEmailFalse" i18n>No</label>
            </app-form-radio-v2>
        </div>

        <div class="l3" style="display: flex;">
            <app-form-radio-v2 class="margin_right">
                <h6 class="title" style="width: 50px;" i18n>Main : </h6>
                <input class="radioTrue form-check-input" type="radio" id="mainTrue" formControlName="main" [value]="true">
                <label class="labelTrue form-check-label" for="noEmailTrue" i18n>Yes</label>
                <input class="radioFalse form-check-input" type="radio" id="mainFalse" formControlName="main" [value]="false">
                <label class="labelFalse form-check-label" for="noEmailFalse" i18n>No</label>
            </app-form-radio-v2>
            <app-form-radio-v2 class="margin_right">
                <h6 class="title" style="width: 150px;" i18n>Do not post email : </h6>
                <input class="radioTrue form-check-input" type="radio" id="noEmailTrue" formControlName="doNotPostEmail" [value]="true">
                <label class="labelTrue form-check-label" for="noEmailTrue" i18n>Yes</label>
                <input class="radioFalse form-check-input" type="radio" id="noEmailFalse" formControlName="doNotPostEmail" [value]="false">
                <label class="labelFalse form-check-label" for="noEmailFalse" i18n>No</label>
            </app-form-radio-v2>
            <app-form-radio-v2 class="margin_right">
                <h6 class="title" style="width: 250px;" i18n>Authorize to share your mobile : </h6>
                <input class="radioTrue form-check-input" type="radio" id="shareMobileTrue" formControlName="shareMobile" [value]="true">
                <label class="labelTrue form-check-label" for="shareMobileTrue" i18n>Yes</label>
                <input class="radioFalse form-check-input" type="radio" id="shareMobileFalse" formControlName="shareMobile" [value]="false">
                <label class="labelFalse form-check-label" for="shareMobileFalse" i18n>No</label>
            </app-form-radio-v2>
            <button type="submit" class="btn-success btn-size btn" style="color: white;" style="margin-left: auto; height: 35px; font-size: 15px;">Save</button>
        </div>
        <hr>

        <div class="row footer">
            <p i18n>CREATE</p>
            <span>{{data.dateCreation}}</span>
            <p i18n>MODIFIED</p>
            <span>{{data.profileUpdateDate}}</span>
            <p class="accountM" i18n>ACCOUNT M.</p>
            <span class="accountM">{{data.accountManager}}</span>
        </div>
    </div>
</form>