<div class="userCard">
    <div class="row justify-content-between">
        <div class="col-md-6" style="display: flex">
            <mat-checkbox color="none"></mat-checkbox>
            <!-- <span class="badge badge-pill badge-secondary" style="opacity: 0.6">{{task.statut.toUpperCase()}}</span> -->
            <svg class="float-right" class="todoIcon" height="24px" width="24px">
                <use xlink:href="#todo"/>
            </svg>
            <p style="position: absolute;top: 35px;"  *ngIf="task.milestone === true">!M!</p>
            <div class="btn-group dropleft">
                <svg class="float-right" id="dropdownMenuButton6" style="cursor:pointer;opacity: 0.2;margin-top: -5px;" height="24px" width="24px"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <use xlink:href="#more-vertical"/>
                </svg>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="#" i18n>Edit Task</a>
                    <a class="dropdown-item" href="#" i18n>Delete Task</a>
                    <a class="dropdown-item" href="#" i18n>Something else here</a>
                    <a class="dropdown-item" *ngIf="!task.milestone" (click)="swipMilestone(task)" i18n>Pass to milestone</a>
                    <a class="dropdown-item" *ngIf="task.milestone" (click)="swipMilestone(task)" i18n>Take off milestone</a>  
                </div>
            </div>
            <a><h3 style="text-decoration: underline;">{{task.taskTitle}}</h3></a>
            <div class="badges" style="display: flex">
                <span *ngIf="task.priorityNumber != 0" class="badge badge-pill badge-{{task.priorityClass}}">{{task.priorityLabel}}</span>
            </div>
        </div>

        <div class="col-md-3 col-sd-5" style="display: flex">
            <h3 style="display: flex;" i18n>Assigned to :&nbsp;<p style="text-decoration: underline;font-weight: bolder;">{{task.assignedTo}}</p></h3>
        </div>
    </div>

    <div class="row justify-content-between l2">
        <div class="col-md-5" style="display: flex">
            <p class="tittle" (click)="displayDetails()">{{detailsCut}}</p>
            <p *ngIf="detailsCut.length === 0" class="tittle" (click)="displayDetails()" style="font-style: italic; margin-left:0px!important" i18n>No details for this task</p>
            <svg class="float-right" [ngClass]="displayDetailsBool ? 'is-display' : 'is-not-display'" height="24px" width="24px" (click)="displayDetails()">
                <use xlink:href="#right"/>
            </svg>
        </div>
        <div class="col-md-3 col-sd-5" style="display: flex">
            <p *ngIf="task.dueDate.year > 0" [ngClass]="dateIsPassed ? 'is-passed' : 'is-not-passed'" i18n>DUE DATE : {{task.dueDate.day}}/{{task.dueDate.month}}/{{task.dueDate.year}}</p>
            <p *ngIf="task.dueDate.year === 0" class="noDueDate" i18n>NO DUE DATE</p>
        </div>   
    </div>

    <div *ngIf="displayDetailsBool">
        {{task.taskContent}}
    </div>

    <div *ngIf="displayDetailsBool && detailsCut.length === 0" i18n>
        No more details for this task !
    </div>

    <hr>

    <div class="row footer" style="position: relative;">
        <p i18n>ADD THE</p>
        <span>{{task.dateCreate}}</span>
        <p i18n>CREATE BY</p>
        <span>{{task.userCreate}}</span>
    </div>
</div>