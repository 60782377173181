<div class="row">
  <div Class="col-md-12">
    <div>
      <div>
        <div style="display: flex;">
          <nav class="nav-responsive-top" style="width: 100%;margin-bottom:1rem!important">
            <div class="nav nav-tabs nav-responsive" id="nav-tab" role="tablist">
              <a placement="left" popoverClass="popover-custom-light" ngbPopover="View contact attendee" triggers="mouseenter:mouseleave"
              class="nav-item nav-link active nowayRegular" id="nav-attendee" data-toggle="tab"
              href="#attendee" role="tab" aria-controls="nav-attendee" aria-selected="true" i18n>Attendee</a>
              <a placement="top" popoverClass="popover-custom-light" ngbPopover="View contact profile" triggers="mouseenter:mouseleave"
              class="nav-item nav-link" id="nav-profile" data-toggle="tab" href="#profile" role="tab"
              aria-controls="nav-profile" aria-selected="false" i18n>Profile</a>
              <a placement="top" popoverClass="popover-custom-light" ngbPopover="View contact events" triggers="mouseenter:mouseleave"
              class="nav-item nav-link" id="nav-event" data-toggle="tab" href="#event" role="tab"
              aria-controls="nav-event" aria-selected="false" i18n>Events</a>
              <a placement="top" popoverClass="popover-custom-light" ngbPopover="View contact travel" triggers="mouseenter:mouseleave"
              *ngIf="this.listOfEvents" class="nav-item nav-link nowayRegular" id="nav-travel" data-toggle="tab" href="#travel" role="tab"
              aria-controls="nav-travel" aria-selected="false" i18n>Travel</a>
              <a placement="right" popoverClass="popover-custom-light" ngbPopover="View contact hotel" triggers="mouseenter:mouseleave"
              class="nav-item nav-link nowayRegular" id="nav-hotel" data-toggle="tab" href="#hotel" role="tab"
              aria-controls="nav-hotel" aria-selected="false" i18n>Hotel</a>
            </div>
          </nav>
        </div>
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade show active mt-3" id="attendee" role="tabpanel" aria-labelledby="nav-attendee">
            <form (ngSubmit)="onSubmit()" [formGroup]="attendeeForm" (change)="changeAttendeeForm()">
              <div id="attendeeInformationRow" class="row mt-3">
                <div class="col-md-6 col-12">
                  <app-form-select>
                    <label for="idTitle" class="label col-sm-5 col-form-label" i18n>Title</label>
                    <select class="select form-control" id="idTitle" formControlName="idTitle">
                      <option value="0" i18n>Please select</option>
                      <option [value]="title.id" *ngFor="let title of listOfTitle">
                        {{ title.label }}
                      </option>
                    </select>
                  </app-form-select>
                  <app-form-input>
                    <label for="firstName" class="label col-sm-5 col-form-label" i18n>First name</label>
                    <input type="text" class="input form-control" id="firstName" formControlName="firstName">
                  </app-form-input>
                  <app-form-input>
                    <label for="lastName" class="label col-sm-5 col-form-label" i18n>Last name</label>
                    <input type="text" class="input form-control" id="lastName" formControlName="lastName">
                  </app-form-input>
                  <hr class="mt-4 mb-4 separator">
                  <app-form-input>
                    <label for="jobPosition" class="label col-sm-5 col-form-label" i18n>Job Position</label>
                    <input type="text" class="input form-control" id="jobPosition" formControlName="jobPosition">
                  </app-form-input>
                  <div [@fadeInDownOnEnter] [@fadeOutUpOnLeave]>
                    <hr class="mt-4 mb-4 separator">
                    <app-form-input>
                      <label for="mobile" class="label col-sm-5 col-form-label" i18n>Mobile</label>
                      <input type="phone" class="input form-control" id="mobile" formControlName="mobile">
                    </app-form-input>
                  </div>
                  <app-form-input>
                    <label for="email" class="label col-sm-5 col-form-label" i18n>Email</label>
                    <input type="email" class="input form-control" id="email" formControlName="email">
                  </app-form-input>
                  <app-form-input>
                    <label for="email2" class="label col-sm-5 col-form-label" i18n>Email2</label>
                    <input type="email2" class="input form-control" id="email2" formControlName="email2">
                  </app-form-input>
                </div>
                <div [@fadeInDownOnEnter] [@fadeOutUpOnLeave] class=" col-md-5 col-12">
                  <h6 class="nowayRegular mb-4 t-participant" i18n>
                    ASSISTANT INFORMATION
                  </h6>
                  <app-form-checkbox>
                    <input class="check form-check-input mt-2" type="checkbox" id="CcAssistant"
                           formControlName="ccAssistant">
                    <label class="label form-check-label mb-4 ml-2" for="CcAssistant">
                      <small style="font-size: 16px" i18n>Assistant receives a copy of all correspondence</small>
                    </label>
                  </app-form-checkbox>
                  <app-form-select>
                    <label for="idTitleAssistant" class="label col-sm-5 col-form-label" i18n>Title</label>
                    <select class="select form-control" id="idTitleAssistant" formControlName="idTitleAssistant">
                      <option value="0" i18n>Please select</option>
                      <option [value]="title.id" *ngFor="let title of listOfTitle">
                        {{ title.label }}
                      </option>
                    </select>
                  </app-form-select>
                  <app-form-input>
                    <label for="firstNameAssistant" class="float-left label col-sm-5 col-form-label" i18n>First name</label>
                    <input type="text" class="input form-control" id="firstNameAssistant" formControlName="firstNameAssistant">
                  </app-form-input>
                  <app-form-input>
                    <label for="lastNameAssistant" class="label col-sm-5 col-form-label" i18n>Last name</label>
                    <input type="text" class="input form-control" id="lastNameAssistant" formControlName="lastNameAssistant">
                  </app-form-input>
                  <app-form-input>
                    <label for="jobPositionAssistant" class="label col-sm-5 col-form-label" i18n>Job Position</label>
                    <input type="text" class="input form-control" id="jobPositionAssistant" formControlName="jobPositionAssistant">
                  </app-form-input>
                  <app-form-input>
                    <label for="faxAssistant" class="label col-sm-5 col-form-label" i18n>Fax</label>
                    <input type="phone" class="input form-control" id="faxAssistant" formControlName="faxAssistant">
                  </app-form-input>
                  <app-form-input>
                    <label for="telephoneAssistant" class="label col-sm-5 col-form-label" i18n>Telephone</label>
                    <input type="phone" class="input form-control" id="telephoneAssistant" formControlName="telephoneAssistant">
                  </app-form-input>
                  <app-form-input>
                    <label for="mobileAssistant" class="label col-sm-5 col-form-label" i18n>Mobile</label>
                    <input type="phone" class="input form-control" id="mobileAssistant" formControlName="mobileAssistant">
                  </app-form-input>
                  <app-form-input>
                    <label for="emailAssistant" class="label col-sm-5 col-form-label" i18n>Email</label>
                    <input type="email" class="input form-control" id="emailAssistant" formControlName="emailAssistant">
                  </app-form-input>
                </div>
              </div>
              <div [@fadeInDownOnEnter] [@fadeOutUpOnLeave]>
                <hr class="mt-5 mb-5">
                <h6 class="nowayRegular mb-4 t-participant" i18n>ATTENDEE ADDRESS</h6>
                <div class="row mt-3" style="justify-content: space-between;">
                  <div class="col-md-6 col-12">
                    <app-form-input>
                      <label for="address" class="label col-sm-5 col-form-label" i18n>Address</label>
                      <input type="text" class="input form-control" id="address" formControlName="address">
                    </app-form-input>
                    <app-form-input>
                      <label for="city" class="label col-sm-5 col-form-label" i18n>City</label>
                      <input type="text" class="input form-control" id="city" formControlName="city">
                    </app-form-input>
                    <app-form-input>
                      <label for="zipCode" class="label col-sm-5 col-form-label" i18n>Zip code</label>
                      <input type="text" class="input form-control" id="zipCode" formControlName="zipCode">
                    </app-form-input>
                  </div>
                  <div class="col-md-5 col-12">
                    <app-form-input>
                      <label for="address2" class="label col-sm-5 col-form-label" i18n>Address 2</label>
                      <input type="text" class="input form-control" id="address2" formControlName="address2">
                    </app-form-input>
                    <app-form-input>
                      <label for="state" class="label col-sm-5 col-form-label" i18n>State</label>
                      <input type="text" class="input form-control" id="state" formControlName="state">
                    </app-form-input>
                    <app-form-select>
                      <label for="idCountry" class="label col-sm-5 col-form-label" i18n>Country</label>
                      <select class="select form-control" id="idCountry" formControlName="idCountry">
                        <option value="" i18n>Please select</option>
                        <option *ngFor="let country of listOfCountry" [value]="country.id">
                          {{country.label}}
                        </option>
                      </select>
                    </app-form-select>
                  </div>
                </div>
                <hr class="mt-5 mb-5">
                <div *ngIf="isAttendeeHasChange || isContactHasChange || isTravelHasChange" class="row button-fixed">
                  <div class="maincontainer w-100" style="display: flex; justify-content: end;">
                    <div class="col-sm-12 row justify-content-end">
                      <button type="button" class="btn btn-classic button--color btn-size btn-responsive mr-3" (click)="cancelEdit()" i18n>
                        Cancel
                      </button>
                      <button type="submit" class="btn btn-success button--color btn-size btn-responsive" i18n>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="tab-pane fade mt-3" id="profile" role="tabpanel" aria-labelledby="nav-profile">
            <form (ngSubmit)="onSubmit()" [formGroup]='contactForm' (change)="changeContactForm()">
              <div class="row mt-3">
                <div class="col-md-6 col-12">
                  <h6 class="nowayRegular mb-4 t-participant" i18n>PROFILES INFORMATION</h6>
                  <app-form-select>
                    <label for="ChannelType" class="label col-sm-5 col-form-label" i18n>Status :</label>
                    <select class="select form-control" id="ChannelType" formControlName="statusContact">
                      <option *ngFor="let statusType of this.listOfStatus" value="{{statusType.short}}">
                        {{statusType.label}}
                      </option>
                    </select>
                  </app-form-select>
                  <app-form-select>
                    <label for="ChannelType" class="label col-sm-5 col-form-label" i18n>Channel type :</label>
                    <select class="select form-control" id="ChannelType" formControlName="idChannelType">
                        <option value="0" or value="-1" or value="undefined" i18n>Please select</option>
                        <option *ngFor="let channelType of this.listOfChannelType" [value]="channelType.id">
                        {{channelType.label}}
                      </option>
                    </select>
                  </app-form-select>
                  <app-form-select>
                    <label for="ChannelType" class="label col-sm-5 col-form-label" i18n>Attendance Type :</label>
                    <select class="select form-control" id="ChannelType" formControlName="idAttendanceType">
                      <option *ngFor="let attType of this.listOfChannelAtten" [value]="attType.id">
                        {{attType.label}}
                      </option>
                    </select>
                  </app-form-select>
                  <app-form-radio class="margin_right">
                    <h6 class="title" i18n>Vip : </h6>
                    <input class="radioTrue form-check-input" type="radio" id="vipTrue" formControlName="vip" [value]="true">
                    <label class="labelTrue form-check-label" for="vipTrue" i18n>Yes</label>
                    <input class="radioFalse form-check-input" type="radio" id="vipFalse" formControlName="vip" [value]="false">
                    <label class="labelFalse form-check-label" for="vipFalse" i18n>No</label>
                  </app-form-radio>
                  <app-form-radio class="margin_right">
                    <h6 class="title" i18n>Main : </h6>
                    <input class="radioTrue form-check-input" type="radio" id="mainTrue" formControlName="main" [value]="true">
                    <label class="labelTrue form-check-label" for="noEmailTrue" i18n>Yes</label>
                    <input class="radioFalse form-check-input" type="radio" id="mainFalse" formControlName="main" [value]="false">
                    <label class="labelFalse form-check-label" for="noEmailFalse" i18n>No</label>
                  </app-form-radio>
                  <app-form-radio class="margin_right">
                    <h6 class="title" i18n>Incident : </h6>
                    <input class="radioTrue form-check-input" type="radio" id="incidentTrue" formControlName="incident" [value]="true">
                    <label class="labelTrue form-check-label" for="incidentTrue" i18n>Yes</label>
                    <input class="radioFalse form-check-input" type="radio" id="incidentFalse" formControlName="incident" [value]="false">
                    <label class="labelFalse form-check-label" for="incidentFalse" i18n>No</label>
                  </app-form-radio>
                  <app-form-radio class="margin_right">
                      <h6 class="title" i18n>Do not post email : </h6>
                      <input class="radioTrue form-check-input" type="radio" id="noEmailTrue" formControlName="doNotPostEmail" [value]="true">
                      <label class="labelTrue form-check-label" for="noEmailTrue" i18n>Yes</label>
                      <input class="radioFalse form-check-input" type="radio" id="noEmailFalse" formControlName="doNotPostEmail" [value]="false">
                      <label class="labelFalse form-check-label" for="noEmailFalse" i18n>No</label>
                  </app-form-radio>
                  <app-form-radio class="margin_right">
                    <h6 class="title" i18n>Authorize to share your email : </h6>
                    <input class="radioTrue form-check-input" type="radio" id="shareEmailTrue" formControlName="shareEmail" [value]="true">
                    <label class="labelTrue form-check-label" for="shareEmailTrue" i18n>Yes</label>
                    <input class="radioFalse form-check-input" type="radio" id="shareEmailFalse" formControlName="shareEmail" [value]="false">
                    <label class="labelFalse form-check-label" for="shareEmailFalse" i18n>No</label>
                  </app-form-radio>
                  <app-form-radio class="margin_right">
                      <h6 class="title" i18n>Authorize to share your mobile : </h6>
                      <input class="radioTrue form-check-input" type="radio" id="shareMobileTrue" formControlName="shareMobile" [value]="true">
                      <label class="labelTrue form-check-label" for="shareMobileTrue" i18n>Yes</label>
                      <input class="radioFalse form-check-input" type="radio" id="shareMobileFalse" formControlName="shareMobile" [value]="false">
                      <label class="labelFalse form-check-label" for="shareMobileFalse" i18n>No</label>
                  </app-form-radio>
                </div>
                <div class="col-md-6 col-12">
                  <div class="row">
                    <div class="col-md-1"></div>
                    <div class="col-md-11 pl-2rem">
                      <h6 class="nowayRegular mb-4 t-participant" i18n>INTERNAL INFORMATION</h6>
                      <app-form-select>
                        <label for="region" class="label col-sm-5 col-form-label" i18n>
                          Region
                        </label>
                        <select class="select form-control float-right" id="registrationChannel" formControlName="idRegion">
                          <option value="0" i18n>Please select</option>
                          <option *ngFor="let region of this.listOfRegion" [value]="region.id">
                            {{region.label}}
                          </option>
                        </select>
                      </app-form-select>
                      <app-form-select>
                        <label for="registrationChannel" class="label col-sm-5 col-form-label" i18n>
                          Registration
                          channel
                        </label>
                        <select class="select form-control float-right" id="registrationChannel" formControlName="idRegistrationChannel">
                          <option value="0" i18n>Please select</option>
                          <option *ngFor="let regisChannel of this.listOfRegistrationChannel" [value]="regisChannel.id">
                            {{regisChannel.label}}
                          </option>
                        </select>
                      </app-form-select>
                      <div class="form-group">
                        <label for="generalComments" i18n>General comments </label>
                        <textarea class="form-control" id="generalComments" formControlName="comments"
                                  rows="5"></textarea>
                        <small i18n>Characters (Max. 1000)</small>
                      </div>
                      <app-form-radio class="margin_right col-md-7">
                        <h6 class="title" i18n>Profile update</h6>
                        <input class="radioTrue form-check-input" type="radio" id="profileUpdateTrue" formControlName="profileUpdate" [value]="true">
                        <label class="labelTrue form-check-label" for="profileUpdateTrue" i18n>Yes</label>
                        <input class="radioFalse form-check-input" type="radio" id="profileUpdateFalse" formControlName="profileUpdate" [value]="false">
                        <label class="labelFalse form-check-label" for="profileUpdateFalse" i18n>No</label>
                      </app-form-radio>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <hr class="mt-5 mb-5">
                  <app-form-input>
                    <label for="photoUrl" class="label col-sm-3 col-form-label" i18n>Photo url</label>
                    <input type="text" class="input input-full form-control" id="photoUrl" formControlName="photoUrl" style="max-width: 100%;">
                  </app-form-input>
                </div>
              </div>
              <hr class="mt-5 mb-5">
              <div *ngIf="isAttendeeHasChange || isContactHasChange || isTravelHasChange" class="row button-fixed">
                <div class="maincontainer w-100" style="display: flex; justify-content: end;">
                  <div class="col-sm-12 row justify-content-end">
                    <button type="button" class="btn btn-classic button--color btn-size btn-responsive mr-3" (click)="cancelEdit()" i18n>
                      Cancel
                    </button>
                    <button type="submit" class="btn btn-success button--color btn-size btn-responsive" i18n>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="tab-pane fade mt-3" id="event" role="tabpanel" aria-labelledby="nav-event">
            <div class="list">
              <ul style="list-style: none; padding-left: 0;">
                <li *ngFor="let item of listOfAllEvents">
                  <app-update-participant-event [data]="item" ></app-update-participant-event>
                </li>
              </ul>
            </div>
            <form (ngSubmit)="onSubmit()">
              <div *ngIf="isAttendeeHasChange || isContactHasChange || isTravelHasChange" class="row button-fixed">
                <div class="maincontainer w-100" style="display: flex; justify-content: end;">
                  <div class="col-sm-12 row justify-content-end">
                    <button type="button" class="btn btn-classic button--color btn-size btn-responsive mr-3" (click)="cancelEdit()">
                      Cancel
                    </button>
                    <button type="submit" class="btn btn-success button--color btn-size btn-responsive">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div *ngIf="this.listOfEvents" class="tab-pane fade mt-3" id="travel" role="tabpanel" aria-labelledby="nav-travel">
            <form (ngSubmit)="onSubmit()" [formGroup]='travelForm' (change)="changeTravelForm()">
              <h6 class="nowayRegular mb-4 t-participant" i18n>EVENT</h6>
              <div class="row mt-3">
                <div class="col-md-7 col-12">
                  <select class="select form-control" style="max-width: 100%;" id="event" formControlName="idEvent" (change)="onChange()">
                    <option *ngFor="let event of this.listOfEvents" [value]="event.idEvent">
                      {{event.startDate}} - {{event.event}}
                    </option>
                  </select>
                </div>
                <div class="col-md-5 col-12 row" style="justify-content: center; align-items: center;">
                  <p>
                    <span class="badge-status badge-status-{{status_event.replaceAll(' ', '-')}}">{{status_event.substring(0,1).toUpperCase() + status_event.substring(1)}}</span>
                  </p>
                </div>
              </div>
              <hr class="mt-5 mb-5">
              <div class="row mt-3">
                <div class="col-md-6 col-12">
                  <h6 class="nowayRegular mb-4 t-participant" i18n>COMMENTS</h6>
                  <div class="form-group">
                    <textarea class="input form-control IDinput" rows="3" id="comments" #comments
                      formControlName="comments" style="min-height: 120px;max-width: 100%;">
                    </textarea>
                    <p class="form-text" [ngClass]="comments.value.length > 500 ? 'text-red' : 'text-muted'" i18n>
                      <b>{{comments.value.length}}</b>&nbsp;Characters (Max. 500)
                    </p>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <h6 class="nowayRegular mb-4 t-participant" i18n>OTHER COMMENTS</h6>
                  <div class="form-group">
                    <textarea class="input form-control IDinput" rows="3" id="otherComments" #otherComments
                      formControlName="otherComments" style="min-height: 120px;max-width: 100%;">
                    </textarea>
                    <p class="form-text" [ngClass]="otherComments.value.length > 500 ? 'text-red' : 'text-muted'" i18n>
                      <b>{{otherComments.value.length}}</b>&nbsp;Characters (Max. 500)
                    </p>
                  </div>
                </div>
              </div>
              <hr class="mt-5 mb-5">
              <div class="row mt-3">
                <div class="col-md-6 col-12">
                  <h6 class="nowayRegular mb-4 t-participant" i18n>ARRIVAL DETAILS</h6>
                  <app-form-select>
                    <label for="arrivalTransport" class="label col-sm-5 col-form-label" i18n>Arrival Method</label>
                    <select class="select form-control" id="arrivalTransport" formControlName="idArrivalTransport">
                      <option value="0" i18n>Please select</option>
                      <option *ngFor="let transport of this.listOfTransport" [value]="transport.id">
                        {{transport.label}}
                      </option>
                    </select>
                  </app-form-select>
                  <div class="form-group row ">
                    <label for="arrivalDate" class="label col-sm-5 col-form-label" i18n>Arrival Date</label>
                    <mat-form-field class="col-md-6">
                      <input matInput [matDatepicker]="arrivalDate" name="arrivalDate" formControlName="arrivalDate" class="mat_imput_custom">
                      <mat-datepicker-toggle matIconSuffix [for]="arrivalDate"></mat-datepicker-toggle>
                      <mat-datepicker #arrivalDate></mat-datepicker>
                    </mat-form-field>
                    <ngx-timepicker-field style="margin-left: 50%" [format]="24" formControlName="arrivalHour"></ngx-timepicker-field>
                  </div>
                  <app-form-input>
                    <label for="arrivalCity" class="label col-sm-5 col-form-label" i18n>Arrival city</label>
                    <input type="text" class="input form-control" id="arrivalCity" formControlName="arrivalCity">
                  </app-form-input>
                </div>
                <div class="col-md-6 col-12">
                  <h6 class="nowayRegular mb-4 t-participant" i18n>DEPARTURE DETAILS</h6>
                  <app-form-select>
                    <label for="departureTransport" class="label col-sm-5 col-form-label" i18n>Departure Method</label>
                    <select class="select form-control" id="departureTransport" formControlName="idDepartureTransport">
                      <option value="0" i18n>Please select</option>
                      <option *ngFor="let transport of this.listOfTransport" [value]="transport.id">
                        {{transport.label}}
                      </option>
                    </select>
                  </app-form-select>
                  <div class="form-group row">
                    <label for="departureDate" class="label col-sm-5 col-form-label" i18n>Departure Date</label>
                    <mat-form-field class="col-md-6">
                      <input matInput [matDatepicker]="departureDate" name="departureDate" formControlName="departureDate" class="mat_imput_custom">
                      <mat-datepicker-toggle matIconSuffix [for]="departureDate"></mat-datepicker-toggle>
                      <mat-datepicker #departureDate></mat-datepicker>
                    </mat-form-field>
                    <ngx-timepicker-field style="margin-left: 50%" [format]="24" formControlName="departureHour"></ngx-timepicker-field>
                  </div>
                  <app-form-input>
                    <label for="departureCity" class="label col-sm-5 col-form-label" i18n>Departure city</label>
                    <input type="text" class="input form-control" id="departureCity" formControlName="departureCity">
                  </app-form-input>
                </div>
              </div>
              <hr class="mt-5 mb-5">
              <div class="row mt-3">
                <div class="col-md-6 col-12">
                  <app-form-input>
                    <label for="arrivalAirport" class="label col-sm-5 col-form-label" i18n>Arrival airport</label>
                    <input type="text" class="input form-control" id="arrivalCity" formControlName="arrivalAirport">
                  </app-form-input>
                  <app-form-input>
                    <label for="arrivalFlight" class="label col-sm-5 col-form-label" i18n>Arrival flight</label>
                    <input type="text" class="input form-control" id="arrivalFlight" formControlName="arrivalFlight">
                  </app-form-input>
                  <app-form-input>
                    <label for="arrivalArline" class="label col-sm-5 col-form-label" i18n>Arrival airline</label>
                    <input type="text" class="input form-control" id="arrivalAirline" formControlName="arrivalAirline">
                  </app-form-input>
                </div>
                <div class="col-md-6 col-12">
                  <app-form-input>
                    <label for="departureAirport" class="label col-sm-5 col-form-label" i18n>Departure airport</label>
                    <input type="text" class="input form-control" id="departureCity" formControlName="departureAirport">
                  </app-form-input>
                  <app-form-input>
                    <label for="departureFlight" class="label col-sm-5 col-form-label" i18n>Departure flight</label>
                    <input type="text" class="input form-control" id="departureFlight" formControlName="departureFlight">
                  </app-form-input>
                  <app-form-input>
                    <label for="departureArline" class="label col-sm-5 col-form-label" i18n>Departure airline</label>
                    <input type="text" class="input form-control" id="departureAirline" formControlName="departureAirline">
                  </app-form-input>
                </div>
              </div>
              <hr class="mt-5 mb-5">
              <div class="row mt-3">
                <div class="col-md-6 col-12">
                  <app-form-input>
                    <label for="arrivalStation" class="label col-sm-5 col-form-label" i18n>Arrival station</label>
                    <input type="text" class="input form-control" id="arrivalStation" formControlName="arrivalStation">
                  </app-form-input>
                  <app-form-input>
                    <label for="arrivalTrain" class="label col-sm-5 col-form-label" i18n>Arrival train</label>
                    <input type="text" class="input form-control" id="arrivalTrain" formControlName="arrivalTrain">
                  </app-form-input>
                </div>
                <div class="col-md-6 col-12">
                  <app-form-input>
                    <label for="departureStation" class="label col-sm-5 col-form-label" i18n>Departure station</label>
                    <input type="text" class="input form-control" id="departureStation" formControlName="departureStation">
                  </app-form-input>
                  <app-form-input>
                    <label for="departureTrain" class="label col-sm-5 col-form-label" i18n>Departure train</label>
                    <input type="text" class="input form-control" id="departureTrain" formControlName="departureTrain">
                  </app-form-input>
                </div>
              </div>
              <div class="row mt-3">
                <app-form-checkbox>
                  <input class="check form-check-input" type="checkbox" id="ownTransport" formControlName="ownTransport">
                  <label class="label form-check-label mb-3" for="ownTransport" i18n>
                    No accommodation
                  </label>
                </app-form-checkbox>
              </div>
              <hr class="mt-5 mb-5">
              <div *ngIf="isAttendeeHasChange || isContactHasChange || isTravelHasChange" class="row button-fixed">
                <div class="maincontainer w-100" style="display: flex; justify-content: end;">
                  <div class="col-md-12 row justify-content-end">
                    <button type="button" class="btn btn-classic button--color btn-size btn-responsive mr-3" (click)="cancelEdit()" i18n>
                      Cancel
                    </button>
                    <button type="submit" class="btn btn-success button--color btn-size btn-responsive" i18n>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div *ngIf="this.listOfEvents" class="tab-pane fade mt-3" id="hotel" role="tabpanel" aria-labelledby="nav-hotel">
            <form (ngSubmit)="onSubmit()" [formGroup]='hotelForm' (change)="changeHotelForm()">
              <h6 class="nowayRegular mb-4 t-participant" i18n>EVENT</h6>
              <div class="row mt-3">
                <div class="col-md-7 col-12">
                  <select class="select form-control" style="max-width: 100%;" id="eventHotel" formControlName="idEvent">
                    <option *ngFor="let event of this.listOfEvents" [value]="event.idEvent">
                      {{event.startDate}} - {{event.event}}
                    </option>
                  </select>
                </div>
                <div class="col-md-5 col-12 row" style="justify-content: center; align-items: center;">
                  <p>
                    <span class="badge-status badge-status-{{status_event_hotel.replaceAll(' ', '-')}}">{{status_event_hotel.substring(0,1).toUpperCase() + status_event_hotel.substring(1)}}</span>
                  </p>
                </div>
              </div>
              <hr>
              <div *ngIf="listOfNights">
                <h6 class="nowayRegular mb-4 t-participant" i18n>NIGHT</h6>
                <div class="pb-3" style="display: flex; justify-content: space-around; text-align: center; border-bottom: 2px solid #84C000; font-weight: bold;">
                  <span style="width: 20%;">Date</span>
                  <span style="width: 20%;">Hotel name</span>
                  <span style="width: 20%;">Room type</span>
                  <span style="width: 15%;">Price</span>
                  <span style="width: 15%;">Nbr of room left</span>
                  <span style="width: 10%;"></span>
                </div>
                <ng-container *ngFor="let list of listOfNights">
                    <app-participant-reservation-night [data]="list"></app-participant-reservation-night>
                </ng-container>
              </div>
              <div *ngIf="!listOfNights">
                <h6 class="nowayRegular mb-4 t-participant" i18n>NO NIGHT</h6>
              </div>
            </form>
          </div>
        </div>
      </div>
  </div>
</div>
