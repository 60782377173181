import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Inject } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FeaturesModule } from './features/features.module';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ToastrModule } from 'ngx-toastr';
import { DatePipe, registerLocaleData } from '@angular/common';
import { JwtModule } from '@auth0/angular-jwt';
import { JwtInterceptor } from './core/interceptors/jwt-interceptor';
import { ErrorInterceptor } from './core/interceptors/error-interceptor';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import fr from '@angular/common/locales/fr';

//import { FilterPipe } from './core/pipes/filter.pipe';

// the second parameter 'fr' is optional
registerLocaleData(fr, 'fr');

export function tokenGetter() {
  return localStorage.getItem('accessToken');
}
export const MY_FORMATS = {
  parse: {
    dateInput: ["DD.MM.yyyy", "DD/MM/yyyy", "DD,MM,yyyy", "yyyy.MM.DD", "yyyy/MM/DD", "yyyy,MM,DD"], // to accept different input styles from user
  },
  display: {
    dateInput: "yyyy/MM/DD", // display format in input field
    monthYearLabel: 'yyyy MMMM',
    dateA11yLabel: 'MMMM dd, y',//'LL',
    monthYearA11yLabel: 'MMMM yyyy'
  },
};

@NgModule({
  declarations: [
    AppComponent,
    //FilterPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    FeaturesModule,
    DragDropModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      closeButton: true,
      progressBar: true,
    }),
    JwtModule.forRoot({
      config:
      {
        tokenGetter,
        whitelistedDomains: ['http://itdistri-test.eventcatalyst.io/', 'https://itdistri-api-test.eventcatalyst.io'],
      },
    }),
    MatCheckboxModule,
  ],
  providers: [DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
  bootstrap: [AppComponent],

})
export class AppModule {
  constructor(
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string
  ) {}

  ngOnInit() {
    this._locale = $localize `en-US`;
    this._adapter.setLocale(this._locale);
  }
}
