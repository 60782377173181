<!-- Card notifications -->
<div class="card">
    <div class="card-body">

        <div class="dropright dropright-action">
            <svg class="opacity-icon " width="24px" height="24px" id="dropdownMenuButton4"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <use xlink:href="#action" />
            </svg>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton4">
                <span class="dropdown-menu-arrow"></span>
                <a class="dropdown-item" href="http://ecv5-test.eventcatalyst.io/assets/extract_test.xls" i18n>Extract Followup</a>
                <a class="dropdown-item" [routerLink]="['/admin/communication/emailing']" i18n>Resend notifications to unopened</a>
                <a class="dropdown-item" [routerLink]="['/admin/communication/emailing']" i18n>View followup list</a>
                <a class="dropdown-item" [routerLink]="['/admin/communication/emailing']" i18n>View template notifications sent</a>
            </div>
        </div>

        <h4 class="card-title" i18n>Notifications push</h4>

        <span class="timer" i18n>2018/07/09 – 05:30pm</span>
        
        <div class="bottomRows">
        
                <div class="row middleRow">
                    <div class="col d-flex align-items-baseline">
                        <span class="h2Top">{{notifReceived}}</span>
                        <span class="ml-2 valueLabel" i18n>RECEIVED NOTIF.</span>
                        <div class="col-3 taux">{{tauxProgress(taux)}}<span>&nbsp;%</span></div>
                    </div>
                </div>
        
                <div class="progressBar">   
                    <ngb-progressbar ngbPopover="{{tauxProgress(taux)}} %" popoverClass="popover-light" triggers="mouseenter:mouseleave" class="mb-2 mt-3" [showValue]="false"
                        showValue="true" [value]="taux*100">
                    </ngb-progressbar>
                </div>
        
                <div class="row lastRow">
                    <div class="col">
                        <span class="h2">{{notifSent}}</span>
                        <span class="ml-2 valueLabel" i18n>SENT NOTIF.</span>
                    </div>
                    <div class="col-1" style="margin-right: 10px">
                        <span class="mr-2 pt-2" ngbPopover="TO COMPLETE" popoverClass="popover-custom" triggers="mouseenter:mouseleave">
                            <svg class="opacity-icon " width="24px" height="24px">
                            <use xlink:href="#ic_Settings"/>
                            </svg>
                        </span>
                    </div>
                    <div class="col-1" style="margin-right: 10px">
                        <span class="mr-2 pt-2" ngbPopover="TO COMPLETE" popoverClass="popover-custom" triggers="mouseenter:mouseleave">
                            <svg class="opacity-icon " width="24px" height="24px">
                            <use xlink:href="#edit"/>
                            </svg>
                        </span>
                    </div>
                    <div class="col-1" style="margin-right:7px">
                        <span class="mr-2 pt-2" ngbPopover="TO COMPLETE" popoverClass="popover-custom" triggers="mouseenter:mouseleave">
                            <svg class="opacity-icon " width="24px" height="24px">
                            <use xlink:href="#arrow-right"/>
                            </svg>
                        </span>
                    </div>
                </div>
        
            </div>

    </div>
</div>
<!-- End card -->
