import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, templateType: string): any {
    if (templateType === '') {
      return value;
    }
    let templatesFiltered = [];
    templatesFiltered.push(value[0]);
    for (let i = 0; i < value.length; i++) {
      if (value[i].templateType === templateType) {
        templatesFiltered.push(value[i])
      }
    }
    return templatesFiltered;
  }

}
