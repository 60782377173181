<div class="eventCard mb-3">
    <div class="container-Event">
        <div class="row p-0 m-0">
            <div class="col-12 pt-3">
                <div class="row" style="max-height: 45px;">
                    <div class="col-6" style="display:flex; justify-content: start;">
                        <div class="btn-group dropleft">
                            <span placement="left" popoverClass="popover-custom-light popover-width" ngbPopover="More option" triggers="mouseenter:mouseleave" id="dropdownMenuButton6" data-toggle="dropdown">
                                <svg class="mb-2 float-right-eventCard" aria-haspopup="true"
                                aria-expanded="false">
                                    <use xlink:href="#more-vertical" />
                                </svg>
                            </span>
                            <div class="dropdown-menu dropleft" aria-labelledby="dropdownMenuButton">
                                <a placement="top" popoverClass="popover-custom-light" ngbPopover="Edit event" triggers="mouseenter:mouseleave" class="dropdown-item" [routerLink]="['/admin/agenda/events/', eventObj?.idEvent, 'edit']" i18n>Edit
                                event</a>
                                <a placement="bottom" popoverClass="popover-custom-light" ngbPopover="Delete event" triggers="mouseenter:mouseleave" class="dropdown-item" data-toggle="modal" [attr.data-target]="'#'+eventObj?.idModal"
                                style="cursor: pointer;" i18n>Delete Event</a>
                            </div>                          
                        </div>
                        <h5 class="d-flex" >
                            <b>
                                <p placement="top" popoverClass="popover-custom-light" ngbPopover="View details" triggers="mouseenter:mouseleave" class="event-function-title p-0 m-0" style="text-decoration: none;" [routerLink]="['/admin/agenda/events/', eventObj?.idEvent, 'details']">{{eventObj?.eventName}}</p>
                            </b>
                            <span placement="top" popoverClass="popover-custom-light popover-width" ngbPopover="Edit event" triggers="mouseenter:mouseleave" [routerLink]="['/admin/agenda/events/', eventObj?.idEvent, 'edit']">
                                <svg class="svgEdit edit-details-button" style="display: none;height: 20px;">
                                    <use xlink:href="#editDetails" />
                                </svg>
                            </span>
                        </h5>
                    </div>
                    <div class="col-3 d-flex">
                        <span class="font-weight-bold" style="font-size: 16px;">
                            <p class="uppercase" i18n>Date: {{eventObj?.startDate}} to {{eventObj?.endDate}}<br />
                        </span>
                    </div>
                    <div class="col-3 d-flex" style="justify-content: space-between;">
                        <div class="badges">
                            <span *ngIf="eventObj?.type=='Virtual'" style="background-color: indianred;"
                            class="badge badge-pill badge-success {{eventObj?.type}}">{{eventObj?.type}}</span>
                            <span style="background-color: #84C000 ;" *ngIf="eventObj?.type=='Onsite'"
                            class="badge badge-pill badge-success {{eventObj?.type}}">{{eventObj?.type}}</span>
                        </div>
                        <div class="capactiy">
                            <p class="uppercase event-function-capacity p-0" i18n>Capacity :{{eventObj?.capacity}}</p>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-11 d-flex justify-content-end">
                        <div class="row col-12 w-100 justify-content-between">
                            <div class="row d-flex justify-content-around collapse col-2">
                                <span style="display: flex; flex-direction: column; justify-content: start; align-items: center;">
                                    <br/>
                                    <br/>
                                    <img src={{eventObj?.logo}} style="width: 90px; height: 90px;">
                                </span>
                            </div>
                            <div class="row d-flex justify-content-center collapse col-5">
                                <br/>
                                <ul>
                                    <li>
                                        <p class="uppercase" style="font-size: 16px; width: 200px;" i18n>Location :
                                        {{eventObj?.location}}</p>
                                    </li>
                                    <li>
                                        <p class="uppercase" i18n>Registration timeslot :
                                            <br />
                                            from: {{eventObj?.startDateRegistration}}
                                            <br />
                                            to {{eventObj?.endDateRegistration}}
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-4">
                                <p class="uppercase" i18n>Participants status</p>
                                <div class="row">
                                    <div class="col-6">
                                        <p class="m-0"> <span class="badge badge-pill card__badge-invited" style="width: 80px;" i18n>INVITED</span></p>
                                        <p class="m-0"> <span class="badge badge-pill card__badge-declined" style="width: 80px;" i18n>DECLINED</span></p>
                                        <p class="m-0"> <span class="badge badge-pill card__badge-attended" style="width: 80px;" i18n>ATTENDED</span></p>
                                        <p class="m-0"> <span class="badge badge-pill card__badge-confirmed" style="width: 80px;" i18n>COMFIRMED</span></p>
                                        <p class="m-0"> <span class="badge badge-pill card__badge-noshow" style="width: 80px;" i18n>NOSHOW</span></p>
                                    </div>
                                    <div class="col-6">
                                        <p class="m-0">{{nbrInvited}}</p>
                                        <p class="m-0">{{nbrDeclined}}</p>
                                        <p class="m-0">{{nbrAttended}}</p>
                                        <p class="m-0">{{nbrConfirmed}}</p>
                                        <p class="m-0">{{nbrNoShow}}</p>
                                    </div>
                                </div>
                                <button placement="right" popoverClass="popover-custom-light" ngbPopover="View event" triggers="mouseenter:mouseleave" class="btn btn-link btn-block" [routerLink]="['/admin/agenda/events/', eventObj?.idEvent, 'details']" i18n>More
                                information</button>
                            </div>
                            <p class="event-function-description p-0 m-b-10">{{eventObj?.pitch}}</p>
                        </div>
                    </div>
                    <div class="row w-100 justify-content-between" style="margin-left: 44px;">
                        <div class="w-100 p-0 m-0" [ngClass]="collapse ? 'mb-0' : 'no_class'">
                            <span *ngIf="collapse">
                                <hr style="width: 98%; margin-left: -20px;">
                                <div class="row">
                                    <div class="col-11" style="display: flex; flex-direction: column;">
                                        <span *ngIf="eventObj?.organizer.length>0">
                                            <p class="uppercase" i18n>Organizer : {{eventObj?.organizer}}</p>
                                        </span>
                                        <p class="event-function-description p-0 m-0" style="width: 90%;" i18n>Event description :
                                        {{eventObj?.description}}</p>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                    <div class="col-12  p-0 m-0 d-flex justify-content-end">
                        <a placement="top" popoverClass="popover-custom-light" [ngbPopover]="collapse ? 'View less' : 'View more'" triggers="mouseenter:mouseleave" href="#" data-toggle="collapse" (click)="collapse = !collapse"
                        style="margin-right: 15px; margin-bottom: 10px;">
                            <svg class="ml-2" height="25px" width="25px"
                            [ngClass]="collapse ? 'svg_collapse' : 'svg_no_collapse'">
                                <use xlink:href="#right"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="{{eventObj?.idModal}}" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{eventObj?.eventName}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" i18n>
                Are you sure you want to delete the event "{{eventObj?.eventName}}"?
                <br />
                This action cannot be reversed.
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" i18n>No, it's a mistake</button>
                <button (click)="showId()" (click)="toastDelete()" type="button" id="deleteEvent" class="btn btn-primary"
                data-dismiss="modal" i18n>Yes, I'm sure !</button>
            </div>
        </div>
    </div>
</div>