<div class="card">
  <div class="card-body">
    <div class="dropright dropright-action">
      <span placement="top" popoverClass="popover-custom-light popover-width" ngbPopover="More option" triggers="mouseenter:mouseleave" id="dropdownMenuButton4" data-toggle="dropdown">
        <svg class="opacity-icon"  width="24px" height="24px" aria-haspopup="true" aria-expanded="false">
          <use xlink:href="#action" />
        </svg>
      </span>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton4">
        <a class="dropdown-item" href="http://ecv5-test.eventcatalyst.io/assets/extract_test.xls" i18n>Extract</a>
        <a class="dropdown-item" [routerLink]="['/admin/contacts/add']" i18n>Add {{data.title}}</a>
        <a class="dropdown-item" [routerLink]="['/admin/communication/emailing']" i18n>Send e-mail</a>
        <a class="dropdown-item" [routerLink]="data.urlList" i18n>View list</a>
      </div>
    </div>
    <div style="display: flex">
      <h4 placement="top" popoverClass="popover-custom-light" ngbPopover="View list" triggers="mouseenter:mouseleave" [routerLink]="data.urlList" class="card-title mb-0" i18n>{{data.title}}</h4>
    </div>
    <div class="bottomRows">
      <div class="row">
        <div class="col-12">
          <div style="display:flex;align-items:center;">
            <span placement="left" popoverClass="popover-custom-light" ngbPopover="View list" triggers="mouseenter:mouseleave" class="h2Top" [routerLink]="data.urlList" >{{data.nb_participants}}</span>
            <div style="margin-left: 20px">
              <span placement="right" popoverClass="popover-custom-light" ngbPopover="View list" triggers="mouseenter:mouseleave" class="badge-status" [ngClass]="data.stampClass" [routerLink]="data.urlList">
                {{data.status}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="row" style="padding-right:10px;align-items: baseline">
        <div class="col col-ml-0 icons_bottom">
          <div popoverClass="popover-custom-light" [ngbPopover]="data.alt_N1" triggers="mouseenter:mouseleave" style="cursor:pointer">
            <span class="h2">{{data.nb_participantsN1}}</span>
            <span class="h4 valueLabel ml-1 marginLabel">N-1</span>
          </div>
          <div>
            <span [routerLink]="data.urlList" class="float-right mt-3 resp resp-last-item" popoverClass="popover-custom-light" ngbPopover="View list"
                  triggers="mouseenter:mouseleave">
              <svg class="float-right opacity-icon" width="24px" height="24px">
                <use xlink:href="#arrow-right"/>
              </svg>
            </span>
            <span [routerLink]="['/admin/communication/emailing']" class="float-right mt-3 mr-3 resp" ngbPopover="Send e-mail" popoverClass="popover-custom-light"
                  triggers="mouseenter:mouseleave">
              <svg class="float-right hover-svg opacity-icon" width="24px" height="24px">
                <use xlink:href="#mail"/>
              </svg>
            </span>
            <span [routerLink]="['/admin/contacts/add']" class="float-right mt-3 mr-3 resp" ngbPopover="Add {{data.title}}" popoverClass="popover-custom-light"
                  triggers="mouseenter:mouseleave">
              <svg class="float-right opacity-icon" width="24px" height="24px">
                <use xlink:href="#user-plus" />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
