import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-dashboard-guest-confirmed-previous-event',
  templateUrl: './card-dashboard-guest-confirmed-previous-event.component.html',
  styleUrls: ['./card-dashboard-guest-confirmed-previous-event.component.scss']
})
export class CardDashboardGuestConfirmedPreviousEventComponent implements OnInit {

  guests = 35;
  target = 150;
  taux = this.guests / this.target;

  pastYears = [
    { year: 2018, count: 38, total: 146 },
    { year: 2017, count: 28 },
    { year: 2016, count: 25 }
  ];

  constructor() { }

  ngOnInit() {
  }

  tauxProgress(taux: number) {
    return Math.round(taux * 100);
  }

}
