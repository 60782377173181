import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-participants',
  templateUrl: './card-participants.component.html',
  styleUrls: ['./card-participants.component.scss']
})
export class CardParticipantsComponent implements OnInit {

  constructor() { }

  @Input() data: any;

  ngOnInit() {
  }

}
