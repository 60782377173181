import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-partcipants-funnel',
  templateUrl: './card-partcipants-funnel.component.html',
  styleUrls: ['./card-partcipants-funnel.component.scss']
})
export class CardPartcipantsFunnelComponent implements OnInit {
    // tslint:disable: prefer-template

  constructor() { }

  @Input() data;

  remainingParticipants;
  remainingParticipantsPerCent;
  altTotal;
  altRemain;
  urlAll;

  totalShortlist;
  totalShortlistPerCent;
  wasShortlist;
  wasShortlistPerCent;
  shortlistPerCent;
  altTotalShortlist;
  altShortlist;
  urlShortlists;

  totalThirdRow;
  totalInvited;
  totalInvitedPerCent;
  invitedPerCent;
  wasInvited;
  wasInvitedPerCent;
  altInvited;
  altTotalInvited;
  urlinvited;

  waitingPerCent;
  altWaiting;
  urlWaiting;

  rejectedPerCent;
  altRejected;
  urlRejected;

  totalFourthRow;
  totalConfirmed;
  totalConfirmedPerCent;
  confirmedPerCent;
  wasConfirmed;
  wasConfirmedPerCent;
  altConfirmed;
  altTotalConfirmed;
  urlConfirmed;

  declinedPerCent;
  altDeclined;
  urlDeclined;

  cancelledPerCent;
  cancelledAlt;
  urlCancelled;

  urlList = '/admin/contacts/';

  ngOnInit() {
    // this.totalShortlist = this.data.nb_short_att + this.data.nb_invited + this.data.nb_waiting + this.data.nb_rejected + this.data.nb_confirmed + this.data.nb_declined + this.data.nb_cancelled + this.data.nb_cancelled7 + this.data.nb_replaced;
    // this.totalShortlistPerCent = this.calculPerCent(this.totalShortlist, this.data.nb_participants);
    // this.shortlistPerCent = this.calculPerCent(this.data.nb_short_att, this.totalShortlist);
    // this.wasShortlist = this.totalShortlist - this.data.nb_short_att;
    // this.wasShortlistPerCent = this.calculPerCent(this.wasShortlist, this.totalShortlist);
    // this.altTotalShortlist = 'TOTAL SHORTLIST - ' + this.totalShortlist;
    // this.altShortlist = 'SHORTLIST - ' + this.data.nb_short_att;
    // this.urlShortlists = this.urlList + this.data.title + '_shortlist';

    // this.remainingParticipants = this.data.nb_participants - this.totalShortlist;
    // this.remainingParticipantsPerCent = this.calculPerCent(this.remainingParticipants, this.data.nb_participants);
    // this.altTotal = 'TOTAL ALL - ' + this.data.nb_participants;
    // this.altRemain = 'ALL - ' + this.remainingParticipants;
    // this.urlAll = this.urlList + this.data.title + '_all';
    this.remainingParticipants = this.data.nb_participants;
    this.remainingParticipantsPerCent = this.calculPerCent(this.remainingParticipants, this.data.nb_participants);
    this.altTotal = 'TOTAL ALL - ' + this.data.nb_participants;
    this.altRemain = 'ALL - ' + this.remainingParticipants;
    this.urlAll = this.urlList + this.data.route + '/all';

    this.totalThirdRow = this.calculPerCent(this.wasShortlist, this.data.nb_participants);
    this.totalInvited = this.data.nb_invited + this.data.nb_confirmed + this.data.nb_declined + this.data.nb_cancelled + this.data.nb_cancelled7;
    this.totalInvitedPerCent = this.calculPerCent(this.totalInvited, this.wasShortlist);
    this.invitedPerCent = this.calculPerCent(this.data.nb_invited, this.totalInvited);
    this.wasInvited = this.totalInvited - this.data.nb_invited;
    this.wasInvitedPerCent = this.calculPerCent(this.wasInvited, this.totalInvited);
    this.altInvited = 'INVITED - ' + this.data.nb_invited;
    this.altTotalInvited = 'TOTAL INVITED - ' + this.totalInvited;
    this.urlinvited = this.urlList + this.data.route + '/invited';

    this.waitingPerCent = this.calculPerCent(this.data.nb_waiting, this.wasShortlist);
    this.altWaiting = 'WAITING LIST - ' + this.data.nb_waiting;
    this.urlWaiting = this.urlList + this.data.route + '/waiting-list';

    this.rejectedPerCent = this.calculPerCent(this.data.nb_rejected, this.wasShortlist);
    this.altRejected = 'REJECTED - ' + this.data.nb_rejected;
    this.urlRejected = this.urlList + this.data.route + '/rejected';

    this.totalFourthRow = this.calculPerCent(this.wasInvited, this.data.nb_participants);
    this.totalConfirmed = this.data.nb_confirmed + this.data.nb_cancelled + this.data.nb_cancelled7;
    this.totalConfirmedPerCent = this.calculPerCent(this.totalConfirmed, this.totalFourthRow);
    this.wasConfirmed = this.totalConfirmed - this.data.nb_confirmed;
    this.wasConfirmedPerCent = this.calculPerCent(this.wasConfirmed, this.totalConfirmed);
    this.confirmedPerCent = this.calculPerCent(this.data.nb_confirmed, this.totalConfirmed);
    this.altConfirmed = 'CONFIRMED - ' + this.data.nb_confirmed;
    this.altTotalConfirmed = 'TOTAL CONFIRMED - ' + this.totalConfirmed;
    this.urlConfirmed = this.urlList + this.data.route + '/confirmed';

    this.declinedPerCent = this.calculPerCent(this.data.nb_declined, this.data.totalFourthRow);
    this.altDeclined = 'DECLINED - ' + this.data.nb_declined;
    this.urlDeclined = this.urlList + this.data.route + '/declined';

    this.cancelledPerCent = this.calculPerCent(this.data.nb_cancelled, this.data.nb_participants);
    this.cancelledAlt = 'CANCELLED - ' + this.data.nb_cancelled;
    this.urlCancelled = this.urlList + this.data.route + '/cancelled';
  }


  calculPerCent(nb, nbRef) {
    return nb / nbRef * 100 + '%';
  }

}
