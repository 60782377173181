<form [formGroup]="updateEventRegistrationFormGroup">
    <div class="col-md-12 row justify-content-between userCard">
        <div class="col-md-5 row" style="padding: 0; margin: 0;">
            <div class="col-md-12" style="padding: 0;">
                <a [routerLink]="detailUrl">
                    <h3>{{data.eventName}}</h3>
                </a>
            </div>
            <span>{{data.eventDate}} {{data.location}}</span>
        </div>
        <div *ngIf="isUpdate; then updateLabel else noUpdateLabel"></div>
        <ng-template #noUpdateLabel>
            <div class="col-md-2" style="display: flex; align-items: flex-end;">
                <p>{{typeLabel}}</p>
            </div>
            <div *ngIf="statusLabel" class="col-md-2">
                <p>
                    <span *ngIf="statusLabel" class="badge-status badge-status-{{statusLabel.toLowerCase().replaceAll(' ', '-')}}">{{statusLabel}}</span>
                </p>
                <span style="font-size: 12px">{{dateStatus}}</span>
            </div>
            <div class="col-md-2">
                <p>
                    <span *ngIf="onsiteLabel" class="badge-status badge-status-{{onsiteLabel.toLowerCase().replaceAll(' ', '')}}">{{onsiteLabel}}</span>
                </p>
                <span style="font-size: 12px">{{dateOnsite}}</span>
            </div>
            <div class="col-md-1">
                <svg class="svgEdit" id="svgEdit" (click)="updateOn()"
                style="cursor: pointer; overflow: visible"
                height="24px" width="24px">
                    <use xlink:href="#edit" />
                </svg>
            </div>
        </ng-template>
        <ng-template #updateLabel>
            <div class="col-md-2">
                <select class="select form-control" id="IdType" formControlName="idType">
                    <option *ngFor="let type of this.listOfType" [value]="type.id">
                    {{type.label}}
                    </option>
                </select>
            </div>
            <div class="col-md-2">
                <select class="select form-control" id="Status" formControlName="status">
                    <option *ngFor="let statusType of this.listOfStatus" value="{{statusType.short}}">
                    {{statusType.label}}
                    </option>
                </select>
                <span style="font-size: 12px">{{dateStatus}}</span>
            </div>
            <div class="col-md-2">
                <select class="select form-control" id="OnsiteStatus" formControlName="onsiteStatus">
                    <option *ngFor="let evtStatus of this.listOfOnsiteEvent" value="{{evtStatus.short}}">
                    {{evtStatus.label}}
                    </option>
                </select>
                <span style="font-size: 12px">{{dateOnsite}}</span>
            </div>
            <div class="col-md-1">
                <svg class="svgDelete" id="svgDelete" (click)="updateNo()"
                    style="cursor: pointer; overflow: visible; fill: red;"
                    height="20px" width="20px">
                    <use height="20px" width="20px" xlink:href="#delete" />
                </svg>
                <svg class="svgCheck" id="svgCheck" (click)="onSubmit()"
                    style="cursor: pointer; overflow: visible; fill: green;"
                    height="20px" width="20px">
                    <use height="20px" width="20px" xlink:href="#check" />
                </svg>
            </div>
        </ng-template>
    </div>
</form>