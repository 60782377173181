import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings-dashboard-option',
  templateUrl: './settings-dashboard-option.component.html',
  styleUrls: ['./settings-dashboard-option.component.scss']
})
export class SettingsDashboardOptionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
