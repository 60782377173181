import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TestBed } from '@angular/core/testing';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit {
  // @ViewChild('editor', this.editor) 

  cardData;
  displayHTML = false;
  displayTS = false;
  displaySCSS = false;

  constructor() { }
  text:string = '<app-card-participants [data]="cardData"></app-card-participants>';
  testTS = "cardData = { participantType: 'participantType', participantStatus: 'participantStatus', stampClass: 'card__badge-confirmed', nb_participants: 413, nb_participantsN1: 560, urlList: '/admin/contacts/list/guests_confirmed', urlExtract: 'http://ecv5-test.eventcatalyst.io/assets/extract_test.xls', alt_N1: '560 confirmed guests at the same time last year', urlNewList: '/admin/contacts/list/new_guests_confirmed'}";
  testSCSS:string = 'scss test';


  ngOnInit() {
    this.displayHTMLEditor()
    this.cardData = {
      participantType: 'participantType',
      participantStatus: 'participantStatus',
      stampClass: 'card__badge-confirmed',
      nb_participants: 413,
      nb_participantsN1: 560,
      urlList: '/admin/contacts/list/guests_confirmed',
      urlExtract: 'http://ecv5-test.eventcatalyst.io/assets/extract_test.xls',
      alt_N1: '560 confirmed guests at the same time last year',
      urlNewList: '/admin/contacts/list/new_guests_confirmed'
    };
  }

  displayHTMLEditor(){
    this.displayHTML = true;
    this.displayTS = false;
    this.displaySCSS = false;
  };

  displayTSEditor(){
    this.displayHTML = false;
    this.displayTS = true;
    this.displaySCSS = false; 
  };

  displaySCSSEditor(){
    this.displayHTML = false;
    this.displayTS = false;
    this.displaySCSS = true; 
  };

}
