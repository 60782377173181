import { Component, OnInit, Input } from '@angular/core';
import { EventService } from '../../../../core/http/event.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
  selector: 'app-event-agenda-item',
  templateUrl: './event-agenda-item.component.html',
  styleUrls: ['./event-agenda-item.component.scss']
})
export class EventAgendaItemComponent implements OnInit {

  @Input() item: any;
  
  long_desc: string;
  short_desc: string;
  short_title: string;
  collapse_url: string;
  collapse: boolean = false;
  cut_desc: boolean = false;
  url_edit_item: string;
  url_details_item: string;
  idEvent: any;
  itemName: any;
  id: any;
  mandatory: string;
  optional: string;

  constructor(
    private eventService: EventService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.get('id'))
        this.id = params.get('id');
    });
    this.collapse_url = "#collapse" + this.item.id;
    this.url_edit_item = "/admin/agenda/event-agenda/" + this.item.idHappening + "/edit";
    this.url_details_item = "/admin/agenda/event-agenda/" + this.item.idHappening + "/details";
    if (this.item.function.length > 35) { this.short_title = this.item.function.substr(0, 35) + '...' }
    else { this.short_title = this.item.function }
    if (this.item.description !== "") {
      if (this.item.description.length > 150) {
        this.cut_desc = true;
        this.short_desc = this.item.description.substr(0, 150) + '...';
      } else {
        this.cut_desc = false
        this.short_desc = this.item.description;
      }
    } else {
      this.cut_desc = false
      this.short_desc = "";
      this.item.description = "";
    }
    this.mandatory = this.item.typeParticipantMandatory.toString().replaceAll(",", " - ");
    this.optional = this.item.typeParticipantOptional.toString().replaceAll(",", " - ");
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  showMore() {
    this.collapse = !this.collapse;
  }
  
  deleteAgenda(id: number) {
    this.idEvent = this.item.idEvent;
    this.eventService.deleteAgenda(id).subscribe( res => {
      console.log('success', res);
      this.toastr.clear();
      this.toastr.success('Success', 'Agenda delete');
      if (this.id)
        window.location.reload();
      else
        this.router.navigateByUrl(`/admin/agenda/event-agenda/${this.idEvent}`);
    }, err => {
      console.log("L'error est : " + err);
      this.toastr.clear();
      this.toastr.error('Error', 'Agenda not delete');
    });
  }
  modalDeleteAgenda(id: number) {
    $(`#deleteModal${id}`).modal('show');
  }
}
