import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CheckDeviceService} from '../../../core/http/check-device.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-fil-ariane-emailing',
  templateUrl: './fil-ariane-emailing.component.html',
  styleUrls: ['./fil-ariane-emailing.component.scss']
})
export class FilArianeEmailingComponent implements OnInit {

  @Output() filterStatut: EventEmitter<any> = new EventEmitter();

  mobileRes: boolean;
  statut = false;
  link;
  url;
  public href: string;


  constructor(private checkDeviceService: CheckDeviceService, private router: Router) { }

  ngOnInit() {
    this.href = this.router.url;
    this.url = this.href.split('/');
    const indexMax = this.url.length;
    this.mobileRes = this.checkDeviceService.isMobileResolution;
    for (let i = 0; i < indexMax; i++) {
      this.url[i] = this.url[i].substr(0, 1).toUpperCase() + this.url[i].substr(1);
    }
  }

  displayFilters() {
    this.statut = !this.statut;
    this.filterStatut.emit(this.statut);
  }


}
