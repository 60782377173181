import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TaskListService } from '../../../../core/http/task-list.service';
// import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { DatePipe, formatDate } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-task-in-progress',
  templateUrl: './task-in-progress.component.html',
  styleUrls: ['./task-in-progress.component.scss']
})
export class TaskInProgressComponent implements OnInit {

  public modifTaskForm = this.fb.group(
    {
      id: [Math.random().toString(36).substr(2, 9)],
      dateCreate: [formatDate(new Date(), 'yyyy/MM/dd', 'en')],
      taskTitle: ['', [Validators.required]],
      statut: ['In progress', Validators.required],
      taskContent: [''],
      taskComment: [''],
      assignedTo: [''],
      dueDate: [''],
      priority: ['none', Validators.required],
    });

  public modifCommentForm = this.fb.group({
    comment: ['']
  });

  @Input()
  index;

  @Output() dataChange: EventEmitter<any> = new EventEmitter();


  public users = [' Me', ' Emma', ' Jade', ' Jules', ' Louis', ' Gabriel', ' Louise', ' Anna', ' Admin1', ' Admin2'];

  // variables
  todayDate = { day: 0, month: 0, year: 0 };
  dateIsPassed = false;
  indexList;
  indexComment;
  userAssigned = [];
  userAssignedString;
  userInitialName = [];
  titleCut;
  task;
  modalName2;
  modal2Name2;
  modal3Name2;

  constructor(private taskListService: TaskListService,
              private fb: FormBuilder,
              private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.task = this.taskListService.taskIP[this.index];
    this.cutTitle();
    this.userAssigned = this.task.assignedTo;
    this.userAssignedString = this.userAssigned.toString();
    this.getTodaysDate();
    this.checkDateIsPassed();
    this.modalName2 = '#example2Modal' + this.index;
    this.modal2Name2 = '#modif2Task' + this.index;
    this.modal3Name2 = '#modif2Comment' + this.index;
    this.taskListService.sortData();
    this.getInitials();
  }

  getInitials() {
    this.userInitialName = [];
    if (this.task.taskComment.length > 0) {
      this.task.taskComment.forEach(element => {            // Récupere l'initiale de l'user qui a comment
        this.userInitialName.push(element.user.substr(0, 1));
      });
    } else {
      this.task.taskComment = [];
    }
  }

  cutTitle() {
    if (this.task.taskTitle.length > 25) {
      this.titleCut = this.task.taskTitle.substr(0, 25) + '...';
    } else {
      this.titleCut = this.task.taskTitle;
    }
  }

  swipTask(task) {
    this.dataChange.emit();
    task.doneDate = this.todayDate;
    task.doneBy = [' Admin1'];                   // PUSH ici l'utilisateur connecté
    this.task.statut = 'Done';
    this.taskListService.taskDone.push(task);
    this.taskListService.taskIP.splice(this.index, 1);
    this.taskListService.sortData();
  }

  getTodaysDate() {
    const dayString = this.datePipe.transform(new Date(), 'dd');
    const day = Number(dayString);
    const monthString = this.datePipe.transform(new Date(), 'MM');
    const month = Number(monthString);
    const yearString = this.datePipe.transform(new Date(), 'yyyy');
    const year = Number(yearString);
    this.todayDate = { day, month, year };
  }

  checkDateIsPassed() {
    if (this.task.dueDate.year < this.todayDate.year) {
      return this.dateIsPassed = true;
    }  if (this.task.dueDate.year === this.todayDate.year) {
      if (this.task.dueDate.month < this.todayDate.month) {
          return this.dateIsPassed = true;
        }  if (this.task.dueDate.month === this.todayDate.month) {
          if (this.task.dueDate.day <= this.todayDate.day) {
            return this.dateIsPassed = true;
          } 
          return this.dateIsPassed = false;
          
        } else {
          return this.dateIsPassed = false;
        }
    }
  }

  patchFormDatas() {
    $(this.modalName2).modal('hide');
    if (this.task.priority) {
      this.modifTaskForm.patchValue({
        taskTitle: this.task.taskTitle,
        taskContent: this.task.taskContent,
        dueDate: this.task.dueDate,
        priority: this.task.priorityLabel.toLowerCase(),
      });
    } else {
      this.modifTaskForm.patchValue({
        taskTitle: this.task.taskTitle,
        taskContent: this.task.taskContent,
        dueDate: this.task.dueDate,
        priority: 'none',
      });
    }
  }

  modifComment(index) {
    $(this.modalName2).modal('hide');
    // console.log('you are trying to edit task at index' + index);
    this.indexComment = index;
    this.modifCommentForm.patchValue({
      comment: this.task.taskComment[index].comment
    });
  }

  deleteComment(index) {
    if (confirm('Are you sure to delete this comment ?')) {
      this.task.taskComment.splice(index, 1);
      this.taskListService.taskIP[this.index] = this.task;
    }
    console.log(this.task);
  }

  onChange(user) {
    this.userAssigned.push(user);
  }

  deleteUserAssigned(index) {
    this.userAssigned.splice(index, 1);
  }

  openModalDetails() {
    $(this.modalName2).modal('show');
  }

  deleteTask() {
    if (confirm('Are you sure to delete task ' + this.task.taskTitle)) {
      this.task.statutClass = 'warning';
      this.task.deleteBy = 'AdminX';         // ICI put l'user qui a delete
      this.task.deleteOn = formatDate(new Date(), 'yyyy/MM/dd', 'en');
      this.taskListService.taskIP.splice(this.index, 1);
      this.taskListService.removedTasks.push(this.task);
    }
  }

  onSubmit() {
    this.modifTaskForm.value.assignedTo = this.userAssigned;
    this.userAssignedString = this.userAssigned.toString();
    if (this.modifTaskForm.value.priority === 'none') {
      this.modifTaskForm.value.priority = false;
      this.modifTaskForm.value.priorityNumber = '0';
    } else if (this.modifTaskForm.value.priority === 'low') {
      this.modifTaskForm.value.priority = true;
      this.modifTaskForm.value.priorityLabel = 'Low';
      this.modifTaskForm.value.priorityClass = 'info';
      this.modifTaskForm.value.priorityNumber = '1';
    } else if (this.modifTaskForm.value.priority === 'medium') {
      this.modifTaskForm.value.priority = true;
      this.modifTaskForm.value.priorityLabel = 'Medium';
      this.modifTaskForm.value.priorityClass = 'warning';
      this.modifTaskForm.value.priorityNumber = '2';
    } else if (this.modifTaskForm.value.priority === 'high') {
      this.modifTaskForm.value.priority = true;
      this.modifTaskForm.value.priorityLabel = 'High';
      this.modifTaskForm.value.priorityClass = 'danger';
      this.modifTaskForm.value.priorityNumber = '3';
    }
    if (this.modifTaskForm.value.dueDate === null) {
      this.modifTaskForm.value.dueDate = { day: 0, month: 0, year: 0 };
    }
    if (this.modifTaskForm.value.taskComment != null && this.modifTaskForm.value.taskComment.length > 0) {
      const com = this.modifTaskForm.value.taskComment;
      const commentObject = {
        user: 'Admin1',    // ICI METTRE LE PSEUDO DE L'USER
        dateComment: formatDate(new Date(), 'yyyy/MM/dd', 'en'),
        comment: com
      };
      this.task.taskComment.push(commentObject);
      this.modifTaskForm.value.taskComment = this.task.taskComment;
    }
    this.task = this.modifTaskForm.value;
    this.taskListService.taskIP[this.index] = this.modifTaskForm.value;
    if (this.modifTaskForm.value.statut === 'Done') {
      this.taskListService.taskDone.push(this.task);
      this.taskListService.taskIP.splice(this.index, 1);
      this.task.doneDate = this.todayDate;
      this.task.doneBy = [' Admin1'];
      this.dataChange.emit();
    } else if (this.modifTaskForm.value.statut === 'To do') {
      this.taskListService.taskTodo.push(this.task);
      this.taskListService.taskIP.splice(this.index, 1);
    }
    this.taskListService.sortData();
    // console.log(this.task);
    this.checkDateIsPassed();
    this.getInitials();
    $(this.modal2Name2).modal('hide');
    // $(this.modalName2).modal('show');
  }

  onSubmitComment() {
    this.task.taskComment[this.indexComment].comment = this.modifCommentForm.value.comment;
    this.task.taskComment[this.indexComment].dateComment = formatDate(new Date(), 'yyyy/MM/dd', 'en');
    this.task.taskComment[this.indexComment].user = 'Admin1'; // ICI mettre l'user qui a modifié le commentaire
    this.taskListService.taskIP[this.index] = this.task;
    this.getInitials();
    $(this.modal3Name2).modal('hide');
    $(this.modalName2).modal('show');
    console.log(this.task);
  }

}
