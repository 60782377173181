import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { HotelService } from 'src/app/core/http/hotel.service';
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
  selector: 'app-hotels-booking-list',
  templateUrl: './hotels-booking-list.component.html',
  styleUrls: ['./hotels-booking-list.component.scss']
})
export class HotelsBookingListComponent implements OnInit {

  @Input() data: any;

  route: string;
  public href: string;

  constructor(
    private fb: FormBuilder,
    private hotelService: HotelService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  url_edit_hotel: string;
  url_add_night: string;
  short_desc: string;
  collapse: boolean = false;
  cut_desc: boolean = false;
  nbr_nights: number = 0;
  nbr_rooms: number = 0;
  listOfNightByHotel: any;
  url_details_hotel: string;
  url_add_room_type: string;

  ngOnInit() {
    this.url_edit_hotel = `/admin/logistics/hotels/${this.data.idHotel}/edit`;
    this.url_details_hotel = `/admin/logistics/hotels/${this.data.idHotel}/details`;
    this.url_add_night = `/admin/logistics/night/${this.data.idEvent}/add`;
    this.hotelService.getNightByHotel(this.data.idHotel, this.data.idEvent).subscribe(res => {
      this.listOfNightByHotel = res;
      if (this.listOfNightByHotel) {
        for (let i = 0; this.listOfNightByHotel.length > i; i++) {
          this.nbr_rooms += this.listOfNightByHotel[i].nbrRoom;
          if (this.listOfNightByHotel[i - 1]?.date != this.listOfNightByHotel[i].date) { this.nbr_nights += 1; }
        }
      }
    });
    if (this.data.description !== "") {
      if (this.data.description.length > 150) {
        this.cut_desc = true;
        this.short_desc = this.data.description.substr(0, 150) + '...';
      } else {
        this.cut_desc = false
        this.short_desc = this.data.description;
      }
    } else {
      this.cut_desc = false
      this.short_desc = "";
      this.data.description = "";
    }
  }
  ngOnChanges() {
  }

  showMore() {
    this.collapse = !this.collapse;
  }
  deleteHotel(id: number) {
    this.hotelService.deleteHotel(id).subscribe( res => {
      console.log('success', res);
      this.toastr.clear();
      this.toastr.success('Success', 'Agenda delete');
      window.location.reload();
    }, err => {
      console.log("L'error est : " + err);
      this.toastr.clear();
      this.toastr.error('Error', 'Agenda not delete');
    });
  }
  modalDeleteHotel(id: number) {
    $(`#deleteModal${id}`).modal('show');
  }
}
