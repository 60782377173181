import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-edit-attendee',
  templateUrl: './card-edit-attendee.component.html',
  styleUrls: ['./card-edit-attendee.component.scss']
})
export class CardEditAttendeeComponent implements OnInit {

  @Input() data;
  participantType;

  constructor() { }

  ngOnInit() {
    this.participantType = this.data.participantType;

  }

}
