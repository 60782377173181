<div class="maincontainer">
  <form (ngSubmit)="editHotelsFormGroup.valid && onSubmit()" [formGroup]='editHotelsFormGroup' (change)="changeHotelsForm()">
      <div class="mt-3">
          <app-form-input>
              <label for="hotelName" class="col-md-2 label col-form-label" i18n>Hotel Name</label>
              <input type="text" class="input form-control" id="hotelName" formControlName="hotelName">
          </app-form-input>
          <hr>
          <div class="form-group row">
              <div class="col-md-6">
                  <app-form-input>
                      <label for="adressHotel" class="col-md-4 label col-form-label" i18n>Address</label>
                      <input type="text" class="input form-control" id="address" formControlName="address">
                  </app-form-input>
                  <app-form-input>
                      <label for="cityHotel" class="col-md-4 label col-form-label" i18n>City</label>
                      <input type="text" class="input form-control" id="city" formControlName="city">
                  </app-form-input>
                  <app-form-select>
                      <label for="countryHotel" class="col-md-4 label col-form-label" i18n>Country</label>
                      <select class="select form-control" id="countryHotel" formControlName="idCountry">
                          <option value="0" i18n>Please select</option>
                          <option *ngFor="let country of listOfCountry" value="{{country.id}}">
                          {{country.label}}
                          </option>
                      </select>
                  </app-form-select>
              </div>
              <div class="col-md-6">
                  <app-form-input>
                      <label for="zipCodeHotel" class="col-md-4 label col-form-label" i18n>Zip Code</label>
                      <input type="text" class="input form-control" id="zipCode" formControlName="zipCode">
                  </app-form-input>
                  <app-form-input>
                      <label for="stateHotel" class="col-md-4 label col-form-label" i18n>State</label>
                      <input  type="text" class="input form-control" id="state" formControlName="state">
                  </app-form-input>
              </div>
          </div>
          <hr>
          <div class="row">
              <div class="col-md-6">
                  <app-form-input>
                      <label for="phoneHotel" class="col-md-4 label col-form-label" i18n>Phone Number</label>
                      <input type="text" class="input form-control" id="mobile" formControlName="mobile">
                  </app-form-input>
              </div>
              <div class="col-md-6">
                  <app-form-input>
                      <label for="faxHotel" class="col-md-4 label col-form-label" i18n>Fax</label>
                      <input type="text" class="input form-control" id="faxHotel" formControlName="fax">
                  </app-form-input>
              </div>
              <div class="col-md-12">
              <app-form-input>
                  <label for="urlHotel" class="col-md-2 label labelSpe col-form-label" i18n>Url</label>
                  <input type="text" class="input form-control" id="url" formControlName="url">
              </app-form-input>
              </div>
          </div>
          <hr>
          <div class="row justify-content-center">
              <div class="col-md-12">
                  <div class="form-group">
                      <label for="descriptionHotel" i18n>Hotel description: </label>
                      <textarea class="form-control" id="descriptionHotel" formControlName="description"
                      rows="5" maxlength="1000"></textarea>
                  </div>
              </div>
              <div *ngIf="isHotelHasChange" class="row button-fixed">
                <div class="maincontainer w-100" style="display: flex; justify-content: end;">
                  <div class="col-md-12 row justify-content-end">
                    <button type="button" class="btn btn-classic button--color btn-size btn-responsive mr-3" (click)="cancelEdit()" i18n>
                      Cancel
                    </button>
                    <button type="submit" class="btn btn-success button--color btn-size btn-responsive" i18n>
                      Save
                    </button>
                  </div>
                </div>
              </div>
          </div>
      </div>   
  </form>
</div>