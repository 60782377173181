import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';

@Component({
  selector: 'app-card-pie-chart',
  templateUrl: './card-pie-chart.html',
  styleUrls: ['./card-pie-chart.scss']
})
export class CardPieChart implements OnInit {
  @Input() data;
  constructor(
    private router: Router
  ) {}

  nb_total: number = 0;
  url: any;
  route: any;
  public href: string;

  ngOnInit() {
    this.href = this.router.url;
    this.url = this.href.split('/');
    this.route = this.url[this.url.length - 1];
    for (let i = 1; this.data[i]; i++) {
      if (this.data[i].dataLabel != "declined" && this.data[i].dataLabel != "cancelled" && this.route != "contacts") {
        this.data.splice(i, 1);
        i--;
      } else if (this.data[i].dataLabel != "declined" && this.data[i].dataLabel != "rejected" && this.route == "contacts") {
        this.data.splice(i, 1);
        i--;
      } else { this.nb_total += this.data[i].data; }
    }
  }
}
