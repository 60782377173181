import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../core/http/dashboard.service';
import { ParticipantService } from '../../core/http/participant.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  constructor(
    private DashboardDataService: DashboardService,
    private ParticipantService: ParticipantService
  ) { }

  listOfParticipantAll: any;
  listOfParticipantConf: any;
  listOfParticipantInv: any;
  listOfParticipantShort: any;
  nbAll: number;
  nbConf: number;
  nbInv:number;
  nbShort: number;
  dataCardSend: any = [];
  dataCardSet = this.DashboardDataService;
  statut = false;
  link: any;
  url: any;
  public href: string;

  async ngOnInit() {
    await this.initListApi();
    this.checkData();
    this.initCard();
  }
  async initListApi() {
    this.listOfParticipantAll = await this.ParticipantService.getParticipantIdWithCompanyIdAndNameByStatus("all").toPromise();
    this.listOfParticipantConf = await this.ParticipantService.getParticipantIdWithCompanyIdAndNameByStatus("confirmed").toPromise();
    this.listOfParticipantInv = await this.ParticipantService.getParticipantIdWithCompanyIdAndNameByStatus("invited").toPromise();
    this.listOfParticipantShort = await this.ParticipantService.getParticipantIdWithCompanyIdAndNameByStatus("shortlist").toPromise();
  }
  checkData() {
    this.nbAll = this.listOfParticipantAll ? this.listOfParticipantAll.length : 0;
    this.nbConf = this.listOfParticipantConf ? this.listOfParticipantConf.length : 0;
    this.nbInv = this.listOfParticipantInv ? this.listOfParticipantInv.length : 0;
    this.nbShort = this.listOfParticipantShort ? this.listOfParticipantShort.length : 0;
  }
  initCard() {
    this.dataCardSend.push(this.dataCardSet.createCard1("all contacts", "all", this.nbAll, ''));
    this.dataCardSend.push(this.dataCardSet.createCard1("active contacts", "active", this.nbConf, ''));
    this.dataCardSend.push(this.dataCardSet.createCard1("occasional contacts", "occasional", this.nbInv, ''));
    this.dataCardSend.push(this.dataCardSet.createCard1("pending contacts", "pending", this.nbShort, ''));
    this.dataCardSend.push(this.dataCardSet.createCard5("emails", "emailing", "29/12/1997", 500, 1000));
    this.dataCardSend.push(this.dataCardSet.createCard5("sms", "emailing", "29/12/1997", 500, 1000));
    this.dataCardSend.push(this.dataCardSet.createCard5("notification", "emailing", "29/12/1997", 500, 1000));
  }
}
