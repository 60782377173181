import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetDataService {

  // FAKE COMPANY + PARTICIPANT LISTS

  public companyList = [
    {
      idCompany: 1,
      typeCompany: 'Invited',
      companyName: 'Event Catalyst',
      brandCompany: '',
      groupCompany: '',
      address1Company: 'Tour CIT - Rue de l\'arrivée',
      address2Company: '',
      zipCodeCompany: 75015,
      cityCompany: 'Paris',
      stateCompany: '',
      countryCompany: 'France',
      switchboardPhoneCompany: '+33 (0)1 54 65 78 96',
      faxCompany: '+33 (0)1 54 65 78 96',
      websiteCompany: 'http://www.eventcatalyst.com',
      website2Company: '',
      website3Company: '',

      accountManagerCompany: 'Roger Rabbit',
      profileUpdateCompany: 'false',
      groupInternalCompany: '',
      parentAccountCompany: '',
      opportunityIDCompany: '',
      accountIDCompany: '',
    },
    {
      idCompany: 2,
      typeCompany: 'Guest',
      companyName: 'Lenovo',
      brandCompany: '',
      groupCompany: '',
      address1Company: 'Rue du vieux Port',
      address2Company: '',
      zipCodeCompany: 13000,
      cityCompany: 'Marseille',
      stateCompany: '',
      countryCompany: 'France',
      switchboardPhoneCompany: '+33 (0)1 54 65 78 96',
      faxCompany: '+33 (0)1 54 65 78 96',
      websiteCompany: 'http://www.lenovo.com',
      website2Company: '',
      website3Company: '',

      accountManagerCompany: 'Roger Rabbit',
      profileUpdateCompany: 'false',
      groupInternalCompany: '',
      parentAccountCompany: '',
      opportunityIDCompany: '',
      accountIDCompany: '',
    },
    {
      idCompany: 3,
      typeCompany: 'Guest',
      companyName: 'Microsoft',
      brandCompany: '',
      groupCompany: '',
      address1Company: '52 Rue de Rivoli',
      address2Company: '',
      zipCodeCompany: 75000,
      cityCompany: 'Paris',
      stateCompany: '',
      countryCompany: 'France',
      switchboardPhoneCompany: '+33 (0)1 54 65 78 96',
      faxCompany: '+33 (0)1 54 65 78 96',
      websiteCompany: 'http://www.microsoft.com',
      website2Company: '',
      website3Company: '',

      accountManagerCompany: 'Roger Rabbit',
      profileUpdateCompany: 'false',
      groupInternalCompany: '',
      parentAccountCompany: '',
      opportunityIDCompany: '',
      accountIDCompany: '',
    },
    {
      idCompany: 4,
      typeCompany: 'Guest',
      companyName: 'Microsoft',
      brandCompany: '',
      groupCompany: '',
      address1Company: '21 Viale Pasubio',
      address2Company: '',
      zipCodeCompany: 20154,
      cityCompany: 'Milan',
      stateCompany: '',
      countryCompany: 'Italie',
      switchboardPhoneCompany: '+39 02 3859 1444',
      faxCompany: '+39 02 3859 1444',
      websiteCompany: 'http://www.microsoft.com',
      website2Company: '',
      website3Company: '',

      accountManagerCompany: 'Roger Rabbit',
      profileUpdateCompany: 'false',
      groupInternalCompany: '',
      parentAccountCompany: '',
      opportunityIDCompany: '',
      accountIDCompany: '',
    },
    {
      idCompany: 5,
      typeCompany: 'Visitor',
      companyName: 'Société Générale',
      brandCompany: '',
      groupCompany: '',
      address1Company: 'La défense',
      address2Company: '',
      zipCodeCompany: 92000,
      cityCompany: 'Nanterre',
      stateCompany: '',
      countryCompany: 'France',
      switchboardPhoneCompany: '+33 (0)1 54 65 78 96',
      faxCompany: '+33 (0)1 54 65 78 96',
      websiteCompany: 'http://www.sociétégénérale.com',
      website2Company: '',
      website3Company: '',

      accountManagerCompany: 'Roger Rabbit',
      profileUpdateCompany: 'false',
      groupInternalCompany: '',
      parentAccountCompany: '',
      opportunityIDCompany: '',
      accountIDCompany: '',
    },
    {
      idCompany: 6,
      typeCompany: 'Visitor',
      companyName: 'Société Générale',
      brandCompany: '',
      groupCompany: '',
      address1Company: 'La défense',
      address2Company: '',
      zipCodeCompany: 75000,
      cityCompany: 'Paris',
      stateCompany: '',
      countryCompany: 'France',
      switchboardPhoneCompany: '+33 (0)1 54 65 78 96',
      faxCompany: '+33 (0)1 54 65 78 96',
      websiteCompany: 'http://www.sociétégénérale.com',
      website2Company: '',
      website3Company: '',

      accountManagerCompany: 'Roger Rabbit',
      profileUpdateCompany: 'false',
      groupInternalCompany: '',
      parentAccountCompany: '',
      opportunityIDCompany: '',
      accountIDCompany: '',
    }
  ]

  public participantsList = [
    {
      attendee: {
        idParticipant: 1,
        type: 'Guest',
        companyID: 1,
        gender: 'Mr',
        firstName: 'Thelonious',
        lastName: 'Monk',
        title: '',
        email: 'thelonious.monk@microsoft.com',
        jobPosition: 'Lead Software Developer',
        function: 'Academic',

        directPhone: '+33 (0)1 00 54 87 96',
        mobile: '+33 (0)6 58 78 69 63',
        mobile2: '',

        address1: '37 Quai du president Roosevelt',
        address2: '',
        city: 'Issy-les-moulineaux',
        state: '',
        zipCode: 92130,
        country: 'France',

        emergencyContactName: '',
        emergencyContactNumber: '',

        assistantBool: true,
        assistant: {
          firstName: 'Alfred',
          lastName: 'Hitchcock',
          title: '',
          jobPosition: 'Director',
          directPhone: '+33 (0)1 54 65 78 96',
          mobile: '+33 (0)6 54 65 78 96',
          email: 'alfred.hitckock@microsoft.com'
        },

      }, participation: {
        pass: '',
        status: 'Declined',
        main: true,
        free: true,
        oneOnOne: false,
        vip: true,
        speaker: false,
        hotel: true,
        travel: false,
        doNotPostEmail: true,
        incident: false,
        profileUpdate: true,
        speakerType: 2,
        generalComments: '',
        couponUsed: '',
      }, travel: {
        arrivalTravelOption: '',
        arrivalDate: '',
        departureTravelOption: '',
        departureDate: '',
        departureCity: '',
        departureCountry: '',
        prefferedStation: '',
        prefferedAirline: '',
        flyerNumber: '',
        alternativeDate: '',
        readOnly: false,
        dietaryRequirements: '',
        otherRequirements: '',
        checkInDate: '',
        checkOutDate: '',
        roomType: '',
        noAccommodation: false,
      }, hotel: {
        hotelName: '',
        room: '',
        additionnalComments: '',
        notifyAttendee: true,
      }
    },

    {
      attendee: {
        idParticipant: 2,
        type: 'Guest',
        companyID: 1,
        gender: 'Mr',
        firstName: 'John',
        lastName: 'Doe',
        title: '',
        email: 'john.doe@microsoft.com',
        jobPosition: 'Lead Software Developer',
        function: 'Academic',

        directPhone: '+33 (0)1 00 54 87 96',
        mobile: '+33 (0)6 58 78 69 63',
        mobile2: '',

        address1: '37 Quai du president Roosevelt',
        address2: '',
        city: 'Issy-les-moulineaux',
        state: '',
        zipCode: 92130,
        country: 'France',

        emergencyContactName: '',
        emergencyContactNumber: '',

        assistantBool: true,
        assistant: {
          firstName: 'Alfred',
          lastName: 'Hitchcock',
          title: '',
          jobPosition: 'Director',
          directPhone: '+33 (0)1 54 65 78 96',
          mobile: '+33 (0)6 54 65 78 96',
          email: 'alfred.hitckock@microsoft.com'
        },

      }, participation: {
        pass: '',
        status: 'Declined',
        main: true,
        free: true,
        oneOnOne: false,
        vip: true,
        speaker: false,
        hotel: true,
        travel: false,
        doNotPostEmail: true,
        incident: false,
        profileUpdate: true,
        speakerType: 2,
        generalComments: '',
        couponUsed: '',
      }, travel: {
        arrivalTravelOption: '',
        arrivalDate: '',
        departureTravelOption: '',
        departureDate: '',
        departureCity: '',
        departureCountry: '',
        prefferedStation: '',
        prefferedAirline: '',
        flyerNumber: '',
        alternativeDate: '',
        readOnly: false,
        dietaryRequirements: '',
        otherRequirements: '',
        checkInDate: '',
        checkOutDate: '',
        roomType: '',
        noAccommodation: false,
      }, hotel: {
        hotelName: '',
        room: '',
        additionnalComments: '',
        notifyAttendee: true,
      }
    },

    {
      attendee: {
        idParticipant: 3,
        type: 'Guest',
        companyID: 1,
        gender: 'Mrs',
        firstName: 'Jane',
        lastName: 'Doe',
        title: '',
        email: 'jane.doe@microsoft.com',
        jobPosition: 'Lead Software Developer',
        function: 'Academic',

        directPhone: '+33 (0)1 00 54 87 96',
        mobile: '+33 (0)6 58 78 69 63',
        mobile2: '',

        address1: '37 Quai du president Roosevelt',
        address2: '',
        city: 'Issy-les-moulineaux',
        state: '',
        zipCode: 92130,
        country: 'France',

        emergencyContactName: '',
        emergencyContactNumber: '',

        assistantBool: true,
        assistant: {
          firstName: 'Alfred',
          lastName: 'Hitchcock',
          title: '',
          jobPosition: 'Director',
          directPhone: '+33 (0)1 54 65 78 96',
          mobile: '+33 (0)6 54 65 78 96',
          email: 'alfred.hitckock@microsoft.com'
        },

      }, participation: {
        pass: '',
        status: 'Declined',
        main: true,
        free: true,
        oneOnOne: false,
        vip: true,
        speaker: false,
        hotel: true,
        travel: false,
        doNotPostEmail: true,
        incident: false,
        profileUpdate: true,
        speakerType: 2,
        generalComments: '',
        couponUsed: '',
      }, travel: {
        arrivalTravelOption: '',
        arrivalDate: '',
        departureTravelOption: '',
        departureDate: '',
        departureCity: '',
        departureCountry: '',
        prefferedStation: '',
        prefferedAirline: '',
        flyerNumber: '',
        alternativeDate: '',
        readOnly: false,
        dietaryRequirements: '',
        otherRequirements: '',
        checkInDate: '',
        checkOutDate: '',
        roomType: '',
        noAccommodation: false,
      }, hotel: {
        hotelName: '',
        room: '',
        additionnalComments: '',
        notifyAttendee: true,
      }
    },

    {
      attendee: {
        idParticipant: 4,
        type: 'Guest',
        companyID: 1,
        gender: 'Mr',
        firstName: 'Elon',
        lastName: 'Musk',
        title: '',
        email: 'elon.musk@microsoft.com',
        jobPosition: 'Lead Software Developer',
        function: 'Academic',

        directPhone: '+33 (0)1 00 54 87 96',
        mobile: '+33 (0)6 58 78 69 63',
        mobile2: '',

        address1: '37 Quai du president Roosevelt',
        address2: '',
        city: 'Issy-les-moulineaux',
        state: '',
        zipCode: 92130,
        country: 'France',

        emergencyContactName: '',
        emergencyContactNumber: '',

        assistantBool: true,
        assistant: {
          firstName: 'Alfred',
          lastName: 'Hitchcock',
          title: '',
          jobPosition: 'Director',
          directPhone: '+33 (0)1 54 65 78 96',
          mobile: '+33 (0)6 54 65 78 96',
          email: 'alfred.hitckock@microsoft.com'
        },

      }, participation: {
        pass: '',
        status: 'Declined',
        main: true,
        free: true,
        oneOnOne: false,
        vip: true,
        speaker: false,
        hotel: true,
        travel: false,
        doNotPostEmail: true,
        incident: false,
        profileUpdate: true,
        speakerType: 2,
        generalComments: '',
        couponUsed: '',
      }, travel: {
        arrivalTravelOption: '',
        arrivalDate: '',
        departureTravelOption: '',
        departureDate: '',
        departureCity: '',
        departureCountry: '',
        prefferedStation: '',
        prefferedAirline: '',
        flyerNumber: '',
        alternativeDate: '',
        readOnly: false,
        dietaryRequirements: '',
        otherRequirements: '',
        checkInDate: '',
        checkOutDate: '',
        roomType: '',
        noAccommodation: false,
      }, hotel: {
        hotelName: '',
        room: '',
        additionnalComments: '',
        notifyAttendee: true,
      }
    },

    {
      attendee: {
        idParticipant: 5,
        type: 'Guest',
        companyID: 1,
        gender: 'Mrs',
        firstName: 'Alice',
        lastName: 'Walton',
        title: '',
        email: 'alice.walton@microsoft.com',
        jobPosition: 'Lead Software Developer',
        function: 'Academic',

        directPhone: '+33 (0)1 00 54 87 96',
        mobile: '+33 (0)6 58 78 69 63',
        mobile2: '',

        address1: '37 Quai du president Roosevelt',
        address2: '',
        city: 'Issy-les-moulineaux',
        state: '',
        zipCode: 92130,
        country: 'France',

        emergencyContactName: '',
        emergencyContactNumber: '',

        assistantBool: true,
        assistant: {
          firstName: 'Alfred',
          lastName: 'Hitchcock',
          title: '',
          jobPosition: 'Director',
          directPhone: '+33 (0)1 54 65 78 96',
          mobile: '+33 (0)6 54 65 78 96',
          email: 'alfred.hitckock@microsoft.com'
        },

      }, participation: {
        pass: '',
        status: 'Declined',
        main: true,
        free: true,
        oneOnOne: false,
        vip: true,
        speaker: false,
        hotel: true,
        travel: false,
        doNotPostEmail: true,
        incident: false,
        profileUpdate: true,
        speakerType: 2,
        generalComments: '',
        couponUsed: '',
      }, travel: {
        arrivalTravelOption: '',
        arrivalDate: '',
        departureTravelOption: '',
        departureDate: '',
        departureCity: '',
        departureCountry: '',
        prefferedStation: '',
        prefferedAirline: '',
        flyerNumber: '',
        alternativeDate: '',
        readOnly: false,
        dietaryRequirements: '',
        otherRequirements: '',
        checkInDate: '',
        checkOutDate: '',
        roomType: '',
        noAccommodation: false,
      }, hotel: {
        hotelName: '',
        room: '',
        additionnalComments: '',
        notifyAttendee: true,
      }
    },

    {
      attendee: {
        idParticipant: 6,
        type: 'Guest',
        companyID: 1,
        gender: 'Mr',
        firstName: 'Maria',
        lastName: 'Sharapova',
        title: '',
        email: 'maria.sharapova@microsoft.com',
        jobPosition: 'Lead Software Developer',
        function: 'Academic',

        directPhone: '+33 (0)1 00 54 87 96',
        mobile: '+33 (0)6 58 78 69 63',
        mobile2: '',

        address1: '37 Quai du president Roosevelt',
        address2: '',
        city: 'Issy-les-moulineaux',
        state: '',
        zipCode: 92130,
        country: 'France',

        emergencyContactName: '',
        emergencyContactNumber: '',

        assistantBool: true,
        assistant: {
          firstName: 'Alfred',
          lastName: 'Hitchcock',
          title: '',
          jobPosition: 'Director',
          directPhone: '+33 (0)1 54 65 78 96',
          mobile: '+33 (0)6 54 65 78 96',
          email: 'alfred.hitckock@microsoft.com'
        },

      }, participation: {
        pass: '',
        status: 'Declined',
        main: true,
        free: true,
        oneOnOne: false,
        vip: true,
        speaker: false,
        hotel: true,
        travel: false,
        doNotPostEmail: true,
        incident: false,
        profileUpdate: true,
        speakerType: 2,
        generalComments: '',
        couponUsed: '',
      }, travel: {
        arrivalTravelOption: '',
        arrivalDate: '',
        departureTravelOption: '',
        departureDate: '',
        departureCity: '',
        departureCountry: '',
        prefferedStation: '',
        prefferedAirline: '',
        flyerNumber: '',
        alternativeDate: '',
        readOnly: false,
        dietaryRequirements: '',
        otherRequirements: '',
        checkInDate: '',
        checkOutDate: '',
        roomType: '',
        noAccommodation: false,
      }, hotel: {
        hotelName: '',
        room: '',
        additionnalComments: '',
        notifyAttendee: true,
      }
    },

    {
      attendee: {
        idParticipant: 7,
        type: 'Guest',
        companyID: 2,
        gender: 'Mr',
        firstName: 'Thelonious',
        lastName: 'Monk',
        title: '',
        email: 'thelonious.monk@microsoft.com',
        jobPosition: 'Lead Software Developer',
        function: 'Academic',

        directPhone: '+33 (0)1 00 54 87 96',
        mobile: '+33 (0)6 58 78 69 63',
        mobile2: '',

        address1: '37 Quai du president Roosevelt',
        address2: '',
        city: 'Issy-les-moulineaux',
        state: '',
        zipCode: 92130,
        country: 'France',

        emergencyContactName: '',
        emergencyContactNumber: '',

        assistantBool: true,
        assistant: {
          firstName: 'Alfred',
          lastName: 'Hitchcock',
          title: '',
          jobPosition: 'Director',
          directPhone: '+33 (0)1 54 65 78 96',
          mobile: '+33 (0)6 54 65 78 96',
          email: 'alfred.hitckock@microsoft.com'
        },

      }, participation: {
        pass: '',
        status: 'Declined',
        main: true,
        free: true,
        oneOnOne: false,
        vip: true,
        speaker: false,
        hotel: true,
        travel: false,
        doNotPostEmail: true,
        incident: false,
        profileUpdate: true,
        speakerType: 2,
        generalComments: '',
        couponUsed: '',
      }, travel: {
        arrivalTravelOption: '',
        arrivalDate: '',
        departureTravelOption: '',
        departureDate: '',
        departureCity: '',
        departureCountry: '',
        prefferedStation: '',
        prefferedAirline: '',
        flyerNumber: '',
        alternativeDate: '',
        readOnly: false,
        dietaryRequirements: '',
        otherRequirements: '',
        checkInDate: '',
        checkOutDate: '',
        roomType: '',
        noAccommodation: false,
      }, hotel: {
        hotelName: '',
        room: '',
        additionnalComments: '',
        notifyAttendee: true,
      }
    },

    {
      attendee: {
        idParticipant: 8,
        type: 'Guest',
        companyID: 2,
        gender: 'Mr',
        firstName: 'John',
        lastName: 'Doe',
        title: '',
        email: 'john.doe@microsoft.com',
        jobPosition: 'Lead Software Developer',
        function: 'Academic',

        directPhone: '+33 (0)1 00 54 87 96',
        mobile: '+33 (0)6 58 78 69 63',
        mobile2: '',

        address1: '37 Quai du president Roosevelt',
        address2: '',
        city: 'Issy-les-moulineaux',
        state: '',
        zipCode: 92130,
        country: 'France',

        emergencyContactName: '',
        emergencyContactNumber: '',

        assistantBool: true,
        assistant: {
          firstName: 'Alfred',
          lastName: 'Hitchcock',
          title: '',
          jobPosition: 'Director',
          directPhone: '+33 (0)1 54 65 78 96',
          mobile: '+33 (0)6 54 65 78 96',
          email: 'alfred.hitckock@microsoft.com'
        },

      }, participation: {
        pass: '',
        status: 'Declined',
        main: true,
        free: true,
        oneOnOne: false,
        vip: true,
        speaker: false,
        hotel: true,
        travel: false,
        doNotPostEmail: true,
        incident: false,
        profileUpdate: true,
        speakerType: 2,
        generalComments: '',
        couponUsed: '',
      }, travel: {
        arrivalTravelOption: '',
        arrivalDate: '',
        departureTravelOption: '',
        departureDate: '',
        departureCity: '',
        departureCountry: '',
        prefferedStation: '',
        prefferedAirline: '',
        flyerNumber: '',
        alternativeDate: '',
        readOnly: false,
        dietaryRequirements: '',
        otherRequirements: '',
        checkInDate: '',
        checkOutDate: '',
        roomType: '',
        noAccommodation: false,
      }, hotel: {
        hotelName: '',
        room: '',
        additionnalComments: '',
        notifyAttendee: true,
      }
    },

    {
      attendee: {
        idParticipant: 9,
        type: 'Guest',
        companyID: 2,
        gender: 'Mrs',
        firstName: 'Jane',
        lastName: 'Doe',
        title: '',
        email: 'jane.doe@microsoft.com',
        jobPosition: 'Lead Software Developer',
        function: 'Academic',

        directPhone: '+33 (0)1 00 54 87 96',
        mobile: '+33 (0)6 58 78 69 63',
        mobile2: '',

        address1: '37 Quai du president Roosevelt',
        address2: '',
        city: 'Issy-les-moulineaux',
        state: '',
        zipCode: 92130,
        country: 'France',

        emergencyContactName: '',
        emergencyContactNumber: '',

        assistantBool: true,
        assistant: {
          firstName: 'Alfred',
          lastName: 'Hitchcock',
          title: '',
          jobPosition: 'Director',
          directPhone: '+33 (0)1 54 65 78 96',
          mobile: '+33 (0)6 54 65 78 96',
          email: 'alfred.hitckock@microsoft.com'
        },

      }, participation: {
        pass: '',
        status: 'Declined',
        main: true,
        free: true,
        oneOnOne: false,
        vip: true,
        speaker: false,
        hotel: true,
        travel: false,
        doNotPostEmail: true,
        incident: false,
        profileUpdate: true,
        speakerType: 2,
        generalComments: '',
        couponUsed: '',
      }, travel: {
        arrivalTravelOption: '',
        arrivalDate: '',
        departureTravelOption: '',
        departureDate: '',
        departureCity: '',
        departureCountry: '',
        prefferedStation: '',
        prefferedAirline: '',
        flyerNumber: '',
        alternativeDate: '',
        readOnly: false,
        dietaryRequirements: '',
        otherRequirements: '',
        checkInDate: '',
        checkOutDate: '',
        roomType: '',
        noAccommodation: false,
      }, hotel: {
        hotelName: '',
        room: '',
        additionnalComments: '',
        notifyAttendee: true,
      }
    },

    {
      attendee: {
        idParticipant: 10,
        type: 'Guest',
        companyID: 2,
        gender: 'Mr',
        firstName: 'Elon',
        lastName: 'Musk',
        title: '',
        email: 'elon.musk@microsoft.com',
        jobPosition: 'Lead Software Developer',
        function: 'Academic',

        directPhone: '+33 (0)1 00 54 87 96',
        mobile: '+33 (0)6 58 78 69 63',
        mobile2: '',

        address1: '37 Quai du president Roosevelt',
        address2: '',
        city: 'Issy-les-moulineaux',
        state: '',
        zipCode: 92130,
        country: 'France',

        emergencyContactName: '',
        emergencyContactNumber: '',

        assistantBool: true,
        assistant: {
          firstName: 'Alfred',
          lastName: 'Hitchcock',
          title: '',
          jobPosition: 'Director',
          directPhone: '+33 (0)1 54 65 78 96',
          mobile: '+33 (0)6 54 65 78 96',
          email: 'alfred.hitckock@microsoft.com'
        },

      }, participation: {
        pass: '',
        status: 'Declined',
        main: true,
        free: true,
        oneOnOne: false,
        vip: true,
        speaker: false,
        hotel: true,
        travel: false,
        doNotPostEmail: true,
        incident: false,
        profileUpdate: true,
        speakerType: 2,
        generalComments: '',
        couponUsed: '',
      }, travel: {
        arrivalTravelOption: '',
        arrivalDate: '',
        departureTravelOption: '',
        departureDate: '',
        departureCity: '',
        departureCountry: '',
        prefferedStation: '',
        prefferedAirline: '',
        flyerNumber: '',
        alternativeDate: '',
        readOnly: false,
        dietaryRequirements: '',
        otherRequirements: '',
        checkInDate: '',
        checkOutDate: '',
        roomType: '',
        noAccommodation: false,
      }, hotel: {
        hotelName: '',
        room: '',
        additionnalComments: '',
        notifyAttendee: true,
      }
    },

    {
      attendee: {
        idParticipant: 10,
        type: 'Guest',
        companyID: 2,
        gender: 'Mrs',
        firstName: 'Alice',
        lastName: 'Walton',
        title: '',
        email: 'alice.walton@microsoft.com',
        jobPosition: 'Lead Software Developer',
        function: 'Academic',

        directPhone: '+33 (0)1 00 54 87 96',
        mobile: '+33 (0)6 58 78 69 63',
        mobile2: '',

        address1: '37 Quai du president Roosevelt',
        address2: '',
        city: 'Issy-les-moulineaux',
        state: '',
        zipCode: 92130,
        country: 'France',

        emergencyContactName: '',
        emergencyContactNumber: '',

        assistantBool: true,
        assistant: {
          firstName: 'Alfred',
          lastName: 'Hitchcock',
          title: '',
          jobPosition: 'Director',
          directPhone: '+33 (0)1 54 65 78 96',
          mobile: '+33 (0)6 54 65 78 96',
          email: 'alfred.hitckock@microsoft.com'
        },

      }, participation: {
        pass: '',
        status: 'Declined',
        main: true,
        free: true,
        oneOnOne: false,
        vip: true,
        speaker: false,
        hotel: true,
        travel: false,
        doNotPostEmail: true,
        incident: false,
        profileUpdate: true,
        speakerType: 2,
        generalComments: '',
        couponUsed: '',
      }, travel: {
        arrivalTravelOption: '',
        arrivalDate: '',
        departureTravelOption: '',
        departureDate: '',
        departureCity: '',
        departureCountry: '',
        prefferedStation: '',
        prefferedAirline: '',
        flyerNumber: '',
        alternativeDate: '',
        readOnly: false,
        dietaryRequirements: '',
        otherRequirements: '',
        checkInDate: '',
        checkOutDate: '',
        roomType: '',
        noAccommodation: false,
      }, hotel: {
        hotelName: '',
        room: '',
        additionnalComments: '',
        notifyAttendee: true,
      }
    },

    {
      attendee: {
        idParticipant: 11,
        type: 'Guest',
        companyID: 2,
        gender: 'Mr',
        firstName: 'Maria',
        lastName: 'Sharapova',
        title: '',
        email: 'maria.sharapova@microsoft.com',
        jobPosition: 'Lead Software Developer',
        function: 'Academic',

        directPhone: '+33 (0)1 00 54 87 96',
        mobile: '+33 (0)6 58 78 69 63',
        mobile2: '',

        address1: '37 Quai du president Roosevelt',
        address2: '',
        city: 'Issy-les-moulineaux',
        state: '',
        zipCode: 92130,
        country: 'France',

        emergencyContactName: '',
        emergencyContactNumber: '',

        assistantBool: true,
        assistant: {
          firstName: 'Alfred',
          lastName: 'Hitchcock',
          title: '',
          jobPosition: 'Director',
          directPhone: '+33 (0)1 54 65 78 96',
          mobile: '+33 (0)6 54 65 78 96',
          email: 'alfred.hitckock@microsoft.com'
        },

      }, participation: {
        pass: '',
        status: 'Declined',
        main: true,
        free: true,
        oneOnOne: false,
        vip: true,
        speaker: false,
        hotel: true,
        travel: false,
        doNotPostEmail: true,
        incident: false,
        profileUpdate: true,
        speakerType: 2,
        generalComments: '',
        couponUsed: '',
      }, travel: {
        arrivalTravelOption: '',
        arrivalDate: '',
        departureTravelOption: '',
        departureDate: '',
        departureCity: '',
        departureCountry: '',
        prefferedStation: '',
        prefferedAirline: '',
        flyerNumber: '',
        alternativeDate: '',
        readOnly: false,
        dietaryRequirements: '',
        otherRequirements: '',
        checkInDate: '',
        checkOutDate: '',
        roomType: '',
        noAccommodation: false,
      }, hotel: {
        hotelName: '',
        room: '',
        additionnalComments: '',
        notifyAttendee: true,
      }
    },
  ]

  // COMPANY NAME
  public companyNameList = ['Société Générale', 'FMI', 'SNCF'];

  // PARTICIPANT INFOS
  public participantType = ['Guest', 'Partner', 'Visitor', 'Expert', 'Staff member', 'Journalist', 'Supplier'];
  public participantStatus; // = ['Shortlist', 'Invited', 'Confirmed', 'Waiting list', 'Rejected', 'Declined', 'Cancelled', 'Cancelled D-7'];
  public participantPass = ['Pass P1', 'Pass P2', 'Pass P3', 'Pass P4', 'Pass P5'];
  public partcipantPriorityLvl = ['0', '1', '2', '3', '4'];
  public guestCategory = ['Travel Agency', 'Associate Agency', 'Schools'];
  public partnersCategory = ['Hotel Partner', 'Private Seas', 'Private Places', 'Private Skies', 'Boat Partner', 'Train Partner'];

  // PARTICIPANT CIVILITY
  public gender; // = ['Mr', 'Mrs'];

  getGender() {
    this.gender = this.http.get(`${environment.apiUrl}/api/title`);
    return this.gender;
  }
  getParticipantStatus() {
    this.participantStatus = this.http.get(`${environment.apiUrl}/api/status`);
    return this.participantStatus;
  }

  public title = ['Prof.', 'Dr.', 'Prof. Dr.'];
  public functionList = ['Academic', 'Executive Management', 'Data scientist', 'Hardware/System/ Engineer', 'IT Management', 'Researcher (Non-Academic)',
    'Sale/Marketing', 'Developer?Programmer', 'Engineer', 'Designer', 'Content Creation Professional', 'Startup/Emerging', 'Company Venture Capitalist/Investment banker', 'Other'];

  public domainList = ['Analytics And Data Management', 'Store Technology', 'Social And Relationship', 'Logistics And Supply Chain', 'Aquisition And Advertising', 'Content And Experience', 'Services'];

  // ZONES + COUNTRY
  public zoneList = ['Africa', 'Asia', 'Baltic Countries', 'Benelux', 'British Isles',
    'Central & Eastern Europe', 'DACH', 'Iberian Peninsula', 'Indian Sub Continent',
    'Latin America', 'Maghreb', 'Middle East', 'Nordic Countries', 'North America',
    'Oceania', 'Russia and CIS', 'South East Asia', 'Southern Europe'];
  public countryList;

  getCountry() {
    return this.countryList = this.http.get(`${environment.apiUrl}/api/country`);
  }

  // Function list
  public listOfvendor = ['Guest', 'Partner', 'Visitor', 'Expert', 'Staff member', 'Journalist', 'Supplier'];
  public locationList = ['Africa', 'Asia', 'Baltic Countries', 'Benelux', 'British Isles',
    'Central & Eastern Europe', 'DACH', 'Iberian Peninsula', 'Indian Sub Continent',
    'Latin America', 'Maghreb', 'Middle East', 'Nordic Countries', 'North America',
    'Oceania', 'Russia and CIS', 'South East Asia', 'Southern Europe'];
  public optionnal = ['all', 'gue', 'sta', 'jou', 'non', 'ven', 'vis', 'spe'];
  public mandatory = ['all', 'gue', 'sta', 'jou', 'non', 'ven', 'vis', 'spe'];
  public functiontype = ['Travel Agency', 'Associate Agency', 'Schools'];
  public transfer = ['Hotel Partner', 'Private Seas', 'Private Places', 'Private Skies', 'Boat Partner', 'Train Partner'];
  public parentfunction = ['Travel Agency', 'Associate Agency', 'Schools', 'Visitor', 'Expert', 'Staff member', 'Journalist', 'Supplier'];
  public typeList = ['Workshop', 'Dinner', 'Breakfast', 'Live', 'Plenary', 'One-on-One meeting', 'Showcase', 'Networking', 'General', 'Keynote', 'Boardroom', 'Webinar'];

  // Event list
  public eventlist = ['14/09/2021: Retail Connect 1to1 Fall\'21 - Virtual', '15/06/2021: Channel Connect 1to1 2021 - Virtual', '02/03/2021: Retail Connect 1to1 Spring \'21 - Virtual', '03/11/2020: Retail Connect One-to-One EMEA - Virtual']

  constructor(private http: HttpClient) {

  }

  //////////////////////////////////////// HTTP METHODS //////////////////////////////////:

  getCompanyByID(id: number) {
    return this.http.get(`${environment.apiUrl}/api/a/company/` + id);
  }

  // getParticipantByID(id: number) {
  //   return this.http.get(`${environment.apiUrl}/api/a/person/` + id);
  // }

  getParticipantsByStatusByType(status: string, type: string, size: number, page: number) {
    return this.http.get(`${environment.apiUrl}/api/participantlist?status=${status}&type=${type}&size=${size}&page=${page}`);
  }

  getRefTitle() {
    return this.http.get(`${environment.apiUrl}/api/title/`);
  }

  postCompanyAutocomplete(body) {
    return this.http.post<any>(`${environment.apiUrl}/api/a/companylist`, body);
  }
  // {
  //   "Id" : 0,
  //   "Name" : "",
  //   "Country" : "",
  //   "ZipCode" : "",
  //   "Type" : ""
  // }

  postParticipantAutocomplete(body) {
    return this.http.post<any>(`${environment.apiUrl}/api/personlist`, body);
  }
  // {
  //   "Id" : 0,
  //   "IdCompany" : 0,
  //   "Firstname" : "",
  //   "Lastname" : "",
  //   "Email" : ""
  // }


  //////////////////////////////////////// STYLE METHODS //////////////////////////////////:

  classStamp(status) {
    return 'card__badge-' + status.toLowerCase();
  }

  cutStatus(status) {
    // status.charAt(0).toUpperCase();
    if (status === "Invited") {
      return 'Inv.';
    } else if (status === "Cancelled D-7") {
      return 'Can. D-7';
    } else {
      return status.substr(0, 4) + '.';
    }
  }

}


export interface RefData {
  name: string;
  value: number;
}