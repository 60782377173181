<!-- Card guest confirmed previous event -->
<div class="card">
  <div class="card-body">

    <div class="dropright dropright-action">
      <svg class="opacity-icon " width="24px" height="24px" id="dropdownMenuButton4"
           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <use xlink:href="#action"/>
      </svg>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton4">
        <span class="dropdown-menu-arrow"></span>
        <a class="dropdown-item" [routerLink]="['/admin/contacts/list/guests_confirmed']" i18n>View list</a>
        <a class="dropdown-item" href="http://ecv5-test.eventcatalyst.io/assets/extract_test.xls" i18n>Extract list</a>
        <a href="#" class="dropdown-item" data-toggle="modal" data-target="#exampleModal" i18n>Change target</a>
        <a class="dropdown-item" href="http://ecv5-test.eventcatalyst.io/assets/extract_test.xls" i18n>View history</a>
        <a class="dropdown-item" [routerLink]="['/admin/contacts/add']" i18n>Add guest</a>
        <a class="dropdown-item" [routerLink]="['/admin/communication/emailing']" i18n>Send email</a>
      </div>
    </div>


    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel" i18n>Target</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <input type="text" class="form-control" id="targetGuests" aria-describedby="targetHelp"
                   placeholder="Target">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" i18n>Close</button>
            <button type="button" class="btn btn-success" i18n>Save Target</button>
          </div>
        </div>
      </div>
    </div>


    <h4 class="card-title mb-0" i18n>Guests</h4>
    <div class="badge badge-pill card__badge--color-success"><span i18n>CONFIRMED</span></div>
    <h3 class="card-subtitle" i18n>W-4</h3>

    <div class="bottomRows">

      <div class="row">
        <div class="col">
          <div id="topRow">
            <!-- <span class="h2">2019 </span>-->
            <span class="h2Top">{{guests}}</span>
            <div class='box mt-3'>
              <span style="color: #84C000 !important; font-size: 12pt; font-family: nowaymedium;" i18n>5 </span>
              <span style="color: #84C000 !important; font-size: 12pt; font-family: nowaymedium;" i18n>new</span> <br>
              <span style="color: #474124; font-size: 12pt; font-family: nowaymedium;">/ {{target}}</span>
            </div>
          </div>
        </div>
        <div class="col-3 pl-4 pr-0 taux">
          {{tauxProgress(taux)}}<span>&nbsp;%</span>
        </div>
      </div>

      <div class="progressBar mb-1">
        <ngb-progressbar id="progressBar1" ngbPopover="{{tauxProgress(taux)}} %" popoverClass="popover-light"
                         triggers="mouseenter:mouseleave" class="mb-2 mt-3" [showValue]="false" showValue="true"
                         [value]="taux*100">
        </ngb-progressbar>
      </div>

      <div *ngFor="let yearDatum of pastYears">

        <div class="row">
          <div class="col midRow">
            <span class="h2">{{yearDatum.year}}</span>
            <span class="h3">{{yearDatum.count}}</span>
          </div>
        </div>

        <div class="progressBar">
          <ngb-progressbar ngbPopover="In {{yearDatum.year}}, {{tauxProgress(yearDatum.count/target)}}% guests have confirmed their participation at W-4" popoverClass="popover-light-progressbar"
                           triggers="mouseenter:mouseleave" class="progressBarPrev" [showValue]="false"
                           showValue="true" [value]="yearDatum.count/target*100">
          </ngb-progressbar>
        </div>

      </div>
    </div>

  </div>
</div>
<!-- End card -->
