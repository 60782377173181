import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-new-attendee',
  templateUrl: './card-new-attendee.component.html',
  styleUrls: ['./card-new-attendee.component.scss']
})
export class CardNewAttendeeComponent implements OnInit {

  @Input() data;
  participantType;

  constructor() {
  }

  ngOnInit() {
    this.participantType = this.data.shift().participantType;
    console.log(this.participantType)
  }

}
