<div class="maincontainer-fluid font-color pb-4 pb-5" style="min-height: 80vh">
  <div class="maincontainer">

    <div class="row">

      <div class="col-lg-8 col-md-12 mt-4">
        <h3 class="nowayRegular" i18n>Thelonious Bob</h3>
        <span style="opacity: 0.7" i18n>Super Admin</span>
      </div>

    </div>


    <!-- Desktop -->
    <div class="display--nav-none">

      <div class="mt-4">

        <app-settings-menu url="Manage-user"></app-settings-menu>

      </div>


      <div class="row mt-4">


        <div class="col-xl-12 col-lg-12">

          <div *ngFor="let settingManageUser of settingManageUser">
            <div class="userCard">
              <div class="row justify-content-between">
                <div class="col-md-6" style="display: flex">
                  <input type="checkbox" class="increase">
                  <span class="badge badge-pill badge-danger" style="opacity: 0.6" i18n>Decl.</span>
                  <div class="btn-group dropleft">
                    <svg class="float-right" id="dropdownMenuButton6"
                         style="cursor:pointer;opacity: 0.2;margin-top: -5px;" height="24px" width="24px"
                         data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <use xlink:href="#more-vertical"/>
                    </svg>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item" [routerLink]="['/admin/contacts/1/edit']" i18n>Manage Attendee</a>
                      <a class="dropdown-item" href="#" i18n>Another action</a>
                      <a class="dropdown-item" href="#" i18n>Something else here</a>
                    </div>
                  </div>
                  <a [routerLink]="['/admin/contacts/1/details']"><h3>{{settingManageUser.username}}</h3></a>
                  <div class="badges" style="display: flex">
                    <span class="badge badge-pill badge-success" i18n>MAIN</span>
                    <span class="badge badge-pill badge-success" i18n>VIP</span>
                  </div>
                </div>

                <div class="col-md-3 col-sd-5" style="display: flex">
                  <div class="btn-group dropleft">
                    <svg class="float-right" id="dropdownMenuButton6"
                         style="cursor:pointer;opacity: 0.2;margin-top: -5px;" height="24px" width="24px"
                         data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <use xlink:href="#more-vertical"/>
                    </svg>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item" href="#" i18n>Action</a>
                      <a class="dropdown-item" href="#" i18n>Another action</a>
                      <a class="dropdown-item" href="#" i18n>Something else here</a>
                    </div>
                  </div>
                  <h3 i18n>Microsoft</h3>
                </div>
              </div>

              <div class="row justify-content-between l2">
                <div class="col-md-5 offset-md-1" style="display: flex">
                  <p class="tittle" i18n>Lead Software Developer</p>
                </div>
                <div class="col-md-3 col-sd-5" style="display: flex">
                  <p>FR</p>
                </div>
              </div>

              <div class="row justify-content-between l3">
                <div class="col-md-5 offset-md-1">
                  <a class="phoneNb" style="white-space: nowrap;color:#000000"
                     href="tel:0658786963">+33(0)6-58-78-69-63</a>
                  <a class="mail" style="color:#000000" href="mailto:thelonious.monk@microsoft.com" i18n>thelonious.monk@microsoft.com</a>
                </div>
                <div class="col-md-3 col-sd-5">
                  <a class="phoneNb" style="white-space: nowrap;color:#000000" href="tel:+33658786963" i18n>+33(0)6-58-78-69-63</a>
                </div>
              </div>

              <hr>

              <div class="row footer">
                <p i18n>ADDED</p>
                <span i18n>05/06/2018</span>
                <p i18n>MODIFIED</p>
                <span i18n>05/06/2018</span>
                <p class="accountM" i18n>ACCOUNT M.</p>
                <span class="accountM" i18n>Roger Rabbit</span>
                <p i18n>OPTION</p>
                <span i18n>One-to-One</span>
              </div>
            </div>


          </div>
        </div>

      </div>

    </div>
    <!-- End Des -->


    <!-- Mobile -->
    <div class="accordion display--collapse-none mt-3" id="accordionExample">


      <h2 class="mb-0">
        <button [routerLink]="['/admin/settings']" class="btn btn-link pl-0" type="button" i18n>
          Dashboard Option
        </button>
        <button [routerLink]="['/admin/settings']" class="float-right btn-link btn" type="button">
          <svg width="24px" height="24px" style="fill: #84C000">
            <use xlink:href="#plus"/>
          </svg>
        </button>
      </h2>


      <div class="mt-3">

        <h2 class="mb-0">
          <button [routerLink]="['/admin/settings-account']" class="btn btn-link pl-0"
                  type="button" i18n>
                  Change password
          </button>
          <button [routerLink]="['/admin/settings-account']" class="float-right btn-link btn"
                  type="button">
                  <svg width="24px" height="24px" style="fill: #84C000">
                    <use xlink:href="#plus"/>
                  </svg>
          </button>
        </h2>
      </div>


      <div class="mt-3">

        <h2 class="mb-0">
          <button [routerLink]="['/admin/settings-event']" class="btn btn-link pl-0"
                  type="button" i18n>
            Event
          </button>
          <button [routerLink]="['/admin/settings-event']" class="float-right btn-link btn" type="button">
            <svg width="24px" height="24px" style="fill: #84C000">
              <use xlink:href="#plus"/>
            </svg>
          </button>
        </h2>
      </div>


      <div class="mt-3">
        <div id="headingFive">
          <h2 class="mb-0">
            <button class="btn btn-link pl-0" type="button" data-toggle="collapse"
                    data-target="#collapseFive"
                    aria-expanded="true" aria-controls="collapseFive" style="color: #84C000" i18n>
              Manage Users
            </button>
            <button class="float-right btn-link btn" type="button" data-toggle="collapse"
                    aria-expanded="true"
                    data-target="#collapseFive">
                    <svg width="24px" height="24px" style="fill: #84C000">
                      <use xlink:href="#plus"/>
                    </svg>
            </button>
          </h2>
        </div>
        <div id="collapseFive" class="collapse show" aria-labelledby="headingFive"
             data-parent="#accordionExample">

          <div class="row mt-4">

            <div class="col-xl-12 col-lg-12">

              <div class="row">

              </div>

            </div>

          </div>


        </div>
      </div>
    </div>
    <!-- End Mobile -->


  </div>
</div>
