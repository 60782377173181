import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router, Event, NavigationEnd, NavigationError } from '@angular/router';
import { EventService } from '../http/event.service';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

export class HeaderComponent implements OnInit, OnDestroy {

  displaySearchBar = true;
  siteTest = true; // PASSER EN TRUE SI SITE DE TEST
  mobileQuery: MediaQueryList;
  currentUrl;
  isDashboard: boolean;
  isParticipants: boolean;
  isCommunication: boolean;
  isProgramme: boolean;
  isLogistics: boolean;
  isTools: boolean;
  nextEventId;
  nextEventUrl;

  _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private EventService: EventService,
    private router: Router,
  ) {
    console.log()
    this.mobileQuery = media.matchMedia('(max-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // this.mobileQuery.addEventListener("change", () => { this._mobileQueryListener() });
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.getUrl();
      }
      if (event instanceof NavigationError) {
        console.log(event.error);
      }
      window.scrollTo(0, 0);
    });
  }

  ngOnDestroy(): void {
    // this.mobileQuery.removeEventListener("change", () => { this._mobileQueryListener() });
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit() {
    // on recupère l'id à l'index 0 qui est celui de l'event en cours où le plus proche
    this.EventService.getAllEvents().subscribe(res => {
      this.nextEventId = res[0].id
      this.nextEventUrl = "admin/agenda/events/" + this.nextEventId + "/details";
    })
  }

  getUrl() { // Recupère l'URL et gère le focus des onglets en fonction de l'URL
    this.currentUrl = this.router.url.substr(7);
    this.currentUrl.startsWith('dashboard') ?  this.isDashboard = true : this.isDashboard = false;
    this.currentUrl.startsWith('participants') ?  this.isParticipants = true : this.isParticipants = false;
    this.currentUrl.startsWith('communication') ?  this.isCommunication = true : this.isCommunication = false;
    this.currentUrl.startsWith('programme') ?  this.isProgramme = true : this.isProgramme = false;
    this.currentUrl.startsWith('logistics') ?  this.isLogistics = true : this.isLogistics = false;
    this.currentUrl.startsWith('tools') ?  this.isTools = true : this.isTools = false;
  }

  setMyStyles() {
    const styles = {
      fill: this.displaySearchBar ? '#244746' : '#84c000',
    };
    return styles;
  }

  display() {
    this.displaySearchBar = !this.displaySearchBar;
  }
}
