import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private http: HttpClient) { }

    //////////////////////////////////////// HTTP METHODS FOR EVENTS //////////////////////////////////

                    /* GET */

    getAllEvents() {
      return this.http.get(`${environment.apiUrl}/api/a/event/`);
    }
    getAllEventsByParticipant(idPerson: number) {
      return this.http.get(`${environment.apiUrl}/api/a/event/person-${idPerson}`);
    }
  
    getEventById(idEvent: number) {
      return this.http.get(`${environment.apiUrl}/api/a/event/event-` + idEvent);
    }
  
    getEventByYear(year: number) {
      return this.http.get(`${environment.apiUrl}/api/a/event/year-` + year);
    }

    getEventType() {
      return this.http.get(`${environment.apiUrl}/api/eventType`);
    }

    getEventContact(idEvent: number) {
      return this.http.get(`${environment.apiUrl}/api/a/event/contact/` + idEvent);
    }
    getEventMenu(idEvent: number) {
      return this.http.get(`${environment.apiUrl}/api/a/event/menu/` + idEvent);
    }
    getEventAgenda(idEvent: number) {
      return this.http.get(`${environment.apiUrl}/api/a/event/agenda/event-` + idEvent);
    }
    getAgenda(idEvent: number) {
      return this.http.get(`${environment.apiUrl}/api/a/event/agenda/happening-` + idEvent);
    }
    getEventRegistrationStatus(status: string, idEvent: number) {
      return this.http.get(`${environment.apiUrl}/api/a/event/registration/event-${idEvent}&status-${status}`);
    }
    getEventRegistrationByStatusWithPage(status: string, idEvent: number, size: number, page: number, id_country: number, id_type: number,
    id_company: number, vip: number, main: number, search: string) { 
      return this.http.get(`${environment.apiUrl}/api/a/event/registration/size-${size}&page-${page}&event-${idEvent}&status-${status}&country-${id_country}&type-${id_type}&company-${id_company}&vip-${vip}&main-${main}&search-${search}`);
    }
                    /* POST */
    addEvent(body: any) {
      return this.http.post<any>(`${environment.apiUrl}/api/a/event`, body);
    }
    addEventAgenda(body: any) {
      return this.http.post<any>(`${environment.apiUrl}/api/a/event/agenda`, body);
    }
                    /* PUT */
    updateEvent(idEvent, body: any) {
      return this.http.put<any>(`${environment.apiUrl}/api/a/event/` + idEvent, body)
      .subscribe(
        data => console.log('success', data),
        error => console.log("L'error est : " + error)
      );
    }
    updateEventContact(idEvent, body: any) {
      return this.http.put<any>(`${environment.apiUrl}/api/a/event/contact/` + idEvent, body)
      .subscribe(
        data => console.log('success', data),
        error => console.log("L'error est : " + error)
      );
    }
    updateEventMenu(idEvent, body: any) {
      return this.http.put<any>(`${environment.apiUrl}/api/a/event/menu/` + idEvent, body)
      .subscribe(
        data => console.log('success', data),
        error => console.log("L'error est : " + error)
      );
    }
    editAgenda(idHappening, body: any) {
      return this.http.put<any>(`${environment.apiUrl}/api/a/event/agenda/` + idHappening, body);
    }
                      /* DELETE */
    deleteAgenda(idEvent: number) {
      return this.http.delete<any>(`${environment.apiUrl}/api/a/event/agenda/${idEvent}`);
    }
}
