<div class="font-color pb-4">
  <div style="width: 100%;border-top: 3px solid #eae5d5;background-color: #fff; margin-bottom: 1%;">
      <nav class="breadcrumb-responsive filAriane row maincontainer" aria-label="breadcrumb">
          <ol class="breadcrumb_fil">
              <svg id="dropdownMenuButton6" height="24px" width="24px" aria-haspopup="true" aria-expanded="false"
              style="fill:#84C000">
              <use xlink:href="#company_f"/>
              </svg>
              <div style="display:flex; align-items: center;">
              <ng-container *ngFor="let item of url; let myIndex = index; first as isFirst; last as isLast" >
                  <li class="breadcrumb-item" *ngIf="myIndex > 1"><a>{{url[myIndex].replaceAll('-', ' ')}}</a></li>
              </ng-container>
              <li>&nbsp;/&nbsp;</li>
              <li style="width: 400px;">
                  <app-form-search-select (afterChange)="afterChange($event)"></app-form-search-select>
              </li>
              </div>
          </ol>
          <div class="icons">
              <div class="btn-group dropleft ml-3">
                  <span placement="left" popoverClass="popover-custom-light popover-width" ngbPopover="Add more" triggers="mouseenter:mouseleave"
                  id="AddThings" data-toggle="dropdown">
                      <svg aria-haspopup="true" aria-expanded="false" class="iconFilArianne">
                          <use xlink:href="#plus"/>
                      </svg>
                  </span>
                  <div class="dropdown-menu" aria-labelledby="AddThings">
                      <a placement="left" popoverClass="popover-custom-light" ngbPopover="New hotel" triggers="mouseenter:mouseleave"
                      class="dropdown-item" [routerLink]="['/admin/logistics/hotels/', valueEvent, 'add']" i18n>New hotel</a>
                      <a placement="left" popoverClass="popover-custom-light" ngbPopover="New event" triggers="mouseenter:mouseleave"
                      class="dropdown-item" [routerLink]="['/admin/agenda/events/add']" i18n>New event</a>
                  </div>
              </div>
              <span placement="top" popoverClass="popover-custom-light popover-width" ngbPopover="Extract list" triggers="mouseenter:mouseleave">
                  <svg height="24px" width="24px" class="ml-3" (click)="downloadList()">
                      <use xlink:href="#export"/>
                  </svg>
              </span>
              <div class="btn-group dropleft ml-3">
                  <span placement="right" popoverClass="popover-custom-light popover-width" ngbPopover="More option" triggers="mouseenter:mouseleave"
                  id="dropdownMenuButton2" data-toggle="dropdown">
                      <svg aria-haspopup="true" aria-expanded="false" class="iconFilArianne">
                          <use xlink:href="#action_h" />
                      </svg>
                  </span>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                      <a class="dropdown-item" href="#" i18n>Action 1</a>
                      <a class="dropdown-item" href="#" i18n>Action 2</a>
                      <a class="dropdown-item" href="#" i18n>Action 3</a>
                      <a class="dropdown-item" href="#" i18n>Action 4</a>
                  </div>
              </div>
          </div>
      </nav>
  </div>
  <div class="maincontainer">
      <div *ngIf="listOfHotel">
        <h2 class="title pl-0">{{listOfHotel[0].hotelName}}</h2>
        <div class="pb-3" style="display: flex; justify-content: space-around; text-align: center; border-bottom: 2px solid #84C000; font-weight: bold;">
            <span style="width: 12%;">Date</span>
            <span style="width: 22%;">Participant type</span>
            <span style="width: 10%;">Nbr of room(s)</span>
            <span style="width: 10%;">Surbooking</span>
            <span style="width: 12%;">Room type</span>
            <span style="width: 12%;">Nbr of booked</span>
            <span style="width: 10%;">Nbr of free</span>
            <span style="width: 12%;">Nbr of request</span>
        </div>
        <ng-container *ngFor="let list of listOfHotel">
            <app-hotels-details [data]="list"></app-hotels-details>
        </ng-container>
      </div>
      <div *ngIf="!listOfHotel">
        <h2 class="title pl-0">No night</h2>
      </div>
  </div>
</div>