<div class="font-color pb-4">
    <div style="width: 100%;border-top: 3px solid #eae5d5;background-color: #fff; margin-bottom: 1%;">
        <nav class="breadcrumb-responsive filAriane row maincontainer" aria-label="breadcrumb">
            <ol class="breadcrumb_fil">
                <svg id="dropdownMenuButton6" height="24px" width="24px" aria-haspopup="true" aria-expanded="false"
                style="fill:#84C000">
                <use xlink:href="#company_f"/>
                </svg>
                <div style="display:flex; align-items: center;">
                <ng-container *ngFor="let item of url; let myIndex = index; first as isFirst; last as isLast" >
                    <li class="breadcrumb-item" *ngIf="myIndex > 1"><a>{{url[myIndex].replaceAll('-', ' ')}}</a></li>
                </ng-container>
                <li>&nbsp;/&nbsp;</li>
                <li style="width: 400px;">
                    <app-form-search-select (afterChange)="afterChange($event)"></app-form-search-select>
                </li>
                </div>
            </ol>
            <div class="icons">
                <div class="btn-group dropleft ml-3">
                    <span placement="left" popoverClass="popover-custom-light popover-width" ngbPopover="Add more" triggers="mouseenter:mouseleave"
                    id="AddThings" data-toggle="dropdown">
                        <svg aria-haspopup="true" aria-expanded="false" class="iconFilArianne">
                            <use xlink:href="#plus"/>
                        </svg>
                    </span>
                    <div class="dropdown-menu" aria-labelledby="AddThings">
                        <a placement="left" popoverClass="popover-custom-light" ngbPopover="New hotel" triggers="mouseenter:mouseleave"
                        class="dropdown-item" [routerLink]="['/admin/logistics/hotels/', valueEvent, 'add']" i18n>New hotel</a>
                        <a placement="left" popoverClass="popover-custom-light" ngbPopover="New event" triggers="mouseenter:mouseleave"
                        class="dropdown-item" [routerLink]="['/admin/agenda/events/add']" i18n>New event</a>
                    </div>
                </div>
                <span placement="top" popoverClass="popover-custom-light popover-width" ngbPopover="Extract list" triggers="mouseenter:mouseleave">
                    <svg height="24px" width="24px" class="ml-3" (click)="downloadList()">
                        <use xlink:href="#export"/>
                    </svg>
                </span>
                <div class="btn-group dropleft ml-3">
                    <span placement="right" popoverClass="popover-custom-light popover-width" ngbPopover="More option" triggers="mouseenter:mouseleave"
                    id="dropdownMenuButton2" data-toggle="dropdown">
                        <svg aria-haspopup="true" aria-expanded="false" class="iconFilArianne">
                            <use xlink:href="#action_h" />
                        </svg>
                    </span>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                        <a class="dropdown-item" href="#" i18n>Action 1</a>
                        <a class="dropdown-item" href="#" i18n>Action 2</a>
                        <a class="dropdown-item" href="#" i18n>Action 3</a>
                        <a class="dropdown-item" href="#" i18n>Action 4</a>
                    </div>
                </div>
            </div>
        </nav>
    </div>
    <div class="maincontainer">
        <h2 class="title pl-0">Hotel(s)</h2>
        <div *ngIf="listOfHotel">
            <ng-container *ngFor="let list of listOfHotel">
                <app-hotels-booking-list [data]="list"></app-hotels-booking-list>
            </ng-container>
        </div>
        <div *ngIf="!listOfHotel">
            <p>no hotel</p>
        </div>
    </div>
</div>