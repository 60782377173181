import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { CheckDeviceService } from '../../../core/http/check-device.service';
import { EventService } from 'src/app/core/http/event.service';
import { ParticipantService } from 'src/app/core/http/participant.service';
import { CompanyService } from 'src/app/core/http/company.service';
import { Location } from '@angular/common'
import { HotelService } from 'src/app/core/http/hotel.service';

@Component({
  selector: 'app-fil-ariane-v2',
  templateUrl: './fil-ariane-v2.component.html',
  styleUrls: ['./fil-ariane-v2.component.scss']
})
export class FilArianeV2Component implements OnInit {
  
  @Output() exportExcel: EventEmitter<any> = new EventEmitter();

  mobileRes: boolean;
  cancelPage: boolean = false;
  exportIcon: boolean;
  nameUrl: any;
  url: any = [];
  public href: string;
  id: any;
  listOfEvent: any;
  listOfContact: any;
  listOfCompany: any;
  listOfAgenda: any;
  dataLogistic: any;
  urlNewAgenda: string;
  urlNewEvent: string;
  urlNewContact: string;
  urlNewCompany: string;
  urlNewBroadcast: string;

  constructor(
    private checkDeviceService: CheckDeviceService,
    private router: Router,
    private route: ActivatedRoute,
    private eventService: EventService,
    private companyService: CompanyService,
    private location: Location,
    private participantService: ParticipantService,
    private hotelService: HotelService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
    });
    this.href = this.router.url;
    this.nameUrl = this.href.split('/');
    if (this.nameUrl[this.nameUrl.length - 1] == "add" || this.nameUrl[this.nameUrl.length - 1] == "edit") { this.cancelPage = true }
    for (let i = 0; i < this.nameUrl.length; i++) {
      if (i == 0) { this.url[i] = ""; }
      else {this.url[i] = this.url[i - 1] + "/" + this.nameUrl[i] }
    }
    this.exportIcon = true;
    this.pageAgenda();
    this.pageContact();
    this.pageCompany();
    this.pageTools();
    this.pageLogistic();
    this.mobileRes = this.checkDeviceService.isMobileResolution;
    if (this.nameUrl[this.nameUrl.length - 1] == "edit" || this.nameUrl[this.nameUrl.length - 1] == "add" || this.nameUrl[this.nameUrl.length - 1] == "details") {
      this.nameUrl.splice(this.nameUrl.length - 1, 1);
      this.url.splice(this.url.length - 1, 1);
    }
    for (let i = 0; i < this.nameUrl.length; i++) {
      this.nameUrl[i] = this.nameUrl[i].substring(0, 1).toUpperCase() + this.nameUrl[i].substring(1).replaceAll('-', ' ');
    }
  }
  pageAgenda() {
    if (this.nameUrl[2] == "agenda") {
      this.urlNewEvent = "/admin/agenda/events/add";
      if (this.nameUrl[3] == "events" && (this.nameUrl[5] == "edit" || this.nameUrl[5] == "details")) {
        this.urlNewAgenda = "/admin/agenda/event-agenda/" + this.id + "/add";
        this.eventService.getEventById(this.id).subscribe( res => {
          this.listOfEvent = res;
          for (let i = 0; i < this.nameUrl.length; i++) {
            if (this.nameUrl[i] == this.id) this.nameUrl[i] = this.listOfEvent.eventName;
          }
        });
      }
      else if (this.nameUrl[3] == "event-agenda" && this.nameUrl[4] == this.id) {
        if (this.nameUrl.length < 6 || this.nameUrl[5] == "add") {
          this.urlNewAgenda = "/admin/agenda/event-agenda/" + this.id + "/add";
          this.eventService.getEventById(this.id).subscribe( res => {
            this.listOfEvent = res;
            for (let i = 0; i < this.nameUrl.length; i++) {
              if (this.nameUrl[i] == this.id) this.nameUrl[i] = this.listOfEvent.eventName;
            }
          });
        }
        else if (this.nameUrl[3] == "event-agenda" && (this.nameUrl[5] == "edit" || this.nameUrl[5] == "details")) {
          this.eventService.getAgenda(this.id).subscribe( res => {
            this.listOfAgenda = res;
            for (let i = 0; i < this.nameUrl.length; i++) {
              if (this.nameUrl[i] == this.id) this.nameUrl[i] = this.listOfAgenda.function;
            }
          });
        }
      }
    }
  }
  pageContact() {
    if (this.nameUrl[2] == "contacts") {
      if (this.nameUrl[this.nameUrl.length - 1] == "contacts") { this.exportIcon = false; }
      this.urlNewContact = "/admin/contacts/add";
      if (this.id) {
        this.participantService.getParticipantByID(this.id).subscribe( res => {
          this.listOfContact = res;
          for (let i = 0; i < this.nameUrl.length; i++) {
            if (this.nameUrl[i] == this.id) {
              this.nameUrl[i] = this.listOfContact.lastName + " " + this.listOfContact.firstName;
            }
          }
        });
      }
    }
  }
  pageLogistic() {
    if (this.nameUrl[2] == "logistics") {
      if (this.id) {
        if (this.nameUrl[3] == "hotels" && this.nameUrl[this.nameUrl.length - 1] == "edit") {
          this.hotelService.getHotel(this.id).subscribe(res => {
            this.dataLogistic = res;
            for (let i = 0; i < this.nameUrl.length; i++) {
              if (this.nameUrl[i] == this.id) {
                this.nameUrl[i] = this.dataLogistic.hotelName;
              }
            }
          })
        }
        if (this.nameUrl[this.nameUrl.length - 1] == "add") {
          this.eventService.getEventById(this.id).subscribe(res => {
            this.dataLogistic = res;
            for (let i = 0; i < this.nameUrl.length; i++) {
              if (this.nameUrl[i] == this.id) {
                this.nameUrl[i] = this.dataLogistic.eventName;
              }
            }
          });
        }
      }
    }
  }
  pageCompany() {
    if (this.nameUrl[2] == "company") {
      this.urlNewEvent = "/admin/agenda/events/add";
      this.urlNewContact = "/admin/contacts/add";
      this.urlNewCompany = "/admin/company/add";
      this.companyService.getCompanyByID(this.id).subscribe( res => {
        this.listOfCompany = res;
        for (let i = 0; i < this.nameUrl.length; i++) {
          if (this.nameUrl[i] == this.id) {
            this.nameUrl[i] = this.listOfCompany.company;
          }
        }
      });
    }
  }
  pageTools() {
    if (this.nameUrl[2] == "tools") {
      this.exportIcon = false;
      this.urlNewContact = "/admin/contacts/add";
      this.urlNewEvent = "/admin/agenda/events/add";
      this.urlNewCompany = "/admin/company/add";
    }
  }
  exportFile() {
    this.exportExcel.emit();
  }
  backPage() {
    this.location.back();
  }
}
