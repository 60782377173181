<div class="card">
  <form style="width:100%" [formGroup]="dataDownload" (ngSubmit)="onSubmit()">
    <div class="card-body">
      <div class="d-flex">
        <h4 class="card-title mb-0" i18n>{{data}}</h4>
      </div>
      <div *ngIf="data == 'Contacts' || data == 'Events'" class="bottomRows row">
        <div *ngIf="data == 'Contacts'" class="col-md-12 col-xs-12" i18n>
            Status
            <select class="select form-control" id="IdStatus" [(ngModel)]="statusContact" formControlName="Status">
              <option value="all">All</option>
              <option *ngFor="let status of this.listOfStatus" [value]="status.label">
                {{status.label}}
              </option>
            </select>
        </div>
        <div *ngIf="data == 'Events'" class="col-md-12 col-xs-12" i18n>
          Years
          <select class="select form-control" id="IdYears" [(ngModel)]="selectYear" formControlName="Year">
            <option value="0">All</option>
            <option *ngFor="let years of this.listOfYears" [value]="years">
              {{years}}
            </option>
          </select>
        </div>
      </div>
      <div *ngIf="data == 'Participants' || data == 'Agendas'" class="bottomRows row">
        <div *ngIf="data == 'Participants'" class="col-md-4 col-xs-12" i18n>
          Status
          <select class="select form-control" id="IdEventStatus" [(ngModel)]="statusParticipant" formControlName="Status">
            <option value="all">All</option>
            <option *ngFor="let status of this.listOfEventStatus" [value]="status.label.toLowerCase().replaceAll(' ', '-')">
              {{status.label}}
            </option>
          </select>
        </div>
        <div *ngIf="data == 'Participants'" class="col-md-8 col-xs-12" i18n>
          Event
          <app-form-search-select (afterChange)="afterChangeParticipant($event)" [id]="1"></app-form-search-select>
        </div>
        <div *ngIf="data == 'Agendas'" class="col-md-12 col-xs-12" i18n>
          Event
          <app-form-search-select (afterChange)="afterChangeAgenda($event)" [id]="2"></app-form-search-select>
        </div>
      </div>
      <div class="lastRow">
        <button type="submit" class="btn btn-success button--color" style="margin-left: auto;margin-right: auto;" i18n>Extract</button>
      </div>
    </div>
  </form>
</div>
  