import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormDataCompanyService {

  constructor(
    private fb: FormBuilder
  ) { }
  
  dataCompany: any;
  data() {
    return this.dataCompany = this.fb.group({
      companyName: ['', Validators.required],
      group: [''],
      address: [''],
      address2: [''],
      zipCode: ['', Validators.required],
      channelType: [0],
      city: [''],
      state: [''],
      idCountry: [0, Validators.required],
      telephone: [''],
      fax: [''],
      website: [''],
      comments: [''],
      profileUpdate: [false],
      tweetPich: [''],
      logoUrl: [''],
      profileUrl: [''],
      profileType: ['']
    });
  }
  dataOrder() {
    return this.dataCompany = this.fb.group({
      boardrooms: [false],
      oneOnOne: [false],
      nbOne: [''],
      nbRequest: [''],
      boothNumber: [''],
      virtualType: ['']
    });
  }
}
