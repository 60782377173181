<div class="block__size--responsive">
  
  
  <div class="card card-font-size" style="margin-top: 14px">
    <div class="card-body">
      <div class="row">
        <div class="col-xl-5 col-lg-5 col-md-6 pr-0 card-responsive">
          <p class="card-text mb-2" i18n>ADDED</p>
          <p class="card-text mb-2" i18n>MODIFIED</p>
          <p class="card-text mb-2" i18n>ACCOUNT M.</p>
        </div>
        <div class="col-xl-7 col-lg-7 col-md-6 ml-0 pr-0 pl-0 card-responsive">
          <p class="card-text nowayRegular mb-2" i18n>05/06/2018</p>
          <p class="card-text nowayRegular mb-2" i18n>05/06/2018</p>
          <p class="card-text nowayRegular mb-2" i18n>Roger Rabbit</p>
        </div>
      </div>
    </div>
  </div>

<!--  <style>
    .mat-expansion-panel {
      color: #244746;
      background-color: #FFFFFF;
      border-radius: 2px;
      border: 1px solid #EFEEE2;
    }
    .mat-expansion-panel:not([class*="mat-elevation-z"]) {
      box-shadow: none
    }
    /deep/ .mat-expansion-indicator::after {
      margin-bottom: 8px;
    }
    /deep/ .mat-expansion-indicator::after, .mat-expansion-panel-header-description {
      color: #244746;
    }
  </style>


  <div class="mt-3">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <span class="nowayRegular mb-1" style="color: #244746; font-size: 18px; font-weight: bold">PAYMENT</span>
        </mat-expansion-panel-header>

        <div class="row card-font-size">
          <div class="col-xl-5 col-lg-5 col-md-6 card-responsive">
            <p class="card-text">INVOICE</p>
            <p class="card-text">TYPE</p>
            <p class="card-text">DATE</p>
            <p class="card-text">AMOUNT</p>
            <p class="card-text">COUPON</p>
          </div>
          <div class="col-xl-7 col-lg-7 col-md-6 ml-0 pr-0 pl-0 card-responsive">
            <p class="card-text nowayRegular">20171994</p>
            <p class="card-text nowayRegular">VISA (paid)</p>
            <p class="card-text nowayRegular">24/03/19 <span class="card-details-o nowayRegular">- 11:44am</span></p>
            <p class="card-text nowayRegular">7,070.39</p>
            <p class="card-text nowayRegular">2018COUPON50</p>
          </div>
        </div>

      </mat-expansion-panel>
  </div>-->


  <div class="card mt-3">


    <div class="card-body" style="margin-bottom: 11px">

      <div class="nowayRegular mb-1" style="color: #244746; font-size: 18px; font-weight: bold">PAYMENT

        <div class="float-right">
          <div class="dropdown">
            <a href="http://ecv5-test.eventcatalyst.io/assets/extract_test.xls">
              <svg height="24px" width="24px" class="ml-3">
                <use xlink:href="#export" />
              </svg>
            </a>
            <div class="dropdown-menu menu-nav dropdown-menu-right" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
            </div>
          </div>
        </div>
      </div>

      <div class="row card-font-size">
        <div class="col-xl-5 col-lg-5 col-md-6 card-responsive">
          <p class="card-text">INVOICE</p>
          <p class="card-text">TYPE</p>
          <p class="card-text">DATE</p>
          <p class="card-text">AMOUNT</p>
          <p class="card-text">COUPON</p>
        </div>
        <div class="col-xl-7 col-lg-7 col-md-6 ml-0 pr-0 pl-0 card-responsive">
          <p class="card-text nowayRegular">20171994</p>
          <p class="card-text nowayRegular">VISA (paid)</p>
          <p class="card-text nowayRegular">24/03/19 <span class="card-details-o nowayRegular">- 11:44am</span></p>
          <p class="card-text nowayRegular">7,070.39</p>
          <p class="card-text nowayRegular">2018COUPON50</p>
        </div>
      </div>

    </div>
  </div>

  <div class="card mt-3">
    <div class="card-body" style="padding-bottom: 80px">
      <h6 class="nowayRegular" style="color: #244746; font-size: 18px; font-weight: bold">LOGGING</h6>
      <div class="card-font-size">
        <p class="mb-0 opacity-text ">05/06/2018 <span class="mr-2"></span> 10:01am</p>
        <p class="nowayRegular card-text">Last login</p>
        <p class="mb-0 opacity-text ">05/06/2018 <span class="mr-2"></span> 10:01am</p>
        <p class="nowayRegular card-text">Confirmed</p>
      </div>
    </div>
  </div>

  <div style="margin-top:14px">
    <app-company-attendee-card></app-company-attendee-card>
    <!--[selectedCompany]="selectedCompany"-->
  </div>
</div>
