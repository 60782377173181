<div class="col-xl-12">
  <form [formGroup]="newTemplateFormGroup" (ngSubmit)="onSubmit()">

    <h3 class="pt-4" i18n>TEMPLATE</h3>
    <p i18n>Vestibulum tempus sed nulla finibus maximus.</p>
    <br>
    <br>
    <div class="row form-row">
      <div class="col-md-5 flex">
        <label for="newTemplateName" class="label col-sm-4 col-form-label noPaddingLabel" i18n>Template name*</label>
        <input type="text" class="input form-control" id="newTemplateName" #newTemplateName formControlName="templateNameForm">
      </div>
    </div>
    <div class="row form-row">
      <div class="col-md-5 flex">
        <label for="DDL_templateType" class="label col-sm-4 col-form-label noPaddingLabel" i18n>Template Type*</label>
        <select class="select form-control" id="DDL_templateType" formControlName="templateTypeForm">
          <option value="" disabled i18n>Choose</option>
          <option *ngFor="let type of listOfTemplatesType">
            {{ type }}
          </option>
        </select>
      </div>
    </div>
    <div class="row form-row">
      <div class="col-md-10 flex">
        <label for="newTemplateSubject" class="label col-sm-2 col-form-label noPaddingLabel" i18n>Template subject*</label>
        <input type="text" class="input form-control" id="newTemplateSubject" formControlName="templateSubjectForm" style="width:100%!important">
      </div>
    </div>

    <div class="card mt-4">
      <div class="card-body" style="height: auto">

        <ckeditor editorUrl="https://ecv5-test.eventcatalyst.io/assets/ckeditor/ckeditor.js" [data]="templateObject.templateBody" (change)="onChange($event)"></ckeditor> <!--[editor]="editor"-->

      </div>
    </div>

    <button *ngIf="templateObject.newTemplate" class="btn btn-success button--color mt-4" style="float:right" type="submit" [disabled]="!newTemplateFormGroup.valid" i18n>Save as new template</button>
    <button *ngIf="!templateObject.newTemplate" class="btn btn-success button--color mt-4" style="float:right" type="submit" [disabled]="!newTemplateFormGroup.valid" i18n>Save template</button>
    <button (click)="switchPreviousView()"
            class="btn navButton mt-4" style="float: left;" i18n>
      Previous
    </button>
    <button (click)="deleteTemplate()"
            class="btn navButton mt-4" style="float: left;color: #dc3545!important; border-color: #dc3545!important;" i18n>
      Delete template
    </button>


  </form>
</div>
