import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { EventService } from '../../../core/http/event.service';
import { CompanyService } from '../../../core/http/company.service';
import { RefService } from '../../../core/http/ref.service';
import { FormDataFilterService } from '../../../core/http/form-data-filter.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-event-update-participant',
  templateUrl: './event-update-participant.component.html',
  styleUrls: ['./event-update-participant.component.scss']
})
export class EventUpdateParticipantComponent implements OnInit {

  listOfAllParticipants: any;
  url: any;
  public href: string;
  mobileRes: boolean;
  filterStatut = false;
  config: any;
  nbItems = 10;
  count: number;
  idEvent: number;
  status: '';
  typeStatus: string = '';
  countItems: any;
  check: boolean = false;
  box: boolean = false;
  initFilter: boolean = true;
  filterFormGroup: any;
  listOfCountry: any;
  listOfType: any;
  listOfCompany: any;
  listOfTypeParticipant: any;
  listOfChecked: any;
  search: string;
  selected: number;
  listOfStatus: any;
  listOfOnsite: any;

  constructor(
    private eventService: EventService,
    private companyService: CompanyService,
    private refService: RefService,
    private formDataFilterService: FormDataFilterService,
    private route: ActivatedRoute,
    private router: Router
  ) { 
    this.config = {
      currentPage: 1,
      itemsPerPage: this.nbItems
    };

    this.route.queryParamMap.pipe(map(params => params.get('page')))
      .subscribe(page => this.config.currentPage = page || 1);
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.idEvent = Number(params.get('id'));
    });
    if (this.initFilter) {
      this.filterFormGroup = this.formDataFilterService.dataFilters();
      this.filterFormGroup.valueChanges.subscribe();
      this.refService.getCountry().subscribe(response => {
        this.listOfCountry = response;
      });
      this.refService.getTypeParticipant().subscribe(res => {
        this.listOfType = res;
        for (let i = 0; this.listOfType.length > i; i++) {
          if (this.listOfType[i].short == "dis") { this.listOfType.splice(i, 1); }
          if (this.listOfType[i].short == "ret") { this.listOfType.splice(i, 1); }
        }
      });
      this.companyService.getAllCompany().subscribe(res => {
        this.listOfCompany = res;
      });
    }
    this.initFilter = false;
    this.selected = 0;
    this.listOfChecked = [];
    this.box = false;
    if (this.filterFormGroup["value"].search.length <= 0) { this.search = "!" }
    else { this.search = this.filterFormGroup["value"].search; }
    this.eventService.getEventRegistrationByStatusWithPage("all", this.idEvent, this.nbItems, this.config.currentPage,
    this.filterFormGroup["value"].idCountry, this.filterFormGroup["value"].idType, this.filterFormGroup["value"].idCompany,
    this.filterFormGroup["value"].vip, this.filterFormGroup["value"].main, this.search).subscribe(res => {
      this.listOfTypeParticipant = res;
    });
  }
  pageChange(newPage: number) {
    this.router.navigate(['admin/contacts'], { queryParams: { page: newPage } });
  }

  onItemsChange(event) {
    this.config.itemsPerPage = event;
    this.ngOnInit();
  }

  checkStatut(event: any) {
    this.filterStatut = event;
  }

  changeFilter() {
    this.ngOnInit();
  }

  resetFilter() {
    this.initFilter = true;
    this.ngOnInit();
  }
}
