import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UpdateParticipantComponent } from './participants/update-participant/update-participant.component';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { ListUsersDesktopComponent } from './list-users/list-users-desktop.component';
import { ChartsModule } from 'ng2-charts';
import { DetailsParticipantComponent } from './participants/details-participant/details-participant.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { UsernameResetComponent } from './username-reset/username-reset.component';
import { CreateParticipantComponent } from './participants/create-participant/create-participant.component';
import { NgbDatepickerModule, NgbTimepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';

import { ManualComponent } from './manual/manual.component';
import { EmailingComponent } from './communication/emailing/emailing.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { CKEditorModule } from 'ckeditor4-angular';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SettingsDashboardOptionComponent } from './settings/settings-dashboard-option/settings-dashboard-option.component';
import { SettingsAccountComponent } from './settings/settings-account/settings-account.component';
import { SettingsEventComponent } from './settings/settings-event/settings-event.component';
import { SettingsManageUserComponent } from './settings/settings-manage-user/settings-manage-user.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';


import { SettingsModulesComponent } from './settings/settings-modules/settings-modules.component';
import { SettingsMenuComponent } from './settings/settings-menu/settings-menu.component';
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Redirect404Component } from './redirect404/redirect404.component';
import { TemplateStepComponent } from './communication/template-step/template-step.component';
import { MailingListStepComponent } from './communication/mailing-list-step/mailing-list-step.component';
import { MailingRecapStepComponent } from './communication/mailing-recap-step/mailing-recap-step.component';
import { TemplateEditorComponent } from './communication/template-step/template-editor/template-editor.component';

import { FilterPipe } from '../core/pipes/filter.pipe';
import { CreateCompanyComponent } from './company/create-company/create-company.component';
import { UpdateCompanyComponent } from './company/update-company/update-company.component';
import { DetailsCompanyComponent } from './company/details-company/details-company.component';
import { WikiComponentsComponent } from './tools/wiki-components/wiki-components.component';
import { FollowupListComponent } from './communication/followup-list/followup-list.component';
import { ExtractionsComponent } from './tools/extractions/extractions.component';
import { EventAgendaComponent } from './event-agenda/event-agenda.component';
import { AgendaAddItemFormComponent } from './agenda/agenda-add-item-form/agenda-add-item-form.component';
import { AgendaEditItemFormComponent } from './agenda/agenda-edit-item-form/agenda-edit-item-form.component';
import { AgendaDetailsItemFormComponent } from './agenda/agenda-details-item-form/agenda-details-item-form.component';
import { AddEventsFormComponent } from './events/add-events-form/add-events-form.component';
import { EditEventsFormComponent } from './events/edit-events-form/edit-events-form.component';
import { ListAllEventsComponent } from './events/list-all-events/list-all-events.component';
import { ContactUpdateParticipantComponent } from './participant-update/contact-update-participant/contact-update-participant.component';
import { EventUpdateParticipantComponent } from './participant-update/event-update-participant/event-update-participant.component';
import { DetailsEventComponent } from './events/details-event/details-event.component';
import { HotelsBookingComponent } from './hotels/hotels-booking/hotels-booking.component';
import { HotelsAddItemFormComponent } from './hotels/hotels-add-item-form/hotels-add-item-form.component';
import { HotelsEditItemFormComponent } from './hotels/hotels-edit-item-form/hotels-edit-item-form.component';
import { HotelsDetailsItemComponent } from './hotels/hotels-details-item/hotels-details-item.component';
import { NightAddItemFormComponent } from './hotels/night-add-item-form/night-add-item-form.component';

@NgModule({
  declarations: [
    FilterPipe,
    DashboardComponent,
    UpdateParticipantComponent,
    LoginComponent,
    ListUsersDesktopComponent,
    DetailsParticipantComponent,
    StatisticsComponent,
    PasswordResetComponent,
    UsernameResetComponent,
    CreateParticipantComponent,
    ManualComponent,
    EmailingComponent,
    SettingsDashboardOptionComponent,
    SettingsAccountComponent,
    SettingsEventComponent,
    SettingsManageUserComponent,
    SettingsModulesComponent,
    SettingsMenuComponent,
    Redirect404Component,
    TemplateStepComponent,
    MailingListStepComponent,
    MailingRecapStepComponent,
    TemplateEditorComponent,
    CreateCompanyComponent,
    UpdateCompanyComponent,
    DetailsCompanyComponent,
    WikiComponentsComponent,
    FollowupListComponent,
    ExtractionsComponent,
    EventAgendaComponent,
    AgendaAddItemFormComponent,
    AgendaEditItemFormComponent,
    AgendaDetailsItemFormComponent,
    AddEventsFormComponent,
    EditEventsFormComponent,
    ListAllEventsComponent,
    ContactUpdateParticipantComponent,
    EventUpdateParticipantComponent,
    DetailsEventComponent,
    HotelsBookingComponent,
    HotelsAddItemFormComponent,
    HotelsEditItemFormComponent,
    HotelsDetailsItemComponent,
    NightAddItemFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    FormsModule,
    ChartsModule,
    NgbTimepickerModule,
    NgbDatepickerModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    CKEditorModule,
    NgbModule,
    MatCheckboxModule,
    MatStepperModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    // FontAwesomeModule,
    MatIconModule,
  ],
})
export class FeaturesModule {
}
