import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DateService } from 'src/app/core/http/date.service';
import { DownloadService } from 'src/app/core/http/download.service';
import { EventService } from 'src/app/core/http/event.service';
import { HotelService } from 'src/app/core/http/hotel.service';

@Component({
  selector: 'app-hotels-details-item',
  templateUrl: './hotels-details-item.component.html',
  styleUrls: ['./hotels-details-item.component.scss']
})
export class HotelsDetailsItemComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private eventService: EventService,
    private hotelService: HotelService,
    private datePipe: DatePipe,
    private dateService: DateService,
    private downloadService: DownloadService,
    private toastr: ToastrService
  ) { }

  url: any;
  public href: string;
  listOfAllEvents: any;
  listOfHotel: any;
  valueEvent: number = 0;
  textEvent: any;
  dataDownload: any;
  request: any;
  idHotel: any;

  async ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => { // Recupère l'id pour faire appel api ?
      this.idHotel = params.get('id');
    });
    this.href = this.router.url;
    this.url = this.href.split('/');
    for (let i = 0; i < this.url.length; i++) {
      this.url[i] = this.url[i].substr(0, 1).toUpperCase() + this.url[i].substr(1);
    }
    await this.initApi();
  }
  async initApi() {
    this.listOfAllEvents = await this.eventService.getAllEvents().toPromise();
    if (this.valueEvent == 0) {
      this.textEvent = this.datePipe.transform(this.listOfAllEvents[this.valueEvent].date, 'yyyy-MM-dd') + " - " + this.listOfAllEvents[this.valueEvent].label;
      this.valueEvent = this.listOfAllEvents[this.valueEvent].id;
    }
    this.hotelService.getNightByHotel(this.idHotel ,this.valueEvent).subscribe(res => {
      this.listOfHotel = res;
      console.log(this.listOfHotel)
    });
  }
  afterChange(event: any) {
    this.valueEvent = event.id;
    this.textEvent = event.label;
    this.initApi();
  }
  downloadList() {
    
  }

}
