import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-select',
  inputs: ['cssClass'],
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss']
})
export class FormSelectComponent implements OnInit {
  cssClass: string = 'col-sm-7';
  constructor() {
  }

  ngOnInit() {
  }
}
