  <app-fil-ariane-v2></app-fil-ariane-v2>
  <div class="maincontainer-fluid font-color pb-4">
    <div class="maincontainer">
    <!-- <div ngbPopover="Add guest" popoverClass="popover-custom-x-light" triggers="mouseenter:mouseleave"
         [routerLink]="['/admin/contacts/add']" style="outline: none;cursor: pointer; height: 50px; width:150px;background-color: #FFF;border: 1px solid #84c000;border-radius: 28px; display: flex; box-shadow: 0px 1px 3px #5d6163;margin-bottom: 25px;margin-left: 8px;">
      <span style="height: 25px; width: 25px; color:#84c000 ; font-size:30pt; font-weight: bold;text-align:center;margin-top: -8px;margin-left: 12px;">+</span>
      <span style="color:#84c000 ; font-size:13pt; font-weight: bold; margin-top:12px; margin-left:12px;">Add Conta</span>
    </div> -->

    <div class="size__card--responsive">

      
        <div class="responsive-card">
          <div *ngFor="let card of dataCardSend"> 
            <app-card-partcipants-funnel *ngIf='card.cardType === 4' [data]="card.cardData"></app-card-partcipants-funnel>
          </div>
        </div>

      <div class="card-columns">

        <div *ngFor="let card of dataCardSend">
          <div class="responsive-card" *ngIf='card.cardType !== 4'>
            <app-card-participants *ngIf='card.cardType === 1' [data]="card.cardData"></app-card-participants>
            <app-card-pie-chart *ngIf='card.cardType === 2' [data]="card.cardData"></app-card-pie-chart>
            <!-- <app-card-new-attendee *ngIf='card.cardType === 3' [data]="card.cardData"></app-card-new-attendee> -->
            <!-- <app-card-progress-chart *ngIf='card.cardType === 4' [data]="card.cardData"></app-card-progress-chart> -->
            <app-card-edit-attendee  *ngIf='card.cardType === 3'  [data]="card.cardData"></app-card-edit-attendee>
          </div>
        </div>

      </div>
    </div>
  </div>
  </div>
