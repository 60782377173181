<form (ngSubmit)="onSubmit()" [formGroup]='dataNight'>
    <div class="mt-4 mb-4 ml-2 mr-2 pt-4 pb-3 pl-1 pr-1" style="display: flex; justify-content: space-around">
        <div class="date w_12 text-align-center">
            <mat-form-field class="">
                <input matInput [matDatepicker]="date" name="date" formControlName="dateString" class="mat_imput_custom">
                <mat-datepicker-toggle matIconSuffix [for]="date"></mat-datepicker-toggle>
                <mat-datepicker #date></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="w_12 text-align-center">
            <select style="width: 180px;" class="" id="idHotel" formControlName="idHotel" (change)="onChangeRoomType()">
                <option value="0" i18n>Please select</option>
                <option *ngFor="let ht of listOfHotel" value="{{ht.idHotel}}">
                {{ht.hotelName}}
                </option>
            </select>
        </div>
        <div class="nbr_room w_8 text-align-center">
            <input style="width: 110px;" type="number" class="" id="nbrRoom" formControlName="nbrRoom">
        </div>
        <div class="surbooking w_8 text-align-center">
            <input style="width: 110px;" type="number" class="" id="surbooking" formControlName="nbrSurbook">
        </div>
        <div class="w_12 text-align-center">
            <select style="width: 180px;" class="" id="IdRoom" formControlName="idRoom">
                <option value="0" i18n>Please select</option>
                <option *ngFor="let room of listOfTypeRoom" value="{{room.id}}">
                {{room.label}}
                </option>
            </select>
        </div>
        <div class="price w_8 text-align-center">
            <input type="number" class="" id="price" formControlName="price">
            EUR
        </div>
        <div class="max w_8 text-align-center">
            <input style="width: 110px;" type="number" class="" id="max" formControlName="max">
        </div>
        <div class="w_15 par_style">
            <div style="width: 25%; text-align: end;" *ngFor="let par of participantType">
                <input class="form-check-input click_input" style=" height: auto;"
                (change)="onChangeType(par.libelle, $event.target.checked)" name="{{ par.libelle }}"
                type="checkbox" [checked]="par.checked" id="{{ par.libelle }}">
                <label class="form-check-label" for="{{ par.libelle }}">
                {{ par.libelle }}
                </label>
            </div>
        </div>
        <div class="w_11 text-align-center">
            <textarea formControlName="note" name="note" id="note" cols="15" rows="2"></textarea>
        </div>
        <div class="w_6 text-align-center">
            <svg *ngIf="data.idNight" class="svgTrash" id="svgSave" height="21px" width="30px" (click)="deleteNight()">
                <use xlink:href="#trash" />
            </svg>
            <svg *ngIf="data.idNight" class="svgCopy" id="svgSave" height="24px" width="24px" (click)="duplicateNight()">
                <use xlink:href="#copy" />
            </svg>
            <button class="buttonSvg" type="submit">
                <svg class="svgSave" id="svgSave" height="24px" width="24px">
                    <use xlink:href="#save" />
                </svg>
            </button>
        </div>
    </div>
</form>