import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-username-reset',
  templateUrl: './username-reset.component.html',
  styleUrls: ['./username-reset.component.scss']
})
export class UsernameResetComponent implements OnInit {
  public error = false;

  constructor(private fb: FormBuilder,
              private router: Router,
              private toastr: ToastrService) {
  }

  loginForm = this.fb.group({
    username: [''],
  });

  ngOnInit() {
  }

  onSubmit() {
    if (this.loginForm.value.username === 'event') {
      this.loginForm.reset();
      this.toastr.clear();
      this.toastr.success('Success', 'Connection success');
      this.router.navigateByUrl('/admin/dashboard');
    } else {
      this.toastr.clear();
      this.toastr.error('Error', 'Connection refused');
      this.error = true;
    }
  }

}
