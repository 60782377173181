<div class="card">
    <div class="card-body">
      <h4 placement="top" popoverClass="popover-custom-light" ngbPopover="Edit contact" triggers="mouseenter:mouseleave" class="card-title mb-0" [routerLink]="data.urlAdd" i18n>{{data.title}}</h4>
    </div>
    <!-- Add Button -->
    <div class="bottomRows">
        <div style="display: flex;width: 100%;height: 100%; justify-content: center; align-items: center;">
            <div placement="bottom" popoverClass="popover-custom-light" ngbPopover="Edit contact" triggers="mouseenter:mouseleave" [routerLink]=data.urlAdd style="background-color: #84c000; width: 83px; border-radius: 400%; height: 83px;display: flex; cursor: pointer; align-items: center;
            justify-content: center;">
                <svg class="svg-edit" width="30px" height="30px" id="dropdownMenuButton"
                 data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <use xlink:href="#editDetails" width="30px" height="30px"/>
                </svg>
            </div>
        </div>
    </div>
</div>
