
<!-- Desktop-->
<div class="display--nav-none">

  <nav class="mt-3">
    <div class="nav nav-tabs nav-responsive" id="nav-tab" role="tablist">
      <a placement="left" popoverClass="popover-custom-light" ngbPopover="View contact attendee" triggers="mouseenter:mouseleave"
      class="nav-item nav-link active" id="nav-attendee" data-toggle="tab" href="#attendee" role="tab"
      aria-controls="nav-attendee" aria-selected="true" i18n>Attendee</a>
      <a placement="top" popoverClass="popover-custom-light" ngbPopover="View contact profile" triggers="mouseenter:mouseleave"
      class="nav-item nav-link" id="nav-contact" data-toggle="tab" href="#contact" role="tab"
      aria-controls="nav-contact" aria-selected="false" i18n>Profile</a>
      <a placement="top" popoverClass="popover-custom-light" ngbPopover="View contact events" triggers="mouseenter:mouseleave"
      class="nav-item nav-link" id="nav-event" data-toggle="tab" href="#event" role="tab"
      aria-controls="nav-event" aria-selected="false" i18n>Events</a>
      <a placement="top" popoverClass="popover-custom-light" ngbPopover="View contact travel" triggers="mouseenter:mouseleave"
      class="nav-item nav-link" id="nav-travel" data-toggle="tab" href="#travel" role="tab"
      aria-controls="nav-travel" aria-selected="false" i18n>Travel</a>
      <a placement="top" popoverClass="popover-custom-light" ngbPopover="View contact hotel" triggers="mouseenter:mouseleave"
      class="nav-item nav-link" id="nav-hotel" data-toggle="tab" href="#hotel" role="tab"
      aria-controls="nav-hotel" aria-selected="false" i18n>Hotel</a>
      <span placement="right" popoverClass="popover-custom-light" ngbPopover="Edit contact" triggers="mouseenter:mouseleave" [routerLink]="urlEdit">
        <svg class="ml-3" width="30px" height="30px" aria-haspopup="true" aria-expanded="false" >
          <use xlink:href="#edit"/>
        </svg>
      </span>
    </div>
  </nav>
  <div class="tab-content mt-4 pb-5" id="nav-tabContent">
    <div class="tab-pane fade show active mt-1" id="attendee" role="tabpanel" aria-labelledby="nav-attendee">
      <div class="row" style="font-size: 13px">
        <div class="col-md-6" *ngFor="let section of attendee">
          <h6 class="nowayMedium" style="color: #244746; font-weight: bold;">{{section.title}}</h6>
          <div class="row mt-4">
            <div class="col-md-12">
              <div class="row" *ngFor="let field of section.fields">
                <div class="col-4">
                  <p class="fieldTitle">
                    {{field.title}}
                  </p>
                </div>
                <div class="col ft nowayRegular" style="color: #244746" *ngIf="field.title !== 'E-MAIL' && field.title !== 'E-MAIL2' && field.title !== 'MOBILE'">
                  {{field.content}}
                </div>
                <div class="col ft" *ngIf="field.title === 'E-MAIL' || field.title === 'E-MAIL2'">
                  <a class="no_link_a nowayRegular" style="color: #244746" href="mailto:{{field.content}}" target="_blank">{{field.content}}</a>
                </div>
                <div class="col ft" *ngIf="field.title === 'MOBILE'">
                  <a class="no_link_a nowayRegular" style="color: #244746" href="tel:{{field.content.replaceAll(' ','')}}" target="_blank">{{field.content}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-pane fade mt-1" id="contact" role="tabpanel" aria-labelledby="nav-contact">
      <div class="row" style="font-size: 13px">
        <div class="col-md-6" *ngFor="let section of contact">
          <h6 class="nowayMedium" style="color: #244746; font-weight: bold;">{{section.title}}</h6>
          <div class="row mt-4">
            <div class="col-md-12">
              <div class="row" *ngFor="let field of section.fields">
                <div class="col-4">
                  <p class="fieldTitle">
                    {{field.title}}
                  </p>
                </div>
                  <div *ngIf="field.title !== 'STATUS'" class="col ft nowayRegular" style="color: #244746">
                  {{field.content}}
                </div>
                <div *ngIf="field.title === 'STATUS'" class="col ft nowayRegular" style="color: #244746">
                  <span class="badge-status badge-status-{{field.content.toLowerCase().replace(' ', '-')}}">{{field.content}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-pane fade mt-1" id="event" role="tabpanel" aria-labelledby="nav-event">
      <div class="row" style="font-size: 13px">
        <div class="col-md-12" *ngFor="let section of event">
          <h6 class="nowayMedium" style="color: #244746; font-weight: bold;">{{section.title}}</h6>
          <div class="row mt-4">
            <div class="col-md-12 row justify-content-between userCard" *ngFor="let field of section.fields">
              <div class="col-md-6">
                <a href="{{field.detailUrl}}">
                  <h3>{{field.title}}</h3>
                  <span>{{field.date}} {{field.location}}</span>
                </a>
              </div>
              <div class="col-md-2" style="display: flex; align-items: flex-end;">
                  <p>{{field.channel}}</p>
              </div>
              <div class="col-md-2">
                  <p>
                      <span class="badge-status badge-status-{{field.status.toLowerCase().replaceAll(' ', '-')}}">{{field.status}}</span>
                  </p>
                  <span style="font-size: 12px">{{field.dateStatus}}</span>
              </div>
              <div class="col-md-2">
                  <p>{{field.onsite}}</p>
                  <span style="font-size: 12px">{{field.dateOnsite}}</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="tab-pane fade mt-1" id="travel" role="tabpanel" aria-labelledby="nav-travel">
      <form [formGroup]='travelForm' class="mb-5">
        <h6 class="nowayMedium" style="color: #244746; font-weight: bold;" i18n>EVENT</h6>
        <div class="row mt-3">
          <div class="col-md-7">
            <select class="select form-control" id="event" formControlName="idEvent" (change)="onChange()">
              <option *ngFor="let event of this.listOfAllEvent" [value]="event.idEvent">
                {{event.startDate}} - {{event.event}}
              </option>
            </select>
          </div>
          <div *ngIf="status_event" class="col-md-5 col-12 row" style="justify-content: center; align-items: center;">
            <p>
              <span class="badge-status badge-status-{{status_event.toLowerCase().replaceAll(' ', '-')}}">{{status_event.substring(0,1).toUpperCase() + status_event.substring(1)}}</span>
            </p>
          </div>
        </div>
      </form>
      <div class="row" style="font-size: 13px">
        <div class="col-md-6 mb-5" *ngFor="let section of travel">
          <h6 class="nowayMedium" style="color: #244746; font-weight: bold;">{{section.title}}</h6>
          <div class="row mt-4">
            <div class="col-md-12 ">
              <div class="row" *ngFor="let field of section.fields">
                <div *ngIf="field.title" class="col-4">
                  <p>
                    {{field.title}}
                  </p>
                </div>
                <div *ngIf="field.content !== ''" class="col ft nowayRegular" style="color: #244746">
                  {{field.content}}
                </div>
                <div *ngIf="field.content == ''" class="col ft nowayRegular" style="color: #244746">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-pane fade mt-1" id="hotel" role="tabpanel" aria-labelledby="nav-hotel">
      <div class="row" style="font-size: 13px">
        <div class="col-md-6" *ngFor="let section of hotel">
          <h6 class="nowayMedium" style="color: #244746; font-weight: bold;">{{section.title}}</h6>
          <div class="row mt-4">
            <div class="col-md-12">
              <div class="row" *ngFor="let field of section.fields">
                <div class="col-4">
                  <p>
                    {{field.title}}
                  </p>
                </div>
                <div *ngIf="field.content !== ''" class="col ft nowayRegular" style="color: #244746">
                  {{field.content}}
                </div>
                <div *ngIf="field.content == ''" class="col ft nowayRegular" style="color: #244746">
                  -
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>












<!-- Mobile -->
<div class="accordion display--collapse-none mt-3" id="accordionExample">
  <div class="">
    <div id="headingOne">
      <h2 class="mb-0">
        <button class="btn btn-link pl-0" type="button" data-toggle="collapse" data-target="#collapseOne"
                aria-expanded="false" aria-controls="collapseOne">
          <span class="h3">Attendee</span>
        </button>
        <svg class="float-right" id="AddThings" height="44px" width="44px" data-toggle="collapse"
             data-target="#collapseOne" aria-haspopup="true"
             aria-expanded="false" style="fill: #84C000; margin-top: 12px">
          <use xlink:href="#plus"/>
        </svg>
      </h2>
    </div>

    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
      <div class="card-body">

        <div class="row" style="font-size: 13px">
          <div class="col-md-6 pr-0 pl-0">
            <h6 class="nowayRegular" style="color: #244746; font-weight: bold;">ASSISTANT INFORMATION</h6>

            <div class="row mt-4">
              <div class="col-md-12">
                <p>FIRST NAME <span class="nowayRegular participant__detail--firstname">Alfred</span>
                </p>
                <p>LAST NAME <span class="nowayRegular participant__detail--lastname">Hitchcock</span>
                </p>
                <p>TITLE <span class="nowayRegular participant__detail--title">-</span></p>
                <p>JOB POSITION <span class="nowayRegular participant__detail--job">Director</span></p>
                <p>DIRECT PHONE <span
                        class="nowayRegular participant__detail--phone--direct--mobile">+33 (0)1 54 65
                                        78 96</span>
                </p>
                <p>MOBILE <span class="nowayRegular participant__detail--phone--mobile">+33 (0)6 54 65
                                        78 96</span>
                </p>
                <p>E-MAIL <span
                        class="nowayRegular participant__detail--email--mobile">alfred.hitckock@microsoft.com</span>
                </p>
              </div>
            </div>

          </div>

          <div class="col-md-6 pr-0 pl-0">
            <h6 class="nowayRegular">EMERGENCY CONTACT</h6>

            <div class="row mt-4">
              <div class="col-md-12">
                <p>FIRST NAME <span class="nowayRegular participant__detail--firstname">Alfred</span>
                </p>
                <p>LAST NAME <span class="nowayRegular participant__detail--lastname">Hitchcock</span>
                </p>
                <p>MOBILE <span class="nowayRegular participant__detail--phone--mobile">+33 (0)6 54 65
                                        78 96</span>
                </p>
                <p>E-MAIL <span
                        class="nowayRegular participant__detail--email--mobile">alfred.hitckock@microsoft.com</span>
                </p>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="mt-3">
    <div id="headingTwo">
      <h2 class="mb-0">
        <button class="btn btn-link pl-0 collapsed" type="button" data-toggle="collapse"
                data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
          <span class="h3">Participation</span>
        </button>
        <svg class="float-right" id="AddThings" height="44px" width="44px" data-toggle="collapse"
             data-target="#collapseTwo" aria-haspopup="true"
             aria-expanded="false" style="fill: #84C000; margin-top: 12px">
          <use xlink:href="#plus"/>
        </svg>
      </h2>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
      <div class="card-body">
        2
      </div>
    </div>
  </div>
  <div class="mt-3">
    <div id="headingThree">
      <h2 class="mb-0">
        <button class="btn btn-link pl-0 collapsed" type="button" data-toggle="collapse"
                data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          <span class="h3">Travel</span>
        </button>
        <svg class="float-right" id="AddThings" height="44px" width="44px" data-toggle="collapse"
             data-target="#collapseThree" aria-haspopup="true"
             aria-expanded="false" style="fill: #84C000; margin-top: 12px">
          <use xlink:href="#plus"/>
        </svg>
      </h2>
    </div>
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
      <div class="card-body">
        3
      </div>
    </div>
  </div>
  <div class="mt-3">
    <div id="headingFive">
      <h2 class="mb-0">
        <button class="btn btn-link pl-0 collapsed" type="button" data-toggle="collapse"
                data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
          <span class="h3">Hotel</span>
        </button>
        <svg class="float-right" id="AddThings" height="44px" width="44px" data-toggle="collapse"
             data-target="#collapseFive" aria-haspopup="true"
             aria-expanded="false" style="fill: #84C000; margin-top: 12px">
          <use xlink:href="#plus"/>
        </svg>
      </h2>
    </div>
    <div id="collapseFive" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
      <div class="card-body">
        4
      </div>
    </div>
  </div>
</div>
