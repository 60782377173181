import { Component, OnInit } from '@angular/core';
import { CheckDeviceService } from '../../../core/http/check-device.service';
import { slideInUpOnEnterAnimation, slideOutLeftOnLeaveAnimation, } from 'angular-animations';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-meals',
  templateUrl: './meals.component.html',
  styleUrls: ['./meals.component.scss'],
  animations: [
    slideInUpOnEnterAnimation(),
    slideOutLeftOnLeaveAnimation()
  ]
})
export class MealsComponent implements OnInit {

  mobileRes: boolean;
  filterStatut = false;
  className1 = 'zoneContainerShadow';
  className2 = 'zoneContainerShadow';
  firstFilter = 'All zones';
  zoneFilter = ['Africa', 'Asia', 'Baltic Countries', 'Benelux', 'British Isles',
    'Central & Eastern Europe', 'DACH', 'Iberian Peninsula', 'Indian Sub Continent',
    'Latin America', 'Maghreb', 'Middle East', 'Nordic Countries', 'North America',
    'Oceania', 'Russia and CIS', 'South East Asia', 'Southern Europe'];
  config: any;
  collection = [];
  nbItems = 10;
  count: number;
  status: '';

  constructor(
    private checkDeviceService: CheckDeviceService,
    private route: ActivatedRoute,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.config = {
      currentPage: 1,
      itemsPerPage: this.nbItems
    };

    this.route.queryParamMap.pipe(map(params => params.get('page')))      
      .subscribe(page => this.config.currentPage = page);

    for (let i = 1; i <= 301; i++) {    // A Supprimer, définit nb d'items dans la collection
      this.collection.push(`item ${i}`);
    }
  }

  ngOnInit() {
    this.mobileRes = this.checkDeviceService.isMobileResolution;
    this.count = 0;

    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {       // Recup id hotel pour patchvalue les inputs et pour remplir le fil d'arianne
      status = params.get('status');
    });
  }
  userCount(event) {
    if (event === 1) {
      this.count = this.count + 1;
    } else if (event === -1) {
      this.count = this.count - 1;
    }
    console.log(this.count);
  }

  checkStatut($event) {
    this.filterStatut = $event;
  }

  pageChange(newPage: number) {
    this.router.navigate(['admin/meals/'], { queryParams: { page: newPage } });
  }

  onItemsChange(event) {
    this.config.itemsPerPage = event;
  }
}
