<div class="row details-responsive responsiveHeader">
    <div class="col-md-1 mt-1 " style="display: flex;">
        <div class="float-left">
            <div class="mt-2 pipe">
                <svg id="dropdownMenuButton6" height="24px"
                width="24px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <use xlink:href="#minus" />
                </svg>
            </div>
        </div>
        <div class="mt-2">
            <span class="badge-status badge-status-{{listOfDetailsContact?.status}}">
                {{listOfDetailsContact?.status}}
            </span>
        </div>
    </div>
    <div class="col-md-8 mt-1 row">
        <div class="col-md-9">
            <p class="mb-0">
                <span class="h1 nowayMedium title_responsive" style="font-size: 31px">{{listOfDetailsContact?.lastName}} {{listOfDetailsContact?.firstName}}</span>
                <span *ngIf="listOfDetailsContact?.main" class="badge-status badge-status-success ml-4" i18n>main</span>
                <span *ngIf="listOfDetailsContact?.vip" class="badge-status badge-status-success ml-2" i18n>vip</span>
            </p>
        </div>
        <div class="col-md-11">
            <p class="badge--id mt-0 idParticipant">{{listOfDetailsContact?.idPerson}}</p>
        </div>
    </div>
</div>
