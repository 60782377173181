<div class="overlay">
  <div class="contains-autocomplete">
    <form [formGroup]='companyForm'>
      <table width="100%" colspan='5'>
        <thead style="line-height: 51px !important; background-color: white;">
          <mat-placeholder id="placeholder" style="background-color:white;" class="mat-holderplace">
            <tr class="trr">
              <th scope="row" width="261px">
                <input formControlName="inputName" type="text" (keyup)="changeInput('inputName')" id="inputName"
                  style="width:255px; height:30px!important" class="input-autocomplete" autocomplete="chrome-off"
                  placeholder="Name" />
              </th>
              <th width="120px"><input type="text" formControlName="inputId" (keyup)="onInputChanges()" id="inputId"
                  autocomplete="off" class="input-autocomplete" placeholder="Id" /></th>
              <th width="120px"><input type="text" formControlName='inputZipcode' (keyup)="changeInput('inputZipcode')"
                  id="inputZipcode" autocomplete="chrome-off" class="input-autocomplete" placeholder="ZipCode" /></th>
              <th width="120px"><input type="text" autocomplete="off" formControlName='inputCountry'
                  (keyup)="changeInput('inputCountry')" id="inputCountry" class="input-autocomplete"
                  placeholder="Country" /></th>
              <th width="120px"><input type="text" autocomplete="off" formControlName='inputType'
                  (keyup)="changeInput('inputType')" id="inputType" class="input-autocomplete" placeholder="Type" />
              </th>
              <th style="vertical-align:middle; padding-left:40px;" width="24px"><svg class="iconCroix"
                  points="0,0 30,0 15,20" transform="rotate(45)" width="24px" height="24px"
                  (click)="resetCompanyForm()">
                  <use xlink:href="#plus" />
                </svg></th>
            </tr>
          </mat-placeholder>
        </thead>
      </table>
    </form>
    
    <div class="autocomplete m-0">


      <div class="row m-0">

        <div class="col-md-12 p-0">


          <table width="100%" colspan='5'>
            <tbody>
              <div *ngIf="!loader">
                <mat-option id="datalist1" class="color-autocomplete" style="max-height:800px!important;"
                  *ngFor="let company of companyList" (click)="getCompanySelected(company)">
                  <tr>
                    <th class="companyName" width="260.8px">{{company.companyName}}</th>
                    <td class="companyInfo" width="120px">{{company.id}}</td>
                    <td class="companyInfo" width="120px">{{company.zipCode}}</td>
                    <td class="companyInfo" width="120px">{{company.country}}</td>
                    <td class="companyInfo" width="120px">{{company.type}}</td>
                  </tr>
                </mat-option>
              </div>
              <div *ngIf="loader" class="loader_ec">
                  <tr>
                    <app-loader></app-loader>
                  </tr>
              </div>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <mat-option id="placeholder1" class="borderAddCompany"
      style="background-color:white; position:absolute; min-width:820px!important; max-width:820px!important; padding:4%; height: 70px;">
      <form (ngSubmit)="onSubmit()" [formGroup]="addCompanyForm" style="display: flex; justify-content: space-around; align-items: center;">
        <th scope="row" width="150px">
          <app-form-input>
            <input type="text" class="input form-control" id="name" formControlName="companyName" placeholder="Name" required style="width: 150px;">
          </app-form-input>
        </th>
        <th scope="row" width="150px">
          <app-form-select>
            <select class="select form-control" id="idCountry" formControlName="idCountry" required style="width: 150px;">
              <option value="0" i18n>Please select</option>
              <option *ngFor="let country of listOfCountry" [value]="country.id">
                {{country.label}}
              </option>
            </select>
          </app-form-select>
        </th>
        <th scope="row" width="180px">
          <app-form-input>
            <input type="text" class="input form-control" id="zipCode" formControlName="zipCode" placeholder="ZipCode" required style="width: 150px;">
          </app-form-input>
        </th>
        <th width="150px">
          <button [disabled]="" type="submit" class="btn-create-company" i18n>Add Company</button>
        </th>
      </form>
    </mat-option>
  </div>
  
</div>