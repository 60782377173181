import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-event',
  templateUrl: './header-event.component.html',
  styleUrls: ['./header-event.component.scss']
})
export class HeaderEventComponent implements OnInit {

  constructor(private authenticationService: AuthenticationService,
              private toastr: ToastrService,
              private router: Router) { }

  ngOnInit() {
  }

  logout() {
    this.authenticationService.logout();
    this.toastr.success('Success', 'Profile logout');
    this.router.navigateByUrl('/');
  }

}
