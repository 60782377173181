import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-settings-manage-user',
  templateUrl: './settings-manage-user.component.html',
  styleUrls: ['./settings-manage-user.component.scss']
})
export class SettingsManageUserComponent implements OnInit {
  public closeResult: string;

  constructor(private fb: FormBuilder,
              private modalService: NgbModal) {
  }

  public userForm = this.fb.group(
    {
      id: [Math.random().toString(36).substr(2, 9)],
      dateCreate: [new Date()],
      username: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      email: ['', Validators.required],
      role: ['', Validators.required],
      twoFactorAuthentication: [''],
      locked: 'No',
    });

  public updateUserForm = this.fb.group(
    {
      id: [''],
      username: ['', Validators.required],
      email: ['', Validators.required],
      role: ['', Validators.required],
      twoFactorAuthentication: [''],
    });

  public settingManageUser = [
    {
      id: '1',
      dateCreate: '2019/09/18',
      username: 'Bob',
      password: 'test',
      confirmPassword: 'test',
      email: 'test@gmail.com',
      locked: 'No',
      role: 'Admin',
      twoFactorAuthentication: 'Yes',
      platformAccess: ['test', 'youpi', 'demo', 'test', 'youpi', 'demo', 'test', 'youpi', 'demo', 'test', 'youpi', 'demo'],
    },
    {
      id: '2',
      dateCreate: '2019/09/18',
      username: 'Silver',
      password: 'test',
      confirmPassword: 'test',
      email: 'test@gmail.com',
      locked: 'No',
      role: 'Checkin',
      twoFactorAuthentication: 'No',
      platformAccess: ['test', 'youpi', 'demo2'],
    },
    {
      id: '3',
      dateCreate: '2019/09/18',
      username: 'Matt',
      password: 'test',
      confirmPassword: 'test',
      email: 'test@gmail.com',
      locked: 'No',
      role: 'Admin',
      twoFactorAuthentication: 'No',
      platformAccess: ['test', 'youpi', 'demo'],
    },
    {
      id: '4',
      dateCreate: '2019/09/18',
      username: 'Jack',
      password: 'test',
      confirmPassword: 'test',
      email: 'test@gmail.com',
      locked: 'No',
      role: 'Admin',
      twoFactorAuthentication: 'Yes',
      platformAccess: ['test', 'youpi', 'demo'],
    },
    {
      id: '5',
      dateCreate: '2019/09/18',
      username: 'Stabilo',
      password: 'test',
      confirmPassword: 'test',
      email: 'test@gmail.com',
      locked: 'No',
      role: 'Admin',
      twoFactorAuthentication: 'Yes',
      platformAccess: ['test', 'youpi', 'demo'],
    },
  ];

  ngOnInit() {
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    },                                                                                 (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openPlatformAccess(content) {
    this.modalService.open(content, { ariaLabelledBy: 'openPlatformAccess' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    },                                                                                  (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    }  if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  onSubmit() {
    this.settingManageUser.push(this.userForm.value);
    this.userForm.reset();
  }

  onSubmitUpdate() {
    console.log(this.updateUserForm.value);
   /* this.settingManageUser.splice(this.updateUserForm.value.username);
    this.updateUserForm.reset();*/
  }

}
