import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-user-card-desktop-v2',
  templateUrl: './user-card-desktop-v2.component.html',
  styleUrls: ['./user-card-desktop-v2.component.scss']
})
export class UserCardDesktopV2Component implements OnInit {

  @Output() countUser: EventEmitter<any> = new EventEmitter(); // Emit l'etat de la checkbox
  @Input() data: any; // On recoit les data a afficher depuis List-User-Desktop
  @Input() check: any;

  form: FormGroup;
  userSelected = false;
  detailUrl: string;
  updateUrl: string;
  updateCompanyUrl: string;
  mailAdressUrl: string;
  telUrl: string;
  url: any;
  route: string;
  public href: string;

  constructor(
    private fb: FormBuilder,
    private router: Router
  ) {
    this.form = fb.group({ checkBoxControl: new FormControl() });
  }


  ngOnInit() {
    this.data.mobile = this.data.mobile.replaceAll(' ', '');
    this.href = this.router.url;
    this.url = this.href.split('/');
    this.route = this.url[this.url.length - 2];
    this.changeNameRoute();
    if (this.data.main == true) { this.data.main = "Main" }
    else { this.data.main = "" }
    if (this.data.vip == true) { this.data.vip = "Vip" }
    else { this.data.vip = null }
    this.detailUrl = '/admin/contacts/' + this.data.idPerson + "/details";
    this.updateUrl = '/admin/contacts/' + this.data.idPerson + "/edit";
    this.updateCompanyUrl = '/admin/company/' + this.data.idCompany + "/details";
    this.mailAdressUrl = 'mailto:' + this.data.email;
    this.telUrl = 'tel:' + this.data.mobile;
    this.userSelected = this.check;
    this.userSelected ? this.countUser.emit(1) : this.countUser.emit(0);
  }
  changeNameRoute() {
    if (this.route == "contacts") {
      if (this.data.status == "conf") { this.data.status = "act"; }
      if (this.data.status == "inv") { this.data.status = "occa"; }
      if (this.data.status == "can") { this.data.status = "old"; }
      if (this.data.status == "short") { this.data.status = "pen"; }
    } else if (this.route == "events") {
      if (this.data.status == "pen") { this.data.status = "short"; }
    }
  }
  ngOnChanges() {
    if (this.userSelected == this.check) {
      this.countUser.emit(0);
    } else if (this.userSelected == true) {
      this.userSelected = this.check
    } else if (this.userSelected == false) {
      this.userSelected = this.check
      this.countUser.emit(1);
    }
  }

  selectUser(event: any) {
    this.userSelected = !this.userSelected;
    this.userSelected ? this.countUser.emit(1) : this.countUser.emit(-1);
  }

  checkState(event) {
    console.log(event);
  }
}
