import { Component, OnInit, Input } from '@angular/core';
import { ParamMap, ActivatedRoute } from '@angular/router';
import { EventService } from '../../../../core/http/event.service'
@Component({
  selector: 'app-event-participant-status-card',
  templateUrl: './event-participant-status-card.component.html',
  styleUrls: ['./event-participant-status-card.component.scss']
})
export class EventParticipantStatusCardComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private eventService: EventService
  ) { }

  idEvent: any;
  listOfParticipantEvent: any;
  nbrConfirmed: number;
  nbrDeclined: number;
  nbrAttended: number;
  nbrInvited: number;
  nbrNoShow: number;

  async ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.idEvent = params.get('id');
    });
    this.eventService.getEventRegistrationStatus("confirmed", this.idEvent).subscribe(res => {
      if (res) { this.nbrConfirmed = res["length"]; } 
      else { this.nbrConfirmed = 0; }
    });
    this.eventService.getEventRegistrationStatus("invited", this.idEvent).subscribe(res => {
      if (res) { this.nbrInvited = res["length"]; } 
      else { this.nbrInvited = 0; }
    });
    this.eventService.getEventRegistrationStatus("declined", this.idEvent).subscribe(res => {
      if (res) { this.nbrDeclined = res["length"]; } 
      else { this.nbrDeclined = 0; }
    });
    this.eventService.getEventRegistrationStatus("attended", this.idEvent).subscribe(res => {
      if (res) { this.nbrAttended = res["length"]; } 
      else { this.nbrAttended = 0; }
    });
    this.eventService.getEventRegistrationStatus("noshow", this.idEvent).subscribe(res => {
      if (res) { this.nbrNoShow = res["length"]; } 
      else { this.nbrNoShow = 0; }
    });
  }
}
