import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-followup-item',
  templateUrl: './followup-item.component.html',
  styleUrls: ['./followup-item.component.scss']
})
export class FollowupItemComponent implements OnInit {
  @Input() data;

  followup_link;
  
  constructor() { }

  ngOnInit() {
    this.followup_link = "/admin/communication/emailing/followup/" + this.data.mailingId;
    // ICI FAIRE UN GET AVEC MAILING ID OU FOLLOWUP ID POUR RECUP LES DATA DU FOLLOWUP
  }

}
