import { Component, OnInit } from '@angular/core';
import { GetDataService } from '../../../core/http/get-data.service';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-details-company',
  templateUrl: './details-company.component.html',
  styleUrls: ['./details-company.component.scss']
})
export class DetailsCompanyComponent implements OnInit {

  selectedCompany;
  idCompany;
  constructor(private route: ActivatedRoute, private getDataService: GetDataService) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => { // Recupère l'id pour faire appel api ?
      this.idCompany = params.get('id');
    });
    
    this.getDataService.getCompanyByID(this.idCompany).subscribe( response => {
      this.selectedCompany = response;
    }, error => {
      console.log(error);
    });
  
    // this.countryCompany = (this.company.countryCompany.substr(0,3)).toUpperCase();
  }

}

