import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/core/http/event.service';


@Component({
  selector: 'app-details-event',
  templateUrl: './details-event.component.html',
  styleUrls: ['./details-event.component.scss']
})
export class DetailsEventComponent implements OnInit {
  routeSub: Subscription;
  idEvent: number;
  currentEvent;
  urlEditEvent;

  constructor(private EventService: EventService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {
      this.idEvent = params['id'] 
    });
    this.EventService.getEventById(this.idEvent).subscribe(data => {
      this.currentEvent = data;
    });
    this.urlEditEvent = "/admin/agenda/events/" + this.idEvent + "/edit";
  }

}
