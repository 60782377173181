import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-radio-v2',
  templateUrl: './form-radio-v2.component.html',
  styleUrls: ['./form-radio-v2.component.scss']
})
export class FormRadioV2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
