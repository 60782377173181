<div class="container" style="height:1900px; border:2px solid green; max-width: 1270px;">
    <div class="row" style="height:5%; border:1px solid black">
    </div>
    <div class="row" style="height:5%; border:1px solid black;">
        <div class="offset-md-3 col-md-6" style="border: 1px solid blue;">
        </div>
    </div>
    <div class="row" style="height:90%; border:1px solid black">
        <div class="offset-md-1 col-md-10" style="border: 1px solid blue;">
        </div>
    </div>
</div>