import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParticipantService {

  constructor(private http: HttpClient) { }

    //////////////////////////////////////// HTTP METHODS FOR PARTICIPANT //////////////////////////////////
    getParticipantByID(id: number) {
      return this.http.get(`${environment.apiUrl}/api/a/person/` + id);
    }
    getParticipantAttendee(id: number) {
      return this.http.get(`${environment.apiUrl}/api/a/person/attendee/` + id);
    }
    getParticipantCompany(id: number) {
      return this.http.get(`${environment.apiUrl}/api/a/person/company-` + id);
    }
    getParticipantIdWithCompanyIdAndNameByStatus(status: string) {
      return this.http.get(`${environment.apiUrl}/api/a/person/status-${status}`);
    }
    getParticipantByStatusWithPage(status: string, size: number, page: number, id_country: number, id_type: number,
    id_company: number, vip: number, main: number, search: string) { 
      return this.http.get(`${environment.apiUrl}/api/a/person/size-${size}&page-${page}&status-${status}&country-${id_country}&type-${id_type}&company-${id_company}&vip-${vip}&main-${main}&search-${search}`);
    }
    getAllParticipantWithPage(size: number, page: number) { 
      return this.http.get(`${environment.apiUrl}/api/a/person/contactInformation/size-${size}&page-${page}`);
    }
    updateParticipantContactInformation(body: any, id: number) {
      return this.http.put<any>(`${environment.apiUrl}/api/a/person/contactInformation/${id}`, body)
      .subscribe(
        data => console.log('success', data),
        error => console.log("L'error est : " + error)
      );
    }
    updateFastParticipantContactInformation(body: any, id: number) {
      return this.http.put<any>(`${environment.apiUrl}/api/a/person/contactInformation/person-${id}`, body);
    }
    updateParticipantEventRegistration(body: any, id: number) {
      return this.http.put<any>(`${environment.apiUrl}/api/a/person/eventsRegistration/${id}`, body);
    }
    updateParticipantAttendee(body: any, id: number) {
      return this.http.put<any>(`${environment.apiUrl}/api/a/person/attendee/${id}`, body)
      .subscribe(
        data => console.log('success', data),
        error => console.log("L'error est : " + error)
      );
    }
    updateParticipantEventLogistic(body: any, id: number) {
      return this.http.put<any>(`${environment.apiUrl}/api/a/person/eventsLogistic/${id}`, body)
      .subscribe(
        data => console.log('success', data),
        error => console.log("L'error est : " + error)
      );
    }
    createParticipant(body: any) {
      return this.http.post<any>(`${environment.apiUrl}/api/a/person/`, body);
    }
    getParticipantContactInformation(id: number) {
      return this.http.get(`${environment.apiUrl}/api/a/person/contactInformation/${id}`);
    }
    getParticipantTravel(id_person: number, id_event: number) {
      return this.http.get(`${environment.apiUrl}/api/a/person/eventsLogistic/person-${id_person}&event-${id_event}`);
    }
    getParticipantEventRegistration(id: number) {
      return this.http.get(`${environment.apiUrl}/api/a/person/eventsRegistration/${id}`);
    }
}
