<!-- Card guests confirmed -->
<div class="card">
  <div class="card-body">

    <div class="dropright dropright-action">
      <svg class="opacity-icon " width="24px" height="24px" id="dropdownMenuButton4"
           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <use xlink:href="#action" />
      </svg>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton4">
        <span class="dropdown-menu-arrow"></span>
        <a class="dropdown-item" href="http://ecv5-test.eventcatalyst.io/assets/extract_test.xls" i18n>Extract</a>
        <a class="dropdown-item" [routerLink]="['/admin/contacts/add']" i18n>Add participant</a>
        <a class="dropdown-item" [routerLink]="['/admin/communication/emailing']" i18n>Send email</a>
        <a class="dropdown-item" [routerLink]="['/admin/contacts/list/guests_confirmed']" i18n>View list</a>
      </div>
    </div>


    <h4 class="card-title mb-0">Guests</h4>
    <div class="badge badge-pill card__badge--color-success"><span i18n>CONFIRMED</span></div>

    <div class="bottomRows">

      <div class="row">
        <div class="col-12">
          <div><span class="h2Top">84</span> <span class="ml-2 valueLabel" i18n>CONFIRMED</span></div>
        </div>
      </div>

      <hr>

      <div class="row" style="padding-right:15px;align-items: baseline">

        <div class="col">
          <div><span class="h2">8</span> <span class="ml-2 valueLabel" i18n>NEW</span></div>
        </div>


        <div class="col col-ml-0">
          <div><span class="h2">176</span> <span class="h4 valueLabel ml-1 marginLabel" i18n>N-1</span></div>
        </div>

        <div class="col-12 col-sm-4 col-lg-4 col-xl-4">


          <ng-template #popList><span class="popover-custom" i18n>List confirmed guests</span></ng-template>
          <span [routerLink]="['/admin/contacts/list/guests_confirmed']" class="float-right mt-3" popoverClass="popover-custom" [ngbPopover]="popList"
                triggers="mouseenter:mouseleave">
            <svg class="opacity-icon  float-right" width="24px"
                 height="24px">
              <use xlink:href="#arrow-right" />
            </svg>
          </span>

          <ng-template #popMail><span class="popover-custom" i18n>Send mail</span></ng-template>
          <span [routerLink]="['/admin/communication/emailing']" class="float-right mr-3" style="margin-top: 17px" [ngbPopover]="popMail" popoverClass="popover-custom"
                triggers="mouseenter:mouseleave">
            <svg class="opacity-icon  float-right hover-svg" width="24px" height="24px">
              <use xlink:href="#mail" />
            </svg>
          </span>

          <ng-template #popAdd><span class="popover-custom" i18n>Add guest</span></ng-template>
          <span [routerLink]="['/admin/contacts/add']" class="float-right mt-3 mr-3" [ngbPopover]="popAdd" popoverClass="popover-custom"
                triggers="mouseenter:mouseleave">
            <svg class="opacity-icon  float-right" width="24px" height="24px">
              <use xlink:href="#user-plus" />
            </svg>
          </span>


        </div>
      </div>
    </div>
  </div>
</div>
<!-- End card -->
