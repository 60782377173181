<div class="maincontainer-fluid font-color pb-4 pb-5" style="min-height: 80vh; background-color: #f9f8f4">
  <div class="maincontainer">

    <div class="row">

      <div class="col-lg-8 col-md-12 mt-4">
        <h3 class="nowayRegular" i18n>Thelonious Bob</h3>
        <span style="opacity: 0.7" i18n>Super Admin</span>
      </div>

    </div>

    <div class="mt-4">

      <app-settings-menu url="Modules"></app-settings-menu>

    </div>

    <h2 class="mt-4" i18n>FORMULAIRES</h2>

    <ng-container *ngFor="let formulaire of modulesFormulaire">
      <div class="card mt-2">
        <div class="card-body" style="padding: 0.75rem 1.25rem;">
          <div class="row">
            <div class="col-xl-9" style="padding-top: 6px">
              <span class="nowayMedium">{{formulaire.name}}</span>
             <!-- <span class="badge badge-pill badge-success ml-2" *ngFor="let type of formulaire.type">{{type}}</span>-->
            </div>
            <div class="col-xl-3">
             <!-- <select class="form-control">
                <option>statuts</option>
                <option *ngFor="let status of formulaire.statuts">
                  {{ status }}
                </option>
              </select>-->
              <button class="float-right btn-link btn" type="button" data-toggle="collapse"
                      data-target="#collapseOne">
                      <svg width="24px" height="24px" style="fill: #84C000">
                        <use xlink:href="#plus"/>
                      </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>


    <h2 class="mt-4" i18n>API</h2>

    <ng-container *ngFor="let formulaire of modulesApi">
      <div class="card mt-2">
        <div class="card-body" style="padding: 0.75rem 1.25rem;">
          <div class="row">
            <div class="col-xl-9" style="padding-top: 6px">
              <span class="nowayMedium">{{formulaire.name}}</span>
             <!-- <span class="badge badge-pill badge-success ml-2" *ngFor="let type of formulaire.type">{{type}}</span>-->
            </div>
            <div class="col-xl-3">
              <button class="float-right btn-link btn" type="button" data-toggle="collapse"
                      data-target="#collapseOne">
                      <svg width="24px" height="24px" style="fill: #84C000">
                        <use xlink:href="#plus"/>
                      </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>


    <h2 class="mt-4">Bo</h2>

    <ng-container *ngFor="let formulaire of modulesBo">
      <div class="mb-2">

        <ngb-accordion #acc="ngbAccordion">
          <ngb-panel [id]="formulaire.id">
            <ng-template ngbPanelHeader let-opened="opened">
              <div *ngIf="formulaire.cards; else elseBlock" class="row">
                <div (click)="acc.toggle(formulaire.id)" class="col-xl-9" style="padding-top: 6px; cursor: pointer">
                  <span class="nowayMedium">{{formulaire.name}}</span>
                  <!--<span class="badge badge-pill badge-success ml-2"
                        *ngFor="let type of formulaire.type">{{type}}</span>
                  <span *ngIf="formulaire.cards" class="ml-1" style="cursor: pointer"
                        (click)="acc.toggle(formulaire.id)">
                     <span class="badge badge-pill color-blue ml-2"
                           *ngFor="let type of formulaire.cards">{{type.type}}</span>
                  </span>-->
                </div>
                <div class="col-xl-3">
                  <button class="float-right btn-link btn" type="button" data-toggle="collapse"
                          data-target="#collapseOne">
                          <svg width="24px" height="24px" style="fill: #84C000">
                            <use xlink:href="#plus"/>
                          </svg>
                  </button>
                </div>
              </div>

              <ng-template #elseBlock>
                <div class="row">
                  <div class="col-xl-9" style="padding-top: 6px">
                    <span class="nowayMedium">{{formulaire.name}}</span>
                    <!--<span class="badge badge-pill badge-success ml-2"
                          *ngFor="let type of formulaire.type">{{type}}</span>-->
                  </div>
                  <div class="col-xl-3">
                    <button class="float-right btn-link btn" type="button" data-toggle="collapse"
                            data-target="#collapseOne">
                            <svg width="24px" height="24px" style="fill: #84C000">
                              <use xlink:href="#plus"/>
                            </svg>
                    </button>
                  </div>
                </div>
              </ng-template>

            </ng-template>
            <ng-template ngbPanelContent>
              <div *ngIf="formulaire.cards" class="mt-1">
                <ng-container *ngFor="let card of formulaire.cards">
                  <div class="card-body p-2 mt-2">
                    <div class="row">
                      <div class="col-xl-9" style="padding-top: 6px">
                        <span class="nowayMedium">{{formulaire.name}}</span>
                       <!-- <span class="badge badge-pill badge-success ml-2" *ngFor="let type of card.type">{{type}}</span>-->
                      </div>
                      <div class="col-xl-3">
                        <button class="float-right btn-link btn" type="button" data-toggle="collapse"
                                data-target="#collapseOne">
                                <svg width="24px" height="24px" style="fill: #84C000">
                                  <use xlink:href="#plus"/>
                                </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>

      </div>
    </ng-container>


    <h2 class="mt-4" i18n>MOBILE</h2>


    <ng-container *ngFor="let mobile of modulesMobile">
      <div class="mb-2">

        <ngb-accordion #acc="ngbAccordion">
          <ngb-panel [id]="mobile.id">
            <ng-template ngbPanelHeader let-opened="opened">
              <div *ngIf="mobile.cards" class="row">
                <div (click)="acc.toggle(mobile.id)" class="col-xl-9" style="padding-top: 6px; cursor: pointer">
                  <span class="nowayMedium">{{mobile.name}}</span>
                  <!--<span class="badge badge-pill badge-success ml-2"
                        *ngFor="let type of mobile.type">{{type}}</span>
                  <span *ngIf="mobile.cards" class="ml-1" style="cursor: pointer"
                        (click)="acc.toggle(mobile.id)">
                     <span class="badge badge-pill color-blue ml-2"
                           *ngFor="let type of mobile.cards">{{type.type}}</span>
                  </span>-->
                </div>
                <div class="col-xl-3">
                  <button class="float-right btn-link btn" type="button" data-toggle="collapse"
                          data-target="#collapseOne">
                          <svg width="24px" height="24px" style="fill: #84C000">
                            <use xlink:href="#plus"/>
                          </svg>
                  </button>
                </div>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div *ngIf="mobile.cards" class="mt-1">
                <ng-container *ngFor="let card of mobile.cards">
                  <div class="card-body p-2 mt-2">
                    <div class="row">
                      <div class="col-xl-9" style="padding-top: 6px">
                        <span class="nowayMedium">{{mobile.name}}</span>
                        <!--<span class="badge badge-pill badge-success ml-2" *ngFor="let type of card.type">{{type}}</span>-->
                      </div>
                      <div class="col-xl-3">
                        <button class="float-right btn-link btn" type="button" data-toggle="collapse"
                                data-target="#collapseOne">
                                <svg width="24px" height="24px" style="fill: #84C000">
                                  <use xlink:href="#plus"/>
                                </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>

      </div>
    </ng-container>


  </div>
</div>
