<form [formGroup]="form">
    <div class="mb-3 userCard" [ngClass]="userSelected ? 'selected' : ''">
        <div class="row justify-content-between">
            <div class="col-md-6" style="display: flex">
                <mat-checkbox formControlName="checkBoxControl" [(ngModel)]='check' color="none" (click)="selectUser($event.target.checked)">
                </mat-checkbox>
                <div class="pipe">
                    <svg id="dropdownMenuButton6" height="24px"
                    width="24px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <use xlink:href="#minus" />
                    </svg>
                </div>
                <div class="col-md-6 row">
                    <a placement="top" popoverClass="popover-custom-light" ngbPopover="View details" triggers="mouseenter:mouseleave" [routerLink]="detailUrl">
                        <h3 class="userName">{{data.firstName}} {{data.lastName}}</h3>
                    </a>
                    <span placement="top" popoverClass="popover-custom-light popover-width" ngbPopover="Edit contact" triggers="mouseenter:mouseleave">
                        <svg [routerLink]="updateUrl" class="svgEdit" id="svgEdit"
                        style="cursor:pointer;margin-top: -5px;fill: #fffefb;margin-left: 15px;overflow: visible"
                        height="24px" width="24px">
                            <use xlink:href="#edit" />
                        </svg>
                    </span>
                </div>
                <div class="col-md-4">
                    <span *ngIf="data.main" class="badge-status badge-status-success mr-2">{{data.main}}</span>
                    <span *ngIf="data.vip" class="badge-status badge-status-success">{{data.vip}}</span>
                </div>
            </div>
            <div class="col-md-12" style="display: flex;flex: 0 0 30.333333% !important;">
                <span class="group col-md-4" style="text-align: end;">{{data.participantType.toUpperCase()}}</span>
                <div class="pipe">
                    <svg id="dropdownMenuButton6" height="24px"
                    width="24px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <use xlink:href="#minus" />
                    </svg>
                </div>
                <h3 placement="top" popoverClass="popover-custom-light" ngbPopover="View details" triggers="mouseenter:mouseleave" class="col-md-5" [routerLink]="['/admin/company/', data.idCompany, 'details']">{{data.company}}</h3>
                <span placement="top" popoverClass="popover-custom-light popover-width" ngbPopover="Edit company" triggers="mouseenter:mouseleave">
                    <svg [routerLink]="['/admin/company/', data.idCompany, 'edit']" class="svgEdit" id="svgEdit"
                        style="cursor:pointer;margin-top: -5px;fill: #fffefb;margin-left: 15px" height="24px" width="24px">
                        <use xlink:href="#edit" />
                    </svg>
                </span>
            </div>
        </div>
        <div class="row justify-content-between l2">
            <span class="badge-rotate badge-status badge-status-{{data.status}}">{{data.status.toUpperCase().substring(0,1) + this.data.status.substring(1)}}</span>
            <div class="col-md-8 pl-0">
                <p class="tittle">{{data.jobPosition}}</p>
            </div>
            <div class="col-md-3 col-sd-5" style="display: flex">
                <p>{{data.country}}</p>
            </div>
        </div>

        <div class="row justify-content-between l3">
            <div class="col-md-5" style="margin-left: 56px;">
                <a class="phoneNb" style="white-space: nowrap;color:#000000" [href]="telUrl">{{data.mobile}}</a>
                <a class="mail" style="color:#000000" [href]="mailAdressUrl">{{data.email}}</a>
            </div>
            <div class="col-md-3 col-sd-5">
                <a class="phoneNb" style="white-space: nowrap;color:#000000"
                href="tel:{{data.companyTelephone}}">{{data.companyTelephone}}</a>
            </div>
        </div>
        <hr>
        <div class="row footer">
            <p i18n>ADDED</p>
            <span class="mr-5">{{data.creationDate}}</span>
            <p i18n>MODIFIED</p>
            <span class="mr-5">{{data.modificationDate}}</span>
            <p class="accountM">ACCOUNT M.</p>
            <span class="accountM mr-5">{{data.accountManager}}</span>
            <p i18n>OPTION</p>
            <span *ngFor="let option of data.options">{{option}}</span>
        </div>
    </div>
</form>