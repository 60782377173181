<div class="row">
  <div class="col-md-12">
    <div>
      <!-- Desktop-->
      <div>
        <div class="tab-content" id="nav-tabContent">
          <div style="display: flex;">
            <nav class="nav-responsive-top" style="width: 100%;margin-bottom:1rem!important">
              <div class="nav nav-tabs nav-responsive" id="nav-tab" role="tablist">
                <a class="nav-item nav-link active nowayRegular" id="nav-event_info" data-toggle="tab" href="#participant_first"
                  role="tab" aria-controls="nav-event_info" aria-selected="true" i18n>Attendee</a>
              </div>
            </nav>
          </div>
          <form (ngSubmit)="onSubmit()" [formGroup]='participantForm'>
            <div id="participantInformationRow" class="row mt-3">
              <div class="col-md-6 col-6" id="participant_first">
                <app-form-input *ngIf='!isDisplayCompanyHeader'>
                  <label id="labelCompanyName" for="companyName" class="label col-sm-5 col-form-label" i18n>Company name&nbsp;*</label>
                  <input *ngIf="!isDisplayAutocomplete" autocomplete="chrome-off" #autoCompleteInput  name="companyName" type="text" autocomplete="off"
                         [ngClass]="participantForm.get('companyName').errors ? 'is-invalid' : 'is-valid'"
                         class="input form-control" id="companyName" formControlName="companyName"
                         (input)="checkDisplayAutocomplete($event.target.value)">
                </app-form-input>
                <app-autocomplete-company *ngIf="isDisplayAutocomplete" [companyNameValue]="companyNameValue"
                  (displayAutocomplete)="swapViewAutocomplete($event)" (selectedCompanyEmitter)="getSelectedCompany($event)">
                </app-autocomplete-company>
                <app-form-input>
                  <label for="firstName" class="label col-sm-5 col-form-label" i18n>First name&nbsp;*</label>
                  <input type="text" class="input form-control" name="firstName" id="firstName" formControlName="firstName"
                        [ngClass]="participantForm.get('firstName').errors ? 'is-invalid' : 'is-valid'" required>
                </app-form-input>
                <app-form-input>
                  <label for="lastName" class="label col-sm-5 col-form-label" i18n>Last name&nbsp;*</label>
                  <input type="text" class="input form-control" name="lastName" id="lastName" formControlName="lastName"
                        [ngClass]="participantForm.get('lastName').errors ? 'is-invalid' : 'is-valid'" required>
                </app-form-input>
                <app-form-input>
                  <label for="email" class="label col-sm-5 col-form-label" i18n>Email&nbsp;*</label>
                  <input [ngClass]="participantForm.get('email').errors ? 'is-invalid' : 'is-valid'"
                        type="email" class="input form-control" name="email" id="email" formControlName="email" required>
                </app-form-input>
            </div>
            <!-- Block company -->
            <div class="col-md-6 col-6" id="participant_second">
              <div class="row" *ngIf='isDisplayCompanyHeader'>
                <div class="col-md-8 mt-1">
                  <div class="company_selected">
                    <div class="row m-0">
                      <div class="nowayRegular w-100 d-flex justify-content-between" *ngIf="selectedCompany">
                        <p class="mb-1">
                          <a class="companyName_link" [routerLink]="urlSelectedCompany">
                            <svg class="mr-2" width="24px" height="24px">
                              <use xlink:href="#company" />
                            </svg>{{selectedCompany.companyName}}
                          </a>
                        </p>
                        <svg class="svgEdit" id="svgEdit" height="24px" width="24px" (click)="resetCompany()">
                          <use xlink:href="#eraser" />
                        </svg>
                      </div>
                    </div>
                    <p class="mb-1 mt-1" *ngIf="selectedCompany">{{selectedCompany.id}}</p>
                    <p class="mb-1 mt-1" *ngIf="selectedCompany">{{selectedCompany.country}}</p>
                    <p class="mb-1 mt-1" *ngIf="selectedCompany">{{selectedCompany.zipCode}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row col justify-content-end mt-5">
              <button type="button" class="btn btn-classic button--color btn-size btn-responsive mr-3" (click)="cancelEdit()">
                Cancel
              </button>
              <button [disabled]="!participantForm.valid" data-toggle="#existingModal" type="submit" class="btn-success btn-size btn" style="color: white;" i18n>
                Save
              </button>
            </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="p-4 already_exist modal" id="existingModal" tabindex="-1" aria-labelledby="existingModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="">
    <div class="">
      <div class="">
        <h4 class="mb-4 text-align-center" id="existingModalLabel" i18n>The contact already exists</h4>
      </div>
      <hr class="mt-2 mb-3 separator" style="background-color: #84C000;">
      <div class="dp-flex-justy-beetw">
        <div class="p-3">
          <h5 class="" i18n>In creation</h5>
          <ul class="p-0 m-0" style="list-style: none;">
            <li i18n>Company : {{selectedCompany.companyName}}</li>
            <li i18n>First name : {{participantForm["value"].firstName}}</li>
            <li i18n>Last name : {{participantForm["value"].lastName}}</li>
            <li i18n>Email : {{participantForm["value"].email}}</li>
          </ul>
        </div>
        <div class="ml-4 mr-4 arrow_middle">
          <div class="mt-2 pt-2 pr-2 pl-2 border_arrow_exist">
            <a href="/admin/contacts/{{idParticipantExist}}/edit" class="link_exist">
              <span>Edit existing</span>
              <svg class="mb-2" id="svgAR" height="24px" width="24px">
                <use class="arrow_exist" xlink:href="#arrow-right"/>
              </svg>
            </a>
          </div>
          <div class="mt-2 pt-2 pr-2 pl-2 border_arrow_edit">
            <a href="" data-dismiss="modal" class="link_edit">
              <svg class="mb-2" id="svgAL" height="24px" width="24px">
                <use class="arrow_edit" xlink:href="#arrow-left"/>
              </svg>
              <span>Edit creation</span>
            </a>
          </div>
        </div>
        <div *ngIf="listOfParticipantExisting" class="p-3" style="border: 1px solid;">
          <h5 class="" i18n>Already existing</h5>
          <ul class="p-0 m-0" style="list-style: none;">
            <li i18n>Company : {{selectedCompany.companyName}}</li>
            <li i18n>First name : {{listOfParticipantExisting?.firstName}}</li>
            <li i18n>Last name : {{listOfParticipantExisting?.lastName}}</li>
            <li i18n>Email : {{listOfParticipantExisting?.email}}</li>
            <li i18n><b>Id : {{listOfParticipantExisting?.idPerson}}</b></li>
            <!-- <li i18n>Phone : {{listOfParticipantExisting?.mobile}}</li>
            <li i18n>Country : {{listOfParticipantExisting?.country}}</li>
            <li i18n>City : {{listOfParticipantExisting?.city}}</li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
