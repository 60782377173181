<div class="search-dropdown" [ngClass]="{'open':shown}" id="Search-Dropdown{{id}}">
  <div class="search-dropdown__btn" (click)="show()">
    <span id="TextEvent">{{textEvent}}</span>
  </div>
  <div *ngIf="shown" class="search-dropdown__dropdown">
    <input class="search-dropdown__dropdown__search" type="text" placeholder="Search"
    [(ngModel)]="keyword" (keyup)="search(keyword)" #input id="SearchInput"/>
    <div *ngIf="'listSearch.length > 0'" class="search-dropdown__dropdown__scroller">
      <ng-container *ngFor="let item of listSearch">
        <div class="search-dropdown__dropdown__item" *ngIf="item.id != valueEvent"
        (click)="select(item)">
            {{datePipe.transform(item.date, 'yyyy-MM-dd')}} - {{item.label}}
        </div>
      </ng-container>
    </div>
    <div *ngIf="listSearch.length == 0" class="search-dropdown__dropdown__scroller">
      <div class="search-dropdown__dropdown__item" i18n>
        No Result Found
      </div>
    </div>
  </div>
</div>
