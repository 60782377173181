import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EC_Participant } from '../../models/EC_Participant';

@Component({
  selector: 'app-backup-form-edit',
  templateUrl: './old-form-edit.component.html',
  styleUrls: ['./old-form-edit.component.scss']
})
export class OldFormEditComponent implements OnInit {

  public id: string;
  public participants: EC_Participant;

  public title = ['Prof.', 'Dr.', 'Prof. Dr.'];
  public gender = ['Mr', 'Mrs'];
  // tslint:disable-next-line:max-line-length
  public functionSelect = ['Academic', 'Executive Management', 'Data scientist', 'Hardware/System/ Engineer', 'IT Management', 'Researcher (Non-Academic)', 'Sale/Marketing', 'Developer?Programmer', 'Engineer', 'Designer', 'Content Creation Professional', 'Startup/Emerging', 'Company Venture Capitalist/Investment banker', 'Other'];


  public attendeeInformation = this.fb.group(
    {
      firstNameAttendeeI: ['', Validators.required],
      lastNameAttendeeI: ['', Validators.required],
      titleAttendeeI: [''],
      genderAttendeeI: [''],
      jobPositionAttendeeI: [''],
      functionSelectAttendeeI: [''],
      functionInputAttendeeI: [''],
      directPhoneAttendeeI: [''],
      mobileAttendeeI: [''],
      mobileTwoAttendeeI: [''],
      emailAttendeeI: ['', Validators.required],

      AssistantCopyCorrespondence: [''],
      firstNameAssistant: ['', Validators.required],
      lastNameAssistant: ['', Validators.required],
      titleAssistant: [''],
      jobPositionAssistant: [''],
      directTelephoneAssistant: [''],
      mobileAssistant: [''],
      emailAssistant: ['', Validators.required],

      addressOneAttendeeA: [''],
      addressTwoAttendeeA: [''],
      cityAttendeeA: [''],
      stateAttendeeA: [''],
      zipCodeAttendeeA: [''],
      countryAttendeeA: [''],

      contactNameEmergency: [''],
      contactNumberEmergency: [''],

      uploadPicture: [''],
    });

  public eventInformation = this.fb.group(
    {
      status: [''],
      pass: [''],
      main: [''],
      free: [''],
      oneOnOne: [''],
      vip: [''],
      speaker: [''],
      hotel: [''],
      travel: [''],
      doNotPostEmail: [''],
      incident: [''],
      profileUpdate: [''],
      speakerType: [''],

      generalComments: ['', Validators.maxLength(1000)],
      couponUsed: [''],
    }
  );

  public travelDetails = this.fb.group(
    {
      travelOption: [''],
      date: [''],
      travelOptionD: [''],
      dateD: [''],

      departureCity: [''],
      preferredDepartureAirportStation: [''],
      frequentFlyerNumber: [''],
      readOnly: [''],
      departureCountry: [''],
      preferredAirline: [''],
      alternativeArrivalOrDepartureDate: [''],

      dietaryRequirements: [''],
      otherRequirements: [''],

      checkInDate: [''],
      roomType: [''],
      noAccommodation: [''],
      checkOutDate: [''],
    }
  );

  public hotelBooking = this.fb.group(
    {
      hotelBooking: [''],
      roomBooking: [''],
      commentsBooking: ['', Validators.maxLength(1000)],
      notifyAttendee: [''],
    }
  );

  constructor(private fb: FormBuilder,
              // private participantsService: ParticipantsService,
              private route: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get('id');
      if (this.id) {
        /*
        this.participantsService.getParticipants(this.id).subscribe(
          (participants: Participants) => {
            this.participants = participants;
            this.attendeeInformation.patchValue(participants);
            this.eventInformation.patchValue(participants);
            this.travelDetails.patchValue(participants);
            this.hotelBooking.patchValue(participants);
          },
        );
         */
      }
    });

  }

  onSubmit() {
    if (this.id) {
      // update participants

      if (this.attendeeInformation.value && this.eventInformation.value && this.travelDetails.value && this.hotelBooking.value) {
        console.log(this.attendeeInformation.value);
        console.log(this.eventInformation.value);
        console.log(this.travelDetails.value);
        console.log(this.hotelBooking.value);
        /* this.participantsService.putParticipants(
          this.attendeeInformation.value).subscribe(
          () => {
            this.toastr.clear();
            this.toastr.success('success', 'Participant Updated');
            this.router.navigateByUrl('/');
          },
          (error) => {
            this.toastr.clear();
            this.toastr.error(`Error ${error}`);
          });  */
      }

    } else {
      // create participants

      if (this.attendeeInformation.value && this.eventInformation.value && this.travelDetails.value && this.hotelBooking.value) {
        console.log(this.attendeeInformation.value);
        console.log(this.eventInformation.value);
        console.log(this.travelDetails.value);
        console.log(this.hotelBooking.value);
        /* this.participantsService.postParticipants(
          this.attendeeInformation.value).subscribe(
          () => {
            this.toastr.clear();
            this.toastr.success('success', 'Participant Created');
            this.router.navigateByUrl('/');
          },
          (error) => {
            this.toastr.clear();
            this.toastr.error(`Error ${error}`);
          }); */
      }

    }
  }

}
