import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormDataEventService {

  constructor(
    private fb: FormBuilder
  ) { }

  dataEvent: any;
  
  dataEvents() {
    return this.dataEvent = this.fb.group({
      EventName: ['', Validators.required],
      EventType: ['', Validators.required],
      Location: [''],
      Capacity: [''],
      Organizer: [''],
      IdLogo: [''],
      IdLogoBanner: [''],
      IdFooter: [''],
      StartDate: ['', Validators.required],
      StartHour: '',
      EndDate: ['', Validators.required],
      EndHour: '',
      StartDateRegistration: ['', Validators.required],
      StartRegistrationHour: '',
      EndDateRegistration: ['', Validators.required],
      EndRegistrationHour: '',
      IdTimezone: [''],
      replyTo: ['', Validators.required],
      TypeParticipant: [''],
      pitch: [''],
      description: ['', Validators.required],
      loginTxt: [''],
    });
  }
  dataContact() {
    return this.dataEvent = this.fb.group({
      contactInformation: [''],
    });
  }
  dataMenu() {
    return this.dataEvent = this.fb.group({
      openLive: [false],
      openNetworking: [false],
      idPlaceholder: [''],
      liveDate: [''],
      hourLive: [''],
      liveUrl: ['']
    });
  }
}
