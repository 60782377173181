import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(private http: HttpClient) { }

    //////////////////////////////////////// HTTP METHODS FOR DATE //////////////////////////////////
    datePickerToString(date: any) {
      if (date.day && date.month && date.year) {
        let day = date.day;
        let month = date.month;
        let year = date.year;
        return (date = day + "/" + month + "/" + year);
      }
      return ("");
    }
    timePickerToString(time: any) {
      if (time.hour >= 0 && time.minute >= 0) {
        let hour = time.hour;
        let min = time.minute;
        return (time = hour + ":" + min);
      }
      return ("");
    }

    formatToNgbDateStruct(date: string) {
      let myDate: NgbDateStruct = {
        year:  new Date(date).getFullYear(),
        month: new Date(date).getMonth() + 1,
        day: new Date(date).getDate()
      };  
      return myDate
    }

    formatToNgbTimeStruct(date: string) {
      let myDate: NgbTimeStruct = {
        hour: new Date(date).getHours(),
        minute:  new Date(date).getMinutes(),
        second:  new Date(date).getSeconds(),
      }
      return myDate
    }
}
