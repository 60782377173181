<div class="container" style="height:1900px; border:2px solid green; max-width: 1270px;">
    <div class="row" style="height:5%; border:1px solid black">
    </div>
    <div class="row font" style="height:10%; border:1px solid black;">
    </div>
    <div class="row" style="height:7%; border:1px solid black">
    </div>
    <div class="row" style="height:7%; border:1px solid black">
    </div>
    <div class="row" style="height:71%; border:1px solid black">
    </div>
</div>