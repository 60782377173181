import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filters-mobile',
  templateUrl: './filters-mobile.component.html',
  styleUrls: ['./filters-mobile.component.scss']
})
export class FiltersMobileComponent implements OnInit {

  @Output() filterStatut: EventEmitter<any> = new EventEmitter();

  statut: boolean;
  firstFilter = 'All zones';
  zoneFilter = ['Africa', 'Asia', 'Baltic Countries', 'Benelux', 'British Isles',
    'Central & Eastern Europe', 'DACH', 'Iberian Peninsula', 'Indian Sub Continent',
    'Latin America', 'Maghreb', 'Middle East', 'Nordic Countries', 'North America',
    'Oceania', 'Russia and CIS', 'South East Asia', 'Southern Europe'];


  constructor() { }

  ngOnInit() {
    this.statut = true;
  }

  closeFilters() {
    this.statut = !this.statut;
    this.filterStatut.emit(this.statut);
  }

}
