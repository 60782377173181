<div class="block__size--responsive">

    <div class="card mb-4">
      <div class="card-body p-3">
        <a class="nowayRegular mb-3" style="color: #244746; font-size: 18px; font-weight: bold;" i18n>CONTACTS
        </a>
        <div class="card-font-size" style="color: #244746; margin-top:10%">
          <p *ngFor='let participant of listOfParticipantCompany; let index = index;'><a  class="text-a" href="/admin/contacts/{{participant.id}}/details">{{participant.label}}</a></p>
        </div>
      </div>
    </div>
  
  </div>