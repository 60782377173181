<div class="container-fluid pb-4">
    <div class="maincontainer">

        <div class="row mt-4">

            <div class="col-lg-6 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <img class="float-left mt-5" src="assets/icon/navbarParticipants.svg" width="90" height="120"/>
                        <h4 class="text-center nowayRegular" i18n>Participants</h4>

                        <div class="list-group p-3">
                            <button type="button" class="list-group-item list-group-item-action" i18n>Add</button>
                            <button type="button" class="list-group-item list-group-item-action" i18n>List</button>
                            <button type="button" class="list-group-item list-group-item-action" i18n>Payments</button>
                            <button type="button" class="list-group-item list-group-item-action" i18n>Booth</button>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <img class="float-left mt-5" src="assets/icon/navbarCommunication.svg" width="90" height="120"/>
                        <h4 class="text-center nowayRegular" i18n>Communication</h4>

                        <div class="list-group p-3">
                            <button type="button" class="list-group-item list-group-item-action" i18n>Emailing</button>
                            <button type="button" class="list-group-item list-group-item-action" i18n>Text message broadcast</button>
                            <button type="button" class="list-group-item list-group-item-action" i18n>Push notification</button>
                            <button type="button" class="list-group-item list-group-item-action" i18n>Reports</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="row mt-3">

            <div class="col-lg-6 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <img class="float-left mt-3" src="assets/icon/navbarProgramme.svg" width="90" height="120"/>
                        <h4 class="text-center nowayRegular" i18n>Programme</h4>

                        <div class="list-group p-3">
                            <button type="button" class="list-group-item list-group-item-action" i18n>Business match</button>
                            <button type="button" class="list-group-item list-group-item-action" i18n>General agenda</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <img class="float-left mt-3" src="assets/icon/navbarLogistics.svg" width="90" height="120"/>
                        <h4 class="text-center nowayRegular" i18n>Logistics</h4>

                        <div class="list-group p-3">
                            <button type="button" class="list-group-item list-group-item-action" i18n>Hotel booking</button>
                            <button type="button" class="list-group-item list-group-item-action" i18n>Reports</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="row mt-3">

            <div class="col-lg-6 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <img class="float-left mt-5" src="assets/icon/navbarTools.svg" width="90" height="120"/>
                        <h4 class="text-center nowayRegular" i18n>Tools</h4>

                        <div class="list-group p-3">
                            <button type="button" class="list-group-item list-group-item-action" i18n>Extraction</button>
                            <button type="button" class="list-group-item list-group-item-action" i18n>Import</button>
                            <button type="button" class="list-group-item list-group-item-action" i18n>Coupons</button>
                            <button type="button" class="list-group-item list-group-item-action" i18n>Config</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
