<div class="maincontainer-fluid font-color pb-4">
  <div class="maincontainer">

    <!-- <div>

      <ol class="breadcrumb pl-0 breadcrumb-color" style="background-color: #f9f8f4;">
        <svg id="dropdownMenuButton6" height="24px" width="24px" aria-haspopup="true" aria-expanded="false"
             style="fill:#84C000">
          <use xlink:href="#company_f" />
        </svg>
        <li class="breadcrumb-item" *ngIf="url.length > 2"><a [routerLink]="['/admin/dashboard']">{{url[2]}}</a></li>
        <li class="breadcrumb-item" *ngIf="url.length > 3"><a [routerLink]="['/admin/contacts/guests/dashboard']">{{url[3]}}</a></li>
        <li class="breadcrumb-item active" aria-current="page" style="font-family:'nowaymedium';font-size: 18px;color:#244746">{{url[4]}}</li>
      </ol>
    </div> -->

    <div class="row">

      <div class="rowAdd">
        <div ngbPopover="Add new guest" popoverClass="popover-custom" triggers="mouseenter:mouseleave"
             [routerLink]="['/admin/contacts/add']" style="outline: none;cursor: pointer; height: 56px; width:56px;background-color: #84C000;border-radius: 28px; display: flex; justify-content: center; align-items: center;">
          <span style="height: 24px; width: 24px; color:#FFF ; font-size:30pt; font-weight: bold;text-align:center;margin-top: -44px;">+</span>
        </div>
        <!-- <div *ngIf="mobileRes">
          Add new guest
        </div> -->
      </div>

      <!-- <div class="col-xl-4">

        <div class="responsive-card">
          <app-card-participants [data]="EC_Card_Guests_all"></app-card-participants>
        </div>

        <div class="responsive-card">
          <app-card-participants [data]="EC_Card_Guests_invited"></app-card-participants>
        </div>

        <div class="responsive-card">
          <app-card-participants [data]="EC_Card_Guests_waiting_list"></app-card-participants>
        </div>

        <div class="responsive-card">
          <app-card-participants [data]="EC_Card_Guests_rejected"></app-card-participants>
        </div>

        <div class="responsive-card">
          <app-card-participants [data]="EC_Card_Guests_declined"></app-card-participants>
        </div>

        <div class="responsive-card">
          <app-card-participants [data]="EC_Card_Guests_cancelled"></app-card-participants>
        </div>

        <div class="responsive-card">
          <app-card-participants [data]="EC_Card_Guests_cancelled_D7"></app-card-participants>
        </div>

      </div> -->


      <div class="col-xl-4">

        <div class="responsive-card">
          <app-card-progress-chart></app-card-progress-chart>
        </div>

        <div class="responsive-card">
          <!-- <app-card-dashboard-guest-completed-travel></app-card-dashboard-guest-completed-travel> -->
        </div>

      </div>



      <div class="col-xl-4">

        <div class="responsive-card">
          <app-card-new-attendee></app-card-new-attendee>
        </div>


      </div>


    </div>



  </div>
</div>
