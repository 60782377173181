import { HttpClient } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImagesHandlerService {

  constructor(private http: HttpClient) { }

  uploadImage(file){
    const formData = new FormData();
    formData.append(file.name, file);
    let uploadReq:any;
    const url = `${environment.apiUrl}/api/a/image/`;
    return uploadReq = new HttpRequest('POST', url, formData, {
      reportProgress: true,
    });
  }
  getImage(idImg: number) {
    // const formData = new FormData();
    // formData.append(file.name, file);
    // let uploadReq:any;
    // const url = `${environment.apiUrl}/api/a/image/`;
    // return uploadReq = new HttpRequest('POST', url, formData, {
    //   reportProgress: true,
    // });
    return this.http.get(`${environment.apiUrl}/api/a/image/` + idImg);
  }
}
