import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '../../../../core/http/dashboard.service';
import { ParticipantService } from '../../../../core/http/participant.service';

@Component({
  selector: 'app-contact-dashboard',
  templateUrl: './contact-dashboard.component.html',
  styleUrls: ['./contact-dashboard.component.scss']
})
export class ContactDashboardComponent implements OnInit {

  constructor(
    private router: Router,
    private DashboardDataService: DashboardService,
    private ParticipantService: ParticipantService
  ) { }

  listOfParticipantAll: any;
  nbAll: number;
  listOfParticipantConf: any;
  nbConf: number;
  listOfParticipantInv: any;
  nbInv: number;
  listOfParticipantShort: any;
  nbShort: number;
  listOfParticipantWait: any;
  nbWait: number;
  listOfParticipantDec: any;
  nbDec: number;
  listOfParticipantCan: any;
  nbCan: number;
  listOfParticipantCan7: any;
  nbCan7: number;
  listOfParticipantRej: any;
  nbRej: number;
  listOfParticipantNone: any;
  nbNone: number;
  dataCardSend: any;
  elementEvents: any;
  dataCardSet = this.DashboardDataService;
  statut = false;
  link: any;
  url: any;
  route: string;
  public href: string;

  async ngOnInit() {
    this.dataCardSend = [];
    this.href = this.router.url;
    this.url = this.href.split('/');
    this.route = this.url[this.url.length - 1];
    for (let i = 0; this.url.length > i; i++) {
      this.url[i] = this.url[i].substr(0, 1).toUpperCase() + this.url[i].substr(1);
    }
    await this.initListApi();
    this.checkData();
    this.initCard();
  }
  async initListApi() {
    this.listOfParticipantAll = await this.ParticipantService.getParticipantIdWithCompanyIdAndNameByStatus("all").toPromise();
    this.listOfParticipantConf = await this.ParticipantService.getParticipantIdWithCompanyIdAndNameByStatus("confirmed").toPromise();
    this.listOfParticipantInv = await this.ParticipantService.getParticipantIdWithCompanyIdAndNameByStatus("invited").toPromise();
    this.listOfParticipantShort = await this.ParticipantService.getParticipantIdWithCompanyIdAndNameByStatus("shortlist").toPromise();
    this.listOfParticipantWait = await this.ParticipantService.getParticipantIdWithCompanyIdAndNameByStatus("waiting-list").toPromise();
    this.listOfParticipantRej = await this.ParticipantService.getParticipantIdWithCompanyIdAndNameByStatus("rejected").toPromise();
    this.listOfParticipantDec = await this.ParticipantService.getParticipantIdWithCompanyIdAndNameByStatus("declined").toPromise();
    this.listOfParticipantCan = await this.ParticipantService.getParticipantIdWithCompanyIdAndNameByStatus("cancelled").toPromise();
    this.listOfParticipantCan7 = await this.ParticipantService.getParticipantIdWithCompanyIdAndNameByStatus("cancelled7").toPromise();
    this.listOfParticipantNone = await this.ParticipantService.getParticipantIdWithCompanyIdAndNameByStatus("none").toPromise();
  }
  checkData() {
    this.nbAll = this.listOfParticipantAll ? this.listOfParticipantAll.length : 0;
    this.nbConf = this.listOfParticipantConf ? this.listOfParticipantConf.length : 0;
    this.nbInv = this.listOfParticipantInv ? this.listOfParticipantInv.length : 0;
    this.nbShort = this.listOfParticipantShort ? this.listOfParticipantShort.length : 0;
    this.nbWait = this.listOfParticipantWait ? this.listOfParticipantWait.length : 0;
    this.nbRej = this.listOfParticipantRej ? this.listOfParticipantRej.length : 0;
    this.nbDec = this.listOfParticipantDec ? this.listOfParticipantDec.length : 0;
    this.nbCan = this.listOfParticipantCan ? this.listOfParticipantCan.length : 0;
    this.nbCan7 = this.listOfParticipantCan7 ? this.listOfParticipantCan7.length : 0;
    this.nbNone = this.listOfParticipantNone ? this.listOfParticipantNone.length : 0;
  }
  initCard() {
    this.dataCardSend.push(this.dataCardSet.createCard3("edit contacts", "edit"));
    this.dataCardSend.push(this.dataCardSet.createCard1("rejected contacts", "rejected", this.nbRej, ''));
    this.dataCardSend.push(this.dataCardSet.createCard1("declined contacts", "declined", this.nbDec, ''));
    this.dataCardSend.push(this.dataCardSet.createCard1("all contacts", "all", this.nbAll, ''));
    this.dataCardSend.push(this.dataCardSet.createCard1("occasional contacts", "occasional", this.nbInv, ''));
    this.dataCardSend.push(this.dataCardSet.createCard1("old contacts", "old", this.nbCan, ''));
    this.dataCardSend.push(this.dataCardSet.createCard1("active contacts", "active", this.nbConf, ''));
    this.dataCardSend.push(this.dataCardSet.createCard1("pending contacts", "pending", this.nbShort, ''));
  }
}
