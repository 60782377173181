<div class="font-color pb-4">
  <div *ngIf="!idHappening" style="width: 100%;border-top: 3px solid #eae5d5;background-color: #fff; margin-bottom: 1%;">
    <nav class="breadcrumb-responsive filAriane row maincontainer" aria-label="breadcrumb">
      <ol class="breadcrumb_fil">
        <svg id="dropdownMenuButton6" height="24px" width="24px" aria-haspopup="true" aria-expanded="false"
            style="fill:#84C000">
          <use xlink:href="#company_f" />
        </svg>
        <div style="display:flex; align-items: center;">
          <ng-container *ngFor="let item of url; let myIndex = index; first as isFirst; last as isLast" >
              <li class="breadcrumb-item" *ngIf="myIndex > 1"><a>{{url[myIndex].replaceAll('-', ' ')}}</a></li>
          </ng-container>
          <li>&nbsp;/&nbsp;</li>
          <li style="width: 400px;">
            <app-form-search-select (afterChange)="afterChange($event)"></app-form-search-select>
          </li>
        </div>
      </ol>
      <div class="icons">
        <div class="btn-group dropleft ml-3">
          <span placement="left" popoverClass="popover-custom-light popover-width" ngbPopover="Add more" triggers="mouseenter:mouseleave"
          id="AddThings" data-toggle="dropdown">
            <svg aria-haspopup="true" aria-expanded="false" class="iconFilArianne">
                <use xlink:href="#plus"/>
            </svg>
          </span>
          <div class="dropdown-menu" aria-labelledby="AddThings">
              <a placement="left" popoverClass="popover-custom-light" ngbPopover="New agenda" triggers="mouseenter:mouseleave"
              class="dropdown-item" [routerLink]="['/admin/agenda/event-agenda/', valueEvent, 'add']" i18n>New agenda</a>
              <a placement="left" popoverClass="popover-custom-light" ngbPopover="New event" triggers="mouseenter:mouseleave"
              class="dropdown-item" [routerLink]="['/admin/agenda/events/add']" i18n>New event</a>
          </div>
        </div>
        <span placement="top" popoverClass="popover-custom-light popover-width" ngbPopover="Extract list" triggers="mouseenter:mouseleave">
          <svg height="24px" width="24px" class="ml-3" (click)="downloadList()">
            <use xlink:href="#export"/>
          </svg>
        </span>
        <div class="btn-group dropleft ml-3">
          <span placement="right" popoverClass="popover-custom-light popover-width" ngbPopover="More option" triggers="mouseenter:mouseleave"
          id="dropdownMenuButton2" data-toggle="dropdown">
            <svg aria-haspopup="true" aria-expanded="false" class="iconFilArianne">
                <use xlink:href="#action_h" />
            </svg>
          </span>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
              <a class="dropdown-item" href="#" i18n>Action 1</a>
              <a class="dropdown-item" href="#" i18n>Action 2</a>
              <a class="dropdown-item" href="#" i18n>Action 3</a>
              <a class="dropdown-item" href="#" i18n>Action 4</a>
          </div>
        </div>
      </div>
    </nav>
  </div>
  <app-fil-ariane-v2 *ngIf="idHappening"></app-fil-ariane-v2>
  <div *ngIf="listOfEventAgenda" class="maincontainer pb-5">
      <div *ngFor="let list of listOfEventAgenda; let index = index; let isFirst = first;">
        <div class="col row">
          <h2 *ngIf="isFirst" class="title pl-0">{{list.startDate | date:'fullDate'}}</h2>
          <h2 *ngIf="!isFirst && listOfEventAgenda[index].startDate.substring(0, 10) != listOfEventAgenda[index - 1].startDate.substring(0, 10)"
          class="title pl-0">{{listOfEventAgenda[index].startDate | date:'fullDate'}}</h2>
        </div>
          <app-event-agenda-item [item]="list"></app-event-agenda-item>
      </div>
  </div>
  <div *ngIf="!listOfEventAgenda" class="maincontainer pb-5 row">
    <h2 class="title pl-0" i18n>No agenda available</h2>
  </div>
</div>