import { Component, OnInit, Input } from '@angular/core';
import { ParamMap, ActivatedRoute } from '@angular/router';
import { ParticipantService } from '../../../../core/http/participant.service'
@Component({
  selector: 'app-company-attendee-card',
  templateUrl: './company-attendee-card.component.html',
  styleUrls: ['./company-attendee-card.component.scss']
})
export class CompanyAttendeeCardComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private particiapantService: ParticipantService
  ) { }

  idCompany: any;
  listOfParticipantCompany: any;

  async ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.idCompany = params.get('id');
    });
    this.listOfParticipantCompany = await this.particiapantService.getParticipantCompany(this.idCompany).toPromise();
  }
}
