<div class="block__size--responsive">
    <div class="card mb-4">
      <div class="card-body p-3">
        <a class="nowayRegular" style="color: #244746; font-size: 18px; font-weight: bold;" i18n>Agenda Event</a>
        <div *ngFor="let agenda of listOfEventAgenda">
          <a *ngIf="agenda.idHappening != idHappening" style="color: #244746;" href="/admin/agenda/event-agenda/{{agenda.idHappening}}/details">
            <p class="m-0">{{agenda.function}}</p>
          </a>
        </div>
      </div>
    </div>
  </div>