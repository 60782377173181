<div class="container-fluid font-color">
  <div class="maincontainer">
    <div class="row mt-5 mb-3">
      <div class="col-12 justify-content-center d-flex mb-4">
        <h3>
          <u i18n>
            Cards dahsboards
          </u>
        </h3>
      </div>
      <div class="col-12  d-flex mb-4">
        <h4>
          <u i18n>
            Card participants
          </u>
        </h4>
      </div>
      <div class="col-5">
        <app-card-participants [data]="cardData"></app-card-participants>
      </div>
      <div class="col-7 p-0" style="border: 1px solid">
        <div class="col-12 border-bottom p-0 m-0">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class="nav-link htmlEditor active" aria-current="page" (click)="displayHTMLEditor()">HTML</a>
            </li>
            <li class="nav-item">
              <a class="nav-link tsEditor" (click)="displayTSEditor()">TS</a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link scssEditor" (click)="displaySCSSEditor()">SCSS</a>
            </li> -->
          </ul>
          <!-- <ace-editor class="editor" [(text)]="text" id="editorHTML" *ngIf='displayHTML'>
          </ace-editor> -->
          <!-- <ace-editor class="editor" [(text)]="testTS" id="editorTS" *ngIf='displayTS'>
          </ace-editor> -->
          <!-- <ace-editor class="editor" [(text)]="testSCSS" id="editorSCSS" *ngIf='displaySCSS'>
          </ace-editor> -->
        </div>
      </div>
    </div>
  </div>
</div>