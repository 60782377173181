import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { ParticipantService } from '../../../core/http/participant.service';
import { EventService } from '../../../core/http/event.service';

@Component({
  selector: 'app-fil-ariane-list-users',
  templateUrl: './fil-ariane-list-users.component.html',
  styleUrls: ['./fil-ariane-list-users.component.scss']
})
export class FilArianeListUsersComponent implements OnInit {

  @Output() filterStatut: EventEmitter<any> = new EventEmitter();
  @Output() filterStatutFast: EventEmitter<any> = new EventEmitter();
  @Output() exportExcel: EventEmitter<any> = new EventEmitter();

  mobileRes: boolean;
  statut = false;
  statutFastEdit = false;
  public href: string;
  typeStatus: string = '';
  companyCount: number = 1;
  participantCount: number = 0;
  idEvent: number = 0;
  url: any = [];
  urlList: any;
  listOfParticipants: any;
  listOfEvent: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ParticipantService: ParticipantService,
    private EventService: EventService
  ) { }

  async ngOnInit() {
    this.href = this.router.url;
    this.urlList = this.href.split('/');
    for (let i = 0; i < this.urlList.length; i++) {
      if (i == 0) { this.url[i] = "" }
      else { this.url[i] = this.url[i - 1] + "/" + this.urlList[i] }
    }
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.typeStatus = params.get('status');
      if (!this.typeStatus) { this.typeStatus = "all" }
      if (params.get('id')) {
        this.idEvent = Number(params.get('id'));
        this.EventService.getEventById(this.idEvent).subscribe( res => {
          this.listOfEvent = res;
          for (let i = 0; this.urlList.length > i; i++) {
            if (this.urlList[i] == this.idEvent) {
              this.urlList[i] = this.listOfEvent.eventName;
            }
          }
        })
      }
    });
    if (this.idEvent == 0) {
      if (this.typeStatus == "active") { this.typeStatus = "confirmed"; }
      if (this.typeStatus == "occasional") { this.typeStatus = "invited"; }
      if (this.typeStatus == "pending") { this.typeStatus = "shortlist"; }
      if (this.typeStatus == "old") { this.typeStatus = "cancelled"; }
      this.listOfParticipants = await this.ParticipantService.getParticipantByStatusWithPage(this.typeStatus, 0, 0, 0, 0, 0, -1, -1, "!").toPromise();
    } else {
      this.listOfParticipants = await this.EventService.getEventRegistrationStatus(this.typeStatus, this.idEvent).toPromise();
    }
    if (this.listOfParticipants) {
      this.listOfParticipants.sort((a: any, b: any) => {
        if (a.idCompany < b.idCompany) return -1;
        else if (a.idCompany > b.idCompany) return 1;
        else return 0;
      });
      this.participantCount = this.listOfParticipants.length;
    } else this.participantCount = 0;
    if (this.listOfParticipants && this.listOfParticipants.length > 1) { 
      this.participantCount = this.listOfParticipants.length;
      for (let i = 1; this.listOfParticipants[i]; i++) {
        if (this.listOfParticipants[i].idCompany != this.listOfParticipants[i - 1].idCompany) {
          this.companyCount += 1;
        }
      }
    } else if (this.listOfParticipants && this.listOfParticipants.length == 1) { this.companyCount = 1; }
    else { this.companyCount = 0; }
  }
  displayFilters() {
    this.statut = !this.statut;
    this.filterStatut.emit(this.statut);
  }
  displayFastEdit() {
    this.statutFastEdit = !this.statutFastEdit;
    this.filterStatutFast.emit(this.statutFastEdit);
  }
  exportFile() {
    this.exportExcel.emit();
  }
}
