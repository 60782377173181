<div class="container" style="height:1400px;border: 2px solid green; max-width: 1270px; ">

  <div class="row" style="height: 5%; border:1px solid black;">
  </div>

  <div class="row" style=" height:95%;">

    <div class="col-md-9" style="height:100%;">

      <div class="row" style="height: 25%; border: 1px solid black;">
      </div>

      <div class="row" style="height: 5%; border: 1px solid black;">
      </div>

      <div class="row" style="height: 70%;">
        <div class="col-md-6 col-12" style="border:1px solid blue;height:95%">
        </div>
        <div class="col-md-6 col-12" style="border:1px solid blue;height:95%">
        </div>
      </div>

    </div>
    <div class="col-md-3 col-12" style="height:100%; border:1px solid blue">
    </div>

  </div>
</div>
