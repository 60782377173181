import { Component, OnInit } from '@angular/core';
import { GetDataService } from "src/app/core/http/get-data.service";
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EventService } from '../../core/http/event.service';
import { DateService } from '../../core/http/date.service';
import * as XLSX from 'xlsx';
import { DownloadService } from 'src/app/core/http/download.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-event-agenda',
  templateUrl: './event-agenda.component.html',
  styleUrls: ['./event-agenda.component.scss']
})
export class EventAgendaComponent implements OnInit {
 
  listOfEvent = this.getDataService.eventlist;
  userStop$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private getDataService: GetDataService,
    private eventService: EventService,
    private datePipe: DatePipe,
    private dateService: DateService,
    private downloadService: DownloadService,
    private toastr: ToastrService
  ) { }

  url: any;
  public href: string;
  listOfAllEvents: any;
  listOfEventAgenda: any;
  valueEvent: number = 0;
  textEvent: any;
  listAgendaDate: any;
  elementEvents: any;
  idHappening: any;
  dataDownload: any;
  request: any;

  async ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.idHappening = params['id'];
        this.valueEvent = this.idHappening;
      }
    });
    this.href = this.router.url;
    this.url = this.href.split('/');
    for (let i = 0; i < this.url.length; i++) {
      this.url[i] = this.url[i].substr(0, 1).toUpperCase() + this.url[i].substr(1);
    }
    await this.initApi();
  }
  async initApi() {
    this.dataDownload = this.downloadService.downloadData();
    this.listOfAllEvents = await this.eventService.getAllEvents().toPromise();
    if (this.valueEvent == 0) {
      this.textEvent = this.datePipe.transform(this.listOfAllEvents[this.valueEvent].date, 'yyyy-MM-dd') + " - " + this.listOfAllEvents[this.valueEvent].label;
      this.valueEvent = this.listOfAllEvents[this.valueEvent].id;
    }
    this.listOfEventAgenda = await this.eventService.getEventAgenda(this.valueEvent).toPromise();
    if (this.listOfEventAgenda) {
      for (let i = 0; this.listOfEventAgenda.length > i; i ++) {
        this.listOfEventAgenda[i].date = this.dateService.formatToNgbDateStruct(this.listOfEventAgenda[i].date);
      }
    }
  }
  afterChange(event: any) {
    this.valueEvent = event.id;
    this.textEvent = event.label;
    this.initApi();
  }

  downloadList() {
    this.dataDownload["value"].Name = "agenda";
    this.dataDownload["value"].TypeFile = ".csv";
    this.dataDownload["value"].IdEvent = this.valueEvent;
    this.downloadService.exportFile(this.dataDownload["value"]).subscribe(res => {
      let blob: Blob = res.body as Blob;
      let name = res.headers.get('content-disposition');
      let a = document.createElement('a');
      a.download = name.split(';')[1].split('=')[1].replace(/\"/g, '');
      a.href = window.URL.createObjectURL(blob);
      a.click();
      console.log("Success", res);
    }, error => {
      console.log("Error", error);
    });
  }
}
