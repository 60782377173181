import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { EmailingService } from '../../../../core/http/emailing.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-followup-page',
  templateUrl: './followup-page.component.html',
  styleUrls: ['./followup-page.component.scss']
})
export class FollowupPageComponent implements OnInit {
  config: any;
  configHyperlinks: any;
  nbItems = 10;
  nbItemsHyperlinks = 10;
  idFollowup;
  followup =   {
    mailingId : '472d9683a601fc98366b92c442c5069e',
    fromAdress: 'invite@it-distri.com',
    subject: 'Register Now for DISTREE Online',
    idlist : [123, 456, 789, 987],
    imageTracking: true,
    author: 'Admin',
    sentDate: '03/03/21 10:30',
    openingDate: '03/03/21 10:34',
    lastOpeningDate: '03/08/21 19:28',
    nbMessages: '4',
    nbMessagesOpen: '3',
  };

  listOfContacts;
  listOfHyperlinks = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private emailingService : EmailingService,
    ) {

      this.config = {
        id: "pagination",
        currentPage: 1,
        itemsPerPage: this.nbItems
      };
  
      this.configHyperlinks = {
        id: "paginationHL",
        currentPage: 1,
        itemsPerPage: this.nbItemsHyperlinks
      };
  
      this.route.queryParamMap.pipe(map(params => params.get('page')))
      .subscribe(page => this.config.currentPage = page);

      this.route.queryParamMap.pipe(map(params => params.get('pageHL')))
      .subscribe(page => this.configHyperlinks.currentPage = page);
  
     }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.idFollowup = params.get('id');
    });

    this.listOfContacts = this.emailingService.listOfFollowupContacts;
    this.listOfHyperlinks = this.emailingService.listOfFollowupContacts;
  }

  onItemsChange(event) {
    this.config.itemsPerPage = event;
  }
  
  onItemsChangeHyperlinks(event) {
    this.configHyperlinks.itemsPerPage = event;
  }


  pageChange(newPage: number) {
    this.router.navigate(['admin/communication/emailing/followup/' + this.idFollowup], { queryParams: { page: newPage } });
  }
  
  pageChangeHL(newPage: number) {
    console.log(this.route.queryParamMap.pipe(map(params => params.get('page'))))
    this.router.navigate(['admin/communication/emailing/followup/' + this.idFollowup], { queryParams: { pageHL: newPage } });
  }


}
