<div class="card">
    <div class="card-body">
  
        <div class="dropleft dropright-action">
            <svg  width="24px" height="24px" id="dropdownMenuButton4"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <use xlink:href="#action" />
            </svg>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton4">
            <span class="dropdown-menu-arrow"></span>
            <a class="dropdown-item" href="http://ecv5-test.eventcatalyst.io/assets/extract_test.xls" i18n>Extract</a>
            <a class="dropdown-item" [routerLink]="['/admin/contacts/add']" i18n>Add {{data.title}}</a>
            <a class="dropdown-item" [routerLink]="urlAll" i18n>View list</a>
            </div>
        </div>
    
        <div style="display: flex">
            <h4 class="card-title mb-0" i18n>{{data.title}} registration workflow</h4>
        </div>

        <div class="bottomRows" >
            <div style="width: 100%;height: 100%;">
                <div class="flex_row">
                    <div class="main_container" style="border-radius: 99em;border-color: #EFEEE2;" [routerLink]="urlAll">
                        
                        <span class="status_bold" i18n>ALL</span>
                        <!-- <div [ngStyle]="{'width' : remainingParticipantsPerCent}" class="first_container" style=" background: repeating-linear-gradient(60deg, white, white 10px, rgba(239, 238, 226, 0.3) 10px, rgba(239, 238, 226, 0.3) 22px)"
                            [ngbPopover]="altTotal" popoverClass="popover-custom-light" triggers="mouseenter:mouseleave">
                        </div> -->
                        <div [ngStyle]="{'width' : remainingParticipantsPerCent}" class="second_container" style="background-color: #EFEEE2;"
                            [ngbPopover]="altRemain" popoverClass="popover-custom-light" triggers="mouseenter:mouseleave">
                        </div>    

                    </div>
                </div>
                <!-- <div class="flex_row">
                    <div [ngStyle]="{'width': totalShortlistPerCent}" class="main_container" style="border-radius: 99em;border-color: #B39DDB;" [routerLink]="urlShortlists">
                        
                        <span class="status_bold">SHORTLIST</span>
                        <div [ngStyle]="{'width' : wasShortlistPerCent}" class="first_container" style="background: repeating-linear-gradient(60deg, white, white 10px, rgba(179, 157, 219, 0.3) 10px, rgba(179, 157, 219, 0.3) 22px)"
                            [ngbPopover]="altTotalShortlist" popoverClass="popover-custom-light" triggers="mouseenter:mouseleave">
                        </div>
                        <div [ngStyle]="{'width' : shortlistPerCent}" class="second_container" style="background-color: #B39DDB;"
                            [ngbPopover]="altShortlist" popoverClass="popover-custom-light" triggers="mouseenter:mouseleave">
                        </div> 

                    </div>
                </div> -->
                <div class="flex_row">
                    <div [ngStyle]="{'width': totalThirdRow}" class="main_container" style="border:none;">
                        
                        <div [ngStyle]="{'width': totalInvitedPerCent}" *ngIf="data.nb_invited > 0" class="main_container" style="border-color: #80CBC4;border-bottom-left-radius: 99em;border-top-left-radius: 99em;"
                            [routerLink]="urlinvited">
                            <span class="status_bold" i18n>INVITED</span>
                            <div [ngStyle]="{'width' : wasInvitedPerCent}" class="first_container" style="background: repeating-linear-gradient(60deg, white, white 10px, rgba(128, 203, 196, 0.3) 10px, rgba(128, 203, 196, 0.3) 22px)"
                                [ngbPopover]="altTotalInvited" popoverClass="popover-custom-light" triggers="mouseenter:mouseleave">
                            </div>  
                            <div [ngStyle]="{'width' : invitedPerCent}" class="second_container" style="background-color: #80CBC4;border-top-right-radius: 99em;border-bottom-right-radius: 99em;"
                                [ngbPopover]="altInvited" popoverClass="popover-custom-light" triggers="mouseenter:mouseleave">
                            </div>  
                        </div>
                        <div [ngStyle]="{'width' : waitingPerCent}" *ngIf="data.nb_waiting > 0" style="outline:none; cursor:pointer;height: 25px;border: 1px solid #A2CBEC; background-color: #A2CBEC; text-align: center; font-weight: bold; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                             [ngbPopover]="altWaiting" popoverClass="popover-custom-light" triggers="mouseenter:mouseleave" [routerLink]="urlWaiting" i18n>
                                WAITING LIST
                        </div>
                        <div [ngStyle]="{'width' : rejectedPerCent}" *ngIf="data.nb_rejected > 0" style="outline:none; border-top-right-radius: 99em;border-bottom-right-radius: 99em;cursor:pointer;height: 25px;border: 1px solid #FDC95B; background-color: #FDC95B; text-align: center; font-weight: bold; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                             [ngbPopover]="altRejected" popoverClass="popover-custom-light" triggers="mouseenter:mouseleave" [routerLink]="urlRejected" i18n>
                                REJECTED
                        </div>

                    </div>
                </div>
                <div class="flex_row">
                    <div [ngStyle]="{'width' : totalFourthRow}" class="main_container" style="border:none;justify-content: start;">

                        <div [ngStyle]="{'width' : totalConfirmedPerCent}" class="main_container" style="border-color: #C5E1A5;border-bottom-left-radius: 99em;border-top-left-radius: 99em;"
                            [routerLink]="urlConfirmed">
                            <span class="status_bold" i18n>CONFIRMED</span>
                            <div [ngStyle]="{'width' : wasConfirmedPerCent}" class="first_container" style="background: repeating-linear-gradient(60deg, white, white 10px, rgba(197, 225, 165, 0.3) 10px, rgba(197, 225, 165, 0.3) 22px)"
                                [ngbPopover]="altTotalConfirmed" popoverClass="popover-custom-light" triggers="mouseenter:mouseleave">
                            </div>  
                            <div [ngStyle]="{'width' : confirmedPerCent}" class="second_container" style="background-color: #C5E1A5;border-top-right-radius: 99em;border-bottom-right-radius: 99em;"
                                [ngbPopover]="altConfirmed" popoverClass="popover-custom-light" triggers="mouseenter:mouseleave">
                            </div>  
                        </div> 
                        <div  [ngStyle]="{'width' : declinedPerCent}" *ngIf="data.nb_declined > 0"  style="outline:none; border-top-right-radius: 99em;border-bottom-right-radius: 99em;cursor:pointer; height: 25px; font-weight: bold; border: 1px solid #F7A145; background-color: #F7A145; text-align: center;"
                            [ngbPopover]="altDeclined" popoverClass="popover-custom-light" triggers="mouseenter:mouseleave" [routerLink]="urlDeclined" i18n>
                            DECLINED
                        </div>

                    </div>
                </div>
                <div class="flex_row">
                    <!-- <div *ngIf="data.nb_attended > 0" style="width: 0.1%;height: 25px;border:2px solid #5eac5e; text-align: center;" ngbPopover="Attended - 0" popoverClass="popover-custom-light"
                    triggers="mouseenter:mouseleave">
                    </div>
                    <div *ngIf="data.nb_replaced > 0" style="width: 0.1%;height: 25px;border:2px solid #eaee71; text-align: center;" ngbPopover="Replaced - 0" popoverClass="popover-custom-light"
                    triggers="mouseenter:mouseleave">
                    </div>
                    <div *ngIf="data.nb_noShow > 0" style="width: 0.1%;height: 25px;border:2px solid #a42309; text-align: center;" ngbPopover="No-show - 0" popoverClass="popover-custom-light"
                    triggers="mouseenter:mouseleave">
                    </div> -->
                    <div *ngIf="data.nb_cancelled7 > 0" style="width: 0.1%;height: 25px;border:2px solid #E2013A; text-align: center;" ngbPopover="Cancelled d-7 - 0" popoverClass="popover-custom-light"
                    triggers="mouseenter:mouseleave">
                    </div>
                    <div [ngStyle]="{'width' : cancelledPerCent}" style="outline:none; border-radius: 99em;cursor:pointer; height: 25px;border: 1px solid #EA6044;background-color: #EA6044; text-align: center;" 
                    [ngbPopover]="cancelledAlt" popoverClass="popover-custom-light" triggers="mouseenter:mouseleave" [routerLink]="urlCancelled">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  