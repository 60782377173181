import { Component, OnInit , Input } from '@angular/core';

@Component({
  selector: 'app-participant-edit-card-details',
  templateUrl: './participant-edit-card-details.component.html',
  styleUrls: ['./participant-edit-card-details.component.scss']
})
export class ParticipantEditCardDetailsComponent implements OnInit {
@Input() data;
companyID;

  constructor() { }
  ngOnInit() {
    this.companyID = this.data;
  }

}
