<!-- Card email -->
<div class="card">
    <div class="card-body">

        <div class="dropright dropright-action">
            <svg class="opacity-icon " width="24px" height="24px" id="dropdownMenuButton4"
                 data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <use xlink:href="#action" />
            </svg>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton4">
                <span class="dropdown-menu-arrow"></span>
                <a class="dropdown-item" href="data.extractFollowup" i18n>Extract Followup</a>
                <a class="dropdown-item" [routerLink]="data.routerLink" i18n>Resend email to unopened</a>
                <a class="dropdown-item" [routerLink]="data.routerLink" i18n>View followup list</a>
                <a class="dropdown-item" [routerLink]="data.routerLink" i18n>View template email sent</a>
            </div>
        </div>

        <h4 class="card-title" i18n>{{ data.cardTitle }}</h4>

        <h3 class="card-subtitle" i18n>{{ data.oneToOne }}</h3>

        <span class="timer" style="margin-left: 5px;">{{ data.date }}</span>

        <div class="bottomRows">
        
                <div class="row middleRow">
                    <div class="col d-flex align-items-baseline">
                        <span class="h2Top">{{ data.nbrOpened }}</span>
                        <span class="ml-2 valueLabel" i18n>{{ data.txtOpened }}</span>
                        <div class="col-3 taux">{{ tauxProgress(data.taux) }}<span>&nbsp;%</span></div>
                    </div>
                </div>
        
                <div class="progressBar">
                    <ngb-progressbar ngbPopover="{{ tauxProgress(data.taux) }} %" popoverClass="popover-light" triggers="mouseenter:mouseleave" class="mb-2 mt-3" [showValue]="false"
                        showValue="true" [value]="data.taux*100">
                    </ngb-progressbar>
                </div>
        
                <div class="row lastRow">
                    <div class="col">
                        <span class="h2">{{ data.nbrSent }}</span>
                        <span class="ml-2 valueLabel" i18n>{{ data.txtSent }}</span>
                    </div>
                    <div class="col-1" style="margin-right: 10px">
                        <span class="mr-2 pt-2" ngbPopover="TO COMPLETE" popoverClass="popover-custom" triggers="mouseenter:mouseleave">
                            <svg class="opacity-icon " width="24px" height="24px">
                            <use xlink:href="#ic_Settings"/>
                            </svg>
                        </span>
                    </div>
                    <div class="col-1" style="margin-right: 10px">
                        <span class="mr-2 pt-2" ngbPopover="TO COMPLETE" popoverClass="popover-custom" triggers="mouseenter:mouseleave">
                            <svg class="opacity-icon " width="24px" height="24px">
                            <use xlink:href="#edit"/>
                            </svg>
                        </span>
                    </div>
                    <div class="col-1" style="margin-right:7px">
                        <span class="mr-2 pt-2" ngbPopover="TO COMPLETE" popoverClass="popover-custom" triggers="mouseenter:mouseleave">
                            <svg class="opacity-icon " width="24px" height="24px">
                            <use xlink:href="#arrow-right"/>
                            </svg>
                        </span>
                    </div>
                </div>
        
            </div>

    </div>
</div>
<!-- End card -->
