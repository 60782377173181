<div class="display--nav-none ">
  <div style="display: flex;" >
    <nav class="nav-responsive-top" style="width: 100%;margin-bottom: 1rem!important;">
      <div class="nav nav-tabs nav-responsive" id="nav-tab" role="tablist">
        <a class="nav-item nav-link active nowayRegular" id="nav-attendee" data-toggle="tab"
          href="#company" role="tab" aria-controls="nav-company" aria-selected="true" i18n>Company</a>
      </div>
    </nav>
  </div>
  <div class="tab-content" id="nav-tabContent">
    <div class="tab-pane fade show active mt-3" id="company" role="tabpanel" aria-labelledby="nav-company">
      <div class="row">
        <form style="width:100%" (ngSubmit)="companyInformation.valid && onSubmit()" [formGroup]='companyInformation'>
          <div class="row maincontainer">
            <div class="col-md-6 col-12">
              <app-form-input>
                <label for="companyName" class="label col-sm-5 col-form-label" i18n>Company name&nbsp;*</label>
                <input type="text" class="input form-control" id="companyName"
                  [ngClass]="companyInformation.get('companyName').errors ? 'is-invalid' : 'is-valid'" formControlName="companyName">
              </app-form-input>
            </div>
          </div>
          <div style="padding-right: 15px ;padding-left:15px">
            <hr class="mt-5 mb-5">
            <h6 class="nowayRegular mb-5 t-participant" i18n>COMPANY ADDRESS</h6>
            <div class="row">
              <div class="mt-3" style="width:100%"></div>
              <div class="col-md-6 col-12 ">
                <app-form-select>
                  <label for="countryCompany" class="label col-sm-5 col-form-label" i18n>Country&nbsp;*</label>
                  <select class="select form-control" id="idCountry"
                    formControlName="idCountry"
                    [ngClass]="companyInformation.get('idCountry').errors ? 'is-invalid' : 'is-valid'">
                    <option value='' i18n>Please select</option>
                    <option *ngFor="let country of listOfCountry" [value]="country.id">
                      {{country.label}}
                    </option>
                  </select>
                </app-form-select>
                <app-form-input>
                  <label for="zipCodeCompany" class="label col-sm-5 col-form-label" i18n>Zip code *</label>
                  <input type="text" class="input form-control" id="zipCodeCompany"
                    formControlName="zipCode"
                    [ngClass]="companyInformation.get('zipCode').errors ? 'is-invalid' : 'is-valid'">
                </app-form-input>
              </div>
            </div>
          </div>
          <div class="row details-responsive mt-4 mb-5">
            <div class="col-sm-12 mt-4 " style="text-align: center;">
              <span class="badge-none mt-3">
                <button class="btn btn-classic button--color btn-size btn-responsive"
                style="margin-right: 13px; color:#FFF" (click)="clickCancelCompany()" i18n>Cancel</button>
                <button class="btn btn-success button--color btn-size btn-responsive" type="submit"
                style="margin-right: 10px;color:#FFF" [disabled]="!companyInformation.valid" data-toggle="#existingModal" i18n>Save</button>
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="p-4 already_exist modal" id="existingModal" tabindex="-1" aria-labelledby="existingModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="">
    <div class="">
      <div class="">
        <h4 class="mb-4 text-align-center" id="existingModalLabel" i18n>The company already exists</h4>
      </div>
      <hr class="mt-2 mb-3 separator" style="background-color: #84C000;">
      <div class="dp-flex-justy-beetw">
        <div class="p-3">
          <h5 class="" i18n>In creation</h5>
          <ul class="p-0 m-0" style="list-style: none;">
            <li>Company : {{companyInformation["value"].companyName}}</li>
            <li>Country : {{countryName}}</li>
            <li>ZipCode : {{companyInformation["value"].zipCode}}</li>
          </ul>
        </div>
        <div class="ml-4 mr-4 arrow_middle">
          <div class="mt-2 pt-2 pr-2 pl-2 border_arrow_exist">
            <a href="/admin/company/{{this.idCompanyExist}}/edit" class="link_exist">
              <span>Edit existing</span>
              <svg class="mb-2" id="svgAR" height="24px" width="24px">
                <use class="arrow_exist" xlink:href="#arrow-right"/>
              </svg>
            </a>
          </div>
          <div class="mt-2 pt-2 pr-2 pl-2 border_arrow_edit">
            <a href="" data-dismiss="modal" class="link_edit">
              <svg class="mb-2" id="svgAL" height="24px" width="24px">
                <use class="arrow_edit" xlink:href="#arrow-left"/>
              </svg>
              <span>Edit creation</span>
            </a>
          </div>
        </div>
        <div *ngIf="listOfCompanyExisting" class="p-3" style="border: 1px solid;">
          <h5 class="" i18n>Already existing</h5>
          <ul class="p-0 m-0" style="list-style: none;">
            <li i18n>Company : {{listOfCompanyExisting.company}}</li>
            <li i18n>Country : {{listOfCompanyExisting.country}}</li>
            <li i18n>ZipCode : {{listOfCompanyExisting.zipCode}}</li>
            <li i18n><b>Id : {{listOfCompanyExisting?.idCompany}}</b></li>
            <!-- <li i18n>Address : {{listOfCompanyExisting.address}}</li>
            <li i18n>City : {{listOfCompanyExisting.city}}</li>
            <li i18n>Telephone : {{listOfCompanyExisting.telephone}}</li>
            <li i18n>State : {{listOfCompanyExisting.state}}</li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>