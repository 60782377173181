import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../core/authentication/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public error:boolean = false;
  public loading:boolean = false;

  constructor(private fb: FormBuilder,
              private router: Router,
              private toastr: ToastrService,
              private authenticationService: AuthenticationService) {
  }

  loginForm = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });

  ngOnInit() {
  }

  get form() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.loading = true;
    this.authenticationService.login(this.form.username.value, this.form.password.value)
      .subscribe(
        () => {
          this.loading = false;
          this.loginForm.reset();
          this.toastr.clear();
          this.toastr.success('Success', 'Connection success');
          this.router.navigateByUrl('/admin/dashboard');
        },
        () => {
          this.loading = false;
          this.toastr.clear();
          this.toastr.error('Error', 'Connection refused');
          this.error = true;
        });
  }

}
