import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {}
  };
  public barChartLabelsUser: Label[] = ['2013', '2014', '2015', '2016', '2017', '2018', '2019'];
  public barChartLabelsSession: Label[] = ['2013', '2014', '2015', '2016', '2017', '2018', '2019'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public barChartPlugins = [];

  public barChartDataUser: ChartDataSets[] = [
    {
      data: [1965, 1859, 1880, 2341, 1896, 2105, 2400],
      label: 'User',
      backgroundColor: 'rgb(65,105,225,0.6)',
      borderColor: 'rgb(65,105,225)',
      hoverBackgroundColor: 'rgb(65,105,225)',
      hoverBorderColor: 'rgb(65,105,225,0.6)',
    },
  ];

  public barChartDataSession: ChartDataSets[] = [
    {
      data: [3570, 3769, 4340, 3421, 4436, 3345, 3420],
      label: 'Session',
      backgroundColor: 'rgba(214,50,54,0.6)',
      borderColor: 'rgb(255,60,66)',
      hoverBackgroundColor: 'rgb(214,50,55)',
      hoverBorderColor: 'rgba(254,59,64,0.6)',
    },
  ];

  public lineChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 65, 50, 67, 75, 87, 64, 56], label: 'bandwidth' },
  ];
  public lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
    'October', 'November', 'December'];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], yAxes: [{}] },
  };

  public lineChartColors: Color[] = [
    {
      backgroundColor: 'rgba(75,177,49,0.2)',
      borderColor: 'rgb(98,177,96)',
      pointBackgroundColor: 'rgb(106,218,103)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#85da88',
      pointHoverBorderColor: 'rgba(61,177,66,0.8)'
    },
  ];
  public lineChartLegend = false;
  public lineChartType = 'line';
  public lineChartPlugins = [];

  constructor() {
  }

  ngOnInit() {
  }

}
