import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EventService } from '../../../../core/http/event.service';
import { RefService } from '../../../../core/http/ref.service';
import { CompanyService } from '../../../../core/http/company.service';
import { FormDataEventAgendaService } from '../../../../core/http/form-data-event-agenda.service';
import { bounceOutRightOnLeaveAnimation, bounceAnimation, hueRotateAnimation, fadeInDownOnEnterAnimation, fadeOutUpOnLeaveAnimation } from 'angular-animations';
import * as _moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-agenda-add-form',
  templateUrl: './agenda-add-form.component.html',

  styleUrls: ['./agenda-add-form.component.scss'],
  animations: [
    bounceOutRightOnLeaveAnimation({ delay: 100 }),
    bounceAnimation(),
    hueRotateAnimation(),
    fadeInDownOnEnterAnimation({ duration: 10, translate: '5px', delay: 0 }),
    fadeOutUpOnLeaveAnimation({ duration: 10, translate: '5px', delay: 0 }),
  ]
})

export class AgendaAddFormComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formDataEventAgendaService: FormDataEventAgendaService,
    private companyService: CompanyService,
    private toastr: ToastrService,
    private eventService: EventService,
    private refService: RefService
  ) {}

  addEventAgendaFormGroup: any;
  idEvent: any;
  listOfEventType: any;
  listOfVendor: any;
  listOfParent: any;
  listOfHappeningType: any;
  listOfTimezone: any;
  listOfTypeParticipant: any;
  listOfLocation: any;
  participantTypeMandatory: any = [];
  participantTypeOptional: any = [];
  listOfImages: any = [];
  uploadFile: File | null;
  uploadFileMenu: File | null;
  uploadFileLabel: string | undefined = 'Choose an image to upload';

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.idEvent = params.get('id');
    });
    this.initRef();
    this.listOfImages = [null, null, null];
    this.addEventAgendaFormGroup = this.formDataEventAgendaService.dataEventsAgenda(); 
  }

  initRef() {
    this.refService.getHappeningType().subscribe( res => {
      this.listOfHappeningType = res;
    })
    this.companyService.getAllCompany().subscribe( res => {
      this.listOfVendor = res;
    })
    this.eventService.getEventAgenda(this.idEvent).subscribe( res => {
      this.listOfParent = res;
    })
    this.eventService.getEventType().subscribe( response => {
      this.listOfEventType = response;
    });
    this.refService.getTimezone().subscribe( response => {
      this.listOfTimezone = response;
    });
    this.refService.getTypeParticipant().subscribe( response => {
      this.listOfTypeParticipant = response;
      for (let i = 0; this.listOfTypeParticipant.length > i; i++) {
        if (this.listOfTypeParticipant[i].short == "dis") this.listOfTypeParticipant.splice(i, 1);
        if (this.listOfTypeParticipant[i].short == "ret") this.listOfTypeParticipant.splice(i, 1);
      }
      for (let i = 0; this.listOfTypeParticipant.length > i; i++) {
        this.participantTypeMandatory.push({"Libelle": this.listOfTypeParticipant[i].short, "Checked":  false});
        this.participantTypeOptional.push({"Libelle": this.listOfTypeParticipant[i].short, "Checked":  false});
      }
    });
  }
  onSubmit() {
    this.addEventAgendaFormGroup["value"].idEvent = this.idEvent;
    this.addEventAgendaFormGroup["value"].typeParticipantMandatory = this.participantTypeMandatory;
    this.addEventAgendaFormGroup["value"].typeParticipantOptional = this.participantTypeOptional;
    this.addEventAgendaFormGroup["value"].date = _moment(this.addEventAgendaFormGroup["value"].date).format("DD/MM/YYYY");
    this.eventService.addEventAgenda(this.addEventAgendaFormGroup["value"]).subscribe(res => {
      console.log('success', res);
      this.toastr.clear();
      this.toastr.success('Success', 'EventAgenda create');
      this.router.navigateByUrl(`/admin/agenda/event-agenda/${this.idEvent}`);
    }, err => {
      console.log("L'error est : " + err);
      this.toastr.clear();
      this.toastr.error('Error', 'Event not create');
    });
  }
  onChangeMandatory(short: string, isChecked: boolean) {
    for (let i = 0; this.participantTypeMandatory.length > i; i++) {
      if (this.participantTypeMandatory[i].Libelle == short) {
        !isChecked ? this.participantTypeMandatory[i].Checked = false : this.participantTypeMandatory[i].Checked = true
      }
    }
  }
  onChangeOptional(short: string, isChecked: boolean) {
    for (let i = 0; this.participantTypeOptional.length > i; i++) {
      if (this.participantTypeOptional[i].Libelle == short) {
        !isChecked ? this.participantTypeOptional[i].Checked = false : this.participantTypeOptional[i].Checked = true
      }
    }
  }
  selectAll() {
    document.querySelectorAll<HTMLElement>('.click_input').forEach(ele => ele.click());
  }
  cancelEdit() {
    this.ngOnInit();
  }
}