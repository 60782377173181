import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EventService } from '../../../../core/http/event.service';
import { RefService } from '../../../../core/http/ref.service';
import { CompanyService } from '../../../../core/http/company.service';
import { FormDataEventAgendaService } from '../../../../core/http/form-data-event-agenda.service';
import { bounceOutRightOnLeaveAnimation, bounceAnimation, hueRotateAnimation, fadeInDownOnEnterAnimation, fadeOutUpOnLeaveAnimation } from 'angular-animations';
import * as _moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-agenda-edit-form',
  templateUrl: './agenda-edit-form.component.html',

  styleUrls: ['./agenda-edit-form.component.scss'],
  animations: [
    bounceOutRightOnLeaveAnimation({ delay: 100 }),
    bounceAnimation(),
    hueRotateAnimation(),
    fadeInDownOnEnterAnimation({ duration: 10, translate: '5px', delay: 0 }),
    fadeOutUpOnLeaveAnimation({ duration: 10, translate: '5px', delay: 0 }),
  ]
})

export class AgendaEditFormComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formDataEventAgendaService: FormDataEventAgendaService,
    private companyService: CompanyService,
    private toastr: ToastrService,
    private eventService: EventService,
    private refService: RefService
  ) {}

  editEventAgendaFormGroup: any;
  idHappening: any;
  listOfEventType: any;
  listOfVendor: any;
  listOfParent: any;
  listOfHappeningType: any;
  listOfHappening: any;
  listOfHappeningInit: any;
  listOfTimezone: any;
  listOfTypeParticipant: any;
  listOfLocation: any;
  participantTypeMandatoryInit: any = [];
  participantTypeMandatory: any = [];
  participantTypeOptionalInit: any = [];
  participantTypeOptional: any = [];
  listOfImages = [null, null, null];
  uploadFile: File | null;
  uploadFileMenu: File | null;
  uploadFileLabel: string | undefined = 'Choose an image to upload';
  initialAgendaForm: any;
  isAgendaHasChange: boolean = false;
  isOptionalHasChange: boolean = false;
  isMandatoryHasChange: boolean = false;

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.idHappening = params.get('id');
    });
    this.editEventAgendaFormGroup = this.formDataEventAgendaService.dataEventsAgenda();
    this.initRef();
    this.eventService.getAgenda(this.idHappening).subscribe(res => {
      this.listOfHappeningInit = res
      this.participantTypeMandatoryInit = this.listOfHappeningInit.typeParticipantMandatoryObj;
      this.participantTypeOptionalInit = this.listOfHappeningInit.typeParticipantOptionalObj;
    });
    this.eventService.getAgenda(this.idHappening).subscribe(res => {
      this.listOfHappening = res;
      this.eventService.getEventAgenda(this.listOfHappening.idEvent).subscribe( res => {
        this.listOfParent = res;
        for (let i = 0; this.listOfParent.length > i; i++) {
          if (this.listOfParent[i].idHappening == this.idHappening) this.listOfParent.splice(i, 1);
        }
      });
      this.initValueForm();
    });
  }
  initRef() {
    this.refService.getHappeningType().subscribe( res => {
      this.listOfHappeningType = res;
    })
    this.companyService.getAllCompany().subscribe( res => {
      this.listOfVendor = res;
    })
    this.eventService.getEventType().subscribe( response => {
      this.listOfEventType = response;
    });
    this.refService.getTimezone().subscribe( response => {
      this.listOfTimezone = response;
    });
    this.refService.getTypeParticipant().subscribe( response => {
      this.listOfTypeParticipant = response;
      for (let i = 0; this.listOfTypeParticipant.length > i; i++) {
        if (this.listOfTypeParticipant[i].short == "dis") this.listOfTypeParticipant.splice(i, 1);
        if (this.listOfTypeParticipant[i].short == "ret") this.listOfTypeParticipant.splice(i, 1);
      }
    });
  }
  initValueForm() {
    this.participantTypeMandatory = this.listOfHappening.typeParticipantMandatoryObj;
    this.participantTypeOptional = this.listOfHappening.typeParticipantOptionalObj;
    this.editEventAgendaFormGroup.patchValue({
      idEvent: this.listOfHappening.idEvent,
      function: this.listOfHappening.function,
      functionShort: this.listOfHappening.functionShort,
      comment: this.listOfHappening.comment,
      idVendor: this.listOfHappening.idVendor,
      idParent: this.listOfHappening.idParent,
      date: this.listOfHappening.date.substring(0,10),
      startHour: this.listOfHappening.startDate.substring(11,16),
      endHour: this.listOfHappening.endDate.substring(11,16),
      uponRegistration: this.listOfHappening.uponRegistration,
      uponInvitation: this.listOfHappening.uponInvitation,
      print: this.listOfHappening.print,
      live: this.listOfHappening.live,
      description: this.listOfHappening.description,
      idLocation: this.listOfHappening.idLocation,
      idTypeFunction: this.listOfHappening.idTypeFunction,
      typeParticipantMandatory: this.participantTypeMandatory,
      typeParticipantOptional: this.participantTypeOptional,
      capacity: this.listOfHappening.capacity,
      minus: this.listOfHappening.minus,
      transfer: this.listOfHappening.transfer,
      itemUrl: this.listOfHappening.itemUrl,
    })
    this.initialAgendaForm = this.editEventAgendaFormGroup.value;
    this.editEventAgendaFormGroup.valueChanges.subscribe(value => {
      console.log("ici");
      this.isAgendaHasChange = Object.keys(this.initialAgendaForm).some(key =>
        this.editEventAgendaFormGroup.value[key] != this.initialAgendaForm[key])
      }
    );
  }
  onSubmit() {
    this.editEventAgendaFormGroup["value"].typeParticipantMandatory = this.participantTypeMandatory;
    this.editEventAgendaFormGroup["value"].typeParticipantOptional = this.participantTypeOptional;
    this.editEventAgendaFormGroup["value"].date = _moment(this.editEventAgendaFormGroup["value"].date).format("DD/MM/YYYY");
    this.eventService.editAgenda(this.idHappening ,this.editEventAgendaFormGroup["value"]).subscribe(res => {
      console.log('success', res);
      this.toastr.clear();
      this.toastr.success('Success', 'EventAgenda edit');
      this.router.navigateByUrl(`/admin/agenda/event-agenda/${this.editEventAgendaFormGroup["value"].idEvent}`);
      this.router.navigateByUrl(`/admin/agenda/event-agenda/${this.editEventAgendaFormGroup["value"].idEvent}`);
    }, err => {
      console.log("L'error est : " + err);
      this.toastr.clear();
      this.toastr.error('Error', 'EventAgenda not edit');
    });
  }
  onChangeMandatory(short: string, isChecked: boolean) {
    for (let i = 0; this.participantTypeMandatory.length > i; i++) {
      if (this.participantTypeMandatory[i].libelle == short) {
        !isChecked ? this.participantTypeMandatory[i].checked = false : this.participantTypeMandatory[i].checked = true
      }
    }
    for (let i = 0; this.participantTypeMandatory.length > i; i++) {
      if (this.participantTypeMandatory[i].checked != this.participantTypeMandatoryInit[i].checked) {
        this.isMandatoryHasChange = true;
        break;
      } else {
        this.isMandatoryHasChange = false;
      }
    }
  }
  onChangeOptional(short: string, isChecked: boolean) {
    for (let i = 0; this.participantTypeOptional.length > i; i++) {
      if (this.participantTypeOptional[i].libelle == short) {
        !isChecked ? this.participantTypeOptional[i].checked = false : this.participantTypeOptional[i].checked = true
      }
    }
    for (let i = 0; this.participantTypeOptional.length > i; i++) {
      if (this.participantTypeOptional[i].checked != this.participantTypeOptionalInit[i].checked) {
        this.isOptionalHasChange = true;
        break;
      } else {
        this.isOptionalHasChange = false;
      }
    }
  }
  cancelEdit() {
    this.isAgendaHasChange = false;
    this.ngOnInit();
  }
  changeAgendaForm() {
    this.editEventAgendaFormGroup.valueChanges.subscribe(value => {
      console.log("ici");
      this.isAgendaHasChange = Object.keys(this.initialAgendaForm).some(key =>
        this.editEventAgendaFormGroup.value[key] != this.initialAgendaForm[key])
      }
    );
  }
  selectAll() {
    document.querySelectorAll<HTMLElement>('.click_input').forEach(ele => ele.click());
  }
}