<div style="width:100%; border-top: 3px solid #eae5d5;background-color: #fff">
    <div class="maincontainer font-color col" [ngStyle]="{'background-color':mobileRes == true ? '#f8f9f4' : '#FFF' }">
        <nav class="breadcrumb-responsive filAriane row" aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-color">
                <svg [routerLink]="['/admin/dashboard']" id="dropdownMenuButton6" height="24px" width="24px" aria-haspopup="true" aria-expanded="false"
                    style="fill:#84C000">
                    <use xlink:href="#company_f" />
                </svg>
                <div style="display:flex;">
                    <ng-container *ngFor="let item of nameUrl; let myIndex = index; first as isFirst; last as isLast" >
                        <li class="breadcrumb-item" *ngIf="!isLast && myIndex > 1 && (item == 'Agenda' || item == 'Tools')"><a>{{nameUrl[myIndex]}}</a></li>
                        <li class="breadcrumb-item" *ngIf="!isLast && myIndex > 1 && item != 'Agenda' && item != 'Tools'"><a [routerLink]="url[myIndex]">{{nameUrl[myIndex]}}</a></li>
                        <li class="breadcrumb-item active" *ngIf="isLast" aria-current="page">{{nameUrl[myIndex]}}</li>
                    </ng-container>
                </div>
            </ol>
            <div *ngIf="cancelPage" class="icons">
                <span placement="left" popoverClass="popover-custom-light popover-width" ngbPopover="Back" triggers="mouseenter:mouseleave">
                    <svg height="24px" width="24px" class="ml-3" (click)="backPage()">
                        <use xlink:href="#delete" />
                    </svg>
                </span>
            </div>
            <div *ngIf="!cancelPage" class="icons">
                <div class="btn-group dropleft ml-3">
                    <span placement="left" popoverClass="popover-custom-light popover-width" ngbPopover="Add more" triggers="mouseenter:mouseleave"
                    id="AddThings" data-toggle="dropdown">
                        <svg aria-haspopup="true" aria-expanded="false" class="iconFilArianne">
                            <use xlink:href="#plus" />
                        </svg>
                    </span>
                    <div class="dropdown-menu" aria-labelledby="AddThings">
                        <a *ngIf="urlNewAgenda" placement="left" popoverClass="popover-custom-light" ngbPopover="New agenda" triggers="mouseenter:mouseleave"
                        class="dropdown-item" [routerLink]="urlNewAgenda" i18n>New agenda</a>
                        <a *ngIf="urlNewEvent" placement="left" popoverClass="popover-custom-light" ngbPopover="New event" triggers="mouseenter:mouseleave"
                        class="dropdown-item" [routerLink]="urlNewEvent" i18n>New event</a>
                        <a *ngIf="urlNewContact" placement="left" popoverClass="popover-custom-light" ngbPopover="New contact" triggers="mouseenter:mouseleave"
                        class="dropdown-item" [routerLink]="urlNewContact" i18n>New contact</a>
                        <a *ngIf="urlNewCompany" placement="left" popoverClass="popover-custom-light" ngbPopover="New company" triggers="mouseenter:mouseleave"
                        class="dropdown-item" [routerLink]="urlNewCompany" i18n>New company</a>
                        <a *ngIf="urlNewBroadcast" placement="left" popoverClass="popover-custom-light" ngbPopover="New broadcast" triggers="mouseenter:mouseleave"
                        class="dropdown-item" [routerLink]="urlNewBroadcast" i18n>New broadcast</a>
                    </div>
                </div>
                <span *ngIf="exportIcon" placement="top" popoverClass="popover-custom-light popover-width" ngbPopover="Export list" triggers="mouseenter:mouseleave">
                    <svg height="24px" width="24px" class="ml-3" (click)="exportFile()">
                        <use xlink:href="#export" />
                    </svg>
                </span>
                <div class="btn-group dropleft ml-3">
                    <span placement="right" popoverClass="popover-custom-light popover-width" ngbPopover="More option" triggers="mouseenter:mouseleave"
                    id="dropdownMenuButton2" data-toggle="dropdown">
                        <svg aria-haspopup="true" aria-expanded="false" class="iconFilArianne">
                            <use xlink:href="#action_h"/>
                        </svg>
                    </span>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                        <a class="dropdown-item" href="#" i18n>Action 1</a>
                        <a class="dropdown-item" href="#" i18n>Action 2</a>
                        <a class="dropdown-item" href="#" i18n>Action 3</a>
                        <a class="dropdown-item" href="#" i18n>Action 4</a>
                    </div>
                </div>
            </div>
        </nav>
        <nav class=" breadcrumb-responsive filArianeMobile row justify-content-center" style="margin-top: 5px;"
            aria-label="breadcrumb">
                <!-- VERSION MOBILE -->
            <div>
                <ol class="breadcrumb breadcrumb-color col align-self-center" style="flex-wrap: nowrap!important;background-color:#F8F9F4;">
                    <li class="breadcrumb-item"><a href="#" i18n>Participants</a></li>
                    <li class="breadcrumb-item"><a href="#" i18n>Guest</a></li>
                    <li class="breadcrumb-item active" aria-current="page"><a href="#" i18n>Invited</a></li>
                </ol>
            </div>
        </nav>
    </div>
</div>
