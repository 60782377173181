import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TaskListService } from '../../../../core/http/task-list.service';
// import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { DatePipe, formatDate } from '@angular/common';

declare var $: any;


@Component({
  selector: 'app-task-done',
  templateUrl: './task-done.component.html',
  styleUrls: ['./task-done.component.scss']
})
export class TaskDoneComponent implements OnInit {

  public modifTaskForm = this.fb.group(
    {
      id: [Math.random().toString(36).substr(2, 9)],
      // dateCreate: [''],
      // taskTitle: ['', [Validators.required, Validators.maxLength(20)]],
      // taskContent: [''],
      statut: ['Done', Validators.required],
      taskComment: [''],
      doneBy: [''],
      doneDate: ['', Validators.required],
      // priority: ['none', Validators.required],
    });

  public modifCommentForm = this.fb.group({
    comment: ['']
  });

  @Input()
  index;

  @Output() dataChange: EventEmitter<any> = new EventEmitter();


  public users = [' Me', ' Emma', ' Jade', ' Jules', ' Louis', ' Gabriel', ' Louise', ' Anna', ' Admin1', ' Admin2'];

  // variables
  todayDate = { day: 0, month: 0, year: 0 };
  usersDone = [];
  userInitialName;
  usersDoneString;
  userAssigned;
  userAssignedString;
  dateIsPassed = false;
  doneDateIsPassed = false;
  indexComment;
  titleCut;
  task;
  modalName3;
  modal2Name3;
  modal3Name3;

  constructor(private taskListService: TaskListService,
              private fb: FormBuilder,
              private datePipe: DatePipe) { }


  ngOnInit() {
    this.task = this.taskListService.taskDone[this.index];
    this.cutTitle();
    this.userAssigned = this.task.assignedTo;
    this.usersDone = this.task.doneBy;
    this.getTodaysDate();
    this.checkDateIsPassed();
    this.checkDoneDateIsPassed();
    this.modalName3 = '#example3Modal' + this.index;
    this.modal2Name3 = '#modif3Task' + this.index;
    this.modal3Name3 = '#modif3Comment' + this.index;
    this.getInitials();
    // console.log(this.task);
    // console.log(this.dateIsPassed);
  }

  getInitials() {
    this.userInitialName = [];
    if (this.task.taskComment.length > 0) {
      this.task.taskComment.forEach(element => {            // Récupere l'initiale de l'user qui a comment
        this.userInitialName.push(element.user.substr(0, 1));
      });
    } else {
      this.task.taskComment = [];
    }
  }

  cutTitle() {
    if (this.task.taskTitle.length > 25) {
      this.titleCut = this.task.taskTitle.substr(0, 25) + '...';
    } else {
      this.titleCut = this.task.taskTitle;
    }
  }

  swipTask(task) {
    this.dataChange.emit();
    this.task.statut = 'To do';
    this.taskListService.taskTodo.push(task);
    this.taskListService.taskDone.splice(this.index, 1);
    this.taskListService.sortData();
  }

  getTodaysDate() {
    const dayString = this.datePipe.transform(new Date(), 'dd');
    const day = Number(dayString);
    const monthString = this.datePipe.transform(new Date(), 'MM');
    const month = Number(monthString);
    const yearString = this.datePipe.transform(new Date(), 'yyyy');
    const year = Number(yearString);
    this.todayDate = { day, month, year };
  }

  checkDateIsPassed() {
    if (this.task.dueDate.year < this.todayDate.year) {
      return this.dateIsPassed = true;
    }  if (this.task.dueDate.year === this.todayDate.year) {
      if (this.task.dueDate.month < this.todayDate.month) {
          return this.dateIsPassed = true;
        }  if (this.task.dueDate.month === this.todayDate.month) {
          if (this.task.dueDate.day <= this.todayDate.day) {
            return this.dateIsPassed = true;
          } 
          return this.dateIsPassed = false;
          
        } else {
          return this.dateIsPassed = false;
        }
    }
  }

  checkDoneDateIsPassed() {
    if (this.task.dueDate.year < this.task.doneDate.year) {
      return this.doneDateIsPassed = true;
    }  if (this.task.dueDate.year === this.task.doneDate.year) {
      if (this.task.dueDate.month < this.task.doneDate.month) {
        return this.doneDateIsPassed = true;
      }  if (this.task.dueDate.month === this.task.doneDate.month) {
        if (this.task.dueDate.day < this.task.doneDate.day) {
          return this.doneDateIsPassed = true;
        } 
        return this.doneDateIsPassed = false;
        
      } else {
        return this.doneDateIsPassed = false;
      }
    }
  }

  patchFormDatas() {
    $(this.modalName3).modal('hide');
    this.modifTaskForm.patchValue({
      doneDate: this.task.doneDate,
    });
  }

  modifComment(index) {
    $(this.modalName3).modal('hide');
    // console.log('you are trying to edit task at index' + index);
    this.indexComment = index;
    this.modifCommentForm.patchValue({
      comment: this.task.taskComment[index].comment
    });
  }

  deleteComment(index) {
    if (confirm('Are you sure to delete this comment ?')) {
      this.task.taskComment.splice(index, 1);
      this.taskListService.taskDone[this.index] = this.task;
    }
    console.log(this.task);
  }

  onChange(user) {
    this.usersDone.push(user);
    // console.log(this.usersDone);
  }

  deleteUserAssigned(index) {
    this.usersDone.splice(index, 1);
  }

  openModalDetails() {
    $(this.modalName3).modal('show');
  }

  deleteTask() {
    if (confirm('Are you sure to delete task ' + this.task.taskTitle)) {
      this.task.statutClass = 'success';
      this.task.deleteBy = 'AdminX';                 // ICI put l'user qui a delete
      this.task.deleteOn = formatDate(new Date(), 'yyyy/MM/dd', 'en');
      this.taskListService.taskDone.splice(this.index, 1);
      this.taskListService.removedTasks.push(this.task);
    }
  }

  onSubmit() {
    // if (this.modifTaskForm.value.priority === 'none') {
    //   this.modifTaskForm.value.priority = false;
    // } else if (this.modifTaskForm.value.priority === 'low') {
    //   this.modifTaskForm.value.priority = true;
    //   this.modifTaskForm.value.priorityLabel = 'Low';
    //   this.modifTaskForm.value.priorityClass = 'info';
    // } else if (this.modifTaskForm.value.priority === 'medium') {
    //   this.modifTaskForm.value.priority = true;
    //   this.modifTaskForm.value.priorityLabel = 'Medium';
    //   this.modifTaskForm.value.priorityClass = 'warning';
    // } else if (this.modifTaskForm.value.priority === 'high') {
    //   this.modifTaskForm.value.priority = true;
    //   this.modifTaskForm.value.priorityLabel = 'High';
    //   this.modifTaskForm.value.priorityClass = 'danger';
    // }
    if (this.modifTaskForm.value.taskComment != null && this.modifTaskForm.value.taskComment.length > 0) {
      const com = this.modifTaskForm.value.taskComment;
      const commentObject = {
        user: 'Admin1',    // ICI METTRE LE PSEUDO DE L'USER
        dateComment: formatDate(new Date(), 'yyyy/MM/dd', 'en'),
        comment: com
      };
      this.task.taskComment.push(commentObject);
      this.modifTaskForm.value.taskComment = this.task.taskComment;
    }
    this.taskListService.taskDone[this.index].taskComment = this.modifTaskForm.value.taskComment;
    this.taskListService.taskDone[this.index].doneDate = this.modifTaskForm.value.doneDate;
    this.taskListService.taskDone[this.index].doneBy = this.usersDone;
    this.task = this.taskListService.taskDone[this.index];
    if (this.modifTaskForm.value.statut === 'To do') {
      this.taskListService.taskTodo.push(this.task);
      this.taskListService.taskDone.splice(this.index, 1);
      this.dataChange.emit();
    } else if (this.modifTaskForm.value.statut === 'In progress') {
      this.taskListService.taskIP.push(this.task);
      this.taskListService.taskDone.splice(this.index, 1);
    }
    this.taskListService.sortData();
    // console.log(this.task);
    this.checkDateIsPassed();
    this.checkDoneDateIsPassed();
    this.getInitials();
    $(this.modalName3).modal('hide');
    $(this.modal2Name3).modal('hide');
  }

  onSubmitComment() {
    this.task.taskComment[this.indexComment].comment = this.modifCommentForm.value.comment;
    this.task.taskComment[this.indexComment].dateComment = formatDate(new Date(), 'yyyy/MM/dd', 'en');
    this.task.taskComment[this.indexComment].user = 'Admin1'; // ICI mettre l'user qui a modifié le commentaire
    this.taskListService.taskDone[this.index] = this.task;
    this.getInitials();
    $(this.modal3Name3).modal('hide');
    $(this.modalName3).modal('show');
    // console.log(this.task);
  }

}
