import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-agenda-edit-item-form',
  templateUrl: './agenda-edit-item-form.component.html',
  styleUrls: ['./agenda-edit-item-form.component.scss']
})
export class AgendaEditItemFormComponent implements OnInit {

  constructor() { }
  
  ngOnInit() {}
}
