import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormDataHotelService } from '../../../../core/http/form-data-hotel.service';
import { HotelService } from '../../../../core/http/hotel.service';
import { RefService } from '../../../../core/http/ref.service';

// tslint:disable: max-line-length
@Component({
  selector: 'app-hotels-add-form',
  templateUrl: './hotels-add-form.component.html',
  styleUrls: ['./hotels-add-form.component.scss']
})
export class HotelsAddFormComponent implements OnInit {

  listOfCountry: any
  dataHotel: any;
  eventId: any;

  constructor(
    private router: Router,
    private refService: RefService,
    private route: ActivatedRoute,
    private hotelService: HotelService,
    private toastr: ToastrService,
    private formDataHotelService: FormDataHotelService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.eventId = params.get('id');
    });
    this.dataHotel = this.formDataHotelService.dataHotel();
    this.refService.getCountry().subscribe( res => {
      this.listOfCountry = res;
    })
  }

  onSubmit() {
    this.dataHotel["value"].idEvent = this.eventId;
    this.hotelService.createHotel(this.dataHotel["value"]).subscribe(res => {
      console.log('success', res);
      this.toastr.clear();
      this.toastr.success('Success', 'EventAgenda create');
      this.router.navigateByUrl(`/admin/logistics/hotels`);
    }, err => {
      console.log("L'error est : " + err);
      this.toastr.clear();
      this.toastr.error('Error', 'Event not create');
    });
  }

  onSubmitHotelRoom() {
  }

}
