import { Component, OnInit, Input } from '@angular/core';
import { ParamMap, ActivatedRoute } from '@angular/router';
import { EventService } from '../../../../core/http/event.service'
import { DateService } from '../../../../core/http/date.service'
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-agenda-details',
  templateUrl: './agenda-details.component.html',
  styleUrls: ['./agenda-details.component.scss']
})
export class AgendaDetailsComponent implements OnInit {
  
  constructor(
    private route: ActivatedRoute,
    private eventService: EventService,
    private dateService: DateService,
    private datePipe: DatePipe
  ) {}

  idHappening: any;
  updateLink: string;
  agenda: any;
  dataAgenda: any;
  agendaDescription: any;

  async ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.idHappening = params.get('id');
    });
    this.updateLink = '/admin/agenda/event-agenda/' + this.idHappening + "/edit";
    this.dataAgenda = await this.eventService.getAgenda(this.idHappening).toPromise();
    this.initAgenda();
    console.log(this.dataAgenda);
  }
  initAgenda() {
    this.agenda = [
      {
        title: 'INFORMATION FONCTION', fields: [
          { title: 'FUNCTION', content: this.dataAgenda?.function ? this.dataAgenda.function : '-' },
          { title: 'FUNCTION SHORT', content: this.dataAgenda?.functionShort ? this.dataAgenda.functionShort : '-' },
          { title: 'COMMENT', content: this.dataAgenda?.comment ? this.dataAgenda.comment : '-' },
          { title: 'VENDOR', content: this.dataAgenda?.vendor ? this.dataAgenda.vendor : '-' },
          { title: 'LOCATION', content: this.dataAgenda?.location ? this.dataAgenda.location : '-' },
          { title: 'TYPE', content: this.dataAgenda?.typeFunction ? this.dataAgenda.typeFunction : '-' },
          { title: 'PARTICIPANT MANDATORY', content: this.dataAgenda?.typeParticipantMandatory ? this.dataAgenda.typeParticipantMandatory : '-' },
        ]
      },
      {
        fields: [
          { title: 'PARENT FUNCTION', content: this.dataAgenda?.parent ? this.dataAgenda.parent : '-' },
          { title: 'DATE', content: this.dataAgenda?.date ? this.dataAgenda.date.substring(0, 10) : '-' },
          { title: 'TIME', content: this.dataAgenda?.startDate ? this.dataAgenda.startDate.substring(11) + " - " + this.dataAgenda.endDate.substring(11) : '-' },
          { title1: 'UPON REGISTRATION', content: this.dataAgenda?.uponRegistration, title2: 'UPON INVITATION', content2: this.dataAgenda?.uponInvitation },
          { title1: 'PRINT', content: this.dataAgenda?.print, title2: 'LIVE', content2: this.dataAgenda?.live },
          { title1: 'CAPACITY', content: this.dataAgenda?.capacity, title2: 'MINUS', content2: this.dataAgenda?.minus },
          { title: 'TRANSFER', content: this.dataAgenda?.transfer ? this.dataAgenda.transfer : '-' },
          { title: 'PARTICIPANT OPTIONAL', content: this.dataAgenda?.typeParticipantOptional ? this.dataAgenda.typeParticipantOptional : '-' },
        ]
      },
    ];
    this.agendaDescription = [
      {
        fields: [
          { title: 'DESCRIPTION', content: this.dataAgenda?.description ? this.dataAgenda.description : '-' },
          { title: 'ITEM URL (Zoom, Link ...)', content: this.dataAgenda?.itemUrl ? this.dataAgenda.itemUrl : '-' },
        ]
      },
    ];
  }
}
