import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormDataHotelService {

  constructor(
    private fb: FormBuilder
  ) { }

  data: any;

  dataHotel() {
    return this.data = this.fb.group({
      hotelName: ['', Validators.required],
      idEvent: [0],
      idSociete: [0],
      address: [''],
      zipCode: [''],
      city: [''],
      state: [''],
      idCountry: [0],
      mobile: [''],
      fax: [''],
      url: [''],
      description: ['']
    });
  }
  dataNight() {
    return this.data = this.fb.group({
      idNight: [0],
      idHotel: [0],
      idEvent: [0],
      dateString: [''],
      date: [''],
      nbrRoom: [0],
      nbrSurbook: [0],
      idRoom: [0],
      price: [0],
      max: [0],
      typeParticipant: [''],
      note: [''],
    });
  }
  dataRoomType() {
    return this.data = this.fb.group({
      idHotel: [0],
      roomName: [''],
    });
  }
}
