import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexModule } from '@angular/flex-layout';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ChartsModule } from 'ng2-charts';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { HeaderEventComponent } from './components/header-event/header-event.component';
import { FilArianeV2Component } from './components/fil-ariane-v2/fil-ariane-v2.component';
import { ParticipantEditFormComponent } from './components/participant/participant-edit-form/participant-edit-form.component';
import { ParticipantAddFormComponent } from './components/participant/participant-add-form/participant-add-form.component';
import { EventAddFormComponent } from './components/event/event-add-form/event-add-form.component';
import { AgendaAddFormComponent } from './components/agenda/agenda-add-form/agenda-add-form.component';
import { AgendaEditFormComponent } from './components/agenda/agenda-edit-form/agenda-edit-form.component';
import { EventEditFormComponent } from './components/event/event-edit-form/event-edit-form.component';
import { EventDetailsComponent } from './components/event/event-details/event-details.component';
import { AgendaDetailsComponent } from './components/agenda/agenda-details/agenda-details.component';
import { ParticipantEditCardDetailsComponent } from './components/participant/participant-edit-card-details/participant-edit-card-details.component';
import { UserCardDesktopComponent } from './components/user-card-desktop/user-card-desktop.component';
import { UserCardMobileComponent } from './components/user-card-mobile/user-card-mobile.component';
import { ParticipantDetailsComponent } from './components/participant/participant-details/participant-details.component';
import { ParticipantDetailsCardComponent } from './components/participant/participant-details-card/participant-details-card.component';
import { ParticipantReservationNightComponent } from './components/participant/participant-reservation-night/participant-reservation-night.component';
import { FormInputComponent } from './components/form/form-input/form-input.component';
import { FormSelectComponent } from './components/form/form-select/form-select.component';
import { FormSearchSelectComponent } from './components/form/form-search-select/form-search-select.component';
import { FormCheckboxComponent } from './components/form/form-checkbox/form-checkbox.component';
import { FormRadioComponent } from './components/form/form-radio/form-radio.component';
import { FormRadioV2Component } from './components/form/form-radio-v2/form-radio-v2.component';
import { UserCardDesktopV2Component } from './components/user-card-desktop-v2/user-card-desktop-v2.component';
import { UserCardContactUpdateComponent } from './components/user-card-update/user-card-contact-update/user-card-contact-update.component';
import { UserCardEventUpdateComponent } from './components/user-card-update/user-card-event-update/user-card-event-update.component';
import { UpdateParticipantEventComponent } from './components/update-participant-event/update-participant-event.component';
import { FiltersMobileComponent } from './components/filters-mobile/filters-mobile.component';
import { ListSortByComponent } from './components/list-sort-by/list-sort-by.component';
import { CardDashboardWithPercentComponent } from './components/dashboard/card-dashboard-with-percent/card-dashboard-with-percent.component';
import { CardDashboardEmailComponent } from './components/dashboard/card-dashboard-email/card-dashboard-email.component';
import { CardDashboardGuestInvitedComponent } from './components/dashboard/guest/card-dashboard-guest-invited/card-dashboard-guest-invited.component';
import { CardDashboardSmsComponent } from './components/dashboard/card-dashboard-sms/card-dashboard-sms.component';
import { CardProgressChart } from './components/dashboard/card-progress-chart/card-progress-chart';
import { CardDashboardGuestConfirmedPreviousEventComponent } from './components/dashboard/guest/card-dashboard-guest-confirmed-previous-event/card-dashboard-guest-confirmed-previous-event.component';
import { CardDashboardGuestShortlistComponent } from './components/dashboard/guest/card-dashboard-guest-shortlist/card-dashboard-guest-shortlist.component';
import { CardDashboardGuestConfirmedComponent } from './components/dashboard/guest/card-dashboard-guest-confirmed/card-dashboard-guest-confirmed.component';
import { NgbPopoverModule, NgbProgressbarModule, NgbDatepickerModule, NgbTimepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CardPieChart } from './components/dashboard/card-pie-chart/card-pie-chart';
import { CardDashboardNotificationComponent } from './components/dashboard/card-dashboard-notification/card-dashboard-notification.component';
import { SettingsDashboardOptionFormComponent } from './components/settings/settings-dashboard-option-form/settings-dashboard-option-form.component';
import { HotelsAddFormComponent } from './components/hotels/hotels-add-form/hotels-add-form.component';
import { HotelsEditFormComponent } from './components/hotels/hotels-edit-form/hotels-edit-form.component';
import { HotelsDetailsComponent } from './components/hotels/hotels-details/hotels-details.component';
import { HotelsBookingListComponent } from './components/hotels/hotels-booking-list/hotels-booking-list.component';
import { HotelsManageComponent } from './components/hotels-manage/hotels-manage.component';
import { NightAddFormComponent } from './components/hotels/night-add-form/night-add-form.component';
import { NightTabFormComponent } from './components/hotels/night-tab-form/night-tab-form.component';
import { TaskListComponent } from './components/dashboard/task-list/task-list.component';
import { TaskComponent } from './components/dashboard/task/task.component';
import { TaskInProgressComponent } from './components/dashboard/task-in-progress/task-in-progress.component';
import { TaskDoneComponent } from './components/dashboard/task-done/task-done.component';
import { ListAllTasksComponent } from './components/list-all-tasks/list-all-tasks.component';
import { TaskForListComponent } from './components/task-for-list/task-for-list.component';
import { ContactDashboardComponent } from './components/lists-dashboard/contact-dashboard/contact-dashboard.component';
import { EventDashboardComponent } from './components/lists-dashboard/event-dashboard/event-dashboard.component';
import { FilArianeEmailingComponent } from './components/fil-ariane-emailing/fil-ariane-emailing.component';
import { CreateTemplateComponent } from './components/emailing/create-template/create-template.component';
import { MealsComponent } from './components/meals/meals.component';
import { CardParticipantsComponent } from './components/dashboard/card-participants/card-participants.component';
import { CardNewAttendeeComponent } from './components/dashboard/card-new-attendee/card-new-attendee.component';
import { CardDeckCommunicationComponent } from './components/dashboard/card-deck-communication/card-deck-communication.component';
import { CardPartcipantsFunnelComponent } from './components/dashboard/card-partcipants-funnel/card-partcipants-funnel.component';
import { CompanyEditFormComponent } from './components/company/company-edit-form/company-edit-form.component';
import { CompanyAddFormComponent } from './components/company/company-add-form/company-add-form.component';
import { CompanyDetailsComponent } from './components/company/company-details/company-details.component';
import { CompanyDetailsCardComponent } from './components/company/company-details-card/company-details-card.component';
import { OldFormEditComponent } from './components/old-form-edit/old-form-edit.component';
import { CompanyAttendeeCardComponent } from './components/company/company-attendee-card/company-attendee-card.component';
import { EventParticipantStatusCardComponent } from './components/event/event-participant-status-card/event-participant-status-card.component';
import { OlfFormCompanyComponent } from './components/olf-form-company/olf-form-company.component';
import { AutocompleteCompanyComponent } from './components/form/autocomplete-company/autocomplete-company.component';
import { MainDashboardComponent } from './components/templates/main-dashboard/main-dashboard.component';
import { LandingPageComponent } from './components/templates/landing-page/landing-page.component';
import { FicheModelComponent } from './components/templates/fiche-model/fiche-model.component';
import { ListModelComponent } from './components/templates/list-model/list-model.component';
import { BroadcastComponent } from './components/templates/broadcast/broadcast.component';
import { SettingsModuleComponent } from './components/templates/settings-module/settings-module.component';
import { ParticipantHeaderComponent } from './components/participant/participant-header/participant-header.component';
import { CompanyHeaderComponent } from './components/company/company-header/company-header.component';
import { EventHeaderComponent } from './components/event/event-header/event-header.component';
import { AgendaHeaderComponent } from './components/agenda/agenda-header/agenda-header.component';
import { AutocompleteParticipantComponent } from '../shared/components/form/autocomplete-participant/autocomplete-participant.component';
import { CardsComponent } from './components/wiki/dashboard/cards/cards.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SvgLibraryComponent } from './components/svg-library/svg-library.component';
import { FollowupItemComponent } from './components/emailing/followup-item/followup-item.component';
import { FollowupPageComponent } from './components/emailing/followup-page/followup-page.component';
import { FollowupContactComponent } from './components/emailing/followup-contact/followup-contact.component';
import { ExtractCardComponent } from './components/extract-card/extract-card.component';
import { CardAddAttendeeComponent } from './components/dashboard/card-add-attendee/card-add-attendee.component';
import { CardEditAttendeeComponent } from './components/dashboard/card-edit-attendee/card-edit-attendee.component';
import { EventAgendaItemComponent } from './components/event/event-agenda-item/event-agenda-item.component';
import { FilArianeListUsersComponent } from './components/fil-ariane-list-users/fil-ariane-list-users.component';
import { EventItemCardComponent } from './components/event/event-item-card/event-item-card.component';
import { AgendaParentCardComponent } from './components/agenda/agenda-parent-card/agenda-parent-card.component';

@NgModule({
  declarations: [
    HeaderEventComponent,
    FilArianeV2Component,
    EventAddFormComponent,
    AgendaAddFormComponent,
    AgendaEditFormComponent,
    EventEditFormComponent,
    EventDetailsComponent,
    AgendaDetailsComponent,
    ParticipantEditFormComponent,
    ParticipantAddFormComponent,
    ParticipantEditCardDetailsComponent,
    UserCardDesktopComponent,
    UserCardMobileComponent,
    ParticipantDetailsComponent,
    ParticipantDetailsCardComponent,
    ParticipantReservationNightComponent,
    FormInputComponent,
    FormSelectComponent,
    FormSearchSelectComponent,
    FormCheckboxComponent,
    FormRadioComponent,
    FormRadioV2Component,
    UserCardDesktopV2Component,
    UserCardContactUpdateComponent,
    UserCardEventUpdateComponent,
    UpdateParticipantEventComponent,
    FiltersMobileComponent,
    ListSortByComponent,
    CardDashboardEmailComponent,
    CardDashboardWithPercentComponent,
    CardDashboardGuestInvitedComponent,
    CardDashboardSmsComponent,
    CardProgressChart,
    CardDashboardGuestConfirmedPreviousEventComponent,
    CardPieChart,
    CardDashboardGuestShortlistComponent,
    CardDashboardGuestConfirmedComponent,
    CardPieChart,
    CardDashboardNotificationComponent,
    CardPieChart,
    SettingsDashboardOptionFormComponent,
    HotelsAddFormComponent,
    HotelsEditFormComponent,
    HotelsDetailsComponent,
    HotelsBookingListComponent,
    HotelsManageComponent,
    NightAddFormComponent,
    NightTabFormComponent,
    TaskListComponent,
    TaskComponent,
    TaskInProgressComponent,
    TaskDoneComponent,
    ListAllTasksComponent,
    TaskForListComponent,
    ContactDashboardComponent,
    EventDashboardComponent,
    FilArianeEmailingComponent,
    FilArianeListUsersComponent,
    CreateTemplateComponent,
    MealsComponent,
    CardParticipantsComponent,
    CardNewAttendeeComponent,
    CardDeckCommunicationComponent,
    CardPartcipantsFunnelComponent,
    CompanyEditFormComponent,
    CompanyAddFormComponent,
    CompanyDetailsComponent,
    CompanyDetailsCardComponent,
    OldFormEditComponent,
    CompanyAttendeeCardComponent,
    EventParticipantStatusCardComponent,
    OlfFormCompanyComponent,
    AutocompleteCompanyComponent,
    MainDashboardComponent,
    LandingPageComponent,
    FicheModelComponent,
    ListModelComponent,
    BroadcastComponent,
    SettingsModuleComponent,
    ParticipantHeaderComponent,
    CompanyHeaderComponent,
    EventHeaderComponent,
    AgendaHeaderComponent,
    AutocompleteParticipantComponent,
    CardsComponent,
    LoaderComponent,
    SvgLibraryComponent,
    FollowupItemComponent,
    FollowupPageComponent,
    FollowupContactComponent,
    ExtractCardComponent,
    CardAddAttendeeComponent,
    CardEditAttendeeComponent,
    EventAgendaItemComponent,
    EventItemCardComponent,
    AgendaParentCardComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    ChartsModule,
    NgbProgressbarModule,
    NgbPopoverModule,
    NgbTimepickerModule,
    NgbDatepickerModule,
    NgxPaginationModule,
    NgxMaterialTimepickerModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    MatAutocompleteModule,
    DragDropModule,
    MatExpansionModule,
    MatListModule,
    NgbDropdownModule,
    FlexModule,
    MatStepperModule,
    MatButtonModule,
    CKEditorModule,
  ],
  exports: [
    HeaderEventComponent,
    FilArianeV2Component,
    EventAddFormComponent,
    AgendaAddFormComponent,
    AgendaEditFormComponent,
    EventEditFormComponent,
    EventDetailsComponent,
    AgendaDetailsComponent,
    ParticipantEditFormComponent,
    ParticipantAddFormComponent,
    ParticipantEditCardDetailsComponent,
    UserCardDesktopComponent,
    UserCardMobileComponent,
    ParticipantDetailsComponent,
    ParticipantDetailsCardComponent,
    ParticipantReservationNightComponent,
    UserCardDesktopV2Component,
    UserCardContactUpdateComponent,
    UpdateParticipantEventComponent,
    UserCardEventUpdateComponent,
    FiltersMobileComponent,
    ListSortByComponent,
    FormSelectComponent,
    FormSearchSelectComponent,
    CardDashboardEmailComponent,
    CardDashboardWithPercentComponent,
    CardDashboardGuestInvitedComponent,
    CardDashboardSmsComponent,
    CardPieChart,
    CardProgressChart,
    CardDashboardGuestConfirmedPreviousEventComponent,
    CardDashboardGuestShortlistComponent,
    CardDashboardGuestConfirmedComponent,
    CardPieChart,
    CardDashboardNotificationComponent,
    CompanyEditFormComponent,
    CompanyAddFormComponent,
    SettingsDashboardOptionFormComponent,
    TaskListComponent,
    FilArianeEmailingComponent,
    FilArianeListUsersComponent,
    FormInputComponent,
    FormCheckboxComponent,
    FormRadioComponent,
    FormRadioV2Component,
    CompanyDetailsComponent,
    CompanyDetailsCardComponent,
    OlfFormCompanyComponent,
    AutocompleteCompanyComponent,
    CompanyAttendeeCardComponent,
    EventParticipantStatusCardComponent,
    MainDashboardComponent,
    LandingPageComponent, 
    FicheModelComponent,
    ParticipantHeaderComponent,
    CompanyHeaderComponent,
    EventHeaderComponent,
    AgendaHeaderComponent,
    AutocompleteParticipantComponent,
    LoaderComponent,
    SvgLibraryComponent,
    FollowupItemComponent,
    FollowupPageComponent,
    ExtractCardComponent,
    CardAddAttendeeComponent,
    CardEditAttendeeComponent,
    EventAgendaItemComponent,
    CardParticipantsComponent,
    EventItemCardComponent,
    AgendaParentCardComponent,
    HotelsAddFormComponent,
    HotelsEditFormComponent,
    HotelsDetailsComponent,
    HotelsBookingListComponent,
    NightAddFormComponent,
    NightTabFormComponent
  ]
})
export class SharedModule {
}
