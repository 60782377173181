<div>
    <div>
        <div class="form-check form-check-inline">
            <ng-content select=".title"></ng-content>
        </div>
        <div class="form-check form-check-inline">
            <ng-content select=".radioTrue"></ng-content>
            <ng-content select=".labelTrue"></ng-content>
        </div>
        <div class="form-check form-check-inline">
            <ng-content select=".radioFalse"></ng-content>
            <ng-content select=".labelFalse"></ng-content>
        </div>
    </div>
</div>
