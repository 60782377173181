import { Component, OnInit, Input } from '@angular/core';
import { bounceOutRightOnLeaveAnimation, bounceAnimation, hueRotateAnimation, fadeInDownOnEnterAnimation, fadeOutUpOnLeaveAnimation } from 'angular-animations';
import * as _moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-hotels-details',
  templateUrl: './hotels-details.component.html',

  styleUrls: ['./hotels-details.component.scss'],
  animations: [
    bounceOutRightOnLeaveAnimation({ delay: 100 }),
    bounceAnimation(),
    hueRotateAnimation(),
    fadeInDownOnEnterAnimation({ duration: 10, translate: '5px', delay: 0 }),
    fadeOutUpOnLeaveAnimation({ duration: 10, translate: '5px', delay: 0 }),
  ]
})

export class HotelsDetailsComponent implements OnInit {

  @Input() data: any;

  constructor(
  ) {}


  ngOnInit() {
    console.log(this.data);
  }
}