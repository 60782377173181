import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common'
import { DashboardService } from '../../../../core/http/dashboard.service';
import { EventService } from '../../../../core/http/event.service';

@Component({
  selector: 'app-event-dashboard',
  templateUrl: './event-dashboard.component.html',
  styleUrls: ['./event-dashboard.component.scss']
})
export class EventDashboardComponent implements OnInit {

  constructor(
    private router: Router,
    private dashboardDataService: DashboardService,
    private eventService: EventService,
    private datepipe: DatePipe
  ) { }

  url: any;
  public href: string;
  listOfParticipantAll: any;
  nbAll: number;
  listOfParticipantConf: any;
  nbConf: number;
  listOfParticipantInv: any;
  nbInv: number;
  listOfParticipantAtt: any;
  nbAtt: number;
  listOfParticipantWait: any;
  nbWait: number;
  listOfParticipantDec: any;
  nbDec: number;
  listOfParticipantCan: any;
  nbCan: number;
  listOfParticipantNoshow: any;
  nbNoshow: number;
  listOfAllEvents: any;
  dataCardSend: any = [];
  elementEvents: any;
  valueEvent: number = 0;
  textEvent: string;
  dataCardSet = this.dashboardDataService;
  statut = false;

  async ngOnInit() {
    this.href = this.router.url;
    this.url = this.href.split('/');
    for (let i = 0; i < this.url.length; i++) {
      this.url[i] = this.url[i].substr(0, 1).toUpperCase() + this.url[i].substr(1);
    }
    this.dataCardSend = [];
    await this.initListApi();
    this.checkData();
    this.initCard();
  }
  async initListApi() {
    this.listOfAllEvents = await this.eventService.getAllEvents().toPromise();
    if (this.valueEvent == 0) {
      this.textEvent = this.datepipe.transform(this.listOfAllEvents[this.valueEvent].date, 'yyyy-MM-dd') + " - " + this.listOfAllEvents[this.valueEvent].label;
      this.valueEvent = this.listOfAllEvents[this.valueEvent].id;
    }
    this.listOfParticipantAll = await this.eventService.getEventRegistrationStatus("all", this.valueEvent).toPromise();
    this.listOfParticipantConf = await this.eventService.getEventRegistrationStatus("confirmed", this.valueEvent).toPromise();
    this.listOfParticipantInv = await this.eventService.getEventRegistrationStatus("invited", this.valueEvent).toPromise();
    this.listOfParticipantAtt = await this.eventService.getEventRegistrationStatus("attended", this.valueEvent).toPromise();
    this.listOfParticipantWait = await this.eventService.getEventRegistrationStatus("waiting-list", this.valueEvent).toPromise();
    this.listOfParticipantDec = await this.eventService.getEventRegistrationStatus("declined", this.valueEvent).toPromise();
    this.listOfParticipantCan = await this.eventService.getEventRegistrationStatus("cancelled", this.valueEvent).toPromise();
    this.listOfParticipantNoshow = await this.eventService.getEventRegistrationStatus("noshow", this.valueEvent).toPromise();
  }
  checkData() {
    this.nbAll = this.listOfParticipantAll ? this.listOfParticipantAll.length : 0;
    this.nbConf = this.listOfParticipantConf ? this.listOfParticipantConf.length : 0;
    this.nbInv = this.listOfParticipantInv ? this.listOfParticipantInv.length : 0;
    this.nbAtt = this.listOfParticipantAtt ? this.listOfParticipantAtt.length : 0;
    this.nbNoshow = this.listOfParticipantNoshow ? this.listOfParticipantNoshow.length : 0;
    this.nbWait = this.listOfParticipantWait ? this.listOfParticipantWait.length : 0;
    this.nbDec = this.listOfParticipantDec ? this.listOfParticipantDec.length : 0;
    this.nbCan = this.listOfParticipantCan ? this.listOfParticipantCan.length : 0;
  }
  initCard() {
    this.dataCardSend.push(this.dataCardSet.createCard4(this.textEvent, `events-participants/${this.valueEvent}`, this.nbAll, this.nbAtt, this.nbInv, this.nbWait, 0, this.nbConf, this.nbDec, this.nbCan));
    this.dataCardSend.push(this.dataCardSet.createCard3("edit contacts", `events-participants/${this.valueEvent}/edit`));
    this.dataCardSend.push(this.dataCardSet.createCard1("invited contacts", "invited", this.nbInv, "events-participants/" + this.valueEvent));
    this.dataCardSend.push(this.dataCardSet.createCard1("declined contacts", "declined", this.nbDec, "events-participants/" + this.valueEvent));
    this.dataCardSend.push(this.dataCardSet.createCard1("confirmed contacts", "confirmed", this.nbConf, "events-participants/" + this.valueEvent));
    this.dataCardSend.push(this.dataCardSet.createCard1("waiting list contacts", "waiting-list", this.nbWait, "events-participants/" + this.valueEvent));
    this.dataCardSend.push(this.dataCardSet.createCard1("cancelled contacts", "cancelled", this.nbCan, "events-participants/" + this.valueEvent));
    this.dataCardSend.push(this.dataCardSet.createCard1("attended contacts", "attended", this.nbAtt, "events-participants/" + this.valueEvent));
    this.dataCardSend.push(this.dataCardSet.createCard1("noshow contacts", "noshow", this.nbNoshow, "events-participants/" + this.valueEvent));
  }
  afterChange(event: any) {
    this.valueEvent = event.id;
    this.textEvent = event.label;
    this.ngOnInit();
  }
}
