<div class="userCard mb-2 mt-2">
    <div class="row justify-content-between mb-2">
        <div class="col-md-8" style="display: flex">
            <span class="date-style col-md-2 p-0">
                {{item?.startDate | date:'shortTime' }} - {{item?.endDate | date:'shortTime' }}
              </span>
            <div class="col-md-1 pipe">
                <svg id="dropdownMenuButton6" height="24px"
                width="24px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <use xlink:href="#minus" />
                </svg>
            </div>
            <div class="col-md-6 row">
                <a placement="top" popoverClass="popover-custom-light" ngbPopover="View details" triggers="mouseenter:mouseleave" [routerLink]="url_details_item">
                    <h3 class="userName">{{short_title}}</h3>
                </a>
                <span placement="top" popoverClass="popover-custom-light popover-width" ngbPopover="Edit agenda" triggers="mouseenter:mouseleave" [routerLink]="url_edit_item">
                    <svg class="svgEdit" id="svgEdit"
                    style="cursor:pointer;margin-top: -5px;fill: #fffefb;margin-left: 15px;overflow: visible"
                    height="24px" width="24px">
                        <use xlink:href="#edit" />
                    </svg>
                </span>
            </div>
            <div class="col-md-4 pr-0 text-align-center">
                <span *ngIf="item?.uponInvitation" class="badge-status badge-status-success col-md-4 mr-2">Invitation</span>
                <span *ngIf="!item?.uponInvitation" class="col-md-4 mr-2" style="color: #fff;">Invitation</span>
                <span *ngIf="item?.uponRegistration" class="badge-status badge-status-success col-md-4 mr-2">Registration</span>
            </div>
        </div>

        <div class="row col-md-4">
            <h5 class="col-md-10 ellipsis" style="text-align: end; font-family: 'nowayregular'">Monaco</h5>
            <div class="col-md-2 justify-content-end btn-group dropleft">
                <span id="dropdownMenuButton6" data-toggle="dropdown" placement="right" popoverClass="popover-custom-light popover-width" ngbPopover="More option" triggers="mouseenter:mouseleave">
                    <svg height="24px" width="24px" aria-haspopup="true" aria-expanded="false">
                        <use xlink:href="#more-vertical" />
                    </svg>
                </span>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" [routerLink]="url_edit_item" placement="left" popoverClass="popover-custom-light" ngbPopover="Edit agenda" triggers="mouseenter:mouseleave" i18n>Edit</a>
                    <a class="dropdown-item" data-toggle="#deleteModal" (click)="modalDeleteAgenda(item.idHappening)" placement="left" popoverClass="popover-custom-light" ngbPopover="Delete agenda" triggers="mouseenter:mouseleave" i18n>Delete</a>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!collapse" class="row justify-content-between l2 m-0">
        <p *ngIf="short_desc == ''" class="event-function-description mb-0">&nbsp;</p>
        <p *ngIf="!short_desc == ''" class="event-function-description mb-0">{{short_desc}}</p>
        <svg *ngIf="cut_desc" class="show_more" (click)="showMore()" placement="right" popoverClass="popover-custom-light" ngbPopover="Show more" triggers="mouseenter:mouseleave">
            <use xlink:href="#right"/>
        </svg>
    </div>
    <div *ngIf="collapse" class="row justify-content-between l2 m-0">
        <p class="event-function-description mb-0">{{item?.description}}</p>
        <svg class="show_more" (click)="showMore()" placement="right" popoverClass="popover-custom-light" ngbPopover="Show less" triggers="mouseenter:mouseleave">
            <use xlink:href="#left"/>
        </svg>
    </div>

    <hr>

    <div class="row col-md-12 footer justify-content-between p-0 m-0">
        <p class="col-md-2 m-0 p-0">
            <span *ngIf="item?.typeFunction" class="badge-status badge-{{item?.typeFunction.replaceAll(' ', '-')}} m-0">{{item?.typeFunction.replaceAll('-', ' ')}}</span>
        </p>
        <p class="col-md-5 p-0 m-0" i18n><b>MANDATORY:</b> {{mandatory}}</p>
        <p class="col-md-4 p-0 m-0" i18n><b>OPITIONAL:</b> {{optional}}</p>
        <p class="col-md-1 p-0 m-0" style="text-align: right;"><b>Capacity:</b> {{item?.capacity}}</p>
    </div>
</div>
<div class="p-4 already_exist modal" id="deleteModal{{item.idHappening}}" tabindex="-1" aria-labelledby="existingModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="">
        <div class="">
            <div class="">
            <h4 class="mb-4 text-align-center" i18n>Delete Agenda : {{short_title}}</h4>
        </div>
        <hr class="mt-2 mb-3 separator" style="background-color: #84C000;">
        <div class="dp-flex-justy-beetw">
            <div class="p-3" style="border: 1px solid #aeaeae;">
                <h5 class="" i18n>Agenda</h5>
                <ul class="p-0 m-0" style="list-style: none;">
                    <li i18n>Function : {{item?.function}}</li>
                    <li i18n>Capacity : {{item?.capacity}}</li>
                    <li i18n>Date : {{item?.startDate | date:'shortTime' }} - {{item?.endDate | date:'shortTime' }}</li>
                    <li i18n>Type : {{item?.typeFunction.replaceAll('-', ' ')}}</li>
                    <li i18n><b>Id : {{item?.idHappening}}</b></li>
                </ul>
            </div>
            <div class="ml-4 mr-4 arrow_middle">
                <div class="mt-2 pt-2 pr-2 pl-2 border_arrow_edit" data-dismiss="modal">
                    <a href="" class="link_edit">
                    <svg class="mb-2" id="svgAR" height="24px" width="24px">
                        <use class="arrow_edit" xlink:href="#arrow-left"/>
                    </svg>
                    <span>Cancel</span>
                    </a>
                </div>
                <div class="mt-2 pt-2 pr-2 pl-2 border_arrow_delete" data-dismiss="modal" (click)="deleteAgenda(item.idHappening)">
                    <a href="" class="link_delete">
                    <svg class="mb-2" id="svgAL" height="24px" width="24px">
                        <use class="arrow_delete" xlink:href="#delete"/>
                    </svg>
                    <span>Delete agenda</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
