<div class="container-fluid" style="background-color: #f9f8f4">
    <div class="maincontainer pt-5 pb-5">

        <div class="row">

            <div class="col-lg-6">

                <div class="row">

                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">

                                <h3 class="card-title mb-0" i18n>USER</h3>
                                <small style="opacity: 0.5" i18n>Online</small>

                                <h1 class="text-center" style="font-size: 55px" i18n> 89 </h1>

                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 mt-3">
                        <div class="card">
                            <div class="card-body">

                                <h3 class="card-title mb-0" i18n>USER</h3>
                                <small style="opacity: 0.5" i18n>Lats 14 days daily</small>

                                <h1 class="text-center" style="font-size: 55px" i18n>2,468</h1>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-lg-6">
                <div class="card">
                    <div class="card-body">

                        <span class="float-right"><svg class="mr-3" width="24px" height="24px" style="fill: #84C000; opacity:0.7 " >
                            <use xlink:href="#download"/>
                          </svg>  
                        </span>
                        <h3 class="card-title mb-0" i18n>USER</h3>
                        <div class="mt-4" style="display: block;">
                            <canvas baseChart
                                    [datasets]="barChartDataUser"
                                    [labels]="barChartLabelsUser"
                                    [options]="barChartOptions"
                                    [plugins]="barChartPlugins"
                                    [legend]="barChartLegend"
                                    [chartType]="barChartType">
                            </canvas>
                        </div>

                    </div>
                </div>
            </div>

        </div>


        <div class="row mt-3">

            <div class="col-lg-6">

                <div class="row">

                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">

                                <h3 class="card-title mb-0" i18n>SESSIONS</h3>
                                <small style="opacity: 0.5" i18n>Online</small>

                                <h1 class="text-center" style="font-size: 55px" i18n>150</h1>

                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 mt-3">
                        <div class="card">
                            <div class="card-body">

                                <h3 class="card-title mb-0" i18n>SESSIONS</h3>
                                <small style="opacity: 0.5" i18n>Lats 14 days daily</small>

                                <h1 class="text-center" style="font-size: 55px" i18n>6,438</h1>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-lg-6">
                <div class="card">
                    <div class="card-body">

                        <span class="float-right"><svg class="mr-3" width="24px" height="24px" style="fill: #84C000; opacity:0.7 " >
                            <use xlink:href="#download"/>
                          </svg>  </span>
                        <h3 class="card-title mb-0" i18n>SESSIONS</h3>
                        <small style="opacity: 0.5" i18n>Average Connection Time 00:02:14</small>
                        <div class="mt-4" style="display: block;">
                            <canvas baseChart
                                    [datasets]="barChartDataSession"
                                    [labels]="barChartLabelsSession"
                                    [options]="barChartOptions"
                                    [plugins]="barChartPlugins"
                                    [legend]="barChartLegend"
                                    [chartType]="barChartType">
                            </canvas>
                        </div>

                    </div>
                </div>
            </div>

        </div>


        <div class="row mt-4">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <span class="float-right"><svg width="24px" height="24px" style="fill: #84C000; opacity:0.7 " >
                            <use xlink:href="#download"/>
                          </svg>  
                        </span>
                        <h3 i18n>bandwidth use</h3> 

                        <div class="mt-4">
                            <canvas baseChart width="auto" height="80"
                                    [datasets]="lineChartData"
                                    [labels]="lineChartLabels"
                                    [options]="lineChartOptions"
                                    [colors]="lineChartColors"
                                    [legend]="lineChartLegend"
                                    [chartType]="lineChartType"
                                    [plugins]="lineChartPlugins">

                            </canvas>
                        </div>

                    </div>

                </div>
            </div>
        </div>


        <div class="row mt-4">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <h3 i18n>Disk Space</h3>

                        <div class="mt-3">
                            <div class="progress">
                                <div class="progress-bar bg-success-color" role="progressbar" style="width: 75%;" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">75%</div>
                            </div>
                            <p class="mt-2 mb-0"><strong style="opacity: 0.5" i18n>70.50 Go / 120.00 Go</strong></p>
                        </div>

                    </div>

                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-lg-12 mt-4">
                <div class="card">
                    <div class="card-body">

                        <div class="float-right">
                            <button class="btn btn-success" type="button" data-toggle="collapse"
                                    data-target="#collapseExample" aria-expanded="false"
                                    aria-controls="collapseExample" i18n>
                                Open
                            </button>
                        </div>

                        <h3 class="card-title mb-0" i18n>Log</h3>

                        <div class="collapse mt-4" id="collapseExample">

                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col" i18n>Date</th>
                                    <th scope="col" i18n>Message</th>
                                    <th scope="col" i18n>Last</th>
                                    <th scope="col" i18n>Handle</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th scope="row" i18n>1</th>
                                    <td i18n>Mark</td>
                                    <td i18n>Otto</td>
                                    <td i18n>@mdo</td>
                                </tr>
                                <tr>
                                    <th scope="row" i18n>2</th>
                                    <td i18n>Jacob</td>
                                    <td i18n>Thornton</td>
                                    <td i18n>@fat</td>
                                </tr>
                                <tr>
                                    <th scope="row" i18n>3</th>
                                    <td i18n>Larry</td>
                                    <td i18n>the Bird</td>
                                    <td i18n>@twitter</td>
                                </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>
                </div>
            </div>

            <div class="col-lg-12 mt-4">
                <div class="card">
                    <div class="card-body">

                        <div class="float-right">
                            <button class="btn btn-success" type="button" data-toggle="collapse"
                                    data-target="#collapseExample1" aria-expanded="false"
                                    aria-controls="collapseExample1" i18n>
                                Open
                            </button>
                        </div>

                        <h3 class="card-title mb-0">Data extraction</h3>

                        <div class="collapse mt-4" id="collapseExample1">

                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col" i18n>#</th>
                                    <th scope="col" i18n>First</th>
                                    <th scope="col" i18n>Last</th>
                                    <th scope="col" i18n>Handle</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th scope="row" i18n>1</th>
                                    <td i18n>Mark</td>
                                    <td i18n>Otto</td>
                                    <td i18n>@mdo</td>
                                </tr>
                                <tr>
                                    <th scope="row" i18n>2</th>
                                    <td i18n>Jacob</td>
                                    <td i18n>Thornton</td>
                                    <td i18n>@fat</td>
                                </tr>
                                <tr>
                                    <th scope="row" i18n>3</th>
                                    <td i18n>Larry</td>
                                    <td i18n>the Bird</td>
                                    <td i18n>@twitter</td>
                                </tr>
                                </tbody>
                            </table>

                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>

</div>

