import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/core/http/event.service';


@Component({
  selector: 'app-agenda-details-item-form',
  templateUrl: './agenda-details-item-form.component.html',
  styleUrls: ['./agenda-details-item-form.component.scss']
})
export class AgendaDetailsItemFormComponent implements OnInit {
  idHappening: number;
  currentEvent: any;
  urlEditEvent: any;

  constructor(
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.idHappening = params['id'] 
    });
    this.urlEditEvent = "/admin/agenda/events/" + this.idHappening + "/edit";
  }

}
