<div class="container">
    <div class="row justify-content-center">
        <h1 i18n>SVG LIBRARY</h1>
    </div>
    <div class="row mb-4 d-none">
        <div class="col-1 svg-col">
            <p>#activity</p>
            <svg width="24px" height="24px" >
                <use xlink:href="#activity" />
            </svg>
        </div>
        <div class="col-1 svg-col">
            <p>#arrow-left</p>
            <svg width="24px" height="24px" >
                <use xlink:href="#arrow-left" />
            </svg>
        </div>
        <div class="col-1 svg-col">
            <p>#bell</p>
            <svg width="24px" height="24px" >
                <use xlink:href="#bell" />
            </svg>
        </div>
        <div class="col-1 svg-col">
            <p>#briefcase</p>
            <svg width="24px" height="24px" >
                <use xlink:href="#briefcase" />
            </svg>
        </div>
        <div class="col-1 svg-col">
            <p>#check</p>
            <svg width="24px" height="24px" >
                <use xlink:href="#check" />
            </svg>
        </div>
        <div class="col-1 svg-col">
            <p>#chevrons-right</p>
            <svg width="24px" height="24px" >
                <use xlink:href="#chevrons-right" />
            </svg>
        </div>
        <div class="col-1 svg-col">
            <p>#ic_stats</p>
            <svg width="24px" height="24px" >
                <use xlink:href="#ic_stats" />
            </svg>
        </div>
        <div class="col-1 svg-col">
            <p>#late</p>
            <svg width="24px" height="24px" >
                <use xlink:href="#late" />
            </svg>
        </div>
    </div>
    <div class="row mb-4">
        <div class="col-2 svg-col"  *ngFor="let svg of listSvg">
            <p>#{{svg}}</p> 
            <svg width="24px" height="24px" (click)="copyToClipboard(svg)">
                <use attr.xlink:href="#{{svg}}" />
            </svg>
        </div>
    </div>
</div>