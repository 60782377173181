<div class="example-container" [class.example-is-mobile]="mobileQuery.matches" *ngIf="mobileQuery.matches">

  <mat-toolbar fxLayout="row" color="primary" class="example-toolbar">
    <button mat-icon-button (click)="snav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>

    <img fxFlex="10" fxFlex.xs="20" [routerLink]="['/admin/dashboard']" src="assets/icon/navbar/icon-dashboard.svg" (click)="snav.close()" width="37" height="40" style="padding: 3px; margin-left: 10px; cursor: pointer;"/>

    <span fxFlex="80" fxFlex.xs="80" class="header-event-responsive text-center" *ngIf="displaySearchBar" i18n>BLUE VENTURE</span>

    <input fxFlex="85" fxFlex.xs="60" class="input-search" type="search" *ngIf="!displaySearchBar" (blur)="display()" autofocus>

    <img src="assets/icon/svg/search.svg" fxFlex="5" fxFlex.xs="10" class="mr-3" width="24px" height="24px"
      (click)="display()" [ngStyle]="setMyStyles()"/>

  </mat-toolbar>

  <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav class="sidenav" #snav [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">

      <mat-nav-list>

        <mat-accordion multi="true">

          <mat-expansion-panel class="no-shadow">
            <mat-expansion-panel-header class="specific-class" fxLayout="row">
              <mat-panel-title>
                <span i18n>PARTICIPANTS</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list class="ml-0" dense>
              <mat-list-item>
                <a class="dropdown-item" [routerLink]="['/admin/contacts/add']" (click)="snav.close()"
                  i18n>Add</a>
              </mat-list-item>
              <!--<mat-list-item>
                <a class="dropdown-item" [routerLink]="['/admin/contacts/list']" (click)="snav.close()">General
                  attendees</a>
              </mat-list-item>-->
              <mat-list-item>
                <a class="dropdown-item" [routerLink]="['/admin/contacts/guests']" (click)="snav.close()"
                  i18n>Guests</a>
              </mat-list-item>
              <mat-list-item>
                <a class="dropdown-item" [routerLink]="['/admin/contacts/partners/dashboard']"
                  (click)="snav.close()" i18n>Partners</a>
              </mat-list-item>
              <mat-list-item>
                <a class="dropdown-item" [routerLink]="['/admin/contacts/visitors/dashboard']"
                  (click)="snav.close()" i18n>Visitors</a>
              </mat-list-item>
              <mat-list-item>
                <a class="dropdown-item" [routerLink]="['/admin/contacts/experts/dashboard']" (click)="snav.close()"
                  i18n>Experts</a>
              </mat-list-item>
              <mat-list-item>
                <a class="dropdown-item" [routerLink]="['/admin/contacts/staff-members/dashboard']"
                  (click)="snav.close()" i18n>Staff
                  members</a>
              </mat-list-item>
              <mat-list-item>
                <a class="dropdown-item" [routerLink]="['/admin/contacts/journalists/dashboard']"
                  (click)="snav.close()" i18n>Journalists</a>
              </mat-list-item>
              <mat-list-item>
                <a class="dropdown-item" [routerLink]="['/admin/contacts/suppliers/dashboard']"
                  (click)="snav.close()" i18n>Suppliers</a>
              </mat-list-item>
            </mat-nav-list>
          </mat-expansion-panel>

          <mat-expansion-panel class="no-shadow my-0">
            <mat-expansion-panel-header class="specific-class" fxLayout="row">
              <mat-panel-title>
                <span i18n>COMMUNICATION</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list class="ml-0" dense>
              <mat-list-item>
                <a class="dropdown-item" [routerLink]="['/admin/communication/emailing']" (click)="snav.close()" i18n>Emailing</a>
              </mat-list-item>
            </mat-nav-list>
          </mat-expansion-panel>

          <mat-expansion-panel class="no-shadow my-0">
            <mat-expansion-panel-header class="specific-class" fxLayout="row">
              <mat-panel-title>
                <span i18n>AGENDA</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list class="ml-0" dense>
              <mat-list-item>
                <a class="dropdown-item" (click)="snav.close()" i18n>none</a>
              </mat-list-item>
            </mat-nav-list>
          </mat-expansion-panel>

          <mat-expansion-panel class="no-shadow my-0">
            <mat-expansion-panel-header class="specific-class" fxLayout="row">
              <mat-panel-title>
                <span i18n>LOGISTICS</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list class="ml-0" dense>
              <mat-list-item>
                <a class="dropdown-item" [routerLink]="['admin/logistics/hotels']" (click)="snav.close()" i18n>Hotels Booking</a>
              </mat-list-item>
            </mat-nav-list>
          </mat-expansion-panel>

          <mat-expansion-panel class="no-shadow my-0">
            <mat-expansion-panel-header class="specific-class" fxLayout="row">
              <mat-panel-title>
                <span i18n>TOOLS</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list class="ml-0" dense>
              <mat-list-item>
                <a class="dropdown-item" [routerLink]="['/admin/reports']" (click)="snav.close()" i18n>Reports</a>
                <a class="dropdown-item" [routerLink]="['/admin/tools/manual']" (click)="snav.close()" i18n>Manual</a>
              </mat-list-item>
            </mat-nav-list>
          </mat-expansion-panel>

          <mat-expansion-panel class="no-shadow my-0">
            <mat-expansion-panel-header class="specific-class" fxLayout="row">
              <mat-panel-title>
                <span i18n>SETTING</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list class="ml-0" dense>
              <mat-list-item>
                <a class="dropdown-item" [routerLink]="['/admin/settings']" (click)="snav.close()" i18n>Setting</a>
              </mat-list-item>
              <mat-list-item>
                <a class="dropdown-item" (click)="logout(); snav.close()" i18n>Logout</a>
              </mat-list-item>
            </mat-nav-list>
          </mat-expansion-panel>

          <mat-expansion-panel class="no-shadow my-0">
            <mat-expansion-panel-header fxLayout="row">
              <mat-panel-title class="not-hover">
                <a [routerLink]="['/admin/statistics']" (click)="snav.close()" i18n>STATISTICS</a>
              </mat-panel-title>
            </mat-expansion-panel-header>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
