import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, ValidationErrors, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DateService } from 'src/app/core/http/date.service';
import { EventService } from '../../../../core/http/event.service';
import { RefService } from '../../../../core/http/ref.service';
import { FormDataEventService } from '../../../../core/http/form-data-event.service';
import { ImagesHandlerService } from 'src/app/core/http/images-handler.service';
import { bounceOutRightOnLeaveAnimation, bounceAnimation, hueRotateAnimation, fadeInDownOnEnterAnimation, fadeOutUpOnLeaveAnimation } from 'angular-animations';
import * as _moment from 'moment'

declare var $: any;

@Component({
  selector: 'app-event-add-form',
  templateUrl: './event-add-form.component.html',

  styleUrls: ['./event-add-form.component.scss'],
  animations: [
    bounceOutRightOnLeaveAnimation({ delay: 100 }),
    bounceAnimation(),
    hueRotateAnimation(),
    fadeInDownOnEnterAnimation({ duration: 10, translate: '5px', delay: 0 }),
    fadeOutUpOnLeaveAnimation({ duration: 10, translate: '5px', delay: 0 }),
  ]
})

export class EventAddFormComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private FormDataEventService: FormDataEventService,
    private imagesHandlerService: ImagesHandlerService,
    private toastr: ToastrService,
    private dateService: DateService,
    private http: HttpClient,
    private eventService: EventService,
    private refService: RefService
  ) {}

  addEventFormGroup: any;
  addEventContactFormGroup: any;
  addEventMenuFormGroup: any;
  listOfEventType: any;
  listOfTimezone: any;
  listOfTypeParticipant: any;
  participantType: any = [];
  listOfImages = [null, null, null];
  uploadFile: File | null;
  uploadFileMenu: File | null;
  uploadFileLabel: string | undefined = 'Choose an image to upload';

  ngOnInit() {
    this.initRef();
    this.addEventFormGroup = this.FormDataEventService.dataEvents(); 
    this.addEventContactFormGroup = this.FormDataEventService.dataContact();
    this.addEventMenuFormGroup = this.FormDataEventService.dataMenu();
  }

  initRef() {
    this.eventService.getEventType().subscribe( response => {
      this.listOfEventType = response;
    });
    this.refService.getTimezone().subscribe( response => {
      this.listOfTimezone = response;
    });
    this.refService.getTypeParticipant().subscribe( response => {
      this.listOfTypeParticipant = response;
      for (let i = 0; this.listOfTypeParticipant.length > i; i++) {
        if (this.listOfTypeParticipant[i].short == "dis") this.listOfTypeParticipant.splice(i, 1);
        if (this.listOfTypeParticipant[i].short == "ret") this.listOfTypeParticipant.splice(i, 1);
      }
      for (let i = 0; this.listOfTypeParticipant.length > i; i++) {
        this.participantType.push({"Libelle": this.listOfTypeParticipant[i].short, "Checked":  false});
      }
    });
  }
  async onSubmit() {
    var tab = [-1, -1, -1];
      // 1st post image, Then await id_image in response
    if (this.listOfImages.every(e => e === null)) {
      console.log('no images');
    } else {
      for (let i = 0; i < this.listOfImages.length; i++){
        if (this.listOfImages[i] !== null){
          const response = await this.http.request(this.imagesHandlerService.uploadImage(this.listOfImages[i])).toPromise();
          response["body"] ? (tab[i] = response["body"].idImage) : null;
        }
      }
    }
    if (this.uploadFileMenu) {
      const res = await this.http.request(this.imagesHandlerService.uploadImage(this.uploadFileMenu)).toPromise();
      res["body"] ? (this.addEventMenuFormGroup["value"].idPlaceholder = res["body"].idImage) : null;
    }
    //Init value at 0 if is null or empty
    if (this.addEventFormGroup["value"].Capacity == "" || this.addEventFormGroup["value"].Capacity == null) {
      this.addEventFormGroup["value"].Capacity = 0;
    }
    if (this.addEventFormGroup["value"].IdTimezone == "" || this.addEventFormGroup["value"].IdTimezone == null) {
      this.addEventFormGroup["value"].IdTimezone = 0;
    }
    //Init TypeParticipant
    this.addEventFormGroup["value"].TypeParticipant = this.participantType;
    //Init id image in the formGroup
    this.addEventFormGroup["value"].IdLogo = tab[0];
    this.addEventFormGroup["value"].IdLogoBanner = tab[1];
    this.addEventFormGroup["value"].IdFooter = tab[2];
    //Parse datepicker toString()
    this.addEventFormGroup["value"].StartDate = _moment(this.addEventFormGroup["value"].StartDate).format('DD/MM/YYYY');
    this.addEventFormGroup["value"].EventDate = this.addEventFormGroup["value"].StartDate;
    this.addEventFormGroup["value"].EndDate = _moment(this.addEventFormGroup["value"].EndDate).format('DD/MM/YYYY');
    this.addEventFormGroup["value"].StartDateRegistration = _moment(this.addEventFormGroup["value"].StartDateRegistration).format('DD/MM/YYYY');
    this.addEventFormGroup["value"].EndDateRegistration = _moment(this.addEventFormGroup["value"].EndDateRegistration).format('DD/MM/YYYY');
    this.addEventMenuFormGroup["value"].liveDate = _moment(this.addEventFormGroup["value"].liveDate).format('DD/MM/YYYY');
    this.eventService.addEvent(this.addEventFormGroup["value"]).subscribe(res => {
      console.log('success', res);
      this.eventService.updateEventContact(res.idEvent, this.addEventContactFormGroup["value"]);
      this.eventService.updateEventMenu(res.idEvent, this.addEventMenuFormGroup["value"]);
      this.toastr.clear();
      this.toastr.success('Success', 'Event create');
      this.router.navigateByUrl(`/admin/agenda/events/${res.idEvent}/details`);
    }, err => {
      console.log("L'error est : " + err);
      this.toastr.clear();
      this.toastr.error('Error', 'Event not create');
    });
  }
  handleFileInput(files: FileList, index: number) {
    if (files.length > 0) {
      this.uploadFile = files.item(0);
      this.listOfImages[index] = this.uploadFile;
    }
  }
  handleFileInputMenu(files: FileList) {
    if (files.length > 0) {
      this.uploadFileMenu = files.item(0);
    }
  }
  onChange(short: string, isChecked: boolean) {
    for (let i = 0; this.participantType.length > i; i++) {
      if (this.participantType[i].Libelle == short) {
        !isChecked ? this.participantType[i].Checked = false : this.participantType[i].Checked = true
      }
    }
  }
  selectAll() {
    document.querySelectorAll<HTMLElement>('.click_input').forEach(ele => ele.click());
  }
}



