import { Component, OnInit } from '@angular/core';
import { ParticipantService } from '../../../core/http/participant.service';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-details-participant',
  templateUrl: './details-participant.component.html',
  styleUrls: ['./details-participant.component.scss']
})
export class DetailsParticipantComponent implements OnInit {
  update : boolean = true ;
  participantID;
  selectedParticipant;
  urlEdit;
  stampClass;
  statusCut;
  company;
  countryCompany;
  displayOff;
  companyID;

  constructor(
    private route: ActivatedRoute, 
    private ParticipantService: ParticipantService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => { // Recupère l'id pour faire appel api ?
      this.participantID = params.get('id');
    });


    this.ParticipantService.getParticipantByID(this.participantID).subscribe(response => {
      this.selectedParticipant = response;
    }, error => {
      console.log(error);
    });

    this.displayOff = false;
  }

}
