import { Component, OnInit } from '@angular/core';
import { ParamMap, ActivatedRoute } from '@angular/router';
import { GetDataService } from '../../../core/http/get-data.service'

@Component({
  selector: 'app-update-company',
  templateUrl: './update-company.component.html',
  styleUrls: ['./update-company.component.scss']
})
export class UpdateCompanyComponent implements OnInit {

  constructor( private route: ActivatedRoute, private getDataService: GetDataService) { }
  idCompany;
  selectedCompany;
  idCompanySelected;
  update: boolean = false;
  
  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => { // Recupère l'id pour faire appel api ?
      this.idCompany = params.get('id');
    })
    
    this.getDataService.getCompanyByID(this.idCompany).subscribe( response => {
      this.selectedCompany = response;
    });
    
  }

}
