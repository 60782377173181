<div class="mb-3 userCard pb-0">
    <div class="col-md-12 dp-flex-justy-beetw p-0">
        <div class="p-0 col-md-5" style="display: flex;">
            <a placement="top" popoverClass="popover-custom-light" ngbPopover="View details" triggers="mouseenter:mouseleave" [routerLink]="url_details_hotel">
                <h3 class="userName">{{data.hotelName}}</h3>
            </a>
            <span placement="top" popoverClass="popover-custom-light popover-width" ngbPopover="Edit contact" triggers="mouseenter:mouseleave"
            [routerLink]="url_edit_hotel">
                <svg class="svgEdit" id="svgEdit" style="cursor:pointer;margin-top: -5px;fill: #fffefb;margin-left: 15px;overflow: visible"
                height="24px" width="24px">
                    <use xlink:href="#edit"/>
                </svg>
            </span>
        </div>
        <div class="p-0 col-md-2">
            <span>Nbr night(s) : {{nbr_nights}}</span>
            <span placement="top" popoverClass="popover-custom-light popover-width" ngbPopover="Add night(s)" triggers="mouseenter:mouseleave"
            [routerLink]="url_add_night">
                <svg class="svgAdd" id="svgAdd" style="cursor:pointer;margin-top: -5px;fill: #fffefb;margin-left: 15px;overflow: visible"
                height="24px" width="24px">
                    <use xlink:href="#plus"/>
                </svg>
            </span>
        </div>
        <div class="p-0 col-md-2">
            <span>Nbr room(s) : {{nbr_rooms}}</span>
            <span placement="top" popoverClass="popover-custom-light popover-width" ngbPopover="Add room(s)" triggers="mouseenter:mouseleave"
            [routerLink]="url_add_night">
                <svg class="svgAdd" id="svgAdd" style="cursor:pointer;margin-top: -5px;fill: #fffefb;margin-left: 15px;overflow: visible"
                height="24px" width="24px">
                    <use xlink:href="#plus"/>
                </svg>
            </span>
        </div>
        <div class="row col-md-3">
            <h5 class="col-md-10 ellipsis" style="text-align: end; font-family: 'nowayregular'">{{data.country}}</h5>
            <div class="col-md-2 justify-content-end btn-group dropleft p-0">
                <span id="dropdownMenuButton6" data-toggle="dropdown" placement="right" popoverClass="popover-custom-light popover-width" ngbPopover="More option" triggers="mouseenter:mouseleave">
                    <svg height="24px" width="24px" aria-haspopup="true" aria-expanded="false">
                        <use xlink:href="#more-vertical" />
                    </svg>
                </span>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" [routerLink]="url_edit_hotel" placement="left" popoverClass="popover-custom-light" ngbPopover="Edit hotel" triggers="mouseenter:mouseleave" i18n>Edit hotel</a>
                    <a class="dropdown-item" data-toggle="#deleteModal" (click)="modalDeleteHotel(data.idHotel)" placement="left" popoverClass="popover-custom-light" ngbPopover="Delete hotel" triggers="mouseenter:mouseleave" i18n>Delete hotel</a>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!collapse" class="row justify-content-between l2 m-0">
        <p *ngIf="short_desc == ''" class="description mb-0">&nbsp;</p>
        <p *ngIf="!short_desc == ''" class="description mb-0">{{short_desc}}</p>
        <svg *ngIf="cut_desc" class="show_more" (click)="showMore()" placement="right" popoverClass="popover-custom-light" ngbPopover="Show more" triggers="mouseenter:mouseleave">
            <use xlink:href="#right"/>
        </svg>
    </div>
    <div *ngIf="collapse" class="row justify-content-between l2 m-0">
        <p class="description mb-0">{{data?.description}}</p>
        <svg class="show_more" (click)="showMore()" placement="right" popoverClass="popover-custom-light" ngbPopover="Show less" triggers="mouseenter:mouseleave">
            <use xlink:href="#left"/>
        </svg>
    </div>
    <hr class="ml-0 mr-0">
    <div class="row col-md-12 footer justify-content-between p-0 m-0">
        <p i18n class="p-0 col-md-2"><b>CITY:</b> {{data.city}}</p>
        <p i18n class="p-0 col-md-2"><b>ZIPCODE:</b> {{data.zipCode}}</p>
        <p i18n class="p-0 col-md-2"><b>MOBILE:</b> {{data.mobile}}</p>
        <p i18n class="p-0 col-md-2"><b>FAX:</b> {{data.fax}}</p>
        <p i18n class="p-0 col-md-4"><b>URL:</b> {{data.url}}</p>
    </div>
</div>
<div class="p-4 already_exist modal" id="deleteModal{{data.idHotel}}" tabindex="-1" aria-labelledby="existingModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="">
        <div class="">
            <h4 class="mb-4 text-align-center" i18n>Delete Hotel : {{data.hotelName}}</h4>
        </div>
        <hr class="mt-2 mb-3 separator" style="background-color: #84C000;">
        <div class="dp-flex-justy-beetw">
            <div class="p-3" style="border: 1px solid #aeaeae;">
                <h5 class="" i18n>Hotel</h5>
                <ul class="p-0 m-0" style="list-style: none;">
                    <li i18n>Name : {{data?.hotelName}}</li>
                    <li i18n>Country : {{data?.country}}</li>
                    <li i18n>City : {{data?.city}}</li>
                    <li i18n>Zipcode : {{data?.zipCode}}</li>
                    <li i18n>Mobile : {{data?.mobile}}</li>
                    <li i18n><b>Id : {{data?.idHotel}}</b></li>
                </ul>
            </div>
            <div class="ml-4 mr-4 arrow_middle">
                <div class="mt-2 pt-2 pr-2 pl-2 border_arrow_edit" data-dismiss="modal">
                    <a href="" class="link_edit">
                    <svg class="mb-2" id="svgAR" height="24px" width="24px">
                        <use class="arrow_edit" xlink:href="#arrow-left"/>
                    </svg>
                    <span>Cancel</span>
                    </a>
                </div>
                <div class="mt-2 pt-2 pr-2 pl-2 border_arrow_delete" data-dismiss="modal" (click)="deleteHotel(data.idHotel)">
                    <a href="" class="link_delete">
                    <svg class="mb-2" id="svgAL" height="24px" width="24px">
                        <use class="arrow_delete" xlink:href="#delete"/>
                    </svg>
                    <span>Delete hotel</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>