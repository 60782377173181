import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailingService {

  public listOfMailTemplates = [
    {
      templateId: 0,
      templateType: '',
      templateName: '',
      templateSubject: '',
      templateBody: "<svg (click)='isCollapsed = !isCollapsed' [attr.aria-expanded]='!isCollapsed' aria-controls='collapseExample' id='AddThings' height='44px' width='44px' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' style='margin-left: 15px; margin-top: 90px; cursor: pointer'><use xlink:href='#plus' class='fill_green' /> </svg>"
    },
    {
      templateId: 1,
      templateType: 'Invitation',
      templateName: '1 - EC Invitations',
      templateSubject: 'Here is your invitation to our event',
      templateBody: '<p>Invitation</p><p><br>&nbsp;</p><p>&nbsp;</p><p><br>&nbsp;</p><p>Dear [[First Name]],</p><p>We are delighted to invite you to register for the 15th edition of The Event Catalyst Event. Please provide all your information <strong>before the 23rd May 2020</strong>.</p><p><br>&nbsp;</p><p>REGISTER HERE</p><p><br>&nbsp;</p><p>This conference is a unique opportunity to meet over 90 European Small and Mid-Cap Pan-European companies, most of them represented by Board Members/Senior Executives.</p><p>We look forward to welcoming you in Paris!</p><p><br>&nbsp;</p><p>The Event Catalyst team</p><p><a href="mailto:contact@eventcatalyst.com">contact@eventcatalyst.com</a></p>'
    },
    {
      templateId: 2,
      templateType: 'Agenda',
      templateName: '2 - Meeting preferences',
      templateSubject: 'Pls fill your meetings preferences',
      templateBody: '<p>Meeting preferences</p><p>&nbsp;</p><p><br>&nbsp;</p><p>Dear [[First Name]],</p><p><br>&nbsp;</p><p>We are thrilled to welcome you in Paris for the Event Catalyst Event!</p><p>At this stage, we invite you to indicate your preferences for the one-to-one meetings in your personal account. We ask that you submit your preferences before <strong>Tuesday, July 31st, 6pm</strong> CET (Paris time).</p><p>Past this deadline, your preferences will not be taken into consideration.</p><p>To indicate your preferences, in the one-to-one meetings section of your personal account, kindly click on “pre-select” on the “Meeting Preferences” side of the page. Then, please select and rank up to 30 companies you wish to meet during the one-to-one meetings to help secure a maximum of matches for your set of meetings.</p><p>If you need any help with the selection module, kindly use the tutorial below:</p><p>TUTORIAL</p><p>We will do our best to take into consideration all participants\' preferences.</p><p>Should the form not be completed by Saturday, Tuesday, July 31st, 6pm CET (Paris time), you will be assigned a random set of meetings.</p><p>We are happy to announce that 95 Partners have registered so far.</p><p>Selections sent within emails will not be taken into account.</p><p>We thank you very much in advance and look forward to seeing you in Paris very soon.</p><p><br>&nbsp;</p><p>Please log in with the same credentials you created during your registration.</p><p>PERSONAL ACCOUNT</p><p>We look forward to welcoming you in Paris!</p><p>The Event Catalyst team</p><p><a href="mailto:contact@eventcatalyst.com">contact@eventcatalyst.com</a></p>'
    },
    {
      templateId: 3,
      templateType: 'Agenda',
      templateName: '3 - Hotel allocation',
      templateSubject: 'Find your hotel allocation !',
      templateBody: '<body><img alt="" src="http://ecv5-test.eventcatalyst.io/assets/header_fake_event.png" width="100%" /><p>&nbsp;</p><p><br>&nbsp;</p><p>Dear [[First Name]],</p><p><br>&nbsp;</p><p>We are thrilled to welcome you in Paris for the Event Catalyst Event!</p><p>At this stage, we invite you to indicate your preferences for the one-to-one meetings in your personal account. We ask that you submit your preferences before <strong>Tuesday, July 31st, 6pm</strong> CET (Paris time).</p><p>Past this deadline, your preferences will not be taken into consideration.</p><p>To indicate your preferences, in the one-to-one meetings section of your personal account, kindly click on “pre-select” on the “Meeting Preferences” side of the page. Then, please select and rank up to 30 companies you wish to meet during the one-to-one meetings to help secure a maximum of matches for your set of meetings.</p><p>If you need any help with the selection module, kindly use the tutorial below:</p><p>TUTORIAL</p><p>We will do our best to take into consideration all participants\' preferences.</p><p>Should the form not be completed by Saturday, Tuesday, July 31st, 6pm CET (Paris time), you will be assigned a random set of meetings.</p><p>We are happy to announce that 95 Partners have registered so far.</p><p>Selections sent within emails will not be taken into account.</p><p>We thank you very much in advance and look forward to seeing you in Paris very soon.</p><p><br>&nbsp;</p><p>Please log in with the same credentials you created during your registration.</p><p>PERSONAL ACCOUNT</p><p>We look forward to welcoming you in Paris!</p><p>The Event Catalyst team</p><p><a href="mailto:contact@eventcatalyst.com">contact@eventcatalyst.com</a></p></body>'
    },
    {
      templateId: 4,
      templateType: 'Recap',
      templateName: '4 - EC Recap',
      templateSubject: 'Recap for the event',
      templateBody: '<p>Meeting preferences</p><p>&nbsp;</p><p><br>&nbsp;</p><p>Dear [[First Name]],</p><p><br>&nbsp;</p><p>We are thrilled to welcome you in Paris for the Event Catalyst Event!</p><p>At this stage, we invite you to indicate your preferences for the one-to-one meetings in your personal account. We ask that you submit your preferences before <strong>Tuesday, July 31st, 6pm</strong> CET (Paris time).</p><p>Past this deadline, your preferences will not be taken into consideration.</p><p>To indicate your preferences, in the one-to-one meetings section of your personal account, kindly click on “pre-select” on the “Meeting Preferences” side of the page. Then, please select and rank up to 30 companies you wish to meet during the one-to-one meetings to help secure a maximum of matches for your set of meetings.</p><p>If you need any help with the selection module, kindly use the tutorial below:</p><p>TUTORIAL</p><p>We will do our best to take into consideration all participants\' preferences.</p><p>Should the form not be completed by Saturday, Tuesday, July 31st, 6pm CET (Paris time), you will be assigned a random set of meetings.</p><p>We are happy to announce that 95 Partners have registered so far.</p><p>Selections sent within emails will not be taken into account.</p><p>We thank you very much in advance and look forward to seeing you in Paris very soon.</p><p><br>&nbsp;</p><p>Please log in with the same credentials you created during your registration.</p><p>PERSONAL ACCOUNT</p><p>We look forward to welcoming you in Paris!</p><p>The Event Catalyst team</p><p><a href="mailto:contact@eventcatalyst.com">contact@eventcatalyst.com</a></p>'
    },
    {
      templateId: 5,
      templateType: 'Recap',
      templateName: '5 - EC Recap2',
      templateSubject: 'Recap for the event',
      templateBody: '<p>Meeting preferences</p><p>&nbsp;</p><p><br>&nbsp;</p><p>Dear [[First Name]],</p><p><br>&nbsp;</p><p>We are thrilled to welcome you in Paris for the Event Catalyst Event!</p><p>At this stage, we invite you to indicate your preferences for the one-to-one meetings in your personal account. We ask that you submit your preferences before <strong>Tuesday, July 31st, 6pm</strong> CET (Paris time).</p><p>Past this deadline, your preferences will not be taken into consideration.</p><p>To indicate your preferences, in the one-to-one meetings section of your personal account, kindly click on “pre-select” on the “Meeting Preferences” side of the page. Then, please select and rank up to 30 companies you wish to meet during the one-to-one meetings to help secure a maximum of matches for your set of meetings.</p><p>If you need any help with the selection module, kindly use the tutorial below:</p><p>TUTORIAL</p><p>We will do our best to take into consideration all participants\' preferences.</p><p>Should the form not be completed by Saturday, Tuesday, July 31st, 6pm CET (Paris time), you will be assigned a random set of meetings.</p><p>We are happy to announce that 95 Partners have registered so far.</p><p>Selections sent within emails will not be taken into account.</p><p>We thank you very much in advance and look forward to seeing you in Paris very soon.</p><p><br>&nbsp;</p><p>Please log in with the same credentials you created during your registration.</p><p>PERSONAL ACCOUNT</p><p>We look forward to welcoming you in Paris!</p><p>The Event Catalyst team</p><p><a href="mailto:contact@eventcatalyst.com">contact@eventcatalyst.com</a></p>'
    },
  ];

  public listOfMailTypes = ['Invitation', 'Agenda', 'Relance', 'Recap', 'Appel à projet', 'Save the date', 'Confirmation', 'Remerciements'];


  public listOfIdList = [
    { listName : 'ID list 1',
      listId: 0,
      ids : '0001,0002,0003,0004,0005,0006,0007,0008,0009'},
    { listName : 'ID list 2',
      listId: 1,
      ids : '0011,0012,0013,0014,0015,0016,0017,0018,0019'},
  ];

  public listOfFiltersList = [
    { listName: 'Fr confirmed guests',
      listId: 0,
      participantType: 'Guests',
      participantCountry: 'France',
      participantLangage: 'FR',
      participantGuestCategory: 'All',
      participantPartnersCategory: 'All',
      participantStatus: 'Confirmed',
      participantPass: 'All',
      participantPrioLvl: 'All',
      participantDomain: 'All',
      participantProfileUpdate: 'Ignore',
      participantCompanyUpdate: 'Ignore',
      participantCCDebit: 'Ignore',
      participantOne: 'Ignore'
     },
     { listName: 'test list',
     listId: 1,
     participantType: 'All',
     participantCountry: 'China',
     participantLangage: 'EN',
     participantGuestCategory: 'All',
     participantPartnersCategory: 'All',
     participantStatus: 'Invited',
     participantPass: 'Pass P1',
     participantPrioLvl: '0',
     participantDomain: 'All',
     participantProfileUpdate: 'Ignore',
     participantCompanyUpdate: 'Ignore',
     participantCCDebit: 'Ignore',
     participantOne: 'Yes'
    },
    { listName: 'test filter list 2',
    listId: 2,
    participantType: 'Partners',
    participantCountry: 'All',
    participantLangage: 'EN',
    participantGuestCategory: 'All',
    participantPartnersCategory: 'Hotel Partner',
    participantStatus: 'Invited',
    participantPass: 'All',
    participantPrioLvl: 'All',
    participantDomain: 'All',
    participantProfileUpdate: 'Yes',
    participantCompanyUpdate: 'No',
    participantCCDebit: 'Ignore',
    participantOne: 'Ignore'
   },
];

public listOfMailing = [
  {
    mailingId : '472d9683a601fc98366b92c442c5069e',
    fromAdress: 'invite@it-distri.com',
    subject: 'Register Now for DISTREE Online',
    idlist : [123, 456, 789, 987],
    imageTracking: true,
    author: 'Admin',
    sentDate: '03/03/21',
    sentHour: '10:30',
    nbMessages: '4',
    nbMessagesOpen: '3',
  },
  {
    mailingId : '1a7fb82cec94e509b5597fd8195df729',
    fromAdress: 'invite@it-distri.com',
    subject: 'Thank you for joining DISTREE Online – Content Replay Available',
    idlist : [123, 456, 789, 987],
    imageTracking: true,
    author: 'Admin',
    sentDate: '18/02/21',
    sentHour: '17:30',
    nbMessages: '4',
    nbMessagesOpen: '3',
  },
  {
    mailingId : '472d9683a601fc98366b92c442c5069e',
    fromAdress: 'invite@it-distri.com',
    subject: 'Register Now for DISTREE Online',
    idlist : [123, 456, 789, 987],
    imageTracking: true,
    author: 'Admin',
    sentDate: '03/03/21',
    sentHour: '10:30',
    nbMessages: '4',
    nbMessagesOpen: '3',
  },
  {
    mailingId : '1a7fb82cec94e509b5597fd8195df729',
    fromAdress: 'invite@it-distri.com',
    subject: 'Thank you for joining DISTREE Online – Content Replay Available',
    idlist : [123, 456, 789, 987],
    imageTracking: true,
    author: 'Admin',
    sentDate: '18/02/21',
    sentHour: '17:30',
    nbMessages: '4',
    nbMessagesOpen: '3',
  },
  {
    mailingId : '472d9683a601fc98366b92c442c5069e',
    fromAdress: 'invite@it-distri.com',
    subject: 'Register Now for DISTREE Online',
    idlist : [123, 456, 789, 987],
    imageTracking: true,
    author: 'Admin',
    sentDate: '03/03/21',
    sentHour: '10:30',
    nbMessages: '4',
    nbMessagesOpen: '3',
  },
  {
    mailingId : '1a7fb82cec94e509b5597fd8195df729',
    fromAdress: 'invite@it-distri.com',
    subject: 'Thank you for joining DISTREE Online – Content Replay Available',
    idlist : [123, 456, 789, 987],
    imageTracking: true,
    author: 'Admin',
    sentDate: '18/02/21',
    sentHour: '17:30',
    nbMessages: '4',
    nbMessagesOpen: '3',
  },
  {
    mailingId : '472d9683a601fc98366b92c442c5069e',
    fromAdress: 'invite@it-distri.com',
    subject: 'Register Now for DISTREE Online',
    idlist : [123, 456, 789, 987],
    imageTracking: true,
    author: 'Admin',
    sentDate: '03/03/21',
    sentHour: '10:30',
    nbMessages: '4',
    nbMessagesOpen: '3',
  },
  {
    mailingId : '1a7fb82cec94e509b5597fd8195df729',
    fromAdress: 'invite@it-distri.com',
    subject: 'Thank you for joining DISTREE Online – Content Replay Available',
    idlist : [123, 456, 789, 987],
    imageTracking: true,
    author: 'Admin',
    sentDate: '18/02/21',
    sentHour: '17:30',
    nbMessages: '4',
    nbMessagesOpen: '3',
  },
  {
    mailingId : '472d9683a601fc98366b92c442c5069e',
    fromAdress: 'invite@it-distri.com',
    subject: 'Register Now for DISTREE Online',
    idlist : [123, 456, 789, 987],
    imageTracking: true,
    author: 'Admin',
    sentDate: '03/03/21',
    sentHour: '10:30',
    nbMessages: '4',
    nbMessagesOpen: '3',
  },
  {
    mailingId : '1a7fb82cec94e509b5597fd8195df729',
    fromAdress: 'invite@it-distri.com',
    subject: 'Thank you for joining DISTREE Online – Content Replay Availables',
    idlist : [123, 456, 789, 987],
    imageTracking: true,
    author: 'Admin',
    sentDate: '18/02/21',
    sentHour: '17:30',
    nbMessages: '4',
    nbMessagesOpen: '3',
  },
  {
    mailingId : '1a7fb82cec94e509b5597fd8195df729',
    fromAdress: 'invite@it-distri.com',
    subject: 'Register Now for DISTREE Online',
    idlist : [123, 456, 789, 987],
    imageTracking: true,
    author: 'Admin',
    sentDate: '18/02/21',
    sentHour: '17:30',
    nbMessages: '4',
    nbMessagesOpen: '3',
  },
]

public listOfFollowupContacts = [
  {
    emailAdress: "valentin@eventcatalyst.com",
    firstOpened: "03/03/21 10:34",
    lastOpened: "",
    totalOpening: 1,
  },
  {
    emailAdress: "valentin@eventcatalyst.com",
    firstOpened: "03/03/21 10:34",
    lastOpened: "",
    totalOpening: 1,
  },
  {
    emailAdress: "valentin@eventcatalyst.com",
    firstOpened: "03/03/21 10:34",
    lastOpened: "",
    totalOpening: 1,
  },
  {
    emailAdress: "valentin@eventcatalyst.com",
    firstOpened: "03/03/21 10:34",
    lastOpened: "",
    totalOpening: 1,
  },
  {
    emailAdress: "valentin@eventcatalyst.com",
    firstOpened: "03/03/21 10:34",
    lastOpened: "",
    totalOpening: 1,
  },
  {
    emailAdress: "valentin@eventcatalyst.com",
    firstOpened: "03/03/21 10:34",
    lastOpened: "",
    totalOpening: 1,
  },
  {
    emailAdress: "valentin@eventcatalyst.com",
    firstOpened: "03/03/21 10:34",
    lastOpened: "",
    totalOpening: 1,
  },
  {
    emailAdress: "valentin@eventcatalyst.com",
    firstOpened: "03/03/21 10:34",
    lastOpened: "",
    totalOpening: 1,
  },
  {
    emailAdress: "valentin@eventcatalyst.com",
    firstOpened: "03/03/21 10:34",
    lastOpened: "",
    totalOpening: 1,
  },
  {
    emailAdress: "valentin@eventcatalyst.com",
    firstOpened: "03/03/21 10:34",
    lastOpened: "",
    totalOpening: 1,
  },
  {
    emailAdress: "valentin@eventcatalyst.com",
    firstOpened: "03/03/21 10:34",
    lastOpened: "",
    totalOpening: 1,
  },
]


  constructor() { }

}
