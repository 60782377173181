import { Component, OnInit, Input, Output, EventEmitter, HostListener} from '@angular/core';
import { EventService } from '../../../../core/http/event.service'
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-form-search-select',
  inputs: ['cssClass'],
  templateUrl: './form-search-select.component.html',
  styleUrls: ['./form-search-select.component.scss']
})
export class FormSearchSelectComponent implements OnInit {

  @Output() afterChange = new EventEmitter();
  @Input() id: any = 0;

  constructor(
    private eventService: EventService,
    private datePipe: DatePipe
  ) {}

  listOfAllEvents: any;
  listSearch: any;
  valueEvent: number = 0;
  textEvent: string;
  shown: boolean = false;
  searchInput: string;
  keyword: string;
  onChange: any = () => { };

  ngOnInit() {
    this.eventService.getAllEvents().subscribe(res => {
      this.listOfAllEvents = res;
      this.listSearch = res;
      if (this.valueEvent == 0) {
        this.textEvent = this.datePipe.transform(this.listOfAllEvents[this.valueEvent].date, 'yyyy-MM-dd') + " - " + this.listOfAllEvents[this.valueEvent].label;
        this.valueEvent = this.listOfAllEvents[this.valueEvent].id;
      }
    });
  }
  show() {
    this.shown = this.shown ? false : true;
    this.keyword = '';
    this.search(this.keyword);
  }
  select(item: any) {
    this.onChange();
    this.valueEvent = item.id;
    this.textEvent = this.datePipe.transform(item.date, 'yyyy-MM-dd') + " - " + item.label;
    this.shown = false;
    this.keyword = '';
    this.search(this.keyword);
    const event = {
      id: this.valueEvent,
      label: this.textEvent
    }
    this.afterChange.emit(event);
  }
  search(e: any) {
    const val = e.toLowerCase();
    if (this.listOfAllEvents) {
      const temp = this.listOfAllEvents.filter((x: any) => {
        if (x.label.toLowerCase().indexOf(val) !== -1 || !val) {
          return x;
        }
      });
      this.listSearch = temp;
    }
  }
  @HostListener('document:click', ['$event']) onClick(e: any) {
    if (!document.getElementById("Search-Dropdown" + this.id).contains(e.target)) {
      this.shown = false;
      this.keyword = '';
      this.search(this.keyword);
    }
  }
}
