<div class="card">
    <div class="card-body">
      <h4 class="card-title mb-0" [routerLink]="data.urlAdd" i18n>{{data.title}}</h4>
    </div>
    <!-- Add Button -->
    <div class="bottomRows">
        <div style="display: flex;width: 100%;height: 100%; justify-content: center; align-items: center;">
            <div [routerLink]=data.urlAdd style="background-color: #84c000; width: 83px; border-radius: 400%;color: #FFF;font-size: 30px;font-weight:bold;height: 83px;display: flex;align-items: center;justify-content: center;cursor: pointer">
                <span>+</span>
            </div>
        </div>
    </div>
</div>
