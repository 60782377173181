<div class="container-fluid pb-4 font-color">

  <div class="maincontainer">
    <div class="row">
      <div class="col-xl-12">

        <h6 style="font-family: 'nowayRegular', sans-serif" class="pt-4">TEMPLATE</h6>
        <p>Vestibulum tempus sed nulla finibus maximus.</p>

        <div class="card mt-4">
          <div class="card-body" style="height: 414px">

            <ckeditor [editor]="editor" data="<p>Hello, world!</p>"></ckeditor>

            <button class="btn float-right mt-2" style="background-color: #84C000; color: white">Save as new template</button>
          </div>
        </div>

        <button class="btn float-right mt-4" style="background-color: #84C000; color: #000">Manage contacts</button>
        <button class="btn float-right mt-4 mr-3"
                style="background-color: #000; border-color: #84C000; color: #84C000">
          Cancel
        </button>

      </div>
    </div>
  </div>

</div>

