<div class="maincontainer">
    <div class="dp-flex-justy-beetw tab-content mt-4 pb-2 text-align-center" id="nav-tabContent">
        <span class="w_12">{{data?.date.substring(0,10)}}</span>
        <span class="w_22">{{data?.typeParticipant.toString().replaceAll(",", " - ")}}</span>
        <span class="w_10">{{data.nbrRoom}}</span>
        <span class="w_10">{{data.nbrSurbook}}</span>
        <span class="w_12">{{data.roomType}}</span>
        <span class="w_12">{{data?.nbrBook}}</span>
        <span class="w_10">{{data?.nbrFree}}</span>
        <span class="w_12">0</span>
    </div>   
</div>