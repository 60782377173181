<div *ngIf="!mobileRes">         
  <app-fil-ariane-list-users (filterStatut)="checkStatut($event)"></app-fil-ariane-list-users>
  <div id="listUsersDesktop">
    <div class="maincontainer">
      <div *ngIf="filterStatut">
        <form [formGroup]="filterFormGroup" (keyup)="changeFilter()" (change)="changeFilter()">
          <div style="background-color: #f9f8f4;" class="row col filters">
            <div style="padding-left: 15px;" class="filtersCenter mt-3">
              <div style="width: 25%;" class="select-custom">
                <label style="width: 25%;" i18n>Country</label>
                <select class="form-control-sm" id="IdCountry" formControlName="idCountry">
                  <option value="0" i18n>Please select</option>
                  <option *ngFor="let country of this.listOfCountry" [value]="country.id">
                    {{country.label}}
                  </option>
                </select>
              </div>
              <div style="width: 25%" class="select-custom">
                <label style="width: 25%;" i18n>Type</label>
                <select class="form-control-sm" id="IdType" formControlName="idType">
                  <option value="0" i18n>Please select</option>
                  <option *ngFor="let type of this.listOfType" [value]="type.id">
                    {{type.label}}
                  </option>
                </select>
              </div>
              <div style="width: 25%;" class="select-custom">
                <label style="width:25%" i18n>Vip</label>
                <select class="form-control-sm" id="Vip" formControlName="vip">
                  <option value="-1" i18n>Please select</option>
                  <option value="1" i18n>Yes</option>
                  <option value="0" i18n>No</option>
                </select>
              </div>
              <div style="width:25%" class="select-custom">
                <label style="width:25%" i18n>Main</label>
                <select class="form-control-sm" id="Main" formControlName="main">
                  <option [value]="-1" i18n>Please select</option>
                  <option [value]="1" i18n>Yes</option>
                  <option [value]="0" i18n>No</option>
                </select>
              </div>
            </div>
            <div class="filtersCenter" style='border-top:none; padding-left: 15px'>
              <div style="width:25%" class="select-custom">
                <label style="width: 25%;" i18n>Company</label>
                <select class="form-control-sm" id="IdCountry" formControlName="idCompany">
                  <option value="0" i18n>Please select</option>
                  <option *ngFor="let company of this.listOfCompany" [value]="company.id">
                    {{company.label}}
                  </option>
                </select>
              </div>
              <div style="width:50%">
                <label style="width:12.5%" i18n>Search</label>
                <input class="input-custom" id="SearchName" formControlName="search">
              </div>
              <div style="width:25%; text-align: center;">
                <button type="button" (click)="resetFilter()"
                  class="btn btn-success button--color btn-size btn-responsive" i18n>Clear
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div style="width: auto!important;" class="row headerList justify-content-between">
        <div class="col-3 selectAll">
        </div>
        <div class="pagination" style="white-space: nowrap;">
          <pagination-controls (pageChange)="pageChange($event)" class="myPagination" previousLabel="" nextLabel=""
            maxSize="5"></pagination-controls>
          <div style="padding-right: 25px;" class="nbItems">
            <span i18n>Show</span>
            <div class="select-custom">
              <select [(ngModel)]="nbItems" (ngModelChange)='onItemsChange($event);'>
                <option selected [value]="10">10</option>
                <option [value]="50">50</option>
                <option [value]="100">100</option>
                <option [value]="300">300</option>
              </select>
            </div>
            <span i18n>lines</span>
          </div>
        </div>
      </div>
      <div *ngIf="listOfTypeParticipant" class="list">
        <ul> 
          <li *ngFor="let item of listOfTypeParticipant | paginate: config">
            <app-user-card-contact-update [check]="check" (countUser)="userCount($event, item.idPerson)" [data]="item" ></app-user-card-contact-update>
          </li>
          <br>
        </ul>
      </div>
      <div *ngIf="!listOfTypeParticipant" class="list">
        <h3 i18n>No user found</h3>
      </div>
    </div>
  </div>
</div>
