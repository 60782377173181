import { Component, OnInit } from '@angular/core';
import { ParamMap, ActivatedRoute } from '@angular/router';
import { GetDataService } from '../../../../core/http/get-data.service'


@Component({
  selector: 'app-company-details-card',
  templateUrl: './company-details-card.component.html',
  styleUrls: ['./company-details-card.component.scss']
})
export class CompanyDetailsCardComponent implements OnInit {

  constructor(private route: ActivatedRoute, private getDataService: GetDataService) { }

  idCompany;
  selectedCompany;
  attendeesList;

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => { // Recupère l'id pour faire appel api ?
    this.idCompany = params.get('id');
  })
  this.selectedCompany = this.getDataService.companyList[this.idCompany - 1];
  // ICI RECUPERER LES INFOS DES ATTENDEES DE CETTE SOCIETE
  this.attendeesList = ['Arthur Jensen', 'Vincenzo Coccotti', 'Sam Lowry']
  }
}
