import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-progress-chart',
  templateUrl: './card-progress-chart.html',
  styleUrls: ['./card-progress-chart.scss']
})
export class CardProgressChart implements OnInit {

  @Input() data;

  complete;
  incomplete;
  total;
  taux;

  constructor() { }

  ngOnInit() {
    this.complete = this.data.complete;
    this.incomplete = this.data.incomplete;
    this.total = this.complete + this.incomplete;
    this.taux = this.complete / this.total;
  }

  tauxProgress(taux: number) {
    return Math.round(taux * 100);
  }

}
