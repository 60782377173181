import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TaskListService } from '../../../../core/http/task-list.service';
import { DatePipe, formatDate } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent implements OnInit {

  public modifTaskForm = this.fb.group(
    {
      id: [Math.random().toString(36).substr(2, 9)],
      dateCreate: [formatDate(new Date(), 'yyyy/MM/dd', 'en')],
      taskTitle: ['', [Validators.required]],
      statut: ['To do', Validators.required],
      taskContent: [''],
      taskComment: [''],
      assignedTo: ['', [Validators.required]],
      dueDate: [],
      priority: ['none', Validators.required],
    });

  public modifCommentForm = this.fb.group({
    comment: ['']
  });

  @Input()
  index;

  @Input()
  Task;

  @Output() dataChange: EventEmitter<any> = new EventEmitter();

  @Output() taskEdit: EventEmitter<any> = new EventEmitter();




  public users = [' Me', ' Emma', ' Jade', ' Jules', ' Louis', ' Gabriel', ' Louise', ' Anna', ' Admin1', ' Admin2'];

  // variables
  todayDate = { day: 0, month: 0, year: 0 };
  milestoneActivate;
  dateIsPassed = false;
  taskDone = false;
  iconClicked = false;
  displayTaskDetails = false;
  indexList;
  indexComment;
  userInitialName = [];
  titleCut;
  task;
  modalName;
  modal2Name;
  modal3Name;


  constructor(private taskListService: TaskListService,
              private fb: FormBuilder,
              private datePipe: DatePipe) {
  }

  ngOnInit() {
    // console.log(this.Task);
    this.iconClicked = false;
    this.task = this.Task;
    this.cutTitle();
    this.getTodaysDate();
    this.checkDateIsPassed();
    this.task.milestone === true ? this.milestoneActivate = true : this.milestoneActivate = false;
    this.task.statut === 'done' ? this.taskDone = true : this.taskDone = false;
    if (this.task.taskComment !== undefined && this.task.taskComment.length > 0) {
      this.task.taskComment.forEach(element => {
        const value = element.user.substr(0, 1);            // Récupere l'initiale de l'user qui a comment
        this.userInitialName.push(value);
      });
    }
  }

  editTask(task) {
    this.taskEdit.emit(task);
    this.taskListService.taskTodo.splice(this.index, 1);
    this.taskListService.TaskInEdition.push(task);
  }

  swipMilestone(task) {
    if (task.milestone) {
      this.taskListService.milestoneTodo.splice(this.index, 1);
      task.milestone = !task.milestone;
      this.taskListService.taskTodo.push(task);
    } else {
      this.taskListService.taskTodo.splice(this.index, 1);
      task.milestone = !task.milestone;
      this.taskListService.milestoneTodo.push(task);
    }
  }

  cutTitle() {
    if (this.task.taskTitle.length > 25) {
      this.titleCut = this.task.taskTitle.substr(0, 25) + '...';
    } else {
      this.titleCut = this.task.taskTitle;
    }
  }

  swipTask1(task) {
    this.dataChange.emit();
    this.task.statut = 'inProgress';
    this.taskListService.taskIP.push(task);
    this.taskListService.taskTodo.splice(this.index, 1);
    this.taskListService.allTasks.splice(this.index, 1);
    this.taskListService.allTasks.push(task);
    this.taskListService.sortData();
  }

  swipTask2(task) {
    this.dataChange.emit();
    this.task.statut = 'done';
    this.task.doneBy.push('Admin1');
    this.task.doneDate = this.todayDate;
    this.task.priorityNumber = '0';
    this.taskListService.taskDone.push(task);
    this.taskListService.taskIP.splice(this.index, 1);
    this.taskListService.allTasks.splice(this.index, 1);
    this.taskListService.allTasks.push(task);

    this.taskListService.sortData();
  }

  swipTask3(task) {
    this.dataChange.emit();
    this.task.statut = 'todo';
    this.task.doneBy.splice(1, 1);
    this.task.doneDate = '';
    this.taskListService.taskTodo.push(task);
    this.taskListService.taskDone.splice(this.index, 1);
    this.taskListService.allTasks.splice(this.index, 1);
    this.taskListService.allTasks.push(task);
    this.taskListService.sortData();
  }

  getTodaysDate() {
    const dayString = this.datePipe.transform(new Date(), 'dd');
    const day = Number(dayString);
    const monthString = this.datePipe.transform(new Date(), 'MM');
    const month = Number(monthString);
    const yearString = this.datePipe.transform(new Date(), 'yyyy');
    const year = Number(yearString);
    this.todayDate = { day, month, year };
  }

  displayTask() {
    this.displayTaskDetails = !this.displayTaskDetails;
  }

  checkDateIsPassed() {
    if (this.task.dueDate.year < this.todayDate.year) {
      return this.dateIsPassed = true;
    } if (this.task.dueDate.year === this.todayDate.year) {
      if (this.task.dueDate.month < this.todayDate.month) {
        return this.dateIsPassed = true;
      }if (this.task.dueDate.month === this.todayDate.month) {
        if (this.task.dueDate.day <= this.todayDate.day) {
          return this.dateIsPassed = true;
        }
        return this.dateIsPassed = false;
      }
      return this.dateIsPassed = false;
    }
  }

  patchFormDatas() {
    $(this.modalName).modal('hide');
    if (this.task.priority) {
      this.modifTaskForm.patchValue({
        taskTitle: this.task.taskTitle,
        taskContent: this.task.taskContent,
        assignedTo: this.task.assignedTo,
        dueDate: this.task.dueDate,
        priority: this.task.priorityLabel.toLowerCase(),
      });
    } else {
      this.modifTaskForm.patchValue({
        taskTitle: this.task.taskTitle,
        taskContent: this.task.taskContent,
        assignedTo: this.task.assignedTo,
        dueDate: this.task.dueDate,
        priority: 'none',
      });
    }
  }

  // modifComment(index) {
  //   $(this.modalName).modal('hide');
  //   // console.log('you are trying to edit task at index' + index);
  //   this.indexComment = index;
  //   this.modifCommentForm.patchValue({
  //     comment: this.task.taskComment[index].comment
  //   });
  // }

  // deleteComment(index) {
  //   if (confirm('Are you sure to delete this comment ?')) {
  //     this.task.taskComment.splice(index, 1);
  //     this.taskListService.taskTodo[this.index] = this.task;
  //   }
  //   // console.log(this.task);
  // }

  deleteTask() {
    if (confirm('Are you sure to delete task ' + this.task.taskTitle)) {
      this.task.statutClass = 'secondary';
      this.task.deleteBy = 'AdminX';         // ICI put l'user qui a delete
      this.task.deleteOn = formatDate(new Date(), 'yyyy/MM/dd', 'en');
      this.taskListService.taskTodo.splice(this.index, 1);
      this.taskListService.removedTasks.push(this.task);
    }
  }

  // onSubmit() {
  //   if (this.modifTaskForm.value.priority === 'none') {
  //     this.modifTaskForm.value.priority = false;
  //     this.modifTaskForm.value.priorityNumber = '0';
  //   } else if (this.modifTaskForm.value.priority === 'low') {
  //     this.modifTaskForm.value.priority = true;
  //     this.modifTaskForm.value.priorityLabel = 'Low';
  //     this.modifTaskForm.value.priorityClass = 'info';
  //     this.modifTaskForm.value.priorityNumber = '1';
  //   } else if (this.modifTaskForm.value.priority === 'medium') {
  //     this.modifTaskForm.value.priority = true;
  //     this.modifTaskForm.value.priorityLabel = 'Medium';
  //     this.modifTaskForm.value.priorityClass = 'warning';
  //     this.modifTaskForm.value.priorityNumber = '2';
  //   } else if (this.modifTaskForm.value.priority === 'high') {
  //     this.modifTaskForm.value.priority = true;
  //     this.modifTaskForm.value.priorityLabel = 'High';
  //     this.modifTaskForm.value.priorityClass = 'danger';
  //     this.modifTaskForm.value.priorityNumber = '3';
  //   }
  //   if (this.modifTaskForm.value.dueDate === null) {
  //     this.modifTaskForm.value.dueDate = {day: 0, month: 0, year: 0};
  //   }
  //   if (this.modifTaskForm.value.taskComment != null && this.modifTaskForm.value.taskComment.length > 0) {
  //     const com = this.modifTaskForm.value.taskComment;
  //     const commentObject = {
  //       user: 'Admin1',    // ICI METTRE LE PSEUDO DE L'USER
  //       dateComment: formatDate(new Date(), 'yyyy/MM/dd', 'en'),
  //       comment: com
  //     };
  //     this.task.taskComment.push(commentObject);
  //     this.modifTaskForm.value.taskComment = this.task.taskComment;
  //   } else {
  //     this.modifTaskForm.value.taskComment = this.task.taskComment;
  //   }
  //   this.task = this.modifTaskForm.value;
  //   this.taskListService.taskTodo[this.index] = this.modifTaskForm.value;
  //   if (this.modifTaskForm.value.statut === 'In progress') {
  //     this.taskListService.taskIP.push(this.task);
  //     this.taskListService.taskTodo.splice(this.index, 1);
  //     this.dataChange.emit();
  //   } else if (this.modifTaskForm.value.statut === 'Done') {
  //     this.taskListService.taskDone.push(this.task);
  //     this.taskListService.taskTodo.splice(this.index, 1);
  //     this.task.doneDate = this.todayDate;
  //     this.task.doneBy = [' Admin1'];
  //   }
  //   this.cutTitle();
  //   this.taskListService.sortData();
  //   // console.log(this.task);
  //   this.checkDateIsPassed();
  //   $(this.modal2Name).modal('hide');
  //   // $(this.modalName).modal('hide');
  //   // $(this.modalName).modal('show');
  // }

  // onSubmitComment() {
  //   this.task.taskComment[this.indexComment].comment = this.modifCommentForm.value.comment;
  //   this.task.taskComment[this.indexComment].dateComment = formatDate(new Date(), 'yyyy/MM/dd', 'en');
  //   this.task.taskComment[this.indexComment].user = 'Admin1'; // ICI mettre l'user qui a modifié le commentaire
  //   this.taskListService.taskTodo[this.index] = this.task;
  //   $(this.modal3Name).modal('hide');
  //   $(this.modalName).modal('show');
  //   // console.log(this.task);
  // }


  // drop(event: CdkDragDrop<string[]>) {
  //   moveItemInArray(this.taskListService.taskTodo, event.previousIndex, event.currentIndex);
  // }

}
