<!-- LISTE DESKTOP -->
<div *ngIf="!mobileRes">

    <app-fil-ariane (filterStatut)="checkStatut($event)"></app-fil-ariane>

    <div id="listUsersDesktop">

        <div class="row filters" *ngIf="filterStatut">
            <div class="filtersCenter mt-3">
                <span i18n>Country</span>
                <div class="select-custom">
                    <select class="form-control-sm">
                        <option i18n>Select...</option>
                    </select>
                </div>
                <span style="margin-left: 35px" i18n>Free</span>
                <div class="select-custom">
                    <select class="form-control-sm">
                        <option i18n>Select...</option>
                    </select>
                </div>
                <span style="margin-left: 35px" i18n>ID</span>
                <div class="select-custom">
                    <select class="form-control-sm">
                        <option i18n>Select...</option>
                    </select>
                </div>
                <button type="button" class="btn btn-success btn-size btn-responsive" i18n>Apply</button>
            </div>
        </div>

        <app-list-sort-by></app-list-sort-by>

        <div class="row headerList justify-content-between">

            <div class="col-3 selectAll">
                <label>
                    <input type="checkbox" id="selectAllCB">
                    <span class="cbLabel" i18n>Select all</span>
                </label>
                <span i18n>3 selected</span>
            </div>

            <div class="pagination" style="white-space: nowrap;">

                <pagination-controls (pageChange)="pageChange($event)" class="myPagination" previousLabel=""
                    nextLabel="" maxSize="5"></pagination-controls>

                <div class="nbItems">
                    <span i18n>Show</span>
                    <div class="select-custom">
                        <select [(ngModel)]="nbItems" (ngModelChange)='onItemsChange($event)'>
                            <option selected [value]="10">10</option>
                            <option [value]="50">50</option>
                            <option [value]="100">100</option>
                            <option [value]="300">300</option>
                            <option [value]="999999999">All</option>
                        </select>
                    </div>
                    <span i18n>lines</span>
                </div>

            </div>
        </div>

        <div class="list">
            <ul>
                <li *ngFor="let item of collection | paginate: config">
                    <app-user-card-desktop-v2 (countUser)="userCount($event)"></app-user-card-desktop-v2>
                    <!-- <app-user-card-desktop></app-user-card-desktop -->
                    <!--OLD VERSION-->
                </li>
                <br>
            </ul>
        </div>

    </div>
</div>
<!-- LISTE MOBILE -->
<div *ngIf="mobileRes" style="background-color: #f9f8f4;">
    <div id="listUsers">

        <app-fil-ariane (filterStatut)="checkStatut($event)" *ngIf="!filterStatut && count == 0"></app-fil-ariane>

        <div class="filtersMobile" *ngIf="filterStatut" [@slideInUpOnEnter] [@slideOutLeftOnLeave]>
            <app-filters-mobile (filterStatut)="checkStatut($event)"></app-filters-mobile>
        </div>

        <div *ngIf="!filterStatut">

            <!-- <div class="row justify-content-center">
                <div class="pagination">
                    <pagination-controls (pageChange)="pageChange($event)" 
                        class="myPagination" 
                        previousLabel="" 
                        nextLabel=""
                        maxSize="5"></pagination-controls>
                </div>
            </div> -->
            <!-- <div class="row justify-content-end selectAll">
                <span>Select all</span>
                <input type="checkbox">
            </div> -->
            <!-- <select class="custom-select" style="width: 20%" [(ngModel)]="nbItems" (ngModelChange)='onItemsChange($event)'>
                    <option selected value="10">10</option>
                    <option [value]="50">50</option>
                    <option [value]="100">100</option>
                    <option [value]="300">300</option>            
                    <option [value]="999999999">All</option>
                </select> -->

            <div class="list">
                <div *ngIf="count > 0" class="userCount">
                    <div i18n>
                        {{count}} user<span *ngIf="count > 1">s</span> selected
                    </div>

                    <div style="padding-top: 8px;">
                        <svg height="44px" width="44px">
                            <use xlink:href="#export" />
                        </svg>

                        <div class="btn-group dropup">
                            <svg id="dropdownMenuButton" height="44px" width="44px" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <use xlink:href="#mail" />
                            </svg>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" href="#" i18n>Send mail</a>
                                <a class="dropdown-item" href="#" i18n>Send SMS</a>
                            </div>
                        </div>

                        <div class="btn-group dropup">
                            <svg id="dropdownMenuButton2" height="44px" width="44px" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <use xlink:href="#action_h" />
                            </svg>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                <a class="dropdown-item" href="#" i18n>Change status</a>
                                <a class="dropdown-item" href="#" i18n>Change account manager</a>
                                <a class="dropdown-item" href="#" i18n>Swip participant type</a>
                                <a class="dropdown-item" href="#" i18n>Detach</a>
                                <a class="dropdown-item" href="#" i18n>Add VIP tag</a>
                                <a class="dropdown-item" href="#" i18n>Add MAIN tag</a>
                                <a class="dropdown-item" href="#" i18n>Delete users</a>
                                <a class="dropdown-item" href="#" i18n>Delete access password</a>
                            </div>
                        </div>
                    </div>
                </div>
                <ul [ngClass]=" count == 0 ? 'count0' : 'count1'">
                    <li *ngFor="let item of collection">
                        <!--| paginate: config-->
                        <app-user-card-mobile (countUser)="userCount($event)"></app-user-card-mobile>
                    </li>
                </ul>
            </div>
        </div>

    </div>
</div>