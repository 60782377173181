import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { RefService } from '../../../../core/http/ref.service';
import { CompanyService } from '../../../../core/http/company.service';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { fadeInDownOnEnterAnimation, fadeOutUpOnLeaveAnimation } from 'angular-animations';
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
  selector: 'app-company-add-form',
  templateUrl: './company-add-form.component.html',
  styleUrls: ['./company-add-form.component.scss'],
  animations: [fadeInDownOnEnterAnimation({ translate: '30px' }),
  fadeOutUpOnLeaveAnimation({ translate: '30px' }),
  ],

})
export class CompanyAddFormComponent implements OnInit {

  companyNameFormControl = new FormControl();
  listOfCountry: any;
  idCompanyExist: any;
  isCompanyExist: boolean;
  listOfCompanyExisting: any;
  countryName: string;

  constructor(
    private fb: FormBuilder,
    private refService: RefService,
    private router: Router,
    private toastr: ToastrService,
    private companyService: CompanyService
  ) {}

  public companyInformation = this.fb.group({
    companyName: ['', Validators.required],
    zipCode: ['', Validators.required],
    idCountry: ['', Validators.required]
  });

  ngOnInit() {
    this.refService.getCountry().subscribe( res => {
      this.listOfCountry = res;
    })
  }

  onSubmit() {
    for (let i = 0; this.listOfCountry.length > i; i++) {
      if (this.listOfCountry[i].id == this.companyInformation["value"].idCountry) {
        this.countryName = this.listOfCountry[i].label;
      }
    }
    this.companyService.createCompany(this.companyInformation["value"]).subscribe(res => {
      this.toastr.clear();
      this.toastr.success('Success', 'Company Create');
      this.router.navigateByUrl(`/admin/company/${res.idCompany}/edit`);
    }, (error) => {
        console.error(error);
        if (error.substring(0, 26) == "The company already exists") {
          this.isCompanyExist = true;
          $('#existingModal').modal('show');
          this.idCompanyExist = error.substring(27);
          this.companyService.getCompanyByID(this.idCompanyExist).subscribe(res => {
            this.listOfCompanyExisting = res;
          });
        }
    });
  }
  clickCancelCompany() {
    this.companyInformation.reset();
    this.companyInformation.patchValue({
      idCountry: ''
    });
  }
  editCompany() {
    this.router.navigateByUrl(`/admin/company/${this.idCompanyExist}/edit`);
  }
}
