import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-sort-by',
  templateUrl: './list-sort-by.component.html',
  styleUrls: ['./list-sort-by.component.scss']
})
export class ListSortByComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
