<div class="container" style="border:2px solid green;height:1400px">
    <div class="row" style="border:1px solid black; height:10%">
    </div>
    <div class="row" style="border: 1px solid black; height:90%">
        <div class="col-md-4" style="border:1px solid blue; height:100%">
        </div>
        <div class="col-md-8" style="border:1px solid blue; height:100%">
        </div>
    </div>
</div>
