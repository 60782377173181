<div class="container-fluid pb-4 pl-0 pr-0 font-color">
  
  <app-fil-ariane-emailing></app-fil-ariane-emailing>
  
  
  <div class="maincontainer">
    <mat-horizontal-stepper class="font-color" labelPosition="bottom" [linear]="isLinear" #stepper>
      <mat-step [stepControl]="firstFormGroup" [completed]="false">
        <ng-template matStepLabel><b i18n>Template</b></ng-template>

          <app-template-step [emailObject]="emailObject" (subject)="editSubject($event)"></app-template-step>

      </mat-step>
      <mat-step class="step" [completed]="false">
        <ng-template matStepLabel><b i18n>Mailing list</b></ng-template>

          <app-mailing-list-step [emailObject]="emailObject"></app-mailing-list-step>
          
      </mat-step>
      <mat-step [completed]="false">
        <ng-template matStepLabel><b i18n>Mailing recap</b></ng-template>

          <app-mailing-recap-step [emailObject]="emailObject"></app-mailing-recap-step>

      </mat-step>
    </mat-horizontal-stepper>
  </div>

</div>
