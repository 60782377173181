<div class="row tab-content">
  <form [formGroup]="addEventAgendaFormGroup" (ngSubmit)="onSubmit()">
    <div id="eventAgendaInformationRow" class="row mt-3 justify-content-center">
      <div class="col-md-6 col-12">
        <h6 class="nowayRegular mb-5 t-title" i18n>INFORMATION FONCTION</h6>
        <app-form-input>
          <label for="Function" class="label col-sm-5 col-form-label" i18n>Function</label>
          <input type="text" class="input form-control" id="Function" formControlName="function">
        </app-form-input>
        <app-form-input>
          <label for="FunctionShort" class="label col-sm-5 col-form-label" i18n>Function short</label>
          <input type="text" class="input form-control" id="Function" formControlName="functionShort">
        </app-form-input>
        <app-form-input>
          <label for="Comment" class="label col-sm-5 col-form-label" i18n>Comment</label>
          <input type="text" class="input form-control" id="Comment" formControlName="comment">
        </app-form-input>
        <app-form-select>
          <label for="IdVendor" class="label col-sm-5 col-form-label" i18n>Vendor</label>
          <select class="select form-control" id="IdVendor" formControlName="idVendor">
            <option value="0" i18n>Please select</option>
            <option *ngFor="let vendor of this.listOfVendor" [value]="vendor.id">
              {{vendor.label}}
            </option>
          </select>
        </app-form-select>
      </div>
      <div class="col-md-6 col-12">
        <div class="form-group row mr-0">
          <label for="IdParent" class="label col-sm-5 col-form-label" i18n>Parent function</label>
          <div class="col-sm-7 row justify-content-end">
            <select class="select form-control" id="IdParent" formControlName="idParent">
              <option value="0" i18n>Please select</option>
              <option *ngFor="let parent of this.listOfParent" [value]="parent.idHappening">
                {{parent.functionShort}}
              </option>
            </select>
          </div>
        </div>
        <!-- <DatePicker views="{['year', 'month', 'day']}"/> -->
        <div class="form-group col-12 pr-0 row mb-0">
          <label for="Date" class="pl-0 label col-md-4 col-form-label" i18n>Date</label>
          <mat-form-field class="col-md-6 ml-auto">
            <input matInput [matDatepicker]="da" name="date" formControlName="date" class="mat_imput_custom">
            <mat-datepicker-toggle matIconSuffix [for]="da"></mat-datepicker-toggle>
            <mat-datepicker #da></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-12 row">
          <label class="pl-0 label col-md-2 col-form-label" for="StartHour" i18n>Start hour</label>
          <ngx-timepicker-field [format]="24" formControlName="startHour"></ngx-timepicker-field>
          <label class="ml-auto pl-0 label col-md-2 col-form-label" for="EndHour" i18n>End hour</label>
          <ngx-timepicker-field [format]="24" formControlName="endHour"></ngx-timepicker-field>
        </div>
        <hr class="mt-3 mb-3 separator">
        <div class="col-12 row justify-content-between">
          <app-form-checkbox>
            <input class="check form-check-input mt-2" type="checkbox" id="Registration" formControlName="uponRegistration">
            <label class="label form-check-label mb-4 ml-2" for="UponRegistration" i18n>Upon registration</label>
          </app-form-checkbox>
          <app-form-checkbox>
            <label class="label form-check-label mb-4 ml-2" for="Invitation" i18n>Upon invitation</label>
            <input class="check form-check-input mt-2" type="checkbox" id="UponInvitation" formControlName="uponInvitation">
          </app-form-checkbox>
        </div>
        <div class="col-md-12 row justify-content-between">
          <app-form-checkbox>
            <input class="check form-check-input mt-2" type="checkbox" id="Print" formControlName="print">
            <label class="label form-check-label mb-4 ml-2" for="Print" i18n>Print</label>
          </app-form-checkbox>
          <app-form-checkbox>
            <label class="label form-check-label mb-4 ml-2" for="Live" i18n>Live</label>
            <input class="check form-check-input mt-2" type="checkbox" id="Live" formControlName="live">
          </app-form-checkbox>
        </div>
      </div>
      <div class="col-md-12">
        <hr class="mt-5 mb-5">
        <div class="form-group col-12 row">
          <label for="description" class="p-0 pb-2 col-sm-2 col-form-label" i18n>Description</label>
          <textarea class="input form-control IDinput" rows="3" id="description" #description formControlName="description"
          style="min-height: 70px;width: 100%;">
          </textarea>
          <p class="form-text" [ngClass]="description.value.length > 1500 ? 'text-red' : 'text-muted'">
            <b i18n>{{description.value.length}}</b>&nbsp;Characters (Max. 1500)
          </p>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <app-form-select>
          <label for="IdLocation" class="label col-sm-5 col-form-label" i18n>Location</label>
          <select class="select form-control" id="IdLocation" formControlName="idLocation">
            <option value="0" i18n>Please select</option>
            <option *ngFor="let location of this.listOfLocation" [value]="location.id">
              {{location.label}}
            </option>
          </select>
        </app-form-select>
        <app-form-select>
          <label for="IdTypeFunction" class="label col-sm-5 col-form-label" i18n>Type</label>
          <select class="select form-control" id="IdTypeFunction" formControlName="idTypeFunction">
            <option value="0" i18n>Please select</option>
            <option *ngFor="let type of this.listOfHappeningType" [value]="type.id">
              {{type.label}}
            </option>
          </select>
        </app-form-select>
        <div class="form-group row col-md-12">
          <div class="p-0 pb-2 col-md-4 col-form-label">
            <label for="TypeParticipantMandatory" i18n>Mandatory</label>
          </div>
          <div class="row col-md-8">
            <div class="form-check p-1 w-33">
              <input class="form-check-input" type="checkbox" (change)="selectAll()" id="select_all">
              <label class="form-check-label" for="select_all" i18n>
                all
              </label>
            </div>
            <div class="form-check p-1 w-33" *ngFor="let par of listOfTypeParticipant">
              <input class="form-check-input click_input"
                (change)="onChangeMandatory(par.short, $event.target.checked)" name="{{ par.short }}" type="checkbox"
                id="{{ par.id }}">
              <label class="form-check-label" for="{{ par.id }}">
                {{ par.short }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="row">
          <div class="col-md-6">
            <app-form-input>
              <label for="Capacity" class="label col-sm-4 col-form-label" i18n>Capacity</label>
              <input type="number" class="input form-control" style="width: 100%;" id="Capacity" formControlName="capacity">
            </app-form-input>
          </div>
          <div class="col-md-6">
            <app-form-input>
              <label for="Minus" class="label col-sm-4 col-form-label" i18n>Minus</label>
              <input type="number" class="input form-control" style="width: 100%;" id="Minus" formControlName="minus">
            </app-form-input>
          </div>
        </div>
        <div class="form-group row mr-0">
          <label for="Transfer" class="label col-sm-5 col-form-label" i18n>Transfer</label>
          <div class="col-sm-7 row justify-content-end">
            <input type="text" class="input form-control w-100" id="Transfer" formControlName="transfer">
          </div>
        </div>
        <div class="form-group row col-md-12">
          <div class="p-0 pb-2 col-md-4 col-form-label">
            <label for="TypeParticipantOptional" i18n>Optional</label>
          </div>
          <div class="row col">
            <div class="form-check p-1 w-33">
              <input class="form-check-input" type="checkbox" (change)="selectAll()" id="select_all">
              <label class="form-check-label" for="select_all">
                all
              </label>
            </div>
            <div class="form-check p-1 w-33" *ngFor="let par of listOfTypeParticipant">
              <input class="form-check-input click_input"
                (change)="onChangeOptional(par.short, $event.target.checked)" name="{{ par.short }}" type="checkbox"
                id="{{ par.id }}">
              <label class="form-check-label" for="{{ par.id }}">
                {{ par.short }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <hr class="mt-5 mb-5">
        <app-form-input>
          <label for="ItemUrl" class="label col-sm-5 col-form-label" i18n>Item URL (Zoom, link ...)</label>
          <input type="text" class="input form-control" style="width: 100%;" id="ItemUrl" formControlName="itemUrl">
        </app-form-input>
      </div>
      <div class="row col justify-content-end mt-5">
        <button type="button" class="btn btn-classic button--color btn-size btn-responsive mr-3" (click)="cancelEdit()" i18n>
          Cancel
        </button>
        <button type="submit" class="btn btn-success button--color btn-size btn-responsive" i18n>
          Save
        </button>
      </div>
    </div>
  </form>
</div>