<div class="maincontainer-fluid font-color pb-4 pb-5" style="min-height: 80vh">
  <div class="maincontainer">

    <div class="row">

      <div class="col-lg-8 col-md-12 mt-4">
        <h3 class="nowayRegular" i18n>Thelonious Bob</h3>
        <span style="opacity: 0.7" i18n>Super Admin</span>
      </div>

    </div>

    <!-- Desktop -->
    <div class="display--nav-none">

      <app-settings-menu url="Dashboard-Option"></app-settings-menu>

      <app-settings-dashboard-option-form></app-settings-dashboard-option-form>

    </div>
    <!-- End Des -->


    <!-- Mobile -->
    <div class="accordion display--collapse-none mt-3" id="accordionExample">

      <div id="headingOne">
        <h2 class="mb-0">
          <button class="btn btn-link pl-0" type="button"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="true" aria-controls="collapseFive" style="color: #84C000" i18n>
            Dashboard Option
          </button>
          <button class="float-right btn-link btn" type="button"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="true" aria-controls="collapseFive">
                  <svg width="24px" height="24px" style="fill: #84C000">
                    <use xlink:href="#plus"/>
                  </svg>
          </button>
        </h2>
      </div>

      <div class="mt-3">
        <h2 class="mb-0">
          <button [routerLink]="['/admin/settings-account']" class="btn btn-link pl-0"
                  type="button" i18n>
                  Change password
          </button>
          <button [routerLink]="['/admin/settings-account']" class="float-right btn-link btn"
                  type="button">
                  <svg width="24px" height="24px" style="fill: #84C000">
                    <use xlink:href="#plus"/>
                  </svg>

          </button>
        </h2>
      </div>

      <div class="mt-3">

        <h2 class="mb-0">
          <button [routerLink]="['/admin/settings-event']" class="btn btn-link pl-0"
                  type="button" i18n>
            Event
          </button>
          <button [routerLink]="['/admin/settings-event']" class="float-right btn-link btn" type="button">
   <svg width="24px" height="24px" style="fill: #84C000">
                        <use xlink:href="#plus"/>
                      </svg>
          </button>
        </h2>
      </div>


      <div class="mt-3">

        <h2 class="mb-0">
          <button [routerLink]="['/admin/settings-manage-user']" class=" btn btn-link pl-0" type="button" i18n>
            Manage Users
          </button>
          <button [routerLink]="['/admin/settings-manage-user']" class="float-right btn-link btn"
                  type="button">
                  <svg width="24px" height="24px" style="fill: #84C000">
                    <use xlink:href="#plus"/>
                  </svg>
          </button>
        </h2>


        <div id="collapseFive" class="collapse show" aria-labelledby="headingOne"
             data-parent="#accordionExample">

          <app-settings-dashboard-option-form></app-settings-dashboard-option-form>

        </div>
      </div>
    </div>
    <!-- End Mobile -->


  </div>
</div>
