<div class="card">
  <div class="card-body">

    <div class="dropleft dropright-action">
      <svg  class="opacity-icon" width="24px" height="24px" id="dropdownMenuButton4"
           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <use xlink:href="#action" />
      </svg>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton4">
        <span class="dropdown-menu-arrow"></span>
        <a class="dropdown-item" href="http://ecv5-test.eventcatalyst.io/assets/extract_test.xls" i18n>Extract</a>
        <a class="dropdown-item" [routerLink]="['/admin/contacts/add']" i18n>Add {{ participantType }}</a>
        <a class="dropdown-item" [routerLink]="['/admin/communication/emailing']" i18n>Send email</a>
        <a class="dropdown-item" [routerLink]="['/admin/contacts/list/new_attendees']" i18n>View list</a>
      </div>
    </div>

    <div class="d-flex">
      <h4 class="card-title mb-0" i18n>New {{participantType}}s since your last connection</h4>
    </div>
    <div class="emptySpace" *ngIf="data.length < 3">
    </div>
    <div class="bottomRows" >
      <div *ngFor="let newAttendees of data">
        <div style="width:100%">
          <span class="h2Top"> + {{newAttendees.nbNew}}</span>
          <div class="badge badge-dashboard badge-pill" [ngClass]="newAttendees.stampClass">
            <span i18n>
              {{newAttendees.status}}
            </span>
          </div>
        </div>
        <!-- <div class="row rowNewAttendees" [routerLink]="newAttendees.urlList"  [ngbPopover]="newAttendees.alt" popoverClass="popover-custom-medium" triggers="mouseenter:mouseleave">
          <span class="h2Top"> + {{newAttendees.nbNew}}</span>
            <div style="margin-left: 5px">
              <div class="badge badge-dashboard badge-pill" [ngClass]="newAttendees.stampClass">
                <span i18n>
                  {{newAttendees.status}}
                </span>
              </div>
            </div>
        </div> -->
      </div>
    </div>
    <div style="height:12px" *ngIf="data.length < 3"></div>
    <div class="lastRow">
      <span class="mt-3 resp resp-last-item" popoverClass="popover-custom-light" ngbPopover="View list"
      triggers="mouseenter:mouseleave" [routerLink]="['/admin/contacts/list/new_attendees']">
        <svg class="opacity-icon"  width="24px" height="24px">
          <use xlink:href="#arrow-right" />
        </svg>
      </span>
    </div>
    </div>
  </div>
