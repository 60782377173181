import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-dashboard-notification',
  templateUrl: './card-dashboard-notification.component.html',
  styleUrls: ['./card-dashboard-notification.component.scss']
})
export class CardDashboardNotificationComponent implements OnInit {

  notifReceived = 67;
  notifSent = 78;
  taux = this.notifReceived / this.notifSent;

  constructor() { }

  ngOnInit() {
  }

  tauxProgress(taux: number) {
    return Math.round(taux * 100);
  }
}
