import { Component, OnInit, Input } from '@angular/core';
import { EventService } from '../../../../core/http/event.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
  selector: 'app-participant-reservation-night',
  templateUrl: './participant-reservation-night.component.html',
  styleUrls: ['./participant-reservation-night.component.scss']
})
export class ParticipantReservationNightComponent implements OnInit {

  @Input() data: any;
  
  long_desc: string;
  short_desc: string;
  short_title: string;
  collapse_url: string;
  collapse: boolean = false;
  cut_desc: boolean = false;
  url_edit_data: string;
  url_details_data: string;
  idEvent: any;
  dataName: any;
  id: any;
  mandatory: string;
  optional: string;

  constructor(
    private eventService: EventService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    console.log(this.data)
    this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.get('id'))
        this.id = params.get('id');
    });
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  showMore() {
    this.collapse = !this.collapse;
  }
  
  deleteAgenda(id: number) {
  }
  modalDeleteAgenda(id: number) {
    $(`#deleteModal${id}`).modal('show');
  }
}
