import { Component, OnInit, Output, EventEmitter, ViewChild, Input, HostListener } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { GetDataService } from '../../../../core/http/get-data.service';

declare var $: any;

@Component({
  selector: 'app-autocomplete-participant',
  templateUrl: './autocomplete-participant.component.html',
  styleUrls: ['./autocomplete-participant.component.scss']
})
export class AutocompleteParticipantComponent implements OnInit {

  @Output() displayAutocomplete: EventEmitter<any> = new EventEmitter();
  @Input() autocompleteParticipantsValue;

  attendeeList;
  attendeeAutocomplete;
  loader: boolean = false;
  testcount = 0;


  public attendeeForm = this.fb.group({
    inputFirstName: [''],
    inputLastName : [''],
    inputId: [],
    inputEmail: [''],
    inputStatus: [''],
    inputType: [''],
  });

  constructor(private fb: FormBuilder, private getDataService : GetDataService, private router: Router,) { }

  ngOnInit() {
    this.attendeeAutocomplete = {
      "Id" : 0,
      "IdCompany" : 0,
      "Firstname" : this.autocompleteParticipantsValue.firstNameAttendeeI,
      "Lastname" : this.autocompleteParticipantsValue.lastNameAttendeeI,
      "Email" : this.autocompleteParticipantsValue.emailAttendeeI,
      // inputStatus: '',
      // inputType:''
    };

    this.postApiAutocomplete(this.attendeeAutocomplete);

    const inputFrom = this.autocompleteParticipantsValue.from;
    this.checkInputFrom(inputFrom)

    this.attendeeForm.patchValue
    ({
      inputFirstName: this.autocompleteParticipantsValue.firstNameAttendeeI,
      inputLastName: this.autocompleteParticipantsValue.lastNameAttendeeI,
      inputId:'',
      inputEmail: this.autocompleteParticipantsValue.emailAttendeeI,
      // inputStatus: '',
      // inputType:''
    });

  }


  checkInputFrom(input){
    if(input === 'firstNameAttendeeI'){
      $('#inputName').focus();
    } else if(input === 'lastNameAttendeeI'){
      $('#inputLastName').focus();
    } else if(input === 'emailAttendeeI'){
      $('#inputEmail').focus();
    }
  }
  // ngOnChanges(){
  //   this.attendeeForm.patchValue({
  //     inputFirstName: this.autocompleteParticipantsValue.firstNameAttendeeI,
  //     inputLastName: this.autocompleteParticipantsValue.lastNameAttendeeI,
  //     inputId:'',
  //     inputEmail: this.autocompleteParticipantsValue.emailAttendeeI,
  //     inputStatus: '',
  //     inputType:''});
  // }

  postApiAutocomplete(body) {
    this.loader = true;
    this.getDataService.postParticipantAutocomplete(body).subscribe( response => {
      this.attendeeList = [];
      if(response){
        console.log(response)
        this.loader = false;
        for (let i = 0; i < response.length; i++) {
          console.log(response[i])
          this.attendeeList.push(response[i]);
          this.emailSubstr(this.attendeeList);
        }
        console.log(this.attendeeList)
      }
      else{
        // HERE ERROR ? CATH ERROR TODO
        console.log('no response')
      }
    });

  }

  emailSubstr(list){
    if(list.length > 0){
      return list.forEach(attendee => {
        if(attendee.email == null){
          attendee.email = ' - ';
        }
        else if(attendee.email.length > 25){
          attendee.email = attendee.email.substr(0, 25) + '...';
        } 
      });
    }
  }

  resetCompanyForm() {
    this.attendeeForm.patchValue({
      inputFirstName:'',
      inputLastName:'',
      inputId:'',
      inputEmail:'',
      inputStatus: '',
      inputType:''});
    this.displayAutocomplete.emit('CLEAR');
  }

  @HostListener('click')
  clickInside() {
    this.testcount += 1;
  }

  @HostListener('document:click')
  clickout() {
    this.testcount -= 1;
    if (this.testcount < -1) {
      this.closeAutocomplete();
    }
  }

  closeAutocomplete() {
    this.displayAutocomplete.emit(false);
  }


  
  changeInput(input){    

    this.attendeeAutocomplete = {
      "Id" : this.attendeeForm.value.inputId.length > 1 ?  this.attendeeForm.value.inputId : 0,
      "IdCompany" : 0,
      "Firstname" : this.attendeeForm.value.inputFirstName,
      "Lastname" : this.attendeeForm.value.inputLastName,
      "Email" : this.attendeeForm.value.inputEmail,
      // inputStatus: '',
      // inputType:''
    };

    this.postApiAutocomplete(this.attendeeAutocomplete);

  }

  getAttendeeSelected(attendee){
    // redirect to detail or update page to participant ?
    this.router.navigate(['/admin/contacts/' + attendee.id + "/details"]);
  }

  skipAutocomplete(){
    this.displayAutocomplete.emit('SKIPAUTOCOMPLETE');
  }
}
