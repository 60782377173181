import { Component, OnInit } from '@angular/core';
import { DownloadService } from 'src/app/core/http/download.service';

declare var $: any

@Component({
  selector: 'app-extractions',
  templateUrl: './extractions.component.html',
  styleUrls: ['./extractions.component.scss']
})
export class ExtractionsComponent implements OnInit {
  constructor(
    private downlaodService: DownloadService,
  ) { }

  idEvent: number;
  typeStatus: any;
  contactTitle: string = "Contacts";
  participantTitle: string = "Participants";
  eventTitle: string = "Events";
  agendaTitle: string = "Agendas";
  dataDownload: any;

  ngOnInit() {
  }

  downloadList(event: any) {
    this.dataDownload = event;
    console.log(this.dataDownload);
    this.downlaodService.exportFile(this.dataDownload).subscribe(res => {
      console.log(res);
      let blob: Blob = res.body as Blob;
      let name = res.headers.get('content-disposition');
      let a = document.createElement('a');
      a.download = name.split(';')[1].split('=')[1].replace(/\"/g, '');
      a.href = window.URL.createObjectURL(blob);
      a.click();
      console.log("Success", res);
    }, error => {
      console.log("Error", error);
    });
  }
}
