<div>
    <app-fil-ariane-emailing></app-fil-ariane-emailing>
    <div class="container-fluid font-color">
        <div class="maincontainer">
            <p class="pt-4">
                <span class="h1 nowayMedium title_responsive" style="font-size: 31px">Followup {{idFollowup}}</span>
            </p>
            <div class="row" style="font-size: 13px">
                <div class="col-md-6">
                    <h6 class="mb-4 mt-3 nowayRegular" style="color: #244746; font-weight: bold;">SUMMARY</h6>
                
                    <div class="row mt-4">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-4">
                                    <p class="fieldTitle">
                                        Subject
                                    </p>
                                </div>
                                <div class="col ft nowayRegular" style="color: #244746">
                                    {{this.followup.subject}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <p class="fieldTitle">
                                        Number of contacts	
                                    </p>
                                </div>
                                <div class="col ft nowayRegular" style="color: #244746">
                                    {{this.followup.idlist.length}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <p class="fieldTitle">
                                        Messages in mailing	
                                    </p>
                                </div>
                                <div class="col ft nowayRegular" style="color: #244746">
                                    {{this.followup.nbMessages}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <p class="fieldTitle">
                                        Sent on	
                                    </p>
                                </div>
                                <div class="col ft nowayRegular" style="color: #244746">
                                    {{this.followup.sentDate}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <h6 class="mb-4 mt-3 nowayRegular" style="color: #244746; font-weight: bold;">TRACKING REPORT</h6>
                
                    <div class="row mt-4">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-4">
                                    <p class="fieldTitle">
                                        First opening date	
                                    </p>
                                </div>
                                <div class="col ft nowayRegular" style="color: #244746">
                                    {{this.followup.openingDate}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <p class="fieldTitle">
                                        Last opening date
                                    </p>
                                </div>
                                <div class="col ft nowayRegular" style="color: #244746">
                                    {{this.followup.lastOpeningDate}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <p class="fieldTitle">
                                        Total opening	
                                    </p>
                                </div>
                                <div class="col ft nowayRegular" style="color: #244746">
                                    {{this.followup.nbMessagesOpen}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="maincontainer mt-4">
            
                <div style="width: auto!important;" class="row headerList justify-content-between">
                
                    <div class="col-3">
                        <h6 class="mb-4 mt-3 nowayRegular" style="color: #244746; font-weight: bold;">CONTACTS LIST</h6>
                    </div>
                    <div class="col-5">

                    </div>
                    <div class="col-3">
                        <div class="pagination" style="white-space: nowrap;">
                        
                            <pagination-controls (pageChange)="pageChange($event)" id="paginationHL" class="myPagination" previousLabel="" nextLabel=""
                            maxSize="5"></pagination-controls>
                        
                            <div style="padding-right: 25px;" class="nbItems">
                            <span>Show</span>
                            <div class="select-custom">
                                <select [(ngModel)]="nbItems" (ngModelChange)='onItemsChange($event);'>
                                <option selected [value]="10">10</option>
                                <option [value]="50">50</option>
                                <option [value]="100">100</option>
                                <option [value]="300">300</option>
                                <option [value]="999999999">All</option>
                                </select>
                            </div>
                            <span>lines</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-1">

                    </div>
                </div>
    
                <div class="row mt-4 header-row">
                    <div class="col-6">
                        <p class="header-item">Emails</p>
                    </div>
                    <div class="col-2">
                        <p class="header-item">First opened date</p>
                    </div>
                    <div class="col-2">
                        <p class="header-item">Last opened date</p>
                    </div> 
                    <div class="col-2">
                        <p class="header-item">Total opening</p>
                    </div>
                </div>
    
                <div class="list">
                    <ul> 
                        <li *ngFor="let item of listOfContacts | paginate: config">
                        <app-followup-contact [data]="item" ></app-followup-contact>
                        </li>
                        <br>
                    </ul>
                </div>
    
            </div>

            <div class="maincontainer pb-5">
            
                <div class="row headerList justify-content-between">
                
                    <div class="col-3">
                        <h6 class="mb-4 mt-3 nowayRegular" style="color: #244746; font-weight: bold;">VISITED HYPERLINKS</h6>
                    </div>
                    <div class="col-5">
                    </div>
                    <div class="col-3" *ngIf="listOfHyperlinks.length > 0">
                        <div class="pagination" style="white-space: nowrap;">
                        
                            <pagination-controls (pageChange)="pageChangeHL($event)" id="paginationHL" class="myPagination" previousLabel="" nextLabel=""
                            maxSize="5"></pagination-controls>
                        
                            <div style="padding-right: 25px;" class="nbItems">
                            <span>Show</span>
                            <div class="select-custom">
                                <select [(ngModel)]="nbItemsHyperlinks" (ngModelChange)='onItemsChangeHyperlinks($event);'>
                                <option selected [value]="10">10</option>
                                <option [value]="50">50</option>
                                <option [value]="100">100</option>
                                <option [value]="300">300</option>
                                <option [value]="999999999">All</option>
                                </select>
                            </div>
                            <span>lines</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-1">
                    </div>
                </div>
    
                <div class="row mt-4 header-row" *ngIf="listOfHyperlinks.length > 0">
                    <div class="col-6">
                        <p class="header-item">Emails</p>
                    </div>
                    <div class="col-2">
                        <p class="header-item">First opened date</p>
                    </div>
                    <div class="col-2">
                        <p class="header-item">Last opened date</p>
                    </div> 
                    <div class="col-2">
                        <p class="header-item">Total opening</p>
                    </div>
                </div>
    
                <div class="list" *ngIf="listOfHyperlinks.length > 0">
                    <ul> 
                        <li *ngFor="let item of listOfHyperlinks | paginate: configHyperlinks">
                        <app-followup-contact [data]="item" ></app-followup-contact>
                        </li>
                        <br>
                    </ul>
                </div>
                <div class="list" *ngIf="listOfHyperlinks.length === 0">
                    <p class="text-center">No visited hyperlinks</p>
                </div>
            </div>
        </div>
    </div>
</div>