<div style="width:100%; border-top: 3px solid #eae5d5;background-color: #fff">
    <div class="maincontainer font-color" [ngStyle]="{'background-color':mobileRes == true ? '#f8f9f4' : '#FFF' }">
        <div class="maincontainer row" style="justify-content: space-between;">
            <div>
                <nav class="breadcrumb-responsive filAriane" aria-label="breadcrumb" style="margin-top: 15px">
                    <ol class="breadcrumb pl-0 breadcrumb-color">
                        <svg [routerLink]="['/admin/dashboard']" id="dropdownMenuButton6" height="24px" width="24px" aria-haspopup="true" aria-expanded="false"
                            style="fill:#84C000">
                            <use xlink:href="#company_f" />
                        </svg>
                        <div style="display:flex;">
                            <ng-container *ngFor="let item of urlList; let myIndex = index; first as isFirst; last as isLast" >
                                <li class="breadcrumb-item" *ngIf="!isLast && myIndex > 1 && item != 'contacts' && item != 'events-participants'"><a>{{item.substring(0,1).toUpperCase() + item.substring(1).replaceAll('-', ' ')}}</a></li>
                                <li class="breadcrumb-item" *ngIf="!isLast && myIndex > 1 && (item == 'contacts' || item == 'events-participants')"><a [routerLink]="url[myIndex]">{{item.substring(0,1).toUpperCase() + item.substring(1).replaceAll('-', ' ')}}</a></li>
                                <li class="breadcrumb-item active" *ngIf="isLast" aria-current="page">{{item.substring(0,1).toUpperCase() + item.substring(1).replaceAll('-', ' ')}}</li>
                            </ng-container>
                        </div>
                    </ol>
                </nav>
                <div class="row details-responsive">
                    <div class="compteurs col">
                        <a class="compteursLink">
                            <svg id="dropdownMenuButton6" height="26px" width="26px" aria-haspopup="true"
                                aria-expanded="false">
                                <use xlink:href="#user" />
                            </svg>
                            <span class="compteurLabel" i18n>{{participantCount}} Participants</span>
                        </a>
                    </div>
                    <div class="compteurs col">
                        <a class="compteursLink">
                            <svg id="dropdownMenuButton6" height="26px" width="26px" aria-haspopup="true"
                                aria-expanded="false">
                                <use xlink:href="#company" />
                            </svg>
                            <span class="compteurLabel" i18n>{{companyCount}} Companies</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-3" style="text-align: end; height: 90px; margin-top: 18px;">
                <div class="icons" style="vertical-align: middle;">
                    <div class="btn-group dropleft ml-3">
                        <span placement="left" popoverClass="popover-custom-light popover-width" ngbPopover="Add more" triggers="mouseenter:mouseleave" id="AddThings" data-toggle="dropdown">
                            <svg aria-haspopup="true" aria-expanded="false" class="iconFilArianne">
                                <use xlink:href="#plus" />
                            </svg>
                        </span>
                        <div class="dropdown-menu" aria-labelledby="AddThings">
                            <a class="dropdown-item" [routerLink]="['/admin/contacts/add']" placement="left" popoverClass="popover-custom-light" ngbPopover="New contact" triggers="mouseenter:mouseleave" i18n>New contact</a>
                            <a *ngIf="urlList.length > 4" class="dropdown-item" [routerLink]="['/admin/agenda/events/add']" placement="left" popoverClass="popover-custom-light" ngbPopover="New event" triggers="mouseenter:mouseleave" i18n>New event</a>
                        </div>
                    </div>
                    <span placement="top" popoverClass="popover-custom-light popover-width" ngbPopover="Add filter" triggers="mouseenter:mouseleave">
                        <svg height="24px" width="24px" (click)="displayFilters()" class="ml-3"
                            [ngClass]="statut ? 'display' : 'dontDisplay'">
                            <use xlink:href="#filter" />
                        </svg>
                    </span>
                    <span placement="top" popoverClass="popover-custom-light popover-width" ngbPopover="Fast edit" triggers="mouseenter:mouseleave">
                        <svg height="24px" width="24px" (click)="displayFastEdit()" class="ml-3"
                            [ngClass]="statutFastEdit ? 'display' : 'dontDisplay'">
                            <use xlink:href="#edit" />
                        </svg>
                    </span>
                    <span placement="top" popoverClass="popover-custom-light popover-width" ngbPopover="Export list" triggers="mouseenter:mouseleave">
                        <svg height="24px" width="24px" class="ml-3" (click)="exportFile()">
                            <use xlink:href="#export" />
                        </svg>
                    </span>
                    <div class="btn-group dropleft ml-3">
                        <span placement="right" popoverClass="popover-custom-light popover-width" ngbPopover="More option" triggers="mouseenter:mouseleave" id="dropdownMenuButton2" data-toggle="dropdown">
                            <svg aria-haspopup="true" aria-expanded="false" class="iconFilArianne">
                                <use xlink:href="#action_h" />
                            </svg>
                        </span>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                            <a class="dropdown-item" href="#" i18n>Action 1</a>
                            <a class="dropdown-item" href="#" i18n>Action 2</a>
                            <a class="dropdown-item" href="#" i18n>Action 3</a>
                            <a class="dropdown-item" href="#" i18n>Action 4</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
