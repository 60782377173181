import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormDataFilterService {

  constructor(
    private fb: FormBuilder
  ) { }

  dataFilter: any;
  
  dataFilters() {
    return this.dataFilter = this.fb.group({
      idCountry: [0],
      idType: [0],
      idCompany: [0],
      vip: [-1],
      main: [-1],
      search: [''],
    });
  }
}
