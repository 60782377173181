<div>
  <h3 class="mt-5 color titlePart" i18n>MAILING RECAP:</h3>

  <div class="row">
    <div class="col-xl-5">
      <p><span class="label_form_mail" i18n>From :</span> {{emailObject.mailSender}}</p>
    </div>
    <div class="col-xl-5">
      <p><span class="label_form_mail" i18n>Reply to : {{emailObject.mailReplyTo}}</span></p>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-5">
      <p><span class="label_form_mail" i18n>CC :</span> {{emailObject.mailCC}}</p>
    </div>
    <div class="col-xl-5">
      <p><span class="label_form_mail" i18n>BBC :</span> {{emailObject.mailBCC}}</p>
    </div>
  </div>

  <div class="row">

    <div class="col-xl-5">
      <p><span class="label_form_mail" i18n>Tracking image :</span> {{emailObject.mailTrackingImage}}</p>
    </div>
    <div class="col-xl-5">
      <p><span class="label_form_mail" i18n>Confirmation read receipt :</span> {{emailObject.mailConfirmationRead}}</p>
    </div>
  </div>

  <hr style="border: solid 1px #EAE5D6; margin-top: 31px; margin-bottom: 31px">

  <p><span class="label_form_mail" i18n>Subject :</span> {{emailObject.mailSubject}}</p>

  <p>
    <span class="label_form_mail" i18n>Template :</span> {{emailObject.mailTemplateSelected.templateName}}
    <svg id="previewLogo" height="24px" width="24px" viewBox="0 0 24 24" class="svgLogoPreview ml-2" (click)="displayModalTemplates()">
      <use xlink:href="#viewDetails" />
    </svg>
  </p>

  <p>
    <span class="label_form_mail" i18n>Contacts:</span> 2156
    <svg id="previewLogo" height="24px" width="24px" viewBox="0 0 24 24" class="svgLogoPreview ml-2" (click)="displayModalContacts()">
      <use xlink:href="#viewDetails" />
    </svg>
  </p>
</div>


<hr style="border: solid 1px #EAE5D6; margin-top: 31px; margin-bottom: 31px">

<!-- Modal view Template-->
<div class="modal fade" id="viewTemplateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header" style='display:block'>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h5 class="modal-title" id="exampleModalLabel">{{emailObject.mailTemplateSelected.templateName}}</h5>
        <h5 class="modal-title"><u i18n>Subject :</u> {{emailObject.mailTemplateSelected.templateSubject}}</h5>
      </div>
      <div class="modal-body">
        <div [innerHTML]="emailObject.mailTemplateSelected.templateBody"></div>
      </div>
    </div>
  </div>
</div>

<!-- Modal View List -->
<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="contactsListModal">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" style="height: 600px;overflow-y: scroll;">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel" i18n>Selected attendees list</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <table class="table">
        <thead>
          <tr  style="font-size: 18px;">
            <th scope="col" i18n>ID</th>
            <th scope="col" i18n>FIRST NAME</th>
            <th scope="col" i18n>LAST NAME</th>
            <th scope="col" i18n>STATUS</th>
            <th scope="col" i18n>TYPE</th>
            <th scope="col" i18n>EMAIL</th>
          </tr>
        </thead>
        <tbody style="font-size: 16px;color: #000!important;font-family: 'nowayRegular'!important;">
          <tr *ngFor="let attendee of attendeesList">
            <td scope="row">{{attendee.id}}</td>
            <td>{{attendee.firstname}}</td>
            <td>{{attendee.lastname}}</td>
            <td>
              <div class="badge badge-pill card__badge--color-success" style="cursor:pointer;height: 18px;">
                <span i18n>
                  {{attendee.status}}
                </span>
              </div>
            </td>
            <td>{{attendee.type}}</td>
            <td>{{attendee.email}}</td>
          </tr>
        </tbody>
      </table>
      <div class="modal-footer">
        <button type="button" class="btn btn-success button--color" data-dismiss="modal" aria-label="Close" style="margin-left: auto;margin-right: auto; color: #FFFFFF" i18n>ok</button>
      </div>
    </div>
  </div>
</div>


<!-- Modal send test mail> -->
<div class="modal fade" id="sendTestModal" tabindex="-1" role="dialog" aria-labelledby="sendTestModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="sendTestModalLabel" i18n>Send test mail</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row form-row">
          <p i18n>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
          <div class="col-md-10 flex">
            <label for="testMailAdress" class="label" i18n>Mail adress</label>
            <input type="mail" value="admin@mail.com" class="input form-control" id="testMailAdress" style="width:100%!important">
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn float-right" (click)="sendTest()"
                style="margin-right: 13px; background-color: #84C000; color: #000; border: 1px solid #84C000; border-radius: 1px;" i18n>
          Send Test
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal send Later> -->
<div class="modal fade" id="sendLaterModal" tabindex="-1" role="dialog" aria-labelledby="sendLaterModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="sendLaterModalLabel" i18n>Send this mail later</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row form-row">
          <p i18n>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
          <div class="form-group col-6">
            <label for="sendingDate" i18n>Sending date</label>
            <div class="input-group">
              <input class="form-control" name="dp" ngbDatepicker #d="ngbDatepicker">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
              </div>
            </div>
          </div>
          <div class="form-group col-6">
            <label for="priority" i18n>Sending Hour</label>
            <select class="form-control" id="priority">
              <option *ngFor="let hour of hours" [value]="hour">{{hour}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn float-right" (click)='sendLater()'
                style="margin-right: 13px; background-color: #84C000; color: white; border: 1px solid #84C000; border-radius: 1px;" i18n>
          Send Later
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal send mail> -->
<div class="modal fade" id="sendMailModal" tabindex="-1" role="dialog" aria-labelledby="sendMailModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="sendMailModalLabel" i18n>Send mail</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row form-row">
          <p i18n>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn float-right" data-dismiss="modal" aria-label="Close"
                style="margin-right: 23px; background-color: #dc3545; color: white; border: 1px solid #dc3545; border-radius: 1px;" i18n>
          Cancel
        </button>
        <button type="button" class="btn float-right" (click)="sendMail()"
                style="margin-right: 13px; background-color: #84C000; color: #000; border: 1px solid #84C000; border-radius: 1px;" i18n>
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>

<div class="mt-4" style="display: flex;justify-content: space-between;">
  <button class="btn" mat-button matStepperPrevious i18n>
    Previous
  </button>


  <button mat-button class="btn sendTestBtn" data-toggle="modal" data-target="#sendTestModal" style="margin-left: 12%;" i18n>
    Send test
  </button>

  <div *ngIf="!testEmailSend">
    <button disabled class="btn" mat-button data-toggle="modal" data-target="#sendLaterModal"
            style="margin-right: 13px; background-color: #84C000; color: white; border: 1px solid #84C000; border-radius: 1px;" i18n>
      Send later...
    </button>
    <button disabled class="btn" mat-button data-toggle="modal" data-target="#sendMailModal"
            style="margin-right: 13px; background-color: #84C000; color: white; border: 1px solid #84C000; border-radius: 1px;" i18n>
      Send now
    </button>

  </div>

  <div *ngIf="testEmailSend">
    <button class="btn" mat-button data-toggle="modal" data-target="#sendLaterModal"
            style="margin-right: 13px; background-color: #84C000; color: white; border: 1px solid #84C000; border-radius: 1px;" i18n>
      Send later...
    </button>
    <button class="btn" mat-button data-toggle="modal" data-target="#sendMailModal"
            style="margin-right: 13px; background-color: #84C000; color: white; border: 1px solid #84C000; border-radius: 1px;" i18n>
      Send now
    </button>

  </div>
</div>
