import { Component, OnInit, Output, Input, EventEmitter, AfterViewChecked, ElementRef, ViewChild, } from '@angular/core';
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { GetDataService } from '../../../core/http/get-data.service';
import { EmailingService } from '../../../core/http/emailing.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ViewportScroller } from '@angular/common';
import { map } from 'rxjs/operators';



declare var $: any;

@Component({
  selector: 'app-template-step',
  templateUrl: './template-step.component.html',
  styleUrls: ['./template-step.component.scss']
})
export class TemplateStepComponent implements OnInit {

  firstStepFormGroup: FormGroup;
  imgNameShort = '';
  newTemplateModel = false;
  radioSelected;
  config: any;
  templateType: string;

  filterDisplay = false;

  public mailAdressList = ['mail1@eventcatalyst.com', 'mail2@eventcatalyst.com', 'exemple@eventcatalyst.com'];
  public mailList = ['mail number 1', 'mail number 2', 'mail number 3'];
  public isCollapsed = true;
  // public editor = ClassicEditor;

  selectedTemplate = 0;

  public templateObject = {
    newTemplate: false,
    templateName: '',
    templateType: '',
    templateSubject: '',
    templateBody: '',
    templateIndex: 0,
  };

  constructor(private _formBuilder: FormBuilder,
              private getDataService: GetDataService,
              private EmailingService: EmailingService,
              private toastr: ToastrService,
              private route: ActivatedRoute,
              private router: Router,
              private viewportScroller: ViewportScroller,
              private activatedRoute: ActivatedRoute) {

    this.config = {
      currentPage: 1,
      itemsPerPage: 5
    };

    this.route.queryParamMap.pipe(map(params => params.get('page')))
      .subscribe(page => this.config.currentPage = page);


  }

  @Output() subject: EventEmitter<any> = new EventEmitter();

  @Input() emailObject;



  listOfMailTemplate = this.EmailingService.listOfMailTemplates;
  listOfTemplatesType = this.EmailingService.listOfMailTypes; 

  ngOnInit() {
    this.firstStepFormGroup = this._formBuilder.group({
      mailSubjectForm: ['', Validators.required],
      mailSenderForm: ['', Validators.required],
      mailCCForm: [''],
      mailBCCForm: [''],
      mailReplyToForm: [''],
      mailTrackingImageForm: [true, Validators.required],
      mailConfirmationReadCB: [false, Validators.required],
      templatesTypeFilter: [''],
      templateName: [''],
      mailTemplateSelected: ['', Validators.required],
    });
    this.templateType = this.firstStepFormGroup.value.templatesTypeFilter;
  }

  onSubmit() {
    const mailObjet = {
      mailSubject : this.firstStepFormGroup.value.mailSubjectForm,
      mailSender : this.firstStepFormGroup.value.mailSenderForm,
      mailCC : this.firstStepFormGroup.value.mailCCForm,
      mailBCC : this.firstStepFormGroup.value.mailBCCForm,
      mailReplyTo : this.firstStepFormGroup.value.mailReplyToForm,
      mailTrackingImage : this.checkCbValue(this.firstStepFormGroup.value.mailTrackingImageForm),
      mailConfirmationRead : this.checkCbValue(this.firstStepFormGroup.value.mailConfirmationReadCB),
      mailTemplateSelected : this.firstStepFormGroup.value.mailTemplateSelected,
    };
    // this.EmailingService.mailSubject = this.mailSubjectForm.value;
    // this.EmailingService.validFirstStep(this.mailSubjectForm.value);
    this.subject.emit(mailObjet);
    // console.log(mailObjet);
  }

  pageChange(newPage: number) {
    this.router.navigate(['admin/communication/emailing/'], { queryParams: { page: newPage } });
    this.viewportScroller.scrollToAnchor('scrollMe');  }

  switchView(event) {
    this.isCollapsed = event;
  }


  onChange(value) {
    this.templateType = value;
  }

  newTemplate() {
    this.templateObject.newTemplate = true;
    this.templateObject.templateIndex = 0;
    this.templateObject.templateBody = '';
    this.templateObject.templateType = '';
    this.templateObject.templateSubject = '';
    this.templateObject.templateName = '';
    this.isCollapsed = !this.isCollapsed;
  }

  saveTemplate() {
    // Ajouter le template crée a la liste des templates;
    this.toastr.clear();
    this.toastr.success('Success', 'Template saved');
    this.isCollapsed = true;
  }


  addNewTemplate(event) {
    if (event.templateIndex === 0) {
      const newTemplate = {
        templateName: event.templateName,
        templateType: event.templateType,
        templateId: this.EmailingService.listOfMailTemplates.length,
        templateSubject : event.templateSubject,
        templateBody: event.templateBody,
      };
      this.EmailingService.listOfMailTemplates.push(newTemplate);
      this.toastr.clear();
      this.toastr.success('Success', 'New template saved');
      this.isCollapsed = true;
    } else {
      const newTemplate = {
        templateName: event.templateName,
        templateType: event.templateType,
        templateId: this.EmailingService.listOfMailTemplates.length,
        templateSubject : event.templateSubject,
        templateBody: event.templateBody,
      };
      if (this.firstStepFormGroup.value.mailTemplateSelected !== '') {
        this.firstStepFormGroup.controls['mailSubjectForm'].setValue(event.templateSubject);
      }
      this.EmailingService.listOfMailTemplates.splice(event.templateIndex, 1, newTemplate);
      this.toastr.clear();
      this.toastr.success('Success', 'Template saved');
      this.isCollapsed = true;
    }
  }

  displayFilters() {
    this.filterDisplay = !this.filterDisplay;
  }

  editTemplate(template, index) {
    this.templateObject.templateIndex = index;
    this.templateObject.newTemplate = false;
    this.templateObject.templateBody = template.templateBody;
    this.templateObject.templateType = template.templateType;
    this.templateObject.templateSubject = template.templateSubject;
    this.templateObject.templateName = template.templateName;
    this.isCollapsed = !this.isCollapsed;
  }

  getModalName(index){
    $("#exampleModal" + index).modal('show');
  }

  onTemplateChange(item) {
    let index = item.substring(0, 1);
    this.firstStepFormGroup.controls['mailTemplateSelected'].setValue(index);
    this.firstStepFormGroup.controls['mailSubjectForm'].setValue(this.EmailingService.listOfMailTemplates[index].templateSubject)
  }

  onItemChange(item) {
    this.firstStepFormGroup.controls['mailSubjectForm'].setValue(item.templateSubject);
    this.firstStepFormGroup.controls['templateName'].setValue(item.templateName);
  }

  checkCbValue(input) {
    if (input) {
      return 'Yes';
    }
    return'No';
  }

}
