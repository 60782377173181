<div *ngIf="listOfNight">
    <div class="ml-3 mr-3 pb-3 tab_night">
        <span class="w_12">Night</span>
        <span class="w_12" >Hotel</span>
        <span class="w_8">Nbr of room(s)</span>
        <span class="w_8">Surbooking</span>
        <span class="w_12">Room type
            <svg class="svgSave" id="svgSave" height="24px" width="24px" style="margin-top: -6px;" (click)="createRoomType()">
                <use xlink:href="#plus" />
            </svg>
        </span>
        <span class="w_8">Price</span>
        <span class="w_8">Max occupancy</span>
        <span class="w_15">Participant type</span>
        <span class="w_11">Note(s)</span>
        <span class="w_6"></span>
    </div>
    <div *ngFor="let list of listOfNight">
        <app-night-tab-form [data]="list" (afterChange)="duplicateNight($event)" (afterSuccess)="addNight($event)" (afterDelete)="deleteNight($event)"></app-night-tab-form>
    </div>
</div>
<div class="p-4 already_exist modal" id="addModal" tabindex="-1" aria-labelledby="existingModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="">
        <div class="">
            <h4 class="mb-4 text-align-center" i18n>Add Room Type</h4>
        </div>
        <hr class="mt-2 mb-3 separator" style="background-color: #84C000;">
        <form (ngSubmit)="onSubmit()" [formGroup]='dataRoomType' style="display: flex;">
            <div class="flex_column">
                <span>Hotel</span>
                <select style="width: 180px;" class="mb-3 mr-3" id="idHotel" formControlName="idHotel">
                    <option value="0" i18n>Please select</option>
                    <option *ngFor="let ht of listOfHotel" value="{{ht.idHotel}}">
                    {{ht.hotelName}}
                    </option>
                </select>
                <span>Room type</span>
                <input stype="text" class="mb-3 mr-3" id="RoomName" formControlName="roomName">
            </div>
            <div class="flex_column">
                <button class="btn btn-success button--color btn-size btn-responsive m-2" type="submit"
                style="margin-right: 10px;color:#FFF" data-toggle="#existingModal" i18n>Save</button>
                <button class="btn btn-success button--color btn-size btn-responsive m-2" data-dismiss="modal" (click)="addRoomAndReload()"
                style="margin-right: 10px;color:#FFF" data-toggle="#existingModal" i18n>Save & Quit</button>
                <button class="btn btn-classic button--color btn-size btn-responsive m-2"
                style="margin-right: 13px; color:#FFF" data-dismiss="modal" i18n>Quit</button>
            </div>
        </form>
    </div>
</div>