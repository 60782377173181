<div class="row details-responsive responsiveHeader">
    <div class="mt-1 " style="display: flex;">
        <div class="float-left">
            <div class="mt-2 pipe">
                <svg id="dropdownMenuButton6" height="24px"
                width="24px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <use xlink:href="#minus" />
                </svg>
            </div>
        </div>
        <div *ngIf="listOfDetailsEvent?.type=='Virtual'" class="mt-2">
            <span class="badge-status badge-status-{{listOfDetailsEvent?.type}}">
                {{listOfDetailsEvent?.type}}
            </span>
        </div>
        <div *ngIf="listOfDetailsEvent?.type=='Onsite'" class="mt-2">
            <span class="badge-status badge-status-{{listOfDetailsEvent?.type}}">
                {{listOfDetailsEvent?.type}}
            </span>
        </div>
    </div>
    <div class="col-md-10 mt-1 row">
        <div class="col-md-12">
            <p class="mb-0">
                <span class="h1 nowayMedium title_responsive" style="font-size: 31px">
                    {{listOfDetailsEvent?.eventName.substring(0, 1).toUpperCase() + listOfDetailsEvent?.eventName.substring(1)}}
                </span>
                <span class="pl-2">
                    {{listOfDetailsEvent?.startDate | date:'fullDate'}} - {{listOfDetailsEvent?.startDate | date:'shortTime'}}
                </span>
            </p>
        </div>
        <div class="col-md-11">
            <p class="badge--id mt-0 idEvent">{{listOfDetailsEvent?.idEvent}}</p>
        </div>
    </div>
</div>