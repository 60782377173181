<div  class="overlay">
  <div  class="contains-autocomplete" >
    <form [formGroup]='attendeeForm'>
    <table width="100%" colspan='5'>
      <thead style="line-height: 51px!important;width:780px!important; background-color: white;">
        <mat-placeholder style="background-color:white;" class="mat-holderplace">
          <tr class="trr">
            <th scope="row" width="151px" >
              <input formControlName="inputFirstName" type="text" (keyup)="changeInput('inputName')"
              id="inputName" class="input-autocomplete" autocomplete="chrome-off"
              placeholder="First name" /></th>
            <th width="120px"><input type="text" formControlName="inputLastName" (keyup)="changeInput('inputLastName')"
              id="inputLastName" autocomplete="off" class="input-autocomplete" type="text"
              placeholder="Last name" /></th>
            <th width="65px"><input type="text" formControlName="inputId" (keyup)="changeInput('inputId')"
                id="inputId" autocomplete="off" class="input-autocomplete" type="text"
                placeholder="Id" /></th>
            <th width="200px"><input type="text" formControlName='inputEmail' (keyup)="changeInput('inputZipcode')"
                id="inputEmail" autocomplete="chrome-off" class="input-autocomplete" type="text"
                placeholder="Email" /></th>
            <!-- <th Width="110px"><input type="text" autocomplete="off" formControlName='inputStatus' (keyup)="changeInput('inputCountry')"
                id="inputStatus"  class="input-autocomplete"
                placeholder="Status" /></th> -->
            <!-- <th width="110px"><input type="text" autocomplete="off"  formControlName='inputType' (keyup)="changeInput('inputType')"
                id="inputType" class="input-autocomplete" type="text"
                placeholder="Type" /></th> -->
            <th style="vertical-align:middle; padding-left:25px;" width="24px">
              <svg class="iconCroix"
              points="0,0 30,0 15,20" transform="rotate(45)" width="24px" height="24px"
              (click)="resetCompanyForm()">
              <use xlink:href="#plus" />
            </svg></th>
          </tr>
        </mat-placeholder>
      </thead>
    </table>
  </form>
  <div  class="autocomplete" style="margin:0!important">


  <div class="row">

    <div class="col-md-12">

      
          <table width="100%" colspan='5'>
            
            <tbody>
              <div *ngIf="!loader">
                <mat-option id="datalist1" class="color-autocomplete" style="max-height:800px!important;"
                  *ngFor="let attendee of attendeeList" [value]="attendee.id"
                  (click)="getAttendeeSelected(attendee)">
                  <tr>
                    <th class="companyName" width="145px">{{attendee.firstname}}</th>
                    <td class="companyInfo" width="120px">{{attendee.lastname}}</td>
                    <td class="companyInfo" width="65px">{{attendee.id}}</td>
                    <td class="companyInfo" width="200px">{{attendee.email}}</td>
                    <!-- <td class="companyInfo" Width="110px">{{attendee.participation.status}}</td> -->
                    <!-- <td class="companyInfo" width="110px">{{attendee.type}}</td> -->
                  </tr>
                </mat-option>
              </div>
              <div *ngIf="loader" class="loader_ec">
                <tr>
                  <app-loader></app-loader>
                </tr>
            </div>
            </tbody>
          </table>
          
          
        </div>
      </div>
      
  </div>
  <mat-option id="placeholder1" class="borderAddCompany"
    style="background-color:white;position:absolute;min-width:820px!important;max-width:820px!important">
    <tr>
      <th width="788px">
        <button class="btn-create-company"(click)="skipAutocomplete()" i18n>Skip</button>
      </th>
    </tr>
  </mat-option>
  </div>
</div>