import { Component, OnInit, Output, EventEmitter, ViewChild, Input, HostListener } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { GetDataService } from '../../../../core/http/get-data.service';
import { RefService} from '../../../../core/http/ref.service';
import { CompanyService} from '../../../../core/http/company.service';

declare var $: any;


@Component({
  selector: 'app-autocomplete-company',
  templateUrl: './autocomplete-company.component.html',
  styleUrls: ['./autocomplete-company.component.scss']
})
export class AutocompleteCompanyComponent implements OnInit {

  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger, static: false }) autoComplete: MatAutocompleteTrigger;
  configUrl = `${environment.apiUrl}`;

  @Output() displayAutocomplete: EventEmitter<any> = new EventEmitter();
  @Output() selectedCompanyEmitter: EventEmitter<any> = new EventEmitter();
  @Input() companyNameValue: any;

  // Creer Form pour créer la company 
  // init vide pour les strings et 0 pour les int
  // ajouter validator à tous les champs exemple ['', Validators.required],
  public companyForm = this.fb.group({
    inputName: [''],
    inputId: [0],
    inputCountry: [''],
    inputZipcode: [''],
    inputType: [''],
  });

  public addCompanyForm = this.fb.group({
    companyName: ['', Validators.required],
    idCountry: [0, Validators.required],
    zipCode: ['', Validators.required],
  });
  

  testcount: number = 0;
  //Creer la list pour tous les pays
  listOfCountry : any;
  companyList: any;
  selectedCompany: any;
  companyAutocomplete: any;
  deleteAction: boolean = false;
  countInputID: number = 0;
  countInputName: number = 0;
  countInputZipcode: number = 0;
  countInputCountry: number = 0;
  countInputType: number = 0;
  heightAutocomplete: any;
  loader: boolean = false;

  constructor(
    private fb: FormBuilder,
    private getDataService: GetDataService,
    private refService: RefService,
    private companyService : CompanyService,
  ) { }

  ngOnInit() {
    this.refService.getCountry().subscribe(response => {
      this.listOfCountry = response;
    });
    this.companyList = [];
    this.companyAutocomplete = {
      Id: 0,
      Name: this.companyNameValue,
      ZipCode: '',
      Country: '',
      Type: ''
    };
    this.postApiAutocomplete(this.companyAutocomplete);
    this.companyForm.patchValue({ inputName: this.companyNameValue });
    $('#inputName').focus(); 
  }

  @HostListener('click')
  clickInside() {
    this.testcount += 1;
  }

  @HostListener('document:click')
  clickout() {
    this.testcount -= 1;
    if (this.testcount < -1) {
      this.closeAutocomplete();
    }
  }

  postApiAutocomplete(body) {
    this.loader = true;
    this.getDataService.postCompanyAutocomplete(body).subscribe(response => {
      this.companyList = [];
      if(response){
        for (let i = 0; i < response.length; i++) {
          if (response[i].companyName.length > 25){
            response[i].companyName = response[i].companyName.substring(0, 25);
            response[i].companyName += ' ...';
          }
          this.companyList.push(response[i]);
          //this.fixHeightAutocomplete();
        }
        console.log("ma list company");
        console.log(this.companyList);
        this.loader = false;

      }
    });
  }

  onInputChanges() {
    if ((this.companyForm.value.inputName.length > 2 || this.companyForm.value.inputId !== null || this.companyForm.value.inputCountry.length > 2 || this.companyForm.value.inputZipcode.length > 2 || this.companyForm.value.inputType.length > 2) || this.deleteAction === true) {
      this.companyAutocomplete = {
        Id: Number(this.companyForm.value.inputId),
        Name: this.companyForm.value.inputName.toString(),
        ZipCode: this.companyForm.value.inputZipcode.toString(),
        Country: this.companyForm.value.inputCountry.toString(),
        Type: this.companyForm.value.inputType.toString()
      };
      isNaN(this.companyAutocomplete.Id) ? this.companyAutocomplete.Id = 0 : this.companyAutocomplete.Id;
      this.postApiAutocomplete(this.companyAutocomplete);
    } else {
      this.postApiAutocomplete(this.companyAutocomplete);
    }
  }

  getCompanySelected(company) {
    this.selectedCompanyEmitter.emit(company);
    this.closeAutocomplete();
  }

  focusInputName() {
    $('#inputName').focus();
  }

  closeAutocomplete() {
    this.displayAutocomplete.emit(false);
  }

  resetCompanyForm() {
    this.companyForm.patchValue({
      inputName: '',
      inputId: '',
      inputZipcode: '',
      inputCountry: '',
      inputType: ''
    });
    this.displayAutocomplete.emit('CLEAR');
  }

  // fixHeightAutocomplete() {
  //   this.heightAutocomplete = -48 * ((parseInt(this.companyList.length.toString())) - 5);
  //   if (parseInt(this.companyList.length.toString()) >= 5) {
  //     $('#placeholder').css('margin-top', this.heightAutocomplete);
  //   } else {
  //     $('#placeholder').css('margin-top', '0');
  //   }
  // }

  changeInput(input) {
    const inputID = '#' + input;
    $(inputID).val().length;
    if ($(inputID).val().length > this.choiceCount(inputID)) {
      this.updateCount(inputID);
      this.deleteAction = false;
    } else {
      this.updateCount(inputID);
      this.deleteAction = true;
    }
    this.onInputChanges();
  }

  choiceCount(inputID) {
    if (inputID === '#inputName') {
      return this.countInputName;
    } if (inputID === '#inputCountry') {
      return this.countInputCountry;
    }
    if (inputID === '#inputZipcode') {
      return this.countInputZipcode;
    }
    if (inputID === '#inputType') {
      return this.countInputType;
    }
  }

  updateCount(inputID) {
    if (inputID === '#inputName') {
      this.countInputName = $(inputID).val().length;
    }
    if (inputID === '#inputCountry') {
      this.countInputCountry = $(inputID).val().length;
    }
    if (inputID === '#inputZipcode') {
      this.countInputZipcode = $(inputID).val().length;
    }
    if (inputID === '#inputType') {
      this.countInputType = $(inputID).val().length;
    }

  }
  onSubmit() {
    this.companyService.createCompany(this.addCompanyForm["value"]).subscribe(response => {
      console.log(response);
    });
    // Faire un console log de te from pour voir que les données sont les bonnes et ensuite faire le call api avec les donnée en parametre
    // Faire un call api pour créer la compagnie voir add contact dans la fonction onSubmit
    // Appeler la fonction createCompany() dans le file core/http/company.service (il faudra importer la class pour utiliser la fonction)
  }
}
