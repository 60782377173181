import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RefService {

  constructor(private http: HttpClient) { }

    //////////////////////////////////////// HTTP METHODS FOR REF //////////////////////////////////

    getTimezone() {
      return this.http.get(`${environment.apiUrl}/api/timezone`);
    }
    getTypeParticipant() {
      return this.http.get(`${environment.apiUrl}/api/participantType`);
    }
    getAttendanceType() {
      return this.http.get(`${environment.apiUrl}/api/attendanceType`);
    }
    getRegistrationChannel() {
      return this.http.get(`${environment.apiUrl}/api/registrationChannel`);
    }
    getHappeningType() {
      return this.http.get(`${environment.apiUrl}/api/happeningType`);
    }
    getRegion() {
      return this.http.get(`${environment.apiUrl}/api/region`);
    }
    getStatus() {
      return this.http.get(`${environment.apiUrl}/api/status`);
    }
    getEventStatus() {
      return this.http.get(`${environment.apiUrl}/api/eventStatus`);
    }
    getOnSiteEventStatus() {
      return this.http.get(`${environment.apiUrl}/api/onSiteEventStatus`);
    }
    getCountry() {
      return this.http.get(`${environment.apiUrl}/api/country`);
    }
    getTitle() {
      return this.http.get(`${environment.apiUrl}/api/title`);
    }
    getTransport() {
      return this.http.get(`${environment.apiUrl}/api/transport`);
    }
}
