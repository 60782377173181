import { Component, OnInit, Input } from '@angular/core';
import { ParamMap, ActivatedRoute } from '@angular/router';
import { EventService } from '../../../../core/http/event.service'

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {
  
  constructor(
    private route: ActivatedRoute,
    private eventService: EventService
  ) {}

  idEvent: any;
  updateLink: string;
  event: any;
  eventContact: any;
  eventMenu: any;
  dataEvent: any;
  dataEventMenu: any;
  dataEventContact: any;

  async ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.idEvent = params.get('id');
    });
    this.updateLink = '/admin/agenda/events/' + this.idEvent + "/edit";
    this.dataEvent = await this.eventService.getEventById(this.idEvent).toPromise();
    this.initEvent();
    this.dataEventContact = await this.eventService.getEventContact(this.idEvent).toPromise();
    this.initEventContact();
    this.dataEventMenu = await this.eventService.getEventMenu(this.idEvent).toPromise();
    this.initEventMenu();
  }
  initEvent() {
    this.event = [
      {
        title: 'EVENT INFORMATION', fields: [
          { title: 'EVENT', content: this.dataEvent?.eventName ? this.dataEvent.eventName : '-' },
          { title: 'TYPE', content: this.dataEvent?.type ? this.dataEvent.type : '-' },
          { title: 'LOCATION', content: this.dataEvent?.location ? this.dataEvent.location : '-' },
          { title: 'CAPACITY', content: this.dataEvent?.capacity ? this.dataEvent.capacity : '-' },
          { title: 'ORGANIZER', content: this.dataEvent?.organizer ? this.dataEvent.organizer : '-' },
          { title: 'REPLY TO', content: this.dataEvent?.replyTo ? this.dataEvent.replyTo : '-' },
          { title: 'PARTICIPANT', content: this.dataEvent?.typeParticipant ? this.dataEvent.typeParticipant : '-' },
        ]
      },
      {
        title: 'EVENT DATES', fields: [
          { title: 'DATE START', content: this.dataEvent?.startDate ? this.dataEvent.startDate : '-' },
          { title: 'DATE END', content: this.dataEvent?.endDate ? this.dataEvent.endDate : '-' },
          { title: 'DATE START REGISTRATION', content: this.dataEvent?.startDateRegistration ? this.dataEvent.startDateRegistration : '-' },
          { title: 'DATE END REGISTRATION', content: this.dataEvent?.endDateRegistration ? this.dataEvent.endDateRegistration : '-' },
          { title: 'TIMEZONE', content: this.dataEvent?.timezone ? this.dataEvent.timezone : '-' },
        ]
      },
      {
        title: 'EVENT TEXT', fields: [
          { title: 'DESCRIPTION', content: this.dataEvent?.description ? this.dataEvent.description : '-' },
          { title: 'PITCH', content: this.dataEvent?.pitch ? this.dataEvent.pitch : '-' },
          { title: 'LOGIN PAGE', content: this.dataEvent?.loginTxt ? this.dataEvent.loginTxt : '-' },
        ]
      },
      {
        title: 'EVENT ASSETS', fields: [
          { title: 'EVENT BANNER', content: this.dataEvent?.logoBanner ? this.dataEvent.logoBanner : '-' },
          { title: 'EVENT LOGO', content: this.dataEvent?.logo ? this.dataEvent.logo : '-' },
          { title: 'EVENT FOOTER', content: this.dataEvent?.footer ? this.dataEvent.footer : '-' },
        ]
      },
    ];
  }
  initEventContact() {
    this.eventContact = [
      {
        title: 'EVENT CONTACT INFORMATION', fields: [
          { content: this.dataEventContact?.contactInformation ? this.dataEventContact.contactInformation : '-' },
        ]
      },
    ];
  }
  initEventMenu() {
    this.eventMenu = [
      {
        title: 'EVENT MENU LIVE', fields: [
          { title: 'OPEN', content: this.dataEventMenu?.openLive == true ? "True" : 'False' },
          { title: 'PLACEHOLDER', content: this.dataEventMenu?.placeholder ? this.dataEventMenu.placeholder : '-' },
          { title: 'LIVE DATE', content: this.dataEventMenu?.liveDate ? this.dataEventMenu.liveDate : '-' },
          { title: 'LINK', content: this.dataEventMenu?.liveUrl ? this.dataEventMenu.liveUrl : '-' },
        ]
      },
      {
        title: 'EVENT MENU NETWORKING', fields: [
          { title: 'OPEN', content: this.dataEventMenu?.openNetworking == true ? "True" : 'False' },
        ]
      },
    ];
  }
}
