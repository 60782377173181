<!-- Desktop-->
<div class="display--nav-none">
  <nav class="mt-3">
    <div class="nav nav-tabs nav-responsive" id="nav-tab" role="tablist">
      <a class="nav-item nav-link active nowayRegular" id="nav-agenda_info" data-toggle="tab" href="#agenda_info"
        role="tab" aria-controls="nav-event_info" aria-selected="true" i18n>Agenda information</a>
      <span placement="right" popoverClass="popover-custom-light popover-width" ngbPopover="Edit agenda" triggers="mouseenter:mouseleave" [routerLink]="updateLink">
        <svg class="ml-3" width="30px" height="30px" aria-haspopup="true" aria-expanded="false">
          <use xlink:href="#edit"/>
        </svg>
      </span>
    </div>
  </nav>
  <div class="tab-content mt-4 pb-5" id="nav-tabContent">
    <div class="row" style="font-size: 13px">
      <div class="col-md-6" *ngFor="let section of agenda">
        <h6 class="nowayMedium" style="color: #244746; font-weight: bold;">{{section.title}}</h6>
        <div class="row mt-4">
          <div class="col-md-12">
            <div class="row" *ngFor="let field of section.fields">
              <ng-container *ngIf="field.title">
                <div *ngIf="field.title != 'PARTICIPANT MANDATORY' && field.title != 'PARTICIPANT OPTIONAL'" class="col-4">
                  <p class="fieldTitle">
                    {{field.title}}
                  </p>
                </div>
                <div *ngIf="field.title == 'PARTICIPANT MANDATORY' || field.title == 'PARTICIPANT OPTIONAL'" class="col-12">
                  <p class="fieldTitle">
                    {{field.title}}
                  </p>
                </div>
                <div *ngIf="!field.content2 && field.title != 'PARTICIPANT MANDATORY' && field.title != 'PARTICIPANT OPTIONAL'" class="col ft nowayRegular" style="color: #244746">
                  {{field.content}}
                </div>
                <div class="col ft mb-3" *ngIf="field.title == 'PARTICIPANT MANDATORY' || field.title == 'PARTICIPANT OPTIONAL'">
                  <ng-container *ngFor="let participant of field.content; last as isLast">
                    <span class="ft nowayRegular" style="color: #244746" *ngIf="!isLast">{{participant}} -&nbsp;</span>
                    <span class="ft nowayRegular" style="color: #244746" *ngIf="isLast">{{participant}}</span>
                  </ng-container>
                </div>
              </ng-container>
              <ng-container *ngIf="!field.title">
                <div class="row col-md-12 ft nowayRegular" style="color: #244746">
                  <p class="col-md-4-5 fieldTitle">{{field.title1}}</p>
                  <div class="col-md-1 ft nowayRegular" style="color: #244746">
                    {{field.content}}
                  </div>
                  <p class="col-md-4-5 fieldTitle pl-5">{{field.title2}}</p>
                  <div class="col-md-1 ft nowayRegular" style="color: #244746">
                    {{field.content2}}
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" *ngFor="let section of agendaDescription">
        <div class="row mt-4">
          <div class="col-md-12">
            <div class="row" *ngFor="let field of section.fields">
              <div class="col-4">
                <p class="fieldTitle">
                  {{field.title}}
                </p>
              </div>
              <div *ngIf="field.title != 'ITEM URL (Zoom, Link ...)'"class="col ft nowayRegular" style="color: #244746">
                {{field.content}}
              </div>
              <div class="col ft" *ngIf="field.title == 'ITEM URL (Zoom, Link ...)'">
                <a class="no_link_a nowayRegular" style="color: #244746" href="{{field.content}}" target="_blank">{{field.content}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

