<div class="container-fluid pb-4">
    <div class="maincontainer">

        <div class="row mt-4">

            <div class="col-lg-6 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <img class="float-left mt-5" src="assets/icon/navbarParticipants.svg" width="90" height="120"/>
                        <h4 class="text-center nowayRegular" i18n>Dashboard</h4>

                        <div class="list-group p-3">
                            <button type="button" class="list-group-item list-group-item-action" [routerLink]="['/admin/tools/wiki/dashboard/cards']" i18n>Cards</button>
                            <button type="button" class="list-group-item list-group-item-action">...</button>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <img class="float-left mt-5" src="assets/icon/navbarCommunication.svg" width="90" height="120"/>
                        <h4 class="text-center nowayRegular" i18n>Forms</h4>

                        <div class="list-group p-3">
                            <button type="button" class="list-group-item list-group-item-action" i18n>Input</button>
                            <button type="button" class="list-group-item list-group-item-action" i18n>...</button>
                            <!-- <button type="button" class="list-group-item list-group-item-action">Push notification</button>
                            <button type="button" class="list-group-item list-group-item-action">Reports</button> -->
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="row mt-3">

            <div class="col-lg-6 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <img class="float-left mt-3" src="assets/icon/navbarProgramme.svg" width="90" height="120"/>
                        <h4 class="text-center nowayRegular" i18n>Templates</h4>

                        <div class="list-group p-3">
                            <button type="button" class="list-group-item list-group-item-action" [routerLink]="['/admin/tools/wiki/templates/list-model']" i18n>Listes</button>
                            <button type="button" class="list-group-item list-group-item-action" [routerLink]="['/admin/tools/wiki/templates/fiche-model']" i18n>Fiches personne</button>
                            <button type="button" class="list-group-item list-group-item-action" [routerLink]="['/admin/tools/wiki/templates/main-dashboard']" i18n>Dashboard</button>
                            <button type="button" class="list-group-item list-group-item-action" [routerLink]="['/admin/tools/wiki/templates/landing-page']" i18n>Landing page</button>
                            <button type="button" class="list-group-item list-group-item-action" [routerLink]="['/admin/tools/wiki/templates/broadcast']" i18n>Broadcast</button>
                            <button type="button" class="list-group-item list-group-item-action" [routerLink]="['/admin/tools/wiki/templates/settings-module']" i18n>Settings</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <img class="float-left mt-3" src="assets/icon/navbarTools.svg" width="90" height="120"/>
                        <h4 class="text-center nowayRegular" i18n>Internal Library</h4>

                        <div class="list-group p-3">
                            <button type="button" class="list-group-item list-group-item-action" [routerLink]="['/admin/tools/wiki/svg-library']" i18n>SVG Library</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
