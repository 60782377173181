import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormDataParticipantService {

  constructor(
    private fb: FormBuilder
  ) { }

  dataParticipant: any;
  dataSimple() {
    return this.dataParticipant = this.fb.group({
      companyName: ['', Validators.required],
      idCompany: [0],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required]
    });
  }
  dataAttendee() {
    return this.dataParticipant = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      idTitle: [0],
      jobPosition: [''],
      mobile: [''],
      email: ['', Validators.required],
      email2: [''],
      ccAssistant: [false],
      firstNameAssistant: [''],
      lastNameAssistant: [''],
      idTitleAssistant: [0],
      jobPositionAssistant: [''],
      telephoneAssistant: [''],
      mobileAssistant: [''],
      faxAssistant: [''],
      emailAssistant: [''],
      address: [''],
      address2: [''],
      city: [''],
      state: [''],
      zipCode: [''],
      idCountry: [0],
      // contactNameEmergency: [''],
      // contactNumberEmergency: [''],
      // uploadPicture: [''],
    });
  }
  dataContact() {
    return this.dataParticipant = this.fb.group({
      statusContact: [''],
      idChannelType: [0],
      idAttendanceType: [0],
      idRegistrationChannel: [0],
      vip: [false],
      incident: [false],
      main: [false],
      doNotPostEmail: [false],
      shareEmail: [false],
      shareMobile: [false],
      comments: ['', Validators.maxLength(1000)],
      profileUpdate: [false],
      idRegion: [0],
      photoUrl: ['']
    });
  }
  dataTravel() {
    return this.dataParticipant = this.fb.group({
      idEvent: [0],
      comments: [''],
      otherComments: [''],
      idArrivalTransport: [0],
      arrivalDate: [''],
      arrivalHour: '',
      arrivalCity: [''],
      arrivalAirport: [''],
      arrivalFlight: [''],
      arrivalAirline: [''],
      arrivalStation: [''],
      arrivalTrain: [''],
      idDepartureTransport: [0],
      departureDate: [''],
      departureHour: '',
      departureCity: [''],
      departureAirport: [''],
      departureFlight: [''],
      departureAirline: [''],
      departureStation: [''],
      departureTrain: [''],
      ownTransport: [false],
    });
  }
  dataHotel() {
    return this.dataParticipant = this.fb.group({
      idEvent: [0],
      idNight: [0],
    });
  }
}
