import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-dashboard-with-percent',
  templateUrl: './card-dashboard-with-percent.component.html',
  styleUrls: ['./card-dashboard-with-percent.component.scss']
})
export class CardDashboardWithPercentComponent implements OnInit {
  constructor() { }
  @Input() data: any;
  ngOnInit() {
  }

  tauxProgress(taux: number) {
    return Math.round(taux * 100);
  }

}
