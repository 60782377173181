import { Component, OnInit, Input } from '@angular/core';
import { ParamMap, ActivatedRoute, Router } from '@angular/router';
import { RefService } from '../../../../core/http/ref.service';
import { CompanyService } from '../../../../core/http/company.service';
import { ToastrService } from 'ngx-toastr';
import { fadeInDownOnEnterAnimation, fadeOutUpOnLeaveAnimation } from 'angular-animations';
import { FormDataCompanyService } from '../../../../core/http/form-data-company.service';

declare var $: any;



@Component({
  selector: 'app-company-edit-form',
  templateUrl: './company-edit-form.component.html',
  styleUrls: ['./company-edit-form.component.scss'],
  animations: [fadeInDownOnEnterAnimation({ translate: '30px' }),
  fadeOutUpOnLeaveAnimation({ translate: '30px' }),
  ],

})
export class CompanyEditFormComponent implements OnInit {

  listOfCountry: any;
  listOfChannelType: any;
  listOfAttendanceType: any;
  companyForm: any;
  companyOrderForm: any;
  dataCompany: any;
  dataCompanyOrder: any;
  idCompany: any;
  initialCompanyForm: any;
  initialOrderForm: any;
  isCompanyHasChange: boolean = false;
  isOrderHasChange: boolean = false;

  constructor(
    private refService: RefService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private formDataCompanyService: FormDataCompanyService,
    private companyService: CompanyService,
  ) {}

  async ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.idCompany = params.get('id');
    });
    this.initRef();
    this.companyForm = this.formDataCompanyService.data();
    this.companyOrderForm = this.formDataCompanyService.dataOrder();
    await this.initData();
    this.initCompanyForm();
    this.initCompanyOrderForm();
    this.initialCompanyForm = this.companyForm.value;
    this.companyForm.valueChanges.subscribe(value => {
      this.isCompanyHasChange = Object.keys(this.initialCompanyForm).some(key =>
        this.companyForm.value[key] != this.initialCompanyForm[key])
      }
    );
    this.initialOrderForm = this.companyOrderForm.value;
    this.companyForm.valueChanges.subscribe(value => {
      this.isCompanyHasChange = Object.keys(this.initialCompanyForm).some(key =>
        this.companyForm.value[key] != this.initialCompanyForm[key])
      }
    );
  }
  async initData() {
    this.dataCompany = await this.companyService.getCompanyByID(this.idCompany).toPromise();
    this.dataCompanyOrder = await this.companyService.getCompanyOrder(this.idCompany).toPromise();
  }
  initRef() {
    this.refService.getCountry().subscribe(res => {
      this.listOfCountry = res;
    });
    this.refService.getTypeParticipant().subscribe( response => {
      this.listOfChannelType = response;
    });
    this.refService.getAttendanceType().subscribe( response => {
      this.listOfAttendanceType = response;
    });
  }
  initCompanyForm() {
    this.companyForm.patchValue({
      companyName: this.dataCompany.company,
      group: this.dataCompany.group,
      address: this.dataCompany.address,
      address2: this.dataCompany.address2,
      zipCode: this.dataCompany.zipCode,
      city: this.dataCompany.city,
      state: this.dataCompany.state,
      idCountry: this.dataCompany.idCountry,
      telephone: this.dataCompany.telephone,
      fax: this.dataCompany.fax,
      website: this.dataCompany.website,
      channelType: this.dataCompany.channelType,
      profileUpdate: this.dataCompany.profileUpdate,
      comments: this.dataCompany.comments,
      tweetPich: this.dataCompany.tweetPich,
      logoUrl: this.dataCompany.logoUrl,
      profileUrl: this.dataCompany.profileUrl,
      profileType: this.dataCompany.profileType,
    })
  }
  initCompanyOrderForm() {
    this.companyOrderForm.patchValue({
      boardrooms: this.dataCompanyOrder.boardrooms,
      oneOnOne: this.dataCompanyOrder.oneOnOne,
      nbOne: this.dataCompanyOrder.nbOne,
      nbRequest: this.dataCompanyOrder.nbRequest,
      boothNumber: this.dataCompanyOrder.boothNumber,
      virtualType: this.dataCompanyOrder.virtualType == true ? 1 : 0,
    });
  }

  onSubmit() {
    this.companyService.updateCompany(this.companyForm["value"], this.idCompany);
    this.companyService.updateCompanyOrder(this.companyOrderForm["value"], this.idCompany);
    this.toastr.clear();
    this.toastr.success('Success', 'Company Update');
    this.router.navigateByUrl(`/admin/company/${this.idCompany}/details`);
  }

  cancelEdit() {
    this.isOrderHasChange = false;
    this.isCompanyHasChange = false;
    this.ngOnInit();
  }
  changeOrderForm() {
    this.companyOrderForm.valueChanges.subscribe(value => {
      this.isOrderHasChange = Object.keys(this.initialOrderForm).some(key =>
        this.companyOrderForm.value[key] != this.initialOrderForm[key])
      }
    );
  }
  changeCompanyForm() {
    this.companyForm.valueChanges.subscribe(value => {
      this.isCompanyHasChange = Object.keys(this.initialCompanyForm).some(key =>
        this.companyForm.value[key] != this.initialCompanyForm[key])
      }
    );
  }

  clickSave() {
  }



  hideMore() {
  }


  clickCancelCompany() {
  }

  clickSkip = function () {
  }


  hideSkip() {
  }

  inputName_click() {
  }

  companyName_click() {
  }

  getCompany(company) {
  }

  DisplayAutocomplete() {
  }

  hideAutocomplete() {
  }

  filter() {
  }

  changeInput() {
  }
}
