<app-fil-ariane-v2></app-fil-ariane-v2>
<div class="container-fluid font-color">
  <div class="maincontainer">
    <div class="row">
      <div class="col-lg-12 col-md-12 mt-4">
        <!-- <app-hotels-header></app-hotels-header> -->
        <app-hotels-edit-form></app-hotels-edit-form>
      </div>
    </div>
  </div>
</div>