import { Component, OnInit, Input } from '@angular/core';
import { EventService } from '../../../../core/http/event.service';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-event-header',
  templateUrl: './event-header.component.html',
  styleUrls: ['./event-header.component.scss']
})
export class EventHeaderComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private eventService: EventService
  ) { }

  listOfDetailsEvent: any;
  idEvent: any;

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.idEvent = params.get('id');
    });
    this.eventService.getEventById(this.idEvent).subscribe(res => {
      this.listOfDetailsEvent = res;
    });
  }
}

