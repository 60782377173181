import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-dashboard-email',
  templateUrl: './card-dashboard-email.component.html',
  styleUrls: ['./card-dashboard-email.component.scss']
})
export class CardDashboardEmailComponent implements OnInit {
  constructor() { }
  @Input() data: any;
  ngOnInit() {
  }

  tauxProgress(taux: number) {
    return Math.round(taux * 100);
  }

}
