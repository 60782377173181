<div class="row" *ngIf='!displayTaskDetails' [ngClass]="task.milestone ? 'bodyMilestone' : 'body'">  <!--cdkDrag cdkDragLockAxis="y"-->
  <div class="col-2 svgIcon" style="text-align: end; padding-right: 0px;flex: 0 0 22%!important;max-width: 19.666667%!important; " [ngClass]="task.milestone ? 'col2Milestone' : 'col-2'"> <!--(click)="status(1, taskTodo)"-->
    <svg [ngClass]="task.priorityNumber === '0' ? 'taskNonPrio' : 'taskPrio'" height="24px" width="24px" *ngIf="task.statut === 'todo' ">
      <use xlink:href="#taskTodo"/>
    </svg>
    <svg [ngClass]="task.priorityNumber === '0' ? 'taskNonPrio' : 'taskPrio'" height="24px" width="24px" *ngIf="task.statut === 'inProgress' ">
      <use xlink:href="#taskIP"/>
    </svg>
    <svg class="taskNonPrio" height="24px" width="24px" *ngIf="task.statut === 'done' ">
      <use xlink:href="#taskDone"/>
    </svg>
  </div>

  <div class="col-10 taskBody assigned" style="height: auto;max-width: 80.333333%!important;" [ngClass]="task.milestone ? 'col10Milestone' : 'col-10'">      <!--(click)="getData(taskTodo)"-->
    <p class="mt-0 mb-0 assignation" *ngIf="task.statut !== 'done'" i18n>ASSIGNED TO:
      <span>{{task.assignedTo.toUpperCase()}}</span>
    </p>
    <p class="mt-0 mb-0 assignation" *ngIf="task.statut === 'done'" i18n>DONE BY:
      <span>{{task.doneBy[0].toUpperCase()}}</span>
    </p>

    <div class="row justify-content-between">
      <div class="btn-group dropleft">
        <svg class="dropdown-toggle" id="dropdownMenuButton" style="cursor:pointer;opacity: 0.2;margin-left: -7px;" height="24px" width="24px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <use xlink:href="#more-vertical"/>
        </svg>
        <div class="dropdown-menu dropleft" aria-labelledby="dropdownMenuButton" style="width: 185px;">
          <span class="dropdown-menu-arrow"></span>
          <a class="dropdown-item" *ngIf="task.statut !== 'inProgress'" (click)="swipTask1(task)" i18n>Move to in progress</a>
          <a class="dropdown-item" *ngIf="task.statut !== 'done'" (click)="swipTask2(task)" i18n>Move to done</a>
          <a class="dropdown-item" *ngIf="task.statut !== 'todo'" (click)="swipTask3(task)" i18n>Move to to Do</a>
          <a class="dropdown-item" *ngIf="!milestoneActivate" (click)="swipMilestone(task)" i18n>Pass to milestone</a>
          <a class="dropdown-item" *ngIf="milestoneActivate" (click)="swipMilestone(task)" i18n>Take off milestone</a>
          <a class="dropdown-item" (click)='displayTask()' i18n>Task details</a>
          <a class="dropdown-item" (click)="editTask(task)" i18n>Edit task</a>
          <a class="dropdown-item" (click)='deleteTask()' i18n>Delete task</a>
        </div>
      </div>
      <!-- <div>
          <span *ngIf="task.priority" class="badge badge-pill badge-{{task.priorityClass}}">{{task.priorityLabel.toUpperCase()}}</span>
      </div> -->
    </div>

    <p *ngIf="task.dueDate.year > 0 && task.statut !== 'done'" class="mt-0 mb-0" [ngClass]="dateIsPassed ? 'is-passed' : 'is-not-passed'" i18n>
      DUE DATE {{task.dueDate.year}}/{{task.dueDate.month}}/{{task.dueDate.day}}
    </p>
    <p *ngIf="task.dueDate.year === 0 && task.statut !== 'done'" class="mt-0 mb-0 noDueDate" i18n>
      NO DUE DATE
    </p>
    <p *ngIf="task.statut === 'done'" class="mt-0 mb-0 is-not-passed" i18n>
      DONE DATE {{task.doneDate.year}}/{{task.doneDate.month}}/{{task.doneDate.day}}
    </p>
    <p class="mt-0 mb-0 title .nowayRegular" (click)='displayTask()'>{{task.taskTitle}}</p>
    <p style="position: relative; left: 200px; top: -43px;"  *ngIf="task.milestone === true">!</p>
  </div>

</div>

<!-- TASK DETAILS -->
<div *ngIf='displayTaskDetails'>
  <hr>
  <div class="firstBox">
    <svg class="svgPlus" (click)="displayTask()" height="24px" width="24px">
      <use xlink:href="#plus"/>
    </svg>
    <div class="taskDetailsTitle">
      <svg class="svgEdit" (click)="editTask(task)" height="24px" width="24px">
        <use xlink:href="#edit"/>
      </svg>
      <p *ngIf="task.milestone === true">!</p>
      {{task.taskTitle}}
    </div>
    <div class="headerRow">
      <div>
        <h2 i18n>Assigned to : {{task.assignedTo}}</h2>
      </div>
      <div>
        <h2 i18n>Status : {{task.statut}}</h2>
      </div>
    </div>
    <div class="headerRow">
      <div>
        <h2 i18n>Due date :
          <span *ngIf="task.dueDate.year > 0" [ngClass]="dateIsPassed ? 'is-passed' : 'is-not-passed'">
                        {{task.dueDate.year}}/{{task.dueDate.month}}/{{task.dueDate.day}}
                    </span>
          <span *ngIf="task.dueDate.year === 0" class="is-not-passed" style="font-style:italic">
                            No due date
                    </span>
        </h2>
      </div>
      <div>
        <h2 i18n>
          Priority :
          <span class="badge badge-pill badge-{{task.priorityClass}} badgeTask">{{task.priorityLabel}}</span>
          <span class="response" *ngIf="!task.priority" style="font-style:italic">None</span>
        </h2>

      </div>
    </div>
    <div class="headerRow" [ngClass]="taskDone ? '' : 'lastRow'">
      <div>
        <h2 i18n>Create by : {{task.userCreate}}</h2>
      </div>
      <div>
        <h2 i18n> On : {{task.dateCreate}}</h2>
      </div>
    </div>
    <div class="headerRow lastRow" *ngIf="task.statut === 'done'">
      <div>
        <h2 i18n>Done by : {{task.doneBy[0]}}</h2>
      </div>
      <div>
        <h2 i18n> On : {{task.doneDate.year}}/{{task.doneDate.month}}/{{task.doneDate.day}}</h2>
      </div>
    </div>
  </div>

  <div class="detailsBox">
    <p>{{task.taskContent}}</p>
    <p *ngIf="task.taskContent.length === 0" style="font-style:italic" i18n>No details for this task</p>
  </div>
  <hr style="margin-bottom: 2rem">
</div>



<!-- MODAL TASK DETAILS -->
<!-- <div class="modal fade" id="exampleModal{{index}}" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header" style="background-color: #f5f5ed">
                <h5 class="modal-title" id="exampleModalLabel">{{task.taskTitle}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="outline: none">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body" style="background-color: #f5f5ed">

                <div class="firstBox">
                    <div class="headerRow">
                        <h2>Assigned to :</h2>
                            <span class="response">{{task.assignedTo}}</span>
                    </div>
                    <div class="headerRow">
                        <h2>Status :</h2>
                        <span class="response">{{task.statut}}</span>
                    </div>
                    <div class="headerRow">
                        <h2>Priority :</h2>
                        <span class="badge badge-pill badge-{{task.priorityClass}} badgeTask">{{task.priorityLabel}}</span>
                        <span class="response" *ngIf="!task.priority" style="font-style:italic">None</span>
                    </div>
                    <div class="headerRow">
                        <h2>Due date :</h2>
                        <span *ngIf="task.dueDate.year > 0" [ngClass]="dateIsPassed ? 'is-passed' : 'is-not-passed'">
                            {{task.dueDate.year}}/{{task.dueDate.month}}/{{task.dueDate.day}}
                        </span>
                        <span *ngIf="task.dueDate.year === 0" class="is-not-passed" style="font-style:italic">
                                No due date
                        </span> 
                    </div>
                    <div class="headerRow lastRow">
                        <h2>Create by : &nbsp;</h2>
                        <strong> {{task.userCreate}} &nbsp;</strong>
                        <h2> on : &nbsp;</h2>
                        <strong>{{task.dateCreate}}</strong>
                    </div>
                </div> 

                <div class="detailsBox">
                    <p>{{task.taskContent}}</p>
                    <p *ngIf="task.taskContent.length === 0" style="font-style:italic">No details for this task</p>
                </div>
                
                <div class="commentBox" *ngFor="let comment of task.taskComment;  let index2 = index;">
                    <div style="display: flex;justify-content: space-between">
                        <div style="display: flex">
                            <div class="avatarUser" [style.background-color]="comment.userColor">{{userInitialName[index2]}}</div>
                            <p>{{comment.user}} -  {{comment.dateComment}}</p>
                    </div>
                        <div style="display: flex">
                                <svg  width="20px" height="20px" viewBox="0 0 24 24"
                                    style="fill:#84C000" (click)="modifComment(index2)" data-toggle="modal" data-backdrop="static" attr.data-target="#modif3Comment{{index}}">
                                <use xlink:href="#edit"/>
                                </svg>&nbsp;
                                <svg  width="20px" height="20px" viewBox="0 0 24 24"
                                    style="fill:#84C000" (click)="deleteComment(index2)">
                                    <use xlink:href="#tratsh"/>
                                </svg>
                          </div>
                    </div>
                    <p>{{comment.comment}}</p>
                </div>

                <div class="commentBox" *ngIf="task.taskComment.length === 0">
                    <span >Pas encore de commentaires ! Commentez en cliquant <a data-toggle="modal" attr.data-target="#modifTask{{index}}" (click)="patchFormDatas()" style="text-decoration: underline; color:blue; cursor:pointer">ici</a></span>
                </div>

                <div class="modal-footer row mt-3">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!---->

<!-- MODAL TASK MODIFICATION
<div class="modal fade" id="modifTask{{index}}" tabindex="-1" role="dialog" aria-labelledby="addTasksLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">

            <div class="modal-header">
                <h5 class="modal-title">Edit task</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="outline: none">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <form (ngSubmit)="modifTaskForm.valid && onSubmit()" [formGroup]='modifTaskForm'>
                <div class="modal-body">

                    <div class="form-group">
                        <label for="taskTitle">Title *</label>
                        <input  type="text"
                                [ngClass]="modifTaskForm.get('taskTitle').errors ? 'is-invalid' : 'is-valid'"
                                class="form-control" id="taskTitle"
                                formControlName="taskTitle">
                        <small>{{modifTaskForm.value.taskTitle.length}} Characters (Max. 35)</small>
                    </div>

                    <div class="form-group">
                        <label for="taskContent">Task details</label>
                        <textarea
                                class="form-control" id="taskContent"
                                formControlName="taskContent"
                                rows="3">
                        </textarea>
                    </div>

                    <div class="row justify-content-between">

                        <div class="form-group col-6">
                            <label for="dueDate">Due date</label>
                            <div class="input-group">
                                <input class="form-control"
                                       name="dp" formControlName="dueDate" ngbDatepicker #d="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-6">
                            <label for="priority">Priority *</label>
                            <select class="form-control" id="priority" formControlName="priority">
                                <option value="none">None</option>
                                <option value="low">Low</option>
                                <option value="medium">Medium</option>
                                <option value="high">High</option>
                            </select>
                        </div>

                        <div class="form-group col-12">
                            <label for="statut">Change statut</label>
                            <select class="form-control" id="statut" formControlName="statut">
                                <option value="To do">To do</option>
                                <option value="In progress">In progress</option>
                                <option value="Done">Done</option>
                            </select>
                        </div>

                        <div class="form-group col-12">
                            <label for="taskComment">Add a comment</label>
                            <textarea
                                    class="form-control" id="taskComment"
                                    formControlName="taskComment"
                                    rows="2">
                            </textarea>
                        </div>

                        <div class="form-group col-6">
                            <label for="assignedTo">Assigned to: *</label>
                            <select class="form-control" id="assignedTo" formControlName="assignedTo">
                                <option *ngFor="let user of users" [value]="user">{{user}}</option>
                            </select>
                        </div> 

                    </div>

                    <div class="mb-5">
                        <button type="submit" class="btn btn-success button--color float-right"
                                [disabled]="!modifTaskForm.valid">Save Task
                        </button>
                        <button type="button" class="btn btn-secondary float-left"
                                data-dismiss="modal">Close
                        </button>
                    </div>

                </div>
            </form>

        </div>
    </div>
</div> -->



<!-- MODIF COMMENT MODAL
<div class="modal" id="modifComment{{index}}" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Edit a Comment</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form (ngSubmit)="onSubmitComment()" [formGroup]='modifCommentForm'>
                <div class="modal-body">
                    <textarea
                            class="form-control" id="comment"
                            formControlName="comment"
                            rows="2">
                    </textarea>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="openModalDetails()" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Save changes</button>
                </div>
            </form>
        </div>
    </div>
</div> -->


<svg style="display: none;">
    <symbol id="edit" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
        <path d="M14,3.41421356 L4,13.4142136 L4,16 L6.58578644,16 L16.5857864,6 L14,3.41421356 Z M21,21 C21.5522847,21 22,21.4477153 22,22 C22,22.5522847 21.5522847,23 21,23 L21,23 L3,23 C2.44771525,23 2,22.5522847 2,22 C2,21.4477153 2.44771525,21 3,21 L3,21 Z M14.7071068,1.29289322 L18.7071068,5.29289322 C19.0976311,5.68341751 19.0976311,6.31658249 18.7071068,6.70710678 L7.70710678,17.7071068 C7.5195704,17.8946432 7.26521649,18 7,18 L3,18 C2.44771525,18 2,17.5522847 2,17 L2,13 C2,12.7347835 2.10535684,12.4804296 2.29289322,12.2928932 L13.2928932,1.29289322 C13.6834175,0.902368927 14.3165825,0.902368927 14.7071068,1.29289322 Z"
        id="path-1"></path>
    </symbol>
</svg>
