<div class="font-color pb-4">
  <div style="width: 100%;border-top: 3px solid #eae5d5; background-color: #fff; margin-bottom: 1%;">
    <nav class="breadcrumb-responsive filAriane row maincontainer" aria-label="breadcrumb">
      <ol class="breadcrumb_fil">
        <svg id="dropdownMenuButton6" height="24px" width="24px" aria-haspopup="true" aria-expanded="false"
            style="fill:#84C000">
          <use xlink:href="#company_f" />
        </svg>
        <div style="display:flex; align-items: center;">
          <ng-container *ngFor="let item of url; let myIndex = index; first as isFirst; last as isLast" >
            <li class="breadcrumb-item" *ngIf="myIndex == 2"><a href="/admin/contacts">{{url[myIndex].replaceAll('-', ' ')}}</a></li>
            <li class="breadcrumb-item" *ngIf="myIndex > 2"><a>{{url[myIndex].replaceAll('-', ' ')}}</a></li>
          </ng-container>
          <li>&nbsp;/&nbsp;</li>
          <li style="width: 400px;">
            <app-form-search-select (afterChange)="afterChange($event)"></app-form-search-select>
          </li>
        </div>
      </ol>
      <div class="icons">
        <div class="btn-group dropleft ml-3">
          <span placement="left" popoverClass="popover-custom-light popover-width" ngbPopover="Add more" triggers="mouseenter:mouseleave" id="AddThings" data-toggle="dropdown" >
            <svg aria-haspopup="true" aria-expanded="false" class="iconFilArianne">
                <use xlink:href="#plus" />
            </svg>
          </span>
          <div class="dropdown-menu" aria-labelledby="AddThings">
              <a class="dropdown-item" [routerLink]="['/admin/agenda/events/add']" placement="left" popoverClass="popover-custom-light" ngbPopover="New event" triggers="mouseenter:mouseleave" i18n>New event</a>
              <a class="dropdown-item" [routerLink]="['/admin/contacts/add']" placement="left" popoverClass="popover-custom-light" ngbPopover="New contact" triggers="mouseenter:mouseleave" i18n>New contact</a>
          </div>
        </div>
        <div class="btn-group dropleft ml-3">
          <span placement="right" popoverClass="popover-custom-light popover-width" ngbPopover="More option" triggers="mouseenter:mouseleave" id="dropdownMenuButton2" data-toggle="dropdown" >
            <svg aria-haspopup="true" aria-expanded="false" class="iconFilArianne">
                <use xlink:href="#action_h" />
            </svg>
          </span>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
              <a class="dropdown-item" href="#" i18n>Action 1</a>
              <a class="dropdown-item" href="#" i18n>Action 2</a>
              <a class="dropdown-item" href="#" i18n>Action 3</a>
              <a class="dropdown-item" href="#" i18n>Action 4</a>
          </div>
        </div>
      </div>
    </nav>
  </div>
  <div class="maincontainer">
    <div class="size__card--responsive">
      <div class="responsive-card">
        <div *ngFor="let card of dataCardSend"> 
          <app-card-partcipants-funnel *ngIf='card.cardType === 4' [data]="card.cardData"></app-card-partcipants-funnel>
        </div>
      </div>
      <div class="card-columns">
        <div *ngFor="let card of dataCardSend">
          <div class="responsive-card" *ngIf='card.cardType !== 4'>
            <app-card-participants *ngIf='card.cardType === 1' [data]="card.cardData"></app-card-participants>
            <app-card-pie-chart *ngIf='card.cardType === 2' [data]="card.cardData"></app-card-pie-chart>
            <app-card-edit-attendee  *ngIf='card.cardType === 3'  [data]="card.cardData"></app-card-edit-attendee>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
