import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DatePipe } from '@angular/common'
import { FormBuilder, FormGroup } from '@angular/forms';
import { RefService } from '../../../core/http/ref.service';
import { ParticipantService } from '../../../core/http/participant.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-update-participant-event',
  templateUrl: './update-participant-event.component.html',
  styleUrls: ['./update-participant-event.component.scss']
})
export class UpdateParticipantEventComponent implements OnInit {

  @Input() data: any; // On recoit les data a afficher depuis List-User-Desktop

  isUpdate: boolean = false;
  updateEventRegistrationFormGroup: FormGroup;
  listOfType: any;
  listOfOnsiteEvent: any;
  listOfStatus: any;
  typeLabel: string;
  statusLabel: string;
  onsiteLabel: string;
  dateOnsite: string;
  dateStatus: string;
  dateNow: Date = new Date();
  detailUrl: string;
  updateUrl: string;

  constructor(
    private fb: FormBuilder,
    private RefService: RefService,
    private ParticipantService: ParticipantService,
    private toastr: ToastrService,
    private datepipe: DatePipe
  ) {}
  
  ngOnInit() {
    this.detailUrl = `/admin/agenda/events/${this.data.idEvent}/details`;
    this.updateUrl = `/admin/agenda/events/${this.data.idEvent}/edit`;
    this.dateOnsite = this.data.dateOnsite;
    this.dateStatus = this.data.dateStatus;
    this.RefService.getTypeParticipant().subscribe( response => {
      this.listOfType = response;
      for (let i = 0; this.listOfType.length > i; i++) {
        if (this.listOfType[i].short == "dis") { this.listOfType.splice(i, 1); }
        if (this.listOfType[i].short == "ret") { this.listOfType.splice(i, 1); }
        if (this.listOfType[i].id == this.data.idType) { this.typeLabel = this.listOfType[i].label; }
      }
    });
    this.RefService.getOnSiteEventStatus().subscribe( response => {
      this.listOfOnsiteEvent = response;
      for (let i = 0; this.listOfOnsiteEvent.length > i; i++) {
        if (this.listOfOnsiteEvent[i].short == this.data.onsite && this.listOfOnsiteEvent[i].short != "") { this.onsiteLabel = this.listOfOnsiteEvent[i].label }
        else if (this.listOfOnsiteEvent[i].short == "") { this.onsiteLabel = "" }
      }
    });
    this.RefService.getEventStatus().subscribe( response => {
      this.listOfStatus = response;
      for (let i = 0; this.listOfStatus.length > i; i++) {
        if (this.listOfStatus[i].short == this.data.status) { this.statusLabel = this.listOfStatus[i].label }
      }
    });
    this.updateEventRegistrationFormGroup = this.fb.group({
      idEvent: [this.data.idEvent],
      idType: [this.data.idType],
      status: [this.data.status],
      onsiteStatus: [this.data.onsite]
    });
  }
  updateOn() {
    this.isUpdate = true;
  }
  updateNo() {
    this.isUpdate = false;
  }
  onSubmit() {
    this.ParticipantService.updateParticipantEventRegistration(this.updateEventRegistrationFormGroup["value"], this.data.idPerson).subscribe(data => {
      console.log("Success", data);
      this.toastr.clear();
      this.toastr.success('Success', 'Participant Event Update');
      this.isUpdate = false;
      for (let i = 0; this.listOfType.length > i; i++) {
        if (this.listOfType[i].id == this.updateEventRegistrationFormGroup["value"].idType) { this.typeLabel = this.listOfType[i].label; }
      }
      for (let i = 0; this.listOfStatus.length > i; i++) {
        if (this.listOfStatus[i].short == this.updateEventRegistrationFormGroup["value"].status) { this.statusLabel = this.listOfStatus[i].label }
      }
      if (this.updateEventRegistrationFormGroup["value"].status != this.data.status) { this.dateStatus = this.datepipe.transform(this.dateNow, 'yyyy-MM-dd'); }
      for (let i = 0; this.listOfOnsiteEvent.length > i; i++) {
        if (this.listOfOnsiteEvent[i].short == this.updateEventRegistrationFormGroup["value"].onsiteStatus) { this.onsiteLabel = this.listOfOnsiteEvent[i].label }
      }
      if (this.updateEventRegistrationFormGroup["value"].onsiteStatus != this.data.onsite) { this.dateOnsite = this.datepipe.transform(this.dateNow, 'yyyy-MM-dd'); }
    }, error => {
      console.log("Error", error);
      this.toastr.clear();
      this.toastr.error('Error', 'Participant Event Fail');
    });
  }
}
