<!-- LISTE DESKTOP -->
<!-- <div *ngIf="!mobileRes">          -->
<div>
    <app-fil-ariane-emailing></app-fil-ariane-emailing>

    <div id="listUsersDesktop">
        
        
        <div class="bg-color">
            <div class="maincontainer">
                <div class="row sortBy pl-15">
                    <div class="col-1 d-flex justify-content-center align-items-center">
                        <span i18n>From</span>
                    </div>
                    <div class="col-2 p-0">
                        <!-- <label for="sendingDate">Sending date</label> -->
                        <div class="input-group">
                            <input class="form-control" name="fromDate" ngbDatepicker #a="ngbDatepicker" [(ngModel)]="startDate">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="a.toggle()" type="button"></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-1 d-flex justify-content-center align-items-center p-0">
                        <span class="m-0" i18n>To</span>
                    </div>
                    <div class="col-2 p-0">
                        <!-- <label for="sendingDate">Sending date</label> -->
                        <div class="input-group">
                            <input class="form-control" name="toDate" ngbDatepicker #d="ngbDatepicker" [(ngModel)]="toDate">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-1">
                    </div>
                    <div class="col-2" style="margin:0 2%!important;width:12%" >
                        <input type="text" class="input form-control" id="mailingID"
                            placeholder="Mailing ID">
                    </div>
                    <div class="col-1">
                        <button style="height: 38px;" type="button"
                        class="btn btn-success button--color btn-size btn-responsive" i18n>Search
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="maincontainer">
            
            <div style="width: auto!important;" class="row headerList justify-content-between">
            
                <div class="col-3">
                </div>
                
                <div class="pagination" style="white-space: nowrap;">
                
                    <pagination-controls (pageChange)="pageChange($event)" class="myPagination" previousLabel="" nextLabel=""
                    maxSize="5"></pagination-controls>
                
                    <div style="padding-right: 25px;" class="nbItems">
                    <span i18n>Show</span>
                    <div class="select-custom">
                        <select [(ngModel)]="nbItems" (ngModelChange)='onItemsChange($event);'>
                        <option selected [value]="10">10</option>
                        <option [value]="50">50</option>
                        <option [value]="100">100</option>
                        <option [value]="300">300</option>
                        <option [value]="999999999">All</option>
                        </select>
                    </div>
                    <span>lines</span>
                    </div>
                </div>
            </div>

            <div class="row mt-4 header-row">
                <div class="col-3">
                    <p class="header-item" i18n>MAILING ID</p>
                </div>
                <div class="col-5">
                    <p class="header-item" i18n>SUBJECT</p>
                </div>
                <div class="col-1">
                    <p class="header-item" i18n>INVITED BY</p>
                </div> 
                <div class="col-1">
                    <p class="header-item" i18n>SENT DATE</p>
                </div>
                <div class="col-2">
                    <p class="header-item" i18n>NB MESSAGE</p>
                </div>
            </div>

            <div class="list">
                <ul> 
                    <li *ngFor="let item of collection | paginate: config">
                    <app-followup-item [data]="item" ></app-followup-item>
                    </li>
                    <br>
                </ul>
            </div>

        </div>
    </div>
</div>

    <!-- LISTE MOBILE -->
<!-- <div *ngIf="mobileRes" style="background-color: #f9f8f4;">             
        <div id="listUsers">
            
            <app-fil-ariane-emailing></app-fil-ariane-emailing>
            
            <div class="filtersMobile" *ngIf="filterStatut"  [@slideInUpOnEnter] [@slideOutLeftOnLeave]>
                <app-filters-mobile (filterStatut)="checkStatut($event)"></app-filters-mobile>
            </div>
            
            <div *ngIf="!filterStatut">
                
                
                <div class="list" >
                    <div *ngIf="count > 0" class="userCount">
                        
                        
                        <div style="padding-top: 8px;">
                            <svg height="44px" width="44px">
                                <use xlink:href="#export" />
                            </svg>
                            
                            <div class="btn-group dropup">
                                <svg id="dropdownMenuButton" height="44px" width="44px" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <use xlink:href="#mail" />
                            </svg>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" href="#">Send mail</a>
                                <a class="dropdown-item" href="#">Send SMS</a>
                            </div>
                        </div>
                        
                        <div class="btn-group dropup">
                            <svg id="dropdownMenuButton2" height="44px" width="44px" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <use xlink:href="#action_h" />
                        </svg>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                            <a class="dropdown-item" href="#">Change status</a>
                            <a class="dropdown-item" href="#">Change account manager</a>
                            <a class="dropdown-item" href="#">Swip participant type</a>
                            <a class="dropdown-item" href="#">Detach</a>
                            <a class="dropdown-item" href="#">Add VIP tag</a>
                            <a class="dropdown-item" href="#">Add MAIN tag</a>
                            <a class="dropdown-item" href="#">Delete users</a>
                            <a class="dropdown-item" href="#">Delete access password</a>
                        </div>
                    </div>
                </div>
            </div>
            <ul [ngClass]=" count == 0 ? 'count0' : 'count1'">
                <li *ngFor="let item of collection | paginate: config ">
                    <app-followup-item></app-followup-item>
                </li>
            </ul>
        </div>
    </div>
</div>
</div> -->
