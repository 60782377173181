import { Component, OnInit, Input } from '@angular/core';
import { EventService } from '../../../../core/http/event.service';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-agenda-header',
  templateUrl: './agenda-header.component.html',
  styleUrls: ['./agenda-header.component.scss']
})
export class AgendaHeaderComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private eventService: EventService
  ) { }

  listOfDetailsAgenda: any;
  idHappening: any;

  async ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.idHappening = params.get('id');
    });
    this.listOfDetailsAgenda = await this.eventService.getAgenda(this.idHappening).toPromise();
  }
}

