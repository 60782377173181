import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-user-card-mobile',
  templateUrl: './user-card-mobile.component.html',
  styleUrls: ['./user-card-mobile.component.scss']
})
export class UserCardMobileComponent implements OnInit {

  @Output() countUser: EventEmitter<any> = new EventEmitter();

  _iOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad/);

  userSelected = false;

  constructor() { }

  selectUser() {
    this.userSelected = !this.userSelected;
    if (this.userSelected) {
      this.countUser.emit(1);
    } else {
      this.countUser.emit(-1);
    }
    if (!this._iOSDevice) {
      navigator.vibrate(50);
    }
  }

  ngOnInit() {
  }

}
