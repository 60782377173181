<div class="maincontainer-fluid font-color pb-4 pb-5" style="min-height: 80vh">
  <div class="maincontainer">

    <div class="row">

      <div class="col-lg-8 col-md-12 mt-4">
        <h3 class="nowayRegular" i18n>Thelonious Bob</h3>
        <span style="opacity: 0.7" i18n>Super Admin</span>
      </div>

    </div>


    <!-- Desktop -->
    <div class="display--nav-none">

      <div class="mt-4">

        <app-settings-menu url="Account"></app-settings-menu>

      </div>


      <div class="mt-4">

        <form>
          <div class="row mt-3" style="margin-bottom: 160px">

            <div class="col-lg-12 mb-3">
              <label for="current-password" i18n>Current password</label>
              <input type="password" id="current-password" class="form-control"
                     placeholder="Current password">
            </div>

            <div class="col-lg-6">
              <label for="password" i18n>New password</label>
              <input type="password" id="password" class="form-control" placeholder="New password">
            </div>
            <div class="col-lg-6">
              <label for="passwordVerif" i18n>Confirm new password</label>
              <input type="password" id="passwordVerif" class="form-control"
                     placeholder="Confirm new password">
            </div>

            <div class="col-lg-12">
              <button class="btn btn-success mt-3" i18n>Update password</button>
            </div>

          </div>
        </form>

      </div>

    </div>


    <!-- Mobile -->
    <div class="accordion display--collapse-none mt-3" id="accordionExample">

      <h2 class="mb-0">
        <button [routerLink]="['/admin/settings']" class="btn btn-link pl-0" type="button" i18n>
          Dashboard Option
        </button>
        <button [routerLink]="['/admin/settings']" class="float-right btn-link btn" type="button">
          <svg width="24px" height="24px" style="fill: #84C000">
            <use xlink:href="#plus"/>
          </svg>
        </button>
      </h2>


      <div class="mt-3">
        <div id="headingTwo">
          <h2 class="mb-0">
            <button class="btn btn-link pl-0"
                    type="button" data-toggle="collapse"
                    data-target="#collapseFive"
                    aria-expanded="true" aria-controls="collapseFive" style="color: #84C000" i18n>
              Change password
            </button>
            <button class="float-right btn-link btn"
                    type="button" data-toggle="collapse"
                    data-target="#collapseFive"
                    aria-expanded="true" aria-controls="collapseFive">
                    <svg width="24px" height="24px" style="fill: #84C000">
                      <use xlink:href="#plus"/>
                    </svg>
            </button>
          </h2>
        </div>
      </div>

      <div class="mt-3">

        <h2 class="mb-0">
          <button [routerLink]="['/admin/settings-event']" class="btn btn-link pl-0"
                  type="button" i18n>
            Event
          </button>
          <button [routerLink]="['/admin/settings-event']" class="float-right btn-link btn" type="button">
            <svg width="24px" height="24px" style="fill: #84C000">
              <use xlink:href="#plus"/>
            </svg>
          </button>
        </h2>
      </div>


      <div class="mt-3">

        <h2 class="mb-0">
          <button [routerLink]="['/admin/settings-manage-user']" class="btn btn-link pl-0" type="button" i18n>
            Manage Users
          </button>
          <button [routerLink]="['/admin/settings-manage-user']" class="float-right btn-link btn"
                  type="button">
                  <svg width="24px" height="24px" style="fill: #84C000">
                    <use xlink:href="#plus"/>
                  </svg>
          </button>
        </h2>


        <div id="collapseFive" class="collapse show" aria-labelledby="headingTwo"
             data-parent="#accordionExample">

          <div class="mt-4">

            <form>
              <div class="row mt-3 mb-4">

                <div class="col-lg-12">
                  <label for="utilisateur" i18n>Utilisateur</label>
                  <input type="text" id="utilisateur" class="form-control" placeholder="Utilisateur">
                </div>

                <div class="col-lg-12">
                  <button class="btn btn-success mt-3" i18n>Update profile</button>
                </div>

              </div>
            </form>

            <form>
              <div class="row mt-3" style="margin-bottom: 160px">

                <div class="col-lg-12 mb-3">
                  <label for="current-password" i18n>Current password</label>
                  <input type="password" id="current-password" class="form-control"
                         placeholder="Current password">
                </div>

                <div class="col-lg-6">
                  <label for="password" i18n>New password</label>
                  <input type="password" id="password" class="form-control" placeholder="New password">
                </div>
                <div class="col-lg-6">
                  <label for="passwordVerif" i18n>Confirm new password</label>
                  <input type="password" id="passwordVerif" class="form-control"
                         placeholder="Confirm new password">
                </div>

                <div class="col-lg-12">
                  <button class="btn btn-success mt-3" i18n>Update password</button>
                </div>

              </div>
            </form>

          </div>

        </div>
      </div>
    </div>
    <!-- End Mobile -->


  </div>
</div>
