import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ParticipantService } from '../../../../core/http/participant.service';
import { EventService } from '../../../../core/http/event.service';
import { FormDataParticipantService } from '../../../../core/http/form-data-participant.service';
import { DateService } from '../../../../core/http/date.service';
import { RefService } from '../../../../core/http/ref.service';
import { bounceOutRightOnLeaveAnimation, bounceAnimation, hueRotateAnimation, fadeInDownOnEnterAnimation, fadeOutUpOnLeaveAnimation } from 'angular-animations';
import * as _moment from 'moment';
import { HotelService } from 'src/app/core/http/hotel.service';

declare var $: any;
// tslint:disable: prefer-template

@Component({
  selector: 'app-participant-edit-form',
  templateUrl: './participant-edit-form.component.html',

  styleUrls: ['./participant-edit-form.component.scss'],
  animations: [
    bounceOutRightOnLeaveAnimation({ delay: 100 }),
    bounceAnimation(),
    hueRotateAnimation(),
    fadeInDownOnEnterAnimation({ duration: 10, translate: '5px', delay: 0 }),
    fadeOutUpOnLeaveAnimation({ duration: 10, translate: '5px', delay: 0 }),
  ]
})

export class ParticipantEditFormComponent implements OnInit {

  updateBool = false;
  skipAutocomplete = false;
  idParticipant: any;
  idCompany: any;
  selectedCompany: any;
  urlSelectedCompany: any;
  companyNameValue: any;
  autocompleteParticipantsValue: any;
  listOfType: any;
  listOfCountry: any;
  listOfStatus: any;
  listOfChannelType: any;
  listOfChannelAtten: any;
  listOfRegistrationChannel: any;
  listOfAttendee: any;
  listOfContact: any;
  listOfTravel: any;
  listOfAllEvents: any;
  listOfEvents: any;
  listOfTitle: any;
  listOfTransport: any;
  listOfRegion: any;
  listOfNights: any;
  listOfEventsHotel: any;
  functionSelect: any;
  companyList: any;
  participantsList: any;
  companylilist: any;
  attendeeForm: any;
  contactForm: any;
  travelForm: any;
  hotelForm: any;
  status_event: any;
  status_event_hotel: any;
  initialAttendeeForm: any;
  initialContactForm: any;
  initialTravelForm: any;
  isAttendeeHasChange: boolean = false;
  isContactHasChange: boolean = false;
  isTravelHasChange: boolean = false;

  // public hotelForm = this.fb.group({
  //   hotelName: [''],
  //   roomBooking: [''],
  //   commentsBooking: ['', Validators.maxLength(1000)],
  //   notifyAttendee: [''],
  // });

  constructor(
    private refService: RefService,
    private participantService: ParticipantService,
    private eventService: EventService,
    private hotelService: HotelService,
    private formDataParticipantService: FormDataParticipantService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.idParticipant = params.get('id');
    });
    this.attendeeForm = this.formDataParticipantService.dataAttendee();
    this.contactForm = this.formDataParticipantService.dataContact();
    this.travelForm = this.formDataParticipantService.dataTravel();
    this.hotelForm = this.formDataParticipantService.dataHotel();
    this.initRef();
    await this.initList();
    this.initAttendeeForm();
    this.initContactForm();
    this.initTravelForm();
    this.initHotelForm();
    this.initialAttendeeForm = this.attendeeForm.value;
    this.attendeeForm.valueChanges.subscribe(value => {
      this.isAttendeeHasChange = Object.keys(this.initialAttendeeForm).some(key =>
        this.attendeeForm.value[key] != this.initialAttendeeForm[key])
      }
    );
    this.initialContactForm = this.contactForm.value;
    this.contactForm.valueChanges.subscribe(value => {
      this.isContactHasChange = Object.keys(this.initialContactForm).some(key =>
        this.contactForm.value[key] != this.initialContactForm[key])
      }
    );
    this.hotelForm.valueChanges.subscribe(res => {
      this.initHotelForm();
    });
  }
  initAttendeeForm() {
    this.attendeeForm.patchValue({
      firstName: this.listOfAttendee.firstName,
      lastName: this.listOfAttendee.lastName,
      idTitle: this.listOfAttendee.idTitle,
      jobPosition: this.listOfAttendee.jobPosition,
      mobile: this.listOfAttendee.mobile,
      email: this.listOfAttendee.email,
      email2: this.listOfAttendee.email2,
      ccAssistant: this.listOfAttendee.ccAssistant,
      firstNameAssistant: this.listOfAttendee.firstNameAssistant ? this.listOfAttendee.firstNameAssistant : "",
      lastNameAssistant: this.listOfAttendee.lastNameAssistant ? this.listOfAttendee.lastNameAssistant : "",
      idTitleAssistant: this.listOfAttendee.idTitleAssistant ? this.listOfAttendee.idTitleAssistant : 0,
      jobPositionAssistant: this.listOfAttendee.jobPositionAssistant ? this.listOfAttendee.jobPositionAssistant : "",
      telephoneAssistant: this.listOfAttendee.telephoneAssistant ? this.listOfAttendee.telephoneAssistant : "",
      mobileAssistant: this.listOfAttendee.mobileAssistant ? this.listOfAttendee.mobileAssistant : "",
      faxAssistant: this.listOfAttendee.faxAssistant ? this.listOfAttendee.faxAssistant : "",
      emailAssistant: this.listOfAttendee.emailAssistant ? this.listOfAttendee.emailAssistant : "",
      address: this.listOfAttendee.address,
      address2: this.listOfAttendee.address2,
      city: this.listOfAttendee.city,
      state: this.listOfAttendee.state,
      zipCode: this.listOfAttendee.zipCode,
      idCountry: this.listOfAttendee.idCountry,
    });
  }
  initContactForm() {
    this.contactForm.patchValue({
      statusContact: this.listOfContact.status,
      idChannelType: this.listOfContact.idChannelType,
      idAttendanceType: this.listOfContact.idAttendanceType,
      idRegistrationChannel: this.listOfContact.idRegistrationChannel,
      vip: this.listOfContact.vip,
      incident: this.listOfContact.incident,
      main: this.listOfContact.main,
      doNotPostEmail: this.listOfContact.doNotPostEmail,
      shareEmail: this.listOfContact.shareEmail,
      shareMobile: this.listOfContact.shareMobile,
      profileUpdate: this.listOfContact.profileUpdate,
      comments: this.listOfContact.comment,
      photoUrl: this.listOfContact.pictureUrl,
      idRegion: this.listOfContact.idRegion,
      ccAssistant: this.listOfContact.ccAssistant
    });
  }
  async initTravelForm() {
    this.listOfTravel = await this.participantService.getParticipantTravel(this.idParticipant, this.travelForm["value"].idEvent).toPromise();
    if (this.listOfEvents) {
      for (let i = 0; this.listOfEvents.length > i; i++) {
        if (this.listOfEvents[i].idEvent == this.travelForm["value"].idEvent) {
          this.status_event = this.listOfEvents[i].eventRegistrationTxt;
        }
      }
    }
    if (this.listOfTravel) {
      this.travelForm.patchValue({
        idEvent: this.listOfTravel.idEvent,
        comments: this.listOfTravel.comments,
        otherComments: this.listOfTravel.otherComments,
        idArrivalTransport: this.listOfTravel.idArrivalTransport,
        arrivalDate: this.listOfTravel.arrivalDate.substring(0,10),
        arrivalHour: this.listOfTravel.arrivalDate.substring(11,16),
        arrivalCity: this.listOfTravel.arrivalCity,
        arrivalAirport: this.listOfTravel.arrivalAirport,
        arrivalFlight: this.listOfTravel.arrivalFlight,
        arrivalAirline: this.listOfTravel.arrivalAirline,
        arrivalStation: this.listOfTravel.arrivalStation,
        arrivalTrain: this.listOfTravel.arrivalTrain,
        idDepartureTransport: this.listOfTravel.idDepartureTransport,
        departureDate: this.listOfTravel.departureDate.substring(0,10),
        departureHour: this.listOfTravel.departureDate.substring(11,16),
        departureCity: this.listOfTravel.departureCity,
        departureAirport: this.listOfTravel.departureAirport,
        departureFlight: this.listOfTravel.departureFlight,
        departureAirline: this.listOfTravel.departureAirline,
        departureStation: this.listOfTravel.departureStation,
        departureTrain: this.listOfTravel.departureTrain,
        ownTransport: this.listOfTravel.ownTransport,
      });
    } else { this.resetTravelForm(); }
    this.initialTravelForm = this.travelForm["value"];
      this.travelForm.valueChanges.subscribe(value => {
        this.isTravelHasChange = Object.keys(this.initialTravelForm).some(key =>
          this.travelForm.value[key] != this.initialTravelForm[key])
        }
      );
    this.isTravelHasChange = false;
  }
  resetTravelForm() {
    this.travelForm.patchValue({
      comments: '',
      otherComments: '',
      idArrivalTransport: 0,
      arrivalDate: [''],
      arrivalHour: '',
      arrivalCity: '',
      arrivalAirport: '',
      arrivalFlight: '',
      arrivalAirline: '',
      arrivalStation: '',
      arrivalTrain: '',
      idDepartureTransport: 0,
      departureDate: [''],
      departureHour: '',
      departureCity: '',
      departureAirport: '',
      departureFlight: '',
      departureAirline: '',
      departureStation: '',
      departureTrain: '',
      ownTransport: false,
    });
  }
  async initHotelForm() {
    this.listOfNights = await this.hotelService.getNightByEvent(this.hotelForm["value"].idEvent).toPromise();
    if (this.listOfEvents) {
      for (let i = 0; this.listOfEvents.length > i; i++) {
        if (this.listOfEvents[i].idEvent == this.hotelForm["value"].idEvent) {
          this.status_event_hotel = this.listOfEvents[i].eventRegistrationTxt;
        }
      }
    }
  }
  resetHotelForm() {
  }
  async initList() {
    this.listOfAttendee = await this.participantService.getParticipantAttendee(this.idParticipant).toPromise();
    this.listOfContact = await this.participantService.getParticipantContactInformation(this.idParticipant).toPromise();
    this.listOfAllEvents = await this.eventService.getAllEventsByParticipant(this.idParticipant).toPromise();
    this.listOfEvents = await this.participantService.getParticipantEventRegistration(this.idParticipant).toPromise();
    if (this.listOfEvents) {
      this.travelForm.patchValue({
        idEvent: this.listOfEvents[0].idEvent
      });
      this.hotelForm.patchValue({
        idEvent: this.listOfEvents[0].idEvent
      });
      this.status_event = this.listOfEvents[0].eventRegistrationTxt;
      this.status_event_hotel = this.listOfEvents[0].eventRegistrationTxt;
    }
  }
  initRef() {
    this.refService.getTitle().subscribe(response => {
      this.listOfTitle = response;
    });
    this.refService.getStatus().subscribe(response => {
      this.listOfStatus = response;
      for (let i = 0; this.listOfStatus.length > i; i++) {
          if (this.listOfStatus[i].short == "conf") { this.listOfStatus[i].short = "act"; }
          if (this.listOfStatus[i].short == "inv") { this.listOfStatus[i].short = "occa"; }
          if (this.listOfStatus[i].short == "can") { this.listOfStatus[i].short = "old"; this.listOfStatus[i].label = "Old"; }
          if (this.listOfStatus[i].short == "short") { this.listOfStatus[i].short = "pen"; }
          if (this.listOfStatus[i].short == "") { this.listOfStatus[i].short = "none"; }
      }
    });
    this.refService.getCountry().subscribe(response => {
      this.listOfCountry = response;
    });
    this.refService.getTypeParticipant().subscribe( response => {
      this.listOfChannelType = response;
    });
    this.refService.getAttendanceType().subscribe( response => {
      this.listOfChannelAtten = response;
    });
    this.refService.getRegistrationChannel().subscribe( response => {
      this.listOfRegistrationChannel = response;
    });
    this.refService.getRegion().subscribe( response => {
      this.listOfRegion = response;
    });
    this.refService.getTransport().subscribe( response => {
      this.listOfTransport = response;
    });
  }
  ngOnChanges() {
    this.ngOnInit();
  }
  onChange() {
    this.initTravelForm();
  }
  onSubmit() {
    this.contactForm["value"].idAttendanceType = this.contactForm["value"].idAttendanceType == 0 ? false : true;
    this.participantService.updateParticipantAttendee(this.attendeeForm["value"], this.idParticipant);
    this.participantService.updateParticipantContactInformation(this.contactForm["value"], this.idParticipant);
    this.travelForm["value"].arrivalDate = _moment(this.travelForm["value"].arrivalDate).format("DD/MM/YYYY");
    this.travelForm["value"].departureDate = _moment(this.travelForm["value"].departureDate).format("DD/MM/YYYY");
    this.participantService.updateParticipantEventLogistic(this.travelForm["value"], this.idParticipant);
    this.toastr.clear();
    this.toastr.success('Success', 'Profile Update');
    this.router.navigateByUrl(`/admin/contacts/${this.idParticipant}/details`);
  }
  cancelEdit() {
    this.isAttendeeHasChange = false;
    this.isContactHasChange = false;
    this.isTravelHasChange = false;
    this.ngOnInit();
  }
  changeContactForm() {
    this.contactForm.valueChanges.subscribe(value => {
      this.isContactHasChange = Object.keys(this.initialContactForm).some(key =>
        this.contactForm.value[key] != this.initialContactForm[key])
      }
    );
  }
  changeTravelForm() {
    this.travelForm.valueChanges.subscribe(value => {
      this.isTravelHasChange = Object.keys(this.initialTravelForm).some(key =>
        this.travelForm.value[key] != this.initialTravelForm[key])
      }
    );
  }
  changeHotelForm() {
    this.hotelForm.valueChanges.subscribe(value => {
      this.initHotelForm();
    });
  }
  changeAttendeeForm() {
    this.attendeeForm.valueChanges.subscribe(value => {
      this.isAttendeeHasChange = Object.keys(this.initialAttendeeForm).some(key =>
        this.attendeeForm.value[key] != this.initialAttendeeForm[key])
      }
    );
  }
}
