import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-hotels-edit-item-form',
  templateUrl: './hotels-edit-item-form.component.html',
  styleUrls: ['./hotels-edit-item-form.component.scss']
})
export class HotelsEditItemFormComponent implements OnInit {

  constructor() { }
  
  ngOnInit() {}
}
