import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { GetDataService } from '../../../../core/http/get-data.service';
import { EmailingService } from '../../../../core/http/emailing.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.scss']
})
export class TemplateEditorComponent implements OnInit {

  @Output() switchView: EventEmitter<any> = new EventEmitter();
  @Output() addNewTemplate: EventEmitter<any> = new EventEmitter();


  @Input() templateObject;

  listOfTemplatesType = this.emailingService.listOfMailTypes; 

  newTemplateFormGroup: FormGroup;
  body;

  constructor(private _formBuilder: FormBuilder,private emailingService: EmailingService) { }

  ngOnInit() {
    this.newTemplateFormGroup = this._formBuilder.group({
      templateNameForm: [this.templateObject.templateName, Validators.required],
      templateTypeForm: [this.templateObject.templateType, Validators.required],
      templateSubjectForm: [this.templateObject.templateSubject, Validators.required],
      // templateBodyForm: [''],
    });
    console.log(this.templateObject);
  }

  switchPreviousView() {
    this.switchView.emit(true);
  }

  onChange(event){
    this.body = event.editor.getData();
  }

  deleteTemplate() {
    if (confirm('Are you sure to delete this template :  ' + this.templateObject.templateName)) {
      this.emailingService.listOfMailTemplates.splice(this.templateObject.templateIndex, 1);
      this.switchPreviousView();
    }
  }

  onSubmit() {
 // Si save new template
    this.templateObject.templateName = this.newTemplateFormGroup.value.templateNameForm;
    this.templateObject.templateSubject = this.newTemplateFormGroup.value.templateSubjectForm;
    this.templateObject.templateType = this.newTemplateFormGroup.value.templateTypeForm;
    this.templateObject.templateBody = this.body;
    this.addNewTemplate.emit(this.templateObject);
// si save template existant
    console.log(this.templateObject);

    // htmlToImage.toBlob(document.getElementById('my-node'))
    // .then(function (blob) {
    //   window.saveAs(blob, 'my-node.png');
    // });
  }

}
