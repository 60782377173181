import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-dashboard-sms',
  templateUrl: './card-dashboard-sms.component.html',
  styleUrls: ['./card-dashboard-sms.component.scss']
})
export class CardDashboardSmsComponent implements OnInit {

  smsOpened = 1803;
  smsSent = 3784;
  taux = this.smsOpened / this.smsSent;

  constructor() { }

  ngOnInit() {
  }

  tauxProgress(taux: number) {
    return Math.round(taux * 100);
  }

}
