import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings-modules',
  templateUrl: './settings-modules.component.html',
  styleUrls: ['./settings-modules.component.scss']
})
export class SettingsModulesComponent implements OnInit {
  public modulesFormulaire = [
    {
      id: 1,
      name: 'Rebookking invité',
      type: ['guest'],
      statuts: ['fermé', 'ouvert', 'cloturé']
    },
    {
      id: 2,
      name: 'Appel a projet',
      type: ['guest'],
      statuts: ['fermé', 'ouvert', 'cloturé']
    },
    {
      id: 3,
      name: 'Demande de participation invité',
      type: ['guest'],
      statuts: ['fermé', 'ouvert', 'cloturé']
    },
    {
      id: 4,
      name: 'Demande de participation partenaire',
      type: ['partner'],
      statuts: ['fermé', 'ouvert', 'cloturé']
    }
  ];
  public modulesApi = [
    {
      id: 5,
      name: 'Programme site public',
      type: ['public'],
      statuts: ['fermé', 'read only', 'cloturé']
    },
    {
      id: 6,
      name: 'Liste exposants',
      type: ['public'],
      statuts: ['fermé', 'read only', 'cloturé']
    }
  ];
  public modulesBo = [
    {
      id: 7,
      name: 'BO invité',
      type: ['guest'],
      statuts: ['fermé', 'ouvert', 'read only', 'on site', 'post event', 'cloturé']
    },
    {
      id: 8,
      name: 'BO partenaire',
      type: ['partner'],
      statuts: ['fermé', 'ouvert', 'read only', 'on site', 'post event', 'cloturé']
    },
    {
      id: 9,
      name: 'Projet',
      type: ['guest'],
      statuts: ['masqué', 'fermé', 'ouvert', 'read only']
    },
    {
      id: 10,
      name: 'Infos exposant',
      type: ['partner'],
      statuts: ['masqué', 'fermé', 'ouvert', 'read only']
    },

    {
      id: 11,
      name: 'Options de séjour',
      type: ['all'],
      statuts: ['masqué', 'fermé', 'ouvert', 'read only'],
      cards: [
        {
          type: ['partner'],
        },
        {
          type: ['guest'],
        },
        {
          type: ['visitor'],
        },
        {
          type: ['staff'],
        },
      ]
    },


    {
      id: 12,
      name: 'Activités',
      type: ['all'],
      statuts: ['masqué', 'fermé', 'ouvert', 'read only', 'cloturé'],
      cards: [
        {
          type: ['partner'],
        },
        {
          type: ['guest'],
        },
        {
          type: ['visitor'],
        },
        {
          type: ['staff'],
        },
      ]
    },
    {
      id: 13,
      name: 'Ateliers',
      type: ['all'],
      statuts: ['masqué', 'fermé', 'ouvert', 'read only', 'cloturé'],
      cards: [
        {
          type: ['partner'],
        },
        {
          type: ['guest'],
        },
        {
          type: ['visitor'],
        },
        {
          type: ['staff'],
        },
      ]
    },
    {
      id: 14,
      name: 'Rendez-vous one to one',
      type: ['all'],
      statuts: ['masqué', 'fermé', 'ouvert', 'read only', 'on site', 'post event', 'cloturé'],
      cards: [
        {
          type: ['partner'],
        },
        {
          type: ['guest'],
        },
      ]
    },
    {
      id: 15,
      name: 'Agenda perso',
      type: ['all'],
      statuts: ['masqué', 'fermé', 'read only'],
      cards: [
        {
          type: ['partner'],
        },
        {
          type: ['guest'],
        },
      ]
    },
    {
      id: 16,
      name: 'Trombinoscope',
      type: ['all'],
      statuts: ['masqué', 'fermé', 'ouvert', 'read only', 'on site', 'post event'],
      cards: [
        {
          type: ['partner'],
        },
        {
          type: ['guest'],
        },
        {
          type: ['visitor'],
        },
        {
          type: ['staff'],
        },
      ]
    },
    {
      id: 17,
      name: 'Hôtels',
      type: ['all'],
      statuts: ['masqué', 'read only'],
      cards: [
        {
          type: ['partner'],
        },
        {
          type: ['guest'],
        },
        {
          type: ['visitor'],
        },
        {
          type: ['staff'],
        },
      ]
    },
    {
      id: 18,
      name: 'Confirmation',
      type: ['all'],
      statuts: ['masqué', 'read only'],
      cards: [
        {
          type: ['partner'],
        },
        {
          type: ['guest'],
        },
        {
          type: ['visitor'],
        },
        {
          type: ['staff'],
        },
      ]
    },
    {
      id: 19,
      name: 'Enquête',
      type: ['all'],
      statuts: ['masqué', 'fermé', 'ouvert', 'cloturé'],
      cards: [
        {
          type: ['partner'],
        },
        {
          type: ['guest'],
        }
      ]
    },
    {
      id: 20,
      name: 'Remboursement',
      type: ['guest'],
      statuts: ['fermé', 'ouvert', 'cloturé']
    },
    {
      id: 21,
      name: 'Photos',
      type: ['all'],
      statuts: ['masqué', 'fermé', 'ouvert'],
      cards: [
        {
          type: ['partner'],
        },
        {
          type: ['guest'],
        },
        {
          type: ['visitor'],
        },
        {
          type: ['staff'],
        },
      ]
    },
    {
      id: 22,
      name: 'Extract leads',
      type: ['partner'],
      statuts: ['masqué', 'fermé', 'ouvert', 'read only', 'on site', 'post event']
    },
    {
      id: 23,
      name: 'Extract rdv',
      type: ['all'],
      statuts: ['masqué', 'fermé', 'ouvert', 'read only', 'on site', 'post event'],
      cards: [
        {
          type: ['partner'],
        },
        {
          type: ['guest'],
        }
      ]
    },
    {
      id: 24,
      name: 'Extract inscrits ateliers',
      type: ['partner'],
      statuts: ['masqué', 'fermé', 'ouvert', 'on site', 'post event', 'cloturé']
    },
  ];
  public modulesMobile = [
    {
      id: 25,
      name: 'Mobile app',
      type: ['all'],
      statuts: ['masqué', 'fermé', 'ouvert', 'on site', 'post event', 'cloturé'],
      cards: [
        {
          type: ['partner'],
        },
        {
          type: ['guest'],
        },
        {
          type: ['visitor'],
        },
        {
          type: ['staff'],
        },
      ]
    }
  ];

  constructor() {
  }

  ngOnInit() {
  }

}
