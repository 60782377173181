<footer class="py-4 text-white-50 sticky-footer ">
    <div class="container text-center">
        <p class="grey">
            <span class="footer-text-responsive" i18n>About us</span>
            <span class="footer-text-responsive" i18n>Support</span>
            <span class="footer-text-responsive" i18n>Developers</span>
            <span class="footer-text-responsive" i18n>Terms of use</span>
            <span class="footer-text-responsive" i18n>Confidentiality</span>
            <span class="footer-text-responsive" i18n>Cookies</span>
            <span class="copyright">&copy; 2021</span>
        </p>
    </div>
</footer>