import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormDataHotelService } from '../../../../core/http/form-data-hotel.service';
import { HotelService } from '../../../../core/http/hotel.service';
import { RefService } from '../../../../core/http/ref.service';
import * as _moment from 'moment';

declare var $: any;

// tslint:disable: max-line-length
@Component({
  selector: 'app-night-add-form',
  templateUrl: './night-add-form.component.html',
  styleUrls: ['./night-add-form.component.scss']
})
export class NightAddFormComponent implements OnInit {

  listOfNight: any;
  idEvent: any;
  night: any;
  listOfHotel: any;
  dataRoomType: any;
  listOfRoomType: any;

  constructor(
    private route: ActivatedRoute,
    private hotelService: HotelService,
    private formDataHotelService: FormDataHotelService,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.idEvent = params.get('id');
    });
    this.dataRoomType = this.formDataHotelService.dataRoomType();
    this.hotelService.getHotelByEvent(this.idEvent).subscribe(res => {
      this.listOfHotel = res;
    });
    this.listOfNight = await this.hotelService.getNightByEvent(this.idEvent).toPromise();
    if (this.listOfNight) {
      this.listOfNight.push({
        "idNight": null,
        "idEvent":this.idEvent,
        "idHotel":0,
        "idRoom":0,
        "max":0,
        "nbrRoom":0,
        "nbrSurbook":0,
        "typeParticipantObj":[
          {"libelle":"gue","checked":false},
          {"libelle":"ven","checked":false},
          {"libelle":"sta","checked":false},
          {"libelle":"vis","checked":false},
          {"libelle":"par","checked":false},
          {"libelle":"jou","checked":false},
          {"libelle":"spe","checked":false}
        ],
        "price":0,
        "date":"",
        "note":""
      });
    } else {
      this.listOfNight = [{
        "idNight": null,
        "idEvent":this.idEvent,
        "idHotel":0,
        "idRoom":0,
        "max":0,
        "nbrRoom":0,
        "nbrSurbook":0,
        "typeParticipantObj":[
          {"libelle":"gue","checked":false},
          {"libelle":"ven","checked":false},
          {"libelle":"sta","checked":false},
          {"libelle":"vis","checked":false},
          {"libelle":"par","checked":false},
          {"libelle":"jou","checked":false},
          {"libelle":"spe","checked":false}
        ],
        "price":0,
        "date":"",
        "note":""
      }];
    }
  }

  onSubmit() {
    this.hotelService.createRoomType(this.dataRoomType["value"]).subscribe( res => {
      console.log('success', res);
      this.toastr.clear();
      this.toastr.success('Success', 'Room type create');
    }, err => {
      console.log("L'error est : " + err);
      this.toastr.clear();
      this.toastr.error('Error', err);
    });
    this.dataRoomType.patchValue({
      idHotel: 0,
      roomName: "",
    });
  }

  duplicateNight(event: any) {
    this.night = {
      "idNight": null,
      "idEvent":event.idEvent,
      "idHotel":event.idHotel,
      "idRoom":event.idRoom,
      "max":event.max,
      "nbrRoom":event.nbrRoom,
      "nbrSurbook":event.nbrSurbook,
      "typeParticipantObj":[
        {"libelle":"gue","checked":event.typeParticipant[0].checked},
        {"libelle":"ven","checked":event.typeParticipant[1].checked},
        {"libelle":"sta","checked":event.typeParticipant[2].checked},
        {"libelle":"vis","checked":event.typeParticipant[3].checked},
        {"libelle":"par","checked":event.typeParticipant[4].checked},
        {"libelle":"jou","checked":event.typeParticipant[5].checked},
        {"libelle":"spe","checked":event.typeParticipant[6].checked}
      ],
      "price":event.price,
      "date":event.date,
      "note":event.note
    };
    this.listOfNight.splice(-1, 0, this.night);
  }
  addNight(event: any) {
    this.night = {
      "idNight": event.res,
      "idEvent":event.idEvent,
      "idHotel":event.idHotel,
      "idRoom":event.idRoom,
      "max":event.max,
      "nbrRoom":event.nbrRoom,
      "nbrSurbook":event.nbrSurbook,
      "typeParticipantObj":[
        {"libelle":"gue","checked":event.typeParticipant[0].checked},
        {"libelle":"ven","checked":event.typeParticipant[1].checked},
        {"libelle":"sta","checked":event.typeParticipant[2].checked},
        {"libelle":"vis","checked":event.typeParticipant[3].checked},
        {"libelle":"par","checked":event.typeParticipant[4].checked},
        {"libelle":"jou","checked":event.typeParticipant[5].checked},
        {"libelle":"spe","checked":event.typeParticipant[6].checked}
      ],
      "price":event.price,
      "date":event.dateString,
      "note":event.note
    };
    this.listOfNight.splice(0, 0, this.night);
    for (let i = 0; this.listOfNight.length > i; i++) {
      if (!this.listOfNight[i].idNight || this.listOfNight[i].idNight <= 0) {
        this.listOfNight.splice(i, 1);
        i--;
      }
    }
    this.listOfNight.push({
      "idNight": null,
      "idEvent":this.idEvent,
      "idHotel":0,
      "idRoom":0,
      "max":0,
      "nbrRoom":0,
      "nbrSurbook":0,
      "typeParticipantObj":[
        {"libelle":"gue","checked":false},
        {"libelle":"ven","checked":false},
        {"libelle":"sta","checked":false},
        {"libelle":"vis","checked":false},
        {"libelle":"par","checked":false},
        {"libelle":"jou","checked":false},
        {"libelle":"spe","checked":false}
      ],
      "price":0,
      "date":"",
      "note":""
    });
  }
  deleteNight(event: any) {
    for (let i = 0; this.listOfNight.length > i; i++) {
      if (this.listOfNight[i].idNight == event) {
        this.listOfNight.splice(i, 1);
      }
    }
  }
  createRoomType() {
    $(`#addModal`).modal('show');
  }
  addRoomAndReload() {
    this.hotelService.createRoomType(this.dataRoomType["value"]).subscribe( res => {
      console.log('success', res);
      this.toastr.clear();
      this.toastr.success('Success', 'Room Type create');
      window.location.reload();
    }, err => {
      console.log("L'error est : " + err);
      this.toastr.clear();
      this.toastr.error('Error', err);
      window.location.reload();
    });
  }
}