<div class="block__size--responsive">

    <div class="card mt-3 mb-4">
      <div class="card-body p-3">
        <div class="nowayRegular mb-3" style="color: #244746; font-size: 18px; font-weight: bold" i18n>{{selectedCompany.companyName.toUpperCase()}}&nbsp;ATTENDEES
        </div>
        <div class="card-font-size" style="color: #244746">
          <p *ngFor='let attendee of attendeesList'><a class="text-a" [routerLink]="['/admin/contacts/2/details']"><span class="badge" i18n>Inv.</span>{{attendee}}</a></p>
        </div>
      </div>
    </div>
  
  </div>
  
  