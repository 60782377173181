
<app-company-header></app-company-header>
<!-- Desktop-->
<div class="display--nav-none">
  <nav class="mt-3">
    <div class="nav nav-tabs nav-responsive" id="nav-tab" role="tablist">
      <a class="nav-item nav-link active nowayRegular" id="nav-attendee" data-toggle="tab"
      href="#company" role="tab" aria-controls="nav-company" aria-selected="true" i18n>Company</a>
      <a class="nav-item nav-link nowayRegular" id="nav-order" data-toggle="tab" href="#order" role="tab"
      aria-controls="nav-order" aria-selected="false" i18n>Order</a>
      <svg class="ml-3" width="30px" height="30px" id="dropdownMenuButton" [routerLink]="updateLink"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
        <use xlink:href="#edit"/>
      </svg>
    </div>
  </nav>
  <div class="tab-content mt-4 pb-5" id="nav-tabContent">
    <div class="tab-pane fade show active mt-1" id="company" role="tabpanel" aria-labelledby="nav-company">
      <div class="row" style="font-size: 13px">
        <div class="col-md-6" *ngFor="let section of company">
          <h6 class="nowayMedium" style="color: #244746; font-weight: bold;">{{section.title}}</h6>
          <div class="row mt-4">
            <div class="col-md-12">
              <div class="row" *ngFor="let field of section.fields">
                <div class="col-4">
                  <p class="fieldTitle">
                    {{field.title}}
                  </p>
                </div>
                <div class="col ft nowayRegular" style="color: #244746" *ngIf="field.title != 'MOBILE' && field.title != 'WEBSITE' && field.title != 'FAX'">
                  {{field.content}}
                </div>
                <div class="col ft" *ngIf="field.title == 'MOBILE' || field.title == 'FAX'">
                  <a class="no_link_a nowayRegular" style="color: #244746" href="tel:{{field.content.replaceAll(' ','')}}" target="_blank">{{field.content}}</a>
                </div>
                <div class="col ft" *ngIf="field.title == 'WEBSITE'">
                  <a class="no_link_a nowayRegular" style="color: #244746" href="{{field.content}}" target="_blank">{{field.content}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-pane fade show mt-1" id="order" role="tabpanel" aria-labelledby="nav-order">
      <div class="row" style="font-size: 13px">
        <div class="col-md-6" *ngFor="let section of companyOrder">
          <h6 class="nowayMedium" style="color: #244746; font-weight: bold;">{{section.title}}</h6>
          <div class="row mt-4">
            <div class="col-md-12">
              <div class="row" *ngFor="let field of section.fields">
                <div class="col-4">
                  <p class="fieldTitle">
                    {{field.title}}
                  </p>
                </div>
                <div class="col ft nowayRegular" style="color: #244746" *ngIf="field.title != 'MOBILE' && field.title != 'WEBSITE' && field.title != 'FAX'">
                  {{field.content}}
                </div>
                <div class="col ft" *ngIf="field.title == 'MOBILE' || field.title == 'FAX'">
                  <a class="no_link_a nowayRegular" style="color: #244746" href="tel:{{field.content.replaceAll(' ','')}}" target="_blank">{{field.content}}</a>
                </div>
                <div class="col ft" *ngIf="field.title == 'WEBSITE'">
                  <a class="no_link_a nowayRegular" style="color: #244746" href="{{field.content}}" target="_blank">{{field.content}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>