<div class="maincontainer-fluid font-color pb-4 pb-5" style="min-height: 80vh">
  <div class="maincontainer">

    <div class="row">

      <div class="col-lg-8 col-md-12 mt-4">
        <h3 class="nowayRegular" i18n>Thelonious Bob</h3>
        <span style="opacity: 0.7" i18n>Super Admin</span>
      </div>

    </div>


    <!-- Desktop -->
    <div class="display--nav-none">

      <div class="mt-4">

        <app-settings-menu url="Event"></app-settings-menu>

      </div>


      <div class="mt-4">

        <form>
          <div class="row mt-3" style="margin-bottom: 270px">

            <div class="form-group col-6">
              <label for="exampleFormControlSelect1" i18n>Color Event</label>
              <select class="form-control" id="exampleFormControlSelect1">
                <option i18n>Red</option>
                <option i18n>Blue</option>
              </select>
            </div>

            <div class="col-lg-12">
              <button class="btn btn-success mt-3" i18n>Update event</button>
            </div>
          </div>
        </form>

      </div>

    </div>


    <!-- Mobile -->
    <div class="accordion display--collapse-none mt-3" id="accordionExample">


      <h2 class="mb-0">
        <button [routerLink]="['/admin/settings']" class="btn btn-link pl-0" type="button" i18n>
          Dashboard Option
        </button>
        <button [routerLink]="['/admin/settings']" class="float-right btn-link btn" type="button">
          <svg width="24px" height="24px" style="fill: #84C000">
            <use xlink:href="#plus"/>
          </svg>
        </button>
      </h2>


      <div class="mt-3">
        <div id="headingTwo">
          <h2 class="mb-0">
            <button [routerLink]="['/admin/settings-account']" class="btn btn-link pl-0"
                    type="button" i18n>
                    Change password
            </button>
            <button [routerLink]="['/admin/settings-account']" class="float-right btn-link btn"
                    type="button">
                    <svg width="24px" height="24px" style="fill: #84C000">
                      <use xlink:href="#plus"/>
                    </svg>
            </button>
          </h2>
        </div>
      </div>

      <div class="mt-3">

        <h2 class="mb-0">
          <button class="btn btn-link pl-0"
                  type="button" data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="true" aria-controls="collapseFive" style="color: #84C000" i18n>
            Event
          </button>
          <button class="float-right btn-link btn" type="button"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="true" aria-controls="collapseFive">
                  <svg width="24px" height="24px" style="fill: #84C000">
                    <use xlink:href="#plus"/>
                  </svg>
          </button>
        </h2>
      </div>


      <div class="mt-3">

        <h2 class="mb-0">
          <button [routerLink]="['/admin/settings-manage-user']" class="btn btn-link pl-0" type="button" i18n>
            Manage Users
          </button>
          <button [routerLink]="['/admin/settings-manage-user']" class="float-right btn-link btn"
                  type="button">
                  <svg width="24px" height="24px" style="fill: #84C000">
                    <use xlink:href="#plus"/>
                  </svg>
          </button>
        </h2>


        <div id="collapseFive" class="collapse show" aria-labelledby="headingTwo"
             data-parent="#accordionExample">


          <div class="mt-4">

            <form>
              <div class="row mt-3" style="margin-bottom: 270px">
                <div class="col-6">
                  <label for="nameEvent" i18n>Name Event</label>
                  <input type="text" id="nameEvent" class="form-control" placeholder="Name Event">
                </div>

                <div class="form-group col-6">
                  <label for="exampleFormControlSelect1" i18n>Color Event</label>
                  <select class="form-control" id="exampleFormControlSelect1">
                    <option i18n>Red</option>
                    <option i18n>Blue</option>
                  </select>
                </div>

                <div class="col-lg-12">
                  <button class="btn btn-success mt-3" i18n>Update event</button>
                </div>
              </div>
            </form>

          </div>


        </div>
      </div>
    </div>
    <!-- End Mobile -->


  </div>
</div>
