<div class="display--nav-none ">
  <div style="display: flex;" >
    <nav class="nav-responsive-top" style="width: 100%;margin-bottom: 1rem!important;">
      <div class="nav nav-tabs nav-responsive" id="nav-tab" role="tablist">
        <a class="nav-item nav-link active nowayRegular" id="nav-attendee" data-toggle="tab"
          href="#company" role="tab" aria-controls="nav-company" aria-selected="true" i18n>Company</a>
        <a class="nav-item nav-link nowayRegular" id="nav-order" data-toggle="tab" href="#order" role="tab"
          aria-controls="nav-order" aria-selected="false" i18n>Order</a>
      </div>
    </nav>
  </div>
  <div class="tab-content" id="nav-tabContent">
    <div class="tab-pane fade show active mt-3" id="company" role="tabpanel" aria-labelledby="nav-company">
      <div class="row">
        <form style="width:100%" (ngSubmit)="onSubmit()" [formGroup]='companyForm' (change)="changeCompanyForm()">
          <div class="row maincontainer">
            <div class="col-md-6 col-12">
              <app-form-input>
                <label for="companyName" class="label col-sm-5 col-form-label" i18n>Company name</label>
                <input type="text" class="input form-control" id="companyName"
                  formControlName="companyName">
              </app-form-input>
              <app-form-input>
                <label for="group" class="label col-sm-5 col-form-label" i18n>Group</label>
                <input type="text" class="input form-control" id="group"
                  formControlName="group">
              </app-form-input>
              <app-form-input>
                <label for="telephone" class="label col-sm-5 col-form-label" i18n>Telephone</label>
                <input type="text" class="input form-control" id="telephone" formControlName="telephone">
              </app-form-input>
              <app-form-input>
                <label for="fax" class="label col-sm-5 col-form-label" i18n>Fax</label>
                <input type="text" class="input form-control" id="fax"
                  formControlName="fax">
              </app-form-input>
              <app-form-input>
                <label for="website" class="label col-sm-5 col-form-label" i18n>Website</label>
                <input type="text" class="input form-control" id="website"
                  formControlName="website">
              </app-form-input>
            </div>
            <div class="col-md-6">
              <div class="col-md-12 pl-2rem">
                <h6 class=" mb-5 nowayRegular" style="color: #244746; font-weight: bold;" i18n>INTERNAL INFORMATION</h6>
                <div class="form-group row col 12">
                  <label for="commment" class="label col-form-label" i18n>Comments</label>
                  <textarea class="input form-control  IDinput" rows="3" id="comments" #comments
                    formControlName="comments" style="min-height: 120px;width: 100%;">
                  </textarea>
                  <p class="form-text" [ngClass]="comments.value.length > 500 ? 'text-red' : 'text-muted'">
                    <b i18n>{{comments.value.length}}</b>&nbsp;Characters (Max. 500)
                  </p>
                </div>
                <app-form-radio>
                  <h6 class="title float-left" i18n>Profile update</h6>
                  <input class="radioTrue form-check-input float-right" type="radio" id="profileUpdateTrue"
                    [value]="true" formControlName="profileUpdate">
                  <label class="labelTrue form-check-label" for="profileUpdateTrue" i18n>Yes</label>
                  <input class="radioFalse form-check-input float-right" type="radio" id="profileUpdateFalse"
                    [value]="false" formControlName="profileUpdate">
                  <label class="labelFalse form-check-label" for="profileUpdateFalse" i18n>No</label>
                </app-form-radio>
              </div>
            </div>
          </div>
          <div class="col-12">
            <hr class="mt-5 mb-5">
            <h6 class="nowayRegular mb-4 t-participant" i18n>PROFILE INFORMATION</h6>
            <div class="row">
              <div class="col-md-6 col-12">
                <app-form-select>
                  <label for="ChannelType" class="label col-sm-5 col-form-label" style="width: 140px;" i18n>Channel type :</label>
                  <select class="select form-control" id="ChannelType" formControlName="channelType">
                      <option value="0" i18n>Please select</option>
                      <option *ngFor="let channelType of this.listOfChannelType" [value]="channelType.id">
                      {{channelType.label}}
                    </option>
                  </select>
                </app-form-select>
                <app-form-input>
                  <label for="profileType" class="label col-sm-5 col-form-label" i18n>Profile type</label>
                  <input type="text" class="input form-control" id="profileType" formControlName="profileType">
                </app-form-input>
                <app-form-input>
                  <label for="logoUrl" class="label col-sm-5 col-form-label" i18n>Logo url</label>
                  <input type="text" class="input form-control" id="logoUrl" formControlName="logoUrl">
                </app-form-input>
                <app-form-input>
                  <label for="profileUrl" class="label col-sm-5 col-form-label" i18n>Profile url</label>
                  <input type="text" class="input form-control" id="profileUrl" formControlName="profileUrl">
                </app-form-input>
              </div>
              <div class=" col-md-6">
                <div class="col-md-12 pl-2rem">
                  <div class="form-group row col 12">
                    <label for="pich" class="label col-form-label" i18n>Tweet pich</label>
                    <textarea class="input form-control IDinput" rows="3" id="tweetPich" #tweetPich
                      formControlName="tweetPich" style="min-height: 120px;width: 100%;">
                    </textarea>
                    <p class="form-text" [ngClass]="tweetPich.value.length > 500 ? 'text-red' : 'text-muted'">
                      <b i18n>{{tweetPich.value.length}}</b>&nbsp;Characters (Max. 500)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [@fadeInDownOnEnter] [@fadeOutUpOnLeave] class="col-12">
            <hr class="mt-5 mb-5">
            <h6 class="nowayRegular mb-4 t-participant" i18n>ATTENDEE ADDRESS</h6>
            <div class="row mt-3" style="justify-content: space-between;">
              <div class="col-md-6 col-12">
                <app-form-input>
                  <label for="address" class="label col-sm-5 col-form-label" i18n>Address</label>
                  <input type="text" class="input form-control" id="address" formControlName="address">
                </app-form-input>
                <app-form-input>
                  <label for="city" class="label col-sm-5 col-form-label" i18n>City</label>
                  <input type="text" class="input form-control" id="city" formControlName="city">
                </app-form-input>
                <app-form-input>
                  <label for="zipCode" class="label col-sm-5 col-form-label" i18n>Zip code</label>
                  <input type="text" class="input form-control" id="zipCode" formControlName="zipCode">
                </app-form-input>
              </div>
              <div class="col-md-5 col-12">
                <app-form-input>
                  <label for="address2" class="label col-sm-5 col-form-label" i18n>Address 2</label>
                  <input type="text" class="input form-control" id="address2" formControlName="address2">
                </app-form-input>
                <app-form-input>
                  <label for="state" class="label col-sm-5 col-form-label" i18n>State</label>
                  <input type="text" class="input form-control" id="state" formControlName="state">
                </app-form-input>
                <app-form-select>
                  <label for="idCountry" class="label col-sm-5 col-form-label" i18n>Country</label>
                  <select class="select form-control" id="idCountry" formControlName="idCountry">
                    <option value=0 i18n>Please select</option>
                    <option *ngFor="let country of listOfCountry" [value]="country.id">
                      {{country.label}}
                    </option>
                  </select>
                </app-form-select>
              </div>
            </div>
          </div>
          <hr class="mt-5 mb-5">
          <div *ngIf="isOrderHasChange || isCompanyHasChange" class="row details-responsive button-fixed">
            <div class="maincontainer w-100" style="display: flex; justify-content: end;">
              <div class="col-sm-12 row justify-content-end">
                <button type="button" class="btn btn-classic button--color btn-size btn-responsive mr-3" (click)="cancelEdit()" i18n>
                  Cancel
                </button>
                <button type="submit" class="btn btn-success button--color btn-size btn-responsive" i18n>
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="tab-pane fade show mt-3" id="order" role="tabpanel" aria-labelledby="nav-company">
      <div class="row">
        <form style="width:100%" (ngSubmit)="onSubmit()" [formGroup]='companyOrderForm' (change)="changeOrderForm()">
          <div class="row maincontainer">
            <div class="col-md-6 col-12">
              <app-form-radio>
                <h6 class="title float-left" i18n>One-on-one</h6>
                <input class="radioTrue form-check-input float-right" type="radio" id="oneOnOneTrue"
                  [value]="true" formControlName="oneOnOne">
                <label class="labelTrue form-check-label" for="oneOnOneTrue" i18n>Yes</label>
                <input class="radioFalse form-check-input float-right" type="radio" id="oneOnOneFalse"
                  [value]="false" formControlName="oneOnOne">
                <label class="labelFalse form-check-label" for="oneOnOneFalse" i18n>No</label>
              </app-form-radio>
              <app-form-input>
                <label for="nbOne" class="label col-sm-5 col-form-label" i18n>One-on-one</label>
                <input type="number" class="input form-control" id="nbOne" formControlName="nbOne">
              </app-form-input>
              <app-form-input>
                <label for="nbReq" class="label col-sm-5 col-form-label" i18n>One-on-one request</label>
                <input type="number" class="input form-control" id="nbReq" formControlName="nbRequest">
              </app-form-input>
              <app-form-input>
                <label for="boardrooms" class="label col-sm-5 col-form-label" i18n>Boardrooms</label>
                <input type="number" class="input form-control" id="boardrooms" formControlName="boardrooms">
              </app-form-input>
              <app-form-input>
                <label for="boothNumber" class="label col-sm-5 col-form-label" i18n>Booth number</label>
                <input type="text" class="input form-control" id="boothNumber" formControlName="boothNumber">
              </app-form-input>
              <app-form-select>
                <label for="virtualType" class="label col-sm-5 col-form-label" style="width: 140px;" i18n>Attendance type :</label>
                <select class="select form-control" id="virtualType" formControlName="virtualType">
                    <option *ngFor="let attendanceType of this.listOfAttendanceType" [value]="attendanceType.id">
                    {{attendanceType.label}}
                  </option>
                </select>
              </app-form-select>
            </div>
          </div>
          <div *ngIf="isOrderHasChange || isCompanyHasChange" class="row details-responsive button-fixed">
            <div class="maincontainer w-100" style="display: flex; justify-content: end;">
              <div class="col-sm-12 row justify-content-end">
                <button type="button" class="btn btn-classic button--color btn-size btn-responsive mr-3" (click)="cancelEdit()" i18n>
                  Cancel
                </button>
                <button type="submit" class="btn btn-success button--color btn-size btn-responsive" i18n>
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>