import { Component, OnInit } from '@angular/core';
import { CheckDeviceService } from '../../core/http/check-device.service';
import { slideInUpOnEnterAnimation, slideOutLeftOnLeaveAnimation, } from 'angular-animations';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { ParticipantService } from '../../core/http/participant.service';
import { RefService } from '../../core/http/ref.service';
import { DownloadService } from '../../core/http/download.service';
import { EventService } from '../../core/http/event.service';
import { CompanyService } from '../../core/http/company.service';
import { FormDataFilterService } from '../../core/http/form-data-filter.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { FormBuilder } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-list-users-desktop',
  templateUrl: './list-users-desktop.component.html',
  styleUrls: ['./list-users-desktop.component.scss'],
  animations: [
    slideInUpOnEnterAnimation(),
    slideOutLeftOnLeaveAnimation()
  ]
})
export class ListUsersDesktopComponent implements OnInit {

  mobileRes: boolean;
  filterStatut = false;
  filterStatutFast = false;
  config: any;
  nbItems: number = 10;
  count: number;
  selected: number;
  idEvent: number;
  typeStatus: string = '';
  url: any;
  public href: string;
  check: boolean = false;
  box: boolean = false;
  listOfFillAriane: any;
  urlLink: string = "";
  listOfTypeParticipant: any;
  listOfStatus: any;
  listOfOnsite: any;
  listOfAttendance: any;
  listOfType: any;
  listOfChecked: any = [];
  detailsOfEvent: any;
  newStatus: any;
  newOnsite: any;
  newType: any;
  newAttendance: any;
  listOfCountry: any;
  initFilter: boolean = true;
  filterFormGroup: any;
  listOfCompany: any;
  search: string;
  dataDownload: any;
  loginForm = this.fb.group({
    username: [''],
    password: [''],
  });

  constructor(
    private fb: FormBuilder,
    private checkDeviceService: CheckDeviceService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private participantService: ParticipantService,
    private refService: RefService,
    private toastr: ToastrService,
    private formDataFilterService: FormDataFilterService,
    private eventService: EventService,
    private companyService: CompanyService,
    private downloadService: DownloadService,
    private authenticationService: AuthenticationService
  ) {
    this.config = {
      currentPage: 1,
      itemsPerPage: this.nbItems
    };

    this.activatedRoute.paramMap.subscribe((params: ParamMap) => { // Recupère le type et statut pour faire appel api ?
      if (params.get('page')) { this.config.currentPage = Number(params.get('page')); }
    });
  }

  ngOnInit() {
    this.dataDownload = this.downloadService.downloadData();
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => { // Recupère le type et statut pour faire appel api ?
      this.typeStatus = params.get('status');
      if (params.get('id')) { this.idEvent = Number(params.get('id')); }
    });
    if (this.initFilter) {
      this.filterFormGroup = this.formDataFilterService.dataFilters();
      this.filterFormGroup.valueChanges.subscribe();
      this.refService.getCountry().subscribe(response => {
        this.listOfCountry = response;
      });
      this.refService.getTypeParticipant().subscribe(res => {
        this.listOfType = res;
        if (this.idEvent) {
          for (let i = 0; this.listOfType.length > i; i++) {
            if (this.listOfType[i].short == "dis") { this.listOfType.splice(i, 1); }
            if (this.listOfType[i].short == "ret") { this.listOfType.splice(i, 1); }
          }
        }
      });
      this.companyService.getAllCompany().subscribe(res => {
        this.listOfCompany = res;
      });
    }
    this.initFilter = false;
    this.href = this.router.url;
    this.url = this.href.split('/');
    this.mobileRes = this.checkDeviceService.isMobileResolution;
    this.selected = 0;
    this.listOfChecked = [];
    this.check = false;
    this.box = false;
    if (!this.idEvent) { this.contactInit(); }
    else { this.eventInit(); }
  }

  eventInit() {
    if (this.filterFormGroup["value"].search.length <= 0) { this.search = "!" }
    else { this.search = this.filterFormGroup["value"].search; }
    this.eventService.getEventRegistrationByStatusWithPage(this.typeStatus, this.idEvent, this.nbItems, this.config.currentPage,
    this.filterFormGroup["value"].idCountry, this.filterFormGroup["value"].idType, this.filterFormGroup["value"].idCompany,
    this.filterFormGroup["value"].vip, this.filterFormGroup["value"].main, this.search).subscribe(res => {
      this.listOfTypeParticipant = res;
      for (let i = 0; this.listOfTypeParticipant.length > i; i++) {
        this.listOfChecked.push({
          "id": this.listOfTypeParticipant[i].idPerson, "name": this.listOfTypeParticipant[i].firstName + ' ' + this.listOfTypeParticipant[i].lastName, "check": false
        });
      }
    });
    this.refService.getEventStatus().subscribe(res => {
      this.listOfStatus = res;
    });
    this.refService.getOnSiteEventStatus().subscribe(res => {
      this.listOfOnsite = res;
    });
  }

  changeFilter() {
    this.ngOnInit();
  }

  contactInit() {
    if (this.typeStatus == "active") { this.typeStatus = "confirmed"; }
    if (this.typeStatus == "occasional") { this.typeStatus = "invited"; }
    if (this.typeStatus == "pending") { this.typeStatus = "shortlist"; }
    if (this.typeStatus == "old") { this.typeStatus = "cancelled"; }
    if (this.filterFormGroup["value"].search.length <= 0) { this.search = "!" }
    else { this.search = this.filterFormGroup["value"].search; }
    this.participantService.getParticipantByStatusWithPage(this.typeStatus, this.nbItems, this.config.currentPage,
    this.filterFormGroup["value"].idCountry, this.filterFormGroup["value"].idType, this.filterFormGroup["value"].idCompany,
    this.filterFormGroup["value"].vip, this.filterFormGroup["value"].main, this.search).subscribe(res => {
      this.listOfTypeParticipant = res;
      for (let i = 0; this.listOfTypeParticipant.length > i; i++) {
        this.listOfChecked.push({
          "id": this.listOfTypeParticipant[i].idPerson, "name": this.listOfTypeParticipant[i].firstName + ' ' + this.listOfTypeParticipant[i].lastName, "check": false
        });
      }
    });
    this.refService.getStatus().subscribe(res => {
      this.listOfStatus = res;
    });
    this.refService.getAttendanceType().subscribe(res => {
      this.listOfAttendance = res;
    });
  }

  userCount(event: any, id: any) {
    for (let i = 0; this.listOfChecked.length > i; i++) {
      if (id == this.listOfChecked[i].id)
      if (event === 1) { this.listOfChecked[i].check = true; }
      else { this.listOfChecked[i].check = false; }
    }
    if (event === 1) { this.selected += 1; }
    else if (event === -1) { this.selected -= 1; }
  }

  checkStatut(event: any) {
    this.filterStatut = event;
  }
  checkStatutFast(event: any) {
    this.filterStatutFast = event;
  }

  pageChange(newPage: number) {
    this.router.navigate(['admin/contacts/all'], { queryParams: { page: newPage } });
  }

  onItemsChange(event: any) {
    this.config.itemsPerPage = event;
    this.ngOnInit();
  }

  checkBoxSelectAll() {
    this.check = !this.check;
    if (!this.check) { 
      this.selected = 0;
      for (let i = 0; this.listOfChecked.length > i; i++) {
        this.listOfChecked[i].check = false;
      }
    } else {
      for (let i = 0; this.listOfChecked.length > i; i++) {
        this.listOfChecked[i].check = true;
      }
    }
    
  }

  initValueForm() {
    this.filterFormGroup.patchValue({
      idCountry: 0,
    })
  }

  fast_edit() {
    this.listOfChecked.sort((a: any, b: any) => {
      if (a.check < b.check) return -1;
      else if (a.check > b.check) return 1;
      else return 0;
    });
    if (this.idEvent) {
      this.newStatus = document.getElementById("IdStatus")["value"];
      this.newOnsite = document.getElementById("IdOnsite")["value"];
      this.newType = document.getElementById("IdType")["value"];
      for (let i = 0; this.listOfChecked.length > i; i++) {
        if (this.listOfChecked[i].check && (i + 1) != this.listOfChecked.length) {
          this.participantService.updateParticipantEventRegistration(
            { "idEvent": this.idEvent, "status": this.newStatus, "onsiteStatus": this.newOnsite, "idType": this.newType },
            this.listOfChecked[i].id
          ).subscribe(data => {
            console.log("Success", data);
          }, error => {
            console.log("Error", error);
            this.toastr.error('Error', 'Profile not update');
          });
        } else if (this.listOfChecked[i].check && (i + 1) == this.listOfChecked.length) {
          this.participantService.updateParticipantEventRegistration(
            { "idEvent": this.idEvent, "status": this.newStatus, "onsiteStatus": this.newOnsite, "idType": this.newType },
            this.listOfChecked[i].id
          ).subscribe(data => {
            console.log("Success", data);
            this.toastr.clear();
            this.toastr.success('Success', 'Profile update');
            this.ngOnInit();
          }, error => {
            console.log("Error", error);
            this.toastr.error('Error', 'Profile not update');
          });
        }
      }
    } else {
      this.newStatus = document.getElementById("IdStatus")["value"];
      if (this.newStatus == "conf") { this.newStatus = "act";}
      else if (this.newStatus == "inv") { this.newStatus = "occa";}
      else if (this.newStatus == "can") { this.newStatus = "old";}
      else if (this.newStatus == "short") { this.newStatus = "pen";}
      this.newAttendance = document.getElementById("IdAttendance")["value"];
      if (this.newAttendance == 0) { this.newAttendance = false; }
      if (this.newAttendance == 1) { this.newAttendance = true; }
      if (this.newAttendance == -1) { this.newAttendance = null; }
      this.newType = document.getElementById("IdType")["value"];
      for (let i = 0; this.listOfChecked.length > i; i++) {
        if (this.listOfChecked[i].check && (i + 1) != this.listOfChecked.length) {
          this.participantService.updateFastParticipantContactInformation(
            { "statusContact": this.newStatus, "idAttendanceType": this.newAttendance, "idChannelType": this.newType },
            this.listOfChecked[i].id
          ).subscribe(data => {
            console.log("Success", data);
          }, error => {
            console.log("Error", error);
            this.toastr.error('Error', 'Profile not update');
          });
          this.listOfChecked[i].check = false;
        } else if (this.listOfChecked[i].check && (i + 1) == this.listOfChecked.length) {
          this.participantService.updateFastParticipantContactInformation(
            { "statusContact": this.newStatus, "idAttendanceType": this.newAttendance, "idChannelType": this.newType },
            this.listOfChecked[i].id
          ).subscribe(data => {
            console.log("Success", data);
            this.toastr.clear();
            this.toastr.success('Success', 'Profile update');
            this.ngOnInit();
          }, error => {
            console.log("Error", error);
            this.toastr.error('Error', 'Profile not update');
          });
        }
      }
    }
  }
  resetFilter() {
    this.initFilter = true;
    this.ngOnInit();
  }
  modalConfirm() {
    $(`#ConfirmModal`).modal('show');
  }

  downloadList() {
    if (this.idEvent) {
      this.dataDownload["value"].Name = "participant";
    } else {
      this.dataDownload["value"].Name = "contact";
    }
    this.dataDownload["value"].TypeFile = ".csv";
    this.dataDownload["value"].IdEvent = this.idEvent;
    this.dataDownload["value"].Status = this.typeStatus;
    this.downloadService.exportFile(this.dataDownload["value"]).subscribe(res => {
      let blob: Blob = res.body as Blob;
      let name = res.headers.get('content-disposition');
      let a = document.createElement('a');
      a.download = name.split(';')[1].split('=')[1].replace(/\"/g, '');
      a.href = window.URL.createObjectURL(blob);
      a.click();
      console.log("Success", res);
    }, error => {
      console.log("Error", error);
    });
  }
  modalDownload() {
    $(`#DownloadModal`).modal('show');
  }
  check_password() {
    this.authenticationService.login(this.loginForm["value"].username, this.loginForm["value"].password)
    .subscribe(
      () => {
        this.downloadList();
      },
      () => {
        this.toastr.clear();
        this.toastr.error('Error', 'Error');
      }
    );
  }
}
