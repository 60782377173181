import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient) { }

    //////////////////////////////////////// HTTP METHODS FOR Company //////////////////////////////////
    getCompanyByID(id: number) {
      return this.http.get(`${environment.apiUrl}/api/a/company/` + id);
    }
    getCompanyOrder(id: number) {
      return this.http.get(`${environment.apiUrl}/api/a/company/order/` + id);
    }
    getAllCompany() {
      return this.http.get(`${environment.apiUrl}/api/a/company/`);
    }
    createCompany(body: any) {
      return this.http.post<any>(`${environment.apiUrl}/api/a/company/`, body);
    }
    updateCompany(body: any, id: number) {
      return this.http.put<any>(`${environment.apiUrl}/api/a/company/${id}`, body).subscribe(
        data => console.log('success', data),
        error => console.log("L'error est : " + error)
      );
    }
    updateCompanyOrder(body: any, id: number) {
      return this.http.put<any>(`${environment.apiUrl}/api/a/company/order/${id}`, body).subscribe(
        data => console.log('success', data),
        error => console.log("L'error est : " + error)
      );
    }
}
