import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormDataEventAgendaService {

  constructor(
    private fb: FormBuilder
  ) { }

  dataEventAgenda: any;
  
  dataEventsAgenda() {
    return this.dataEventAgenda = this.fb.group({
      idEvent: [0],
      function: [''],
      functionShort: [''],
      comment: [''],
      idVendor: [0],
      idParent: [0],
      date: [''],
      startHour: '',
      endHour: '',
      uponRegistration: [false],
      uponInvitation: [false],
      print: [false],
      live: [false],
      description: [''],
      idLocation: [0],
      idTypeFunction: [0],
      typeParticipantMandatory: [''],
      typeParticipantOptional: [''],
      capacity: [0],
      minus: [0],
      transfer: [''],
      itemUrl: [''],
    });
  }
}
