import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HotelService {

  constructor(private http: HttpClient) { }

    //////////////////////////////////////// HTTP METHODS FOR HOTEL //////////////////////////////////

    getHotel(id: number) {
      return this.http.get(`${environment.apiUrl}/api/a/hotel/hotel-${id}`);
    }
    getHotelByEvent(id: number) {
      return this.http.get(`${environment.apiUrl}/api/a/hotel/event-${id}`);
    }
    getAllHotel(id: number) {
      return this.http.get(`${environment.apiUrl}/api/a/hotel`);
    }
    updateHotel(id: number, body: any) {
      return this.http.put<any>(`${environment.apiUrl}/api/a/hotel/${id}`, body);
    }
    createHotel(body: any) {
      return this.http.post<any>(`${environment.apiUrl}/api/a/hotel/`, body);
    }
    deleteHotel(id: number) {
      return this.http.delete<any>(`${environment.apiUrl}/api/a/hotel/${id}`);
    }
    getNightByEvent(id: number) {
      return this.http.get(`${environment.apiUrl}/api/a/hotel/night/event-${id}`);
    }
    getNightByHotel(id_hotel: number, id_event: number) {
      return this.http.get(`${environment.apiUrl}/api/a/hotel/night/hotel-${id_hotel}&event-${id_event}`);
    }
    createNight(body: any) {
      return this.http.post<any>(`${environment.apiUrl}/api/a/hotel/night/`, body);
    }
    updateNight(id: number, body: any) {
      return this.http.put<any>(`${environment.apiUrl}/api/a/hotel/night/${id}`, body);
    }
    deleteNight(id: number) {
      return this.http.delete<any>(`${environment.apiUrl}/api/a/hotel/night/${id}`);
    }
    getRoomByHotel(id_hotel: number) {
      return this.http.get(`${environment.apiUrl}/api/a/hotel/room/hotel-${id_hotel}`);
    }
    createRoomType(body: any) {
      return this.http.post<any>(`${environment.apiUrl}/api/a/hotel/room/`, body);
    }
    updateRoomType(id: number, body: any) {
      return this.http.put<any>(`${environment.apiUrl}/api/a/hotel/room/${id}`, body);
    }
}
