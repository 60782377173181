<div class="row details-responsive responsiveHeader">
    <div class="mt-1" style="display: flex;">
        <div class="float-left">
            <div class="mt-2 pipe">
                <svg id="dropdownMenuButton6" height="24px"
                width="24px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <use xlink:href="#minus" />
                </svg>
            </div>
        </div>
        <div *ngIf="listOfDetailsAgenda?.typeFunction" class="mt-2">
            <span class="badge-status badge-{{listOfDetailsAgenda?.typeFunction.replaceAll(' ', '-')}}">
                {{listOfDetailsAgenda?.typeFunction}}
            </span>
        </div>
    </div>
    <div class="col-md-8 mt-1 row">
        <div class="col-md-12">
            <p class="mb-0">
                <span class="h1 nowayMedium title_responsive" style="font-size: 31px">
                    {{listOfDetailsAgenda?.function.substring(0, 1).toUpperCase() + listOfDetailsAgenda?.function.substring(1)}}
                </span>
                <span class="pl-2">
                    {{listOfDetailsAgenda?.startDate | date:'fullDate'}} - {{listOfDetailsAgenda?.startDate | date:'shortTime'}}
                </span>
            </p>
        </div>
        <div class="col-md-11">
            <p class="badge--id mt-0 idEvent">{{listOfDetailsAgenda?.idHappening}}</p>
        </div>
    </div>
</div>