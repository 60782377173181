<div class="row details-responsive responsiveHeader">
    <div class="col-md-1 mt-1 " style="display: flex;">
        <div class="float-left">
            <div class="btn-group dropleft mt-1">
                <svg class="float-right" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <use xlink:href="#more-vertical" />
                </svg>
                <div class="dropdown-menu dropleft" aria-labelledby="dropdownMenuButton">
                    <span class="dropdown-menu-arrow"></span>
                    <a class="dropdown-item" href="#" i18n>Something if here</a>
                    <a class="dropdown-item" href="#" i18n>Another action</a>
                    <a class="dropdown-item" href="#" i18n>Something else here</a>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-8 mt-1 row">
        <div class="col-md-9">
            <p class="mb-0">
                <span class="h1 nowayMedium title_responsive" style="font-size: 31px">
                    {{listOfDetailsCompany?.company.substring(0, 1).toUpperCase() + listOfDetailsCompany?.company.substring(1)}}
                </span>
                <span class="pl-2">
                    {{listOfDetailsCompany?.country}}
                </span>
            </p>
        </div>
        <div class="col-md-11">
            <p class="badge--id mt-0 idCompany">{{listOfDetailsCompany?.idCompany}}</p>
        </div>
    </div>
</div>