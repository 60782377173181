<div class="tab-content" id="nav-tabContent">
  <div style="display: flex;">
    <nav class="nav-responsive-top" style="width: 100%;margin-bottom:1rem!important">
      <div class="nav nav-tabs nav-responsive" id="nav-tab" role="tablist">
        <a placement="left" popoverClass="popover-custom-light" ngbPopover="View event information" triggers="mouseenter:mouseleave" class="nav-item nav-link active nowayRegular" id="nav-event_info" data-toggle="tab" href="#event_info"
          role="tab" aria-controls="nav-event_info" aria-selected="true" i18n>Event information</a>
        <a placement="top" popoverClass="popover-custom-light" ngbPopover="View event contact" triggers="mouseenter:mouseleave" class="nav-item nav-link" id="nav-event_contacts" data-toggle="tab" href="#event_contacts" role="tab"
          aria-controls="nav-event_contacts" aria-selected="false" i18n>Contacts</a>
        <a placement="right" popoverClass="popover-custom-light" ngbPopover="View event menu" triggers="mouseenter:mouseleave" class="nav-item nav-link" id="nav-event_menu" data-toggle="tab" href="#event_menu" role="tab"
          aria-controls="nav-event_menu" aria-selected="false" i18n>Menu</a>
      </div>
    </nav>
  </div>
  <div class="tab-pane fade show active mt-3" id="event_info" role="tabpanel" aria-labelledby="nav-event_info">
    <form style="width:100%" [formGroup]="addEventFormGroup" (ngSubmit)="onSubmit()">
      <div id="eventInformationRow" class="row mt-3 justify-content-center">
        <div class="col-md-6 col-12">
          <h6 class="nowayRegular mb-5 t-title" i18n>EVENT INFORMATION</h6>
          <app-form-input>
            <label for="EventName" class="label col-sm-5 col-form-label" i18n>Event Name*</label>
            <input type="text" class="input form-control" id="EventName" formControlName="EventName" required>
          </app-form-input>
          <app-form-select>
            <label for="EventType" class="label col-sm-5 col-form-label" i18n>Event Type*</label>
            <select class="select form-control" id="EventType" formControlName="EventType" required>
              <option value="" i18n>Please select</option>
              <option *ngFor="let eventType of this.listOfEventType" [value]="eventType.id">
                {{eventType.label}}
              </option>
            </select>
          </app-form-select>
          <hr class="mt-4 mb-4 separator">
          <app-form-input>
            <label for="Location" class="label col-sm-5 col-form-label" i18n>Location</label>
            <input type="text" class="input form-control" id="Location" formControlName="Location">
          </app-form-input>
          <app-form-input>
            <label for="Capacity" class="label col-sm-5 col-form-label" i18n>Capacity</label>
            <input type="number" min="0" class="input form-control" id="Capacity" formControlName="Capacity">
          </app-form-input>
          <hr class="mt-4 mb-4 separator">
          <app-form-input>
            <label for="Organizer" class="label col-sm-5 col-form-label" i18n>Organizer</label>
            <input type="text" class="input form-control" id="Organizer" formControlName="Organizer">
          </app-form-input>
          <app-form-input>
            <label for="replyTo" class="label col-sm-5 col-form-label" i18n>Reply to*</label>
            <input class="input form-control" type="text" id="replyTo" formControlName="replyTo" required
              [ngClass]="addEventFormGroup.get('replyTo').errors && addEventFormGroup.get('replyTo').touched  ? 'is-invalid' : 'is-valid'">
          </app-form-input>
          <hr class="mt-4 mb-4 separator">
          <div class="form-group row col-md-12">
            <div class="p-0 pb-2 col-md-4 col-form-label">
              <label for="TypeParticipant" i18n>Event open for: </label>
            </div>
            <div class="row col-md-8">
              <div class="form-check p-1 w-33">
                <input class="form-check-input" type="checkbox" (change)="selectAll()" id="select_all">
                <label class="form-check-label" for="select_all" i18n>
                  all
                </label>
              </div>
              <div class="form-check p-1 w-33" *ngFor="let par of listOfTypeParticipant">
                <input class="form-check-input click_input"
                  (change)="onChange(par.short, $event.target.checked)" name="{{ par.short }}" type="checkbox"
                  id="{{ par.id }}">
                <label class="form-check-label" for="{{ par.id }}">
                  {{ par.short }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12 p-0">
          <h6 class="nowayRegular mb-5 t-title" i18n>EVENT DATES</h6>
          <div class="form-group col-12 pr-0 row">
            <label for="StartDate" class="pl-0 label col-sm-4 col-form-label" i18n>Date start*</label>
            <mat-form-field class="col-md-4">
              <input matInput [matDatepicker]="start" class="mat_imput_custom" name="StartDate" formControlName="StartDate"
                [ngClass]="addEventFormGroup.get('StartDate').errors && addEventFormGroup.get('StartDate').touched ? 'is-invalid' : 'is-valid'"
                required>
              <mat-datepicker-toggle matIconSuffix [for]="start"></mat-datepicker-toggle>
              <mat-datepicker #start></mat-datepicker>
            </mat-form-field>
            <ngx-timepicker-field [format]="24" formControlName="StartHour"></ngx-timepicker-field>
          </div>
          <div class="form-group col-12 pr-0 row">
            <label for="EndDate" class="pl-0 label col-sm-4 col-form-label" i18n>Date end*</label>
            <mat-form-field class="col-md-4">
              <input matInput [matDatepicker]="end" class="mat_imput_custom" name="EndDate" formControlName="EndDate"
                [ngClass]="addEventFormGroup.get('EndDate').errors && addEventFormGroup.get('EndDate').touched ? 'is-invalid' : 'is-valid'"
                required>
              <mat-datepicker-toggle matIconSuffix [for]="end"></mat-datepicker-toggle>
              <mat-datepicker #end></mat-datepicker>
            </mat-form-field>
            <ngx-timepicker-field [format]="24" formControlName="EndHour"></ngx-timepicker-field>
          </div>
          <hr class="mt-4 mb-4 separator">
          <div class="form-group col-12 pr-0 row">
            <label for="StartDateRegistration" class="pl-0 label col-sm-4 col-form-label" i18n>Date start registration*</label>
            <mat-form-field class="col-md-4">
              <input matInput [matDatepicker]="startRegistation" class="mat_imput_custom" name="StartDateRegistration" formControlName="StartDateRegistration"
                [ngClass]="addEventFormGroup.get('StartDateRegistration').errors && addEventFormGroup.get('StartDateRegistration').touched ? 'is-invalid' : 'is-valid'"
                required>
              <mat-datepicker-toggle matIconSuffix [for]="startRegistation"></mat-datepicker-toggle>
              <mat-datepicker #startRegistation></mat-datepicker>
            </mat-form-field>
            <ngx-timepicker-field [format]="24" formControlName="StartRegistrationHour"></ngx-timepicker-field>
          </div>
          <div class="form-group col-12 pr-0 row">
            <label for="EndStart" class="pl-0 label col-sm-4 col-form-label" i18n>Date end registration*</label>
            <mat-form-field class="col-md-4">
              <input matInput [matDatepicker]="endRegistation" class="mat_imput_custom" name="EndDateRegistration" formControlName="EndDateRegistration"
                [ngClass]="addEventFormGroup.get('EndDateRegistration').errors && addEventFormGroup.get('EndDateRegistration').touched ? 'is-invalid' : 'is-valid'"
                required>
              <mat-datepicker-toggle matIconSuffix [for]="endRegistation"></mat-datepicker-toggle>
              <mat-datepicker #endRegistation></mat-datepicker>
            </mat-form-field>
            <ngx-timepicker-field [format]="24" formControlName="EndRegistrationHour"></ngx-timepicker-field>
          </div>
          <hr class="mt-4 mb-4 separator">
          <app-form-select>
            <label for="IdTimezone" class="label col-sm-5 col-form-label" i18n>Event Timezone</label>
            <select class="select form-control" id="IdTimezone" formControlName="IdTimezone">
              <option value="" i18n>Please select</option>
              <option *ngFor="let eventTimezone of this.listOfTimezone" [value]="eventTimezone.id">
                {{eventTimezone.label}}
              </option>
            </select>
          </app-form-select>
          <h6 class="nowayRegular mb-5 mt-5 t-title" i18n>EVENT ASSETS</h6>
          <div class="form-group col-12 row">
            <label for="eventBanner" class="label col-sm-4 pl-0 col-form-label" i18n>Event banner</label>
            <input type="file" class="col-sm-8 form-control-file" id="IdLogoBanner"
              accept="image/png, image/jpeg, image/jpg, image/svg" formControlName="IdLogoBanner"
              (change)="handleFileInput($any($event.target).files, 1)">
          </div>
          <div class="form-group col-12 row">
            <label for="eventLogo" class="label col-sm-4 pl-0 col-form-label" i18n>Event Logo</label>
            <input type="file" class="col-sm-8 form-control-file" id="IdLogo"
              accept="image/png, image/jpeg, image/jpg, image/svg" formControlName="IdLogo"
              (change)="handleFileInput($any($event.target).files, 0)">
          </div>
          <div class="form-group col-12 row">
            <label for="eventFooter" class="label col-sm-4 pl-0 col-form-label" i18n>Event footer</label>
            <input type="file" class="col-sm-8 form-control-file" id="IdFooter"
              accept="image/png, image/jpeg, image/jpg, image/svg" formControlName="IdFooter"
              (change)="handleFileInput($any($event.target).files, 2)">
          </div>
        </div>
        <div class="col-12">
          <hr class="mt-5 mb-5">
          <h6 class="nowayRegular mb-5 t-title" i18n>EVENT TEXT</h6>
          <div class="col-12 col-md-8 p-0">
            <div class="form-group col-12 row">
              <label for="description" class="p-0 pb-2 col-sm-2 col-form-label" i18n>Description*</label>
              <textarea class="input form-control IDinput" rows="3" id="description" #description
                formControlName="description"
                [ngClass]="addEventFormGroup.get('description').errors && addEventFormGroup.get('description').touched  ? 'is-invalid' : 'is-valid'"
                placeholder="Write your description here" style="min-height: 70px;width: 100%;" required>
              </textarea>
              <p class="form-text" [ngClass]="description.value.length > 1500 ? 'text-red' : 'text-muted'" i18n>
                <b>{{description.value.length}}</b>&nbsp;Characters (Max. 1500)
              </p>
            </div>
          </div>
          <div class="col-12 col-md-8 p-0">
            <div class="form-group col-12 row">
              <label for="pitch" class="p-0 pb-2 col-sm-2 col-form-label" i18n>Pitch</label>
              <textarea class="input form-control IDinput" rows="3" id="pitch" #pitch formControlName="pitch"
                placeholder="Write your pitch here" style="min-height: 70px;width: 100%;">
              </textarea>
              <p class="form-text" [ngClass]="pitch.value.length > 500 ? 'text-red' : 'text-muted'" i18n>
                <b>{{pitch.value.length}}</b>&nbsp;Characters (Max. 500)
              </p>
            </div>
          </div>
          <div class="col-12 col-md-8 p-0">
            <div class="form-group col-12 row">
              <label for="loginTxt" class="p-0 pb-2 col-sm-2 col-form-label" i18n>Login page</label>
              <textarea class="input form-control IDinput" rows="3" id="loginTxt" #loginTxt
              formControlName="loginTxt" placeholder="Write your login text here" style="min-height: 70px;width: 100%;">
            </textarea>
              <p class="form-text" [ngClass]="loginTxt.value.length > 4000 ? 'text-red' : 'text-muted'" i18n>
                <b>{{loginTxt.value.length}}</b>&nbsp;Characters (Max. 4000)
              </p>
            </div>
          </div>
        </div>
        <div class="row col justify-content-end mt-5">
          <button type="button" class="btn btn-classic button--color btn-size btn-responsive mr-3" i18n>
            Cancel
          </button>
          <button type="submit" [disabled]="!addEventFormGroup.valid" class="btn btn-success button--color btn-size btn-responsive" i18n>
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="tab-pane fade show mt-3" id="event_contacts" role="tabpanel" aria-labelledby="nav-event_contacts">
    <form [formGroup]="addEventContactFormGroup" (ngSubmit)="onSubmit()">
      <div id="eventContactInformationRow" class="row mt-3 justify-content-center">
        <div class="col-md-12">
          <h6 class="nowayRegular mb-5 t-title" i18n>EVENT CONTACT INFORMATION</h6>
          <div class="col-12 col-md-8 p-0">
            <div class="form-group col-12 row">
              <textarea class="input form-control IDinput" rows="3" id="contactInformation" #contactInformation formControlName="contactInformation"
              style="min-height: 70px;width: 100%;">
              </textarea>
              <p class="form-text" [ngClass]="contactInformation.value.length > 1500 ? 'text-red' : 'text-muted'" i18n>
                <b>{{description.value.length}}</b>&nbsp;Characters (Max. 1500)
              </p>
            </div>
          </div>
        </div>
        <div class="form-group row mt-4 mb-5">
          <div class="col-sm-12 mt-4">
            <button type="button" class="btn btn-classic button--color btn-size btn-responsive mr-3" i18n>
              Cancel
            </button>
            <button type="submit" [disabled]="!addEventFormGroup.valid" class="btn btn-success button--color btn-size btn-responsive" i18n>
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="tab-pane fade show mt-3" id="event_menu" role="tabpanel" aria-labelledby="nav-event_menu">
    <form [formGroup]="addEventMenuFormGroup" (ngSubmit)="onSubmit()">
      <div id="eventMenuRow" class="row mt-3 justify-content-center">
        <div class="col-md-12">
          <h6 class="nowayRegular mb-5 t-title" i18n>EVENT MENU LIVE</h6>
          <div class="col-md-8 p-0">
            <app-form-radio class="w-100">
              <h6 class="title" i18n>Open</h6>
              <input class="radioTrue form-check-input" type="radio" id="OpenLiveTrue"
                formControlName="openLive" [value]="true">
              <label class="labelTrue form-check-label" for="OpenLiveTrue" i18n>Yes</label>
              <input class="radioFalse form-check-input" type="radio" id="OpenLiveFalse"
                formControlName="openLive" [value]="false">
              <label class="labelFalse form-check-label" for="OpenLiveFalse" i18n>No</label>
            </app-form-radio>
            <div class="form-group col-12 row">
              <label for="idPlaceholder" class="label col-md-4 pl-0 col-form-label" i18n>Placeholder menu</label>
              <input type="file" class="col-sm-8 form-control-file" id="idPlaceholder"
                accept="image/png, image/jpeg, image/jpg, image/svg" formControlName="idPlaceholder"
                (change)="handleFileInputMenu($any($event.target).files)">
            </div>
            <div class="form-group col-12 row">
              <label for="liveDate" class="pl-0 label col-md-4 col-form-label" i18n>Date to replace placeholder</label>
              <mat-form-field class="col-md-4">
                <input matInput [matDatepicker]="liveDate" class="mat_imput_custom" name="liveDate" formControlName="liveDate">
                <mat-datepicker-toggle matIconSuffix [for]="liveDate"></mat-datepicker-toggle>
                <mat-datepicker #liveDate></mat-datepicker>
              </mat-form-field>
              <ngx-timepicker-field [format]="24" formControlName="hourLive"></ngx-timepicker-field>
            </div>
            <div class="form-group row">
              <label for="LiveUrl" class="label col-md-4 col-form-label" i18n>Link</label>
              <input type="text" class="input form-control col-sm-8" id="LiveUrl" formControlName="liveUrl">
            </div>
          </div>
          <hr class="mt-5 mb-5">
          <h6 class="nowayRegular mb-5 t-title" i18n>EVENT MENU NETWORKING</h6>
          <div class="col-md-8 p-0">
            <app-form-radio class="w-100">
              <h6 class="title" i18n>Open</h6>
              <input class="radioTrue form-check-input" type="radio" id="OpenNetworkingTrue"
                formControlName="openNetworking" [value]="true">
              <label class="labelTrue form-check-label" for="OpenNetworkingTrue" i18n>Yes</label>
              <input class="radioFalse form-check-input" type="radio" id="OpenNetworkingFalse"
                formControlName="openNetworking" [value]="false">
              <label class="labelFalse form-check-label" for="OpenNetworkingFalse" i18n>No</label>
            </app-form-radio>
          </div>
        </div>
        <div class="form-group row mt-4 mb-5">
          <div class="col-sm-12 mt-4">
            <button type="button" class="btn btn-classic button--color btn-size btn-responsive mr-3" i18n>
              Cancel
            </button>
            <button type="submit" [disabled]="!addEventFormGroup.valid" class="btn btn-success button--color btn-size btn-responsive" i18n>
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>