import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParamMap, ActivatedRoute } from '@angular/router';
import { GetDataService } from 'src/app/core/http/get-data.service';

@Component({
  selector: 'app-participant-details-card',
  templateUrl: './participant-details-card.component.html',
  styleUrls: ['./participant-details-card.component.scss']
})
export class ParticipantDetailsCardComponent implements OnInit {

  @Input() data;

  step = 0;
  panelOpenState = false;
  selectedAttendee;
  companyID;

  constructor(private route: ActivatedRoute, private modalService: NgbModal, private getDataService: GetDataService) {}

  ngOnInit() {

    if (this.data != undefined) {
      this.selectedAttendee = this.data;
    }

  //  this.companyID = this.selectedAttendee.attendee.companyID;
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  logging(content) { this.modalService.open(content, { size: 'lg' }); }
  registration(content) { this.modalService.open(content, { size: 'lg' }); }
  achievements(content) { this.modalService.open(content, { size: 'lg' }); }

  setStep(index: number) {
    this.step = index;
  }

}
