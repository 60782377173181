import { Component, OnInit, Input } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';

@Component({
  selector: 'app-task-for-list',
  templateUrl: './task-for-list.component.html',
  styleUrls: ['./task-for-list.component.scss']
})
export class TaskForListComponent implements OnInit {

  @Input() task;

  dateIsPassed = false;
  displayDetailsBool = false;
  detailsCut;
  todayDate = { day: 0, month: 0, year: 0 };

  constructor(private datePipe: DatePipe) { }

  ngOnInit() {
    this.getTodaysDate();
    this.checkDateIsPassed();
    this.cutTitle();
  }

  getTodaysDate() {
    const dayString = this.datePipe.transform(new Date(), 'dd');
    const day = Number(dayString);
    const monthString = this.datePipe.transform(new Date(), 'MM');
    const month = Number(monthString);
    const yearString = this.datePipe.transform(new Date(), 'yyyy');
    const year = Number(yearString);
    this.todayDate = { day, month, year };
  }

  checkDateIsPassed() {
    if (this.task.dueDate.year < this.todayDate.year) {
      return this.dateIsPassed = true;
    }  if (this.task.dueDate.year === this.todayDate.year) {
      if (this.task.dueDate.month < this.todayDate.month) {
          return this.dateIsPassed = true;
        }  if (this.task.dueDate.month === this.todayDate.month) {
          if (this.task.dueDate.day <= this.todayDate.day) {
            return this.dateIsPassed = true;
          } 
          return this.dateIsPassed = false;
          
        } else {
          return this.dateIsPassed = false;
        }
    }
  }

  swipMilestone(task) {
    this.task.milestone = !this.task.milestone;
  }

  cutTitle() {
    if (this.task.taskContent.length > 25) {
      this.detailsCut = this.task.taskContent.substr(0, 25) + '...';
    } else {
      this.detailsCut = this.task.taskContent;
    }
  }

  displayDetails() {
    this.displayDetailsBool = !this.displayDetailsBool;
  }
}
