import { Component, OnInit } from '@angular/core';
import { ParamMap, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder } from '@angular/forms';
import { GetDataService } from '../../../core/http/get-data.service';

@Component({
  selector: 'app-olf-form-company',
  templateUrl: './olf-form-company.component.html',
  styleUrls: ['./olf-form-company.component.scss']
})
export class OlfFormCompanyComponent implements OnInit {
  id;
  listOfCountry;
  listOfManagers = ['Manager 1', 'Manager 2', 'Manager 3']; // A MODIFIER

  constructor(private route: ActivatedRoute, private fb: FormBuilder,private dataService: GetDataService) { }

  public companyInformation = this.fb.group(
    {
      companyName: ['', Validators.required],
      brandCompany: [''],
      groupCompany: [''],
      address1Company: [''],
      address2Company: [''],
      adress1Company: [''],
      zipCodeCompany: ['', Validators.maxLength(10)],
      cityCompany: [''],
      stateCompany: [''],
      countryCompany: ['', Validators.required],
      switchboardPhoneCompany: [''],
      faxCompany: [''],
      websiteCompany: [''],
      website2Company: [''],
      website3Company: [''],

      accountManagerCompany: [''],
      profileUpdateCompany: [''],
      groupInternalCompany: [''],
      parentAccountCompany: [''],
      opportunityIDCompany: [''],
      accountIDCompany: [''],
    }
  );

  onSubmit() {
  }

  ngOnInit() {
  }

}
