import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-participant',
  templateUrl: './create-participant.component.html',
  styleUrls: ['./create-participant.component.scss']
})
export class CreateParticipantComponent implements OnInit {
  displayOff;

  constructor() { }

  ngOnInit() {
    this.displayOff = true;
  }

}


