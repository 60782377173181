import { Component, OnInit, Input } from '@angular/core';
import { ParamMap, ActivatedRoute, Router } from '@angular/router';
import { EventService } from '../../../../core/http/event.service';

@Component({
  selector: 'app-agenda-parent-card',
  templateUrl: './agenda-parent-card.component.html',
  styleUrls: ['./agenda-parent-card.component.scss']
})
export class AgendaParentCardComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private eventService: EventService
  ) {}

  idHappening: any;
  idEvent: any;
  listOfEventAgenda: any;
  listOfAgenda: any;
  url: any;
  public href: string;

  async ngOnInit() {
    this.href = this.router.url;
    this.url = this.href.split('/');
    this.route.paramMap.subscribe((params: ParamMap) => {
      if (this.url[3] == "event-agenda" && this.url[5] == "add") { this.idEvent = params.get('id'); }
      else { this.idHappening = params.get('id'); }
    });
    if (this.idHappening) {
      this.eventService.getAgenda(this.idHappening).subscribe(res => {
        this.listOfAgenda = res;
        this.eventService.getEventAgenda(this.listOfAgenda.idEvent).subscribe(res => {
          this.listOfEventAgenda = res;
        });
      });
    } else if (this.idEvent) {
      this.eventService.getEventAgenda(this.idEvent).subscribe(res => {
        this.listOfEventAgenda = res;
      });
    }
  }
}
