<div class="maincontainer-fluid font-color pb-4">
  <div class="maincontainer">

    <div class="row">

      <div class="col-xl-8 col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-4">

        <div class="size__card--responsive">
          <div class="card-columns">
            <div *ngFor="let card of dataCardSend">
              <div class="responsive-card" *ngIf='card.cardType !== 0'>
                <app-card-participants *ngIf='card.cardType === 1' [data]="card.cardData"></app-card-participants>
                <app-card-dashboard-with-percent *ngIf='card.cardType === 5' [data]="card.cardData"></app-card-dashboard-with-percent>
              </div>
            </div>
            <div class="">
              <app-card-dashboard-guest-confirmed-previous-event></app-card-dashboard-guest-confirmed-previous-event>
            </div>
            <div class="">
              <!-- <app-card-dashboard-partners-confirmed-previous-event></app-card-dashboard-partners-confirmed-previous-event> -->
            </div>
            <!-- <div class="">
              <app-card-pie-chart></app-card-pie-chart>
            </div> -->
          </div>

        </div>

      </div>

      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-4 task-responsive">

        <app-task-list></app-task-list>

      </div>

    </div>

  </div>
</div>
