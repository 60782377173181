import { Component, OnInit, Input } from '@angular/core';
import { EmailingService } from '../../../core/http/emailing.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';



declare var $: any;

@Component({
  selector: 'app-mailing-recap-step',
  templateUrl: './mailing-recap-step.component.html',
  styleUrls: ['./mailing-recap-step.component.scss']
})
export class MailingRecapStepComponent implements OnInit {

  hours = ['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00',
    '13:00', '14:00', '15:00', '16:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '00:00'];

  attendeesList = [{ id: 124, firstname: 'Thelonious', lastname: 'Monk',status: 'invited', email: 'thelonious.monk@microsoft.com', type: 'guest' }]


  testEmailSend = false;

  constructor(private emailingService: EmailingService,
              private toastr: ToastrService,
              private router: Router) { }

  @Input() emailObject;

  ngOnInit() {
    for (let i = 1; i < 30; i++) { //Create fake attendee list
      let attendee = {
        id: 124 + i,
        lastname: this.attendeesList[0].lastname,
        firstname: this.attendeesList[0].firstname,
        status: this.attendeesList[0].status,
        email: this.attendeesList[0].email,
        type: this.attendeesList[0].type
      }
      this.attendeesList.push(attendee);
    };  }

  sendTest() {
    this.testEmailSend = true;
    this.toastr.clear();
    this.toastr.success('Success', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit');
    $('#sendTestModal').modal('hide');
  }

  sendLater(){
    this.toastr.clear();
    this.toastr.success('Success', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit');
    $('#sendLaterModal').modal('hide');
    this.router.navigateByUrl('/admin/dashboard');
  }

  sendMail(){
    this.toastr.clear();
    this.toastr.success('Success', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit');
    $('#sendMailModal').modal('hide');
    this.router.navigateByUrl('/admin/dashboard');
  }




  displayModalTemplates() {
    $('#viewTemplateModal').modal('show');
  }

  displayModalContacts() {
    $('#contactsListModal').modal('show');
  }

  // ngAfterViewInit() {
  //   console.log(this.emailObject)
  // }

}
