import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { UpdateParticipantComponent } from './features/participants/update-participant/update-participant.component';
import { LoginComponent } from './features/login/login.component';
import { HeaderComponent } from './core/header/header.component';
import { ListUsersDesktopComponent } from './features/list-users/list-users-desktop.component';
import { DetailsParticipantComponent } from './features/participants/details-participant/details-participant.component';
import { StatisticsComponent } from './features/statistics/statistics.component';
import { PasswordResetComponent } from './features/password-reset/password-reset.component';
import { UsernameResetComponent } from './features/username-reset/username-reset.component';
import { CreateParticipantComponent } from './features/participants/create-participant/create-participant.component';
import { ManualComponent } from './features/manual/manual.component';
import { EmailingComponent } from './features/communication/emailing/emailing.component';
import { SettingsDashboardOptionComponent } from './features/settings/settings-dashboard-option/settings-dashboard-option.component';
import { SettingsAccountComponent } from './features/settings/settings-account/settings-account.component';
import { SettingsEventComponent } from './features/settings/settings-event/settings-event.component';
import { SettingsManageUserComponent } from './features/settings/settings-manage-user/settings-manage-user.component';
import { HotelsBookingComponent } from './features/hotels/hotels-booking/hotels-booking.component';
import { HotelsManageComponent } from './shared/components/hotels-manage/hotels-manage.component';
import { SettingsModulesComponent } from './features/settings/settings-modules/settings-modules.component';
import { ListAllTasksComponent } from './shared/components/list-all-tasks/list-all-tasks.component';
import { ContactDashboardComponent } from './shared/components/lists-dashboard/contact-dashboard/contact-dashboard.component';
import { EventDashboardComponent } from './shared/components/lists-dashboard/event-dashboard/event-dashboard.component';
import { ContactUpdateParticipantComponent } from './features/participant-update/contact-update-participant/contact-update-participant.component';
import { EventUpdateParticipantComponent } from './features/participant-update/event-update-participant/event-update-participant.component';
import { MealsComponent } from './shared/components/meals/meals.component';
import { Redirect404Component } from './features/redirect404/redirect404.component';
import { CardDeckCommunicationComponent } from './shared/components/dashboard/card-deck-communication/card-deck-communication.component';
import { CreateCompanyComponent } from './features/company/create-company/create-company.component';
import { UpdateCompanyComponent } from './features/company/update-company/update-company.component';
import { DetailsCompanyComponent } from './features/company/details-company/details-company.component';
import { OldFormEditComponent } from './shared/components/old-form-edit/old-form-edit.component';
import { OlfFormCompanyComponent } from './shared/components/olf-form-company/olf-form-company.component';
import { ListModelComponent } from './shared/components/templates/list-model/list-model.component';
import { FicheModelComponent } from './shared/components/templates/fiche-model/fiche-model.component';
import { MainDashboardComponent } from './shared/components/templates/main-dashboard/main-dashboard.component';
import { LandingPageComponent } from './shared/components/templates/landing-page/landing-page.component';
import { BroadcastComponent } from './shared/components/templates/broadcast/broadcast.component';
import { SettingsModuleComponent } from './shared/components/templates/settings-module/settings-module.component';
import { WikiComponentsComponent } from './features/tools/wiki-components/wiki-components.component';
import { CardsComponent } from './shared/components/wiki/dashboard/cards/cards.component';
import { SvgLibraryComponent } from './shared/components/svg-library/svg-library.component';
import { FollowupListComponent } from './features/communication/followup-list/followup-list.component';
import { FollowupPageComponent } from './shared/components/emailing/followup-page/followup-page.component';
import { ExtractionsComponent } from './features/tools/extractions/extractions.component';
import { EventAgendaComponent } from './features/event-agenda/event-agenda.component';
import { AgendaAddItemFormComponent } from './features/agenda/agenda-add-item-form/agenda-add-item-form.component';
import { AgendaEditItemFormComponent } from './features/agenda/agenda-edit-item-form/agenda-edit-item-form.component';
import { HotelsAddItemFormComponent } from './features/hotels/hotels-add-item-form/hotels-add-item-form.component';
import { HotelsEditItemFormComponent } from './features/hotels/hotels-edit-item-form/hotels-edit-item-form.component';
import { HotelsDetailsItemComponent } from './features/hotels/hotels-details-item/hotels-details-item.component';
import { NightAddItemFormComponent } from './features/hotels/night-add-item-form/night-add-item-form.component';
import { AgendaDetailsItemFormComponent } from './features/agenda/agenda-details-item-form/agenda-details-item-form.component';
import { AddEventsFormComponent } from './features/events/add-events-form/add-events-form.component';
import { EditEventsFormComponent } from './features/events/edit-events-form/edit-events-form.component';
import { ListAllEventsComponent } from './features/events/list-all-events/list-all-events.component';
import { DetailsEventComponent } from './features/events/details-event/details-event.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'admin/password/reset', component: PasswordResetComponent },
  { path: 'admin/username/reset', component: UsernameResetComponent },
  { path: '404error', component: Redirect404Component },
  {
    path: '',
    component: HeaderComponent,
    children: [
      { path: 'admin/contacts/oldCreate', component: OldFormEditComponent, canActivate: [AuthGuard] },
      { path: 'admin/company/oldCreate', component: OlfFormCompanyComponent, canActivate: [AuthGuard] },

      // { path: 'admin/model/fiche', component: FicheModelComponent, canActivate: [AuthGuard] },

      // Dashboard Routes
      { path: 'admin/dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'admin/dashboard/allTasks', component: ListAllTasksComponent, canActivate: [AuthGuard] },

      // Participants Routes
      { path: 'admin/contacts', component: ContactDashboardComponent, canActivate: [AuthGuard] },
      { path: 'admin/contacts/edit', component: ContactUpdateParticipantComponent, canActivate: [AuthGuard] },
      { path: 'admin/contacts/events-participants', component: EventDashboardComponent, canActivate: [AuthGuard] },
      { path: 'admin/contacts/events-participants/:id/edit', component: EventUpdateParticipantComponent, canActivate: [AuthGuard] },
      { path: 'admin/contacts/events-participants/:id/:status', component: ListUsersDesktopComponent, canActivate: [AuthGuard] },
      { path: 'admin/contacts/add', component: CreateParticipantComponent, canActivate: [AuthGuard] },
      { path: 'admin/contacts/:status', component: ListUsersDesktopComponent, canActivate: [AuthGuard] },
      { path: 'admin/contacts/:id/edit', component: UpdateParticipantComponent, canActivate: [AuthGuard] },
      { path: 'admin/contacts/:id/details', component: DetailsParticipantComponent, canActivate: [AuthGuard] },

      // Company Routes
      { path: 'admin/company/add', component: CreateCompanyComponent, canActivate: [AuthGuard] },
      { path: 'admin/company/:id/edit', component: UpdateCompanyComponent, canActivate: [AuthGuard] },
      { path: 'admin/company/:id/details', component: DetailsCompanyComponent, canActivate: [AuthGuard] },

      // Communication Routes
      { path: 'admin/communication/dashboard', component: CardDeckCommunicationComponent, canActivate: [AuthGuard] },
      { path: 'admin/communication/emailing', component: EmailingComponent, canActivate: [AuthGuard] },
      { path: 'admin/communication/emailing/followup', component: FollowupListComponent, canActivate: [AuthGuard] },
      { path: 'admin/communication/emailing/followup/:id', component: FollowupPageComponent, canActivate: [AuthGuard] },

      // Agenda Routes  
      /*EVENT ROUTES*/
      { path: 'admin/agenda/events', component: ListAllEventsComponent, canActivate: [AuthGuard] },
      { path: 'admin/agenda/events/add', component: AddEventsFormComponent, canActivate: [AuthGuard] },
      { path: 'admin/agenda/events/:id/edit', component: EditEventsFormComponent, canActivate: [AuthGuard] },
      { path: 'admin/agenda/events/:id/details', component: DetailsEventComponent, canActivate: [AuthGuard] },
      /*EVENT AGENDA ROUTES*/
      { path: 'admin/agenda/event-agenda', component: EventAgendaComponent, canActivate: [AuthGuard] },
      { path: 'admin/agenda/event-agenda/:id', component: EventAgendaComponent, canActivate: [AuthGuard] },
      { path: 'admin/agenda/event-agenda/:id/edit', component: AgendaEditItemFormComponent, canActivate: [AuthGuard] },
      { path: 'admin/agenda/event-agenda/:id/add', component: AgendaAddItemFormComponent, canActivate: [AuthGuard] },
      { path: 'admin/agenda/event-agenda/:id/details', component: AgendaDetailsItemFormComponent, canActivate: [AuthGuard] },


      // Logistics Routes
      { path: 'admin/logistics/hotels', component: HotelsBookingComponent, canActivate: [AuthGuard] },
      { path: 'admin/logistics/hotels/:id/add', component: HotelsAddItemFormComponent, canActivate: [AuthGuard] },
      { path: 'admin/logistics/hotels/:id/edit', component: HotelsEditItemFormComponent, canActivate: [AuthGuard] },
      { path: 'admin/logistics/hotels/:id/details', component: HotelsDetailsItemComponent, canActivate: [AuthGuard] },
      { path: 'admin/logistics/night/:id/add', component: NightAddItemFormComponent, canActivate: [AuthGuard] },
      { path: 'admin/logistics/hotels/manage', component: HotelsManageComponent, canActivate: [AuthGuard] },
      { path: 'admin/logistics/meals', component: MealsComponent, canActivate: [AuthGuard] },

      // Tools Routes
      { path: 'admin/tools/manual', component: ManualComponent, canActivate: [AuthGuard] },
      { path: 'admin/tools/extractions', component: ExtractionsComponent, canActivate: [AuthGuard] },
        // Wiki Routes
          { path: 'admin/tools/wiki', component: WikiComponentsComponent, canActivate: [AuthGuard] },
          { path: 'admin/tools/wiki/dashboard/cards', component: CardsComponent, canActivate: [AuthGuard] },
          { path: 'admin/tools/wiki/templates/list-model', component: ListModelComponent , canActivate: [AuthGuard] },
          { path: 'admin/tools/wiki/templates/fiche-model', component: FicheModelComponent , canActivate: [AuthGuard] },
          { path: 'admin/tools/wiki/templates/main-dashboard', component: MainDashboardComponent , canActivate: [AuthGuard] },
          { path: 'admin/tools/wiki/templates/landing-page', component: LandingPageComponent , canActivate: [AuthGuard] },
          { path: 'admin/tools/wiki/templates/broadcast', component: BroadcastComponent, canActivate: [AuthGuard]},
          { path: 'admin/tools/wiki/templates/settings-module', component: SettingsModuleComponent , canActivate: [AuthGuard] },
          { path: 'admin/tools/wiki/svg-library', component: SvgLibraryComponent , canActivate: [AuthGuard] },
  
      // Other Routes
      { path: 'admin/statistics', component: StatisticsComponent, canActivate: [AuthGuard] },
      { path: 'admin/settings', component: SettingsDashboardOptionComponent, canActivate: [AuthGuard] },
      { path: 'admin/settings-account', component: SettingsAccountComponent, canActivate: [AuthGuard] },
      { path: 'admin/settings-event', component: SettingsEventComponent, canActivate: [AuthGuard] },
      { path: 'admin/settings-manage-user', component: SettingsManageUserComponent, canActivate: [AuthGuard] },
      { path: 'admin/settings-modules', component: SettingsModulesComponent, canActivate: [AuthGuard] },

    ]
  },
  { path: '**', redirectTo: '404error' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
