import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SidenavMobileComponent } from './sidenav-mobile/sidenav-mobile.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule(
  {
    declarations: [HeaderComponent, FooterComponent, SidenavMobileComponent],
    imports: [
      CommonModule,
      RouterModule,
      SharedModule,
      MatToolbarModule,
      MatSidenavModule,
      MatListModule,
      MatIconModule,
      MatButtonModule,
      MatExpansionModule,
      MatFormFieldModule,
      FlexLayoutModule,
      NgbPopoverModule,
    ],
    exports: [
      HeaderComponent,
      FooterComponent
    ]
  })
export class CoreModule {
}
