<app-fil-ariane-v2></app-fil-ariane-v2>
<div class="font-color">
  <div class="maincontainer">
    <div class="row">
      <div class="col-xl-9 col-lg-12 col-md-12 mt-4">
        <app-participant-header></app-participant-header>
        <app-participant-edit-form></app-participant-edit-form>
      </div>
      <div class="col-xl-3 col-lg-12 col-md-12 mt-4">
        <app-participant-edit-card-details></app-participant-edit-card-details>
      </div>
    </div>
  </div>
</div>