import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ParticipantService } from '../../../../core/http/participant.service';

@Component({
  selector: 'app-participant-details',
  templateUrl: './participant-details.component.html',
  styleUrls: ['./participant-details.component.scss']
})
export class ParticipantDetailsComponent implements OnInit {
  urlEdit: any;
  mailtoAddress: any;
  listOfAttendee: any;
  listOfContact: any;
  listOfEvent: any;
  listOfAllEvent: any;
  listOfTravel: any;
  attendee: any;
  contact: any;
  event: any;
  travel: any;
  status_event: any;
  idParticipant: any;
  public travelForm = this.fb.group({
    idEvent: [0]
  })
  hotel: any;

  constructor(
    private fb: FormBuilder,
    private ParticipantService: ParticipantService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.idParticipant = params.get('id');
    });
    this.initAttendee();
    this.initContact();
    this.listOfEvent = await this.ParticipantService.getParticipantEventRegistration(this.idParticipant).toPromise();
    this.initEvent();
    this.listOfAllEvent = await this.ParticipantService.getParticipantEventRegistration(this.idParticipant).toPromise();
    this.initTravel();
    // this.updateHotel();
    this.urlEdit = '/admin/contacts/' + this.idParticipant + "/edit";
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  async initAttendee() {
    this.listOfAttendee = await this.ParticipantService.getParticipantAttendee(this.idParticipant).toPromise();
    this.attendee = [
      {
        title: 'ATTENDEE INFORMATION', fields: [
          { title: 'FIRST NAME', content: this.listOfAttendee?.firstName },
          { title: 'LAST NAME', content: this.listOfAttendee?.lastName },
          { title: 'TITLE', content: this.listOfAttendee?.title ? this.listOfAttendee.title : '-' },
          { title: 'JOB POSITION', content: this.listOfAttendee?.jobPosition ? this.listOfAttendee.jobPosition : '-' },
          { title: 'MOBILE', content: this.listOfAttendee?.mobile ? this.listOfAttendee.mobile : '-' },
          { title: 'E-MAIL', content: this.listOfAttendee?.email },
          { title: 'E-MAIL2', content: this.listOfAttendee?.email2 ? this.listOfAttendee.email2 : '-' },
        ]
      },
      {
        title: 'ASSISTANT INFORMATION', fields: [
          { title: 'COPY OF ALL CORRESPONDENCE', content: this.listOfAttendee?.ccAssitant == true ? 'True' : 'False' },
          { title: 'FIRST NAME', content: this.listOfAttendee?.assFirstName ? this.listOfAttendee.assFirstName : '-' },
          { title: 'LAST NAME', content: this.listOfAttendee?.assLastName ? this.listOfAttendee.assLastName : '-' },
          { title: 'TITLE', content: this.listOfAttendee?.assTitle ? this.listOfAttendee.assTitle : '-' },
          { title: 'JOB POSITION', content: this.listOfAttendee?.assJobPosition ? this.listOfAttendee.assJobPosition : '-' },
          { title: 'FAX', content: this.listOfAttendee?.assDirectPhone ? this.listOfAttendee.assDirectPhone : '-' },
          { title: 'TELEPHONE', content: this.listOfAttendee?.assDirectPhone ? this.listOfAttendee.assDirectPhone : '-' },
          { title: 'MOBILE', content: this.listOfAttendee?.assMobile ? this.listOfAttendee.assMobile : '-' },
          { title: 'E-MAIL', content: this.listOfAttendee?.assEmail ? this.listOfAttendee.assEmail : '-' },
        ]
      },
      {
        title: 'ATTENDEE ADDRESS', fields: [
          { title: 'ADDRESS 1', content: this.listOfAttendee?.address ? this.listOfAttendee.address : '-' },
          { title: 'CITY', content: this.listOfAttendee?.city ? this.listOfAttendee.city : '-' },
          { title: 'ZIP CODE', content: this.listOfAttendee?.zipCode ? this.listOfAttendee.zipCode : '-' },
        ],
      },
      {
        fields: [
          { title: '', content: '' },
          { title: 'ADDRESS 2', content: this.listOfAttendee?.address2 ? this.listOfAttendee.address2 : '-' },
          { title: 'STATE', content: this.listOfAttendee?.state ? this.listOfAttendee.state : '-' },
          { title: 'COUNTRY', content: this.listOfAttendee?.country ? this.listOfAttendee.country : '-' },
        ],
      },
    ];
  }
  async initContact() {
    this.listOfContact = await this.ParticipantService.getParticipantContactInformation(this.idParticipant).toPromise();
    this.contact = [
      {
        title: 'CONTACT INFORMATION', fields: [
         { title: 'STATUS', content: this.listOfContact?.statusTxt ? this.listOfContact.statusTxt.substring(0, 1).toUpperCase() + this.listOfContact.statusTxt.substring(1) : '-' },
         { title: 'MAIN', content: this.listOfContact?.main == true ? 'True' : 'False' },
         { title: 'VIP', content: this.listOfContact?.vip == true ? 'True' : 'False' },
         { title: 'DO NOT POST EMAIL', content: this.listOfContact?.doNotPostEmail == true ? 'True' : 'False' },
         { title: 'INCIDENT', content: this.listOfContact?.incident == true ? 'True' : 'False' },
         { title: 'CHANNEL TYPE', content: this.listOfContact?.channelType ? this.listOfContact.channelType : '-' },
         { title: 'SHARE EMAIL', content: this.listOfContact?.shareEmail == true ? 'True' : 'False' },
         { title: 'SHARE MOBILE', content: this.listOfContact?.shareMobile == true? 'True' : 'False' },
         { title: 'ATTENDANCE TYPE', content: this.listOfContact?.attendanceType ? this.listOfContact.attendanceType : '-' },
        ]
      },
      {
        title: 'INTERNAL INFORMATION', fields: [
         { title: 'REGION', content: this.listOfContact?.region ? this.listOfContact.region : '-' },
         { title: 'REGISTRATION CHANNEL', content: this.listOfContact?.registrationChannel ? this.listOfContact.registrationChannel : '-' },
         { title: 'GENERAL COMMENTS ', content: this.listOfContact?.comment ? this.listOfContact.comment.substring(0, 1).toUpperCase() + this.listOfContact.comment.substring(1) : '-' },
         { title: 'PROFILE CREATE', content: this.listOfContact?.dateCreation ? this.listOfContact.dateCreation : '-' },
         { title: 'PROFILE UPDATE', content: this.listOfContact?.profileUpdateDate ? this.listOfContact.profileUpdateDate : '-' },
        ]
      },
      {
        title: 'PROFILE INFORMATION', fields: [
         { title: 'PHOTO URL', content: this.listOfContact?.pictureUrl ? this.listOfContact.pictureUrl : '-' },
        ]
      }
    ];
  }
  initEvent() {
    this.event = [
      {
        title: 'EVENTS INFORMATION', fields: []
      },
    ];
    if (this.listOfEvent) {
      for (let i = 0; this.listOfEvent.length > i; i++) {
        this.event[0].fields.push(
          {
            detailUrl: `/admin/agenda/events/${this.listOfEvent[i]?.idEvent}/details`,
            title: this.listOfEvent[i]?.event ? this.listOfEvent[i].event : '-',
            date: this.listOfEvent[i]?.startDate ? this.listOfEvent[i].startDate : '-',
            location: this.listOfEvent[i]?.location,
            status: this.listOfEvent[i]?.eventRegistrationTxt ? this.listOfEvent[i].eventRegistrationTxt.substring(0, 1).toUpperCase() + this.listOfEvent[i].eventRegistrationTxt.substring(1) : '-',
            dateStatus: this.listOfEvent[i]?.eventRegistrationDate ? this.listOfEvent[i].eventRegistrationDate : '-',
            channel: this.listOfEvent[i]?.type ? this.listOfEvent[i].type : '-',
            onsite: this.listOfEvent[i]?.onsiteStatus ? this.listOfEvent[i].onsiteStatus.substring(0, 1).toUpperCase() + this.listOfEvent[i].onsiteStatus.substring(1) : '-',
            dateOnsite: this.listOfEvent[i]?.onsiteDate ? this.listOfEvent[i].onsiteDate : '-'
          }
        );
      }
      this.travelForm.patchValue({
        idEvent: this.listOfEvent[0].idEvent
      });
    }
  }
  async onChange() {
    this.initTravel();
  }

  async initTravel() {
    this.listOfTravel = await this.ParticipantService.getParticipantTravel(this.idParticipant, this.travelForm["value"].idEvent).toPromise();
    for (let i = 0; this.listOfAllEvent.length > i; i++) {
      if (this.listOfAllEvent[i].idEvent == this.travelForm["value"].idEvent) {
        this.status_event = this.listOfAllEvent[i].eventRegistrationTxt.substring(0,1).toUpperCase() + this.listOfAllEvent[i].eventRegistrationTxt.substring(1);
      }
    }
    this.travel = [
      {
        title: 'COMMENTS', fields: [
          { content: this.listOfTravel?.comments ? this.listOfTravel?.comments : '-' },
        ]
      },
      {
       title: 'OTHER COMMENTS', fields: [
         { content: this.listOfTravel?.otherComments ? this.listOfTravel?.otherComments : '-' },
       ]
      },
      {
        title: 'ARRIVAL DETAILS', fields: [
          { title: 'TRANSPORT', content: this.listOfTravel?.arrivalTransport ? this.listOfTravel?.arrivalTransport : '-' },
          { title: 'DATE', content: this.listOfTravel?.arrivalDate ? this.listOfTravel?.arrivalDate : '-' },
          { title: 'CITY', content: this.listOfTravel?.arrivalCity ? this.listOfTravel?.arrivalCity : '-' },
          { title: 'AIRPORT', content: this.listOfTravel?.arrivalAirport ? this.listOfTravel?.arrivalAirport : '-' },
          { title: 'FLIGHT', content: this.listOfTravel?.arrivalFlight ? this.listOfTravel?.arrivalFlight : '-' },
          { title: 'AIRLINE', content: this.listOfTravel?.arrivalAirline ? this.listOfTravel?.arrivalAirline : '-' },
          { title: 'STATION', content: this.listOfTravel?.arrivalStation ? this.listOfTravel?.arrivalStation : '-' },
          { title: 'TRAIN', content: this.listOfTravel?.arrivalTrain ? this.listOfTravel?.arrivalTrain : '-' },
          { title: 'NO ACCOMODATION', content: this.listOfTravel?.ownTransport == true ? 'True' : 'False' },
        ]
      },
      {
        title: 'DEPARTURE DETAILS', fields: [
          { title: 'TRANSPORT', content: this.listOfTravel?.departureTransport ? this.listOfTravel?.departureTransport : '-' },
          { title: 'DATE', content: this.listOfTravel?.departureDate ? this.listOfTravel?.departureDate : '-' },
          { title: 'CITY', content: this.listOfTravel?.departureCity ? this.listOfTravel?.departureCity : '-' },
          { title: 'AIRPORT', content: this.listOfTravel?.departureAirport ? this.listOfTravel?.departureAirport : '-' },
          { title: 'FLIGHT', content: this.listOfTravel?.departureFlight ? this.listOfTravel?.departureFlight : '-' },
          { title: 'AIRLINE', content: this.listOfTravel?.departureAirline ? this.listOfTravel?.departureAirline : '-' },
          { title: 'STATION', content: this.listOfTravel?.departureStation ? this.listOfTravel?.departureStation : '-' },
          { title: 'TRAIN', content: this.listOfTravel?.departureTrain ? this.listOfTravel?.departureTrain : '-' },
        ]
      },
    ];
  }
  //  this.hotel = [
  //    {
  //      title: 'ADMIN HOTEL RESERVATION ', fields: [
  //        { title: 'HOTELS', content: participant.hotel.hotelName },
  //        { title: 'ROOM', content: participant.hotel.room },
  //        { title: 'ADDITIONAL COMMENTS: ', content: participant.hotel.additionnalComments },
  //        { title: 'NOTIFY ATTENDEE ', content: participant.hotel.notifyAttendee },
  //      ]
  //    },
  //  ];
}
