import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DownloadService } from 'src/app/core/http/download.service';
import { EventService } from 'src/app/core/http/event.service';
import { RefService } from 'src/app/core/http/ref.service';

@Component({
  selector: 'app-extract-card',
  templateUrl: './extract-card.component.html',
  styleUrls: ['./extract-card.component.scss']
})
export class ExtractCardComponent implements OnInit {

  @Input() data: any;
  @Output() exportExcel: EventEmitter<any> = new EventEmitter();

  constructor(
    private refService: RefService,
    private eventService: EventService,
    private datePipe: DatePipe,
    private downloadService: DownloadService
  ) { }

  listOfStatus: any;
  listOfEventStatus: any;
  listOfYears = [];
  year: number = new Date().getFullYear();
  statusContact: any = "all";
  statusParticipant: any = "all";
  selectYear: any = 0;
  valueEventParticipant: any;
  textEventParticipant: any;
  valueEventAgenda: any;
  textEventAgenda: any;
  listOfAllEvents: any;
  dataDownload: any;

  ngOnInit() {
    this.dataDownload = this.downloadService.downloadData();
    for (let i = -5; i <= 5; i++) {
      this.listOfYears.push(this.year + i);
    }
    this.refService.getStatus().subscribe(res => {
      this.listOfStatus = res;
      for (let i = 0; this.listOfStatus.length > i; i++) {
        if (this.listOfStatus[i].short == "conf") { this.listOfStatus[i].short = "act"; }
        if (this.listOfStatus[i].short == "inv") { this.listOfStatus[i].short = "occa"; }
        if (this.listOfStatus[i].short == "can") { this.listOfStatus[i].short = "old"; this.listOfStatus[i].label = "Old"; }
        if (this.listOfStatus[i].short == "short") { this.listOfStatus[i].short = "pen"; }
      }
    });
    this.refService.getEventStatus().subscribe(res => {
      this.listOfEventStatus = res;
      for (let i = 0; this.listOfEventStatus.length > i; i++) {
        if (this.listOfEventStatus[i].label == "None") { this.listOfEventStatus.splice(i, 1); }
      }
      this.listOfEventStatus.push({ short: "att", label: "Attended"});
      this.listOfEventStatus.push({ short: "noshow", label: "Noshow"});
    });
    this.eventService.getAllEvents().subscribe( res => {
      this.listOfAllEvents = res;
      this.textEventParticipant = this.datePipe.transform(this.listOfAllEvents[0].date, 'yyyy-MM-dd') + " - " + this.listOfAllEvents[0].label;
      this.valueEventParticipant = this.listOfAllEvents[0].id;
      this.textEventAgenda = this.datePipe.transform(this.listOfAllEvents[0].date, 'yyyy-MM-dd') + " - " + this.listOfAllEvents[0].label;
      this.valueEventAgenda = this.listOfAllEvents[0].id;
    });
  }

  afterChangeParticipant(event: any) {
    this.valueEventParticipant = event.id;
    this.textEventParticipant = event.label;
  }
  afterChangeAgenda(event: any) {
    this.valueEventAgenda = event.id;
    this.textEventAgenda = event.label;
    this.dataDownload["value"].IdEvent = this.valueEventAgenda;
  }

  onSubmit() {
    if (this.data == "Contacts") {
      this.dataDownload["value"].Name = "contact";
      this.dataDownload["value"].TypeFile = ".csv";
      this.exportExcel.emit(this.dataDownload["value"]);
    } else if (this.data == "Participants") {
      this.dataDownload["value"].Name = "participant";
      this.dataDownload["value"].TypeFile = ".xls";
      this.dataDownload["value"].IdEvent = this.valueEventParticipant;
      this.exportExcel.emit(this.dataDownload["value"]);
    } else if (this.data == "Events") {
      this.dataDownload["value"].Name = "event";
      this.dataDownload["value"].TypeFile = ".doc";
      this.exportExcel.emit(this.dataDownload["value"]);
    } else if (this.data == "Agendas") {
      this.dataDownload["value"].Name = "agenda";
      this.dataDownload["value"].TypeFile = ".pdf";
      this.dataDownload["value"].IdEvent = this.valueEventAgenda;
      this.exportExcel.emit(this.dataDownload["value"]);
    }
  }

  exportFile() {
    if (this.data == "Contacts") {
      this.exportExcel.emit(this.data + "/" + this.statusContact.toLowerCase());
    } else if (this.data == "Participants") {
      if (this.statusParticipant == "") { this.statusParticipant = "none" }
      this.exportExcel.emit(this.data + "/" + this.valueEventParticipant + "/" + this.statusParticipant);
    } else if (this.data == "Events") {
      this.exportExcel.emit(this.data + "/" + this.selectYear);
    } else if (this.data == "Agendas") {
      this.exportExcel.emit(this.data + "/" + this.valueEventAgenda);
    }
  }
}
