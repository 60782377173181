import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-followup-contact',
  templateUrl: './followup-contact.component.html',
  styleUrls: ['./followup-contact.component.scss']
})
export class FollowupContactComponent implements OnInit {
  @Input() data;
  followup_mail_link;

  constructor() { }

  ngOnInit() {
    this.data.firstOpened === "" ? this.data.firstOpened = "--" : this.data.firstOpened
    this.data.lastOpened === "" ? this.data.lastOpened = "--" : this.data.lastOpened
    this.followup_mail_link = "mailto:" + this.data.emailAdress;
  }

}
