import { Component, OnInit, ViewChild, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GetDataService } from '../../../core/http/get-data.service';
import { EmailingService } from 'src/app/core/http/emailing.service'

@Component({
  selector: 'app-emailing',
  templateUrl: './emailing.component.html',
  styleUrls: ['./emailing.component.scss'],
})
export class EmailingComponent implements OnInit {


  isLinear = false; // true to desactivate navigation on different steps



  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  emailObject = {
    mailSubject: '',
    mailSender: '',
    mailCC : '',
    mailBCC : '',
    mailReplyTo : '',
    mailTrackingImage : 'No' ,
    mailConfirmationRead : 'No',
    mailTemplateSelected : {
      templateName: '',
      templateBody: '',
    }
  };


  tramplate = false;

  constructor(private _formBuilder: FormBuilder, private getDataService: GetDataService, private EmailingService: EmailingService) {}


  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
  }

  createTemplate(response) {
    this.tramplate = response;
    console.log(this.tramplate);
  }

  editSubject(event) {
    console.log(event);
    this.emailObject.mailSubject = event.mailSubject;
    this.emailObject.mailSender = event.mailSender;
    this.emailObject.mailCC = event.mailCC;
    this.emailObject.mailBCC = event.mailBCC;
    this.emailObject.mailReplyTo = event.mailReplyTo;
    this.emailObject.mailTrackingImage = event.mailTrackingImage;
    this.emailObject.mailConfirmationRead = event.mailConfirmationRead;
    this.emailObject.mailTemplateSelected = this.EmailingService.listOfMailTemplates[event.mailTemplateSelected];
  }

/*
  setMyStyles() {
    const styles = {
      'disabled': this.user.isExpired ? 'red' : 'transparent',
      'font-weight': this.isImportant ? 'bold' : 'normal'
    };
    return styles;
  }
*/

}
