<div *ngIf="siteTest" class="bandeauSiteTest">
  <p i18n> This is a test website. </p>
</div>

<div class="bandeauPersistant"></div>

<div *ngIf="siteTest" class="header_event">
  <app-header-event></app-header-event>
</div>
<div *ngIf="!siteTest">
  <app-header-event></app-header-event>
</div>
<nav class="navbar navbar-expand-md navbar-light maincontainer mt-3">
  <div class="maincontainer">
    <div class="collapse navbar-collapse navContainer">
      <div class="row navbar-nav bodyWidth" style="justify-content: space-between;">
        <div class="col-1 row pointer align-items-center">
          <div>
            <img [routerLink]="['/admin/dashboard']" src="assets/icon/navbar/icon-dashboard.svg" width="38" height="42" class="m-auto"/>
          </div>
        </div>
        <div class="col-10 row align-items-center" *ngIf="!displaySearchBar">
          <input type="search" (blur)="display()" autofocus>
        </div>
        <div class="col-2 nav-item dropdown" *ngIf="displaySearchBar">
          <a class="nav-link dropdown-toggle dropLabel align-items-center" href="#" id="navbarDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src="assets/icon/navbar/icon-participants.png">
            <br>
            <span popoverClass="popover-custom-light" placement="left" ngbPopover="View menu" triggers="mouseenter:mouseleave" i18n>CONTACTS</span>
          </a>

          <div class="dropdown-menu menu-nav animate slideIn header" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" [routerLink]="['/admin/contacts/add']" placement="left" popoverClass="popover-custom-light" ngbPopover="New contact" triggers="mouseenter:mouseleave" i18n>Add</a>
            <a class="dropdown-item" [routerLink]="['/admin/contacts/events-participants']" placement="left" popoverClass="popover-custom-light" ngbPopover="View list participants" triggers="mouseenter:mouseleave" i18n>Events participants</a>
            <a class="dropdown-item" [routerLink]="['/admin/contacts']" placement="left" popoverClass="popover-custom-light" ngbPopover="View list contacts" triggers="mouseenter:mouseleave" i18n>All contacts</a>
          </div>
        </div>
        <div class="col-2 nav-item dropdown" *ngIf="displaySearchBar">
          <a class="nav-link dropdown-toggle dropLabel" href="#" id="navbarDropdown1" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src="assets/icon/navbar/icon-communication.png"><br>
            <span i18n>COMMUNICATION</span>
          </a>
          <div class="dropdown-menu menu-nav animate slideIn header" aria-labelledby="navbarDropdown1">
            <div class="dropdown-submenu">
              <a class="dropdown-item" tabindex="-1" i18n>Emailing</a>
              <ul class="dropdown-menu menu-nav">
                <a class="dropdown-item" tabindex="-1" [routerLink]="['/admin/communication/emailing']" i18n>Broadcast</a>
              </ul>
            </div>
            <div class="dropdown-submenu">
              <a class="dropdown-item" tabindex="-1" i18n>Reports</a>
              <ul class="dropdown-menu menu-nav">
                <a class="dropdown-item" tabindex="-1" [routerLink]="['/admin/communication/emailing/followup']" i18n>Followup</a>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-2 nav-item dropdown" *ngIf="displaySearchBar">
          <a class="nav-link dropdown-toggle dropLabel" href="#" id="navbarDropdown2" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src="assets/icon/navbar/icon-programme.png"><br>
            <span i18n class="agenda_span" placement="left" popoverClass="popover-custom-light" ngbPopover="View menu" triggers="mouseenter:mouseleave" i18n>AGENDA</span>
          </a>
          <div class="dropdown-menu menu-nav animate slideIn header" aria-labelledby="navbarDropdown2">
            <div class="dropdown-submenu">
              <a class="dropdown-item" tabindex="-1" i18n>Events</a>
              <ul class="dropdown-menu menu-nav">
                <a class="dropdown-item" tabindex="-1" [routerLink]="['/admin/agenda/events/add']" placement="right" popoverClass="popover-custom-light" ngbPopover="New event" triggers="mouseenter:mouseleave" i18n>Add</a>
                <a class="dropdown-item" tabindex="-1" [routerLink]="nextEventUrl" placement="right" popoverClass="popover-custom-light" ngbPopover="View next event" triggers="mouseenter:mouseleave" i18n>Next</a>
                <a class="dropdown-item" tabindex="-1" [routerLink]="['/admin/agenda/events']" placement="right" popoverClass="popover-custom-light" ngbPopover="View list events" triggers="mouseenter:mouseleave" i18n>All</a>
              </ul>
            </div>
            <a class="dropdown-item" tabindex="-1" [routerLink]="['/admin/agenda/event-agenda']" placement="left" popoverClass="popover-custom-light" ngbPopover="View list agenda" triggers="mouseenter:mouseleave" i18n>Event Agenda</a>
            <a class="dropdown-item" href="#" i18n>Another action</a>
          </div>
        </div>
        <div class="col-2 nav-item dropdown" *ngIf="displaySearchBar">
          <a class="nav-link dropdown-toggle dropLabel" href="#" id="navbarDropdown3" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src="assets/icon/navbar/icon-logistics.png"><br>
            <span i18n>LOGISTICS</span>
          </a>
          <div class="dropdown-menu menu-nav animate slideIn header" aria-labelledby="navbarDropdown3">
            <a class="dropdown-item" [routerLink]="['/admin/logistics/hotels']" i18n>Hotels Booking</a>
            <a class="dropdown-item" [routerLink]="['/admin/logistics/meals']" i18n>Meals</a>
            <a class="dropdown-item" href="#" i18n>Another action</a>
          </div>
        </div>
        <div class="col-2 nav-item dropdown" *ngIf="displaySearchBar">
          <a class="nav-link dropdown-toggle dropLabel" href="#" id="navbarDropdown4" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src="assets/icon/navbar/icon-tools.png"><br>
            <span placement="left" popoverClass="popover-custom-light" ngbPopover="View menu" triggers="mouseenter:mouseleave" i18n>TOOLS</span>
          </a>
          <div class="dropdown-menu menu-nav animate slideIn header" aria-labelledby="navbarDropdown4">
            <a class="dropdown-item" [routerLink]="['/admin/tools/extractions']" placement="right" popoverClass="popover-custom-light" ngbPopover="View extraction" triggers="mouseenter:mouseleave" i18n>Extractions</a>
            <a class="dropdown-item" [routerLink]="['/admin/tools/manual']" placement="right" popoverClass="popover-custom-light" ngbPopover="View manual" triggers="mouseenter:mouseleave" i18n>Manual</a>
            <a class="dropdown-item" [routerLink]="['/admin/tools/wiki']" placement="right" popoverClass="popover-custom-light" ngbPopover="View wiki" triggers="mouseenter:mouseleave" i18n>Wiki</a>
          </div>
        </div>
        <div class="button_search_header">
          <img src="assets/icon/svg//search.svg" class="mr-2" width="24" height="24" class="search_icon" (click)="display()" [ngStyle]="setMyStyles()"/>
        </div>
      </div>
    </div>
  </div>


</nav>

<app-sidenav-mobile [mobileQuery]="mobileQuery"></app-sidenav-mobile>

<div class="font-color" style="min-height: 1000px">
  <router-outlet *ngIf="!mobileQuery.matches"></router-outlet>
</div>

<app-footer></app-footer>
