<div class="card">
    <div class="card-header p-4 card__task--header-font" >

                        <!-- HEADER TASKS-LIST -->

        <span class="h3">Tasks</span>
    
        <div class="headerIcons">
            <svg style="cursor:pointer;margin-right:10px" (click)="toogleForm()" [ngStyle]="formCreate === true ? {'fill':'#84C000', 'opacity':'1'} : { 'fill':'#000000', 'opacity':'0.2'}">  <!-- data-toggle="modal" data-target="#addTask" -->
                <use xlink:href="#plus"/>
            </svg>
            <svg style="cursor:pointer;margin-right:10px" (click)="toogleFilters()" [ngStyle]="displayFilter === true ? {'fill':'#84C000', 'opacity':'1'} : { 'fill':'#000000', 'opacity':'0.2'}">
                <use xlink:href="#filter"/>
            </svg>
            <div class="btn-group dropleft">
                <svg class="float-right" id="dropdownMenuButton6" style="fill: rgb(0, 0, 0); cursor:pointer; float: right;opacity: 0.2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <use xlink:href="#action"/>
                </svg>
                <div class="dropdown-menu dropleft" aria-labelledby="dropdownMenuButton6">
                    <span class="dropdown-menu-arrow"></span>
                    <a class="dropdown-item" (click)="toogleForm()" i18n>Add a new task</a>
                    <a class="dropdown-item" (click)="toogleFilters()" i18n>Sort By</a>
                    <a class="dropdown-item" [routerLink]="['/admin/dashboard/allTasks']" i18n>See all tasks</a>
                    <!-- <a class="dropdown-item" data-toggle="modal" data-target=".removed-modal" href="">See removed tasks</a> -->
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="displayFilter" class="containerFilters">
        <mat-form-field class="selectorFilterTasks">
            <mat-label style="color: black" i18n>Filters</mat-label>
            <mat-select >
                <mat-option i18n>Assigned to me</mat-option>
                <mat-option i18n>Assigned to me</mat-option>
                <mat-option i18n>Assigned to me</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="selectorFilterTasks" style="margin-left: 13px;">
            <mat-label style="color: black" i18n>Sort by</mat-label>
            <mat-select>
                <mat-option i18n>Assigned to me</mat-option>
                <mat-option i18n>Assigned to me</mat-option>
                <mat-option i18n>Assigned to me</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

                        <!-- BODY TASKS-LIST -->

    <ul class="nav nav-pills card__task--nav-font" id="pills-tab" role="tablist">
        <li class="nav-item">
            <a class="nav-links active" id="pills-home-tab" data-toggle="pill" href="#pills-home"
               role="tab" aria-controls="pills-home" aria-selected="true"> 
            <span class="ml-1 mr-2 fs18" i18n>TODO</span>
            <span class="badge badge-pill badge-success" *ngIf="todoLength !== undefined && todoLength != null" [@bounce]="animState1">{{todoLength}}</span>
            <span class="badge badge-pill badge-success" *ngIf="todoLength !== undefined && todoLength > 999" [@bounce]="animState1">999 +</span></a>
        </li>
        <li class="nav-item">
            <a class="nav-links" id="pills-profile-tab" data-toggle="pill" href="#pills-profile"
                role="tab" aria-controls="pills-profile" aria-selected="false">
            <span class="ml-2 mr-2 fs18" i18n>WIP</span>
            <span class="badge badge-pill badge-success" *ngIf="ipLength !== undefined && ipLength != null" [@bounce]="animState2">{{ipLength}}</span>
            <span class="badge badge-pill badge-success" *ngIf="ipLength !== undefined && ipLength > 999" [@bounce]="animState2">999 +</span></a>
        </li>
        <li class="nav-item" >
            <a class="nav-links" id="pills-contact-tab" data-toggle="pill" href="#pills-contact"
               role="tab" aria-controls="pills-contact" aria-selected="false">
            <span class="ml-2 mr-2 fs18" i18n>DONE</span>
            <!-- <span class="badge badge-pill badge-success" *ngIf="doneLength !== undefined && doneLength != null" [@bounce]="animState3">{{doneLength}}</span> -->        <!-- COMMENTÉ POUR LA DEMO !! A ENLEVER  -->
            <span class="badge badge-pill badge-success" *ngIf="doneLength !== undefined && doneLength < 999" [@bounce]="animState3">999 +</span></a>
        </li>
        <li class="nav-item">
            <a class="nav-links" id="pills-profile-tab" data-toggle="pill" href="#pills-allTasks"
                role="tab" aria-controls="pills-profile" aria-selected="false">
            <span class="ml-2 mr-2 fs18" i18n>ALL</span>
            <span class="badge badge-pill badge-success" *ngIf="allLength !== undefined && allLength != null" [@bounce]="animState2">{{allLength}}</span>
            <span class="badge badge-pill badge-success" *ngIf="allLength !== undefined && allLength > 999" [@bounce]="animState2">999 +</span></a>
        </li>
    </ul>

    <div class="tab-content" id="pills-tabContent">

        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

            <div class="formCreateTask" *ngIf="formCreate" [@fadeInDownOnEnter] [@fadeOutUpOnLeave]>
                <form (ngSubmit)="taskForm.valid && onSubmit()" [formGroup]='taskForm'>
                    <div class="form-group">
                        <label for="taskTitle">Title *</label>
                        <input  type="text"
                        class="form-control" id="taskTitle"
                        formControlName="taskTitle">
                        <!-- [ngClass]="taskForm.get('taskTitle').errors ? 'is-invalid' : 'is-valid'" -->
                    </div>  
                        <!-- <small>Max 35 characters</small> -->
                        <!-- <small  *ngIf="taskForm.value.taskTitle.length != 'null'"
                                [ngClass]="taskForm.get('taskTitle').errors ? 'small-invalid' : 'small-valid'">
                                {{taskForm.value.taskTitle.length}} Characters (Max. 35)</small> -->

                    <!--   <small  *ngIf="taskForm.value.taskTitle.length === null"
                                [ngClass]="taskForm.get('taskTitle').errors ? 'small-invalid' : 'small-valid'">
                                0 Characters (Max. 25)</small> -->

                    <div class="form-group">
                        <label for="taskContent" i18n>Task details</label>
                        <textarea
                                class="form-control" id="taskContent"
                                formControlName="taskContent"
                                rows="3">
                        </textarea>
                    </div>

                    <div class="row justify-content-between">

                        <div class="form-group col-6">
                            <label for="dueDate" i18n>Due date</label>
                            <div class="input-group">
                                <input class="form-control" name="dp" formControlName="dueDate" ngbDatepicker #d="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-6">
                            <label for="priority" i18n>Priority *</label>
                            <select class="form-control" id="priority" formControlName="priority">
                                <option value="none" i18n>None</option>
                                <!-- <option value="low">Low</option>
                                <option value="medium">Medium</option> -->
                                <option value="high" i18n>High</option>
                            </select>
                        </div>

                        <div class="form-group col-12">
                            <label for="taskComment" i18n>Add a comment</label>
                            <textarea
                                    class="form-control" id="taskComment"
                                    formControlName="taskComment"
                                    rows="2">
                            </textarea>
                        </div>

                        <div class="form-group col-6">
                            <label for="assignedTo" class="fs16" i18n>Assigned to: *</label>
                            <select class="form-control" id="assignedTo" formControlName="assignedTo">
                                <option *ngFor="let user of users" [value]="user">{{user}}</option>
                            </select>
                        </div>

                        <div class="form-group col-6">
                            <label for="status"class="fs16" i18n>Status *</label>
                            <select class="form-control" id="status" formControlName="status">
                                <option value="todo" i18n>To do</option>
                                <option value="inProgress" i18n>In progress</option>
                                <option value="done" i18n>Done</option>
                            </select>
                        </div>

                        <!-- <div class="col-6">
                            Assigned: <br>
                            <span *ngFor="let user of userAssigned; let index = index">
                                <button (click)="deleteUserAssigned(index)" class="btn btn-outline-danger" style="height: 20px;width: 20px;padding: 0 0 25px 0;margin-bottom: 2px;">x</button>
                                {{user}}<br>
                            </span>
                            <span *ngIf="userAssigned.length === 0 || userAssigned.length == null" style="margin-left: 10%;">-- Nobody --</span>
                        </div> -->

                            
                    </div>

                    <div class="mb-5">
                        <button type="submit" class="btn btn-success button--color float-right" [disabled]="!taskForm.valid" i18n>Save Task</button>
                        <button type="button" class="btn btn-danger ml-2" *ngIf="editTaskForm" (click)='deleteTask()' i18n>Delete</button>
                        <button type="button" class="btn btn-secondary float-left" (click)="restoreTaskInEdition()" i18n>Cancel</button>
                    </div>
                </form>
                <hr>
            </div>
            <div class="card-body" class="example-list">
                <div *ngIf="milestoneTodo.length > 0" class="milestoneList" style="position: absolute;width: 100%; z-index: 1; background-color: white;">
                    <div *ngFor="let milestone of milestoneTodo; let index = index" style="background-color: white;">
                        <app-task [index]="index" [Task]="milestone" style="width: 100%;" (taskEdit)="editTask($event)" (dataChange)="onDataChange1($event)"></app-task>
                    </div>
                    <hr class="hrmilestone">
                </div>
                <div class="taskTodoList" style="z-index: 0;position: relative;" [ngStyle]="getMyStyles()">
                    <div *ngFor="let task of taskTodo; let index = index" [@bounceOutRightOnLeave] >
                        <div class="row task">
                            <app-task [index]="index" [Task]="task" class="appTask" (taskEdit)="editTask($event)" (dataChange)="onDataChange1($event)"></app-task>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">

            <div class="formCreateTask" *ngIf="formCreate" [@fadeInDownOnEnter] [@fadeOutUpOnLeave]>
                <form (ngSubmit)="taskForm.valid && onSubmit()" [formGroup]='taskForm'>
                    <div class="form-group">
                        <label for="taskTitle" i18n>Title *</label>
                        <input  type="text"
                                [ngClass]="taskForm.get('taskTitle').errors ? 'is-invalid' : 'is-valid'"
                                class="form-control" id="taskTitle"
                                formControlName="taskTitle">
                    </div>  
                        <!-- <small>Max 35 characters</small> -->
                        <!-- <small  *ngIf="taskForm.value.taskTitle.length != 'null'"
                                [ngClass]="taskForm.get('taskTitle').errors ? 'small-invalid' : 'small-valid'">
                                {{taskForm.value.taskTitle.length}} Characters (Max. 35)</small> -->

                    <!--   <small  *ngIf="taskForm.value.taskTitle.length === null"
                                [ngClass]="taskForm.get('taskTitle').errors ? 'small-invalid' : 'small-valid'">
                                0 Characters (Max. 25)</small> -->

                    <div class="form-group">
                        <label for="taskContent" i18n>Task details</label>
                        <textarea
                                class="form-control" id="taskContent"
                                formControlName="taskContent"
                                rows="3">
                        </textarea>
                    </div>

                    <div class="row justify-content-between">

                        <div class="form-group col-6">
                            <label for="dueDate" i18n>Due date</label>
                            <div class="input-group">
                                <input class="form-control" name="dp" formControlName="dueDate" ngbDatepicker #d="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-6">
                            <label for="priority" i18n>Priority *</label>
                            <select class="form-control" id="priority" formControlName="priority">
                                <option value="none" i18n>None</option>
                                <!-- <option value="low">Low</option>
                                <option value="medium">Medium</option> -->
                                <option value="high" i18n>High</option>
                            </select>
                        </div>

                        <div class="form-group col-12">
                            <label for="taskComment" i18n>Add a comment</label>
                            <textarea
                                    class="form-control" id="taskComment"
                                    formControlName="taskComment"
                                    rows="2">
                            </textarea>
                        </div>

                        <div class="form-group col-6">
                            <label for="assignedTo" i18n>Assigned to: *</label>
                            <select class="form-control" id="assignedTo" formControlName="assignedTo">
                                <option *ngFor="let user of users" [value]="user">{{user}}</option>
                            </select>
                        </div>

                        <div class="form-group col-6">
                            <label for="status" i18n>Status *</label>
                            <select class="form-control" id="status" formControlName="status">
                                <option value="todo" i18n>To do</option>
                                <option value="inProgress" i18n>In progress</option>
                                <option value="done" i18n>Done</option>
                            </select>
                        </div>

                        <!-- <div class="col-6">
                            Assigned: <br>
                            <span *ngFor="let user of userAssigned; let index = index">
                                <button (click)="deleteUserAssigned(index)" class="btn btn-outline-danger" style="height: 20px;width: 20px;padding: 0 0 25px 0;margin-bottom: 2px;">x</button>
                                {{user}}<br>
                            </span>
                            <span *ngIf="userAssigned.length === 0 || userAssigned.length == null" style="margin-left: 10%;">-- Nobody --</span>
                        </div>       -->
                    </div>

                    <div class="mb-5">
                        <button type="submit" class="btn btn-success button--color float-right"
                                [disabled]="!taskForm.valid" i18n>Save Task
                        </button>
                        <button type="button" class="btn btn-secondary float-left"
                                data-dismiss="modal" (click)="dismissForm()" i18n>Close
                        </button>
                    </div>
                </form>
                <hr>
            </div>

            <div class="card-body">
                <div *ngFor="let task of taskIP; let index = index;" [@bounceOutRightOnLeave]>
                    <div class="row mb-2 task">
                        <!-- <app-task-in-progress [index]="index" class="appTask" (dataChange)="onDataChange2($event)"></app-task-in-progress> -->
                        <app-task [index]="index" [Task]="task" class="appTask" (taskEdit)="editTask($event)" (dataChange)="onDataChange2($event)"></app-task>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">

            <div class="formCreateTask" *ngIf="formCreate" [@fadeInDownOnEnter] [@fadeOutUpOnLeave]>
                <form (ngSubmit)="taskForm.valid && onSubmit()" [formGroup]='taskForm'>
                    <div class="form-group">
                        <label for="taskTitle" i18n>Title *</label>
                        <input  type="text"
                                [ngClass]="taskForm.get('taskTitle').errors ? 'is-invalid' : 'is-valid'"
                                class="form-control" id="taskTitle"
                                formControlName="taskTitle">
                    </div>  
                        <!-- <small>Max 35 characters</small> -->
                        <!-- <small  *ngIf="taskForm.value.taskTitle.length != 'null'"
                                [ngClass]="taskForm.get('taskTitle').errors ? 'small-invalid' : 'small-valid'">
                                {{taskForm.value.taskTitle.length}} Characters (Max. 35)</small> -->

                    <!--   <small  *ngIf="taskForm.value.taskTitle.length === null"
                                [ngClass]="taskForm.get('taskTitle').errors ? 'small-invalid' : 'small-valid'">
                                0 Characters (Max. 25)</small> -->

                    <div class="form-group">
                        <label for="taskContent" i18n>Task details</label>
                        <textarea
                                class="form-control" id="taskContent"
                                formControlName="taskContent"
                                rows="3">
                        </textarea>
                    </div>

                    <div class="row justify-content-between">

                        <div class="form-group col-6">
                            <label for="dueDate" i18n>Due date</label>
                            <div class="input-group">
                                <input class="form-control" name="dp" formControlName="dueDate" ngbDatepicker #d="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-6">
                            <label for="priority" i18n>Priority *</label>
                            <select class="form-control" id="priority" formControlName="priority">
                                <option value="none" i18n>None</option>
                                <!-- <option value="low">Low</option>
                                <option value="medium">Medium</option> -->
                                <option value="high" i18n>High</option>
                            </select>
                        </div>

                        <div class="form-group col-12">
                            <label for="taskComment" i18n>Add a comment</label>
                            <textarea
                                    class="form-control" id="taskComment"
                                    formControlName="taskComment"
                                    rows="2">
                            </textarea>
                        </div>

                        <div class="form-group col-6">
                            <label for="assignedTo" i18n>Assigned to: *</label>
                            <select class="form-control" id="assignedTo" formControlName="assignedTo">
                                <option *ngFor="let user of users" [value]="user">{{user}}</option>
                            </select>
                        </div>

                        <div class="form-group col-6">
                            <label for="status" i18n>Status *</label>
                            <select class="form-control" id="status" formControlName="status">
                                <option value="todo" i18n>To do</option>
                                <option value="inProgress" i18n>In progress</option>
                                <option value="done" i18n>Done</option>
                            </select>
                        </div>

                        <!-- <div class="col-6">
                            Assigned: <br>
                            <span *ngFor="let user of userAssigned; let index = index">
                                <button (click)="deleteUserAssigned(index)" class="btn btn-outline-danger" style="height: 20px;width: 20px;padding: 0 0 25px 0;margin-bottom: 2px;">x</button>
                                {{user}}<br>
                            </span>
                            <span *ngIf="userAssigned.length === 0 || userAssigned.length == null" style="margin-left: 10%;">-- Nobody --</span>
                        </div>   -->
                    </div>

                    <div class="mb-5">
                        <button type="submit" class="btn btn-success button--color float-right"
                                [disabled]="!taskForm.valid" i18n>Save Task
                        </button>
                        <button type="button" class="btn btn-secondary float-left"
                                data-dismiss="modal" (click)="dismissForm()" i18n>Close
                        </button>
                    </div>
                </form>
                <hr>
            </div>

            <div class="card-body">
                <div *ngFor="let taskDone of taskDone; let index = index;" [@bounceOutRightOnLeave]>
                    <div class="row mb-2 task">
                        <!-- <app-task-done [index]="index" class="appTask" (dataChange)="onDataChange3($event)"></app-task-done> -->
                        <app-task [index]="index" [Task]="taskDone" class="appTask" (taskEdit)="editTask($event)" (dataChange)="onDataChange3($event)"></app-task>

                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="pills-allTasks" role="tabpanel" aria-labelledby="pills-contact-tab">

            <div class="formCreateTask" *ngIf="formCreate" [@fadeInDownOnEnter] [@fadeOutUpOnLeave]>
                <form (ngSubmit)="taskForm.valid && onSubmit()" [formGroup]='taskForm'>
                    <div class="form-group">
                        <label for="taskTitle" i18n>Title *</label>
                        <input  type="text"
                                [ngClass]="taskForm.get('taskTitle').errors ? 'is-invalid' : 'is-valid'"
                                class="form-control" id="taskTitle"
                                formControlName="taskTitle">
                    </div>  
                        <!-- <small>Max 35 characters</small> -->
                        <!-- <small  *ngIf="taskForm.value.taskTitle.length != 'null'"
                                [ngClass]="taskForm.get('taskTitle').errors ? 'small-invalid' : 'small-valid'">
                                {{taskForm.value.taskTitle.length}} Characters (Max. 35)</small> -->

                    <!--   <small  *ngIf="taskForm.value.taskTitle.length === null"
                                [ngClass]="taskForm.get('taskTitle').errors ? 'small-invalid' : 'small-valid'">
                                0 Characters (Max. 25)</small> -->

                    <div class="form-group">
                        <label for="taskContent" i18n>Task details</label>
                        <textarea
                                class="form-control" id="taskContent"
                                formControlName="taskContent"
                                rows="3">
                        </textarea>
                    </div>

                    <div class="row justify-content-between">

                        <div class="form-group col-6">
                            <label for="dueDate" i18n>Due date</label>
                            <div class="input-group">
                                <input class="form-control" name="dp" formControlName="dueDate" ngbDatepicker #d="ngbDatepicker">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-6">
                            <label for="priority" i18n>Priority *</label>
                            <select class="form-control" id="priority" formControlName="priority">
                                <option value="none" i18n>None</option>
                                <!-- <option value="low">Low</option>
                                <option value="medium">Medium</option> -->
                                <option value="high" i18n>High</option>
                            </select>
                        </div>

                        <div class="form-group col-12">
                            <label for="taskComment" i18n>Add a comment</label>
                            <textarea
                                    class="form-control" id="taskComment"
                                    formControlName="taskComment"
                                    rows="2">
                            </textarea>
                        </div>

                        <div class="form-group col-6">
                            <label for="assignedTo" i18n>Assigned to: *</label>
                            <select class="form-control" id="assignedTo" formControlName="assignedTo">
                                <option *ngFor="let user of users" [value]="user">{{user}}</option>
                            </select>
                        </div>

                        <div class="form-group col-6">
                            <label for="status" i18n>Status *</label>
                            <select class="form-control" id="status" formControlName="status">
                                <option value="todo" i18n>To do</option>
                                <option value="inProgress" i18n>In progress</option>
                                <option value="done" i18n>Done</option>
                            </select>
                        </div>

                        <!-- <div class="col-6">
                            Assigned: <br>
                            <span *ngFor="let user of userAssigned; let index = index">
                                <button (click)="deleteUserAssigned(index)" class="btn btn-outline-danger" style="height: 20px;width: 20px;padding: 0 0 25px 0;margin-bottom: 2px;">x</button>
                                {{user}}<br>
                            </span>
                            <span *ngIf="userAssigned.length === 0 || userAssigned.length == null" style="margin-left: 10%;">-- Nobody --</span>
                        </div>   -->
                    </div>

                    <div class="mb-5">
                        <button type="submit" class="btn btn-success button--color float-right"
                                [disabled]="!taskForm.valid" i18n>Save Task
                        </button>
                        <button type="button" class="btn btn-secondary float-left"
                                data-dismiss="modal" (click)="dismissForm()" i18n>Close
                        </button>
                    </div>
                </form>
                <hr>
            </div>

            <div class="card-body">
                <div *ngFor="let task of allTasks; let index = index;" [@bounceOutRightOnLeave]>
                    <div class="row mb-2 task">
                        <app-task [index]="index" [Task]='task' class="appTask" (taskEdit)="editTask($event)"></app-task>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>


<svg display="none">
    <symbol width="24" height="24" viewBox="0 0 24 24" id="todoIcon">
        <path d="M2.63816372,4.8536377 C5.80331554,1.15041418 11.027246,-0.0298638476 15.4770621,1.95285834 C15.9815346,2.17763814 16.2082703,2.76881393 15.9834905,3.27328641 C15.7587107,3.77775889 15.1675349,4.0044946 14.6630624,3.7797148 C11.0223038,2.15748756 6.74817888,3.12316958 4.15850921,6.15307973 C1.56883953,9.18298988 1.28052739,13.5553533 3.44992201,16.8990745 C5.61931663,20.2427957 9.72963221,21.7613449 13.5518796,20.6312257 C17.374127,19.5011065 19.9977835,15.9915321 20.0000623,12.0062866 L20.0000623,12.0062866 L20.0000623,11.0762866 C20.0000623,10.5240018 20.4477775,10.0762866 21.0000623,10.0762866 C21.552347,10.0762866 22.0000623,10.5240018 22.0000623,11.0762866 L22.0000623,11.0762866 L22.0000623,12.0068583 C21.9972767,16.8784128 18.7905855,21.1678926 14.1189498,22.5491494 C9.44731406,23.9304062 4.42359501,22.0744017 1.7721127,17.9876313 C-0.879369607,13.9008609 -0.526988104,8.55686121 2.63816372,4.8536377 Z M21.2929555,2.29917979 C21.6834798,1.9086555 22.3166448,1.9086555 22.7071691,2.29917979 C23.0976933,2.68970408 23.0976933,3.32286906 22.7071691,3.71339335 L22.7071691,3.71339335 L11.7071691,14.7133934 C11.3166448,15.1039176 10.6834798,15.1039176 10.2929555,14.7133934 L10.2929555,14.7133934 L7.29295549,11.7133934 C6.9024312,11.3228691 6.9024312,10.6897041 7.29295549,10.2991798 C7.68347978,9.9086555 8.31664476,9.9086555 8.70716905,10.2991798 L8.70716905,10.2991798 L11.0000623,12.592073 Z" id="path-1"></path>
    </symbol>
</svg>
