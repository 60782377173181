import { Component, OnInit } from '@angular/core';
import { CheckDeviceService } from '../../../../core/http/check-device.service';

@Component({
  selector: 'app-settings-dashboard-option-form',
  templateUrl: './settings-dashboard-option-form.component.html',
  styleUrls: ['./settings-dashboard-option-form.component.scss']
})
export class SettingsDashboardOptionFormComponent implements OnInit {

  constructor(private checkDeviceService: CheckDeviceService) { }

  ngOnInit() {
  }

  displayGuestsInvited($event) {
    this.checkDeviceService.displayCardGuestsInvited = !this.checkDeviceService.displayCardGuestsInvited;
    console.log(this.checkDeviceService.displayCardGuestsInvited);
  }

}
