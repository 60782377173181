import { Component, OnInit } from '@angular/core';
// import { ActivatedRoute, ParamMap } from '@angular/router';
// import { ParticipantService } from 'src/app/core/http/participant.service';

@Component({
  selector: 'app-update-participant',
  templateUrl: './update-participant.component.html',
  styleUrls: ['./update-participant.component.scss']
})
export class UpdateParticipantComponent implements OnInit {

  constructor(
    // private route: ActivatedRoute,
    // private ParticipantService: ParticipantService
  ) { }
  
  // participantID;
  // selectedParticipant;

  // urlEdit;
  // stampClass;
  // statusCut;
  // company;
  // countryCompany;
  // companyID;

  async ngOnInit() {
    // this.route.paramMap.subscribe((params: ParamMap) => { // Recupère l'id pour faire appel api ?
    //   this.participantID = params.get('id');
    // });

    // this.selectedParticipant = await this.ParticipantService.getParticipantByID(this.participantID).toPromise();
    //this.company = this.getDataService.companyList[this.selectedParticipant.attendee.companyID - 1];
    //this.countryCompany = (this.company.countryCompany.substr(0,3)).toUpperCase();
    //this.stampClass = this.getDataService.classStamp(this.selectedParticipant.participation.status);
    //this.statusCut = this.selectedParticipant.participation.status.substr(0,3) + '.';
    //this.companyID = this.selectedParticipant.attendee.companyID;
  }

}
