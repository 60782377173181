import { Component, Input } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav-mobile',
  templateUrl: './sidenav-mobile.component.html',
  styleUrls: ['./sidenav-mobile.component.scss']
})
export class SidenavMobileComponent {
  @Input() mobileQuery;  // : MediaQueryList     NE FONCTIONNE PAS AVEC SAFARI
  displaySearchBar = true;

  constructor(private authenticationService: AuthenticationService,
              private toastr: ToastrService,
              private router: Router) { }

  display() {
    this.displaySearchBar = !this.displaySearchBar;
  }

  setMyStyles() {
    const styles = {
      fill: this.displaySearchBar ? '#244746' : '#84c000',
      'margin-left': '10px',
      cursor: 'pointer'
    };
    return styles;
  }

  logout() {
    this.authenticationService.logout();
    this.toastr.success('Success', 'Profile logout');
    this.router.navigateByUrl('/');
  }

}
