import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-card-desktop',
  templateUrl: './user-card-desktop.component.html',
  styleUrls: ['./user-card-desktop.component.scss']
})
export class UserCardDesktopComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
