<div class="container-fluid header-event-responsive font-color" style="padding: 0;">
  <div class="maincontainer p-2 content-header-event">
    <h5 class="float-left mt-1 nowayMedium ml-2 title" i18n>ITDISTRI</h5>
    <span class="ml-5 noway title" i18n>September</span>

    <div class="dropdown float-right">
      <svg class="ml-3 cursor mr-2" width="20px" height="20px" viewBox="0 0 24 24"
        style="fill: white; opacity: 0.5; margin-bottom: 5px;" id="dropdownMenuButton" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <use xlink:href="#ic_Settings" />
      </svg>
      <div class="dropdown-menu menu-nav animate slideIn dropdown-menu-right" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item" href="/fr-FR/">Fr</a>
        <a class="dropdown-item" href="/en-US/">En</a>
        <a class="dropdown-item" [routerLink]="['/admin/settings']" i18n>Settings</a>
        <a class="dropdown-item" (click)="logout()" i18n>Logout</a>
      </div>
    </div>

    <svg [routerLink]="['/admin/statistics']" class="ml-5 cursor float-right" width="20px" height="20px"
      viewBox="0 0 24 24" style="fill: white; opacity: 0.5; margin-top: 4px">
      <use xlink:href="#activity" />
    </svg>

    <strong class="nowayBold float-right pb-1">
        <!-- ICI AJOUTER UN COUNTDOWN -->
    </strong>
  </div>
</div>