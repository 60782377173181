<form [formGroup]="updateEventRegistrationFormGroup" (ngSubmit)="onSubmit()">
    <div [ngClass]="userSelected ? 'userCardSelected' : 'userCard'">
        <div class="row justify-content-beetween" style="margin-bottom: 20px;">
            <div class="col-md-9">
                <div class="col-md-6 row">
                    <a [routerLink]="detailUrl">
                        <h3>{{data.firstName}} {{data.lastName}}</h3>
                    </a>
                    <svg [routerLink]="updateUrl" class="svgEdit" id="svgEdit"
                        style="cursor:pointer;margin-top: -5px;fill: #fffefb;margin-left: 15px;overflow: visible"
                        height="24px" width="24px">
                        <use xlink:href="#edit" />
                    </svg>
                </div>
            </div>
            <div class="col-md-3">
                <a [routerLink]="detailUrl">
                    <h3>{{data.company}}</h3>
                </a>
            </div>
        </div>
        <div class="row l2">
            <span class="badge badge-pill badge-status badge-status-{{data.status}}"
                style="opacity: 0.6; position: relative; top: 40%;">{{data.status}}.</span>
            <app-form-select class="margin_right">
                <label for="ChannelType" class="label col-sm-5 col-form-label" style="width: 50px;" i18n>Type :</label>
                <select class="select form-control" id="IdType" formControlName="idType" style="width: 150px;">
                    <option *ngFor="let statusType of this.listOfType" [value]="statusType.id">
                    {{statusType.label}}
                    </option>
                </select>
            </app-form-select>
            <div class="margin_right">
                <app-form-select>
                    <label for="ChannelType" class="label col-sm-5 col-form-label" style="width: 50px;" i18n>Status :</label>
                    <select class="select form-control" id="Status" formControlName="status" style="width: 150px;">
                        <option *ngFor="let statusType of this.listOfStatus" value="{{statusType.short}}">
                        {{statusType.label}}
                        </option>
                    </select>
                </app-form-select>
                <div style="display: flex;">
                    <p class="col-sm-7" style="padding-left: 0;" i18n>Edited :</p>
                    <p>{{data.eventRegistrationDate}}</p>
                </div>
            </div>
            <div>
                <app-form-select>
                    <label for="ChannelType" class="label col-sm-5 col-form-label" style="width: 50px;" i18n>Onsite :</label>
                    <select class="select form-control" id="OnsiteStatus" formControlName="onsiteStatus" style="width: 150px;">
                        <option *ngFor="let evtStatus of this.listOfOnsiteEvent" value="{{evtStatus.short}}">
                        {{evtStatus.label}}
                        </option>
                    </select>
                </app-form-select>
                <div style="display: flex;">
                    <p class="col-sm-7" style="padding-left: 0;" i18n>Edited :</p>
                    <p>{{data.onsiteDate}}</p>
                </div>
            </div>
        </div>

        <div class="l3" style="display: flex;">
            <button type="submit" class="btn-success btn-size btn" style="color: white;" style="margin-left: auto; height: 35px; font-size: 15px;" i18n>Save</button>
        </div>
        <hr>

        <div class="row footer">
            <p i18n>EVENT :</p>
            <span>{{data.eventName}}</span>
            <p i18n>EVENT START :</p>
            <span>{{data.startDate}}</span>
            <p i18n>EMAIL :</p>
            <span>{{data.email}}</span>
        </div>
    </div>
</form>