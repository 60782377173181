import { Component, OnInit, Input } from '@angular/core';
import { CompanyService } from '../../../../core/http/company.service';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-company-header',
  templateUrl: './company-header.component.html',
  styleUrls: ['./company-header.component.scss']
})
export class CompanyHeaderComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private companyService: CompanyService
  ) { }

  listOfDetailsCompany: any;
  idCompany: any;

  async ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.idCompany = params.get('id');
    });
    this.listOfDetailsCompany = await this.companyService.getCompanyByID(this.idCompany).toPromise();
  }
}

