import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RefService } from '../../../../core/http/ref.service';
import { ParticipantService } from '../../../../core/http/participant.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-card-contact-update',
  templateUrl: './user-card-contact-update.component.html',
  styleUrls: ['./user-card-contact-update.component.scss']
})
export class UserCardContactUpdateComponent implements OnInit {

  @Output() countUser: EventEmitter<any> = new EventEmitter(); // Emit l'etat de la checkbox
  @Input() data; // On recoit les data a afficher depuis List-User-Desktop
  @Input() check;

  userSelected = false;
  detailUrl;
  updateUrl;
  updateCompanyUrl;
  mailAdressUrl;
  telUrl;
  url: any;
  route: string;
  public href: string;
  updateParticipantFormGroup: FormGroup;
  listOfChannelType: any;
  nbChan: number;
  listOfChannelAtten: any;
  nbAtten: number;
  listOfChannelStatus: any;
  nbStatus: number;
  badgeStatus: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private RefService: RefService,
    private ParticipantService: ParticipantService,
    private toastr: ToastrService
  ) {}
  


  ngOnInit() {
    console.log(this.data);
    this.detailUrl = '/admin/contacts/' + this.data.idPerson + "/details";
    this.updateUrl = '/admin/contacts/' + this.data.idPerson + "/edit";
    this.href = this.router.url;
    this.url = this.href.split('/');
    this.route = this.url[this.url.length - 2];
    this.iniStatusForContact();
    this.RefService.getTypeParticipant().subscribe( response => {
      this.listOfChannelType = response;
    });
    this.RefService.getAttendanceType().subscribe( response => {
      this.listOfChannelAtten = response;
    });
    this.RefService.getStatus().subscribe( response => {
      this.listOfChannelStatus = response;
      for (let i = 0; this.listOfChannelStatus.length > i; i++) {
        if (this.route == "contacts") {
          if (this.listOfChannelStatus[i].short == "conf") { this.listOfChannelStatus[i].short = "act"; }
          if (this.listOfChannelStatus[i].short == "inv") { this.listOfChannelStatus[i].short = "occa"; }
          if (this.listOfChannelStatus[i].short == "can") { this.listOfChannelStatus[i].short = "old"; this.listOfChannelStatus[i].label = "Old"; }
          if (this.listOfChannelStatus[i].short == "short") { this.listOfChannelStatus[i].short = "pen"; }
        } else if (this.route == "events") {
          if (this.listOfChannelStatus[i].short == "pen") { this.listOfChannelStatus[i].short = "short"; }
        }
      }
    });
    if (this.data.idAttendanceType != 0 && this.data.idAttendanceType != 1) { this.data.idAttendanceType = 0; }
    this.updateParticipantFormGroup = this.fb.group({
      statusContact: [this.data.status],
      main: [this.data.main],
      vip: [this.data.vip],
      doNotPostEmail: [this.data.doNotPostEmail],
      incident: [this.data.incident],
      idChannelType: [this.data.idParticipantType],
      shareEmail: [this.data.shareEmail],
      shareMobile: [this.data.shareMobile],
      idAttendanceType: [this.data.idAttendanceType]
    });
  }
  iniStatusForContact() {
    if (this.route == "contacts") {
      if (this.data.status == "conf") { this.data.status = "act"; this.badgeStatus = "act"; }
      else if (this.data.status == "inv") { this.data.status = "occa"; this.badgeStatus = "occa"; }
      else if (this.data.status == "can") { this.data.status = "old"; this.badgeStatus = "old"; }
      else if (this.data.status == "short") { this.data.status = "pen"; this.badgeStatus = "pen"; }
      else if (this.data.status == "" || !this.data.status) { this.badgeStatus = "none"; }
      else { this.badgeStatus = this.data.status; }
    }
  }
  onSubmit() {
    this.updateParticipantFormGroup["value"].idAttendanceType = this.updateParticipantFormGroup["value"].idAttendanceType == 0 ? false : true;
    this.ParticipantService.updateFastParticipantContactInformation(this.updateParticipantFormGroup["value"], this.data.idPerson).subscribe(res => {
      console.log("Success", res);
      this.toastr.clear();
      this.toastr.success('Success', 'Profile Update');
    }, error =>{
      console.log("Error", error);
      this.toastr.clear();
      this.toastr.error('Error', 'Profile Not Update');
    });
  }
}
