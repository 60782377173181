
<div class="userCard" (click)='selectUser()' [ngClass]="userSelected ? 'userCardSelected' : 'userCard'" >
  <!-- <input type="checkbox"> -->
  
  <div class="firstLineCard">
    <div class="userPart">
      <span class="badge badge-pill badge-danger" style="opacity: 0.6;">D</span>
      <div class="btn-group dropleft">
            <svg width="24px" height="24px" class="opacity-icon  mt-2 mr-3" id="dropdownMenuButton"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <use xlink:href="#action"/></svg>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="#" i18n>Action</a>
            <a class="dropdown-item" href="#" i18n>Another action</a>
            <a class="dropdown-item" href="#" i18n>Something else here</a>
        </div>
      </div>
      <h3 i18n>Thelonious Monk</h3>
      <span class="badge badge-pill badge-success" style="top: 0;left: 10px;background-color: #84C000;" i18n>MAIN</span>
      <span class="badge badge-pill badge-success" style="top: 0; left: 15px;background-color: #84C000;" i18n>VIP</span>
    </div>
  </div>
  
  <div class="secondLinePart">
    <div class="companyPart">
      <a [routerLink]="['/admin/contacts/1/details']"><h3 i18n>Microsoft</h3></a>
      <p i18n>Lead Software Developer</p>        
    </div>
    <div class="contactPart">
        <a href="mailto:thelonious.monk@microsoft.com">
          <svg  width="30px" height="30px" style="opacity: 0.2; cursor:pointer;margin-right: 10px;"><use xlink:href="#mail"/></svg>
        </a>
        <a href="tel:+33658786963">
            <svg  width="30px" height="30px" style="opacity: 0.2; cursor:pointer;"><use xlink:href="#phone"/></svg>
        </a> 
    </div>
  </div>
  
</div>
