<div class="block__size--responsive">

  <div class="card card-font-size" style="margin-top: 14px">
    <div class="card-body p-3">
      <div class="row">
        <div class="col-xl-5 col-lg-5 col-md-6 pr-0 card-responsive">
          <p class="card-text mb-2" i18n>ADDED</p>
          <p class="card-text mb-2" i18n>MODIFIED</p>
          <p class="card-text" i18n>LOGIN</p>
        </div>
        <div class="col-xl-7 col-lg-7 col-md-6 ml-0 pr-0 pl-0 card-responsive">
          <p class="card-text nowayRegular mb-2">05/06/2018</p>
          <p class="card-text nowayRegular mb-2">05/06/2018</p>
          <p class="card-text nowayRegular pr-2">
            tabatha.eventcatalyst...</p>
        </div>
      </div>
    </div>
  </div>

  <div class="card mt-3">
    
    <div class="card-body" style="padding-bottom: 20px">
      
      <div class="btn-group dropright dropCard dropBlock1" style="margin-top: 13px!important;">
        <svg class="float-right" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <use xlink:href="#action"/>
        </svg>
        <div class="dropdown-menu dropright" aria-labelledby="dropdownMenuButton" style="top: -15px;">
            <span class="dropdown-menu-arrow"></span>
            <a class="dropdown-item" (click)="logging(contentLOGGING)" i18n>View all</a>
        </div>
      </div>

      <h6 class="nowayRegular mt-3" style="color: #244746; font-size: 18px; font-weight: bold" i18n>LOGGING </h6>
      <div class="card-font-size mt-3">
        <p class="mb-0 opacity-text ">
          <span class="nowayRegular card-text" i18n>Last login</span>
          <span class="float-right" i18n>05/06/2018 <span class="mr-2"></span> 10:01am</span>
        </p>
        <p class="mb-0 opacity-text ">
          <span class="nowayRegular card-text" i18n>Last login</span>
          <span class="float-right" i18n>05/06/2018 <span class="mr-2"></span> 10:01am</span>
        </p>
        <p class="mb-0 opacity-text ">
          <span class="nowayRegular card-text" i18n>Last login</span>
          <span class="float-right" i18n>05/06/2018 <span class="mr-2"></span> 10:01am</span>
        </p>
      </div>
    </div>

    <ng-template #contentLOGGING let-modal>
      <div class="modal-header">
        <h4 class="modal-title" i18n>View all logging</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <table class="table table-striped">
          <thead>
          <tr>
            <th scope="col" i18n>#</th>
            <th scope="col" i18n>First</th>
            <th scope="col" i18n>Last</th>
            <th scope="col" i18n>Handle</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th scope="row" i18n>1</th>
            <td i18n>Mark</td>
            <td i18n>Otto</td>
            <td i18n>@mdo</td>
          </tr>
          <tr>
            <th scope="row" i18n>2</th>
            <td i18n>Jacob</td>
            <td i18n>Thornton</td>
            <td i18n>@fat</td>
          </tr>
          <tr>
            <th scope="row" i18n>3</th>
            <td i18n>Larry</td>
            <td i18n>the Bird</td>
            <td i18n>@twitter</td>
          </tr>
          </tbody>
        </table>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="modal.close('Close click')" i18n>Close</button>
      </div>
    </ng-template>


    <div class="card-body" style="padding-bottom: 20px">

      <div class="btn-group dropright dropCard dropBlock1">
        <svg class="float-right" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <use xlink:href="#action"/>
        </svg>
        <div class="dropdown-menu dropright" aria-labelledby="dropdownMenuButton" style="top: -15px;">
            <span class="dropdown-menu-arrow"></span>
            <a class="dropdown-item" (click)="registration(contentREGISTRATION)" i18n>View all</a>
        </div>
      </div>


      <h6 class="nowayRegular" style="color: #244746; font-size: 18px; font-weight: bold" i18n>REGISTRATION STEPS</h6>
      <div class="card-font-size mt-3">
        <p class="mb-0 opacity-text ">
          <span class="nowayRegular card-text" i18n>Registration</span>
          <span class="float-right" i18n>05/06/2019 <span class="mr-2"></span> 10:04am</span>
        </p>
        <p class="mb-0 opacity-text ">
          <span class="nowayRegular card-text" i18n>Registration</span>
          <span class="float-right" i18n>12/06/2019 <span class="mr-2"></span> 12:48am</span>
        </p>
        <p class="mb-0 opacity-text ">
          <span class="nowayRegular card-text" i18n>Registration</span>
          <span class="float-right" i18n>18/06/2019 <span class="mr-2"></span> 08:25am</span>
        </p>
      </div>
    </div>

    <ng-template #contentREGISTRATION let-modal>
      <div class="modal-header">
        <h4 class="modal-title" i18n>View all registration</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" i18n>
          body
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="modal.close('Close click')" i18n>Close</button>
      </div>
    </ng-template>

    <div class="card-body" style="padding-bottom: 20px">

      <div class="btn-group dropright dropCard dropBlock1">
        <svg class="float-right" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <use xlink:href="#action"/>
        </svg>
        <div class="dropdown-menu dropright" aria-labelledby="dropdownMenuButton" style="top: -15px;">
            <span class="dropdown-menu-arrow"></span>
            <a class="dropdown-item" (click)="achievements(contentACHIEVEMENTS)" i18n>View all</a>
        </div>
      </div>

      <h6 class="nowayRegular" style="color: #244746; font-size: 18px; font-weight: bold" i18n>ACHIEVEMENTS</h6>
      <div class="card-font-size mt-3">
        <p class="mb-0 opacity-text ">
          <span class="nowayRegular card-text" i18n>Last login</span>
          <span class="float-right" i18n>05/06/2018 <span class="mr-2"></span> 10:01am</span>
        </p>
        <p class="mb-0 opacity-text ">
          <span class="nowayRegular card-text" i18n>Last login</span>
          <span class="float-right" i18n>05/06/2018 <span class="mr-2"></span> 10:01am</span>
        </p>
        <p class="mb-0 opacity-text ">
          <span class="nowayRegular card-text" i18n>Last login</span>
          <span class="float-right" i18n>05/06/2018 <span class="mr-2"></span> 10:01am</span>
        </p>
      </div>
    </div>

    <ng-template #contentACHIEVEMENTS let-modal>
      <div class="modal-header">
        <h4 class="modal-title" i18n>View all achievements</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" i18n>
          body
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="modal.close('Close click')" i18n>Close</button>
      </div>
    </ng-template>

  </div>

  <div class="card mt-3">

    <div class="card-body" style="padding-bottom: 20px;">
      <h6 class="nowayRegular mt-3" style="color: #244746; font-size: 18px; font-weight: bold" i18n>EMAILS</h6>
      <div class="btn-group dropright dropCard">
        <svg class="float-right" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <use xlink:href="#action"/>
        </svg>
        <div class="dropdown-menu dropright" aria-labelledby="dropdownMenuButton">
            <span class="dropdown-menu-arrow"></span>
            <a class="dropdown-item" [routerLink]="['/admin/communication/emailing']" i18n>Send mail</a> 
            <a class="dropdown-item" [routerLink]="['/admin/dashboard/allTasks']" i18n>View all</a>  <!-- Route a modifier-->
        </div>
      </div>
      <div class="card-font-size">
        <p class="mb-0 opacity-text " i18n>05/06/2018 <span class="mr-2"></span> 10:01am</p>
        <p class="nowayRegular card-text" i18n>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean non accumsan tellus, quis rhoncus erat. Ut ut ornare nisi, ac tincidunt eros.
        </p>
      </div>
    </div>

    <div class="card-body" style="padding-bottom: 20px">
      <h6 class="nowayRegular" style="color: #244746; font-size: 18px; font-weight: bold" i18n>SMS</h6>
      <div class="btn-group dropright dropCard">
        <svg class="float-right" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <use xlink:href="#action"/>
        </svg>
        <div class="dropdown-menu dropright" aria-labelledby="dropdownMenuButton">
            <span class="dropdown-menu-arrow"></span>
            <a class="dropdown-item" [routerLink]="['/admin/communication/emailing']" i18n>Send sms</a>  <!-- Route a modifier-->
            <a class="dropdown-item" [routerLink]="['/admin/dashboard/allTasks']" i18n>View all</a>  <!-- Route a modifier--> 
        </div>
      </div>
      <div class="card-font-size">
        <p class="mb-0 opacity-text " i18n>05/06/2018 <span class="mr-2"></span> 10:01am</p>
        <p class="nowayRegular card-text" i18n>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean non accumsan tellus, quis rhoncus erat. Ut ut ornare nisi, ac tincidunt eros.
        </p>
      </div>
    </div>

  </div>

  <div class="mt-3">
      <!--<app-company-attendee-card [selectedCompany]="selectedCompany"></app-company-attendee-card>-->
  </div>

