import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormDataHotelService } from '../../../../core/http/form-data-hotel.service';
import { HotelService } from '../../../../core/http/hotel.service';
import { RefService } from '../../../../core/http/ref.service';
import * as _moment from 'moment';

// tslint:disable: max-line-length
@Component({
  selector: 'app-night-tab-form',
  templateUrl: './night-tab-form.component.html',
  styleUrls: ['./night-tab-form.component.scss']
})
export class NightTabFormComponent implements OnInit {

  @Input() data: any;
  @Output() afterChange = new EventEmitter<any>();
  @Output() afterSuccess = new EventEmitter<any>();
  @Output() afterDelete = new EventEmitter<any>();
  listOfHotel: any;
  listOfNight: any;
  listOfTypeRoom: any;
  listOfTypeParticipant: any;
  participantType: any = [];
  dataNight: any;
  copyNight: any;
  alreadyExist: boolean = false;
  listCheck: any;

  constructor(
    private router: Router,
    private refService: RefService,
    private route: ActivatedRoute,
    private hotelService: HotelService,
    private toastr: ToastrService,
    private formDataHotelService: FormDataHotelService
  ) {}

  ngAfterChanges() {
    this.dataNight.valueChanges.subscribe(res => {
      this.initRef();
    });
  }
  ngOnInit() {
    this.dataNight = this.formDataHotelService.dataNight();
    this.initValueForm();
    this.hotelService.getHotelByEvent(this.data.idEvent).subscribe(res => {
      this.listOfHotel = res;
    });
    this.initRef();
  }
  initRef() {
    this.hotelService.getRoomByHotel(this.dataNight["value"].idHotel).subscribe(res => {
      this.listOfTypeRoom = res;
    });
  }

  initValueForm() {
    this.participantType = this.data.typeParticipantObj;
    if(typeof(this.data.idRoom) === 'string') { this.data.idRoom = parseInt(this.data.idRoom) }
    if(typeof(this.data.date) != 'string') { this.data.date = _moment(this.data.date).format('YYYY-MM-DD HH:mm:ss'); }
    this.dataNight.patchValue({
      idNight: this.data.idNight,
      idEvent: this.data.idEvent,
      idHotel: this.data.idHotel,
      dateString: this.data.date,
      date: this.data.date,
      nbrRoom: this.data.nbrRoom,
      nbrSurbook: this.data.nbrSurbook,
      idRoom: this.data.idRoom,
      price: this.data.price,
      max: this.data.max,
      typeParticipant: this.data.typeParticipantObj,
      note: this.data.note,
    });
  }

  async onSubmit() {
    this.dataNight["value"].date = _moment(this.dataNight["value"].dateString).format('DD/MM/YYYY');
    this.dataNight["value"].typeParticipant = this.participantType;
    await this.checkData();
    if (this.alreadyExist) {
      this.toastr.clear();
      this.toastr.error('Error', 'The night already exist');
    } else if (this.dataNight["value"].idNight > 0) {
      this.hotelService.updateNight(this.dataNight["value"].idNight, this.dataNight["value"]).subscribe(res => {
        console.log('success', res);
        this.toastr.clear();
        this.toastr.success('Success', 'Night update');
      }, err => {
        console.log("Error" + err);
        this.toastr.clear();
        this.toastr.error('Error', 'Night not update');
      });
    } else {
      this.hotelService.createNight(this.dataNight["value"]).subscribe(res => {
        console.log('success', res);
        this.toastr.clear();
        this.toastr.success('Success', 'Night create');
        this.dataNight["value"].res = res.idNight;
        this.afterSuccess.emit(this.dataNight["value"]);
      }, err => {
        console.log("Error" + err);
        this.toastr.clear();
        this.toastr.error('Error', 'Night not create');
      });
    }
  }
  onChangeType(short: string, isChecked: boolean) {
    for (let i = 0; this.participantType.length > i; i++) {
      if (this.participantType[i].libelle == short) {
        !isChecked ? this.participantType[i].checked = false : this.participantType[i].checked = true
      }
    }
  }
  duplicateNight() {
    this.dataNight["value"].typeParticipantObj = this.participantType;
    this.dataNight["value"].date = this.dataNight["value"].dateString
    this.afterChange.emit(this.dataNight["value"]);
  }
  deleteNight() {
    this.hotelService.deleteNight(this.dataNight["value"].idNight).subscribe( res => {
      console.log('success', res);
      this.toastr.clear();
      this.toastr.success('Success', 'Night delete');
      this.afterDelete.emit(this.dataNight["value"].idNight);
    }, err => {
      console.log("Error" + err);
      this.toastr.clear();
      this.toastr.error('Error', 'Night not delete');
    });
  }
  async checkData() {
    this.listCheck = await this.hotelService.getNightByEvent(this.data.idEvent).toPromise();
    if (this.listCheck) {
      this.alreadyExist = false;
      for (let i = 0; this.listCheck[i]; i++) {
        if (this.listCheck[i].idHotel == this.dataNight["value"].idHotel &&
        this.listCheck[i].date == this.dataNight["value"].dateString &&
        this.listCheck[i].idRoom == this.dataNight["value"].idRoom &&
        this.listCheck[i].price == this.dataNight["value"].price &&
        this.checkTypeParticipant(this.listCheck[i].typeParticipantObj, this.dataNight["value"].typeParticipant) &&
        this.listCheck[i].max == this.dataNight["value"].max
        ) {
          this.alreadyExist = true;
          break;
        }
      }
    }
  }
  checkTypeParticipant(t1: any, t2: any) {
    for (let i = 0; t1.length > i; i++) {
      if (t1[i].libelle != t2[i].libelle || t1[i].checked != t2[i].checked) {
        return false;
      }
    }
    return true;
  }
  onChangeRoomType() {
    this.initRef();
    this.dataNight.patchValue({
      idRoom: 0
    });
  }
}
