<!-- Desktop-->
<div class="display--nav-none">
  <nav class="mt-3">
    <div class="nav nav-tabs nav-responsive" id="nav-tab" role="tablist">
      <a placement="left" popoverClass="popover-custom-light" ngbPopover="View event information" triggers="mouseenter:mouseleave"
      class="nav-item nav-link active nowayRegular" id="nav-event_info" data-toggle="tab" href="#event_info" role="tab"
      aria-controls="nav-event_info" aria-selected="true" i18n>Event information</a>
      <a placement="top" popoverClass="popover-custom-light" ngbPopover="View event contact" triggers="mouseenter:mouseleave"
      class="nav-item nav-link" id="nav-event_contacts" data-toggle="tab" href="#event_contacts" role="tab"
      aria-controls="nav-event_contacts" aria-selected="false" i18n>Contacts</a>
      <a placement="top" popoverClass="popover-custom-light" ngbPopover="View event menu" triggers="mouseenter:mouseleave"
      class="nav-item nav-link" id="nav-event_menu" data-toggle="tab" href="#event_menu" role="tab" aria-controls="nav-event_menu" aria-selected="false" i18n>Menu</a>
      <span placement="right" popoverClass="popover-custom-light popover-width" ngbPopover="Edit event" triggers="mouseenter:mouseleave" [routerLink]="updateLink">
        <svg class="ml-3" width="30px" height="30px" aria-haspopup="true" aria-expanded="false">
          <use xlink:href="#edit"/>
        </svg>
      </span>
    </div>
  </nav>
  <div class="tab-content mt-4 pb-5" id="nav-tabContent">
    <div class="tab-pane fade show active mt-1" id="event_info" role="tabpanel" aria-labelledby="nav-event_info">
      <div class="row" style="font-size: 13px">
        <div class="col-md-6" *ngFor="let section of event">
          <h6 class="nowayMedium" style="color: #244746; font-weight: bold;">{{section.title}}</h6>
          <div class="row mt-4">
            <div class="col-md-12">
              <div class="row" *ngFor="let field of section.fields">
                <div *ngIf="field.title != 'PARTICIPANT'" class="col-6">
                  <p class="fieldTitle">
                    {{field.title}}
                  </p>
                </div>
                <div class="col ft nowayRegular" style="color: #244746" *ngIf="field.title != 'MOBILE' && field.title != 'WEBSITE'
                && field.title != 'FAX' && field.title != 'PARTICIPANT'">
                  {{field.content}}
                </div>
                <div class="col ft" *ngIf="field.title == 'MOBILE' || field.title == 'FAX'">
                  <a class="no_link_a nowayRegular" style="color: #244746" href="tel:{{field.content.replace(' ','')}}" target="_blank">{{field.content}}</a>
                </div>
                <div class="col ft" *ngIf="field.title == 'WEBSITE'">
                  <a class="no_link_a nowayRegular" style="color: #244746" href="{{field.content}}" target="_blank">{{field.content}}</a>
                </div>
                <div class="col ft mb-3" *ngIf="field.title == 'PARTICIPANT'">
                  <ng-container *ngFor="let participant of field.content; last as isLast">
                    <span class="ft nowayRegular" style="color: #244746" *ngIf="!isLast">{{participant}} -&nbsp;</span>
                    <span class="ft nowayRegular" style="color: #244746" *ngIf="isLast">{{participant}}</span>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-pane fade show mt-1" id="event_contacts" role="tabpanel" aria-labelledby="nav-event_contacts">
      <div class="row" style="font-size: 13px">
        <div class="col-md-6" *ngFor="let section of eventContact">
          <h6 class="nowayMedium" style="color: #244746; font-weight: bold;">{{section.title}}</h6>
          <div class="row mt-4">
            <div class="col-md-12">
              <div class="row" *ngFor="let field of section.fields">
                <div class="col ft nowayRegular" style="color: #244746">
                  {{field.content}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-pane fade show mt-1" id="event_menu" role="tabpanel" aria-labelledby="nav-event_menu">
      <div class="row" style="font-size: 13px">
        <div class="col-md-6" *ngFor="let section of eventMenu">
          <h6 class="nowayMedium" style="color: #244746; font-weight: bold;">{{section.title}}</h6>
          <div class="row mt-4">
            <div class="col-md-12">
              <div class="row" *ngFor="let field of section.fields">
                <div class="col-6">
                  <p class="fieldTitle">
                    {{field.title}}
                  </p>
                </div>
                <div class="col ft nowayRegular" style="color: #244746">
                  {{field.content}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

