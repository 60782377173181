
<app-fil-ariane-v2 (exportExcel)="downloadList()"></app-fil-ariane-v2>

<div class="container-fluid font-color"> 
    <div class="maincontainer pt-4 pb-5">
        <div class="row justify-content-center mb-4">
            <button (click)="previousYear()" style="border: none; background-color: transparent; margin-bottom: 8px;">
                <svg width="24px" height="24px"  >
                    <use xlink:href="#left"/>
                    </svg>
            </button>
            <h2>{{this.currentYear}}</h2>
            <button (click)="nextYear()" style="border: none; background-color: transparent; margin-bottom: 10px;">
                <svg width="24px" height="24px"  >
                    <use xlink:href="#right"/>
                    </svg>
            </button>
        </div>
        <div *ngIf="this.listAllEvents?.length > 0 ">
            <div *ngFor="let event of this.listAllEvents">
                <app-event-item-card [event]="event"></app-event-item-card>
            </div>
        </div>
        <div *ngIf="this.listAllEvents?.length === 0 ">
            <p i18n>0 event found</p>
        </div>
    </div>
</div>



