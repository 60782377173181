import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ParticipantService } from 'src/app/core/http/participant.service';

@Component({
  selector: 'app-participant-header',
  templateUrl: './participant-header.component.html',
  styleUrls: ['./participant-header.component.scss']
})
export class ParticipantHeaderComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private ParticipantService: ParticipantService
  ) { }
  idParticipant: any;
  listOfDetailsContact: any;
  status: string;
    
  async ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => { // Recupère l'id pour faire appel api ?
      this.idParticipant = params.get('id');
    });
    this.listOfDetailsContact = await this.ParticipantService.getParticipantContactInformation(this.idParticipant).toPromise();
  }

  ngOnChanges() {
  }

}
