<div class="row followup-item">
    <div class="col-3 pad_right">
        <div class="w-100 d-flex justify-content-between">
            <a [routerLink]="followup_link"><p class="main_txt">{{data.mailingId}}</p></a>
            <svg height="24px" width="24px" class="SVG_arrow" [routerLink]="followup_link">
                <use xlink:href="#arrow-right" />
            </svg>
        </div>
        <p class="sub_txt mb-0 ml-1">{{data.fromAdress}}</p>
    </div>
    <div class="col-5 pad_right">
        <div class="w-100 d-flex justify-content-between">
            <p class="main_txt">{{data.subject}}</p>
            <svg height="24px" width="24px" class="SVG_arrow">
                <use xlink:href="#arrow-right" />
            </svg>
        </div>
        <p class="sub_txt mb-0"> IMAGE TRACKING: <span class="bool_tracking">{{data.imageTracking}}</span></p>
    </div>
    <div class="col-1">
        <p class="main_txt">{{data.author}}</p>
    </div>
    <div class="col-1">
        <p class="main_txt">{{data.sentDate}}</p>
        <p class="sub_txt">{{data.sentHour}}</p>
    </div>
    <div class="col-2">
        <p class="main_txt">{{data.nbMessages}}</p>
        <p class="sub_txt mb-0"><b>{{data.nbMessagesOpen}}</b> messages opened</p>
    </div>
    <div class="w-100" style="border-bottom: 2px solid #EFEEE2;margin: 0 15px;"></div>
</div>