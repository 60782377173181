<div class="filtersMobile">

    <div class="row justify-content-between">
        <div class="col-4" style="display:flex" >
            <svg width="24px" height="24px" style="fill: #84C000;" (click)="closeFilters()">
            <use xlink:href="#left"/>
            </svg>
            <h5 (click)="closeFilters()" i18n>RETOUR</h5>
        </div>

        <div class="col-4" style="display:flex">
            <h5 (click)="closeFilters()" i18n>FILTRES</h5>
            <svg width="24px" height="24px" style="fill: #84C000;" (click)="closeFilters()">
                <use xlink:href="#filter"/> 
            </svg>
        </div>
    </div>
    <br>

    <div class="row justify-content-center">
        <div class="filterName">
            <p i18n>By Zone :</p>
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="zoneContainer" id="style-1">
            <label style="margin-bottom:0">
            <input type="checkbox" checked>
            {{firstFilter}}</label>
            <div *ngFor="let zone of zoneFilter">
                <label style="margin-bottom:0">
                <input type="checkbox" id="filterZone">
                {{zone}}</label>
            </div>
        </div>
    </div>
        

    <div class="row justify-content-around">
        <div class="filterName">
            <p style="margin-top:5vh" i18n>By Country :</p>
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="zoneContainer" id="style-1">
            <label style="margin-bottom:0">
            <input type="checkbox" checked>
            {{firstFilter}}</label>
            <div *ngFor="let zone of zoneFilter">
                <label style="margin-bottom:0">
                <input type="checkbox">
                {{zone}}</label>
            </div>
        </div>
    </div>

    <br>
    <div class="row justify-content-center">
        <button type="button" class="btn btn-success btn-size btn-responsive mr-3" i18n>Apply</button>
    </div>
    <br>
    
</div>