<div class="container-fluid body-height font-color">
    <div class="container pt-5">

        <div class="row">

            <div class="col-lg-6 col-md-6 offset-md-3 mb-5">

                <div class="text-center">
                    <img class="logo pointer" [routerLink]="['']" src="#">
                </div>

                <div class="col-lg-12 login-responsive">

                    <p class="text-center mb-5 login-title" i18n>event catalyst</p>

                    <form (ngSubmit)="onSubmit()" [formGroup]="loginForm">
                        <div class="form-group">
                            <label for="email" i18n>Forgot password</label>
                            <input type="email" class="form-control" id="email" aria-describedby="emailHelp"
                                placeholder="Email adress" formControlName="email">
                        </div>
                        <div class="text-center mt-4">
                            <button type="submit" class="btn btn-lg btn-success" [disabled]="!loginForm.valid">Sent
                            </button>
                        </div>
                    </form>

                    <p *ngIf="error" class="alert mt-4 alert-content" i18n>Sorry, we couldn't find an account with
                        that mail adress.
                    </p>

                </div>

            </div>
        </div>

    </div>
</div>