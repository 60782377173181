<div id="collapseExample" *ngIf="isCollapsed">
  <form [formGroup]="firstStepFormGroup" (ngSubmit)="onSubmit()">

    <h3 class="titlePart" i18n>PARAMETERS:</h3>
    <p class="text18" i18n>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>

    <div class="row mt-5 form-row">
      <div class="col-md-5 select-custom ">
        <label for="fromMailAdress" class="label col-sm-5 col-form-label" i18n>From*</label>
        <select class="select form-control" id="fromMailAdress" formControlName="mailSenderForm">
          <option value="" disabled i18n>Select</option>
          <option *ngFor="let mailAdress of mailAdressList">
            {{ mailAdress }}
          </option>
        </select>
      </div>

      <div class="col-md-5 select-custom">
        <label for="replyTo" class="label col-sm-5 col-form-label" i18n>Reply to</label>
        <select class="select form-control" id="replyTo" formControlName="mailReplyToForm">
          <option value="" disabled i18n>Select</option>
          <option *ngFor="let mail of mailList">
            {{ mail }}
          </option>
        </select>
      </div>
    </div>

    <div class="row form-row">
      <div class="col-md-5 flex">
        <label for="CCcontacts" class="label col-sm-5 col-form-label noPaddingLabel" i18n>CC</label>
        <input type="text" class="input form-control" id="CCcontacts" formControlName="mailCCForm"
          placeholder="Mail@exemple.com">
      </div>

      <div class="col-md-5">
        <app-form-input>
          <label for="BCCcontacts" class="label col-sm-5 col-form-label" i18n>BCC</label>
          <input type="text" class="input form-control" id="BCCcontacts" formControlName="mailBCCForm"
            placeholder="Mail@exemple.com" style="margin-left: -12px;">
        </app-form-input>
      </div>
    </div>

    <div class="row form-row">

      <div class="col-md-5">
        <mat-checkbox color="none" class="check" formControlName="mailConfirmationReadCB"></mat-checkbox>
        <!-- <input class="check form-check-input" type="checkbox" value="" id="AssistantCopyCorrespondence"> -->
        <label class="label form-check-label mb-4 ml-2" for="AssistantCopyCorrespondence">
          <small style="font-size: 16px" i18n>Request confirmation read receipt</small>
        </label>
      </div>

      <div class="col-md-5">
        <mat-checkbox color="none" class="check" formControlName="mailTrackingImageForm"></mat-checkbox>
        <!-- <input class="check form-check-input" type="checkbox" value="" id="mailTrackingImageForm"> -->
        <label class="label form-check-label mb-4 ml-2" for="mailTrackingImageForm">
          <small style="font-size: 16px" i18n>Insert tracking image</small>
        </label>
      </div>
    </div>

    <hr style="border: solid 1px white; margin-top: 31px; margin-bottom: 31px">

    <div style="display:flex; justify-content: space-between" id="scrollMe">
      <h3 i18n>TEMPLATE</h3>
      <svg height="32px" width="32px" (click)="displayFilters()" class="mr-5">
        <!--[ngClass]="statut ? 'display' : 'dontDisplay'"-->
        <use xlink:href="#filter" />
      </svg>
    </div>

    <p class="text18" i18n>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>

    <div *ngIf="filterDisplay">

      <div class="row form-row mb-4 mt-4">
        <div class="col-md-5 flex">
          <label for="DDL_templateType" class="label col-sm-5 col-form-label noPaddingLabel" i18n>Template Type</label>
          <select class="select form-control" id="DDL_templateType" formControlName="templatesTypeFilter"
            (change)="onChange($event.target.value)">
            <option value="" i18n>All</option>
            <option *ngFor="let type of listOfTemplatesType">
              {{ type }}
            </option>
          </select>
        </div>
      </div>
      <hr style="border: solid 1px white; margin-top: 31px; margin-bottom: 31px">

    </div>

    <div class="row form-row">
      <div class="col-md-8 flex">
        <label for="DDL_templates" class="label col-sm-3 col-form-label noPaddingLabel" i18n>Template*</label>
        <select class="select form-control" id="DDL_templates" (change)="onTemplateChange($event.target.value)"
          formControlName="templateName">
          <option value="" disabled i18n>Select</option>
          <option *ngFor="let template of listOfMailTemplate | slice:1" [value]="template.templateName">
            {{ template.templateName }}
          </option>
        </select>
      </div>
    </div>

    <div class="row form-row">
      <div class="col-md-8 flex">
        <label for="mailSubjectForm" class="label col-sm-3 col-form-label noPaddingLabel" i18n>Subject*</label>
        <input type="text" class="input form-control" style="width:100%!important" id="mailSubjectForm"
          placeholder="Your subject" formControlName="mailSubjectForm" disabled>
      </div>
    </div>


    <div class="row mt-4" style="width:100%; display: flex; margin: 0!important; padding-top: 30px">

      <pagination-controls (pageChange)="pageChange($event)" class="myPagination ml-auto mr-auto" previousLabel=""
        nextLabel="" maxSize="3"></pagination-controls>

      <ul style="display: flex;width: 100%;padding: 0;">

        <li *ngFor="let mailTemplate of listOfMailTemplate | filter:templateType | paginate: config;"
          style="width: 16%; margin-right: 4%;list-style:none;">

          <div class="card cardTemplate">
            <div *ngIf='mailTemplate.templateId === 0'>
              <br />
            </div>
            <div class="custom-radios" *ngIf='mailTemplate.templateId !== 0'>
              <input type="radio" id="templateSelector + {{mailTemplate.templateId}}"
                value="{{mailTemplate.templateId}}" (change)="onItemChange(mailTemplate)"
                formControlName="mailTemplateSelected">
              <label for="templateSelector + {{mailTemplate.templateId}}">
                <span>
                  <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/check-icn.svg" alt="Checked Icon" />
                </span>
              </label>
            </div>
            <div class="card-body" style="padding: 5px 5px 5px 5px;">

              <div class="card"
                style="background-color: #F9F8F4; border: 1px solid #F9F8F4; height: 250px;margin-bottom: 25px;"
                *ngIf='mailTemplate.templateId === 0'>
                <div class="card-body text-center">

                  <svg (click)="newTemplate()" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample"
                    id="AddThings" height="44px" width="44px" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false" style="margin-left: 15px; margin-top: 90px; cursor: pointer">
                    <use xlink:href="#plus" style="fill: #84C000;" />
                  </svg>

                </div>
              </div>

              <div class="card" style="background-color: #F9F8F4; border: 1px solid #F9F8F4; height: 250px"
                *ngIf='mailTemplate.templateId !== 0'>
                <div class="card-body text-center">

                  <div [innerHTML]="mailTemplate.templateName"></div>

                </div>
              </div>
            </div>
            <div style="display: flex; justify-content: space-between;">

              <svg *ngIf='mailTemplate.templateId !== 0' class='svgView' height="20px" width="20px"
                style='margin-left:5%; margin-bottom: 5px;' (click)="getModalName(mailTemplate.templateId)">
                <use xlink:href="#viewDetails" style="fill:#84C000" />
              </svg>

              <svg *ngIf='mailTemplate.templateId !== 0' class='svgEdit' height="20px" width="20px"
                style='margin-right:5%; margin-bottom: 5px;'
                (click)="editTemplate(mailTemplate, mailTemplate.templateId)">
                <use xlink:href="#editDetails" style="fill:#84C000" />
              </svg>
            </div>
          </div>

          <!-- Modal -->
          <div class="modal fade" id="exampleModal{{mailTemplate.templateId}}" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header" style='display:block'>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h5 class="modal-title" id="exampleModalLabel">{{mailTemplate.templateName}}</h5>
                  <h5 class="modal-title text18"><u i18n>Subject :</u> {{mailTemplate.templateSubject}}</h5>
                </div>
                <div class="modal-body">
                  <div [innerHTML]="mailTemplate.templateBody"></div>
                </div>
              </div>
            </div>
          </div>

        </li>
      </ul>


    </div>
    <div class="mt-4" style="float: right;">
      <button mat-button type="submit" matStepperNext [disabled]="!firstStepFormGroup.valid"
        class="btn btn-success button--color" style="border: 1px solid #84C000;color: white" i18n>
        Next
      </button>
    </div>
  </form>

</div>


<div id="collapseExample" class="row">

  <app-template-editor *ngIf="!isCollapsed" (addNewTemplate)="addNewTemplate($event)" (switchView)="switchView($event)"
    [templateObject]="templateObject"></app-template-editor>

</div>