import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RefService } from '../../../../core/http/ref.service';
import { ParticipantService } from '../../../../core/http/participant.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-card-event-update',
  templateUrl: './user-card-event-update.component.html',
  styleUrls: ['./user-card-event-update.component.scss']
})
export class UserCardEventUpdateComponent implements OnInit {

  @Output() countUser: EventEmitter<any> = new EventEmitter(); // Emit l'etat de la checkbox
  @Input() data; // On recoit les data a afficher depuis List-User-Desktop
  @Input() check;

  userSelected = false;
  detailUrl;
  updateUrl;
  updateCompanyUrl;
  mailAdressUrl;
  telUrl;
  url: any;
  route: string;
  public href: string;
  updateEventRegistrationFormGroup: FormGroup;
  listOfType: any;
  nbChan: number;
  listOfOnsiteEvent: any;
  nbAtten: number;
  listOfStatus: any;
  nbStatus: number;
  badgeStatus: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private RefService: RefService,
    private ParticipantService: ParticipantService,
    private toastr: ToastrService
  ) {}
  


  ngOnInit() {
    this.detailUrl = '/admin/contacts/' + this.data.idPerson + "/details";
    this.updateUrl = '/admin/contacts/' + this.data.idPerson + "/edit";
    this.href = this.router.url;
    this.url = this.href.split('/');
    this.route = this.url[this.url.length - 2];
    this.RefService.getTypeParticipant().subscribe( response => {
      this.listOfType = response;
      for (let i = 0; this.listOfType.length > i; i++) {
        if (this.listOfType[i].short == "dis") { this.listOfType.splice(i, 1); }
        if (this.listOfType[i].short == "ret") { this.listOfType.splice(i, 1); }
      }
    });
    this.RefService.getOnSiteEventStatus().subscribe( response => {
      this.listOfOnsiteEvent = response;
    });
    this.RefService.getEventStatus().subscribe( response => {
      this.listOfStatus = response;
    });
    this.updateEventRegistrationFormGroup = this.fb.group({
      idEvent: [this.data.idEvent],
      idType: [this.data.idType],
      status: [this.data.status],
      onsiteStatus: [this.data.onsiteStatus]
    });
  }
  onSubmit() {
    this.ParticipantService.updateParticipantEventRegistration(this.updateEventRegistrationFormGroup["value"], this.data.idPerson).subscribe(res => {
      console.log("Success", res);
      this.toastr.clear();
      this.toastr.success('Success', 'Profile Update');
    }, error =>{
      console.log("Error", error);
      this.toastr.clear();
      this.toastr.error('Error', 'Profile Not Update');
    });
  }
}
