<app-fil-ariane-v2></app-fil-ariane-v2>

<div class="maincontainer-fluid font-color pb-4">
    <div class="maincontainer">
        <div class="row">
            <div class="col-md-6 col-sm-12 col-xs-12 mt-4">
                <app-extract-card [data]="contactTitle" (exportExcel)="downloadList($event)"></app-extract-card>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12 mt-4">
                <app-extract-card [data]="participantTitle" (exportExcel)="downloadList($event)"></app-extract-card>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12 mt-4">
                <app-extract-card [data]="eventTitle" (exportExcel)="downloadList($event)"></app-extract-card>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12 mt-4">
                <app-extract-card [data]="agendaTitle" (exportExcel)="downloadList($event)"></app-extract-card>
            </div>
        </div>
        <!-- <div class="modal fade bd-extract-modal-xl" tabindex="-1" role="dialog" aria-labelledby="ExtractLargeModal" aria-hidden="true" data-keyboard="false" data-backdrop="static">
            <div class="modal-dialog modal-xl">
              <div class="modal-content block_modal">
                <h4>Your extract <b>{{extract_name_requested}}</b> are under construction.</h4>
                <div class="loader_ec">
                      <app-loader></app-loader>
                </div>
                <h4>Please wait, you will be automatically redirected.</h4>
              </div>
            </div>
        </div>
        <div class="modal fade bd-dl-extract-modal-xl " tabindex="-1" role="dialog" aria-labelledby="ExtractLargeModal" aria-hidden="true" data-keyboard="false">
            <div class="modal-dialog modal-xl">
                <div class="modal-content block_modal text-center">
                    <div class="modal-header" style="border:none">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true" style="font-size: 35px;">&times;</span>
                        </button>
                    </div>
                    <h4>Your extract <b>{{extract_name_requested}}</b> are ready to download</h4>
                    <svg  width="240px" height="240px" style="fill: #84C000!important; margin: auto;">
                        <use xlink:href="#taskDone-xl" />
                    </svg> 
                    <div class="d-flex justify-content-center" style="width: 80%;margin: 0 10%;">
                        <button type="button" class="btn btn-classic btn-size btn-responsive dl_btn mt-3" data-dismiss="modal" aria-label="Close">
                            <svg width="24px" height="24px" style="fill: #84C000!important;">
                                <use xlink:href="#delete" />
                            </svg>  
                            Cancel
                        </button>   
                        <button type="button" class="btn btn-success btn-size btn-responsive dl_btn mt-3" (click)="dl_extract()">
                            <svg width="24px" height="24px">
                                <use xlink:href="#download" />
                            </svg>  
                            Download
                        </button>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>
