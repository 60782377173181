import { Component, OnInit } from '@angular/core';
import { EmailingService } from '../../../core/http/emailing.service';
import { ActivatedRoute, Router } from '@angular/router';
import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-followup-list',
  templateUrl: './followup-list.component.html',
  styleUrls: ['./followup-list.component.scss']
})
export class FollowupListComponent implements OnInit {

  collection = [];
  config: any;
  nbItems = 10;
  startDate;
  toDate;

  constructor(private emailingService: EmailingService,  
              private route: ActivatedRoute,
              private router: Router,
              private calendar: NgbCalendar) 
  { 
    this.config = {
      currentPage: 1,
      itemsPerPage: this.nbItems
    };

    this.route.queryParamMap.pipe(map(params => params.get('page')))
    .subscribe(page => this.config.currentPage = page);

  }

  ngOnInit() {
    this.collection = this.emailingService.listOfMailing;
    this.startDate = this.getPassedDate(2,  new Date());
    this.toDate = this.calendar.getToday();
  }

  getPassedDate(month:number, date){
    var d = date;
    d.setMonth(d.getMonth() - month);
    var ngbDateStruct = { day: d.getUTCDate(), month: d.getUTCMonth() +1, year: d.getUTCFullYear()};
    return ngbDateStruct;
  }

  onItemsChange(event) {
    this.config.itemsPerPage = event;
  }

  pageChange(newPage: number) {
    this.router.navigate(['admin/communication/emailing/followup/'], { queryParams: { page: newPage } });
  }

}
