import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor() { }

  cardType: any;
  createCard1(title: string, status: string, nbPart: number = 0, route: string) {
    if (route != '') { route += "/" + status; }
    else route = status;
    return this.cardType = {
      cardType: 1,
      cardData: {
        title: title.substring(0, 1).toUpperCase() + title.substring(1),
        status: status.toUpperCase(),
        stampClass: `badge-status-${status.toLowerCase().replace(' ', '-')}`,
        nb_participants: nbPart,
        nb_participantsN1: nbPart,
        urlList: `/admin/contacts/${route}`,
        urlExtract: 'http://ecv5-test.eventcatalyst.io/assets/extract_test.xls',
        alt_N1: `${nbPart} ${status.replace('-', ' ')} ${title} at the same time last year`,
      }
    }
  }
  createCard2(title: string, nbRej: number = 0, nbCan: number = 0, nbDec: number = 0, nbCan7: number = 0) {
    return this.cardType = {
      cardType: 2,
      cardData: [
        { title: title.substring(0, 1).toUpperCase() + title.substring(1) },
        { data: nbRej, dataLabel: 'rejected', urlList: '/admin/contacts/rejected', altNb: `${nbRej} are rejected` },
        { data: nbCan, dataLabel: 'cancelled', urlList: '/admin/contacts/cancelled', altNb: `${nbCan} are cancelled` },
        { data: nbDec, dataLabel: 'declined', urlList: '/admin/contacts/declined', altNb: `${nbDec} are declined` },
        { data: nbCan7, dataLabel: 'cancelled d-7', urlList: '/admin/contacts/cancelled7', altNb: `${nbCan7} are cancelled d-7` }
      ]
    }
  }
  createCard3(title: string, url: string) {
    return this.cardType = {
      cardType: 3,
      cardData: {
        title: title.substring(0, 1).toUpperCase() + title.substring(1),
        urlAdd: `/admin/contacts/${url}`
      }
    }
  }
  createCard4(title: string, route: string, nbAll: number = 0, nbShortOrAtt: number = 0,
  nbInv: number = 0, nbWait: number = 0, nbRej: number = 0, nbConf: number = 0,
  nbDec: number = 0, nbCan: number = 0, nbCan7: number = 0) {
    return this.cardType = {
      cardType: 4,
      cardData: {
        title: title.substring(0, 1).toUpperCase() + title.substring(1),
        route: route,
        nb_participants: nbAll,
        nb_short_att: nbShortOrAtt,
        nb_invited: nbInv,
        nb_waiting: nbWait,
        nb_rejected: nbRej,
        nb_confirmed: nbConf,
        nb_declined: nbDec,
        nb_cancelled: nbCan,
        nb_cancelled7: nbCan7
      }
    }
  }
  createCard5(title: string, route: string, date: string, open: number, sent: number) {
    return this.cardType = {
      cardType: 5,
      cardData: {
        cardTitle: title.substring(0, 1).toUpperCase() + title.substring(1),
        oneToOne: `${title.substring(0, 1).toUpperCase() + title.substring(1)} recap`,
        routerLink: `/admin/communication/${route}`,
        urlExtract: 'http://ecv5-test.eventcatalyst.io/assets/extract_test.xls',
        date: date,
        nbrOpened: open,
        txtOpened: `${title.toUpperCase()} OPENED`,
        taux: open / sent,
        nbrSent: sent,
        txtSent:  `${title.toUpperCase()} SENT`,
      }
    }
  }
  // /////////////////////////////////////////////////////////////// MAIN DASHBOARD ///////////////////////////////////////////////////////////////
  // createCardDashboard() {
  //   this.dashboarDataSet = [
  //     {
  //       cardType: 1,
  //       cardData: {
  //         participantType: 'Contacts',
  //         participantStatus: 'INVITED',
  //         stampClass: 'card__badge-invited',
  //         nb_participants: this.nbInv,
  //         nb_participantsN1: this.nbInv,
  //         urlList: '/admin/contacts/list/contacts_invited',
  //         urlExtract: 'http://ecv5-test.eventcatalyst.io/assets/extract_test.xls',
  //         alt_N1: this.nbInv + ' invited guests at the same time last year',
  //         urlNewList: '/admin/contacts/list/new_guests_invited'
  //       }
  //     },
  //     {
  //       cardType: 1,
  //       cardData: {
  //         participantType: 'Contacts',
  //         participantStatus: 'CONFIRMED',
  //         stampClass: 'card__badge-confirmed',
  //         nb_participants: this.nbConf,
  //         nb_participantsN1: this.nbConf,
  //         urlList: '/admin/contacts/list/contacts_confirmed',
  //         urlExtract: 'http://ecv5-test.eventcatalyst.io/assets/extract_test.xls',
  //         alt_N1: this.nbConf + ' confirmed contact at the same time last year',
  //         urlNewList: '/admin/contacts/list/new_guests_confirmed'
  //       }
  //     },
  //     {
  //       cardType: 1,
  //       cardData: {
  //         participantType: 'Contacts',
  //         participantStatus: 'SHORTLIST',
  //         stampClass: 'card__badge-shortlist',
  //         nb_participants: this.nbShort,
  //         nb_participantsN1: this.nbShort,
  //         urlList: '/admin/contacts/list/contacts_shortlist',
  //         urlExtract: 'http://ecv5-test.eventcatalyst.io/assets/extract_test.xls',
  //         alt_N1: this.nbShort + ' confirmed guests at the same time last year',
  //         urlNewList: '/admin/contacts/list/new_guests_shortlist'
  //       }
  //     },
  //     {
  //       cardType: 2,
  //       cardData: {
  //         cardTitle: 'Emails',
  //         oneToOne: 'Emails recap',
  //         routerLink: '/admin/communication/emailing',
  //         urlExtract: 'http://ecv5-test.eventcatalyst.io/assets/extract_test.xls',
  //         date: this.dateEmail,
  //         nbrOpened: this.nbOpenEmail,
  //         txtOpened: "EMAILS OPENED",
  //         taux: this.nbOpenEmail / this.nbSentEmail,
  //         nbrSent: this.nbSentEmail,
  //         txtSent: "EMAILS SENT",
  //       }
  //     },
  //     {
  //       cardType: 2,
  //       cardData: {
  //         cardTitle: 'Sms',
  //         oneToOne: 'Sms recap',
  //         routerLink: '/admin/communication/emailing',
  //         urlExtract: 'http://ecv5-test.eventcatalyst.io/assets/extract_test.xls',
  //         date: this.dateSms,
  //         nbrOpened: this.nbOpenSms,
  //         txtOpened: "SMS OPENED",
  //         taux: this.nbOpenSms / this.nbSentSms,
  //         nbrSent: this.nbSentSms,
  //         txtSent: "SMS SENT",
  //       }
  //     },
  //     {
  //       cardType: 2,
  //       cardData: {
  //         cardTitle: 'Notifications push',
  //         oneToOne: 'Notification recap',
  //         routerLink: '/admin/communication/emailing',
  //         urlExtract: 'http://ecv5-test.eventcatalyst.io/assets/extract_test.xls',
  //         date: this.dateNotif,
  //         nbrOpened: this.nbOpenNotif,
  //         txtOpened: "RECEIVED NOTIF",
  //         taux: this.nbOpenNotif / this.nbSentNotif,
  //         nbrSent: this.nbSentNotif,
  //         txtSent: "SENT NOTIF",
  //       }
  //     },
  //   ];
  // }
  // /////////////////////////////////////////////////////////////// DASHBOARD LIST ///////////////////////////////////////////////////////////////
  // createCardList() {
  //   this.listsDashboardataSet = [
  //     {
  //       cardType: 0,
  //       cardData: {
  //         title: this.title,
  //         participantType: this.type,
  //         nb_participants: this.nbAll,
  //         nb_shortlist: this.nbShort,
  //         nb_invited: this.nbInv,
  //         nb_waiting: this.nbWait,
  //         nb_rejected: this.nbRej,
  //         nb_confirmed: this.nbConf,
  //         nb_declined: this.nbDec,
  //         nb_cancelled: this.nbCan,
  //         nb_cancelled7: this.nbCan7,
  //         nb_attended: 0,
  //         nb_replaced: 0,
  //         nb_noShow: 0,
  //       }
  //     },
  //     {
  //       cardType: 5,
  //       cardData: {
  //         participantType: 'Contact',
  //       }
  //     },
  //     {
  //       cardType: 1,
  //       cardData: {
  //         participantType: 'Contacts',
  //         participantStatus: 'CONFIRMED',
  //         stampClass: 'card__badge-confirmed',
  //         nb_participants: this.nbConf,
  //         nb_participantsN1: 0,
  //         urlList: '/admin/contacts/list/contacts_confirmed',
  //         urlExtract: 'http://ecv5-test.eventcatalyst.io/assets/extract_test.xls',
  //         alt_N1: this.nbConf + ' confirmed contacts at the same time last year',
  //         urlNewList: '/admin/contacts/list/new_contacts_confirmed'
  //       }
  //     },
  //     {
  //       cardType: 1,
  //       cardData: {
  //         participantType: 'Contacts',
  //         participantStatus: 'INVITED',
  //         stampClass: 'card__badge-invited',
  //         nb_participants: this.nbInv,
  //         nb_participantsN1: 0,
  //         urlList: '/admin/contacts/list/contacts_invited',
  //         urlExtract: 'http://ecv5-test.eventcatalyst.io/assets/extract_test.xls',
  //         alt_N1: this.nbInv + ' invited contacts at the same time last year',
  //         urlNewList: '/admin/contacts/list/new_contacts_invited'
  //       }
  //     },
  //     {
  //       cardType: 1,
  //       cardData: {
  //         participantType: 'Contacts',
  //         participantStatus: 'WAITING LIST',
  //         stampClass: 'card__badge-waiting-list',
  //         nb_participants: this.nbWait,
  //         nb_participantsN1: 0,
  //         urlList: '/admin/contacts/list/contacts_waiting-list',
  //         urlExtract: 'http://ecv5-test.eventcatalyst.io/assets/extract_test.xls',
  //         alt_N1: this.nbWait + ' waiting list contacts at the same time last year',
  //         urlNewList: '/admin/contacts/list/new_contacts_waiting-list'
  //       }
  //     },
  //     {
  //       cardType: 1,
  //       cardData: {
  //         participantType: 'Contacts',
  //         participantStatus: 'SHORTLIST',
  //         stampClass: 'card__badge-shortlist',
  //         nb_participants: this.nbShort,
  //         nb_participantsN1: 0,
  //         urlList: '/admin/contacts/list/contacts_shortlist',
  //         urlExtract: 'http://ecv5-test.eventcatalyst.io/assets/extract_test.xls',
  //         alt_N1: this.nbShort + ' rejected contacts at the same time last year',
  //         urlNewList: '/admin/contacts/list/new_contacts_shortlist'
  //       }
  //     },
  //     {
  //       cardType: 2,
  //       cardData: [
  //         { participantType : 'Contacts'},
  //         { data: this.nbRej, dataLabel: 'rejected' },
  //         { data: this.nbCan, dataLabel: 'cancelled' },
  //         { data: this.nbDec, dataLabel: 'declined' },
  //         { data: this.nbCan7, dataLabel: 'cancelled d-7' },
  //       ]
  //     },
  //     {
  //       cardType: 3,
  //       cardData: [
  //         { participantType : 'Contact'},
  //         {
  //           type: 'Contact',
  //           status: 'CONFIRMED',
  //           stampClass: 'card__badge-confirmed',
  //           nbNew: 0,
  //           alt: 'View contacts confirmed list',
  //           urlList: '/admin/contacts/list/new_contacts_confirmed',
  //         },
  //         {
  //           type: 'Contact',
  //           status: 'WAITING LIST',
  //           stampClass: 'card__badge-waiting-list',
  //           nbNew: 0,
  //           alt: 'View waiting list contacts list',
  //           urlList: '/admin/contacts/list/new_contacts_waiting-list',
  //         },
  //         {
  //           type: 'Contact',
  //           status: 'DECLINED',
  //           stampClass: 'card__badge-declined',
  //           nbNew: 0,
  //           alt: 'View guests declined list',
  //           urlList: '/admin/contacts/list/new_contacts_declined',
  //         },
  //       ]
  //     },
  //     {
  //       cardType: 4,
  //       cardData:
  //       {
  //         type: 'Contacts',
  //         cardTitle: 'project information',
  //         complete: 0,
  //         incomplete: 0,
  //       }
  //     },
  
  //   ];
  // }
}
