<div class="row mt-4">

    <div class="col-xl-3 col-lg-4 mb-3 col-responsive">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-9">
                        <h5 class="card-title card__margin--top" i18n>Partners Invited</h5>
                    </div>
                    <div class="col-3 card__margin--top">
                        <div class="custom-control custom-switch float-right">
                            <input type="checkbox" checked class="custom-control-input" id="customSwitch">
                            <label class="custom-control-label" for="customSwitch"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-4 mb-3 col-responsive">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-9">
                        <h5 class="card-title card__margin--top" i18n>Partners Confirmed</h5>
                    </div>
                    <div class="col-3 card__margin--top">
                        <div class="custom-control custom-switch float-right">
                            <input type="checkbox" checked class="custom-control-input" id="customSwitch1">
                            <label class="custom-control-label" for="customSwitch1"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="col-xl-3 col-lg-4 mb-3 col-responsive">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-9">
                        <h5 class="card-title card__margin--top" i18n>Guests Invited</h5>
                    </div>
                    <div class="col-3 card__margin--top">
                        <div class="custom-control custom-switch float-right">
                            <input type="checkbox" checked class="custom-control-input" (change)="displayGuestsInvited($event)" id="customSwitch3" >
                            <label class="custom-control-label" for="customSwitch3"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-4 mb-3 col-responsive">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-9">
                        <h5 class="card-title card__margin--top" i18n>Guest Confirmed</h5>
                    </div>
                    <div class="col-3 card__margin--top">
                        <div class="custom-control custom-switch float-right">
                            <input type="checkbox" checked class="custom-control-input" id="customSwitch4">
                            <label class="custom-control-label" for="customSwitch4"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="col-xl-3 col-lg-4 mb-3 col-responsive">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-9">
                        <h5 class="card-title card__margin--top" i18n>Guest Shortlist</h5>
                    </div>
                    <div class="col-3 card__margin--top">
                        <div class="custom-control custom-switch float-right">
                            <input type="checkbox" disabled class="custom-control-input" id="customSwitch5">
                            <label class="custom-control-label" for="customSwitch5"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-4 mb-3 col-responsive">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-9">
                        <h5 class="card-title " i18n>Guests – Completed projects</h5>
                    </div>
                    <div class="col-3 card__margin--top">
                        <div class="custom-control custom-switch float-right">
                            <input type="checkbox" checked class="custom-control-input" id="customSwitch6">
                            <label class="custom-control-label" for="customSwitch6"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="col-xl-3 col-lg-4 mb-3 col-responsive">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-9">
                        <h5 class="card-title " i18n>Partners – Completed travel info</h5>
                    </div>
                    <div class="col-3 card__margin--top">
                        <div class="custom-control custom-switch float-right">
                            <input type="checkbox" class="custom-control-input" id="customSwitch7">
                            <label class="custom-control-label" for="customSwitch7"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-4 mb-3 col-responsive">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-9">
                        <h5 class="card-title " i18n>Guest – Completed travel info</h5>
                    </div>
                    <div class="col-3 card__margin--top">
                        <div class="custom-control custom-switch float-right">
                            <input type="checkbox" class="custom-control-input" id="customSwitch8">
                            <label class="custom-control-label" for="customSwitch8"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="col-xl-3 col-lg-4 mb-3 col-responsive">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-9">
                        <h5 class="card-title " i18n>Partners – Invitation Quota</h5>
                    </div>
                    <div class="col-3 card__margin--top">
                        <div class="custom-control custom-switch float-right">
                            <input type="checkbox" disabled class="custom-control-input" id="customSwitch9">
                            <label class="custom-control-label" for="customSwitch9"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-4 mb-3 col-responsive">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-9">
                        <h5 class="card-title" i18n>Partners – Appointment quota</h5>
                    </div>
                    <div class="col-3 card__margin--top">
                        <div class="custom-control custom-switch float-right">
                            <input type="checkbox" class="custom-control-input" id="customSwitch10">
                            <label class="custom-control-label" for="customSwitch10"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-4 mb-3 col-responsive">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-9">
                        <h5 class="card-title card__margin--top" i18n>Email</h5>
                    </div>
                    <div class="col-3 card__margin--top">
                        <div class="custom-control custom-switch float-right">
                            <input type="checkbox" disabled class="custom-control-input" id="customSwitch11">
                            <label class="custom-control-label" for="customSwitch11"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-4 mb-3 col-responsive">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-9">
                        <h5 class="card-title card__margin--top" i18n>SMS</h5>
                    </div>
                    <div class="col-3 card__margin--top">
                        <div class="custom-control custom-switch float-right">
                            <input type="checkbox" checked class="custom-control-input" id="customSwitch12">
                            <label class="custom-control-label" for="customSwitch12"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="col-xl-3 col-lg-4 mb-3 col-responsive">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-9">
                        <h5 class="card-title card__margin--top" i18n>Notification</h5>
                    </div>
                    <div class="col-3 card__margin--top">
                        <div class="custom-control custom-switch float-right">
                            <input type="checkbox" disabled class="custom-control-input" id="customSwitch13">
                            <label class="custom-control-label" for="customSwitch13"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-4 mb-3 col-responsive">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-9">
                        <h5 class="card-title" i18n>Guests Event Previous</h5>
                    </div>
                    <div class="col-3 card__margin--top">
                        <div class="custom-control custom-switch float-right">
                            <input type="checkbox" disabled class="custom-control-input" id="customSwitch14">
                            <label class="custom-control-label" for="customSwitch14"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-4 mb-3 col-responsive">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-9">
                        <h5 class="card-title " i18n>Partners Event Previous</h5>
                    </div>
                    <div class="col-3 card__margin--top">
                        <div class="custom-control custom-switch float-right">
                            <input type="checkbox" class="custom-control-input" id="customSwitch15">
                            <label class="custom-control-label" for="customSwitch15"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-lg-4 mb-3 col-responsive">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-9">
                        <h5 class="card-title card__margin--top" i18n>Attendees</h5>
                    </div>
                    <div class="col-3 card__margin--top">
                        <div class="custom-control custom-switch float-right">
                            <input type="checkbox" checked class="custom-control-input" id="customSwitch16">
                            <label class="custom-control-label" for="customSwitch16"></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
