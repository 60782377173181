<div class="row sortBy">
    <span i18n>Sort</span>

    <div class="select-custom">
        <select class="form-control-sm" style="width: 108px!important;">
            <option class="optionLabel" i18n>First Name</option>
        </select>
    </div>

    <div class="select-custom">
        <select class="form-control-sm" style="width: 108px!important;">
            <option class="optionLabel" i18n>Country</option>
        </select>
    </div>

    <div class="select-custom">
        <select class="form-control-sm">
            <option class="optionLabel" i18n>All category</option>
        </select>
    </div>
    
    <div class="select-custom">
        <select class="form-control-sm">
            <option i18n>Select...</option>
        </select>
    </div>

    <div class="select-custom">
        <select class="form-control-sm lastOne">
            <option i18n>Select...</option>
        </select>
    </div>
</div>
