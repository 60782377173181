import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EventService } from '../../../../core/http/event.service';
import { RefService } from '../../../../core/http/ref.service';
import { FormDataEventService } from '../../../../core/http/form-data-event.service';
import { ImagesHandlerService } from 'src/app/core/http/images-handler.service';
import { bounceOutRightOnLeaveAnimation, bounceAnimation, hueRotateAnimation, fadeInDownOnEnterAnimation, fadeOutUpOnLeaveAnimation } from 'angular-animations';
import * as _moment from 'moment'

declare var $: any;

@Component({
  selector: 'app-event-edit-form',
  templateUrl: './event-edit-form.component.html',

  styleUrls: ['./event-edit-form.component.scss'],
  animations: [
    bounceOutRightOnLeaveAnimation({ delay: 100 }),
    bounceAnimation(),
    hueRotateAnimation(),
    fadeInDownOnEnterAnimation({ duration: 10, translate: '5px', delay: 0 }),
    fadeOutUpOnLeaveAnimation({ duration: 10, translate: '5px', delay: 0 }),
  ],
})

export class EventEditFormComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private FormDataEventService: FormDataEventService,
    private imagesHandlerService: ImagesHandlerService,
    private toastr: ToastrService,
    private http: HttpClient,
    private eventService: EventService,
    private refService: RefService,
  ) {}

  editEventFormGroup: any;
  editEventContactFormGroup: any;
  editEventMenuFormGroup: any;
  listOfEventType: any;
  listOfTimezone: any;
  listOfEvent: any;
  listOfEventInit: any;
  listOfEventContact: any;
  listOfEventMenu: any;
  eventPlaceholder: string;
  eventPlaceholderId: number;
  participantType: any = [];
  participantTypeInit: any = [];
  listOfImages = [null, null, null];
  uploadFile: File | null;
  uploadFileMenu: File | null;
  uploadFileLabel: string | undefined = 'Choose an image to upload';
  idEvent: any;
  tab: any = [-1, -1, -1];
  initialEventForm: any;
  initialEventContactForm: any;
  initialEventMenuForm: any;
  isEventHasChange: boolean = false;
  isEventContactHasChange: boolean = false;
  isEventMenuHasChange: boolean = false;
  isTypeHasChange: boolean = false;

  async ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.idEvent = params.get('id');
    });
    this.initRef();
    this.editEventFormGroup = this.FormDataEventService.dataEvents(); 
    this.editEventContactFormGroup = this.FormDataEventService.dataContact();
    this.editEventMenuFormGroup = this.FormDataEventService.dataMenu();
    await this.initList();
    this.initFormEvent();
    this.initFormEventContact();
    this.initFormEventMenu();
    this.initialEventForm = this.editEventFormGroup.value;
    this.editEventFormGroup.valueChanges.subscribe(value => {
      this.isEventHasChange = Object.keys(this.initialEventForm).some(key =>
        this.editEventFormGroup.value[key] != this.initialEventForm[key])
      }
    );
    this.initialEventContactForm = this.editEventContactFormGroup.value;
    this.editEventContactFormGroup.valueChanges.subscribe(value => {
      this.isEventContactHasChange = Object.keys(this.initialEventContactForm).some(key =>
        this.editEventContactFormGroup.value[key] != this.initialEventContactForm[key])
      }
    );
    this.initialEventMenuForm = this.editEventMenuFormGroup.value;
    this.editEventMenuFormGroup.valueChanges.subscribe(value => {
      this.isEventMenuHasChange = Object.keys(this.initialEventMenuForm).some(key =>
        this.editEventMenuFormGroup.value[key] != this.initialEventMenuForm[key])
      }
    );
  }
  initFormEvent() {
    if (this.listOfEvent["idLogo"] > 0) { this.tab[0] = this.listOfEvent["idLogo"]; }
    if (this.listOfEvent["idLogoBanner"] > 0) { this.tab[1] = this.listOfEvent["idLogoBanner"]; }
    if (this.listOfEvent["idFooter"] > 0) { this.tab[2] = this.listOfEvent["idFooter"]; }
    this.participantType = this.listOfEvent.typeParticipantObj
    this.editEventFormGroup.patchValue({
      EventName: this.listOfEvent.eventName,
      EventType: this.listOfEvent.idType,
      Location: this.listOfEvent.location,
      Capacity: this.listOfEvent.capacity,
      Organizer: this.listOfEvent.organizer,
      IdLogo: '',
      IdLogoBanner: '',
      IdFooter: '',
      StartDate: this.listOfEvent.startDate.substring(0,10),
      StartHour: this.listOfEvent.startDate.substring(11,16),
      EndDate: this.listOfEvent.endDate.substring(0,10),
      EndHour: this.listOfEvent.endDate.substring(11,16),
      StartDateRegistration: this.listOfEvent.startDateRegistration.substring(0,10),
      StartRegistrationHour: this.listOfEvent.startDateRegistration.substring(11,16),
      EndDateRegistration: this.listOfEvent.endDateRegistration.substring(0,10),
      EndRegistrationHour: this.listOfEvent.endDateRegistration.substring(11,16),
      IdTimezone: this.listOfEvent.idTimezone,
      replyTo: this.listOfEvent.replyTo,
      TypeParticipant: '',
      pitch: this.listOfEvent.pitch,
      description: this.listOfEvent.description,
      loginTxt: this.listOfEvent.loginTxt
    });
  }
  initFormEventContact() {
    this.editEventContactFormGroup.patchValue({
      contactInformation: this.listOfEventContact.contactInformation,
    });
  }
  initFormEventMenu() {
    this.editEventMenuFormGroup.patchValue({
      openLive: this.listOfEventMenu.openLive,
      openNetworking: this.listOfEventMenu.openNetworking,
      liveDate: this.listOfEventMenu.liveDate.substring(0,10),
      hourLive: this.listOfEventMenu.liveDate.substring(11,16),
      liveUrl: this.listOfEventMenu.liveUrl
    });
  }
  async initList() {
    this.eventService.getEventById(this.idEvent).subscribe(res => {
      this.listOfEventInit = res;
      this.participantTypeInit = this.listOfEventInit.typeParticipantObj
    });
    this.listOfEvent = await this.eventService.getEventById(this.idEvent).toPromise();
    this.listOfEventContact = await this.eventService.getEventContact(this.idEvent).toPromise();
    this.listOfEventMenu = await this.eventService.getEventMenu(this.idEvent).toPromise();
  }
  initRef() {
    this.eventService.getEventType().subscribe( response => {
      this.listOfEventType = response;
    });
    this.refService.getTimezone().subscribe( response => {
      this.listOfTimezone = response;
    });
  }
  async onSubmit() {
    if (this.listOfImages.every(e => e === null)) {
    } else {
      for (let i = 0; i < this.listOfImages.length; i++) {
        if (this.listOfImages[i] !== null) {
          const response = await this.http.request(this.imagesHandlerService.uploadImage(this.listOfImages[i])).toPromise();
          response["body"] ? (this.tab[i] = response["body"].idImage) : null;
        }
      }
    }
    if (this.uploadFileMenu) {
      const res = await this.http.request(this.imagesHandlerService.uploadImage(this.uploadFileMenu)).toPromise();
      res["body"] ? (this.editEventMenuFormGroup["value"].idPlaceholder = res["body"].idImage) : null;
    } else if (this.listOfEventMenu.idPlaceholder) { 
      this.editEventMenuFormGroup["value"].idPlaceholder = this.listOfEventMenu.idPlaceholder;
    } else {
      this.editEventMenuFormGroup["value"].idPlaceholder = -1;
    }
    //Init value at 0 if is null or empty
    if (this.editEventFormGroup["value"].Capacity == "" || this.editEventFormGroup["value"].Capacity == null) {
      this.editEventFormGroup["value"].Capacity = 0;
    }
    if (this.editEventFormGroup["value"].IdTimezone == "" || this.editEventFormGroup["value"].IdTimezone == null) {
      this.editEventFormGroup["value"].IdTimezone = 0;
    }
    //Init TypeParticipant
    this.editEventFormGroup["value"].TypeParticipant = this.participantType;
    //Init id image in the formGroup
    this.editEventFormGroup["value"].IdLogo = this.tab[0];
    this.editEventFormGroup["value"].IdLogoBanner = this.tab[1];
    this.editEventFormGroup["value"].IdFooter = this.tab[2];
    //Parse datepicker toString()
    this.editEventFormGroup["value"].StartDate = _moment(this.editEventFormGroup["value"].StartDate).format('DD/MM/YYYY');
    this.editEventFormGroup["value"].EventDate = this.editEventFormGroup["value"].StartDate;
    this.editEventFormGroup["value"].EndDate = _moment(this.editEventFormGroup["value"].EndDate).format('DD/MM/YYYY');
    this.editEventFormGroup["value"].StartDateRegistration = _moment(this.editEventFormGroup["value"].StartDateRegistration).format('DD/MM/YYYY');
    this.editEventFormGroup["value"].EndDateRegistration = _moment(this.editEventFormGroup["value"].EndDateRegistration).format('DD/MM/YYYY');
    this.editEventMenuFormGroup["value"].liveDate = _moment(this.editEventMenuFormGroup["value"].liveDate).format('DD/MM/YYYY');
    this.eventService.updateEvent(this.idEvent, this.editEventFormGroup["value"]);
    this.eventService.updateEventContact(this.idEvent, this.editEventContactFormGroup["value"]);
    this.eventService.updateEventMenu(this.idEvent, this.editEventMenuFormGroup["value"]);
    this.toastr.clear();
    this.toastr.success('Success', 'Event Update');
    this.router.navigateByUrl(`/admin/agenda/events/${this.idEvent}/details`);
  }
  handleFileInput(files: FileList, index: number) {
    if (files.length > 0) {
      this.uploadFile = files.item(0);
      this.listOfImages[index] = this.uploadFile;
    }
  }
  handleFileInputMenu(files: FileList) {
    if (files.length > 0) {
      this.uploadFileMenu = files.item(0);
    }
  }
  onChange(short: string, isChecked: boolean) {
    for (let i = 0; this.participantType.length > i; i++) {
      if (this.participantType[i].libelle == short) {
        !isChecked ? this.participantType[i].checked = false :  this.participantType[i].checked = true
      }
    }
    for (let i = 0; this.participantType.length > i; i++) {
      if (this.participantType[i].checked != this.participantTypeInit[i].checked) {
        this.isTypeHasChange = true;
        break;
      } else {
        this.isTypeHasChange = false;
      }
    }
  }
  cancelEdit() {
    this.isEventHasChange = false;
    this.isEventContactHasChange = false;
    this.isEventMenuHasChange = false;
    this.ngOnInit();
  }
  changeEventContactForm() {
    this.editEventContactFormGroup.valueChanges.subscribe(value => {
      this.isEventContactHasChange = Object.keys(this.initialEventContactForm).some(key =>
        this.editEventContactFormGroup.value[key] != this.initialEventContactForm[key])
      }
    );
  }
  changeEventMenuForm() {
    this.editEventMenuFormGroup.valueChanges.subscribe(value => {
      this.isEventMenuHasChange = Object.keys(this.initialEventMenuForm).some(key =>
        this.editEventMenuFormGroup.value[key] != this.initialEventMenuForm[key])
      }
    );
  }
  changeEventForm() {
    this.editEventFormGroup.valueChanges.subscribe(value => {
      this.isEventHasChange = Object.keys(this.initialEventForm).some(key =>
        this.editEventFormGroup.value[key] != this.initialEventForm[key])
      }
    );
  }
  selectAll() {
    document.querySelectorAll<HTMLElement>('.click_input').forEach(ele => ele.click());
  }
}



