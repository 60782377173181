<!-- Card attendees donut -->
<div class="card">
    <div class="card-body">
        <div class="dropright dropright-action">
            <svg class="opacity-icon" width="24px" height="24px" id="dropdownMenuButton4" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <use xlink:href="#action" />
            </svg>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton4">
                <span class="dropdown-menu-arrow"></span>
                <a class="dropdown-item" [routerLink]="['/admin/contacts/list/guests_annulation']" i18n>View
                    list</a>
                <a class="dropdown-item" href="http://ecv5-test.eventcatalyst.io/assets/extract_test.xls" i18n>Extract</a>
                <a class="dropdown-item" [routerLink]="['/admin/contacts/add']" i18n>Add {{data[0].title}}</a>
                <a class="dropdown-item" [routerLink]="['/admin/communication/emailing']" i18n>Send e-mail</a>
            </div>
        </div>

        <!-- <div class="canvas-holder mt-4">
            <canvas width="180" height="180" baseChart [data]="pieChartData" [labels]="pieChartLabels"
                [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins"
                [colors]="pieChartColors" [legend]="pieChartLegend">
            </canvas>
        </div> -->

        <h4 class="card-title mb-0">{{data[0].title}}</h4>
        <!-- <div class="badge badge-pill card__badge--color badge-confirmed-p"><span style="opacity: 0.7" i18n>CONFIRMED</span> 
        </div>-->

        <div class="col-12">
            <div style="display:flex;">
                <span class="mr-3 number h2">{{nb_total}}</span>
                <div class="margin-left: 20px">
                    <ul class="pl-0">
                        <li *ngFor="let item of data; let index = index">
                            <span *ngIf="index > 0" class="badge badge-pill card__badge-{{item.dataLabel}}"
                                [routerLink]="item.urlList" [ngbPopover]="item.altNb"
                                popoverClass="popover-custom" triggers="mouseenter:mouseleave">
                                <span>{{item.dataLabel}}</span>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End card -->