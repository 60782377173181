import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { bounceOutRightOnLeaveAnimation, bounceAnimation, hueRotateAnimation, fadeInDownOnEnterAnimation, fadeOutUpOnLeaveAnimation } from 'angular-animations';
import { TaskListService } from '../../../../core/http/task-list.service';
import { Task } from '../../../../shared/models/Task';
import { formatDate } from '@angular/common';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { MediaMatcher } from '@angular/cdk/layout';

declare var $: any;

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
  animations: [
    bounceOutRightOnLeaveAnimation({ delay: 200 }),
    bounceAnimation(),
    hueRotateAnimation(),
    fadeInDownOnEnterAnimation(),
    fadeOutUpOnLeaveAnimation(),
  ]
})
export class TaskListComponent implements OnInit {

  public taskForm = this.fb.group(
    {
      id: [Math.random().toString(36).substr(2, 9)],
      dateCreate: [formatDate(new Date(), 'yyyy/MM/dd', 'en')],
      userCreate: ['Admin'],
      taskTitle: ['', [Validators.required]],  //  Validators.maxLength(35)
      taskContent: [''],
      taskComment: [''],
      assignedTo: ['', [Validators.required]],
      dueDate: [],
      status: ['todo', Validators.required],
      priority: ['none', Validators.required],
    });

    // --------VARIABLES-------- //

  public users = [' Me', ' Emma', ' Jade', ' Jules', ' Louis', ' Gabriel', ' Louise', ' Anna']; // Liste des utilisateurs pour assigner des taches

  taskSelected;
  allTasks = [];
  allTasksLength;
  displayFilter = false;
  taskTodo;
  milestoneTodo;
  taskIP;
  taskDone;
  removedTasks;
  editTaskForm = false;
  taskModified;
  newTask;
  formCreate = false;
  animState1 = true;
  animState2 = true;
  animState3 = true;
  todoLength;
  ipLength;
  doneLength;
  allLength;
  isMobile;

  constructor(private fb: FormBuilder,
              public taskListService: TaskListService,
              public media: MediaMatcher
             ) {
    this.isMobile = media.matchMedia('(max-width: 768px)');
  }


  ngOnInit() {
    this.allTasks = this.taskListService.allTasks;
    this.taskTodo = this.taskListService.taskTodo;
    this.milestoneTodo = this.taskListService.milestoneTodo;
    this.taskIP = this.taskListService.taskIP;      // Récupère les listes depuis le service ou elles sont stockées
    this.taskDone = this.taskListService.taskDone;
    this.lengthCount(); // Check length des listes
    // this.removedTasks = this.taskListService.removedTasks;
    this.taskListService.sortData(); // Tri les tasks par priority
  }

  getMyStyles() {
    if (!this.isMobile.matches) {
      const myStyles = { // For Desktop
        'padding-top': this.milestoneTodo.length * 19 + '%'
      };
      return myStyles;
    }
    const myStyles = { // For mobile
      'padding-top': this.milestoneTodo.length * 22 + '%'
    };
    return myStyles;
  }

  toogleForm() {
    this.formCreate = true;
  }

  dismissForm() {
    this.formCreate = false;
    this.taskForm.reset();
  }

  toogleFilters() {
    this.displayFilter = !this.displayFilter;
  }

  // Listener des compteurs de liste pour déclencher les animations
  onDataChange1($event) {
    console.log($event);
    this.animState2 = !this.animState2;
  }
  onDataChange2($event) {
    console.log($event);
    this.animState3 = !this.animState3;
  }
  onDataChange3($event) {
    console.log($event);
    this.animState1 = !this.animState1;
  }

  editTask(task) {
    this.editTaskForm = true;
    this.taskModified = task;
    // console.log('Go to edit' + task);
    const assigned = ' ' + task.assignedTo;
    this.toogleForm();
    if (task.priorityNumber !== '0') {
      this.taskForm.patchValue({
        taskTitle: task.taskTitle,
        taskContent: task.taskContent,
        dueDate: task.dueDate,
        priority: 'high',
        assignedTo: assigned,
        status: task.statut
      });
    } else {
      this.taskForm.patchValue({
        taskTitle: task.taskTitle,
        taskContent: task.taskContent,
        dueDate: task.dueDate,
        priority: 'none',
        assignedTo: assigned,
        status: task.statut
      });
    }
  }

  deleteTask() {
    console.log(this.taskListService.removedTasks);
    console.log(this.taskListService.taskTodo);
    if (confirm('Are you sure to delete task ' + this.taskModified.taskTitle)) {
      this.taskModified.statutClass = 'success';
      this.taskModified.deleteBy = 'AdminX';                 // ICI put l'user qui a delete
      this.taskModified.deleteOn = formatDate(new Date(), 'yyyy/MM/dd', 'en');
      // this.taskListService.taskDone.splice(this.index, 1);
      this.taskListService.removedTasks.push(this.taskModified);
      this.dismissForm();
      console.log(this.taskListService.removedTasks);
      console.log(this.taskListService.taskTodo);
    }
  }

  // onChange(user) {
  //   this.userAssigned.push(user);
  // }

  // deleteUserAssigned(index) {
  //   this.userAssigned.splice(index, 1);
  // }

  dismissModal() {
    this.taskForm.reset();
  }

  restoreTask(task, index) {
    if (confirm('You are gonna restore task : ' + task.taskTitle + ' in list : ' + task.statut + '\n \n Are you sure ?')) {
      this.taskListService.removedTasks.splice(index, 1);
      if (task.statut === 'To do') {
        this.taskListService.taskTodo.push(task);
      } else if (task.statut === 'In progress') {
        this.taskListService.taskIP.push(task);
      } else if (task.statut === 'Done') {
        this.taskListService.taskDone.push(task);
      } else {
        console.log('ERROR -- Can\'t restore task' + task.taskTitle);
      }
    }
  }

  restoreTaskInEdition() {
    if (this.editTaskForm) {
      const task = this.taskModified;
      const status = this.taskModified.statut;
      this.taskListService.TaskInEdition.splice(0, 999);
      if  (status === 'todo') {
        this.taskListService.taskTodo.push(task);
      } else if (status === 'inProgress') {
        this.taskListService.taskIP.push(task);
      } else if (status === 'done') {
        this.taskListService.taskDone.push(task);
      }
      this.taskListService.sortData();
      this.dismissForm();
    } else {
      this.dismissForm();
    }
  }

  lengthCount() {
    this.todoLength = this.taskListService.taskTodo.length + this.taskListService.milestoneTodo.length;
    this.ipLength = this.taskListService.taskIP.length + this.taskListService.milestoneIP.length;
    this.doneLength = this.taskListService.taskDone.length + this.taskListService.milestoneDone.length;
    this.allLength = this.taskListService.allTasks.length + this.taskListService.allMilestones.length;
  }


  checkPriority() {
    if (this.taskForm.value.priority === 'none') { // Check la priorité donnée pour patcher les labels/class
      this.taskModified.priority = false;
      this.taskModified.priorityNumber = '0';
    }  else if (this.taskForm.value.priority === 'high') {
      this.taskModified.priority = true;
      this.taskModified.priorityLabel = 'High';
      this.taskModified.priorityClass = 'danger';
      this.taskModified.priorityNumber = '3';
    }
  }

  sortDate() {
    // this.taskListService.sortRemoveDate();
  }

  onSubmitNewTask() {
    console.log(this.taskForm.value);
    if (this.taskForm.value.dueDate === null) { // Ajout d'une fake dueDate si pas de dueDate selectionnée
      this.taskForm.value.dueDate = { day: 0, month: 0, year: 0 };
    }
    this.newTask = {
      statut: this.taskForm.value.status,
      taskTitle: this.taskForm.value.taskTitle,
      taskContent: this.taskForm.value.taskContent,
      dueDate: this.taskForm.value.dueDate,
      assignedTo: this.taskForm.value.assignedTo,
      priority: Boolean,
      priorityNumber: '',
      priorityClass: '',
      priorityLabel: '',
    };
    if (this.taskForm.value.priority === 'none') { // Check la priorité donnée pour patcher les labels/class
      this.newTask.priority = false;
      this.newTask.priorityNumber = '0';
    }  else if (this.taskForm.value.priority === 'high') {
      this.newTask.priority = true;
      this.newTask.priorityLabel = 'High';
      this.newTask.priorityClass = 'danger';
      this.newTask.priorityNumber = '3';
    }
    // if (this.taskForm.value.taskComment.length > 0) { // Ajout du commentaire si champs commentaire rempli
    //   const com = this.taskForm.value.taskComment;
    //   const commentObject = {
    //     user: ' Admin1',    // ICI METTRE LE PSEUDO DE L'USER
    //     dateComment: formatDate(new Date(), 'yyyy/MM/dd', 'en'),
    //     comment: com
    //   };
    //   this.taskForm.value.taskComment = [commentObject];
    // } else {
    //   this.taskForm.value.taskComment = [];
    // }
    if (this.newTask.statut === 'todo') { // Check dans quelle liste push la task
      this.taskListService.taskTodo.push(this.newTask); // Push la task dans la liste des taches todo
      this.animState1 = !this.animState1; // Déclenche l'animation du compteur
    } else if (this.newTask.statut === 'inProgress') {
      this.taskListService.taskIP.push(this.newTask);
      this.animState2 = !this.animState2;
    } else if (this.newTask.statut === 'done') {
      this.taskListService.taskDone.push(this.newTask);
      this.animState3 = !this.animState3;
    }
    this.taskListService.allTasks.push(this.newTask); // Push la task dans la liste allTasks
    this.taskListService.sortData(); // Reorganise la liste par priority
    this.lengthCount(); // Check length des listes
    this.dismissForm();
    console.log(this.newTask);
  }

  onSubmit() {
    if (this.editTaskForm) { ///// On submit d'une new task /////
      this.onSubmitNewTask();
    } else if (this.editTaskForm) {  ///// On submit d'une task modified /////
      // console.log('on va submit une task modifiée');
      this.checkPriority();
      this.taskModified.statut = this.taskForm.value.status;
      this.taskModified.taskTitle = this.taskForm.value.taskTitle;
      this.taskModified.taskContent = this.taskForm.value.taskContent;
      this.taskModified.dueDate = this.taskForm.value.dueDate;
      this.taskModified.assignedTo = this.taskForm.value.assignedTo;
      if  (this.taskForm.value.status === 'todo') { // Check dans quelle liste (statut) push la task
        this.taskListService.taskTodo.push(this.taskModified); // Push la task dans la liste des taches todo
        this.animState1 = !this.animState1; // Déclenche l'animation du compteur
      } else if (this.taskForm.value.status === 'inProgress') {
        this.taskListService.taskIP.push(this.taskModified);
        this.animState2 = !this.animState2;
      } else if (this.taskForm.value.status === 'done') {
        this.taskListService.taskDone.push(this.taskModified);
        this.animState3 = !this.animState3;
      }
      this.taskListService.allTasks.push(this.taskModified);
      this.taskListService.sortData(); // Reorganise la liste par priority
      this.lengthCount(); // Check length des listes
      this.dismissForm();
    }
  }


}
