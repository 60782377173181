import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class TaskListService {
  // tslint:disable: max-line-length

  public taskTodo = [
    {
      id: 1,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      taskTitle: 'Etiam tristique efficitur',
      taskComment: [{ user: 'Admin1', userColor: '#7c2532', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Jean-Michel', userColor: '#536d1a', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 9,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      },
    },
    {
      id: 2,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'magni dolores eos',
      taskTitle: 'Magni dolores eosss',
      taskComment: [],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 10,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 3,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admi',
      doneDate: '',
      doneBy: [],
      taskContent: 'Magni dolores eos',
      taskTitle: 'Ut enim ad minima et dolore magna aliqua',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: true,
      priorityLabel: 'HIGH',
      priorityClass: 'danger',
      priorityNumber: '2',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 4,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: '',
      taskTitle: 'Provident, similique sunt in',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 10,
        month: 12,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 5,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'Magni dolores eos',
      taskTitle: 'Culpa qui officia deserunt',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: true,
      priorityLabel: 'HIGH',
      priorityClass: 'danger',
      priorityNumber: '1',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 0,
        month: 0,
        year: 0
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 6,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'magni dolores eos',
      taskTitle: 'Nimi, id est laborum et dolorum, eiusmod tempor incididunt ut labore et dolore magna aliqua',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 8,
        year: 2020
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 7,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'Retrouvez ici tous les détails et infos supplémentaires concernant la tache',
      taskTitle: 'Nouvelle tache',
      taskComment: [],
      milestone: false,
      priority: true,
      priorityLabel: 'HIGH',
      priorityClass: 'danger',
      priorityNumber: '3',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 8,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'magni dolores eos',
      taskTitle: 'Facilis est et expedita distinctio',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 9,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'magni dolores eos',
      taskTitle: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 10,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'magni dolores eos',
      taskTitle: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 11,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'magni dolores eos',
      taskTitle: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 12,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'magni dolores eos',
      taskTitle: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 13,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'magni dolores eos',
      taskTitle: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 14,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'magni dolores eos',
      taskTitle: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 15,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'magni dolores eos',
      taskTitle: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
  ];

  public taskIP = [
    {
      id: 20,
      statut: 'inProgress',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskTitle: 'Magni dolores eos',
      taskContent: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 21,
      statut: 'inProgress',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskTitle: 'Magni dolores eos',
      taskContent: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 22,
      statut: 'inProgress',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskTitle: 'Magni dolores eos',
      taskContent: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
  ];

  public taskDone = [
    {
      id: 23,
      statut: 'done',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: {
        day: 23,
        month: 8,
        year: 2019
      },
      doneBy: ['Admin1'],
      taskTitle: 'Magni dolores eos',
      taskContent: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 24,
      statut: 'done',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: {
        day: 13,
        month: 9,
        year: 2019
      },
      doneBy: ['Admin2'],
      taskTitle: 'Magni dolores eos',
      taskContent: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
  ];

  public removedTasks = [
    {
      id: 30,
      statut: 'done',
      statutClass: 'success',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: {
        day: 13,
        month: 9,
        year: 2019
      },
      doneBy: ['Admin2'],
      taskTitle: 'Magni dolores eos',
      taskContent: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: 'AdminX',
      deleteOn: '2019/09/26',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 32,
      statut: 'done',
      statutClass: 'success',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: {
        day: 13,
        month: 9,
        year: 2019
      },
      doneBy: ['Admin2'],
      taskTitle: 'Magni eos',
      taskContent: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: 'AdminX',
      deleteOn: '2019/09/26',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 33,
      statut: 'done',
      statutClass: 'success',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: {
        day: 13,
        month: 9,
        year: 2019
      },
      doneBy: ['Admin2'],
      taskTitle: 'Magni dolores',
      taskContent: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: 'AdminX',
      deleteOn: '2019/09/26',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
  ];

  public allTasks  = [
    {
      id: 1,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      taskTitle: 'Etiam tristique efficitur',
      taskComment: [{ user: 'Admin1', userColor: '#7c2532', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Jean-Michel', userColor: '#536d1a', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 9,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      },
    },
    {
      id: 2,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'magni dolores eos',
      taskTitle: 'Magni dolores eosss',
      taskComment: [],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 10,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 3,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admi',
      doneDate: '',
      doneBy: [],
      taskContent: 'Magni dolores eos',
      taskTitle: 'Ut enim ad minima et dolore magna aliqua',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: true,
      priorityLabel: 'HIGH',
      priorityClass: 'danger',
      priorityNumber: '2',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 4,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: '',
      taskTitle: 'Provident, similique sunt in',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 10,
        month: 12,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 5,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'Magni dolores eos',
      taskTitle: 'Culpa qui officia deserunt',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: true,
      priorityLabel: 'HIGH',
      priorityClass: 'danger',
      priorityNumber: '1',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 0,
        month: 0,
        year: 0
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 6,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'magni dolores eos',
      taskTitle: 'Nimi, id est laborum et dolorum, eiusmod tempor incididunt ut labore et dolore magna aliqua',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 8,
        year: 2020
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 7,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'Retrouvez ici tous les détails et infos supplémentaires concernant la tache',
      taskTitle: 'Nouvelle tache',
      taskComment: [],
      milestone: false,
      priority: true,
      priorityLabel: 'HIGH',
      priorityClass: 'danger',
      priorityNumber: '3',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 8,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'magni dolores eos',
      taskTitle: 'Facilis est et expedita distinctio',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 9,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'magni dolores eos',
      taskTitle: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 10,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'magni dolores eos',
      taskTitle: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 11,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'magni dolores eos',
      taskTitle: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 12,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'magni dolores eos',
      taskTitle: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 13,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'magni dolores eos',
      taskTitle: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 14,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'magni dolores eos',
      taskTitle: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 15,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'magni dolores eos',
      taskTitle: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 20,
      statut: 'inProgress',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskTitle: 'Magni dolores eos',
      taskContent: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 21,
      statut: 'inProgress',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskTitle: 'Magni dolores eos',
      taskContent: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 22,
      statut: 'inProgress',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskTitle: 'Magni dolores eos',
      taskContent: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 23,
      statut: 'done',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: {
        day: 23,
        month: 8,
        year: 2019
      },
      doneBy: ['Admin1'],
      taskTitle: 'Magni dolores eos',
      taskContent: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },
    {
      id: 24,
      statut: 'done',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: {
        day: 13,
        month: 9,
        year: 2019
      },
      doneBy: ['Admin2'],
      taskTitle: 'Magni dolores eos',
      taskContent: 'Etiam tristique efficitur nisl',
      taskComment: [{ user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Me', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: false,
      priority: true,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 11,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      }
    },

  ];

  public TaskInEdition = [

  ];

  public milestoneTodo = [
    {
      id: 101,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      taskTitle: 'Milestone TEST',
      taskComment: [{ user: 'Admin1', userColor: '#7c2532', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Jean-Michel', userColor: '#536d1a', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: true,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 9,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      },
    },
    {
      id: 102,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      taskTitle: 'Milestone TEST 2',
      taskComment: [{ user: 'Admin1', userColor: '#7c2532', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Jean-Michel', userColor: '#536d1a', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: true,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 9,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      },
    },
    {
      id: 103,
      statut: 'todo',
      statutClass: '',
      dateCreate: '2019/01/01',
      userCreate: 'Admin',
      doneDate: '',
      doneBy: [],
      taskContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      taskTitle: 'Milestone TEST 3',
      taskComment: [{ user: 'Admin1', userColor: '#7c2532', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' },
                    { user: 'Jean-Michel', userColor: '#536d1a', dateComment: '2019/01/01', comment: 'lorem ipsum dolorets magni etiam tristique' }],
      milestone: true,
      priority: false,
      priorityLabel: '',
      priorityClass: '',
      priorityNumber: '0',
      assignedTo: 'Me',
      deleteBy: '',
      deleteOn: '',
      dueDate: {
        day: 23,
        month: 9,
        year: 2019
      },
      startTime: {
        hour: 14,
        minute: 30,
        second: 0
      },
    }
  ];

  public milestoneIP = [

  ];

  public milestoneDone = [

  ];

  public allMilestones = [

  ];

  constructor() { }

  sortData() {
    this.taskTodo.sort((a, b) => b.priorityNumber.localeCompare(a.priorityNumber));
    this.taskIP.sort((a, b) => b.priorityNumber.localeCompare(a.priorityNumber));
    this.taskDone.sort((a, b) => b.priorityNumber.localeCompare(a.priorityNumber));
    this.allTasks.sort((a, b) => b.priorityNumber.localeCompare(a.priorityNumber));
    // console.log(this.taskTodo);
    // console.log(this.taskIP);
  }

  // sortRemoveDate() {
  //   this.removedTasks.forEach(function(element) {
  //     element.startTime.second = Date.parse(element.deleteOn)
  //   });
  //   this.removedTasks.sort((a, b) => b.startTime.second.localeCompare(a.startTime.second));
  // }
}
