<div *ngIf="!isForm1Display && !isForm2Display && !isResultFilterDisplay && !isResultIdDisplay">
  <p class="mt-5 mb-0">{{emailObject.mailTemplateSelected.templateName}}</p>
  <h3 i18n>SUBJECT: {{emailObject.mailSubject}}</h3>
  <form [formGroup]="selectMethodFormGroup">
    <div class="row mailingList">

      <div class="offset-3 col-md-3">
        <div class="card">
          <div class="card-body text-center">
            <svg id="svgSearch" height="50px" width="50px" class="mr-2" viewBox="0 0 24 24">
              <use xlink:href="#search" />
            </svg>
            <br />
            <p class="mt-4 cardsText" i18n>Mailing list from filters </p>
            <div class="mt-4 mb-5">
              <select class="select form-control" id="listFiltersSaved" (change)="pickFilterList($event.target.value)"
                formControlName="SelectFilterList">
                <option value="null" i18n>Choose</option>
                <option *ngFor="let list of listOfFiltersList;" [value]="list.listId">
                  {{ list.listName }}
                </option>
              </select>
            </div>
            <button mat-button [disabled]="filterListPicked || idsListPicked" class="btn btn-block"
              (click)="isForm1Display = !isForm1Display"
              style="margin-right: 13px; background-color: #84C000; color: white; border: 1px solid #84C000; border-radius: 1px;">
              <span style="font-family: 'nowayRegular', sans-serif" i18n>Create from filters</span>
            </button>
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="card">
          <div class="card-body text-center">
            <svg id="svgIdList" height="50px" width="50px" class="mr-2" viewBox="0 0 24 24">
              <use xlink:href="#idList" />
            </svg>
            <p class="mt-4 cardsText" i18n>Mailing list from person IDs </p>
            <div class="mt-4 mb-5">
              <select class="select form-control" id="listIdSaved" (change)="pickIdList($event.target.value)"
                formControlName="SelectIdsList">
                <option value="null" i18n>Choose</option>
                <option *ngFor="let list of listOfIds" [value]="list.listId">
                  {{ list.listName }}
                </option>
              </select>
            </div>
            <button mat-button [disabled]="filterListPicked || idsListPicked" class="btn btn-block"
              (click)="isForm2Display = !isForm2Display"
              style="margin-right: 13px; background-color: #84C000; color: #000;; border: 1px solid #84C000; border-radius: 1px;">
              <span style="font-family: 'nowayRegular', sans-serif" i18n>Create list from iD's</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<!-- CREATE LIST FROM FILTERS -->
<div *ngIf="isForm1Display" style="min-height: 435px">
  <form [formGroup]="secondStepFormGroup">

    <h3 class="pt-4" i18n>CREATE LIST FROM FILTERS</h3>

    <div class="mt-3 formInfos">
      <span i18n>Create contact list with your own choices. </span>
      <svg id="svgInfo" height="24px" width="24px" viewBox="0 0 24 24" class="svgLogo mb-1 ml-2">
        <use xlink:href="#infoLogo" />
      </svg>
    </div>

    <div class="row form-row mt-5">
      <div class="col-md-5 select-custom">
        <label for="listIdSaved" class="label col-sm-5 col-form-label" i18n>Filters saved</label>
        <select class="select form-control" id="listIdSaved" (change)="LoadFilterList($event.target.value)">
          <option disabled value="" i18n>Choose</option>
          <option *ngFor="let list of listOfFiltersList;" [value]="list.listId">
            {{ list.listName }}
          </option>
        </select>
      </div>
    </div>

    <h3 class="pt-4 mb-4" i18n>ATTENDEE PROFILE</h3>

    <div class="row form-row">
      <div class="col-md-5 select-custom">
        <label for="participantType" class="label col-sm-5 col-form-label" i18n>Type</label>
        <select class="select form-control" id="participantType" formControlName="DDL_participantType" #participantType>
          <option value="All" i18n>All</option>
          <option [ngValue]="type" *ngFor="let type of listOfTypes">
            {{ type }}
          </option>
        </select>
      </div>

      <div class="col-md-5 select-custom">
        <label for="participantCountry" class="label col-sm-5 col-form-label" i18n>Country</label>
        <select class="select form-control" id="participantCountry" formControlName="DDL_participantCountry">
          <option value="All" i18n>All</option>
          <option [ngValue]="country" *ngFor="let country of listOfCountry">
            {{ country }}
          </option>
        </select>
      </div>
    </div>

    <div class="row form-row">
      <div class="col-md-5 select-custom"
        *ngIf="(secondStepFormGroup.value.DDL_participantType != 'Guests') && (secondStepFormGroup.value.DDL_participantType != 'Partners')">
        <label for="catType" class="label col-sm-5 col-form-label" i18n>Category Type</label>
        <select class="select form-control" id="catType" disabled>
          <option disabled value="" i18n>Choose</option>
        </select>
      </div>

      <div class="col-md-5 select-custom" *ngIf="secondStepFormGroup.value.DDL_participantType === 'Guests'">
        <label for="DDL_participantGuestCategory" class="label col-sm-5 col-form-label" i18n>Category Guests</label>
        <select class="select form-control" id="DDL_participantGuestCategory"
          formControlName="DDL_participantGuestCategory">
          <option value="All" i18n>All</option>
          <option [ngValue]="cat" *ngFor="let cat of listOfGuestCategory">
            {{ cat }}
          </option>
        </select>
      </div>


      <div class="col-md-5 select-custom" *ngIf="secondStepFormGroup.value.DDL_participantType === 'Partners'">
        <label for="DDL_participantPartnersCategory" class="label col-sm-5 col-form-label" i18n>Category
          Partners</label>
        <select class="select form-control" id="DDL_participantPartnersCategory"
          formControlName="DDL_participantPartnersCategory">
          <option value="All" i18n>All</option>
          <option [ngValue]="cat" *ngFor="let cat of listOfPartnersCategory">
            {{ cat }}
          </option>
        </select>
      </div>

      <div class="col-md-5 select-custom">
        <label for="participantLangage" class="label col-sm-5 col-form-label" i18n>Langage</label>
        <select class="select form-control" id="participantLangage" formControlName="DDL_participantLangage">
          <option value="All" i18n>All</option>
          <option value="FR" i18n>FR</option>
          <option value="EN" i18n>EN</option>
        </select>
      </div>
    </div>

    <hr style="border: solid 1px #EAE5D6; margin-top: 40px; margin-bottom: 31px">

    <h3 class="pt-4 mb-4" i18n>ATTENDEE PARTICIPATION</h3>

    <div class="row form-row">
      <div class="col-md-5 select-custom">
        <label for="participantStatus" class="label col-sm-5 col-form-label" i18n>Status</label>
        <select class="select form-control" id="participantStatus" formControlName="DDL_participantStatus">
          <option value="All" i18n>All</option>
          <option [ngValue]="status" *ngFor="let status of listOfStatus">
            {{ status }}
          </option>
        </select>
      </div>

      <div class="col-md-5 select-custom">
        <label for="participantPass" class="label col-sm-5 col-form-label" i18n>Pass</label>
        <select class="select form-control" id="participantPass" formControlName="DDL_participantPass">
          <option value="All" i18n>All</option>
          <option [ngValue]="pass" *ngFor="let pass of listOfPass">
            {{ pass }}
          </option>
        </select>
      </div>
    </div>

    <div class="row form-row">
      <div class="col-md-5 select-custom">
        <label for="participantPrioLvl" class="label col-sm-5 col-form-label" i18n>Priority level</label>
        <select class="select form-control" id="participantPrioLvl" formControlName="DDL_participantPrioLvl">
          <option value="All" i18n>All</option>
          <option [ngValue]="priorityLvl" *ngFor="let priorityLvl of listOfPriorityLvl">
            {{ priorityLvl }}
          </option>
        </select>
      </div>

      <div class="col-md-5 select-custom">
        <label for="DDL_participantDomain" class="label col-sm-5 col-form-label" i18n>Domain</label>
        <select class="select form-control" id="DDL_participantDomain" formControlName="DDL_participantDomain">
          <option value="All" i18n>All</option>
          <option [ngValue]="domain" *ngFor="let domain of listOfDomains">
            {{ domain }}
          </option>
        </select>
      </div>
    </div>

    <hr style="border: solid 1px #EAE5D6; margin-top: 40px; margin-bottom: 31px">

    <h3 class="pt-4 mb-4" i18n>OTHER OPTIONS</h3>

    <div class="row form-row">
      <div class="col-md-5 select-custom">
        <label for="participantProfileUpdate" class="label col-sm-5 col-form-label" i18n>Profile update person</label>
        <select class="select form-control" id="participantProfileUpdate"
          formControlName="DDL_participantProfileUpdate">
          <option value="Ignore" selected="selected" i18n>Ignore</option>
          <option value="Yes" i18n>Yes</option>
          <option value="No" i18n>No</option>
        </select>
      </div>

      <div class="col-md-5 select-custom">
        <label for="participantCompanyUpdate" class="label col-sm-5 col-form-label" i18n>Profile update company</label>
        <select class="select form-control" id="participantCompanyUpdate"
          formControlName="DDL_participantCompanyUpdate">
          <option value="Ignore" selected="selected" i18n>Ignore</option>
          <option value="Yes" i18n>Yes</option>
          <option value="No" i18n>No</option>
        </select>
      </div>
    </div>

    <div class="row form-row">
      <div class="col-md-5 select-custom">
        <label for="participantCCDebit" class="label col-sm-5 col-form-label" i18n>CC Debit Form received </label>
        <select class="select form-control" id="participantCCDebit" formControlName="DDL_participantCCDebit">
          <option value="Ignore" selected="selected" i18n>Ignore</option>
          <option value="Yes" i18n>Yes</option>
          <option value="No" i18n>No</option>
        </select>
      </div>

      <div class="col-md-5 select-custom">
        <label for="participantOne" class="label col-sm-5 col-form-label" i18n>One</label>
        <select class="select form-control" id="participantOne" formControlName="DDL_participantOne">
          <option value="Ignore" selected="selected" i18n>Ignore</option>
          <option value="Yes" i18n>Yes</option>
          <option value="No" i18n>No</option>
        </select>
      </div>
    </div>
  </form>
</div>
<!--RESULTS FROM FILTERS-->
<div *ngIf="isResultFilterDisplay">
  <h3 class="pt-4" i18n>RESULTS FROM FILTERS</h3>

  <div class="row no-margin">
    <h3 class="pt-4" i18n>ATTENDEE PROFILE</h3>
    <div class="col-12 flex">
      <div class="col-3" i18n>
        Type : {{filterValues.DDL_participantType}}
      </div>
      <div class="col-3" i18n>
        Country : {{filterValues.DDL_participantCountry}}
      </div>
      <div class="col-3" *ngIf="filterValues.DDL_participantType === 'Guests'" i18n>
        Category Guests : {{filterValues.DDL_participantGuestCategory}}
      </div>
      <div class="col-3" *ngIf="filterValues.DDL_participantType === 'Partners'" i18n>
        Category Partners : {{filterValues.DDL_participantPartnersCategory}}
      </div>
      <div class="col-3"
        *ngIf="(filterValues.DDL_participantType != 'Guests') && (filterValues.DDL_participantType != 'Partners')" i18n>
        Category type : All
      </div>
      <div class="col-3" i18n>
        Langage : {{filterValues.DDL_participantLangage}}
      </div>
    </div>
  </div>
  <div class="row no-margin">
    <h3 class="pt-4" i18n>ATTENDEE PARTICIPATION</h3>
    <div class="col-12 flex">
      <div class="col-3" i18n>
        Status : {{filterValues.DDL_participantStatus}}
      </div>
      <div class="col-3" i18n>
        Pass : {{filterValues.DDL_participantPass}}
      </div>
      <div class="col-3" i18n>
        Priority level : {{filterValues.DDL_participantPrioLvl}}
      </div>
      <div class="col-3" i18n>
        Domain : {{filterValues.DDL_participantDomain}}
      </div>
    </div>

  </div>
  <div class="row no-margin">
    <h3 class="pt-4" i18n>OTHER OPTIONS</h3>
    <div class="col-12 flex">
      <div class="col-3" i18n>
        Profile update person : {{filterValues.DDL_participantProfileUpdate}}
      </div>
      <div class="col-3" i18n>
        Profile update company : {{filterValues.DDL_participantCompanyUpdate}}
      </div>
      <div class="col-3" i18n>
        CC Debit Form received : {{filterValues.DDL_participantCCDebit}}
      </div>
      <div class="col-3" i18n>
        One : {{filterValues.DDL_participantOne}}
      </div>
    </div>
  </div>

  <hr style="border: solid 1px #EAE5D6; margin-top: 40px; margin-bottom: 31px">

  <div class="resultCount">
    <span i18n>2156 attendees found</span>
    <span i18n>from 580 companies</span>
  </div>

  <hr style="border: solid 1px #EAE5D6; margin-top: 40px; margin-bottom: 31px">

</div>

<!-- CREATE LIST FROM ID'S -->

<div *ngIf="isForm2Display" style="min-height: 435px" class="idListForm">

  <h3 class="pt-4" i18n>CREATE LIST FROM ID'S</h3>

  <div class="mt-3 formInfos">
    <span i18n>Copy/Paste your contacts. </span>
    <svg id="svgInfo" height="24px" width="24px" viewBox="0 0 24 24" class="svgLogo mb-1 ml-2">
      <use xlink:href="#infoLogo" />
    </svg>
  </div>

  <div class="row form-row mt-5">
    <div class="col-md-5 select-custom">
      <label for="listIdSaved" class="label col-sm-5 col-form-label" i18n>Id's saved</label>
      <select class="select form-control" id="listIdSaved" (change)="loadIdList($event.target.value)">
        <option disabled value="" i18n>Choose</option>
        <option *ngFor="let list of listOfIds" [value]="list.listId">
          {{ list.listName }}
        </option>
      </select>
    </div>
  </div>

  <div class="row form-row mt-2">
    <div class="col-md-10 flex">
      <label for="newIdList" class="label col-sm-2 col-form-label noPaddingLabel" i18n>ID'S</label>
      <textarea class="input form-control IDinput" rows="5" id="newIdList" [(ngModel)]="idsList"
        placeholder="Paste ID's here (0000) separate by &quot;,&quot;"></textarea>
    </div>
  </div>

</div>

<!--Results from ID's-->

<div *ngIf="isResultIdDisplay" style="min-height: 375px;">
  <h3 class="pt-4" i18n>RESULTS FROM ID list</h3>
  <br>
  <hr style="border: solid 1px #EAE5D6; margin-top: 40px; margin-bottom: 31px">
  <br>
  <div class="resultCount">
    <span i18n>2156 attendees found</span>
    <span i18n>from 580 companies</span>
  </div>

  <hr style="border: solid 1px #EAE5D6; margin-top: 40px; margin-bottom: 31px">

</div>


<!-- Modal Save as-->

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="width: 55%;margin-left: 25%;">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel" *ngIf="isForm2Display" i18n>Save Id list as...</h5>
        <h5 class="modal-title" id="exampleModalLabel" *ngIf="isForm1Display" i18n>Save filters search as...</h5>
        <h5 class="modal-title" id="exampleModalLabel" *ngIf="isResultFilterDisplay || isResultIdDisplay" i18n>Save list
          as...</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form [formGroup]="saveModalFormGroup">
        <div class="modal-body">
          <input type="text" placeholder="List name" class="input form-control" id="nameListInput"
            formControlName="nameListInput">
        </div>
        <div class="modal-footer">
          <button type="button" *ngIf="isForm1Display" class="btn btn-success button--color"
            style="margin-left: auto;margin-right: auto;" (click)="saveFiltersList()" i18n>Save filters list</button>
          <button type="button" *ngIf="isForm2Display" class="btn btn-success button--color"
            style="margin-left: auto;margin-right: auto;" (click)="saveIDsList()" i18n>Save Id's list</button>
          <button type="button" *ngIf="isResultFilterDisplay || isResultIdDisplay" class="btn btn-success button--color"
            style="margin-left: auto;margin-right: auto;" (click)="saveAttendeesList()" i18n>Save attendees list</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Modal View List -->
<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" style="height: 600px;overflow-y: scroll;">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel" i18n>Selected attendees list</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th scope="col" i18n>ID</th>
            <th scope="col" i18n>FIRST NAME</th>
            <th scope="col" i18n>LAST NAME</th>
            <th scope="col" i18n>STATUS</th>
            <th scope="col" i18n>TYPE</th>
            <th scope="col" i18n>EMAIL</th>
          </tr>
        </thead>
        <tbody style="font-size: 14px;color: #000!important;font-family: 'nowayRegular'!important;">
          <tr *ngFor="let attendee of attendeesList">
            <td scope="row">{{attendee.id}}</td>
            <td>{{attendee.firstname}}</td>
            <td>{{attendee.lastname}}</td>
            <td>
              <div class="badge badge-pill card__badge--color-success" style="cursor:pointer;height: 18px;">
                <span i18n>
                  {{attendee.status}}
                </span>
              </div>
            </td>
            <td>{{attendee.type}}</td>
            <td>{{attendee.email}}</td>
          </tr>
        </tbody>
      </table>
      <div class="modal-footer">
        <button type="button" class="btn btn-success button--color" data-dismiss="modal" aria-label="Close"
          style="margin-left: auto;margin-right: auto;">ok</button>
      </div>
    </div>
  </div>
</div>


<div class="footerButtons">
  <button mat-button matStepperPrevious
    *ngIf="!isResultFilterDisplay && !isForm1Display && !isForm2Display && !isResultIdDisplay" class="btn navButton" i18n>
    Previous
  </button>
  <button mat-button *ngIf="isForm1Display || isForm2Display" (click)="checkDisplayForm()" class="btn navButton" i18n>
    Previous
  </button>
  <button mat-button class="btn navButton" *ngIf="isForm1Display || isForm2Display" style="margin-left: 30%!important;"
    data-toggle="modal" data-target="#exampleModal" i18n>
    Save as a new list
  </button>
  <button mat-button matStepperNext disabled class="btn btn-success navButtonNext float-right button--color"
    *ngIf="!isForm1Display && !isForm2Display && !isResultFilterDisplay && !isResultIdDisplay && !filterListPicked && !idsListPicked"
    i18n>
    Next
  </button>
  <button mat-button (click)="skipStep()" class="btn btn-success navButtonNext float-right button--color"
    *ngIf="!isForm1Display && !isForm2Display && !isResultFilterDisplay && !isResultIdDisplay && (filterListPicked || idsListPicked)"
    i18n>
    Next
  </button>
  <button mat-button matStepperNext class="btn btn-success navButtonNext float-right button--color"
    *ngIf="isResultFilterDisplay || isResultIdDisplay" style="margin-bottom: 30px;" i18n>
    Next
  </button>
  <button mat-button class="btn btn-success navButtonNext float-right button--color"
    *ngIf="isForm1Display || isForm2Display" (click)="saveFilters()" i18n>
    Next
  </button>
  <button mat-button class="btn navButton"
    *ngIf="(isResultFilterDisplay || isResultIdDisplay) && !isForm1Display && !isForm2Display" data-toggle="modal"
    data-target=".bd-example-modal-lg" style="margin-left: 25%;" i18n>
    View list
  </button>
  <button mat-button class="btn navButton"
    *ngIf="(isResultFilterDisplay || isResultIdDisplay) && !isForm1Display && !isForm2Display && !filterListPicked && !idsListPicked"
    data-toggle="modal" data-target="#exampleModal" i18n>
    Save list
  </button>
  <button mat-button class="btn navButton"
    *ngIf="(isResultFilterDisplay || isResultIdDisplay) && !isForm1Display && !isForm2Display" style="margin-right: 25%">
    <a href="http://ecv5-test.eventcatalyst.io/assets/extract_test.xls" class="extractLink" i18n>Extract list</a>
  </button>
  <button mat-button (click)="checkDisplayFormResult()" class="btn float-right navButton"
    *ngIf="(!isForm1Display && !isForm2Display) && (isResultFilterDisplay || isResultIdDisplay)"
    style="float: left!important;" i18n>
    Previous
  </button>
</div>