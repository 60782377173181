import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings-module',
  templateUrl: './settings-module.component.html',
  styleUrls: ['./settings-module.component.scss']
})
export class SettingsModuleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
